import { css } from '@emotion/react';

export const baseCss = () => {
  return css`
    display: flex;
    .search-wrapper {
      width: 260px;
      height: 100%;
      padding: 20px;
      display: inline-flex;
      flex-direction: column;

      .category-menu-wrapper {
        margin-top: 8px;
        .category-menu-button {
          &.active {
            background-color: #edf1f6;
          }
          width: 100%;
          background-color: #edf1f6;
          white-space: nowrap;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 8px;
          padding: 8px;
          cursor: pointer;
          background-color: unset;
          transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
          &:hover {
            color: #3d4046;
            background-color: #f9fafb;
            border-radius: 4px;
          }
          span {
            font-size: 15px;
            font-weight: bold;
            color: #53585f;
          }
        }
      }
    }
    .vertical-line {
      width: 1px;
      background-color: #ebeef4;
    }
    .item-wrapper {
      display: inline-flex;
      width: calc(100% - 260px);
      flex-direction: column;

      .empty-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .title-container {
        width: 100%;
        max-height: 88px;
        border-bottom: solid 1px #ededf2;

        .title-wrapper {
          box-sizing: border-box;
          width: 100%;
          height: 76px;
          display: flex;
          align-items: center;
          gap: 20px;
          justify-content: space-between;
          background-color: #ffffff;
          padding: 20px;
          border-top-right-radius: 8px;

          .title-wrapper-text {
            width: fit-content;
            white-space: nowrap;
          }
          .title-item-wrapper {
            display: flex;
            gap: 16px;

            .bg-pagination {
              margin: 0 !important;
            }
          }
        }
      }
      .image-container {
        height: 100%;
        /* border-left: 1px solid #dce0e8; */
        &.dialog {
          height: 603px;
          overflow-y: scroll;
        }
      }
    }
    .footer-button-wrapper {
      width: 100%;
      height: 76px;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      border-top: solid 1px #ededf2;
    }
  `;
};
