import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { baseCss } from './PreviewYoutubeVideo.style';
import { getUniqKey } from '../../../../utils/array.utils';
import StorageService from '../../../../services/StorageService';
import '@egjs/flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/pagination.css';
import { i18nextScanKey } from '../../../../i18n';

export interface PreviewGoogleItemProps {
  setting: CampaignAdSettingState;
  type?: number;
}

export interface PreviewGoogleTextItemProps {
  type: 'long-title' | 'description' | 'body';
  text: string;
  item:
    | 'aw_long_title'
    | 'aw_short_title'
    | 'aw_description'
    | 'mall_name'
    | 'product_name'
    | 'price'
    | 'category_product_name'
    | 'category_price';
}

const googleCTALabel = {
  shop_now: i18nextScanKey('구매하기'),
  see_more: i18nextScanKey('더보기'),
  book_now: i18nextScanKey('예약하기'),
  learn_more: i18nextScanKey('자세히 알아보기'),
  sign_up: i18nextScanKey('가입하기'),
  visit_site: i18nextScanKey('사이트 방문하기'),
};

export interface SingleImageBoxProps {
  url: string;
  skip?: boolean;
}

const PreviewSingleImageBox = ({ url, skip }: SingleImageBoxProps): ReactElement => {
  const i18next = useTranslation();

  const youtubeThumbnailUrl = `https://i.ytimg.com/vi/${url.replace(
    'https://www.youtube.com/watch?v=',
    '',
  )}/hqdefault.jpg`;

  return (
    <div className={`google-preview-img-box  ${url === null || url === undefined || url === '' ? 'empty' : ''}`}>
      {url && <img src={youtubeThumbnailUrl} alt={i18next.t('이미지')} />}
      {(url === null || url === undefined || url === '') && <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />}
      {skip && <div className="skip">SKIP</div>}
    </div>
  );
};

const PreviewTextItem = ({ type, text, item }: PreviewGoogleTextItemProps): ReactElement => {
  const i18next = useTranslation();

  if (text !== '') {
    return (
      <div className={`google-preview-${type}`}>
        {text.split('\n').map((bodyTxt, index) => {
          return (
            <span key={getUniqKey(bodyTxt, index)}>
              {bodyTxt}
              <br />
            </span>
          );
        })}
      </div>
    );
  }

  return (
    <div className={`google-preview-${type}`}>
      {item === 'aw_long_title' && i18next.t('입력하신 긴 광고 제목이 여기에 보여집니다.')}
      {item === 'aw_short_title' && i18next.t('입력하신 광고 제목이 여기에 보여집니다.')}
      {item === 'aw_description' && i18next.t('입력하신 광고 문구가 여기에 보여집니다.')}
      {item === 'mall_name' && i18next.t('쇼핑몰명')}
      {item === 'product_name' && i18next.t('상품명')}
      {item === 'price' && i18next.t('가격')}
      {item === 'category_product_name' && i18next.t('카테고리에 등록된 상품명')}
      {item === 'category_price' && i18next.t('상품의 가격')}
    </div>
  );
};

export const PreviewYoutubeItem = ({ setting, type }: PreviewGoogleItemProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <React.Fragment>
      {type === 1 && (
        <div className="google-preview-youtube">
          <div className="google-preview-item-wrapper youtube main">
            <div
              className="youtube-header-item"
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <img src="/assets/img/campaign/logo-youtube.svg" alt="Youtube" style={{ width: 108, height: 24 }} />
              <div className="icon-wrap">
                <BDSFontIcon name="ic-push" color="#738696" size="20px" />
                <BDSFontIcon name="ic-search" color="#738696" size="20px" />
                <span
                  className="round-wrap"
                  style={{ width: 42, height: 42, borderRadius: '50%', backgroundColor: '#edf1f6' }}
                >
                  <BDSFontIcon name="ic-user" color="#738696" size="20px" />
                </span>
              </div>
            </div>
            <PreviewSingleImageBox url={setting?.aw_youtube_url} />
            <div className="preview-action-wrapper">
              <div className="preview-action-body">
                <p className="action-description">
                  {setting?.aw_call_to_action && i18next.t(googleCTALabel[setting?.aw_call_to_action])}
                </p>
              </div>
              <div className="preview-action-link">
                <BDSFontIcon name="ic-arrow-right" color="#738696" size="20px" />
              </div>
            </div>
            <div className="preview-body-wrap">
              <PreviewTextItem item="aw_long_title" text={setting?.aw_long_title} type="body" />
              <PreviewTextItem item="aw_description" text={setting?.aw_description} type="body" />
              <div className="google-preview-body">
                <span className="badge">광고</span>{' '}
                {setting?.aw_short_title
                  ? setting?.aw_short_title
                  : i18next.t('입력하신 광고 제목이 여기에 보여집니다.')}
              </div>
            </div>
          </div>
        </div>
      )}
      {type === 2 && (
        <div className="google-preview-youtube">
          <div className="google-preview-item-wrapper youtube play">
            <PreviewSingleImageBox url={setting?.aw_youtube_url} skip />
            <div className="google-preview-youtube-item">
              <div className="google-preview-youtube-ad-profile">
                <div className={`logo ${!StorageService.selectedMall?.square_logo_url ? 'empty' : ''}`}>
                  {StorageService.selectedMall?.square_logo_url ? (
                    <img src={StorageService.selectedMall?.square_logo_url} alt={StorageService.selectedMall?.name} />
                  ) : (
                    ''
                  )}
                </div>
                <div className="google-preview-ad-info">
                  <PreviewTextItem item="mall_name" text={StorageService.selectedMall?.name ?? ''} type="body" />
                  <div className="google-preview-body">
                    <span className="badge">광고</span>{' '}
                    {setting?.url ? setting?.url : i18next.t('버튼 URL이 여기에 보여집니다.')}
                  </div>
                </div>
                <div className="google-preview-action-button">
                  {setting?.aw_call_to_action && i18next.t(googleCTALabel[setting?.aw_call_to_action])}
                </div>
              </div>
              <div className="google-preview-youtube-button">
                <img className="icon" src="/assets/img/card_icons/ic-like.svg" alt={i18next.t('좋아요')} />
                <img className="icon" src="/assets/img/card_icons/ic-dislike.svg" alt={i18next.t('싫어요')} />
                <img className="icon" src="/assets/img/card_icons/ic-share-2.svg" alt={i18next.t('공유하기')} />
                <img className="icon" src="/assets/img/card_icons/ic-short.svg" alt="Youtube Shorts" />
                <img className="icon" src="/assets/img/card_icons/ic-save-2.svg" alt={i18next.t('저장하기')} />
              </div>
              <div className="google-preview-youtube-profile">
                <div className="profile-wrap">
                  <span
                    className="round-wrap"
                    style={{ width: 42, height: 42, borderRadius: '50%', backgroundColor: '#edf1f6' }}
                  >
                    <BDSFontIcon name="ic-user" color="#738696" size="20px" />
                  </span>
                  <span className="google-preview-body">Bigin Ads</span>
                </div>
                <div className="subscribe-wrap">
                  <span className="google-preview-description">{i18next.t('구독중')}</span>
                  <img className="icon" src="/assets/img/card_icons/ic-bell.svg" alt={i18next.t('알림')} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export const PreviewYoutubeVideo = ({ setting, type }: PreviewGoogleItemProps): ReactElement => {
  return (
    <div css={[baseCss]} className="google-preview-item">
      {setting.type === 'aw_youtube' && <PreviewYoutubeItem setting={setting} type={type} />}
    </div>
  );
};
