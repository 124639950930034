import _ from 'lodash';
import React, { useEffect, useRef } from 'react';

const deepCompareEquals = (a: any, b: any) => {
  return _.isEqual(a, b);
};

const useDeepCompareMemoize = (value: any) => {
  const ref = useRef();

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export const useDeepCompareEffect = (callback: React.EffectCallback, dependencies: React.DependencyList) => {
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};
