import React, { useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { range } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { BDSButton } from '@bigin/bigin-ui-components';
import { VideoBox } from '../VideoBox/VideoBox';
import { setVideos } from '../../../recoil/Contents';
import { ProductApi } from '../../../lib/api/ProductApi';
import StorageService from '../../../services/StorageService';
import { ModalAssetUpload } from '../ModalAssetUpload/ModalAssetUpload';
import { WrapperModal } from '../../../components/WrapperModal/WrapperModal';
import { getUniqKey } from '../../../utils/array.utils';
import { baseCss, videoDetailCss } from './VideoManageView.style';

export interface VideoManageViewProps {
  templateType?: ViewType;
  selectType?: SelectType;
  modalClose?: () => void;
  setSelectedVideos?: (videos: Video[]) => void;
}

export const VideoManageView = ({
  templateType = 'page',
  selectType = 'none',
  setSelectedVideos,
  modalClose,
}: VideoManageViewProps): JSX.Element => {
  const [videosState, setVideosState] = useRecoilState(setVideos);
  const [selectedVideoList, setSelectedVideoList] = useState<Video[]>([]);
  const [showModalAssetUpload, setShowModalAssetUpload] = useState(false);
  const [showModalVideoDetail, setShowModalVideoDetail] = useState(false);
  const [curSelectedVideo, setCurSelectedVideo] = useState<Video>();

  const isSeletMode = useMemo(() => {
    return selectType !== 'none' && selectType !== 'detail';
  }, [selectType]);
  const i18next = useTranslation();
  const { refetch: videosRefetch, isFetching: isVideosFetching } = useQuery('fetchVideo', async () => {
    const res = await ProductApi().getVideos(StorageService.selectedMall?.id ?? null);
    setVideosState(res.data.data);
    return res.data.data;
  });

  const handleDelete = async (videoId: number) => {
    await ProductApi()
      .deleteVideo(videoId, StorageService.selectedMall.id)
      .then((res) => {
        setVideosState(videosState.filter((value) => value.id !== res.data.data.id));
        setShowModalVideoDetail(false);
      });
  };

  const deleteSelectVideo = (video: Video) => {
    setSelectedVideoList(selectedVideoList.filter((curVideo) => curVideo !== video));
  };
  const handleClickVideo = (video: Video) => {
    if (selectType === 'single') {
      setSelectedVideoList([video]);
    } else if (selectType === 'multiple') {
      const isInclude = selectedVideoList.includes(video);
      if (isInclude) {
        deleteSelectVideo(video);
      } else {
        setSelectedVideoList([...selectedVideoList, video]);
      }
    } else if (selectType === 'detail') {
      setShowModalVideoDetail(true);
    }
    setCurSelectedVideo(video);
  };

  const isSelectedVideo = (video: Video) => {
    return selectedVideoList.findIndex((curVideo) => curVideo.id === video.id) > -1;
  };

  const renderSkeleton = () => (
    <div style={{ width: '100%', display: 'flex', padding: '20px' }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: '8px', flexWrap: 'wrap' }}>
        {range(30).map((index) => (
          <div
            key={getUniqKey('skeleton-video-box', index)}
            style={{ width: '88px', height: '88px', display: 'inline-flex' }}
          >
            <Skeleton className="skeleton" style={{ width: '88px', height: '88px' }} />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {isVideosFetching && renderSkeleton()}
      {!isVideosFetching && (
        <div css={[baseCss]}>
          <div className="title-wrapper">
            <div className="text-h2 text-color-main">{i18next.t('모든 동영상')}</div>
            <BDSButton
              onClick={() => {
                setShowModalAssetUpload(true);
              }}
              label={i18next.t('파일 추가')}
              appearance="secondary"
            />
          </div>
          <div className={`contents-wrapper ${templateType === 'dialog' && 'dialog'}`}>
            {videosState.map((video) => (
              <VideoBox
                clickVideo={() => handleClickVideo(video)}
                key={video.id}
                video={video}
                selectMode={isSeletMode}
                isSelected={isSelectedVideo(video)}
              />
            ))}
          </div>
          {isSeletMode && setSelectedVideos && (
            <div className="footer-button-wrapper">
              <BDSButton appearance="secondary" style={{ marginRight: '12px' }} onClick={modalClose}>
                {i18next.t('취소')}
              </BDSButton>
              <BDSButton onClick={() => setSelectedVideos(selectedVideoList)}>{i18next.t('등록')}</BDSButton>
            </div>
          )}
        </div>
      )}
      {showModalAssetUpload && (
        <ModalAssetUpload
          isOpen={showModalAssetUpload}
          type="video"
          close={() => {
            setShowModalAssetUpload(false);
          }}
          refetch={videosRefetch}
        />
      )}
      {showModalVideoDetail && curSelectedVideo && (
        <WrapperModal
          position={{ width: '640px' }}
          isOpen={showModalVideoDetail}
          close={() => setShowModalVideoDetail(false)}
        >
          <div css={[videoDetailCss]}>
            <div className="text-h1 text-color-main mb-6">{i18next.t('동영상 정보')}</div>
            <div className="detail-modal-contents">
              <video autoPlay muted playsInline controls width="447">
                <source type="video/mp4" src={curSelectedVideo.video.url} />
              </video>
            </div>
            <div className="detail-modal-footer">
              <div
                className="text-disclaimer d-inline-block"
                style={{ cursor: 'pointer' }}
                onClick={() => handleDelete(curSelectedVideo.video.id)}
              >
                {i18next.t('삭제')}
              </div>
              <BDSButton onClick={() => setShowModalVideoDetail(false)} label={i18next.t('닫기')} />
            </div>
          </div>
        </WrapperModal>
      )}
    </React.Fragment>
  );
};
