import React, { useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme, Theme } from '@emotion/react';
import { toastCSS } from './Toast.style';
import { FontIcon, FontIconName } from '../FontIcon/FontIcon';

export interface ToastProps {
  type: 'basic' | 'button';
  status: 'fail' | 'info' | 'success' | 'warning';
  message: string;
  time: number;
  button: JSX.Element | undefined;
  position: 'top' | 'bottom';
  closeCallBack: () => void;
}

export const Toast: React.FC<ToastProps> = ({
  type = 'basic',
  status = 'fail',
  message = '',
  time = 4000,
  button,
  position: propPosition = 'bottom',
  closeCallBack,
}: ToastProps) => {
  const theme: Theme = useTheme();
  let fontIconName = 'ic-delete-alt';

  if (status === 'info') {
    fontIconName = 'ic-info';
  } else if (status === 'success') {
    fontIconName = 'ic-check';
  } else if (status === 'warning') {
    fontIconName = 'ic-warning';
  }

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
    if (type === 'basic') {
      setTimeout(() => {
        setVisible(false);
      }, time);
      setTimeout(() => {
        closeCallBack();
      }, time + 500);
    }
  }, []);

  const handleClick = () => {
    setVisible(false);
    setTimeout(() => {
      closeCallBack();
    }, 500);
  };

  return (
    <div css={[toastCSS(theme)]}>
      {type === 'basic' ? (
        <div id="toast-loading-page" className={`toast-loading-page ${propPosition} ${visible && 'visible'}`}>
          <div className={`toast-box ${status}`}>
            <div className="icon-wrapper">
              <FontIcon className="toast-icon" size="18px" name={fontIconName as FontIconName} />
            </div>
            <div className="toast-text">{message}</div>
            {button && <div className="button-wrapper">{button}</div>}
          </div>
        </div>
      ) : (
        <div id="btm-toast-loading-page" className={`btm-toast-loading-page ${visible && 'visible'}`}>
          <div className="btm-toast-box info">
            <div className="btm-toast-panel-box">
              <FontIcon className="toast-icon" size="18px" name={fontIconName as FontIconName} />
              <div className="btm-toast-text">{message}</div>
            </div>
            <FontIcon size="16px" name="ic-delete" handleClick={handleClick} />
          </div>
        </div>
      )}
    </div>
  );
};
