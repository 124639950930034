import React, { useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { BDSButton, BDSFontIcon } from '@bigin/bigin-ui-components';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { ImageBox } from '../ImageBox/ImageBox';
import { IMAGE_PROXY_URL } from '../../../utils/utils';
import { getUniqKey } from '../../../utils/array.utils';
import { ModalAssetUpload } from '../ModalAssetUpload/ModalAssetUpload';
import { WrapperModal } from '../../../components/WrapperModal/WrapperModal';
import { ProductApi } from '../../../lib/api/ProductApi';
import StorageService from '../../../services/StorageService';
import Products, { setProducts } from '../../../recoil/Products';
import { ImageEditor } from './ImageEditor/ImageEditor';
import { useDataStore } from '../../../context/Store';
import { baseCss, imageDetailCss, imageEditorCss } from './ProductImageContainer.style';
import { filters } from '../../../utils/filter';
import { CurrencyCodeRecoil } from '../../../recoil/Currency';

export interface ProductImageContainerProps {
  product: Product;
  selectedImageIds: number[];
  selectMode: boolean;
  type?: { type: 'creative' | 'create-campaign'; value: String; ratio: '1:1' | '1.91:1' | '2:1' };
  showDefaultLabel?: boolean;
  isRatioSelectionMode: boolean;
  clickImage: (image: ProductImage, ratio: ImageRatioType) => void;
  isLast?: boolean;
  imageRefetch?: () => void;
}

export const ProductImageContainer = ({
  product,
  selectedImageIds = [],
  selectMode,
  type = { type: 'creative', value: '', ratio: '1:1' },
  showDefaultLabel = false,
  isRatioSelectionMode,
  isLast = false,
  clickImage,
  imageRefetch,
}: ProductImageContainerProps): JSX.Element => {
  const i18next = useTranslation();
  const { dialogStore } = useDataStore();

  const [showAllImages, setShowAllImages] = useState(false);
  const [showModalAssetUpload, setShowModalAssetUpload] = useState(false);
  const [showModalImageDetail, setShowModalImageDetail] = useState(false);
  const [showModalImageEditor, setShowModalImageEditor] = useState(false);
  const [curSelectedImage, setCurSelectedImage] = useState<ProductImage | null>();
  const setProductsStateHandler = useSetRecoilState(setProducts);
  const [defaultProductState, setDefaultProductState] = useRecoilState(Products.defaultProduct);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const checkImageAvailable = (image: ProductImage) => {
    if (type.type === 'create-campaign') {
      if (type.value.includes('aw')) {
        if (type.ratio === '1.91:1') {
          return image.width >= 600 && image.height >= 314;
        }
        return image.width >= 300 && image.height >= 300;
      }
      if (type.value.includes('kk')) {
        if (type.value === 'kk_image_banner') {
          return image.width === 1029 && image.height === 258;
        }
        if (type.value === 'kk_image_native') {
          if (type.ratio === '2:1') {
            return image.width >= 1200 && image.height >= 600;
          }
          return image.width >= 500 && image.height >= 500;
        }
      }
    }

    return type.type === 'creative' || image.is_protected || (image.width >= 600 && image.height >= 600);
  };
  const handleEdit = (image: ProductImage) => {
    setShowModalImageEditor(true);
    setCurSelectedImage(image);
  };
  const closeEdit = () => {
    setShowModalImageEditor(false);
    setCurSelectedImage(null);
  };

  const handleDelete = async () => {
    if (!curSelectedImage) return;
    if (curSelectedImage?.product_id) {
      await ProductApi()
        .deleteImage(StorageService.selectedMall.id, curSelectedImage.product_id, curSelectedImage.id)
        .then((res) => {
          setProductsStateHandler((prev) => {
            const clone = _.cloneDeep(prev);
            const findProduct = clone.find((value) => {
              return value.id === res.data.data.product_id;
            });
            if (findProduct) {
              findProduct.images = findProduct.images.filter((image) => image.id !== res.data.data.id);
            }
            return clone;
          });
          setShowModalImageDetail(false);
        });
    } else {
      await ProductApi()
        .deleteMallUploadImage(StorageService.selectedMall.id, curSelectedImage.id)
        .then((res) => {
          const clone = _.cloneDeep(defaultProductState);
          clone.images = clone.images.filter((image: ProductImage) => image.id !== res.data.data.id);
          setDefaultProductState({
            id: -1,
            name: i18next.t('상품 정보 없는 이미지'),
            images: clone.images as ProductImage[],
          } as Product);
          setShowModalImageDetail(false);
        });
    }
  };

  const handleClickImage = (image: ProductImage, ratio: ImageRatioType) => {
    const isProductImage = product.id !== -1;

    if (!selectMode) {
      setShowModalImageDetail(true);
    }
    setCurSelectedImage(image);

    clickImage(
      {
        ...image,
        name: isProductImage ? product.name : '',
        detail_url: isProductImage ? product.detail_url : '',
        sale_price: isProductImage ? product.sale_price_e6 / 1e6 : 0,
      },
      ratio,
    );
  };

  const images = useMemo(() => {
    if (!product.images) return [];
    if (product.id === -1) return product.images;

    // TODO: 대표이미지
    if (showDefaultLabel) {
      const defaultImage = product.images.find((image) => image.is_default);
      if (defaultImage) {
        const imageSet = [defaultImage, ...product.images];
        const uniqueImageSet = imageSet.filter((item, index) => imageSet.indexOf(item) === index);

        return [...uniqueImageSet];
      }
    }
    return product.images;
  }, [product.images]);

  const ProductImageBox = (image: ProductImage) => {
    const isImageAvailable = checkImageAvailable(image);
    let isSelected: boolean;
    let isClicked: boolean;

    if (isRatioSelectionMode) {
      isSelected = selectedImageIds.some((id, index) => (index !== 2 ? id === image.image_id : false)) as boolean;
      isClicked = selectedImageIds[2] === image.image_id;
    } else {
      isSelected = selectedImageIds.some((id) => id === image.image_id);
      isClicked = false;
    }

    return (
      <ImageBox
        label={showDefaultLabel && image.is_default ? i18next.t('대표') : undefined}
        hover
        editMode
        disabled={isImageAvailable === false}
        selectMode={selectMode}
        type={type}
        onEdit={() => handleEdit(image)}
        clickImage={(ratio: ImageRatioType) => handleClickImage(image, ratio)}
        imageUrl={`${IMAGE_PROXY_URL}${image.url}`}
        isClicked={isClicked}
        isSelected={isSelected}
        isRatioSelectionMode={isRatioSelectionMode}
      />
    );
  };
  const priceInfo = useMemo(() => {
    if (product.base_price_e6 && product.sale_price_e6) {
      return `${i18next.t('정상가')} ${filters.formatCurrency({
        value: product.base_price_e6 / 1e6,
        currencyCode,
      })} / ${i18next.t('할인가')} ${filters.formatCurrency({ value: product.sale_price_e6 / 1e6, currencyCode })}`;
    }
    return i18next.t('가격 정보 없음');
  }, [product]);

  const handleClose = async () => {
    const buttons = [
      {
        label: i18next.t('나가기'),
        handleClick: () => {
          setShowModalImageEditor(false);
          dialogStore.hideDialog();
        },
        appearance: 'secondary',
      },
      { label: i18next.t('계속 편집'), handleClick: () => dialogStore.hideDialog(), appearance: 'secondary' },
    ];

    await dialogStore.showDialog(
      i18next.t('정말 나가시겠어요?'),
      i18next.t('나가게되면 지금까지 작성한 내용이 저장되지 않습니다.'),
      buttons,
    );
  };

  const handleEditImage = ({ image, template }: { image: File; template: string }) => {
    if (!curSelectedImage) return;
    const productId = product ? product.id : -1;
    const originalId = curSelectedImage.original_id ? curSelectedImage.original_id : curSelectedImage.id;

    if (productId === -1) {
      ProductApi()
        .updateMallUploadImage(StorageService.selectedMall.id, image, originalId, template)
        .then(() => {
          if (imageRefetch) {
            imageRefetch();
            closeEdit();
          }
        });
    } else {
      ProductApi()
        .postProductImage(StorageService.selectedMall.id, productId, 'thumbnail', image, originalId, template)
        .then(() => {
          if (imageRefetch) {
            imageRefetch();
            closeEdit();
          }
        });
    }
    closeEdit();
  };

  return (
    <div css={[baseCss]} style={{ borderBottom: `${isLast ? 'none' : '1px solid #dce0e8'}` }}>
      <div className="d-flex mb-2 justify-space-between align-center">
        <div>
          <div className="text-h4 text-color-main">{product.name}</div>
          <div className="text-disclaimer">{priceInfo}</div>
        </div>

        {!showDefaultLabel && (
          <BDSButton
            onClick={() => {
              setShowModalAssetUpload(true);
            }}
            label={i18next.t('파일 추가')}
            appearance="secondary"
          />
        )}
      </div>
      <div className="product-image-container-item">
        <div className="image-wrapper">
          {images.map((image, index) => {
            if (index < 10 || showAllImages) {
              return (
                <React.Fragment key={getUniqKey('product-image', `${image.id}-${index}`)}>
                  {ProductImageBox(image)}
                </React.Fragment>
              );
            }
            return <React.Fragment key={getUniqKey('product-image', index)} />;
          })}
        </div>
        {images.length > 10 && (
          <button type="button" className="more-image-button" onClick={() => setShowAllImages(!showAllImages)}>
            {showAllImages ? (
              <BDSFontIcon name="ic-arrow-left" color="#7e8696" size="20px" />
            ) : (
              <BDSFontIcon name="ic-arrow-right" color="#7e8696" size="20px" />
            )}
          </button>
        )}
      </div>
      {showModalAssetUpload && (
        <ModalAssetUpload
          product={product}
          isOpen={showModalAssetUpload}
          close={() => {
            setShowModalAssetUpload(false);
          }}
        />
      )}
      {showModalImageDetail && curSelectedImage && (
        <WrapperModal
          position={{ width: '640px' }}
          isOpen={showModalImageDetail}
          close={() => setShowModalImageDetail(false)}
        >
          <div css={[imageDetailCss]}>
            <div className="text-h1 text-color-main mb-6">{i18next.t('이미지 정보')}</div>
            <div className="detail-modal-contents">
              <div className="ratio-image-wrapper">
                <div className="text-p3 text-color-main text-bold">{i18next.t('1:1 이미지')}</div>
                <img className="square-image" src={curSelectedImage.url} alt="" />
              </div>
              <div className="ratio-image-wrapper">
                <div className="text-p3 text-color-main text-bold">{i18next.t('1:1.91 이미지')}</div>
                <img className="landscape-image" src={curSelectedImage.url} alt="" />
              </div>
            </div>
            <div className="detail-modal-footer">
              <div className="delete-text" onClick={handleDelete}>
                {i18next.t('삭제')}
              </div>
              <BDSButton onClick={() => setShowModalImageDetail(false)} label={i18next.t('닫기')} />
            </div>
          </div>
        </WrapperModal>
      )}
      {showModalImageEditor && curSelectedImage && (
        <WrapperModal
          position={{ width: '100%', maxWidth: '1320px', padding: '0', maxHeight: '840px' }}
          isOpen={showModalImageEditor}
          close={() => handleClose()}
        >
          <div css={[imageEditorCss]}>
            <div className="text-h1 text-color-main pa-5 border-bottom-divider">{i18next.t('이미지 수정')}</div>
            <div className="editor-modal-contents">
              <ImageEditor
                product={product}
                image={curSelectedImage}
                type={type}
                onEditImage={handleEditImage}
                handleClose={() => handleClose()}
              />
            </div>
          </div>
        </WrapperModal>
      )}
    </div>
  );
};
