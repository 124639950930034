import { css } from '@emotion/react';

export const baseCss = css`
  display: inline-block;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'BiginIcons' !important;
  font-size: 24px;
  letter-spacing: normal;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;

  /* Enable Ligatures ================ */
  -webkit-font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  &.pointer {
    cursor: pointer;
  }
`;
