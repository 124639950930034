import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton } from '@bigin/bigin-ui-components';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import _ from 'lodash';
import { BGUrlSelector } from '../../../../components/BGUrlSelector/BGUrlSelector';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss } from './KakaoImageBanner.style';
import CampaignSetting from '../../../../recoil/CampaignSetting';
import { MaterialCheckboxSelector } from '../../MaterialCheckboxSelector/MaterialCheckboxSelector';
import { ModalImageSelect } from '../../../Creative/ModalImageSelect/ModalImageSelect';
import { InputError } from '../../../../components/InputError/InputError';
import { PreviewKakaoItem } from '../PreviewKakaoItem/PreviewKakaoItem';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { BGTagInput } from '../../../../components/BGTagInput/BGTagInput';
import { AssetHeader } from '../../../../components/AssetHeader/AssetHeader';

export interface KakaoImageBannerProps {
  setting: CampaignAdSettingState;
  handleUpdate: (setting: CampaignAdSettingState) => void;
  handleValid?: (type: CampaignType, isValid: boolean) => void;
  removeSelectedCampaignTypes: (type: CampaignType) => void;
}

interface KakaoImageBannerForm {
  name: string;
  url: string;
  kk_image_id: number | undefined | null;
}

export const KakaoImageBanner = (props: KakaoImageBannerProps): ReactElement => {
  const i18next = useTranslation();

  const [name, setName] = useState(props.setting?.name);
  const [url, setUrl] = useState(props.setting?.url);
  const [biginKeywords, setBiginKeywords] = useState<string[]>(props.setting?.bigin_keywords);
  const [showModalImageSelect, setShowModalImageSelect] = useState<boolean>(false);
  const [valueLock, setValueLock] = useState<boolean>(false);
  const { register, formState, setValue, trigger } = useForm<KakaoImageBannerForm>({ mode: 'onChange' });

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);

  const updateName = (curName: string) => {
    props.handleUpdate({ ...props?.setting, name: curName });
  };

  const updateUrl = (curUrl: string) => {
    props.handleUpdate({ ...props?.setting, url: curUrl });
  };

  const updateBiginKeywords = (curKeywords: string[]) => {
    props.handleUpdate({ ...props?.setting, bigin_keywords: curKeywords });
  };

  const updateSingleImage = (curImage: MallImage | ProductImage) => {
    if ('product_id' in curImage) {
      const newUrl = curImage.product_id ? curImage.detail_url : props.setting?.url || '';

      props.handleUpdate({
        ...props?.setting,
        kk_banner_product_image: curImage,
        kk_banner_product_image_id: curImage.id,
        kk_banner_mall_image: undefined,
        kk_banner_mall_image_id: undefined,
        url: newUrl,
      });
      setUrl(newUrl);
      setValue('url', newUrl);
      trigger();
    } else {
      props.handleUpdate({
        ...props?.setting,
        kk_banner_mall_image: curImage,
        kk_banner_mall_image_id: curImage.id,
        kk_banner_product_image: undefined,
        kk_banner_product_image_id: undefined,
      });
    }
  };

  const selectedImages = useMemo(() => {
    return (props.setting.kk_banner_mall_image || props.setting.kk_banner_product_image || []) as ImageTypes[];
  }, [props.setting]);

  const handleSelectImages = (images: ImageTypes[]) => {
    updateSingleImage(images[0] as ProductImage);
    setShowModalImageSelect(false);
    setValue('kk_image_id', images[0].id, { shouldValidate: true });
  };

  useEffect(() => {
    if (name !== props.setting?.name) {
      updateName(name);
    }

    if (!valueLock) {
      if (url !== props.setting?.url) {
        updateUrl(url);
      }
    }

    if (!_.isEqual(biginKeywords, props.setting?.bigin_keywords)) {
      updateBiginKeywords(biginKeywords);
    }

    setValueLock(false);
  }, [name, url, biginKeywords]);

  useEffect(() => {
    register('url', {
      value: url,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isURL(value)) {
          return i18next.t('잘못된 URL 형식입니다.') as string;
        }

        return true;
      },
    });

    register('kk_image_id', {
      value: (props.setting?.kk_banner_mall_image || props.setting?.kk_banner_product_image)?.id,
      required: i18next.t('필수 입력란입니다.') as string,
    });
  }, []);

  useEffect(() => {
    if (props.handleValid) {
      props.handleValid(props.setting?.type, formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <BorderSection>
      <div className="fb-single-image-setting-wrapper" css={[baseCss]}>
        <AssetHeader
          title={i18next.t('비즈보드')}
          icons={[{ imgSrc: '/assets/img/card_icons/ic-channel-kakao.png', alt: 'Kakao' }]}
          handleIconClick={() => {
            props.removeSelectedCampaignTypes(props.setting.type);
          }}
        />
        <div className="create-section-content-wrapper">
          <div className="create-section-content">
            <div className="preview-container">
              <div className="sticky-wrap">
                <div className="title-panel">
                  <div className="title">{i18next.t('미리보기')}</div>
                  <div className="info">{i18next.t('*실제 노출과 미리보기가 다를 수 있습니다.')}</div>
                </div>
                <div className="material-preview-wrapper">
                  <div className="material-preview">
                    <div className="kakao-wrapper">
                      <PreviewKakaoItem setting={props.setting} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <h2 className="material-field-header">{i18next.t('광고 소재명')}</h2>
              <div className="material-field-selector-wrapper">
                <BGInput
                  style={{ width: '100%' }}
                  inputProps={{
                    name: 'name',
                    placeholder: i18next.t('광고 소재 이름을 입력해주세요.'),
                    value: name || '',
                    onChange: (event) => {
                      setName(event.target.value);
                    },
                  }}
                />
                <p className="description">{i18next.t('*다른 소재와 구분지을 수 있게 이름을 입력하세요.')}</p>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 이미지')}</h2>
              <div className="material-field-selector-wrapper">
                <div className="content-upload-container">
                  <div className="single-image-uploader-wrapper uploader-wrapper">
                    <div className="image-uploader-title-wrapper">
                      <div className="image-uploader-subtitle">
                        <div>{i18next.t('* 권장 사이즈: ')} 1029px*258px</div>
                        <div>{i18next.t('* 파일 형식: ')} PNG-24</div>
                        <div>{i18next.t('* 파일 용량: {{size}}MB 이내', { size: 30 })}</div>
                        <div>
                          {i18next.t('이미지 제작에 대한 더 자세한 안내는 ')}
                          <a
                            style={{ color: '#777788' }}
                            href="https://kakaobusiness.gitbook.io/main/ad/moment/start/talkboard/content-guide"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {i18next.t('비즈보드 제작 가이드')}
                          </a>
                          {i18next.t('를 확인 바랍니다.')}
                        </div>
                      </div>
                    </div>
                    <div className="single-image-uploader-wrapper file-wrapper">
                      <BDSButton
                        className="image-updater-btn"
                        onClick={() => {
                          setShowModalImageSelect(true);
                          setValueLock(true);
                        }}
                        label={i18next.t('업로드')}
                      />
                      {props.setting.kk_banner_product_image && props.setting.kk_banner_product_image.name && (
                        <div className="file-name">{props.setting.kk_banner_product_image.name}</div>
                      )}
                      {props.setting.kk_banner_mall_image && (
                        <div className="file-name">{i18next.t('상품 정보 없음')}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 텍스트')}</h2>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('버튼 URL')} checkboxLabel="" value hiddenCheckbox />
                <BGUrlSelector
                  linkButton
                  validationTrackOn
                  isValid={!formState.errors.url}
                  url={url || ''}
                  handleUpdate={(curUrl: string) => {
                    setValue('url', curUrl, { shouldValidate: true });
                    setUrl(curUrl);
                  }}
                  style={{ marginTop: '8px' }}
                  placeholder="www.bigin.io"
                />
                {formState.errors.url && <InputError message={formState.errors.url.message as string} />}
              </div>
              {campaignState.isBiginConnected && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('연계 캠페인')}</h2>
                  <div className="material-field-selector-wrapper">
                    <MaterialCheckboxSelector
                      contentTitle={i18next.t('핵심 키워드')}
                      checkboxLabel=""
                      value
                      hiddenCheckbox
                    />
                    <BGTagInput
                      style={{ width: '100%', marginTop: '8px' }}
                      inputProps={{
                        name: 'bigin_keywords',
                        placeholder: i18next.t(
                          'Bigin CRM에서 해당 광고 소재를 검색할 때 사용할 키워드를 입력해주세요.',
                        ),
                      }}
                      handleUpdate={(keywords) => {
                        setBiginKeywords(keywords);
                      }}
                      defaultTags={biginKeywords}
                      duplicateText={i18next.t('* 이미 등록된 키워드입니다.')}
                    />
                    <p className="description">
                      {i18next.t('*핵심 키워드는 선택사항이며, 최대 5개까지 입력할 수 있습니다.')}
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModalImageSelect && (
        <ModalImageSelect
          selectType="single"
          selectedImages={selectedImages}
          campaignType={props.setting.type}
          isOpen={showModalImageSelect}
          close={() => setShowModalImageSelect(false)}
          setSelectedImages={handleSelectImages}
        />
      )}
    </BorderSection>
  );
};
