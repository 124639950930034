import { i18nextScanKey } from '../i18n';

export const campaignTypeText: { [key: string]: string } = {
  fb_single_image: i18nextScanKey('단일 이미지'),
  fb_slide_image: i18nextScanKey('슬라이드 이미지'),
  aw_responsive_image: i18nextScanKey('반응형 이미지'),
  kk_image_banner: i18nextScanKey('비즈보드'),
  kk_image_native: i18nextScanKey('네이티브 디스플레이'),
  fb_single_video: i18nextScanKey('단일 동영상'),
  aw_youtube: i18nextScanKey('Youtube 광고'),
  fb_catalog_slide: i18nextScanKey('카탈로그 슬라이드'),
  fb_collection_image: i18nextScanKey('컬렉션 광고'),
  fb_collection_video: i18nextScanKey('컬렉션 광고'),
  fb_collection_dynamic_video: i18nextScanKey('컬렉션 광고'),
  aw_shopping: i18nextScanKey('쇼핑 광고'),
  aw_smart_shopping: i18nextScanKey('쇼핑 광고'),
  fb_dynamic: i18nextScanKey('다이나믹 컬렉션/슬라이드'),
  fb_dynamic_image: i18nextScanKey('다이나믹 컬렉션'),
  fb_dynamic_video: i18nextScanKey('다이나믹 컬렉션'),
  fb_dynamic_dynamic_video: i18nextScanKey('다이나믹 컬렉션'),
  fb_dynamic_slide: i18nextScanKey('다이나믹 슬라이드'),
  aw_dynamic_remarketing: i18nextScanKey('동적 리마케팅'),
  aw_pmax: i18nextScanKey('실적 최대화'),
  aw_search: i18nextScanKey('구글 검색'),
};

export const goalTypeText: { [key: string]: string } = {
  maximize_clicks: i18nextScanKey('유입수 극대화'),
  revisit_leads: i18nextScanKey('재방문 유도'),
  buying_acquisition: i18nextScanKey('유효 타겟 확장'),
  purchase_leads: i18nextScanKey('구매 활성화'),
};

export const revisitOptimizeTypeText: { [key: string]: string } = {
  view_content_or_interest: i18nextScanKey('상품 조회 등 관심 유도 목적'),
  purchase: i18nextScanKey('구매 전환 목적'),
};

export const targetExcludeTypeText: { [key: string]: string } = {
  purchase_30days: i18nextScanKey('최근 1개월 구매자 제외'),
  purchase_180days: i18nextScanKey('최근 6개월 구매자 제외'),
  page_view_180days: i18nextScanKey('최근 6개월 방문자 제외'),
  not_exclude: i18nextScanKey('제외 없음'),
};

export const involvementTypeText: { [key: string]: string } = {
  low: i18nextScanKey('저관여 고객'),
  high: i18nextScanKey('고관여 고객'),
  purchased: i18nextScanKey('구매 고객'),
};

export const targetingTypeText: { [key: string]: string } = {
  none: i18nextScanKey('인구 통계 타겟팅'),
  interest: i18nextScanKey('관심사 타겟팅'),
  page_view_180days: i18nextScanKey('사이트 방문자 (최근 6개월)'),
  lookalike_1percent_view_content_180days: i18nextScanKey('방문자 유사 타겟팅'),
  lookalike_1percent_view_content_180days_not_purchase_30days:
    i18nextScanKey('방문자 유사 타겟팅 (최근 1개월 구매자 제외)'),
  none_not_purchase_30days: i18nextScanKey('인구 통계 타겟팅 (최근 1개월 구매자 제외)'),
  interest_not_purchase_30days: i18nextScanKey('관심사 타겟팅 (최근 1개월 구매자 제외)'),
  lookalike_1percent_view_content_180days_not_purchase_180days:
    i18nextScanKey('방문자 유사 타겟팅 (최근 6개월 구매자 제외)'),
  none_not_purchase_180days: i18nextScanKey('인구 통계 타겟팅 (최근 6개월 구매자 제외)'),
  interest_not_purchase_180days: i18nextScanKey('관심사 타겟팅 (최근 6개월 구매자 제외)'),
  lookalike_1percent_view_content_180days_not_page_view_180days:
    i18nextScanKey('방문자 유사 타겟팅 (최근 6개월 방문자 제외)'),
  none_not_page_view_180days: i18nextScanKey('인구 통계 타겟팅 (최근 6개월 방문자 제외)'),
  interest_not_page_view_180days: i18nextScanKey('관심사 타겟팅 (최근 6개월 방문자 제외)'),
  view_content_180days_not_add_to_cart_180days: i18nextScanKey('저관여 고객 타겟팅'),
  add_to_cart_180days_not_purchase_180days: i18nextScanKey('고관여 고객 타겟팅'),
  purchase_180days: i18nextScanKey('구매자 타겟팅'),
  affinity_audience: i18nextScanKey('잠재 고객 타겟팅'),
  in_market_audience: i18nextScanKey('구매 의도 잠재 고객 타겟팅'),
  adwords_optimized_list: i18nextScanKey('유사 타겟팅'),
  adwords_optimized_list_not_visitor_180day: i18nextScanKey('유사 타겟팅 (최근 6개월 방문자 제외)'),
  in_market_not_visitor_180day: i18nextScanKey('구매 의도 잠재 고객 타겟팅 (최근 6개월 방문자 제외)'),
  none_not_visitor_180day: i18nextScanKey('인구 통계 타겟팅 (최근 6개월 방문자 제외)'),
  adwords_optimized_list_not_purchase_30day: i18nextScanKey('유사 타겟팅 (최근 1개월 구매자 제외)'),
  in_market_not_purchase_30day: i18nextScanKey('구매 의도 잠재 고객 타겟팅 (최근 1개월 구매자 제외)'),
  none_not_purchase_30day: i18nextScanKey('인구 통계 타겟팅 (최근 1개월 구매자 제외)'),
  adwords_optimized_list_not_purchase_180day: i18nextScanKey('유사 타겟팅 (최근 6개월 구매자 제외)'),
  in_market_not_purchase_180day: i18nextScanKey('구매 의도 잠재 고객 타겟팅 (최근 6개월 구매자 제외)'),
  none_not_purchase_180day: i18nextScanKey('인구 통계 타겟팅 (최근 6개월 구매자 제외)'),
  view_content_180day_not_add_to_cart_180day: i18nextScanKey('저관여 고객 타겟팅'),
  add_to_cart_180day_not_purchase_180day: i18nextScanKey('고관여 고객 타겟팅'),
  purchase_180day: i18nextScanKey('구매자 타겟팅'),
};

export const adFieldText: { [key: string]: string } = {
  fb_body: i18nextScanKey('광고 문구'),
  fb_single_title: i18nextScanKey('광고 제목'),
  fb_single_description: i18nextScanKey('광고 설명'),
  fb_collection_title: i18nextScanKey('컬렉션 제목'),
  fb_dynamic_collection_body: i18nextScanKey('컬렉션 광고 문구'),
  fb_dynamic_catalog_body: i18nextScanKey('슬라이드 광고 문구'),
  fb_dynamic_slide_title: i18nextScanKey('제목 템플릿'),
  fb_dynamic_slide_description: i18nextScanKey('내용 템플릿'),
  category_id: i18nextScanKey('광고 상품 카테고리'),
  mall_video_id: i18nextScanKey('광고 소재 동영상'),
  src: i18nextScanKey('광고 소재 이미지'),
  slides: i18nextScanKey('광고 소재 이미지'),
  url: 'URL',
  aw_short_title: i18nextScanKey('광고 제목'),
  aw_long_title: i18nextScanKey('긴 광고 제목'),
  aw_description: i18nextScanKey('광고 문구'),
  aw_youtube_url: i18nextScanKey('유튜브 광고 소재'),
  fb_call_to_action: i18nextScanKey('행동 유도 버튼'),
  aw_call_to_action: i18nextScanKey('행동 유도 버튼'),
  kk_action_button: i18nextScanKey('행동 유도 버튼'),
  kk_title: i18nextScanKey('광고 제목'),
  aw_pmax_long_headlines: i18nextScanKey('긴 광고 제목'),
  aw_pmax_headlines: i18nextScanKey('광고 제목'),
  aw_pmax_descriptions: i18nextScanKey('광고 문구'),
};

export const campaignNamingMediaRule: { [key: string]: string } = {
  facebook: 'FB',
  google: 'GA',
  youtube: 'YT',
  kakao: 'KA',
  naver: 'NA',
};

export const campaignNamingGenderRule: { [key: string]: string } = {
  women: 'W',
  men: 'M',
};

export const templateTableHeaderText: { [key: string]: string } = {
  product_name: i18nextScanKey('상품명'),
  price: i18nextScanKey('할인가'),
  copy: i18nextScanKey('광고 문구'),
};

// 결제내역 상세
i18nextScanKey('무통장 입금');
i18nextScanKey('카드 결제');
i18nextScanKey('사용액 차감');
i18nextScanKey('전액 환불');
i18nextScanKey('부분 환불');
i18nextScanKey('월별 사용액 보정');
i18nextScanKey('프로모션');
i18nextScanKey('티켓 구매');

// 업종 카테고리
i18nextScanKey('플랫폼');
i18nextScanKey('쇼핑정보');
i18nextScanKey('가격비교');
i18nextScanKey('리스 / 렌탈');

i18nextScanKey('리빙');
i18nextScanKey('종합가구');
i18nextScanKey('침대 / 매트리스');
i18nextScanKey('조명');
i18nextScanKey('욕실');
i18nextScanKey('주방');
i18nextScanKey('식품');
i18nextScanKey('보충제 / 건강식 / 영양식');
i18nextScanKey('임신 / 출산');
i18nextScanKey('유아 / 어린이');
i18nextScanKey('홈 패브릭');
i18nextScanKey('인테리어');
i18nextScanKey('생활 용품');

i18nextScanKey('뷰티 스킨케어');
i18nextScanKey('스킨케어 / 클렌징');
i18nextScanKey('향수');
i18nextScanKey('메이크업');
i18nextScanKey('헤어 / 바디');
i18nextScanKey('기능성');
i18nextScanKey('남성화장품');

i18nextScanKey('패션의류/잡화');
i18nextScanKey('남성의류');
i18nextScanKey('여성의류');
i18nextScanKey('애슬레져');
i18nextScanKey('쥬얼리');
i18nextScanKey('유아동 의류');
i18nextScanKey('신발');
i18nextScanKey('가방');
i18nextScanKey('패션잡화');

i18nextScanKey('취미용품');
i18nextScanKey('실내 용품');
i18nextScanKey('실외 용품');
i18nextScanKey('게임기 / CD');
i18nextScanKey('프라모델 / RC완구');
i18nextScanKey('퍼즐 / 큐브 / 피젯토이');
i18nextScanKey('도서 / 음반 / 악기');
i18nextScanKey('유아동');
i18nextScanKey('게임 용품');
i18nextScanKey('키덜트 샵');

i18nextScanKey('스포츠용품');
i18nextScanKey('축구 / 야구 / 농구');
i18nextScanKey('낚시 용품');
i18nextScanKey('골프 용품');
i18nextScanKey('등산 용품');
i18nextScanKey('스포츠 의류');
i18nextScanKey('수영복 / 래쉬가드');
i18nextScanKey('피트니스 용품');
i18nextScanKey('기타 스포츠 용품');

i18nextScanKey('자동차 용품');
i18nextScanKey('인테리어 / 디퓨저');
i18nextScanKey('안전 / 편의용품');
i18nextScanKey('튜닝용품');
i18nextScanKey('관리 / 오일 용품');
i18nextScanKey('세차용품');

i18nextScanKey('가전 디지털');
i18nextScanKey('대형 가전');
i18nextScanKey('계절 가전');
i18nextScanKey('tv / 영상가전');
i18nextScanKey('음향 가전');
i18nextScanKey('미용 가전');
i18nextScanKey('주방 가전');
i18nextScanKey('pc / 노트북');
i18nextScanKey('pc 주변기기');
i18nextScanKey('휴대폰 / 태블릿');
i18nextScanKey('기타 가전');

i18nextScanKey('반려동물');
i18nextScanKey('강아지');
i18nextScanKey('고양이');
i18nextScanKey('그 외 반려동물');
