import React, { ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import axios from 'axios';

import { baseCss } from './NewMall.style';
import { Badge } from '../../components/Badge/Badge';
import { FormMallUrl, FormMallUrlForm } from '../../components/FormMallUrl/FormMallUrl';
import { BGButtonGroup } from '../../components/BGButtonGroup/BGButtonGroup';
import { BGButton } from '../../components/BGButton/BGButton';
import { FormMall, FormMallForm } from '../../components/FormMall/FormMall';
import { MallApi } from '../../lib/api/MallApi';
import { UserApi } from '../../lib/api/UserApi';
import { useDataStore } from '../../context/Store';
import { LogoFrame } from '../../components/LogoFrame/LogoFrame';
import { BorderSection } from '../../components/BorderSection/BorderSection';

enum STEP {
  STEP1 = 1,
  STEP2 = 2,
}

export const NewMall = (): ReactElement => {
  const i18next = useTranslation();
  const navigate = useNavigate();
  const { dialogStore } = useDataStore();

  const STEPS = 2;

  const company = useQuery<Company>('company', async () => (await UserApi().getCompany()).data.data);
  const classifications = useQuery<MallClassification[]>(
    'classifications',
    async () => (await MallApi().getMallClassifications()).data.data,
    { enabled: false },
  );

  const [step, setStep] = useState<STEP>(STEP.STEP1);
  const [url, setUrl] = useState<string>('');
  const [isStep1Valid, setIsStep1Valid] = useState<boolean>(false);
  const [isStep2Loading, setIsStep2Loading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [hosting, setHosting] = useState<Hosting>('etc');
  const [classificationId, setClassificationId] = useState<number | undefined>(undefined);
  const [monthlyBudget, setMonthlyBudget] = useState<string | undefined>(undefined);
  const [isStep2Valid, setIsStep2Valid] = useState<boolean>(false);

  const prev = () => {
    if (step === STEP.STEP1) {
      navigate(-1);

      return;
    }

    setStep(step - 1);
  };

  const onStep1Changed = (form: Partial<FormMallUrlForm>) => {
    if (form.url !== undefined && form.url !== url) {
      setUrl(form.url);
    }
  };

  const onStep2Changed = (form: Partial<FormMallForm>) => {
    if (form.name !== undefined && form.name !== name) {
      setName(form.name);
    }
    if (form.hosting !== undefined && form.hosting !== hosting) {
      setHosting(form.hosting);
    }
    if (form.classificationId !== undefined && form.classificationId !== classificationId) {
      setClassificationId(form.classificationId);
    }
    if (form.monthlyBudget !== undefined && form.monthlyBudget !== monthlyBudget) {
      setMonthlyBudget(form.monthlyBudget);
    }
  };

  const onStep1Submit = async (form: FormMallUrlForm) => {
    setStep(step + 1);

    setIsStep2Loading(true);
    return Promise.all([UserApi().getSiteUrlInfo(form.url), classifications.refetch()])
      .then((responses) => {
        const site = responses[0].data.data;
        setName(site.name);
        setHosting(site.hosting as Hosting);
      })
      .finally(() => {
        setIsStep2Loading(false);
      });
  };

  const onStep2Submit = async (form: FormMallForm) => {
    if (company.data === undefined) {
      return dialogStore.showMessage(i18next.t('알림'), i18next.t('초기화 중입니다.\n잠시 후 다시 시도해주세요.'));
    }

    return MallApi()
      .registrationMall(company.data.id, {
        url,
        name: form.name,
        hosting: form.hosting,
        classification_id: form.classificationId as number,
        extra_json: JSON.stringify({
          monthly_budget: form.monthlyBudget,
        }),
      })
      .then((response) => {
        const mall = response.data.data;

        navigate(`/malls/${mall.id}/plans/recommendation/all`);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <div css={[baseCss]}>
      <LogoFrame>
        <BorderSection style={{ padding: '20px', width: '320px' }}>
          <div className="new-mall-header">
            <div className="title-wrapper">
              <div className="text-h1 text-color-main">{i18next.t('새로운 쇼핑몰 추가')}</div>
              <Badge>
                <span>{`${step}/${STEPS}`}</span>
              </Badge>
            </div>
            <div className="text-input text-color-main">
              <p>
                {step === STEP.STEP1 && i18next.t('마케팅을 진행할 새로운 쇼핑몰 URL을 입력하세요.')}
                {step === STEP.STEP2 &&
                  (isStep2Loading
                    ? i18next.t('{{url}}을 분석중입니다.\n잠시만 기다려주세요.', { url })
                    : i18next.t(
                        '{{url}}의 분석을 완료했습니다.\n아래의 정보를 확인하시고 업종 카테고리 및 월 광고 예산을 선택해주세요.',
                        { url },
                      ))}
              </p>
            </div>
          </div>
          {step === STEP.STEP1 && (
            <FormMallUrl
              onIsValidChanged={setIsStep1Valid}
              onSubmit={onStep1Submit}
              url={url}
              onChanged={onStep1Changed}
            >
              <BGButtonGroup rightAlign>
                <BGButton appearance="secondary" label={i18next.t('이전')} hoverable onClick={prev} />
                <BGButton type="submit" label={i18next.t('다음')} hoverable isDisabled={!isStep1Valid} />
              </BGButtonGroup>
            </FormMallUrl>
          )}
          {step === STEP.STEP2 && (
            <FormMall
              mode="new-mall"
              classifications={classifications.data}
              name={name}
              hosting={hosting}
              classificationId={classificationId}
              monthlyBudget={monthlyBudget}
              isLoading={isStep2Loading}
              onChanged={onStep2Changed}
              onIsValidChanged={setIsStep2Valid}
              onSubmit={onStep2Submit}
            >
              <BGButtonGroup rightAlign>
                <BGButton appearance="secondary" label={i18next.t('이전')} hoverable onClick={prev} />
                <BGButton type="submit" label={i18next.t('쇼핑몰 추가')} hoverable isDisabled={!isStep2Valid} />
              </BGButtonGroup>
            </FormMall>
          )}
        </BorderSection>
      </LogoFrame>
    </div>
  );
};
