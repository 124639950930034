import { css, useTheme, Theme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    border: solid 1px #dce0e8;
    background-color: #fff;

    .kakao-preview-item-wrapper {
      display: flex;
      width: 375px;
      flex-direction: column;
      padding: 16px;

      .kakao-header-wrapper {
        border-bottom: solid 1px #dce0e8;
      }

      .kakao-header-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        .header-text {
          ${theme.text.h2};
          color: ${theme.colors.mainFont};
        }
        .icon-wrap {
          display: flex;
          gap: 8px;
          align-items: center;

          .icon {
            width: 20px;
            height: 20px;
          }
        }
      }
      .kakao-title-box {
        display: flex;
        margin: 12px 0 8px;
        justify-content: space-between;
        align-items: center;
        .profile-wrap {
          display: flex;
          gap: 8px;
          .logo {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            overflow: hidden;
            border: solid 1px #edf1f6;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          .media-title-wrapper {
            .title {
              ${theme.text.h5};
              color: ${theme.colors.mainFont};
            }
            .info {
              ${theme.text.disclaimer};
            }
          }
        }
      }

      .kakao-header-tab {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 0 16px 12px;
        .tab-skeleton {
          width: 40px;
          height: 14px;

          background-color: #edf1f6;
        }
      }
      .kakao-preview-title-wrapper {
        width: 100%;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        .kakao-title {
          ${theme.text.input};
          color: ${theme.colors.mainFont};
        }
      }

      .kakao-preview-action-button {
        ${theme.text.p3};
        color: ${theme.colors.mainFont};
        padding: 4px 8px;
        border-radius: 4px;
        border: 1px solid #a5adb2;
      }
      .kakao-preview-item-image {
        width: 100%;
        height: 172px;

        .preview-img-box {
          width: 100%;
          background-color: ${theme.colors.bg3};
          overflow: hidden;
          position: relative;
          box-sizing: border-box;
          height: 100%;
          user-select: none;

          &.empty {
            /* padding: 47%; */
            .font-icon-panel {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          img {
            width: 100%;
            height: 100%;
            vertical-align: top;
            position: absolute;
            top: 0;
            left: 0;
            user-select: none;
            -webkit-user-drag: none;
            -khtml-user-drag: none;
            -moz-user-drag: none;
            -o-user-drag: none;
          }
          .icon-play {
            position: absolute;
            top: 45%;
            left: 45%;
            width: 40px;
            height: 40px;
          }
        }
        .preview-img-box:after {
          padding-bottom: 100%;
        }
      }
      .kakao-preview-item-chat-wrap {
        margin-bottom: 68px;
        .chat-item {
          display: flex;
          margin-top: 20px;

          .circle-skeleton {
            width: 48px;
            height: 48px;
            background-color: #edf1f6;
            border-radius: 50%;
            margin-right: 8px;
          }
          .text-skeleton {
            background-color: #edf1f6;
          }
        }
      }
    }
  `;
};
