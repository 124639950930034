import { i18nextScanKey } from '../i18n/index';

const timesInsights = [
  'actions',
  'actions_7d_click',
  'actions_1d_view_7d_click',
  'impressions',
  'frequency',
  'inline_link_clicks',
  'reach',
];
const currencyInsights = [
  'cpm',
  'cpa',
  'cpa_7d_click',
  'cpa_1d_view_7d_click',
  'spend',
  'cpc',
  'inline_link_click_cpc',
  'mall_total_fee',
  'mall_total_spend',
  'fee',
  'extra',
  'avg_extra',
  'avg_spend',
  'action_values_7d_click',
  'action_values_1d_view_7d_click',
];
const percentInsights = [
  'cvr',
  'cvr_7d_click',
  'cvr_1d_view_7d_click',
  'roas',
  'roas_7d_click',
  'roas_1d_view_7d_click',
  'ctr',
  'inline_link_click_ctr',
  'retention',
  'avg_ctr',
  'avg_roas',
];

// eslint-disable-next-line consistent-return
export const getInsightType = (insight: string) => {
  if (timesInsights.includes(insight)) {
    return 'Number';
  }
  if (currencyInsights.includes(insight)) {
    return 'Currency';
  }
  if (percentInsights.includes(insight)) {
    return 'Percent';
  }

  return '';
};

export const getUnitText = (insight: string) => {
  if (getInsightType(insight) === 'Number') {
    return i18nextScanKey('회');
  }
  if (getInsightType(insight) === 'Currency') {
    return i18nextScanKey('원');
  }
  if (getInsightType(insight) === 'Percent') {
    return '%';
  }

  return '';
};
