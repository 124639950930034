import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import _ from 'lodash';
import moment from 'moment';
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { CampaignChartStyle } from './CampaignChart.style';

import { LineChart } from '../LineChart/LineChart';
import { BGLoadingSpinner } from '../BGLoadingSpinner/BGLoadingSpinner';
import { DateRange } from '../Calendar/Calendar';
import { chartColors } from '../../Theme';
import { Dimension, Metric } from '../../object-model/model';
import { BGLegend, Legend, LegendList } from '../BGLegend/BGLegend';
import { BgChartTooltip, ChartTooltipConfig } from '../BgChartTooltip/BgChartTooltip';
import { FetchQueryFieldFilter } from '../../pages/CampaignReport/CampiagnReportDef';

interface FetchedEvent {
  list: Array<{ event: { [prop: string]: string } }>;
}

export interface CampaignChartProps {
  data?: any;
  config: ChartConfig;
  duration?: DateRange;
  compareDuration?: DateRange;
  fetchedLegendsData?: any;
  isSimpleLegend?: boolean;
  disabledLegend?: boolean;
  parentFilterSetting?: { [prop: string]: string[] };
  emptyComponent?: JSX.Element;
  chartLoading?: boolean;
  handleChartLoading?: (sw: boolean) => void;
}

export interface ChartField extends Metric {
  filters?: FetchQueryFieldFilter[];
  color?: string;
  active?: boolean;
  datasource?: string;
  campaign?: string; // Message Campaign
  status?: string; // Message Campaign
  charge?: number; // Message Campaign
  conversion?: {
    goalEvent: string;
    value: number;
    trackingTime: number;
    filters: FetchQueryFieldFilter[];
    type?: string;
    utm?: string;
    tracking_event?: string;
  };
  campaignChannel?: string;
}

export interface ChartConfig {
  metrics: ChartField[];
  selectedChart: string;
  width?: number;
  height?: number;
  disabledTitle?: boolean;
  // hierarchy 구조 상에서 몇 번째 depth 의 node들을 노출 시킬지 값.
  // root 가 1, leaf 가 1 이상이 값.
  activeDepth?: number;
  tooltipType?: string;
}

const styleCss = CampaignChartStyle;

export const CampaignChart = (props: CampaignChartProps): ReactElement => {
  const { i18n, ...i18next } = useTranslation();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [selectedChartDuration, setSelectedChartDuration] = useState('day');
  const [chartConfig, setChartConfig] = useState<any>({});
  const [legendLists, setLegendLists] = useState<LegendList[]>();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [tooltipConfig, setTooltipConfig] = useState<ChartTooltipConfig>();
  const legendLimit = 40;
  const { config } = props;

  const metric = config.metrics && config.metrics[0];
  const chartBodyRef = useRef<HTMLDivElement>(null);

  let legendMore = false;

  const fetchData = async () => {
    const list: any[] = [];
    props.data.map((value: any) => list.push(value));
    return { list };
  };

  const findColor = (legendItem: any, itemName: string) => {
    if (!legendItem) return chartColors[0];
    let foundLegend;
    if (legendItem.legends) foundLegend = _.find(legendItem.legends, { name: itemName });
    return foundLegend && foundLegend.color ? foundLegend.color : chartColors[0];
  };

  const drawLine = (dataset: any, legend: LegendList[], compare?: any) => {
    const result: any = [];
    let start = compare ? props!.compareDuration!.start : props!.duration!.start;
    let end = compare ? props!.compareDuration!.end : props!.duration!.end;
    start = moment(start).startOf('day');
    end = moment(end).endOf('day');

    let defaultDays = moment(end).diff(start, 'days', true);
    let compareDays;
    if (props!.compareDuration) {
      compareDays = moment(props!.compareDuration!.end).diff(props!.compareDuration!.start, 'days', true);
      defaultDays = defaultDays > compareDays ? defaultDays : compareDays;
    }

    if (selectedChartDuration === 'weeks')
      defaultDays = moment(end).diff(moment(start).startOf('weeks'), 'weeks', true);
    if (selectedChartDuration === 'months') defaultDays = moment(end).diff(start, 'months', true);
    defaultDays = Math.ceil(defaultDays);

    if (dataset && dataset.list)
      props.config.metrics.forEach((metricItem: any) => {
        const dimensionProps = _.map(metricItem.dimensions, 'prop');
        const dayLoop = (legendItem?: any) => {
          for (let day = 0; day < defaultDays; day += 1) {
            let key: any;
            let timestamp;
            let displayName;
            switch (selectedChartDuration) {
              case 'day': {
                key = moment(start).add(day, 'days').format('YYYY-MM-DD');
                displayName = moment(key).format('YYYY.MM.DD');
                timestamp = key;
                break;
              }
              case 'weeks': {
                let changeStart = start;
                if (moment(start).day() === 0) {
                  changeStart = moment(start).add(1, 'days');
                }
                key = moment(changeStart)
                  .add(day + 1, 'week')
                  .format('YYYYWW');
                timestamp = moment(start).startOf('week').add(day, 'weeks').format('YYYY.MM.DD');
                displayName = `${moment(start).add(day, 'week').startOf('weeks').format('YYYY.MM.DD')} ~ ${moment(start)
                  .add(day, 'weeks')
                  .endOf('weeks')
                  .format('YYYY.MM.DD')}`;
                break;
              }
              case 'months': {
                key = moment(start).startOf('months').add(day, 'months').format('YYYYMM');
                timestamp = moment(start).startOf('months').add(day, 'months').format('YYYY.MM.DD');
                displayName = `${moment(start).add(day, 'months').startOf('months').format('YYYY.MM.DD')} ~ ${moment(
                  start,
                )
                  .add(day, 'months')
                  .endOf('months')
                  .format('YYYY.MM.DD')}`;
                break;
              }
              default:
                break;
            }

            const record = _.find(dataset.list, (data: any) => {
              if (!legend || legend.length === 0) {
                return data.timestamp.toString() === key && data.event[metricItem.prop];
              }
              let validCount = 0;
              _.forEach(dimensionProps, (prop) => {
                if (data.event[prop] === legendItem[prop]) validCount += 1;
              });
              return (
                data.timestamp.toString() === key && data.event[metricItem.prop] && validCount === dimensionProps.length
              );
            });

            let name =
              metricItem.dimensions && metricItem.dimensions.length > 0 ? '' : i18next.t(metricItem.displayName);
            const fields: any = [{ event_timestamp: key }];
            _.forEach(metricItem.dimensions, (dimension) => {
              if (record) {
                const dimName = record && record.event[dimension.prop] ? record.event[dimension.prop] : '(none)';
                if (name === '') name += dimName;
                else name += ` / ${dimName}`;
              } else {
                name = legendItem[dimension.prop];
              }
              fields.push({});
            });

            let foundItem = _.find(result, { key: name });
            let color = metricItem.displayName === name ? chartColors[0] : findColor(legend && legend[0], name);
            if (metricItem.color!) color = metricItem.color;

            if (!foundItem) {
              foundItem = {
                index: 0,
                key: name,
                active: true,
                color,
                values: [],
                format: metricItem.format,
                defaultDays,
                start,
                end,
                fields,
              };
              result.push(foundItem);
            }

            const item = {
              active: true,
              metric: metricItem.prop,
              displayName,
              key: timestamp,
              value: record && record.event[metricItem.prop] ? record.event[metricItem.prop] : 0,
              fields,
              isSeperatedY:
                metricItem.prop.indexOf('event_campaign_conversion_rate') > -1 ||
                metricItem.prop.indexOf('event_campaign_click_rate') > -1 ||
                metricItem.prop.indexOf('event_campaign_conversion_value') > -1 ||
                metricItem.prop.indexOf('event_campaign_conversion_revenue') > -1,
            };
            foundItem.values.push(item);
          }
        };
        if (legend && legend[0] && legend[0].legends.length > 0) {
          _.forEach(legend[0].legends, (legendItem) => {
            dayLoop(legendItem);
          });
        } else {
          dayLoop();
        }
      });

    return result;
  };

  const mapChart = (result: FetchedEvent[], legend: LegendList[]) => {
    let newConfig: any = {};
    let lineChartWidth = 800;

    if (!metric?.dimensions) return;

    if (props.config.selectedChart === 'Line') {
      if (chartBodyRef && chartBodyRef.current) {
        lineChartWidth = chartBodyRef.current.offsetWidth - 58;
        // Legend가 있는 경우 Legend의 너비만큼 빼준다.
        if (!props.disabledLegend && legend && legend[0]) {
          lineChartWidth -= 223;
        }
      }
      newConfig = {
        duration: props.duration,
        compareDuration: props.compareDuration,
        dataset: drawLine(result[0], legend),
        comparisonDataset: result[1] ? drawLine(result[1], legend, true) : [],
        width: lineChartWidth,
        tooltipType: props.config.tooltipType,
      };
      if (props.config.width) newConfig.width = props.config.width;
    }
    setChartConfig(newConfig);
  };

  const drawLegend = async (dataset: any, more?: boolean) => {
    let newLegends;
    const legendItem: LegendList = {
      key: _.map(metric.dimensions, 'prop').join(' / '),
      title: _.map(metric.dimensions, 'displayName')
        .map((title) => i18next.t(title))
        .join(' / '),
      active: true,
      more: dataset.more,
      total: dataset.total,
      dimensions: _.map(metric.dimensions, (dimension) => {
        return { prop: dimension.prop };
      }),
      legends: _.map(dataset.list, (item, idx: number) => {
        const legend: any = {
          name: '',
          active: !more,
          color: chartColors[idx >= chartColors.length ? idx % chartColors.length : idx],
          value: item[metric.prop],
        };
        _.forEach(metric.dimensions, (dimension) => {
          let propName = dimension.prop;
          let originPropName;
          if (dimension.category === '커스텀 속성') {
            propName = `custom_${propName}`;
            originPropName = dimension.prop;
          }
          const dimName = item[propName] ? item[propName] : '(none)';
          if (legend.name === '') legend.name += dimName;
          else legend.name += ` / ${dimName}`;
          legend[propName] = item[propName];
          if (originPropName) legend[originPropName] = item[propName];
        });

        return legend;
      }),
    };
    if (more && legendLists && legendLists[0] && legendLists[0].legends) {
      newLegends = legendLists;
      newLegends[0].more = dataset.more;
      const nlegend = [
        {
          ...legendLists[0],
          legends: newLegends[0].legends.concat(legendItem.legends),
        },
      ];

      setLegendLists(nlegend);
    } else {
      newLegends = [legendItem];

      setLegendLists(newLegends);
    }
    return newLegends;
  };

  const createFilterFromLegend = async (newLegend: LegendList[]) => {
    if (!newLegend || !newLegend[0] || !newLegend[0].legends || newLegend[0].legends.length < 1) return {};

    const query: any = { filterPropertyList: [] };

    if (props.config.selectedChart === 'TimeSeries') {
      newLegend[0].legends.forEach((item: Legend, index: number) => {
        if (index >= 1000) return;
        const filter: any = { type: 'AND', value: [] };
        metric.dimensions.forEach((dimension: Dimension) => {
          let propName = dimension.prop;
          if (dimension.category === '커스텀 속성') propName = `custom_${propName}`;
          filter.value.push({ value: item[propName], type: 'eq', dimension: propName });
        });

        if (metric.pageRegex) {
          metric.pageRegex.forEach((pageRegex: any) => {
            filter.value.push({
              type: 'regex',
              dimension: 'event_seg_pagePathname',
              value: pageRegex,
            });
          });
        }

        query.filterPropertyList.push(filter);
      });
    } else {
      newLegend[0].legends.forEach((item: Legend) => {
        if (!item.active) return;

        const filter: any = { type: 'AND', value: [] };
        metric.dimensions.forEach((dimension: Dimension) => {
          let propName = dimension.prop;
          if (dimension.category === '커스텀 속성') propName = `custom_${propName}`;
          filter.value.push({ value: item[propName], type: 'eq', dimension: propName });
        });
        query.filterPropertyList.push(filter);
      });
    }

    query.filterType = 'OR';
    return query;
  };

  const fetchLegend = async (query?: { more?: boolean; skip?: number; searchText?: string; limit?: number }) => {
    let { start, end } = props!.duration!;

    if (props.compareDuration) {
      if (moment(props.compareDuration.start).isBefore(props!.duration!.start)) start = props.compareDuration.start;
      if (moment(props.compareDuration.end).isAfter(props!.duration!.end)) end = props.compareDuration.end;
    }

    const dimQuery: any = {
      interval: `${moment(start).startOf('day').toISOString()}/${moment(end).endOf('day').toISOString()}`,
      limit: legendLimit,
    };

    if (query && query.more && query.skip) dimQuery.skip = query.skip;
    if (query && query.searchText) dimQuery.search = query.searchText;
    if (query && query.limit) dimQuery.limit = query.limit;

    const legendsResult = { list: [], more: false, total: 0 };

    const fetchedData: any = {
      query: {},
      legend: ['facebook'],
      total: 0,
      more: false,
    };

    if (legendsResult) {
      legendMore = legendsResult.more;
      fetchedData.total = legendsResult.total;
      fetchedData.more = legendsResult.more;
      fetchedData.legend = await drawLegend(legendsResult, query && query.more);
      if (legendsResult.list.length > 0) fetchedData.query = await createFilterFromLegend(fetchedData.legend);
    }

    return fetchedData;
  };

  const fetchEvent = async (fetchedData: { query: any; legend: LegendList[] }) => {
    const { query, legend } = fetchedData;

    if (!config) return;
    let compareData;
    const result = [];
    if (
      (metric.dimensions &&
        metric.dimensions.length > 0 &&
        query.filterPropertyList &&
        query.filterPropertyList.length > 0) ||
      metric.dimensions.length === 0
    ) {
      const defaultData = await fetchData();
      if (defaultData) {
        result.push(defaultData);
      }

      if (props.compareDuration) {
        const compareQuery = _.cloneDeep(query);
        compareQuery.interval = `${moment(props.compareDuration.start).startOf('day').toISOString()}/${moment(
          props.compareDuration.end,
        )
          .endOf('day')
          .toISOString()}`;
        compareData = await fetchData();
        if (compareData) result.push(compareData);
      }
    }

    mapChart(result, legend);
  };

  const initChart = async () => {
    if (!metric) return;
    setIsLoaded(false);
    if (props.handleChartLoading) props.handleChartLoading(true);

    let fetchedLegend: any = {
      query: {},
      legend: [],
    };

    if (metric && metric.dimensions && metric.dimensions.length > 0) fetchedLegend = await fetchLegend();
    else setLegendLists([]);
    await fetchEvent(fetchedLegend);
    setIsLoaded(true);
    if (props.handleChartLoading) props.handleChartLoading(false);
  };

  const toggleLegend = async (newLegendLists: any) => {
    if (!newLegendLists || Object.keys(newLegendLists).length < 1) return;
    const fetchedData: any = {};
    if (legendLists) {
      fetchedData.query = await createFilterFromLegend(legendLists);
      fetchedData.legend = legendLists;
    }
    fetchEvent(fetchedData);
  };

  const searchLegend = (text: string) => {
    let searchText = '';
    if (text) searchText = text;

    fetchLegend({ searchText }).then((query) => {
      fetchEvent(query);
    });
  };

  const timeoutRef = useRef();
  const timeoutCancel = useCallback(() => {
    return clearTimeout(timeoutRef.current);
  }, []);

  const visualEvent = ($event: { name: string; data?: any; event?: any }) => {
    if ($event.name === 'mouseover') {
      timeoutCancel();
      setShowTooltip(true);

      if ($event.event) {
        const yPosition = window.innerHeight - (110 + $event.event.clientY + 10);
        const xPosition = window.innerWidth - (245 + $event.event.clientX + 10);

        const tooltipDatas: any[] = [];
        _.forEach($event.data, (data) => {
          const tooltipData = data;
          const numberFormat = ['UNIQUE_USER', 'UNIQUE_SESSION'];
          if (metric.operation && numberFormat.indexOf(metric.operation) > -1) tooltipData.format = 'Number';
          tooltipDatas.push(tooltipData);
        });

        setTooltipConfig({
          duration: props.duration,
          compareDuration: props.compareDuration,
          chartType: props.config.selectedChart,
          metric: config && metric,
          metrics: props.config.metrics,
          data: tooltipDatas,
          style: {
            top: yPosition < 0 ? `${$event.event.clientY + 10 + yPosition - 50}px` : `${$event.event.clientY + 10}px`,
            left: xPosition < 0 ? `${$event.event.clientX + 10 + xPosition - 50}px` : `${$event.event.clientX + 10}px`,
          },
          tooltipType: props.config.tooltipType,
        });
      }
    } else {
      // @ts-ignore
      timeoutRef.current = setTimeout(function () {
        setShowTooltip(false);
      }, 500);
    }
  };

  // init & update chart
  useEffect(() => {
    if (props.chartLoading !== true && props.duration && props.config.metrics) initChart();
  }, [
    props.config.selectedChart,
    props.config.metrics,
    props.data,
    props.duration,
    props.compareDuration,
    selectedChartDuration,
    props.parentFilterSetting,
  ]);

  const handleResize = () => {
    if (props.config.selectedChart === 'Line') {
      let chartWidth = (chartBodyRef.current?.offsetWidth || 1200) - 58;
      // Legend가 있는 경우 Legend의 너비만큼 빼준다.
      if (!props.disabledLegend && legendLists && legendLists[0]) {
        chartWidth -= 223;
      }
      setChartConfig((prevConfig: any) => {
        return {
          ...prevConfig,
          width: chartWidth,
        };
      });
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [legendLists]);

  return (
    <div css={[styleCss]}>
      <div
        className={`bg-chart-container ${
          !props.disabledLegend && legendLists && legendLists[0] && props.config.selectedChart !== 'TimeSeries'
            ? 'with-legend'
            : ''
        } ${i18n.language === 'en' ? 'en' : ''}`}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div className="chart-body-wrapper" ref={chartBodyRef}>
          {metric && (
            <React.Fragment>
              <BGLoadingSpinner isLoading={!isLoaded} style={{ minHeight: `${props.config.height}px` || '400px' }} />
              <div className="chart-wrapper">
                {isLoaded && (
                  <React.Fragment>
                    {props.config.selectedChart === 'Line' && (
                      <LineChart
                        config={chartConfig}
                        hoverChart={($event) => visualEvent($event)}
                        selectedChartDuration={selectedChartDuration}
                        setSelectedChartDuration={setSelectedChartDuration}
                      />
                    )}
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
          {!props.disabledLegend && legendLists && legendLists[0] && props.config.selectedChart !== 'TimeSeries' && (
            <div className="legend-list-container">
              <BGLegend
                legendLists={legendLists}
                toggleLegend={toggleLegend}
                searchLegend={searchLegend}
                loadMore={() => fetchLegend({ more: true, skip: legendLists[0].legends.length })}
                hasMore={legendMore}
                isSimpleLegend={props.isSimpleLegend}
              />
            </div>
          )}
        </div>
      </div>
      {showTooltip && tooltipConfig && (
        <div
          onMouseEnter={() => {
            visualEvent({ name: 'mouseover' });
          }}
          onMouseLeave={() => visualEvent({ name: 'mouseout' })}
        >
          <BgChartTooltip config={tooltipConfig} />
        </div>
      )}
    </div>
  );
};
