import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();
  return css`
    .edit-pannel-wrapper {
      width: 100%;

      .info-title {
        ${theme.text.h5};
        color: ${theme.colors.subFont};
        margin-top: 20px;
        margin-bottom: 8px;
      }
    }
  `;
};
