import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();
  return css`
    .header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .title {
        width: 89px;
        height: 36px;
        margin: 0 119px 4px 0;
        ${theme.text.h1};
        color: ${theme.colors.mainFont};
      }
    }

    .description {
      margin: 4px 0 20px;
      ${theme.text.input};
      color: ${theme.colors.mainFont};
    }

    .buttons-wrapper {
      margin-top: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 1rem;
      }
    }
  `;
};

export const stepCss = () => {
  const theme: Theme = useTheme();
  return css`
    .form-input {
      margin: 0 0 20px 0;

      .label {
        margin: 0 0 8px 0;
        ${theme.text.h4};
        color: ${theme.colors.mainFont};
      }
    }
  `;
};
