import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { useTranslation } from 'react-i18next';
import { baseCss } from './CrmProjectInfoModal.style';

export interface CrmProjectInfoModalProps {
  assets: MallMedia[];
  isOrphan: boolean;
  close: () => void;
}

export const CrmProjectInfoModal = (props: CrmProjectInfoModalProps): ReactElement => {
  const i18next = useTranslation();

  const initialBiginProject = props.assets.find((item) => item.type === 'bigin-project');

  return (
    <div className="crm-project-info-modal-container" css={[baseCss]}>
      <div className="crm-project-info-modal-header">
        {props.isOrphan ? i18next.t('Bigin CRM 임시 연동') : i18next.t('Bigin CRM 연동 관리')}
        <BDSFontIcon className="header-close" name="ic-close" size="20px" handleClick={() => props.close()} />
      </div>
      <div className="crm-project-info-modal-contents">
        {props.isOrphan ? (
          <React.Fragment>
            <div className="text-input text-color-main">
              {i18next.t('Bigin SDK 생성을 위해 Bigin CRM 임시 프로젝트와 연동되어 있습니다.')}
              <br />
              {i18next.t(
                '홈페이지 내 상담 신청을 통해 Bigin CRM을 구독하시면, 임시 프로젝트를 즉시 활용할 수 있습니다.',
              )}
            </div>
            <button
              type="button"
              className="button-text"
              onClick={() => window.open('https://bigin.io/solution/bigin-crm', '_blank')}
            >
              <u>{i18next.t('Bigin CRM은 어떤 솔루션인가요?')}</u>
            </button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="info-label">{i18next.t('Bigin CRM 프로젝트명')}</div>
            <div className="info-description">{initialBiginProject?.name}</div>
            <div className="info-label mt-4">{i18next.t('Bigin CRM 프로젝트 ID')}</div>
            <div className="info-description">{initialBiginProject?.media_id}</div>

            <div className="text-disclaimer mt-4">
              {i18next.t('*연동 해제가 필요한 경우, 담당 매니저에게 문의해주세요.')}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
