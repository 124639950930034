import axios, { AxiosPromise } from 'axios';

export interface CampaignForAudience {
  id: string;
  name: string;
  result_type: string;
  results: number;
}
export const FacebookApi = () => ({
  getPages(mallId: number): AxiosPromise<{ data: { id: string; name: string; picture: string }[] }> {
    return axios.get(`/malls/${mallId}/facebook/pages`);
  },
  getPage(mallId: number): AxiosPromise<{ data: { id: string; name: string; picture: string } }> {
    return axios.get(`/malls/${mallId}/facebook/page`);
  },
  getPixels(mallId: number): AxiosPromise<{ data: { id: string; name: string }[] }> {
    return axios.get(`/malls/${mallId}/facebook/pixels`);
  },
  getInstagramAccounts(mallId: number): AxiosPromise<{
    data: {
      follow_count: number;
      followed_by_count: number;
      has_profile_picture: boolean;
      id: string;
      is_private: boolean;
      is_published: boolean;
      media_count: number;
      profile_pic: string;
      unadvertisable_reasons: string[];
      username: string;
    }[];
  }> {
    return axios.get(`/malls/${mallId}/facebook/instagram-accounts`);
  },
  getBusinesses(mallId: number): AxiosPromise {
    return axios.get(`/malls/${mallId}/facebook/businesses`);
  },
  getFBAdAccounts(mallId: number): AxiosPromise {
    return axios.get(`/malls/${mallId}/facebook/ad-accounts`);
  },
  getInstagramAccount(mallId: number): AxiosPromise<{
    data: InstagramAccountData;
  }> {
    return axios.get(`/malls/${mallId}/facebook/instagram-account`);
  },
  getCampaignForAudience(adAccountId: string): AxiosPromise<{ data: CampaignForAudience[] }> {
    return axios.get(`/facebook/ad-accounts/${adAccountId}/campaigns-for-audience`);
  },
  postCampaignAudience(
    adAccountId: string,
    form: { campaign_ids: string[]; ratio: number; name: string },
  ): AxiosPromise<{ data: any }> {
    return axios.post(`facebook/ad-accounts/${adAccountId}/campaign-audience`, form);
  },
});
