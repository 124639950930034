import { css } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = () => {
  return css`
    width: 100%;
    min-width: fit-content;

    * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: ${GS.gutter}px;
    }

    .edit-ad-container {
      font-stretch: normal;
      font-style: normal;
      width: 100%;
      display: flex;
      position: relative;

      .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }
  `;
};

export const leftWrapper = css`
  width: 100%;
  min-width: ${GS.contentMinWidth}px;
  max-width: ${GS.contentMaxWidth}px;
`;
export const rightWrapper = css`
  width: 260px;
  margin-left: 20px;
  position: relative;
`;

export const addCampaignModalCss = css`
  .edit-ad-header {
    margin-bottom: 4px;
  }
  .edit-ad-body {
    margin-bottom: 24px;
  }
  .edit-ad-footer {
    //
  }
`;
