import axios, { AxiosPromise } from 'axios';

export const GoogleApi = () => ({
  getChannels(mallId: number): AxiosPromise {
    return axios.get(`/malls/${mallId}/google/youtube/channels`);
  },
  getYoutubeVideos(mallId: number, params?: { channel_id?: string }): AxiosPromise<{ data: YoutubeVideo[] }> {
    return axios.get(`/malls/${mallId}/google/youtube/videos`, { params });
  },
  getGAAccountIds(): AxiosPromise {
    return axios.get('/ga/accounts');
  },
  getClientAccounts(): AxiosPromise {
    return axios.get('/google/client-accounts');
  },
});
