import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();
  return css`
    .edit-pannel-wrapper {
      width: 100%;

      .info-title {
        ${theme.text.h5};
        color: ${theme.colors.subFont};
        margin-top: 20px;
        margin-bottom: 8px;
      }
    }

    .classification-list-container {
      position: relative;

      .classification-button {
        border-radius: 8px;
        border: solid 1px #dce0e8;
        background: #f9fafb;
        height: 36px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px 0 12px;
        cursor: pointer;
        transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);

        &:hover {
          cursor: pointer;
          border: solid 1px #b8beca;
        }
      }
      .classification-list-wrapper {
        position: absolute;
        top: 42px;
        z-index: 1;
        width: 280px;
        border: solid 1px #dce0e8;
        background: #fff;
        border-radius: 8px;
        box-sizing: border-box;
        overflow: hidden;
      }
      .list-header {
        display: grid;
        border-bottom: solid 1px #dce0e8;
        height: 42px;
        grid-template-columns: repeat(2, 1fr);

        .header-item {
          padding: 10px 12px;
          ${theme.text.input};
          font-weight: bold;
          color: ${theme.colors.mainFont};
          display: flex;
          align-items: center;
        }
      }
      .list-contents {
        height: calc(100% - 42px);
        display: grid;
        ${theme.text.input};
        grid-template-columns: repeat(2, 1fr);
        .child-list {
          height: 100%;

          &:first-of-type {
            border-right: solid 1px #dce0e8;
          }
          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
              padding: 8px 8px 8px 12px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:hover {
                background: #f9fafb;
              }
              &.active {
                background: #edf1f6;
              }
            }
          }
        }
      }
    }
  `;
};
