import React, { useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import Menu from '@material-ui/core/Menu';
import { withStyles } from '@material-ui/core/styles';
import { MenuItem } from '@material-ui/core';
import { StyledButton, StyledCommonListMoreButtonWrapper } from './MoreButton.style';
import { FontIcon } from '../FontIcon/FontIcon';

export interface BGCommonListMoreButtonProps {
  items: BGCommonListMoreButtonItem[];
  onMenuButtonClick: (value: string) => void;
  style?: Object;
}

const StyledMenuItem = withStyles(() => ({
  root: {
    padding: '10px 7px !important',
    '& .select-box-item': {
      padding: '0 1rem',
      fontSize: '14px',
      minWidth: '80px',
      color: '#778',
      '&:hover': {
        color: '#445',
        backgroundColor: '#f5f5fa',
      },
    },
  },
}))(MenuItem);

export const MoreButton = ({ items, onMenuButtonClick, style = {} }: BGCommonListMoreButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event?: {}) => {
    if (event) {
      (event as { stopPropagation(): void }).stopPropagation();
    }
    setAnchorEl(null);
  };

  const handleClick = (value: string) => {
    onMenuButtonClick(value);
    handleClose();
  };

  return (
    <div className="more-button-panel" style={style} css={[StyledCommonListMoreButtonWrapper]}>
      <StyledButton
        type="button"
        onClick={(event) => {
          event.stopPropagation();
          openMenu(event);
        }}
      >
        <FontIcon name="ic-option" size="20px" />
      </StyledButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(event) => handleClose(event)}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {items.map((item) => {
          const { value, label } = item;
          return (
            <StyledMenuItem
              key={value}
              className="category-select-box-menu"
              onClick={(event) => {
                event.stopPropagation();
                handleClick(value);
              }}
            >
              <div className="select-box-item">{label}</div>
            </StyledMenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export interface BGCommonListMoreButtonItem {
  value: string;
  label: string;
}
