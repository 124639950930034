import { css } from '@emotion/react';

export const wrapperCss = () => {
  return css`
    padding: 0 16px;
    &:first-of-type {
      margin-top: 20px;
    }

    & + & {
      margin-top: 12px;
    }

    & + &:last-child {
      padding-bottom: 20px;
    }

    .media-group-wrapper {
      position: relative;
      min-height: 76px;
    }
    .media-group-wrapper + .media-group-wrapper {
      padding-top: 12px;
    }
  `;
};
