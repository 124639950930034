import { Trans, useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useForm } from 'react-hook-form';
import validator from 'validator';

import { BGInput } from '../../../components/BGInput/BGInput';
import { baseCss } from './Step4.style';
import { InputError } from '../../../components/InputError/InputError';

export interface Step4State {
  userName: string;
  userEmail: string;
  userPassword: string;
  userPasswordConfirm: string;
  userPhone: string;
  isValid: boolean;
}

export interface Step4Props {
  userName: string;
  userEmail: string;
  userPassword: string;
  userPasswordConfirm: string;
  userPhone: string;
  handleUpdate: (state: Step4State) => void;
}

interface Step4Form {
  userName: string;
  userEmail: string;
  userPassword: string;
  userPasswordConfirm: string;
  userPhone: string;
}

export const Step4 = (props: Step4Props) => {
  const i18next = useTranslation();

  const MIN_PASSWORD = 6;

  const [userName, setUserName] = useState<string>(props.userName);
  const [userEmail, setUserEmail] = useState<string>(props.userEmail);
  const [userPassword, setUserPassword] = useState<string>(props.userPassword);
  const [userPasswordConfirm, setUserPasswordConfirm] = useState<string>(props.userPasswordConfirm);
  const [userPhone, setUserPhone] = useState<string>(props.userPhone);

  const MAX_PHONE = 11;
  const { formState, register, setValue, watch } = useForm<Step4Form>({ mode: 'onChange' });
  useEffect(() => {
    register('userName', { value: userName, required: i18next.t('필수 입력란입니다.') as string });
    register('userEmail', {
      value: userEmail,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isEmail(value)) {
          return i18next.t('이메일이 올바르지않습니다.') as string;
        }

        return true;
      },
    });
    register('userPassword', {
      value: userPassword,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isLength(value, { min: MIN_PASSWORD })) {
          return i18next.t('{{length}}자 이상 입력해주세요', { length: MIN_PASSWORD }) as string;
        }

        return true;
      },
    });
    register('userPasswordConfirm', {
      value: userPasswordConfirm,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.equals(value, watch('userPassword'))) {
          return i18next.t('비밀번호가 일치하지않습니다.') as string;
        }

        return true;
      },
    });
    register('userPhone', {
      value: userPhone,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isNumeric(value)) {
          return i18next.t('숫자만 입력해주세요.') as string;
        }
        if (!validator.isLength(value, { max: MAX_PHONE })) {
          return i18next.t('{{length}}자 이하로 입력해주세요', { length: MAX_PHONE }) as string;
        }

        return true;
      },
    });
  }, []);
  useEffect(() => {
    if (props.handleUpdate) {
      props.handleUpdate({
        userName,
        userEmail,
        userPassword,
        userPasswordConfirm,
        userPhone,
        isValid: formState.isValid,
      });
    }
  }, [formState, formState.isValid]);

  return (
    <div css={[baseCss]}>
      <div className="form-input">
        <div className="label">{i18next.t('이름')}</div>
        <BGInput
          error={!!formState.errors.userName}
          inputProps={{
            placeholder: i18next.t('이름을 입력해주세요.'),
            value: userName,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              setValue('userName', event.target.value, { shouldValidate: true });
              setUserName(event.target.value);
            },
          }}
        />
        {formState.errors.userName && <InputError message={formState.errors.userName.message as string} />}
      </div>

      <div className="form-input">
        <div className="label">{i18next.t('이메일')}</div>
        <BGInput
          error={!!formState.errors.userEmail}
          inputProps={{
            placeholder: i18next.t('이메일 주소를 입력해주세요.'),
            value: userEmail,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              setValue('userEmail', event.target.value, { shouldValidate: true });
              setUserEmail(event.target.value);
            },
          }}
        />
        {formState.errors.userEmail && <InputError message={formState.errors.userEmail.message as string} />}
      </div>

      <div className="form-input">
        <div className="label">{i18next.t('비밀번호')}</div>
        <BGInput
          error={!!formState.errors.userPassword}
          inputProps={{
            type: 'password',
            placeholder: i18next.t('비밀번호를 입력해주세요.'),
            value: userPassword,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              setValue('userPassword', event.target.value, { shouldValidate: true });
              setUserPassword(event.target.value);
            },
          }}
        />
        {formState.errors.userPassword && <InputError message={formState.errors.userPassword.message as string} />}
      </div>

      <div className="form-input">
        <div className="label">{i18next.t('비밀번호 확인')}</div>
        <BGInput
          error={!!formState.errors.userPasswordConfirm}
          inputProps={{
            type: 'password',
            value: userPasswordConfirm,
            placeholder: i18next.t('비밀번호를 한번 더 입력해주세요.'),
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              setValue('userPasswordConfirm', event.target.value, { shouldValidate: true });
              setUserPasswordConfirm(event.target.value);
            },
          }}
        />
        {formState.errors.userPasswordConfirm && (
          <InputError message={formState.errors.userPasswordConfirm.message as string} />
        )}
      </div>

      <div className="form-input">
        <div className="label">{i18next.t('전화번호')}</div>
        <BGInput
          error={!!formState.errors.userPhone}
          inputProps={{
            placeholder: i18next.t('전화번호를 - 없이 입력하세요.'),
            value: userPhone,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              setValue('userPhone', event.target.value, { shouldValidate: true });
              setUserPhone(event.target.value);
            },
          }}
        />
        {formState.errors.userPhone && <InputError message={formState.errors.userPhone.message as string} />}
      </div>

      <div className="text-disclaimer">
        <Trans i18nKey="회원가입 약관동의 html">
          * 회원가입을 누르시면&nbsp;
          <a href="https://www.notion.so/13672e5ccdc749e6b5d3473bc95b456e" target="_blank" rel="noreferrer">
            서비스이용약관
          </a>
          &nbsp;및&nbsp;
          <a href="https://taggers.notion.site/15d722733dbf48a6a21bafe9ce4637a1" target="_blank" rel="noreferrer">
            개인정보처리방침
          </a>
          에 동의한 것으로 간주합니다.
        </Trans>
      </div>
    </div>
  );
};
