import { css, Theme, useTheme } from '@emotion/react';

export const MaterialCheckboxSelectorStyle = () => {
  const theme: Theme = useTheme();

  return css`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .material-field-label {
      // view
      width: auto;
      height: auto;

      // text
      color: ${theme.colors.mainFont};
      ${theme.text.h5}
    }

    .material-field-usage-selector {
      // view
      width: auto;
      height: auto;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: auto;
      span {
        margin-left: 4px;
        ${theme.text.input};
        color: #bbc;
      }
    }

    .material-radio-container {
      margin-left: auto;
      width: auto;
      height: auto;
      display: flex;
      align-items: center;
      gap: 16px;
    }
  `;
};
