import React, { FormEvent, ReactElement, useEffect, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Theme, useTheme } from '@emotion/react';
import { isEmpty } from 'lodash';
import AutosizeInput from 'react-input-autosize';
import { useTranslation } from 'react-i18next';
import { BDSFontIcon, BDSRadioListMenu } from '@bigin/bigin-ui-components';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { BGListFilterV2CSS } from './BGListFilterV2.style';
import { FontIcon } from '../FontIcon/FontIcon';
import { Calendar, DateRange } from '../Calendar/Calendar';
import { getUniqKey } from '../../utils/array.utils';
import { Backdrop } from '../Backdrop/Backdrop';
import { BorderSection } from '../BorderSection/BorderSection';
import { BGListFilterV2Filter, CampaignQueryParams } from '../../pages/CampaignDashboard/CampaignDashboardDef';

export interface BGListFilterV2Props {
  placeholder?: string;
  defaultSearchText?: string;
  filters?: BGListFilterV2Filter;
  selectedFilter: ListItem;
  onChangeFilters: (searchText: string, activatedFilters: ListItem) => void;
  dateConfig?: {
    selectableRange: DateRange;
    initDateRange: DateRange;
    onChangeDate: (dateRange: DateRange) => void;
  };
  customButtons?: JSX.Element[];
  style?: Object;
}

export interface BGActivatedFilter {
  [key: string]: string[];
}

export const BGListFilterV2 = (props: BGListFilterV2Props): ReactElement => {
  const { i18n, ...i18next } = useTranslation();
  const {
    defaultSearchText = '',
    filters = {} as BGListFilterV2Filter,
    selectedFilter,
    placeholder = i18next.t('검색 및 필터링'),
    onChangeFilters,
    dateConfig,
    customButtons,
  } = props;

  const theme: Theme = useTheme();
  const themeCss = BGListFilterV2CSS(theme);
  const location = useLocation();
  const [searchText, setSearchText] = useState<string>(defaultSearchText);
  const [showFilterItem, setShowFilterItem] = useState<boolean>(false);
  const prevSearchTextRef = useRef<string>(defaultSearchText);
  const defaultFilterItem = {
    label: i18next.t('전체'),
    value: 'CAMPAIGN_FILTER_ALL',
    isDefault: true,
  } as ListItem;
  const [activatedFilter, setActivatedFilter] = useState<ListItem>(selectedFilter);
  const [isShowFilterPicker, setIsShowFilterPicker] = useState<boolean>(false);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (activatedFilter) {
      onChangeFilters(searchText, activatedFilter);
    }

    // onSearch(searchText || '');
    prevSearchTextRef.current = searchText || '';
    setIsShowFilterPicker(false);
  };

  const parsedQueryParams: CampaignQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if (parsedQueryParams?.filter) {
      setShowFilterItem(true);
    }
  }, []);

  return (
    <BorderSection style={{ width: '100%' }}>
      <div css={themeCss}>
        {isShowFilterPicker && (
          <Backdrop
            handleClick={() => {
              setIsShowFilterPicker(false);
            }}
            style={{ zIndex: 2, backgroundColor: 'rgba(29,0,0,0)' }}
          />
        )}
        <div className="left-wrapper" style={{ paddingRight: customButtons || dateConfig ? '8px' : 0 }}>
          <div className="icon-wrapper">
            <FontIcon name="ic-search" size="20px" color="#53585f" />
          </div>
          {!isEmpty(activatedFilter) && onChangeFilters && showFilterItem && (
            <div style={{ position: 'relative' }}>
              <div className="activated-filter-wrapper">
                <div className="text-wrapper">
                  {filters.label} : {activatedFilter && activatedFilter.label}
                </div>
                <BDSFontIcon
                  className="delete-icon"
                  name="ic-close"
                  size="20px"
                  color="#7e8696"
                  handleClick={() => {
                    setActivatedFilter(defaultFilterItem);
                    setSearchText(prevSearchTextRef.current);
                    onChangeFilters(prevSearchTextRef.current, defaultFilterItem);
                    setShowFilterItem(false);
                  }}
                />
              </div>
            </div>
          )}

          <div className={`search-input-wrapper ${showFilterItem && 'isExistActivated'}`}>
            <form
              onSubmit={onSubmit}
              style={{ zIndex: 3, width: '100%', display: 'flex', alignItems: 'center', paddingRight: '8px' }}
              autoComplete="off"
            >
              <AutosizeInput
                style={{ width: '100%' }}
                name="title"
                inputStyle={{
                  width: '100%',
                  boxSizing: 'border-box',
                }}
                value={searchText}
                placeholder={placeholder}
                onChange={(event) => {
                  setSearchText(event.target.value);
                }}
                onClick={() => {
                  setIsShowFilterPicker(true);
                }}
              />
              {searchText && onChangeFilters && (
                <FontIcon
                  name="ic-delete-alt"
                  size="16px"
                  color="#7e8696"
                  handleClick={() => {
                    setSearchText('');

                    setActivatedFilter(activatedFilter);

                    if (prevSearchTextRef.current) {
                      onChangeFilters('', activatedFilter);
                    } else {
                      onChangeFilters(searchText, activatedFilter);
                    }

                    prevSearchTextRef.current = '';
                  }}
                />
              )}
            </form>
            {filters && onChangeFilters && isShowFilterPicker && (
              <BDSRadioListMenu
                list={filters.items}
                selectedValue={activatedFilter}
                handleUpdate={function (item: ListItem): void {
                  setActivatedFilter(item);
                  setIsShowFilterPicker(false);
                  onChangeFilters(searchText, item);
                  setShowFilterItem(true);
                }}
                close={function (): void {
                  setIsShowFilterPicker(false);
                }}
                language={i18n.language === 'ko' ? 'ko' : 'en'}
                style={{ position: 'absolute', zIndex: '3', top: '50px', left: '0' }}
              />
            )}
          </div>
        </div>
        {(customButtons || dateConfig) && (
          <div className="right-wrapper">
            {customButtons && (
              <div className="custom-button-wrapper">
                <div className="custom-btn-group">
                  {customButtons.map((button, index) => (
                    <React.Fragment key={getUniqKey('filter-custom-button', index)}>{button}</React.Fragment>
                  ))}
                </div>
              </div>
            )}
            {dateConfig && (
              <Calendar
                style={{
                  marginLeft: '12px',
                  minWidth: '220px',
                  height: '36px',
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                }}
                preLabel=""
                disableFuture
                selectableRange={dateConfig?.selectableRange}
                initDateRange={dateConfig?.initDateRange}
                dateHandle={dateConfig?.onChangeDate}
              />
            )}
          </div>
        )}
      </div>
    </BorderSection>
  );
};
