import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton, BDSSelectBox, BDSTextArea } from '@bigin/bigin-ui-components';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import _ from 'lodash';
import { BGUrlSelector } from '../../../../components/BGUrlSelector/BGUrlSelector';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss } from './FacebookSingleImage.style';
import CampaignSetting from '../../../../recoil/CampaignSetting';
import { MaterialCheckboxSelector } from '../../MaterialCheckboxSelector/MaterialCheckboxSelector';
import { PreviewFacebookItem } from '../PreviewFacebookItem/PreviewFacebookItem';
import { ModalImageSelect } from '../../../Creative/ModalImageSelect/ModalImageSelect';
import { InputError } from '../../../../components/InputError/InputError';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { facebookCTA } from '../../../../constants/campaigns';
import { filters } from '../../../../utils/filter';
import { BGTagInput } from '../../../../components/BGTagInput/BGTagInput';
import { CurrencyCodeRecoil } from '../../../../recoil/Currency';
import { AssetHeader } from '../../../../components/AssetHeader/AssetHeader';

export interface FacebookSingeImageProps {
  setting: CampaignAdSettingState;
  handleUpdate: (setting: CampaignAdSettingState) => void;
  handleValid?: (type: CampaignType, isValid: boolean) => void;
  removeSelectedCampaignTypes: (type: CampaignType) => void;
}

interface FacebookSingleImageForm {
  name: string;
  title: string;
  description: string;
  body: string;
  url: string;
  image_id: number | undefined | null;
}

export const FacebookSingleImage = (props: FacebookSingeImageProps): ReactElement => {
  const i18next = useTranslation();

  const [selectedCTA, setSelectedCTA] = useState<FacebookCTA>(
    props.setting?.fb_call_to_action ? props.setting?.fb_call_to_action : 'shop_now',
  );
  const [name, setName] = useState(props.setting?.name);
  const [title, setTitle] = useState(props.setting?.fb_single_title);
  const [description, setDescription] = useState(props.setting?.fb_single_description);
  const [body, setBody] = useState(props.setting?.fb_body);
  const [url, setUrl] = useState(props.setting?.url);
  const [showModalImageSelect, setShowModalImageSelect] = useState<boolean>(false);
  const [valueLock, setValueLock] = useState<boolean>(false);
  const [biginKeywords, setBiginKeywords] = useState<string[]>(props.setting?.bigin_keywords);
  const { register, formState, setValue, trigger } = useForm<FacebookSingleImageForm>({ mode: 'onChange' });

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const updateName = (curName: string) => {
    props.handleUpdate({ ...props?.setting, name: curName });
  };

  const updateSingleTitle = (curSingleTitle: string) => {
    props.handleUpdate({ ...props?.setting, fb_single_title: curSingleTitle });
  };

  const updateSingleDescription = (curSingleDescription: string) => {
    props.handleUpdate({ ...props?.setting, fb_single_description: curSingleDescription });
  };

  const updateBody = (curBody: string) => {
    props.handleUpdate({ ...props?.setting, fb_body: curBody });
  };

  const updateUrl = (curUrl: string) => {
    props.handleUpdate({ ...props?.setting, url: curUrl });
  };

  const updateCTA = (curCTA: FacebookCTA) => {
    props.handleUpdate({ ...props?.setting, fb_call_to_action: curCTA });
  };

  const updateBiginKeywords = (curKeywords: string[]) => {
    props.handleUpdate({ ...props?.setting, bigin_keywords: curKeywords });
  };

  const updateSingleImage = (curImage: MallImage | ProductImage) => {
    if ('product_id' in curImage) {
      const newTitle = curImage.product_id ? curImage.name : props.setting?.fb_single_title || '';
      const newDescription = curImage.product_id
        ? filters.formatCurrency({ value: curImage.sale_price, currencyCode, withoutUnit: currencyCode === 'KRW' })
        : props.setting?.fb_single_description || '';
      const newUrl = curImage.product_id ? curImage.detail_url : props.setting?.url || '';

      props.handleUpdate({
        ...props?.setting,
        product_image: curImage,
        product_image_id: curImage.id,
        mall_image: undefined,
        mall_image_id: undefined,
        fb_single_title: newTitle,
        fb_single_description: newDescription,
        url: newUrl,
      });
      setTitle(newTitle);
      setDescription(newDescription);
      setUrl(newUrl);
      setValue('title', newTitle);
      setValue('description', newDescription);
      setValue('url', newUrl);
      trigger();
    } else {
      props.handleUpdate({
        ...props?.setting,
        mall_image: curImage,
        mall_image_id: curImage.id,
        product_image: undefined,
        product_image_id: undefined,
      });
    }
  };

  const selectedImages = useMemo(() => {
    return (props.setting.mall_image || props.setting.product_image || []) as ImageTypes[];
  }, [props.setting]);

  const handleSelectImages = (images: ImageTypes[]) => {
    updateSingleImage(images[0] as ProductImage);
    setShowModalImageSelect(false);
    setValue('image_id', images[0].id);
  };

  useEffect(() => {
    if (name !== props.setting?.name) {
      updateName(name);
    }
    if (body !== props.setting?.fb_body) {
      updateBody(body);
    }

    if (!valueLock) {
      if (title !== props.setting?.fb_single_title) {
        updateSingleTitle(title);
      }
      if (description !== props.setting?.fb_single_description) {
        updateSingleDescription(description);
      }
      if (url !== props.setting?.url) {
        updateUrl(url);
      }
    }
    if (selectedCTA !== props.setting?.fb_call_to_action) {
      updateCTA(selectedCTA);
    }
    if (!_.isEqual(biginKeywords, props.setting?.bigin_keywords)) {
      updateBiginKeywords(biginKeywords);
    }

    setValueLock(false);
  }, [name, body, title, description, url, selectedCTA, biginKeywords]);

  useEffect(() => {
    register('url', {
      value: url,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isURL(value)) {
          return i18next.t('잘못된 URL 형식입니다.') as string;
        }

        return true;
      },
    });
    register('body', {
      value: body,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('title', {
      value: title,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('description', {
      value: description,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('image_id', {
      value: (props.setting?.mall_image || props.setting?.product_image)?.id,
      required: i18next.t('필수 입력란입니다.') as string,
    });
  }, []);

  useEffect(() => {
    if (props.handleValid) {
      props.handleValid(props.setting?.type, formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <BorderSection>
      <div className="fb-single-image-setting-wrapper" css={[baseCss]}>
        <AssetHeader
          title={i18next.t('단일 이미지')}
          icons={[
            { imgSrc: '/assets/img/card_icons/ic-channel-facebook.png', alt: 'Facebook' },
            { imgSrc: '/assets/img/card_icons/ic-channel-instagram.png', alt: 'Instagram' },
          ]}
          handleIconClick={() => {
            props.removeSelectedCampaignTypes(props.setting.type);
          }}
        />
        <div className="create-section-content-wrapper">
          <div className="create-section-content">
            <div className="preview-container">
              <div className="sticky-wrap">
                <div className="title-panel">
                  <div className="title">{i18next.t('미리보기')}</div>
                  <div className="info">{i18next.t('*실제 노출과 미리보기가 다를 수 있습니다.')}</div>
                </div>
                <div className="material-preview-wrapper">
                  <div className="material-preview">
                    <div className="facebook-wrapper">
                      <div className="preview-media-title-wrapper">Facebook</div>
                      <PreviewFacebookItem setting={props.setting} />
                    </div>
                    <div className="instagram-wrapper">
                      <div className="preview-media-title-wrapper">Instagram</div>
                      <PreviewFacebookItem setting={props.setting} instagram />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <h2 className="material-field-header">{i18next.t('광고 소재명')}</h2>
              <div className="material-field-selector-wrapper">
                <BGInput
                  style={{ width: '100%' }}
                  inputProps={{
                    name: 'name',
                    placeholder: i18next.t('광고 소재 이름을 입력해주세요.'),
                    value: name || '',
                    onChange: (event) => {
                      setName(event.target.value);
                    },
                  }}
                />
                <p className="description">{i18next.t('*다른 소재와 구분지을 수 있게 이름을 입력하세요.')}</p>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 이미지')}</h2>
              <div className="material-field-selector-wrapper">
                <div className="content-upload-container">
                  <div className="single-image-uploader-wrapper uploader-wrapper">
                    <div className="image-uploader-title-wrapper">
                      <div className="image-uploader-subtitle">
                        <div>{i18next.t('* 권장 사이즈: ')} 1080px*1080px</div>
                        <div>{i18next.t('* 최소 사이즈: ')} 600px*600px</div>
                        <div>{i18next.t('* 파일 형식: ')} JPG, PNG</div>
                        <div>{i18next.t('* 파일 용량: {{size}}MB 이내', { size: 30 })}</div>
                      </div>
                    </div>
                    <div className="single-image-uploader-wrapper file-wrapper">
                      <BDSButton
                        className="image-updater-btn"
                        onClick={() => {
                          setShowModalImageSelect(true);
                          setValueLock(true);
                        }}
                        label={i18next.t('업로드')}
                      />
                      {props.setting.product_image && props.setting.product_image.name && (
                        <div className="file-name">{props.setting.product_image.name}</div>
                      )}
                      {props.setting.mall_image && <div className="file-name">{i18next.t('상품 정보 없음')}</div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 텍스트')}</h2>
              <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 문구')} checkboxLabel="" value hiddenCheckbox />
                <div className="material-field-input-wrapper">
                  <BDSTextArea
                    isAutoGrow
                    error={!!formState.errors.body}
                    style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                    textAreaProps={{
                      name: 'fb_body',
                      cols: 30,
                      rows: 3,
                      style: {
                        minHeight: 49,
                      },
                      value: body || '',
                      placeholder: i18next.t('광고 문구를 입력해주세요.'),
                      onChange: (event) => {
                        setValue('body', event.target.value, { shouldValidate: true });
                        setBody(event.target.value);
                      },
                    }}
                    curLength={body.length}
                    limitLength={120}
                    lengthUnit={i18next.t('자')}
                  />
                  {formState.errors.body && <InputError message={formState.errors.body.message as string} />}
                </div>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 제목')} checkboxLabel="" value hiddenCheckbox />
                <BGInput
                  style={{ width: '100%', marginTop: '8px' }}
                  error={!!formState.errors.title}
                  inputProps={{
                    name: 'fb_single_title',
                    value: title || '',
                    placeholder: i18next.t('광고 제목을 입력해주세요.'),
                    onChange: (event) => {
                      setValue('title', event.target.value, { shouldValidate: true });
                      setTitle(event.target.value);
                    },
                  }}
                  customLength={title.length}
                  customLengthLimit={16}
                  lengthUnit={i18next.t('자')}
                />
                {formState.errors.title && <InputError message={formState.errors.title.message as string} />}
                <p className="text-disclaimer mt-2">{i18next.t('*Facebook에서만 보여집니다.')}</p>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 설명')} checkboxLabel="" value hiddenCheckbox />
                <BGInput
                  style={{ width: '100%', marginTop: '8px' }}
                  error={!!formState.errors.description}
                  inputProps={{
                    name: 'fb_single_description',
                    value: description || '',
                    placeholder: i18next.t('광고 설명을 입력해주세요.'),
                    onChange: (event) => {
                      setValue('description', event.target.value, { shouldValidate: true });
                      setDescription(event.target.value);
                    },
                  }}
                  customLength={description.length}
                  customLengthLimit={19}
                  lengthUnit={i18next.t('자')}
                />
                {formState.errors.description && (
                  <InputError message={formState.errors.description.message as string} />
                )}
                <p className="text-disclaimer mt-2">{i18next.t('*Facebook에서만 보여집니다.')}</p>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('행동 유도 버튼')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <BDSSelectBox
                  appearance="gray"
                  style={{ width: '100%', marginTop: '8px' }}
                  list={facebookCTA}
                  defaultValue={props.setting?.fb_call_to_action}
                  displayValue={(item: any) => {
                    return i18next.t(item.label);
                  }}
                  isSelected={(item: any) => {
                    return selectedCTA === item.value;
                  }}
                  handleUpdate={(item: any) => {
                    setSelectedCTA(item.value);
                  }}
                />
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('버튼 URL')} checkboxLabel="" value hiddenCheckbox />
                <BGUrlSelector
                  linkButton
                  validationTrackOn
                  isValid={!formState.errors.url}
                  url={url || ''}
                  handleUpdate={(curUrl: string) => {
                    setValue('url', curUrl, { shouldValidate: true });
                    setUrl(curUrl);
                  }}
                  style={{ marginTop: '8px' }}
                  placeholder="www.bigin.io"
                />
                {formState.errors.url && <InputError message={formState.errors.url.message as string} />}
              </div>
              {campaignState.isBiginConnected && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('연계 캠페인')}</h2>
                  <div className="material-field-selector-wrapper">
                    <MaterialCheckboxSelector
                      contentTitle={i18next.t('핵심 키워드')}
                      checkboxLabel=""
                      value
                      hiddenCheckbox
                    />
                    <BGTagInput
                      style={{ width: '100%', marginTop: '8px' }}
                      inputProps={{
                        name: 'bigin_keywords',
                        placeholder: i18next.t(
                          'Bigin CRM에서 해당 광고 소재를 검색할 때 사용할 키워드를 입력해주세요.',
                        ),
                      }}
                      handleUpdate={(keywords) => {
                        setBiginKeywords(keywords);
                      }}
                      defaultTags={biginKeywords}
                      duplicateText={i18next.t('* 이미 등록된 키워드입니다.')}
                    />
                    <p className="description">
                      {i18next.t('*핵심 키워드는 선택사항이며, 최대 5개까지 입력할 수 있습니다.')}
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModalImageSelect && (
        <ModalImageSelect
          selectType="single"
          selectedImages={selectedImages}
          campaignType={props.setting.type}
          isOpen={showModalImageSelect}
          close={() => setShowModalImageSelect(false)}
          setSelectedImages={handleSelectImages}
        />
      )}
    </BorderSection>
  );
};
