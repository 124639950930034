import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx, css, Theme, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { BDSButton } from '@bigin/bigin-ui-components';
import { baseCss } from './ModalNewCard.style';
import { BGInput } from '../BGInput/BGInput';
import { InputCardNumber } from '../InputCardNumber/InputCardNumber';
import { InputExpiryDate } from '../InputExpiryDate/InputExpiryDate';
import StorageService from '../../services/StorageService';
import { useDataStore } from '../../context/Store';
import { BGRadioButton } from '../BGRadioButton/BGRadioButton';
import { PaymentApi } from '../../lib/api/PaymentApi';
import { BGCheckbox } from '../BGCheckbox/BGCheckbox';
import { InputError } from '../InputError/InputError';

export interface ModalNewCardProps {
  close?: () => void;
  refetch?: () => void;
  card?: Card;
}

enum PayingCardType {
  PERSONAL = 'personal',
  CORPORATION = 'corporation',
}

const MAX_BIRTH_DATE = 6;
const MAX_PASSWORD = 11;
const MAX_REGISTRATION = 10;
const MAX_CVC = 3;

export const ModalNewCard = (props: ModalNewCardProps): ReactElement => {
  const i18next = useTranslation();
  const [cardType, setCardType] = useState<PayingCardType>(PayingCardType.CORPORATION);
  const [isNhCard, setIsNhcard] = useState<boolean>(false);
  const ref: React.RefObject<HTMLInputElement> = React.createRef();
  const pwRef: React.RefObject<HTMLInputElement> = React.createRef();

  const [chargeForm, setChargeForm] = useState({
    card_number: {
      first: '',
      second: '',
      third: '',
      fourth: '',
    },
    birth: '',
    expiry: { yy: '', mm: '' },
    pwd_2digit: '',
    name: '',
    cvc: '',
    isAgreePolicy: false,
  });

  const theme: Theme = useTheme();
  const themeCss = css`
    background-color: ${theme.colors.bg1};
  `;
  const { toastStore, dialogStore } = useDataStore();

  // const errorHandler = (err: any) => {
  //   switch (err.status) {
  //     case 400:
  //       dialogStore.showMessage(i18next.t('알림'), i18next.t('잘못된 요청입니다.'));
  //       break;
  //     case 409:
  //       dialogStore.showMessage(i18next.t('알림'), err.data.message);
  //       break;
  //     case 422:
  //       dialogStore.showMessage(i18next.t('알림'), i18next.t('카드 정보를 다시 확인해 주세요.'));
  //       break;
  //     default:
  //       dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시후 다시 시도해주세요.'));
  //       break;
  //   }
  // };

  const registerCard = (form: StoreCardRequestBody) => {
    PaymentApi()
      .registrationCard(StorageService.selectedMall.id, form)
      .then(() => {
        toastStore.showToast('basic', 'success', i18next.t('결제카드가 정상적으로 등록되었습니다.'));
        if (props.refetch) {
          props.refetch();
        }
        if (props.close) {
          props.close();
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  const getRefinedChargeForm = () => {
    const creditCard: any = {};

    creditCard.card_number = String.prototype.concat(
      chargeForm.card_number.first,
      chargeForm.card_number.second,
      chargeForm.card_number.third,
      chargeForm.card_number.fourth,
    );
    creditCard.expiry_year = chargeForm.expiry.yy;
    creditCard.expiry_month = chargeForm.expiry.mm;
    creditCard.is_corporation = cardType === PayingCardType.CORPORATION;
    if (cardType === PayingCardType.PERSONAL) creditCard.birth = chargeForm.birth.replace(/-/g, '');
    if (cardType === PayingCardType.CORPORATION) creditCard.registration = chargeForm.birth.replace(/-/g, '');
    creditCard.cvc = chargeForm.cvc;
    creditCard.name = chargeForm.name;
    creditCard.password_first_2digit = chargeForm.pwd_2digit;

    return creditCard as StoreCardRequestBody;
  };

  const setCardNumber = (key: any, value: any) => {
    setChargeForm({ ...chargeForm, card_number: { ...chargeForm!.card_number, [key]: value } });
  };

  const setExpiry = (key: any, value: any) => {
    setChargeForm({ ...chargeForm, expiry: { ...chargeForm!.expiry, [key]: value } });
  };

  const setPassWord = (number: React.ChangeEvent<HTMLInputElement>) => {
    setChargeForm({ ...chargeForm, pwd_2digit: number.target.value });
  };

  const setBirth = (number: React.ChangeEvent<HTMLInputElement>) => {
    setChargeForm({ ...chargeForm, birth: number.target.value });
  };

  const setName = (name: React.ChangeEvent<HTMLInputElement>) => {
    setChargeForm({ ...chargeForm, name: name.target.value });
  };

  const setCvc = (number: React.ChangeEvent<HTMLInputElement>) => {
    setChargeForm({ ...chargeForm, cvc: number.target.value });
  };

  const setIsAgreePolicy = (value: boolean) => {
    setChargeForm({ ...chargeForm, isAgreePolicy: value });
  };

  const isFormValid = (): boolean => {
    return Boolean(
      chargeForm &&
        chargeForm.card_number &&
        chargeForm.card_number.first &&
        chargeForm.card_number.second &&
        chargeForm.card_number.third &&
        chargeForm.card_number.fourth &&
        chargeForm.birth &&
        chargeForm.expiry.mm &&
        chargeForm.expiry.yy &&
        chargeForm.pwd_2digit &&
        chargeForm.cvc &&
        chargeForm.name &&
        chargeForm.isAgreePolicy,
    );
  };

  const nhCardNumberList = [
    '356316',
    '356317',
    '356418',
    '356516',
    '485479',
    '524040',
    '524041',
    '543000',
    '543017',
    '542416',
    '546112',
    '941116',
    '944116',
    '946316',
  ] as string[];

  useEffect(() => {
    const cardNumber = chargeForm.card_number.first + chargeForm.card_number.second.substring(0, 2);
    const findeIndex = nhCardNumberList.findIndex((number) => cardNumber.includes(number));

    if (findeIndex !== -1) {
      setIsNhcard(true);
    } else {
      setIsNhcard(false);
    }
  }, [chargeForm.card_number]);
  const formValid = useMemo<boolean>(() => isFormValid(), [chargeForm]);

  return (
    <div css={[baseCss, themeCss]} className="modal-new-card-panel">
      <div className="modal-header">
        <div className="text-h1 text-color-main">
          {!props.card ? i18next.t('결제 카드 등록') : i18next.t('결제 카드 변경')}
        </div>
      </div>

      {/* 새로운 카드 추가 */}
      <div className="new-card-list">
        <React.Fragment>
          <div className="card-item">
            <div className="card-header">{i18next.t('카드 종류')}</div>
            <div className="bg-radio-wrapper flex-row">
              <div className={`radio-type flex-row ${cardType === PayingCardType.CORPORATION ? 'active' : ''}`}>
                <BGRadioButton
                  inputProps={{
                    id: PayingCardType.CORPORATION,
                    name: PayingCardType.CORPORATION,
                    checked: cardType === PayingCardType.CORPORATION,
                    onChange: () => {
                      setChargeForm({ ...chargeForm, birth: '' });
                      setCardType(PayingCardType.CORPORATION);
                    },
                  }}
                  labelText={i18next.t('법인 카드')}
                />
              </div>
              <div className={`radio-type flex-row ${cardType === PayingCardType.PERSONAL ? 'active' : ''}`}>
                <BGRadioButton
                  inputProps={{
                    id: PayingCardType.PERSONAL,
                    name: PayingCardType.PERSONAL,
                    checked: cardType === PayingCardType.PERSONAL,
                    onChange: () => {
                      setChargeForm({ ...chargeForm, birth: '' });
                      setCardType(PayingCardType.PERSONAL);
                    },
                  }}
                  labelText={i18next.t('개인 카드')}
                />
              </div>
            </div>
          </div>
          {cardType === 'personal' ? (
            <div className="card-item">
              <div className="card-header">{i18next.t('생년월일')}</div>
              <BGInput
                inputProps={{
                  placeholder: i18next.t('생년월일 6자리(ex.950101)'),
                  maxLength: MAX_BIRTH_DATE,
                  onChange: (number) => setBirth(number),
                }}
              />
            </div>
          ) : (
            <div className="card-item">
              <div className="card-header">{i18next.t('사업자 등록번호')}</div>
              <BGInput
                inputProps={{
                  placeholder: i18next.t('사업자 등록번호 10자리'),
                  maxLength: MAX_REGISTRATION,
                  onChange: (number) => setBirth(number),
                }}
              />
            </div>
          )}
          <div className="card-item">
            <div className="card-header">{i18next.t('카드 번호')}</div>
            <InputCardNumber nextRef={ref} valueHandle={(key, value) => setCardNumber(key, value)} />
            {isNhCard && (
              <InputError message={i18next.t('농협카드는 카드사 사유로 인해 등록할 수 없습니다.') as string} />
            )}
          </div>
          <div className="card-item custom">
            <div className="card-item-sub">
              <div className="card-header">{i18next.t('유효기간')}</div>
              <InputExpiryDate ref={ref} nextRef={pwRef} valueHandle={(key, value) => setExpiry(key, value)} />
            </div>
            <div className="card-item-sub">
              <div className="card-header">{i18next.t('비밀번호 앞 2자리')}</div>
              <BGInput
                ref={pwRef}
                inputProps={{
                  autoComplete: 'new-password',
                  placeholder: '**',
                  type: 'password',
                  maxLength: MAX_PASSWORD,
                  onChange: (number) => setPassWord(number),
                }}
              />
            </div>
          </div>
          <div className="card-item custom">
            <div className="card-item-sub">
              <div className="card-header">{i18next.t('CVC 번호')}</div>
              <BGInput
                inputProps={{
                  placeholder: i18next.t('카드 뒷면 3자리 숫자'),
                  maxLength: MAX_CVC,
                  onChange: (number) => setCvc(number),
                }}
              />
            </div>
            <div className="card-item-sub">
              <div className="card-header">{i18next.t('카드 소유자 이름')}</div>
              <BGInput
                inputProps={{
                  placeholder: i18next.t('성 / 이름'),
                  onChange: (name) => setName(name),
                }}
              />
            </div>
          </div>
          <div className="card-item mb-0">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="checkbox-wrapper">
                <BGCheckbox
                  size={16}
                  checked={chargeForm.isAgreePolicy}
                  onChange={() => {
                    setIsAgreePolicy(!chargeForm.isAgreePolicy);
                  }}
                />
                <span
                  className="checkbox-label"
                  onClick={() => {
                    setIsAgreePolicy(!chargeForm.isAgreePolicy);
                  }}
                >
                  {i18next.t('결제 정책 및 방식에 동의합니다.')}
                </span>
              </div>
              <a
                className="policy-link"
                href="https://www.notion.so/13672e5ccdc749e6b5d3473bc95b456e"
                target="_blank"
                rel="noreferrer"
              >
                {i18next.t('내용 보기')}
              </a>
            </div>
          </div>
        </React.Fragment>
      </div>
      <hr className="horizontal-line" />
      <div className="register-card-button">
        <BDSButton
          label={i18next.t('취소')}
          appearance="secondary"
          style={{ marginRight: '12px' }}
          onClick={props.close}
        />
        <BDSButton
          label={!props.card ? i18next.t('등록') : i18next.t('변경')}
          isDisabled={!formValid || isNhCard}
          onClick={() => {
            const refinedChargeForm = getRefinedChargeForm();
            registerCard(refinedChargeForm);
          }}
        />
      </div>
    </div>
  );
};
