import React, { useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { useRecoilValue } from 'recoil';
import { useMatch } from 'react-router';
import { baseCss } from './AssetHeader.style';
import CampaignSetting from '../../recoil/CampaignSetting';

interface AssetHeaderProps {
  title: string;
  icons: { imgSrc: string; alt: string }[];
  handleIconClick: () => void;
}

export const AssetHeader = (props: AssetHeaderProps): JSX.Element => {
  const { title, icons, handleIconClick } = props;
  const editMatch = useMatch('/malls/:mallId/edit-ad');
  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);

  const isShowCloseBtn = useMemo(() => {
    return !editMatch && campaignState.selectedTypes.length > 1;
  }, [campaignState.selectedTypes, editMatch]);

  return (
    <div className="create-section-header-wrapper" css={baseCss}>
      <div className="title-wrapper">
        {icons.length > 0 && (
          <span className="icon-wrap">
            {icons.map((iconInfo) => (
              <img key={iconInfo.imgSrc + iconInfo.alt} className="icon" src={iconInfo.imgSrc} alt={iconInfo.alt} />
            ))}
          </span>
        )}
        <div className="title">{title}</div>
      </div>
      {isShowCloseBtn && (
        <BDSFontIcon
          name="ic-close"
          size="20px"
          handleClick={() => {
            handleIconClick();
          }}
        />
      )}
    </div>
  );
};
