import React, { useEffect, useState, ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import axios from 'axios';

import { BDSButton, BDSFontIcon } from '@bigin/bigin-ui-components';
import { baseCss } from './EditPassword.style';
import { BGButtonGroup } from '../../../../components/BGButtonGroup/BGButtonGroup';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { UserApi } from '../../../../lib/api/UserApi';
import { useDataStore } from '../../../../context/Store';
import { InputError } from '../../../../components/InputError/InputError';

interface IEditPasswordForm {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}

export interface EditPasswordProps {
  close: () => void;
}

export const ModalEditPassword = (props: EditPasswordProps): ReactElement => {
  const { ...i18next } = useTranslation();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isShowNewPassword, setIsShowNewPassword] = useState<boolean>(false);
  const [isShowNewConfirmPassword, setIsShowNewConfirmPassword] = useState<boolean>(false);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');

  const { dialogStore } = useDataStore();
  const { register, handleSubmit, formState, watch, setValue } = useForm<IEditPasswordForm>({
    mode: 'all',
  });

  const MIN_PASSWORD = 6;
  useEffect(() => {
    register('currentPassword', {
      value: currentPassword,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isLength(value, { min: MIN_PASSWORD })) {
          return i18next.t('{{length}}자 이상 입력해주세요', { length: MIN_PASSWORD }) as string;
        }

        return true;
      },
    });
    register('newPassword', {
      value: newPassword,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isLength(value, { min: MIN_PASSWORD })) {
          return i18next.t('{{length}}자 이상 입력해주세요', { length: MIN_PASSWORD }) as string;
        }

        return true;
      },
    });
    register('newPasswordConfirm', {
      value: newPasswordConfirm,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.equals(value, watch('newPassword'))) {
          return i18next.t('비밀번호가 일치하지않습니다.') as string;
        }

        return true;
      },
    });
  }, []);

  const onSubmit = (data: IEditPasswordForm) => {
    return UserApi()
      .updateMe({
        new_password: data.newPassword,
        new_password_confirmation: data.newPasswordConfirm,
        current_password: data.currentPassword,
      })
      .then(() => {
        props.close();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <div css={[baseCss]}>
      <div className="edit-pannel-wrapper">
        <div className="edit-title-wrapper">
          <div className="text-h1 text-color-main">{i18next.t('비밀번호 변경')}</div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="edit-pannel-password">
            <div className="edit-password-box flex-col">
              <div className="info-title">{i18next.t('현재 비밀번호')}</div>
              <BGInput
                error={!!formState.errors.currentPassword}
                inputProps={{
                  type: isShowPassword ? 'text' : 'password',
                  placeholder: i18next.t('현재 비밀번호를 입력하세요.'),
                  value: currentPassword,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('currentPassword', event.target.value, { shouldValidate: true });
                    setCurrentPassword(event.target.value);
                  },
                }}
                rightLabelComponent={
                  <BDSFontIcon
                    name={isShowPassword ? 'ic-eye' : 'ic-eye-off'}
                    className="icon-preview-wrapper"
                    size="16px"
                    handleClick={() => setIsShowPassword(!isShowPassword)}
                  />
                }
              />
              {formState.errors.currentPassword && (
                <InputError message={formState.errors.currentPassword.message as string} />
              )}
            </div>
          </div>
          <div className="edit-pannel-password">
            <div className="edit-new-password-box flex-col">
              <div className="info-title">{i18next.t('새로운 비밀번호')}</div>
              <BGInput
                error={!!formState.errors.newPassword}
                inputProps={{
                  type: isShowNewPassword ? 'text' : 'password',
                  placeholder: i18next.t('새로운 비밀번호를 입력하세요.'),
                  value: newPassword,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('newPassword', event.target.value, { shouldValidate: true });
                    setNewPassword(event.target.value);
                  },
                }}
                rightLabelComponent={
                  <BDSFontIcon
                    name={isShowNewPassword ? 'ic-eye' : 'ic-eye-off'}
                    className="icon-preview-wrapper"
                    size="16px"
                    handleClick={() => setIsShowNewPassword(!isShowNewPassword)}
                  />
                }
              />
              {formState.errors.newPassword && <InputError message={formState.errors.newPassword.message as string} />}

              <BGInput
                error={!!formState.errors.newPasswordConfirm}
                inputProps={{
                  type: isShowNewConfirmPassword ? 'text' : 'password',
                  placeholder: i18next.t('새로운 비밀번호를 한 번 더 입력하세요.'),
                  value: newPasswordConfirm,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('newPasswordConfirm', event.target.value, { shouldValidate: true });
                    setNewPasswordConfirm(event.target.value);
                  },
                }}
                rightLabelComponent={
                  <BDSFontIcon
                    name={isShowNewConfirmPassword ? 'ic-eye' : 'ic-eye-off'}
                    className="icon-preview-wrapper"
                    size="16px"
                    handleClick={() => setIsShowNewConfirmPassword(!isShowNewConfirmPassword)}
                  />
                }
                style={{
                  marginTop: '8px',
                }}
              />
              {formState.errors.newPasswordConfirm && (
                <InputError message={formState.errors.newPasswordConfirm.message as string} />
              )}
            </div>
          </div>
          <BGButtonGroup rightAlign style={{ marginTop: '24px' }}>
            <BDSButton
              key={i18next.t('취소') as string}
              label={i18next.t('취소')}
              onClick={() => {
                props.close();
              }}
              appearance="secondary"
            />
            <BDSButton key={i18next.t('변경') as string} label={i18next.t('변경')} type="submit" />
          </BGButtonGroup>
        </form>
      </div>
    </div>
  );
};
