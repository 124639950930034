import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { baseCss } from './PlanComplete.style';
import { LogoFrame } from '../../components/LogoFrame/LogoFrame';
import { BGButton } from '../../components/BGButton/BGButton';
import StorageService from '../../services/StorageService';
import { BGButtonGroup } from '../../components/BGButtonGroup/BGButtonGroup';
import { BorderSection } from '../../components/BorderSection/BorderSection';

export const PlanComplete = (): ReactElement => {
  const { mallId, type } = useParams<{ mallId: string; type: PlanType | 'cloud' }>();
  const i18next = useTranslation();
  const navigate = useNavigate();

  return (
    <div css={[baseCss]}>
      <LogoFrame>
        <BorderSection style={{ padding: '20px', width: '320px', overflow: 'hidden' }}>
          <div className="header" style={{ backgroundImage: 'url(/assets/img/plan/graphic.png)' }} />
          <div className="body">
            <p className="text-h3 text-color-main mb-2">
              {i18next.t('{{name}}님!', { name: StorageService.selectedMall.name })}
            </p>
            <p className="text-h4 text-color-main mb-6">
              {i18next.t('마케터를 위한 디지털 마케팅 자동화 솔루션\nBigin Ads에 오신 것을 환영합니다.')}
            </p>
            <div className="mb-4">
              {type !== 'cloud' && (
                <p className="text-input text-color-main">
                  {i18next.t('마케팅 통합 관리와 자동화를 통한 효율적인 마케팅,\nBigin Ads와 함께 시작해보세요.')}
                </p>
              )}
              {type === 'cloud' && (
                <p className="text-input text-color-main">
                  {i18next.t('클라우드 플랜을 선택하셨군요!\n마케팅 진행을 위해 3일 이내에 연락을 드리겠습니다.')}
                </p>
              )}

              {type === 'cloud' && (
                <p className="text-disclaimer mt-4">{i18next.t('*기재된 기간은 영업일 기준입니다.')}</p>
              )}
            </div>
          </div>
          <BGButtonGroup rightAlign>
            {type !== 'cloud' && (
              <BGButton
                label={i18next.t('시작하기')}
                onClick={() => {
                  navigate(`/malls/${mallId}/campaign-dashboard`);
                }}
              />
            )}
            {type === 'cloud' && (
              <BGButton
                label={i18next.t('확인')}
                onClick={() => {
                  StorageService.clear();
                  navigate('/login');
                }}
              />
            )}
          </BGButtonGroup>
        </BorderSection>
      </LogoFrame>
    </div>
  );
};
