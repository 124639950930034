/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/react';

export const baseCss = css`
  display: none;
  color: #53585f;
  flex-direction: column;

  align-items: flex-start;
  width: 100%;
  &.active {
    cursor: default;
    button {
      font-weight: bold;
    }
  }
  button {
    white-space: nowrap;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    color: #53585f;
    padding: 0.625rem 0 0.625rem 2.5rem;
    cursor: pointer;
    background-color: #ffffff;
  }
`;
