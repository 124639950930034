import { css, useTheme, Theme } from '@emotion/react';
import { GS } from '../../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: 100%;
    min-width: fit-content;

    * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: ${GS.gutter}px;
    }

    .border-section-header-wrapper {
      padding: 0 ${GS.gutter}px;
      padding-top: ${GS.gutter}px;
      width: 100%;
      position: relative;

      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      .description-wrapper {
        margin-top: 8px;
      }
    }
    .empty-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .border-section-contents-wrapper {
      padding: 20px;
      min-height: 816px;
      position: relative;

      .template-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
      }

      .template-box {
        border-radius: 8px;
        border: solid 1px ${theme.colors.divider};
        background-color: #fff;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        user-select: none;
        overflow: hidden;

        &:hover {
          border: solid 1px #39f;
          cursor: pointer;
        }

        &.active {
          border: solid 1px #006fff;
          background-color: #fff;

          &:hover {
            border: solid 1px #39f;
            cursor: pointer;
          }
        }

        .template-thumbnail-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          background-color: ${theme.colors.bg2};
          width: 100%;
          height: 200px;
          position: relative;
          padding: 20px;

          .thumbnail {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .icon-wrap {
            display: flex;
            position: absolute;
            top: 20px;
            right: 20px;

            .icon {
              width: 20px;
              height: 20px;
            }
          }
        }
        .title-wrapper {
          display: flex;
          padding: 16px;
          justify-content: space-between;

          .media-icons-wrapper {
            display: flex;
            align-items: center;
            gap: 2px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  `;
};
export const requestModalCss = () => {
  const theme: Theme = useTheme();

  return css`
    .request-modal-header {
      padding: 20px 20px 0;

      .template-info-wrapper {
        border: solid 1px #dce0e8;
        background-color: #f9fafb;
        border-radius: 8px;
        padding: 16px;
        margin-top: 8px;
      }
      .horizontal-line {
        width: 100%;
        height: 1px;
        background-color: #dce0e8;
        margin-top: 16px;
      }
    }

    .request-modal-body {
      .contents-wrapper {
        margin-top: 20px;
        padding: 0 20px;
        .grid-wrapper {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
        }

        .info-title {
          color: ${theme.colors.subFont};
          ${theme.text.h5};
          margin-bottom: 8px;
        }

        .content-upload-container {
          width: 100%;
          height: auto;
          border-radius: 8px;
          border: solid 1px #dce0e8;
          background-color: #f9fafb;
          overflow: hidden;
        }
        .uploader-wrapper {
          padding: 16px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;

          .image-uploader-title-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
          }

          .image-uploader-subtitle {
            // view
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            & > div {
              //text
              ${theme.text.disclaimer}
            }
          }
          .upload-box-uploader {
            display: flex;
            align-items: center;

            .upload-box-thumbnail {
              display: flex;
              justify-content: center;
              align-items: center;

              width: 40px;
              height: 40px;
              margin-right: 8px;
              border-radius: 8px;
              border: solid 1px #dce0e8;
              background-color: #f9fafb;
              overflow: hidden;

              &.landscape {
                width: 160px;
              }
            }
          }
        }
      }

      .footer-wrapper {
        border-top: solid 1px #dce0e8;
        display: flex;
        justify-content: flex-end;
        gap: 12px;
        margin-top: 20px;
        padding: 16px 20px 20px;
        box-sizing: border-box;
      }
    }
  `;
};
