import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { BGButtonProps } from '../../components/BGButton/BGButton';
import { baseCss, leftWrapper, rightWrapper } from './CopyCampaign.style';
import CampaignSetting, {
  campaignAdSettingSelector,
  campaignConvertCampaignAdSettingSelector,
  campaignTypeSelector,
} from '../../recoil/CampaignSetting';
import StorageService from '../../services/StorageService';
import { CampaignApi } from '../../lib/api/CampaignApi';
import { WrapperModal } from '../../components/WrapperModal/WrapperModal';
import { CampaignSummary } from '../CreateCampaign/CampaignSummary/CampaignSummary';
import { ModalNewCard } from '../../components/ModalNewCard/ModalNewCard';
import { PaymentApi } from '../../lib/api/PaymentApi';
import { MallApi } from '../../lib/api/MallApi';
import { Step1CampaignSetting } from '../CreateCampaign/Step1CampaignSetting/Step1CampaignSetting';
import { Button, DialogConfirm } from '../../components/DialogConfirm/DialogConfirm';
import { CopyCampaignModal } from './CopyCampaignModal';
import { CurrencyCodeRecoil } from '../../recoil/Currency';
import { multilingual } from '../../utils/multilingual.util';
import SideNavBarRecoil from '../../recoil/SideNavBar';

interface QueryParams {
  id?: number;
  type?: string;
}

export const CopyCampaign = (): ReactElement => {
  const i18next = useTranslation();
  const [topNavButton, setTopNavButton] = useState<BGButtonProps[] | []>([]);
  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);
  const campaignAdState = useRecoilValue(CampaignSetting.campaignAdSetting);
  const syncCampaignAdState = useSetRecoilState(campaignAdSettingSelector);
  const setSideBarCollpased = useSetRecoilState(SideNavBarRecoil.isCollapsed);
  const location = useLocation();
  const navigate = useNavigate();

  const [copyCampaignModal, setCopyCampaignModal] = useState<boolean>(false);
  const [creditChargeFailModal, setCreditChargeFailModal] = useState<boolean>(false);
  const convertCampaign = useSetRecoilState(campaignConvertCampaignAdSettingSelector);
  const [disabledNextStep, setDisabledNextStep] = useState<boolean>(true);
  const handleCamapignTypes = useSetRecoilState(campaignTypeSelector);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const [newCardModal, setNewCardModal] = useState<boolean>(false);

  const validateNextStep = () => {
    if (campaignState.gender.length === 0) return true;
    if (campaignState.selectedTypes.length === 0) return true;
    if (campaignState.startOption === ('' as CampaignStartOption)) return true;
    if (campaignState.selectedGoal === ('' as CampaignGoal)) return true;
    if (campaignState.selectedGoal === 'purchase_leads' && campaignState.involvements.length === 0) return true;
    if (
      campaignState.selectedTypes.length * multilingual.getMinimumBudgetByCurrency({ currency: currencyCode }) >
      campaignState.budget
    )
      return true;

    return false;
  };

  const { data: card, refetch: refetchCard } = useQuery<Card>(
    'card',
    async () => (await PaymentApi().getRegisteredCard(StorageService.selectedMall.id)).data.data,
  );

  const { data: activeSubscription } = useQuery<Subscription | undefined>('activeSubscription', async () => {
    const res = await MallApi().getMall(StorageService.selectedMall.id, { includes: ['active_subscription'] });

    return res.data.active_subscription;
  });

  const campaignId = useMemo(() => {
    const parsedQueryParams: QueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!parsedQueryParams) return null;

    return parsedQueryParams.id as number;
  }, [location]);

  const campaignType = useMemo(() => {
    const parsedQueryParams: QueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!parsedQueryParams) return null;

    return parsedQueryParams.type as string;
  }, [location]);

  /** 결제 실패 모달 버튼 */
  const paymentFailtedBtns = useMemo((): Button[] => {
    const defaultBtns = [
      {
        label: i18next.t('확인'),
        appearance: 'secondary',
        handleClick: () => {
          navigate(`/malls/${StorageService.selectedMall?.id}/campaign-dashboard`);
          setCreditChargeFailModal(false);
        },
      },
      {
        label: i18next.t('결제 업데이트'),
        handleClick: () => {
          navigate(`/malls/${StorageService.selectedMall?.id}/payment`);
          setCreditChargeFailModal(false);
        },
      },
    ];

    // 싱가포르 프로젝트에서 현재 결제는 제공하지 않으므로 결제 업데이트 버튼 안보이게 처리.
    switch (currencyCode) {
      case 'SGD':
        return [defaultBtns[0]];
      case 'KRW':
      default:
        return defaultBtns;
    }
  }, [currencyCode, navigate, setCreditChargeFailModal]);

  const { data: campaignInfo } = useQuery('getCampaignInfo', async () => {
    if (!campaignId) return;

    const res = await CampaignApi().getUnifiedCampaignInfo(StorageService.selectedMall.id, campaignId);

    const campaignTypes = [] as string[];
    res.data.data.uni_ads_by_type.forEach((campaign) => {
      let convertType = '';

      if (campaign.type.includes('fb_collection')) {
        convertType = 'fb_collection_image';
      } else if (campaign.type.includes('fb_dynamic')) {
        convertType = 'fb_dynamic';
      } else if (campaign.type.includes('shopping')) {
        convertType = 'aw_shopping';
      } else {
        convertType = campaign.type;
      }

      if (!campaignTypes.includes(convertType)) {
        campaignTypes.push(convertType);
      }

      campaign.uni_ads.forEach((ad) => {
        convertCampaign(ad as any);
      });
    });

    campaignTypes.forEach((type) => {
      handleCamapignTypes(type);
    });
    // eslint-disable-next-line consistent-return
    return res.data.data;
  });
  useEffect(() => {
    if (!campaignInfo || !campaignType) return;
    const types = [] as string[];
    types.push(campaignType);
    if (types.length > 0) {
      syncCampaignAdState({ types, goal: campaignInfo.goals[0] });
    }
  }, [campaignInfo, campaignId]);

  const setTopNavBtn = () => {
    const btn = [];
    btn.push({
      id: 0,
      label: i18next.t('취소'),
      appearance: 'secondary',
      onClick: () => {
        navigate(`/malls/${StorageService.selectedMall?.id}/campaign-report?id=${campaignId}`);
      },
      style: { marginRight: '8px' },
    });

    btn.push({
      id: 1,
      isDisabled: disabledNextStep,
      label: i18next.t('캠페인 생성'),
      onClick: async () => {
        setCopyCampaignModal(true);
      },
    });

    setTopNavButton(btn);
  };

  useEffect(() => {
    setDisabledNextStep(validateNextStep());
    setTopNavBtn();
  }, [disabledNextStep, campaignState, campaignAdState]);

  useEffect(() => {
    // 1320 이하에서는 사이드바 접히도록 설정
    const handleResize = () => {
      if (window.innerWidth <= 1920) {
        setSideBarCollpased(true);
      } else {
        setSideBarCollpased(false);
      }
    };
    handleResize();

    // Create 들어왔을 때 1320px 이하의 브라우저라면 사이드바 접기.
    window.addEventListener('resize', handleResize);
    return () => {
      setSideBarCollpased(false);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <div css={[baseCss]}>
        <TopNavBar
          title={
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {i18next.t('캠페인 복사')}
            </div>
          }
          button={topNavButton}
        />
        <div className="copy-campaign-container flex-row">
          <div css={[leftWrapper]} className="left-wrapper flex-col">
            {campaignInfo && <Step1CampaignSetting key="copy-campaign-step1" />}
          </div>
          <div css={[rightWrapper]} className="right-wrapper flex-col">
            <CampaignSummary handleCardModal={setNewCardModal} card={card} step={1} />
          </div>
        </div>
      </div>
      {/* 캠페인 복사 모달 */}
      <WrapperModal
        position={{ width: '320px' }}
        isOpen={copyCampaignModal}
        close={() => {
          setCopyCampaignModal(false);
        }}
      >
        {campaignInfo && (
          <CopyCampaignModal
            name={i18next.t('{{campaignName}}의 사본', {
              campaignName: campaignInfo.name,
            })}
            activeSubscription={activeSubscription}
            close={() => {
              setCopyCampaignModal(false);
            }}
            handleCreditFailModal={() => {
              setCreditChargeFailModal(true);
            }}
          />
        )}
      </WrapperModal>
      {/* 카드 등록/수정 모달 */}
      <WrapperModal
        position={{ width: '360px' }}
        isOpen={newCardModal}
        close={() => {
          setNewCardModal(false);
        }}
      >
        <ModalNewCard
          card={card}
          refetch={refetchCard}
          close={() => {
            setNewCardModal(false);
          }}
        />
      </WrapperModal>
      {/* 결제 실패 모달 */}
      <WrapperModal isOpen={creditChargeFailModal} isDialogConfirm>
        <DialogConfirm
          title={i18next.t('결제 실패')}
          desc={i18next.t('캠페인이 생성되었으나 결제에 실패하였습니다.\n결제 정보를 업데이트 해주세요.')}
          buttons={paymentFailtedBtns}
        />
      </WrapperModal>
    </React.Fragment>
  );
};
