import { UseTranslationResponse } from 'react-i18next';

import SmartstorePlan from './SmartstorePlan';
import BrandmallPlan from './BrandmallPlan';
import CloudPlan from './CloudPlan';
import ManagedPlan from './ManagedPlan';

export default class PlanBuilder {
  public static build(type: PlanType | 'cloud', i18next: UseTranslationResponse<'translation'>) {
    if (type === 'smartstore') {
      return new SmartstorePlan(i18next);
    }
    if (type === 'brandmall') {
      return new BrandmallPlan(i18next);
    }
    if (['cloud', 'cloud_tier_1'].indexOf(type) > -1) {
      return new CloudPlan(i18next, 1);
    }
    if (type === 'cloud_tier_2') {
      return new CloudPlan(i18next, 2);
    }
    if (type === 'cloud_tier_3') {
      return new CloudPlan(i18next, 3);
    }
    if (type === 'cloud_tier_4') {
      return new CloudPlan(i18next, 4);
    }
    if (type === 'cloud_tier_5') {
      return new CloudPlan(i18next, 5);
    }
    if (type === 'managed') {
      return new ManagedPlan(i18next);
    }

    return new SmartstorePlan(i18next);
  }
}
