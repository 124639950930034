import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { BDSButton } from '@bigin/bigin-ui-components';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import moment from 'moment';
import { copyCampaignModalCss } from './CopyCampaign.style';
import CampaignSetting from '../../recoil/CampaignSetting';
import StorageService from '../../services/StorageService';
import { CampaignApi } from '../../lib/api/CampaignApi';
import { PaymentApi } from '../../lib/api/PaymentApi';
import { convertGroups } from '../../model/CampaignModel';
import { useDataStore } from '../../context/Store';
import { BGButtonGroup } from '../../components/BGButtonGroup/BGButtonGroup';
import { BGInput } from '../../components/BGInput/BGInput';
import { filters } from '../../utils/filter';
import { CurrencyCodeRecoil } from '../../recoil/Currency';
import { multilingual } from '../../utils/multilingual.util';
import Plan from '../../services/plans/Plan';

interface CopyCampaignForm {
  name: string;
}

interface CopyCampaignModalProps {
  name: string;
  activeSubscription: Subscription | undefined;
  handleCreditFailModal: () => void;
  close: () => void;
}

export const CopyCampaignModal = (props: CopyCampaignModalProps): ReactElement => {
  const i18next = useTranslation();
  const { toastStore } = useDataStore();
  const [name, setName] = useState<string>(props.name);
  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);
  const campaignAdState = useRecoilValue(CampaignSetting.campaignAdSetting);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const navigate = useNavigate();
  const resetCampaignState = useResetRecoilState(CampaignSetting.campaignSetting);
  const resetCampaignAdState = useResetRecoilState(CampaignSetting.campaignAdSetting);
  const { formState, register, setValue, handleSubmit } = useForm<CopyCampaignForm>({ mode: 'all' });
  useEffect(() => {
    register('name', {
      value: name,
    });
  });

  const { data: autoCharge } = useQuery<boolean | undefined>(
    'autoCharge',
    async () => (await PaymentApi().getAutoCharge(StorageService.selectedMall.id)).data.data,
  );

  const dayCount = useMemo(() => {
    if (campaignState.startOption === 'endless' || campaignState.startOption === ('' as CampaignStartOption)) {
      return 7;
    }

    const start = moment(campaignState.date.start, 'YYYY-MM-DD');
    const end = moment(campaignState.date.end, 'YYYY-MM-DD');

    return moment.duration(end.diff(start)).asDays() + 1;
  }, [campaignState.startOption, campaignState.date.start, campaignState.date.end]);

  const handleCampaignActivation = async ({
    createCampaignId,
    isAutoCharge,
  }: {
    createCampaignId: number;
    isAutoCharge?: boolean;
  }) => {
    try {
      await CampaignApi().patchUnifiedCampaignStatus(StorageService.selectedMall.id, createCampaignId, 'active');
      navigate(`/malls/${StorageService.selectedMall?.id}/campaign-dashboard`);
      toastStore.showToast('basic', 'success', i18next.t('캠페인 생성이 완료되었습니다.'));
      if (isAutoCharge) {
        toastStore.showToast('basic', 'success', i18next.t('광고 집행을 위해 필요한 크레딧이 자동 충전되었습니다.'));
      }
      useQueryClient().invalidateQueries('remain'); // 캠페인 복사 후 잔액 최신화
      resetCampaignState();
      resetCampaignAdState();
      props.close();
    } catch (error) {
      console.error(error);
      return;
    } finally {
      props.close();
    }
  };

  const onSubmit = async () => {
    if (!props.activeSubscription) return;
    let createCampaignId;

    const groups = await convertGroups(campaignAdState, 'copy');
    const config = {
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      },
    };

    try {
      const createCampaignRes = await CampaignApi().postUnifiedCampaign({
        mallId: StorageService.selectedMall?.id,
        plan: props.activeSubscription.type,
        settings: { ...campaignState, name },
        campaigns: campaignAdState,
        groups,
        config,
        currency: currencyCode,
        country: multilingual.getCountryByCurrencyCode({ currency: currencyCode }),
      });
      createCampaignId = createCampaignRes.data.data.id;
    } catch (e) {
      console.error(e);
      return;
    }
    if (Plan.isManagedType(props.activeSubscription.type)) {
      // managed 플랜의 경우에는 자동 크레딧 충전 옵션이 없고, 따로 해외 송금 처리가 된다.
      handleCampaignActivation({ createCampaignId });
    } else {
      // 이 경우에는 클라이언트 측에서 자동 충전 on off가 가능하기 때문에 충전 요청 로직이 필요해서 남겨두었습니다.
      let successCharge = true;
      let successAutoCharge = false;
      if (campaignState.chargeCredit > 0 && campaignState.chargeAgree) {
        try {
          const res = await PaymentApi().chargeCredits(
            StorageService.selectedMall.id,
            campaignState.chargeCredit * 1e6,
          );
          if (res) {
            successCharge = true;
            successAutoCharge = true;
          }
        } catch (e) {
          successCharge = false;
        }
      } else if (campaignState.chargeCredit > 0 && campaignState.chargeAgree === false) {
        successCharge = false;
      }

      if (!successCharge) {
        props.close();
        props.handleCreditFailModal();
      } else {
        handleCampaignActivation({
          createCampaignId,
          isAutoCharge: (autoCharge && campaignState.chargeCredit > 0) || successAutoCharge,
        });
      }
    }
  };

  return (
    <div css={[copyCampaignModalCss]}>
      <div className="copy-campaign-header">
        <div className="text-h1 text-color-main ">{i18next.t('캠페인 생성')}</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="copy-campaign-body">
          <p className="text-input text-color-main mb-2">
            {i18next.t('앞서 설정한 내용으로 캠페인을 생성할게요.')}
            <br />
            {i18next.t('캠페인 명을 입력하고 설정한 캠페인의 일 예산과 노출 기간을 확인해 주세요.')}
          </p>
          <p className="field-title" style={{ marginTop: 20 }}>
            {i18next.t('캠페인 명')}
          </p>
          <BGInput
            style={{ marginTop: 8 }}
            error={!!formState.errors.name}
            inputProps={{
              value: name,
              placeholder: i18next.t('캠페인 명을 입력해주세요.'),
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('name', event.target.value, { shouldValidate: true });
                setName(event.target.value);
              },
            }}
          />
          <div className="horizontal-divider" />
          <div className="field-wrapper">
            <p className="field-title" style={{ width: '52px' }}>
              {i18next.t('일 예산')}
            </p>
            <p className="field-title">{filters.formatCurrency({ value: campaignState.budget, currencyCode })}</p>
          </div>
          <div className="horizontal-divider" />
          <div className="field-wrapper">
            <p className="field-title" style={{ width: '52px' }}>
              {i18next.t('노출기간')}
            </p>
            <p className="field-title">
              {campaignState.startOption === 'endless' && <div>{i18next.t('오늘부터 계속 게재')}</div>}
              {campaignState.startOption && campaignState.date?.end && (
                <div>{`${campaignState.date.start} ~ ${campaignState.date.end}`}</div>
              )}
              {campaignState.startOption && campaignState.date?.end && (
                <div style={{ fontWeight: 'normal', marginTop: '4px' }}>
                  {i18next.t('(총 {{days}}일)', {
                    days: dayCount,
                  })}
                </div>
              )}
            </p>
          </div>
          <div className="horizontal-divider" />
          <p className="text-disclaimer">{i18next.t('*캠페인 생성 후, 광고 유형별 검토 과정이 이루어집니다.')}</p>
        </div>
        <div className="horizontal-divider" style={{ width: '320px', margin: '16px -20px' }} />
        <div className="copy-campaign-footer">
          <BGButtonGroup rightAlign>
            <BDSButton
              key={i18next.t('취소') as string}
              label={i18next.t('취소')}
              onClick={() => {
                props.close();
              }}
              appearance="secondary"
            />
            <BDSButton label={i18next.t('생성')} type="submit" isDisabled={!formState.isValid} />
          </BGButtonGroup>
        </div>
      </form>
    </div>
  );
};
