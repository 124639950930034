import React, { ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { BDSButton } from '@bigin/bigin-ui-components';
import { baseCss } from './LogoUploadModal.style';
import { BGButtonGroup } from '../BGButtonGroup/BGButtonGroup';
import { MallApi } from '../../lib/api/MallApi';
import StorageService from '../../services/StorageService';
import { useDataStore } from '../../context/Store';
import { FontIcon } from '../FontIcon/FontIcon';

interface InputFileProps {
  accept: string;
  label: string;
  onChange: ChangeEventHandler;
}

const InputFile = (props: InputFileProps): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <React.Fragment>
      <input type="file" accept={props.accept} ref={inputRef} onChange={props.onChange} style={{ display: 'none' }} />
      <BDSButton
        label={props.label}
        onClick={() => {
          inputRef?.current?.click();
        }}
      />
    </React.Fragment>
  );
};

interface ILogoUploadModalForm {
  square: File | undefined;
  landscape: File | undefined;
}

export interface LogoUploadModalProps {
  square?: string;
  landscape?: string;
  media?: 'facebook' | 'google' | 'kakao' | 'image' | string;
  close: () => void;
  onSubmitted?: () => void;
}

export const LogoUploadModal = ({
  square,
  landscape,
  media = 'google',
  close,
  onSubmitted,
}: LogoUploadModalProps): ReactElement => {
  const i18next = useTranslation();
  const { dialogStore } = useDataStore();

  const [squareLogo, setSquareLogo] = useState<string | undefined>(square);
  const [landscapeLogo, setLandscapeLogo] = useState<string | undefined>(landscape);

  const { register, handleSubmit, setValue, getValues, formState } = useForm<ILogoUploadModalForm>({ mode: 'all' });

  const descriptionText = useMemo(() => {
    if (media === 'image') {
      return i18next.t('광고 소재 이미지를 제작하기 위해 \n최초 1회에 한하여 로고 등록이 필요합니다.');
    }
    return i18next.t('{{media}} 광고를 제작하기 위해 \n최초 1회에 한하여 로고 등록이 필요합니다.', {
      media: media[0].toUpperCase() + media.slice(1, media.length),
    });
  }, [media]);
  useEffect(() => {
    register('square', {
      validate: (value: File | undefined) => !(value === undefined && getValues('landscape') === undefined),
    });
    register('landscape', {
      validate: (value: File | undefined) => !(value === undefined && getValues('square') === undefined),
    });
  });

  const loadFile = (file: File, onLoad: (this: FileReader, ev: ProgressEvent<FileReader>) => void) => {
    const fileReader = new FileReader();
    fileReader.onload = onLoad;
    fileReader.readAsDataURL(file);
  };

  const onSubmit = async (form: ILogoUploadModalForm) => {
    return MallApi()
      .updateMallLogo(StorageService.selectedMall.id, {
        ...(form.square ? { square_logo: form.square } : {}),
        ...(form.landscape ? { landscape_logo: form.landscape } : {}),
      })

      .then((res) => {
        if (onSubmitted) {
          onSubmitted();
        }

        StorageService.selectedMall = {
          ...StorageService.selectedMall,
          landscape_logo_url: res.data.data.landscape_logo_url,
          landscape_logo_md5: res.data.data.landscape_logo_md5,
          square_logo_url: res.data.data.square_logo_url,
          square_logo_md5: res.data.data.square_logo_md5,
        };

        close();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <div css={[baseCss]}>
      <div className="logo-upload-modal-header">
        <div className="title">
          <div className="text-h1 text-color-main">{i18next.t('로고 등록 필요')}</div>
        </div>
        <p className="text-input text-color-main">{descriptionText}</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="logo-upload-modal-body">
          {media !== 'image' && (
            <div className="form-input-group">
              <p className="form-input-label">{i18next.t('1:1 비율 로고')}</p>
              <div className="upload-box-wrapper">
                <p className="upload-box-description">
                  {i18next.t('* 파일 형식: JPG, PNG\n* 사이즈: 300px*300px ~ 1200px*1200px\n* 파일 용량: 1MB 미만')}
                </p>
                <div className="upload-box-uploader">
                  <div className="upload-box-thumbnail">
                    {squareLogo ? (
                      <img
                        className="upload-box-image"
                        src={squareLogo}
                        alt="landscape-logo"
                        style={{ width: '100%', height: '100%' }}
                      />
                    ) : (
                      <FontIcon name="ic-img" size="20px" color="#53585f" />
                    )}
                  </div>
                  <InputFile
                    accept="image/*"
                    label={i18next.t('파일 선택')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const file = (e.target.files && e.target.files[0]) || undefined;
                      if (file === undefined) {
                        return;
                      }

                      setValue('square', file, { shouldValidate: true });
                      loadFile(file, (ev: ProgressEvent<FileReader>) => {
                        setSquareLogo(ev.target?.result as string);
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          {media !== 'kakao' && (
            <div className="form-input-group">
              <p className="form-input-label">{i18next.t('4:1 비율 로고')}</p>
              <div className="upload-box-wrapper">
                <p className="upload-box-description">
                  {i18next.t('* 파일 형식: JPG, PNG\n* 사이즈: 512px*128px ~ 1200px*300px\n* 파일 용량: 5MB 미만')}
                </p>
                <div className="upload-box-uploader">
                  <div className="upload-box-thumbnail landscape">
                    {landscapeLogo ? (
                      <img
                        className="upload-box-image"
                        src={landscapeLogo}
                        alt="landscape-logo"
                        style={{ width: '100%', height: '100%' }}
                      />
                    ) : (
                      <FontIcon name="ic-img" size="20px" color="#53585f" />
                    )}
                  </div>
                  <InputFile
                    accept="image/*"
                    label={i18next.t('파일 선택')}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      const file = (e.target.files && e.target.files[0]) || undefined;
                      if (file === undefined) {
                        return;
                      }

                      setValue('landscape', file, { shouldValidate: true });
                      loadFile(file, (ev: ProgressEvent<FileReader>) => {
                        setLandscapeLogo(ev.target?.result as string);
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="logo-upload-modal-footer">
          <BGButtonGroup rightAlign>
            <BDSButton label={i18next.t('취소')} appearance="secondary" onClick={close} />
            <BDSButton label={i18next.t('등록')} type="submit" isDisabled={!formState.isValid} />
          </BGButtonGroup>
        </div>
      </form>
    </div>
  );
};
