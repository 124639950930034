import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton, BDSSelectBox, BDSTextArea } from '@bigin/bigin-ui-components';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { AxiosPromise } from 'axios';
import _ from 'lodash';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss, categoryModalCss } from './FacebookCatalogSlide.style';
import CampaignSetting from '../../../../recoil/CampaignSetting';
import { MaterialCheckboxSelector } from '../../MaterialCheckboxSelector/MaterialCheckboxSelector';
import StorageService from '../../../../services/StorageService';
import { PreviewFacebookItem } from '../PreviewFacebookItem/PreviewFacebookItem';
import { WrapperModal } from '../../../../components/WrapperModal/WrapperModal';
import { CategorySelector } from '../CategorySelector/CategorySelector';
import { ProductApi } from '../../../../lib/api/ProductApi';
import { MallApi } from '../../../../lib/api/MallApi';
import { InputError } from '../../../../components/InputError/InputError';
import { ModalCategoryImageSelector } from '../../../Creative/ModalCategoryImageSelect/ModalCategoryImageSelect';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { facebookCTA } from '../../../../constants/campaigns';
import { BGTagInput } from '../../../../components/BGTagInput/BGTagInput';
import { AssetHeader } from '../../../../components/AssetHeader/AssetHeader';

export interface FacebookCatalogSlideProps {
  setting: CampaignAdSettingState;
  handleUpdate: (setting: CampaignAdSettingState) => void;
  handleValid?: (type: CampaignType, isValid: boolean) => void;
  removeSelectedCampaignTypes: (type: CampaignType) => void;
}

interface FacebookCatalogSlideForm {
  name: string;
  titleTemplate: string;
  descriptionTemplate: string;
  body: string;
  category_id: number | undefined | null;
}

type Button = {
  label: string;
  handleClick?: () => void;
  appearance?: string;
};

const CategorySelectorModalWrapper: React.FC<{
  isShow: boolean;
  buttons: Array<Button>;
  categories: Category[];
  selectedCategory: Category | undefined;
  selectedParentCategory: Category | undefined;
  setCategory: (category: Category | undefined) => void;
  setParentCategory: (category: Category | undefined) => void;
}> = (props: {
  isShow: boolean;
  buttons: Array<Button>;
  categories: Category[];
  selectedCategory: Category | undefined;
  selectedParentCategory: Category | undefined;
  setCategory: (category: Category | undefined) => void;
  setParentCategory: (category: Category | undefined) => void;
}) => {
  return (
    <React.Fragment>
      <div css={[categoryModalCss]}>
        <WrapperModal isOpen={`${props.isShow ? 'true' : ''}`}>
          <CategorySelector
            buttons={props.buttons}
            categories={props.categories}
            selectedCategory={props.selectedCategory}
            selectedParentCategory={props.selectedParentCategory}
            setCategory={props.setCategory}
            setParentCategory={props.setParentCategory}
          />
        </WrapperModal>
      </div>
    </React.Fragment>
  );
};

export const FacebookCatalogSlide = (props: FacebookCatalogSlideProps): ReactElement => {
  const i18next = useTranslation();

  const [showCategorySelector, setShowCategorySelector] = useState<boolean>(false);
  const [showChangeDefaultImageSelector, setShowChangeDefaultImageSelector] = useState<boolean>(false);

  const templateTextTitle: { label: string; value: string | null }[] = [
    { label: i18next.t('{상품이름}'), value: '{{product.name}}' },
    { label: i18next.t('{판매가격}'), value: '{{product.current_price}}' },
    { label: i18next.t('{쇼핑몰} BEST 상품'), value: '{{product.brand}} BEST 상품' },
    { label: i18next.t('{판매가격} > {할인가격}'), value: '{{product.price}} > {{product.current_price}}' },
  ];
  const templateTextDescription: { label: string; value: string | null }[] = [
    { label: i18next.t('{상품이름}'), value: '{{product.name}}' },
    { label: i18next.t('{판매가격}'), value: '{{product.current_price}}' },
    { label: i18next.t('{쇼핑몰} BEST 상품'), value: '{{product.brand}} BEST 상품' },
    { label: i18next.t('{판매가격} > {할인가격}'), value: '{{product.price}} > {{product.current_price}}' },
  ];

  const [selectedTitleTemplate, setSelectedTitleTemplate] = useState<string>(props.setting?.fb_dynamic_slide_title);
  const [selectedDescriptionTemplate, setSelectedDescriptionTemplate] = useState<string>(
    props.setting?.fb_dynamic_slide_description,
  );

  const [selectedCTA, setSelectedCTA] = useState<FacebookCTA>(
    props.setting?.fb_call_to_action ? props.setting?.fb_call_to_action : 'shop_now',
  );
  const [name, setName] = useState(props.setting?.name);
  const [body, setBody] = useState(props.setting?.fb_body);
  const [biginKeywords, setBiginKeywords] = useState<string[]>(props.setting?.bigin_keywords);

  const [selectedParentCategory, setSelectedParentCategory] = useState<Category | undefined>(undefined);
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>(props.setting?.category_id);
  const [categoriesState, setCategoriesState] = useState<Category[]>([] as Category[]);
  const [categoryProducts, setCategoryProducts] = useState<Product[]>([] as Product[]);

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);

  const { register, formState, setValue } = useForm<FacebookCatalogSlideForm>({
    mode: 'onChange',
  });

  const fetchCategoryProducts = async (categoryId: number) => {
    const query = `((categories ct ${categoryId}))`;
    const res = await ProductApi().getProducts(StorageService.selectedMall?.id ?? null, {
      query,
      page: 1,
      per_page: 4,
      includes: ['images'],
    });
    setCategoryProducts(res.data.data);
  };

  const updateCategoryProduct = () => {
    if (props.setting?.category_id) {
      fetchCategoryProducts(props.setting?.category_id);
    } else {
      setCategoryProducts([]);
    }
  };

  const handleDefaultImages = async (images: ProductImage[]) => {
    const payloads: AxiosPromise<{ data: ProductImage }>[] = [];
    images.forEach((image) => {
      payloads.push(ProductApi().updateDefaultImage(StorageService.selectedMall.id, image.product_id, image.id));
    });
    await Promise.all(payloads);
    setShowChangeDefaultImageSelector(false);
    if (props.setting?.category_id) fetchCategoryProducts(props.setting?.category_id);
  };

  const hasCategoryId = useMemo(() => {
    if (props.setting?.category_id) return true;
    return false;
  }, [props.setting?.category_id]);

  const updateName = (curName: string) => {
    props.handleUpdate({ ...props?.setting, name: curName });
  };

  const updateBody = (curBody: string) => {
    props.handleUpdate({ ...props?.setting, fb_body: curBody });
  };

  const updateCTA = (curCTA: FacebookCTA) => {
    props.handleUpdate({ ...props?.setting, fb_call_to_action: curCTA });
  };

  const updateDynamicSlideTitle = (curTemplateTitle: string) => {
    props.handleUpdate({ ...props?.setting, fb_dynamic_slide_title: curTemplateTitle });
  };

  const updateDynamicSlideDescription = (curTemplateDescription: string) => {
    props.handleUpdate({ ...props?.setting, fb_dynamic_slide_description: curTemplateDescription });
  };

  const updateCategoryId = (curCategoryId: number) => {
    props.handleUpdate({ ...props?.setting, category_id: curCategoryId });
    updateCategoryProduct();
  };

  const updateBiginKeywords = (curKeywords: string[]) => {
    props.handleUpdate({ ...props?.setting, bigin_keywords: curKeywords });
  };

  const buttons = [
    {
      label: i18next.t('취소'),
      appearance: 'secondary',
      handleClick: () => {
        setShowCategorySelector(false);
      },
    },
    {
      label: i18next.t('선택'),
      handleClick: () => {
        setShowCategorySelector(false);
        setSelectedCategoryId(selectedCategory?.id);
        setValue('category_id', selectedCategory?.id, { shouldValidate: true });
      },
    },
  ];

  useEffect(() => {
    if (name !== props.setting?.name) {
      updateName(name);
    }
    if (body !== props.setting?.fb_body) {
      updateBody(body);
    }
    if (selectedCTA && selectedCTA !== props.setting?.fb_call_to_action) {
      updateCTA(selectedCTA);
    }
    if (selectedTitleTemplate && selectedTitleTemplate !== props.setting?.fb_dynamic_slide_title) {
      updateDynamicSlideTitle(selectedTitleTemplate);
    }
    if (selectedDescriptionTemplate && selectedDescriptionTemplate !== props.setting?.fb_dynamic_slide_description) {
      updateDynamicSlideDescription(selectedDescriptionTemplate);
    }
    if (selectedCategoryId && selectedCategoryId !== props.setting?.category_id) {
      updateCategoryId(selectedCategoryId);
    }
    if (!_.isEqual(biginKeywords, props.setting?.bigin_keywords)) {
      updateBiginKeywords(biginKeywords);
    }
  }, [name, body, selectedCTA, selectedTitleTemplate, selectedDescriptionTemplate, selectedCategoryId, biginKeywords]);

  const fetchCategories = async () => {
    const res = await MallApi().getCategories(StorageService.selectedMall?.id ?? null, {
      includes: ['product_summary'],
    });
    setCategoriesState(res.data.data);
    return res.data.data as Category[];
  };

  const findCategory = (categoryId: number) => {
    let tempCategory: Category | undefined;

    // eslint-disable-next-line consistent-return
    const searchCategory = (category: Category, findCategoryId: number) => {
      if (category.id === findCategoryId) {
        tempCategory = category;
        return tempCategory;
      }
      category.children.map((childCategory) => searchCategory(childCategory, categoryId));
    };

    categoriesState.map((category) => searchCategory(category, categoryId));

    // eslint-disable-next-line consistent-return
    return tempCategory as Category | undefined;
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    async function getCategories() {
      if (props.setting?.category_id) {
        setSelectedCategory(findCategory(props.setting?.category_id));
      }
    }
    getCategories();
  }, [categoriesState]);

  useEffect(() => {
    if (selectedCategory) {
      if (selectedCategory.parent_id) {
        setSelectedParentCategory(findCategory(selectedCategory.parent_id));
      } else {
        setSelectedParentCategory(selectedCategory);
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    updateCategoryProduct();
  }, [props.setting?.category_id]);

  useEffect(() => {
    register('body', {
      value: body,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('titleTemplate', {
      value: selectedTitleTemplate,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('descriptionTemplate', {
      value: selectedDescriptionTemplate,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('category_id', {
      value: selectedCategoryId,
      required: i18next.t('필수 입력란입니다.') as string,
    });
  }, []);

  useEffect(() => {
    if (props.handleValid) {
      props.handleValid(props.setting?.type, formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <BorderSection>
      <div className="fb-catalog-slide-setting-wrapper" css={[baseCss]}>
        <AssetHeader
          title={i18next.t('카탈로그 슬라이드')}
          icons={[
            { imgSrc: '/assets/img/card_icons/ic-channel-facebook.png', alt: 'Facebook' },
            { imgSrc: '/assets/img/card_icons/ic-channel-instagram.png', alt: 'Instagram' },
          ]}
          handleIconClick={() => {
            props.removeSelectedCampaignTypes(props.setting.type);
          }}
        />
        <div className="create-section-content-wrapper">
          <div className="create-section-content">
            <div className="preview-container">
              <div className="sticky-wrap">
                <div className="title-panel">
                  <div className="title">{i18next.t('미리보기')}</div>
                  <div className="info">{i18next.t('*실제 노출과 미리보기가 다를 수 있습니다.')}</div>
                </div>
                <div className="material-preview-wrapper">
                  <div className="material-preview">
                    <div className="facebook-wrapper">
                      <div className="preview-media-title-wrapper">Facebook</div>
                      <PreviewFacebookItem setting={props.setting} products={categoryProducts} />
                    </div>
                    <div className="instagram-wrapper">
                      <div className="preview-media-title-wrapper">Instagram</div>
                      <PreviewFacebookItem setting={props.setting} instagram products={categoryProducts} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <h2 className="material-field-header">{i18next.t('광고 소재명')}</h2>
              <div className="material-field-selector-wrapper">
                <BGInput
                  style={{ width: '100%' }}
                  inputProps={{
                    name: 'name',
                    placeholder: i18next.t('광고 소재 이름을 입력해주세요.'),
                    value: name || '',
                    onChange: (event) => {
                      setName(event.target.value);
                    },
                  }}
                />
                <p className="description">{i18next.t('*다른 소재와 구분지을 수 있게 이름을 입력하세요.')}</p>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 상품 카테고리')}</h2>
              <div className="material-field-selector-wrapper">
                <div className="content-upload-container">
                  <div className="single-image-uploader-wrapper uploader-wrapper">
                    <div className="image-uploader-title-wrapper">
                      <div className="image-uploader-subtitle">
                        <div>{i18next.t('카테고리 상품의 대표 이미지가 보여집니다.')}</div>
                        <div>{i18next.t('상품수가 5개 이상인 카테고리만 선택 가능합니다.')}</div>
                      </div>
                    </div>
                    <div className="single-image-uploader-wrapper category-wrapper">
                      <BDSButton
                        className="image-updater-btn"
                        onClick={() => {
                          setShowCategorySelector(true);
                        }}
                        label={i18next.t('카테고리 선택')}
                      />
                      {typeof selectedCategoryId === 'number' && selectedCategory && (
                        <div className="selected-category-name">{selectedCategory.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="horizontal-line" />
                  <div className="single-image-uploader-wrapper uploader-wrapper">
                    <div className="image-uploader-title-wrapper">
                      <div className="image-uploader-title">{i18next.t('대표 이미지 변경')}</div>
                      <div className="image-uploader-subtitle">
                        <div>{i18next.t('선택된 카테고리 상품의 대표 노출 이미지를 변경합니다.')}</div>
                        <div>{i18next.t('변경을 원한다면 아래 버튼을 클릭하여 이미지를 변경해주세요.')}</div>
                      </div>
                    </div>
                    <div className="single-image-uploader-wrapper">
                      <BDSButton
                        className="image-updater-btn"
                        isDisabled={!hasCategoryId}
                        appearance="secondary"
                        onClick={() => {
                          setShowChangeDefaultImageSelector(true);
                        }}
                        label={i18next.t('수정')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 텍스트')}</h2>
              <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 문구')} checkboxLabel="" value hiddenCheckbox />
                <div className="material-field-input-wrapper">
                  <BDSTextArea
                    isAutoGrow
                    error={!!formState.errors.body}
                    style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                    textAreaProps={{
                      name: 'fb_body',
                      cols: 30,
                      rows: 3,
                      style: {
                        minHeight: 49,
                      },
                      value: body || '',
                      placeholder: i18next.t('광고 문구를 입력해주세요.'),
                      onChange: (event) => {
                        setBody(event.target.value);
                        setValue('body', event.target.value, { shouldValidate: true });
                      },
                    }}
                    curLength={body.length}
                    limitLength={120}
                    lengthUnit={i18next.t('자')}
                  />
                  {formState.errors.body && <InputError message={formState.errors.body.message as string} />}
                </div>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('제목 템플릿')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <BDSSelectBox
                  appearance="gray"
                  style={{ width: '100%', marginTop: '8px' }}
                  list={templateTextTitle}
                  defaultValue={props.setting?.fb_dynamic_slide_title}
                  displayValue={(item: any) => {
                    if (!item) return i18next.t('제목 템플릿을 선택해주세요.');
                    return item.label;
                  }}
                  isSelected={(item: any) => {
                    return selectedTitleTemplate === item.value;
                  }}
                  handleUpdate={(item: any) => {
                    setSelectedTitleTemplate(item.value);
                    updateDynamicSlideTitle(item.value);
                    setValue('titleTemplate', item.value, { shouldValidate: true });
                  }}
                />
                {formState.errors.titleTemplate && (
                  <InputError message={formState.errors.titleTemplate.message as string} />
                )}
                <p className="description">{i18next.t('*선택한 카테고리의 정보가 보여집니다.')}</p>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('내용 템플릿')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <BDSSelectBox
                  appearance="gray"
                  style={{ width: '100%', marginTop: '8px' }}
                  list={templateTextDescription}
                  defaultValue={props.setting?.fb_dynamic_slide_description}
                  displayValue={(item: any) => {
                    if (!item) return i18next.t('내용 템플릿을 선택해주세요.');
                    return item.label;
                  }}
                  isSelected={(item: any) => {
                    return selectedDescriptionTemplate === item.value;
                  }}
                  handleUpdate={(item: any) => {
                    setSelectedDescriptionTemplate(item.value);
                    updateDynamicSlideDescription(item.value);
                    setValue('descriptionTemplate', item.value, { shouldValidate: true });
                  }}
                />
                {formState.errors.descriptionTemplate && (
                  <InputError message={formState.errors.descriptionTemplate.message as string} />
                )}
                <p className="description">{i18next.t('*선택한 카테고리의 정보가 보여집니다.')}</p>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('행동 유도 버튼')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <BDSSelectBox
                  appearance="gray"
                  style={{ width: '100%', marginTop: '8px' }}
                  list={facebookCTA}
                  defaultValue={props.setting?.fb_call_to_action}
                  displayValue={(item: any) => {
                    return i18next.t(item.label);
                  }}
                  isSelected={(item: any) => {
                    return selectedCTA === item.value;
                  }}
                  handleUpdate={(item: any) => {
                    setSelectedCTA(item.value);
                  }}
                />
              </div>
              {campaignState.isBiginConnected && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('연계 캠페인')}</h2>
                  <div className="material-field-selector-wrapper">
                    <MaterialCheckboxSelector
                      contentTitle={i18next.t('핵심 키워드')}
                      checkboxLabel=""
                      value
                      hiddenCheckbox
                    />
                    <BGTagInput
                      style={{ width: '100%', marginTop: '8px' }}
                      inputProps={{
                        name: 'bigin_keywords',
                        placeholder: i18next.t(
                          'Bigin CRM에서 해당 광고 소재를 검색할 때 사용할 키워드를 입력해주세요.',
                        ),
                      }}
                      handleUpdate={(keywords) => {
                        setBiginKeywords(keywords);
                      }}
                      defaultTags={biginKeywords}
                      duplicateText={i18next.t('* 이미 등록된 키워드입니다.')}
                    />
                    <p className="description">
                      {i18next.t('*핵심 키워드는 선택사항이며, 최대 5개까지 입력할 수 있습니다.')}
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <React.Suspense fallback={null}>
        {showCategorySelector && (
          <CategorySelectorModalWrapper
            isShow={showCategorySelector}
            buttons={buttons}
            categories={categoriesState}
            selectedParentCategory={selectedParentCategory}
            selectedCategory={selectedCategory}
            setCategory={setSelectedCategory}
            setParentCategory={setSelectedParentCategory}
          />
        )}
      </React.Suspense>
      <React.Suspense fallback={null}>
        {showChangeDefaultImageSelector && (
          <ModalCategoryImageSelector
            isOpen={showChangeDefaultImageSelector}
            close={() => setShowChangeDefaultImageSelector(false)}
            setDefaultImages={handleDefaultImages}
            selectedCategoryId={selectedCategoryId}
          />
        )}
      </React.Suspense>
    </BorderSection>
  );
};
