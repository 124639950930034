import { css } from '@emotion/react';

export const CampaignChartStyle = css`
  width: 100%;
  .bg-chart-container {
    width: 100%;

    .right-wrapper {
      flex: 1;
    }

    .chart-body-wrapper {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      gap: 20px;
      padding: 20px;
      border: solid 1px #dce0e8;
      border-radius: 4px;

      .chart-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        width: inherit;
        .chart-empty {
          background-color: #ffffff;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
      .legend-list-container {
        width: fit-content;
      }
    }
  }
`;
