// import { ListItem } from 'bigin-ui-components';

import { I18Next, i18nextScanKey } from '../../i18n';
import { BaseQueryParameter } from '../../object-model/model';
import StorageService from '../../services/StorageService';
import { AllColumnType } from '../../types/BGEventDef';

export interface Metric {
  prop: string;
  displayName: string;
  format: 'Currency' | 'Number' | 'Percent';
  count: number;
  compareCount: number;
  tooltip?: string;
  lock?: boolean;
}

export interface BGListFilterV2Filter {
  label: string;
  value: string;
  items: Array<ListItem>;
}

export interface CampaignQueryParams extends BaseQueryParameter {
  activePage?: string;
  countPerPage?: string;
  sortData?: {
    key: string;
    direction: string;
  };
  filter?: string;
  searchText?: string;
}

export const initGridColumn = (): AllColumnType[] => {
  const defaultColumn = [
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: i18nextScanKey('지출 금액'),
      prop: 'spend',
      dataType: 'Currency',
      visible: true,
      checked: true,
      lock: false,
    },
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: i18nextScanKey('노출 수'),
      prop: 'impressions',
      dataType: 'Number',
      visible: true,
      checked: true,
      lock: false,
    },
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: i18nextScanKey('노출 빈도'),
      prop: 'frequency',
      dataType: 'Number',
      visible: true,
      checked: true,
      lock: false,
    },
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: 'CPM',
      prop: 'cpm',
      dataType: 'Percent',
      visible: true,
      checked: true,
      lock: false,
    },
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: i18nextScanKey('클릭 수'),
      prop: 'inline_link_clicks',
      dataType: 'Number',
      visible: true,
      checked: true,
      lock: false,
    },
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: 'CTR',
      prop: 'inline_link_click_ctr',
      dataType: 'Number',
      visible: true,
      checked: true,
      lock: false,
    },
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: 'CPC',
      prop: 'inline_link_click_cpc',
      dataType: 'Currency',
      visible: true,
      checked: true,
      lock: false,
    },
  ] as any;
  if (StorageService.activePlan?.type === 'smartstore') {
    return defaultColumn;
  }
  return [
    ...defaultColumn,
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: i18nextScanKey('구매 수'),
      prop: `actions_${StorageService.attributionWindow}`,
      dataType: 'Number',
      visible: true,
      checked: true,
      lock: true,
    },
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: i18nextScanKey('구매 전환율'),
      prop: `cvr_${StorageService.attributionWindow}`,
      dataType: 'Percent',
      visible: true,
      checked: true,
      lock: true,
    },
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: 'CPA',
      prop: `cpa_${StorageService.attributionWindow}`,
      dataType: 'Currency',
      visible: true,
      checked: true,
      lock: true,
    },
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: 'ROAS',
      prop: `roas_${StorageService.attributionWindow}`,
      dataType: 'Percent',
      visible: true,
      checked: true,
      lock: true,
    },
    {
      columnType: 'Custom',
      groupName: i18nextScanKey('데이터 항목'),
      displayName: i18nextScanKey('구매 전환 값'),
      prop: `action_values_${StorageService.attributionWindow}`,
      dataType: 'Currency',
      visible: true,
      checked: true,
      lock: true,
    },
  ];
};

export const metricConfigList = (): Metric[] => {
  const defaultMetrics: Metric[] = [
    {
      displayName: i18nextScanKey('지출 금액'),
      prop: 'spend',
      format: 'Currency',
      count: 0,
      compareCount: 0,
      tooltip: i18nextScanKey('광고 매체에서 사용된 금액입니다.'),
      lock: false,
    },
    {
      displayName: i18nextScanKey('노출 수'),
      prop: 'impressions',
      format: 'Currency',
      count: 0,
      compareCount: 0,
      tooltip: i18nextScanKey('광고가 고객에게 보여진 횟수입니다.'),
      lock: false,
    },
    {
      displayName: 'CTR',
      prop: 'inline_link_click_ctr',
      format: 'Percent',
      count: 0,
      compareCount: 0,
      tooltip: i18nextScanKey('Click Through Rate, 광고를 본 고객 중, 해당 광고를 클릭한 비율입니다. '),
      lock: false,
    },
    {
      displayName: 'CPC',
      prop: 'inline_link_click_cpc',
      format: 'Currency',
      count: 0,
      compareCount: 0,
      tooltip: i18nextScanKey('Click Per Cost, 한 번의 광고 클릭을 위해 광고 매체에서 지출된 금액입니다.'),
      lock: false,
    },
  ];

  if (StorageService.activePlan?.type === 'smartstore') {
    return defaultMetrics;
  }

  return [
    ...defaultMetrics,
    {
      displayName: i18nextScanKey('구매 수'),
      prop: `actions_${StorageService.attributionWindow}`,
      format: 'Currency',
      count: 0,
      compareCount: 0,
      tooltip: i18nextScanKey('고객이 상품을 구매한 횟수입니다.'),
      lock: true,
    },
    {
      displayName: i18nextScanKey('구매 전환율'),
      prop: `cvr_${StorageService.attributionWindow}`,
      format: 'Percent',
      count: 0,
      compareCount: 0,
      tooltip: i18nextScanKey('광고를 클릭한 고객 중 상품을 구매한 비율입니다.'),
      lock: true,
    },
    {
      displayName: 'CPA',
      prop: `cpa_${StorageService.attributionWindow}`,
      format: 'Currency',
      count: 0,
      compareCount: 0,
      tooltip: i18nextScanKey('Cost Per Action, 한 번의 상품 구매를 위해 광고 매체에서 지출된 금액입니다.'),
      lock: true,
    },
    {
      displayName: 'ROAS',
      prop: `roas_${StorageService.attributionWindow}`,
      format: 'Percent',
      count: 0,
      compareCount: 0,
      tooltip: i18nextScanKey('Return On Advertising Spend, 광고 지출 금액 대비 판매 수익 금액의 비율입니다.'),
      lock: true,
    },
  ];
};

export const getCampaignFilterList = (i18next: I18Next): BGListFilterV2Filter => {
  return {
    label: i18next.t('캠페인 분류'),
    value: 'filter',
    items: [
      { label: i18next.t('전체'), value: 'CAMPAIGN_FILTER_ALL' as CampaignFilterType, isDefault: true },
      { label: i18next.t('활성'), value: 'CAMPAIGN_FILTER_ACTIVE' as CampaignFilterType, isDefault: false },
      {
        label: i18next.t('일부 비활성'),
        value: 'CAMPAIGN_FILTER_PARTIAL_INACTIVE' as CampaignFilterType,
        isDefault: false,
      },
      {
        label: i18next.t('전체 비활성'),
        value: 'CAMPAIGN_FILTER_ALL_INACTIVE' as CampaignFilterType,
        isDefault: false,
      },
    ],
  };
};

export const getMoreButtonObject = (i18next: I18Next) => ({
  add: {
    label: i18next.t('소재 추가'),
    value: 'add',
  },
  copy: {
    label: i18next.t('캠페인 복사'),
    value: 'copy',
  },
  modify: {
    label: i18next.t('예산 수정'),
    value: 'modify',
  },
});
