import React, { useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import axios, { AxiosError } from 'axios';
import validator from 'validator';
import { useQueryClient } from 'react-query';

import { BDSButton, BDSFontIcon } from '@bigin/bigin-ui-components';
import { baseCss } from './Login.style';
import { BGInput } from '../../components/BGInput/BGInput';
import { LogoFrame } from '../../components/LogoFrame/LogoFrame';
import { UserApi } from '../../lib/api/UserApi';
import StorageService from '../../services/StorageService';
import { MallApi } from '../../lib/api/MallApi';
import useScript from '../../hooks/useScript';
import { useDataStore } from '../../context/Store';
import { InputError } from '../../components/InputError/InputError';
import { BorderSection } from '../../components/BorderSection/BorderSection';

interface ILoginForm {
  email: string;
  password: string;
}

export const Login = () => {
  const i18next = useTranslation();
  const [loginFail, setLoginFail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const [, setEmail] = useState<string>('');
  const [, setPassword] = useState<string>('');

  const { register, handleSubmit, formState, setValue } = useForm<ILoginForm>({
    mode: 'onChange',
  });
  useEffect(() => {
    register('email', {
      validate: (value: string) => {
        if (value.length !== 0 && !validator.isEmail(value)) {
          return i18next.t('잘못된 이메일 형식입니다.') as string;
        }

        return true;
      },
    });
    register('password');
  }, []);

  useScript('https://developers.kakao.com/sdk/js/kakao.js');
  const navigate = useNavigate();
  const { dialogStore } = useDataStore();
  const queryClient = useQueryClient();

  const finishLogin = async () => {
    try {
      const response = await MallApi().getMalls({ includes: ['active_subscription'] });
      const malls = response.data.data;

      const hasSelectedMall =
        StorageService.selectedMall && _.findIndex(malls, { id: StorageService.selectedMall.id }) > -1;
      if (hasSelectedMall) {
        StorageService.selectedMall = malls[_.findIndex(malls, { id: StorageService.selectedMall.id })];
        StorageService.activePlan =
          malls[_.findIndex(malls, { id: StorageService.selectedMall.id })].active_subscription;
      } else if (malls.length > 0) {
        [StorageService.selectedMall] = malls; // Same as `StorageService.selectedMall = malls[0];`
        StorageService.activePlan = malls[0].active_subscription;
      } else {
        setLoginFail(true);
        return;
      }
      queryClient.invalidateQueries('remain');
      queryClient.invalidateQueries('applicationFind');

      navigate(`/malls/${StorageService.selectedMall.id}/campaign-dashboard`);
    } catch (err) {
      setLoginFail(true);

      throw err;
    }
  };

  const login = async (params: ILoginForm) => {
    setIsLoading(true);

    try {
      try {
        const response = await UserApi().login(params.email, params.password);
        StorageService.token = response.data.data.access_token;
        StorageService.refreshToken = response.data.data.refresh_token;
      } catch (err) {
        setLoginFail(true);

        throw err;
      }

      await finishLogin();
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * https://developers.kakao.com/docs/latest/ko/kakaologin/js#advanced-guide
   */
  const loginViaKakao = () => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init(String(process.env.REACT_APP_KAKAO_APP_KEY));
    }

    setIsLoading(true);
    window.Kakao.Auth.login({
      scope: 'account_email',
      async success(authObj: AuthObj) {
        try {
          const response = await UserApi().loginViaKakao(authObj.access_token);
          StorageService.token = response.data.data.access_token;
          StorageService.refreshToken = response.data.data.refresh_token;

          await finishLogin();
        } catch (err) {
          if (axios.isAxiosError(err)) {
            const axiosError = err as AxiosError;
            if (axiosError.response?.status === 404) {
              navigate('/sign-up');
              return;
            }
          } else {
            throw err;
          }
        } finally {
          setIsLoading(false);
        }
      },
      fail(errorObj) {
        dialogStore.showMessage(errorObj.error, errorObj.error_description);
      },
      always() {
        setIsLoading(false);
      },
    });
  };

  useEffect(() => {
    if (StorageService.user) {
      finishLogin();
    }
  }, []);

  return (
    <div css={[baseCss]}>
      <LogoFrame useViewPort>
        <BorderSection style={{ padding: '20px', width: '360px', boxSizing: 'border-box' }}>
          <div className="text-h1 text-color-main mb-5">{i18next.t('로그인')}</div>
          <form onSubmit={handleSubmit(login)}>
            <div className="login-pannel-email">
              <div className="login-email-box flex-col">
                <div className="info-title">{i18next.t('아이디')}</div>
                <BGInput
                  inputProps={{
                    type: 'text',
                    id: 'id',
                    placeholder: i18next.t('Bigin Ads 계정을 입력해주세요.'),
                    name: 'email',
                    onChange: (event) => {
                      setValue('email', event.target.value, { shouldValidate: true });
                      setEmail(event.target.value);
                    },
                  }}
                />
              </div>
              {formState.errors.email && <InputError message={formState.errors.email.message as string} />}
            </div>

            <div className="login-pannel-password">
              <div className="login-password-box flex-col">
                <table className="password-box">
                  <tbody>
                    <tr>
                      <td className="info-title">{i18next.t('비밀번호')}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="input-wrapper">
                  <BGInput
                    inputProps={{
                      type: isShowPassword ? 'text' : 'password',
                      name: 'password',
                      id: 'password',
                      placeholder: i18next.t('비밀번호를 입력해주세요.'),
                      onChange: (event) => {
                        setValue('password', event.target.value, { shouldValidate: true });
                        setPassword(event.target.value);
                      },
                    }}
                    rightLabelComponent={
                      <BDSFontIcon
                        name={isShowPassword ? 'ic-eye' : 'ic-eye-off'}
                        className="icon-preview-wrapper"
                        size="16px"
                        handleClick={() => setIsShowPassword(!isShowPassword)}
                      />
                    }
                  />
                </div>
              </div>
              {formState.errors.password && <InputError message={formState.errors.password.message as string} />}
            </div>
            {loginFail && (
              <div className="login-fail-alert">
                <span>{i18next.t('이메일과 비밀번호가 일치하지 않습니다.')}</span>
              </div>
            )}
            <BDSButton
              label={i18next.t('로그인')}
              type="submit"
              isDisabled={isLoading}
              style={{ width: '100%', alignSelf: 'center', justifyContent: 'center', marginTop: '20px' }}
            />
          </form>
          <div className="google-login-button-wrapper" onClick={() => loginViaKakao()}>
            <div className="kakao-login-image" />
            <div className="text-input text-color-main">{i18next.t('카카오 계정으로 간편하게 로그인')}</div>
          </div>
          <div className="password-reset-button-wrapper" onClick={() => navigate('/find-password')}>
            <span className="password-reset-button">{i18next.t('비밀번호를 잊으셨나요?')}</span>
          </div>
          <hr className="horizontal-line" />
          <div className="text-p3 text-color-main text-center">{i18next.t('Bigin Ads 계정이 없으신가요?')}</div>
          <BDSButton
            appearance="out-line"
            style={{
              width: '100%',
              alignSelf: 'center',
              justifyContent: 'center',
              marginTop: '8px',
              backgroundColor: '#4e4e4e',
              boxShadow: 'unset',
            }}
            onClick={() => navigate('/sign-up')}
          >
            <p style={{ color: '#fff' }}>{i18next.t('회원가입')}</p>
          </BDSButton>
        </BorderSection>
      </LogoFrame>
    </div>
  );
};
