import { css, useTheme, Theme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();
  return css`
    .manage-member-pannel-wrapper {
      width: 100%;

      .manage-member-table-wrapper {
        margin-top: 24px;
        width: 640px;
        margin-left: -20px;
        margin-right: -20px;
        overflow: auto;
        max-height: 522px;

        table {
          padding: 0;
          margin: 0;
          border-collapse: separate;
          border-spacing: 0px;
          width: 100%;
        }

        th {
          border-top: solid 1px #dce0e8;
          border-bottom: solid 1px #b8beca;
          background-color: #f9fafb;
          padding: 12px 16px;
          ${theme.text.h5};
          color: ${theme.colors.mainFont};
          text-align: left;
          position: sticky;
          top: 0;
        }
        th:not(:first-child) {
          border-left: solid 1px #dce0e8;
        }

        td {
          padding: 11px 16px 8px;
          ${theme.text.input};
          color: ${theme.colors.subFont};
          text-align: left;
          border-bottom: solid 1px #dce0e8;
        }

        td:not(:first-child) {
          border-left: solid 1px #dce0e8;
        }

        .name {
          width: 180px;
        }
        .email {
          width: 240px;
        }
        .phone {
          width: 220px;
        }

        .badge {
          display: inline-block;
          padding: 0 4px;
          background-color: ${theme.colors.bg3};
          border-radius: 4px;
          ${theme.text.disclaimer};
          color: ${theme.colors.smallFont};
        }
      }
    }
  `;
};
