import React, { ReactElement, useState } from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, useTheme, Theme } from '@emotion/react';
import { baseCss } from './BGButton.style';
import { FontIcon, FontIconName } from '../FontIcon/FontIcon';

export interface BGButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  id?: number;
  active?: boolean;
  isLoading?: boolean;
  isUnclickable?: boolean;
  isDisabled?: boolean;
  appearance?:
    | 'destructive'
    | 'out-line-positive'
    | 'out-line-destructive'
    | 'secondary'
    | 'danger'
    | 'no-line-destructive'
    | string;
  className?: string;
  hoverable?: boolean;
  type?: 'submit';

  /**
   * BGButton contents
   */
  label?: string;
  icon?: string;
  fontIcon?: FontIconName;
  fontIconColor?: string;
  iconAlign?: 'left' | 'right';
  // rightIcon?: string;
  // rightFontIcon?: string;
  style?: Object;
  /**
   * Optional click handler
   */
  onClick?: (e?: any) => void;
  children?: ReactElement;
}

/**
 * Primary UI component for user interaction
 */
export const BGButton = ({
  active = false,
  icon,
  fontIcon,
  fontIconColor,
  iconAlign = 'left',
  label,
  style,
  onClick,
  isDisabled,
  appearance,
  type,
  className,
  hoverable = true,
  children,
}: BGButtonProps): ReactElement => {
  const theme: Theme = useTheme();

  const themeCss = css`
    color: #ffffff;
    background-color: ${theme.colors.primary};

    &.active {
      background-color: ${theme.colors.primaryHover};
    }
  `;

  const [isOver, setIsOver] = useState(false);

  const classNames = className ? ['bg-button'].concat(className.split(' ')) : ['bg-button'];

  if (active || (hoverable && isOver)) classNames.push('active');

  return (
    <button
      css={[baseCss, themeCss]}
      style={style || {}}
      type={type ? 'submit' : 'button'}
      onMouseEnter={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      onClick={(e: any) => {
        if (onClick) return onClick(e);
        return e;
      }}
      disabled={isDisabled}
      className={`${appearance} ${isDisabled && 'isDisabled'} ${(icon || fontIcon) && 'icon'} ${classNames.join(' ')}`}
    >
      {iconAlign === 'left' && (
        <React.Fragment>
          {icon ? <img src={`/assets/img/card_icons/icons-${icon}.png`} alt="icon" /> : null}
          {fontIcon ? <FontIcon name={fontIcon} size="20px" color={fontIconColor} /> : null}
        </React.Fragment>
      )}
      {label && <p>{label}</p>}
      {iconAlign === 'right' && (
        <React.Fragment>
          {icon ? <img src={`/assets/img/card_icons/icons-${icon}.png`} alt="icon" /> : null}
          {fontIcon ? <FontIcon name={fontIcon} size="20px" color={fontIconColor} /> : null}
        </React.Fragment>
      )}
      {children}
    </button>
  );
};

BGButton.defaultProps = {
  isLoading: false,
  isUnclickable: true,
  isDisabled: false,
};
