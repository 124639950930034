import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import validator from 'validator';

import { baseCss } from './FormUser.style';
import { BGInput } from '../BGInput/BGInput';
import { InputError } from '../InputError/InputError';

export interface FormUserForm {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
  phone: string;
}

type Mode = 'invitation' | 'sign-up';

export interface FormUserProps {
  mode: Mode;
  name?: string;
  email?: string;
  password?: string;
  passwordConfirm?: string;
  phone?: string;
  children?: React.ReactNode;
  onIsValidChanged?: (isValid: boolean) => void;
  onSubmit: (form: FormUserForm) => void;
}

export const FormUser = (props: FormUserProps): ReactElement => {
  const i18next = useTranslation();

  const fixes = useMemo<(keyof FormUserForm)[]>(() => {
    if (props.mode === 'invitation') {
      return ['email'];
    }

    return [];
  }, [props.mode]);

  const MIN_PASSWORD = 6;
  const MAX_PHONE = 11;

  const [name, setName] = useState<string>(props.name || '');
  const [email, setEmail] = useState<string>(props.email || '');
  const [password, setPassword] = useState<string>(props.password || '');
  const [passwordConfirm, setPasswordConfirm] = useState<string>(props.passwordConfirm || '');
  const [phone, setPhone] = useState<string>(props.phone || '');

  const { formState, register, setValue, watch, handleSubmit } = useForm<FormUserForm>({ mode: 'all' });
  useEffect(() => {
    register('name', { value: name, required: i18next.t('필수 입력란입니다.') as string });
    register('email', {
      value: email,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isEmail(value)) {
          return i18next.t('이메일이 올바르지않습니다.') as string;
        }

        return true;
      },
    });
    register('password', {
      value: password,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isLength(value, { min: MIN_PASSWORD })) {
          return i18next.t('{{length}}자 이상 입력해주세요', { length: MIN_PASSWORD }) as string;
        }

        return true;
      },
    });
    register('passwordConfirm', {
      value: passwordConfirm,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.equals(value, watch('password'))) {
          return i18next.t('비밀번호가 일치하지않습니다.') as string;
        }

        return true;
      },
    });
    register('phone', {
      value: phone,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isNumeric(value)) {
          return i18next.t('숫자만 입력해주세요.') as string;
        }
        if (!validator.isLength(value, { max: MAX_PHONE })) {
          return i18next.t('{{length}}자 이하로 입력해주세요', { length: MAX_PHONE }) as string;
        }

        return true;
      },
    });
  }, []);
  useEffect(() => {
    if (props.onIsValidChanged) {
      props.onIsValidChanged(formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <form css={[baseCss]} onSubmit={handleSubmit(props.onSubmit)}>
      <div className="user-form-body">
        <div className="form-input">
          <div className="label">{i18next.t('이메일')}</div>
          {fixes.indexOf('email') > -1 ? (
            <span className="value">{email}</span>
          ) : (
            <BGInput
              error={!!formState.errors.email}
              inputProps={{
                placeholder: i18next.t('이메일 주소를 입력해주세요.'),
                value: email,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('email', event.target.value, { shouldValidate: true });
                  setEmail(event.target.value);
                },
              }}
            />
          )}
          {formState.errors.email && <InputError message={formState.errors.email.message as string} />}
        </div>

        <div className="form-input">
          <div className="label">{i18next.t('이름')}</div>
          <BGInput
            error={!!formState.errors.name}
            inputProps={{
              placeholder: i18next.t('이름을 입력해주세요.'),
              value: name,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('name', event.target.value, { shouldValidate: true });
                setName(event.target.value);
              },
            }}
          />
          {formState.errors.name && <InputError message={formState.errors.name.message as string} />}
        </div>

        <div className="form-input">
          <div className="label">{i18next.t('비밀번호')}</div>
          <BGInput
            error={!!formState.errors.password}
            inputProps={{
              type: 'password',
              placeholder: i18next.t('비밀번호를 입력해주세요.'),
              value: password,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('password', event.target.value, { shouldValidate: true });
                setPassword(event.target.value);
              },
            }}
          />
          {formState.errors.password && <InputError message={formState.errors.password.message as string} />}
        </div>

        <div className="form-input">
          <div className="label">{i18next.t('비밀번호 확인')}</div>
          <BGInput
            error={!!formState.errors.passwordConfirm}
            inputProps={{
              type: 'password',
              value: passwordConfirm,
              placeholder: i18next.t('비밀번호를 한번 더 입력해주세요.'),
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('passwordConfirm', event.target.value, { shouldValidate: true });
                setPasswordConfirm(event.target.value);
              },
            }}
          />
          {formState.errors.passwordConfirm && (
            <InputError message={formState.errors.passwordConfirm.message as string} />
          )}
        </div>

        <div className="form-input">
          <div className="label">{i18next.t('전화번호')}</div>
          <BGInput
            error={!!formState.errors.phone}
            inputProps={{
              placeholder: i18next.t('전화번호를 - 없이 입력하세요.'),
              value: phone,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('phone', event.target.value, { shouldValidate: true });
                setPhone(event.target.value);
              },
            }}
          />
          {formState.errors.phone && <InputError message={formState.errors.phone.message as string} />}
        </div>

        <div className="footer">
          <Trans i18nKey="회원가입 약관동의 html">
            * 회원가입을 누르시면&nbsp;
            <a href="https://www.taggers.io/terms-of-service" target="_blank" rel="noreferrer">
              서비스이용약관
            </a>
            &nbsp;및&nbsp;
            <a href="https://www.taggers.io/privacy" target="_blank" rel="noreferrer">
              개인정보처리방침
            </a>
            에 동의한 것으로 간주합니다.
          </Trans>
        </div>
      </div>
      {props.children}
    </form>
  );
};
