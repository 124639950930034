import styled from '@emotion/styled';

interface BorderProps {
  addHoverCss: boolean;
}

export const StyledBorderSection = styled.section<BorderProps>`
  label: BorderSection;

  border-radius: 8px;
  box-shadow: 0 4px 24px 0 rgba(79, 86, 97, 0.05);
  height: fit-content;
  background-color: #fff;
  transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);

  ${(props) => `
    border: 1px solid ${props.theme.colors.divider};
  `}

  ${(props) => {
    return props.addHoverCss
      ? `
          &:hover {
            background-color: #f7f7fa;
            box-shadow: 0 8px 36px 0 rgba(79, 86, 97, 0.1);
          }
        `
      : '';
  }}
`;
