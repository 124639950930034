import axios, { AxiosPromise } from 'axios';
import { makeForm } from '../../utils/utils';

export type MallsParam = 'medias' | 'manager' | 'active_subscription';
export type MallParam = 'productCount' | 'medias' | 'active_subscription' | 'company' | 'classification.parent';
export type CategoryParam = 'product_summary';

export interface CrmProject {
  id: string;
  is_bigin_crm_connected: boolean;
  is_cafe24_plugin_installed: boolean;
  is_equal_url: boolean;
  name: string;
  url: string;
}

export const MallApi = () => ({
  getMalls(params?: ApiParam<MallsParam>): AxiosPromise<{ data: Mall[] }> {
    return axios.get('/malls', { params });
  },
  getMall(mallId: number, params?: ApiParam<MallParam>): AxiosPromise<Mall> {
    return axios.get(`/malls/${mallId}`, { params });
  },
  getMallInboxes(
    mallId: number,
    params?: { type: AlarmType },
  ): AxiosPromise<{ data: AlarmMessage[]; unread_count: UnreadCount }> {
    return axios.get(`/malls/${mallId}/inboxes`, { params });
  },
  updateInboxOpenedDate(mallId: number, params?: { type: AlarmType }): AxiosPromise<{ data: AlarmMessage[] }> {
    return axios.patch(`/malls/${mallId}/inboxes`, { params });
  },
  updateInboxMsgById(mallId: number, inboxId: number): AxiosPromise<{ data: AlarmMessage }> {
    return axios.patch(`malls/${mallId}/inboxes/${inboxId}`);
  },
  updateMallLogo(
    mallId: string | number,
    form: Partial<{ hosting: string; square_logo: File; landscape_logo: File }>,
  ): AxiosPromise<{ data: Mall }> {
    const formData = makeForm(form);
    formData.append('_method', 'patch');
    return axios.post(`/malls/${mallId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  },
  updateMall(mallId: number, mall: Partial<Mall>): AxiosPromise<{ data: Mall }> {
    return axios.patch(`/malls/${mallId}`, {
      ...mall,
    });
  },
  registrationMall(
    companyId: number,
    params: {
      url: string;
      name: string;
      hosting: string;
      plan?: string;
      classification_id: number;
      extra_json: string;
    },
  ): AxiosPromise<{ data: Mall }> {
    const formData = makeForm(params);
    return axios.post(`company/${companyId}/malls`, formData);
  },
  getCategories(mallId: number, params?: ApiParam<CategoryParam>): AxiosPromise<{ data: Category[] }> {
    return axios.get(`/malls/${mallId}/categories`, {
      params: { structured: true, ...params },
    });
  },
  updateMallMedia(
    mallId: number,
    mediaType: string,
    mediaId: string,
    mediaName: string,
  ): AxiosPromise<{
    data: {
      created_at: string;
      id: number;
      mall_id: number;
      media_id: string;
      name: string;
      type: MediaAssetType;
      updated_at: string;
    };
  }> {
    return axios.put(`/malls/${mallId}/medias/${mediaType}`, {
      media_id: mediaId,
      name: mediaName,
    });
  },
  patchMall(mallId: number, data: any): AxiosPromise<{ data: Mall }> {
    return axios.patch(`/malls/${mallId}`, data);
  },
  deleteMallMedia(mallId: number, mediaType: string) {
    return axios.delete(`/malls/${mallId}/medias/${mediaType}`);
  },
  getMallClassifications(): AxiosPromise<{ data: MallClassification[] }> {
    return axios.get(`/mall-classifications`);
  },
  getAnalysisImages(url: string): AxiosPromise {
    return axios.get(`/analysis/images?url=${url}`);
  },
  indexTask(mallId: number): AxiosPromise<{ data: MallTask[] }> {
    return axios.get(`/malls/${mallId}/mall-tasks`);
  },
  createTask(
    mallId: number,
    data: { task_type: TaskType; data: object; status?: TaskStatus },
  ): AxiosPromise<{ data: MallTask }> {
    return axios.post(`/malls/${mallId}/mall-tasks`, data);
  },
  createSubscription(mallId: number, data: { plan: PlanType }): AxiosPromise<{ data: Subscription }> {
    return axios.post(`/malls/${mallId}/subscriptions`, data);
  },
  createCafe24Integration(mallId: number, data: { project_id: string }): AxiosPromise<{ data: { url: string } }> {
    return axios.post(`/malls/${mallId}/medias/bigin-project/cafe24`, data, { withCredentials: true });
  },
  deleteCafe24Integration(mallId: number): AxiosPromise<{ data: { url: string } }> {
    return axios.delete(`/malls/${mallId}/medias/bigin-project/cafe24`, { withCredentials: true });
  },
  getGtmCode(mallId: number): AxiosPromise<{ data: GtmData }> {
    return axios.get(`/malls/${mallId}/gtm/container`);
  },
  putGtmCode(mallId: number): AxiosPromise<{ data: GtmData }> {
    return axios.put(`/malls/${mallId}/gtm/container/script-code`);
  },
  getGtmInstalled(mallId: number): AxiosPromise<{ data: GtmData }> {
    return axios.get(`/malls/${mallId}/gtm/container?refresh=is_installed`);
  },
  getBiginCrmCafe24Plugin(mallId: number): AxiosPromise<{ data: { is_cafe24_plugin_installed: boolean } }> {
    return axios.get(`/bigin/malls/${mallId}/applications/plugin`);
  },
  getBiginCrmProjectList(mallId: number): AxiosPromise<{ data: CrmProject[] }> {
    return axios.get(`/bigin/malls/${mallId}/applications`);
  },
  createBiginCrmProject(mallId: number): AxiosPromise<{ data: CrmProject }> {
    return axios.post(`/bigin/malls/${mallId}/applications`);
  },
  getIsOrphanProject(mallId: number): AxiosPromise<{ data: { is_orphan: boolean } }> {
    return axios.get(`/bigin/malls/${mallId}/applications/is-orphan`);
  },
});
