import { css, useTheme, Theme } from '@emotion/react';
import { GS } from '../../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: 100%;
    min-width: fit-content;

    * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: ${GS.gutter}px;
    }

    .border-section-header-wrapper {
      padding: 0 ${GS.gutter}px;
      padding-top: ${GS.gutter}px;
      width: 100%;

      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .border-section-contents-wrapper {
      padding: 20px;
      min-height: 816px;
      position: relative;

      .template-table-form-wrapper {
        overflow: auto;
        height: 100%;
        position: relative;
        border: solid 1px #dce0e8;
        border-radius: 8px;

        .template-table {
          width: 100%;
          background: #ffffff;
          border-radius: 8px;
          border-spacing: 0;
          border-collapse: separate;
          thead {
            position: sticky;
            top: 0;
            z-index: 2;

            th {
              height: 42px;
              ${theme.text.input};
              font-weight: bold;
              color: ${theme.colors.subFont};
              text-align: left;
              background-color: #ffffff;
              border-bottom: 1px solid #dce0e8;
              min-width: 240px;
              padding: 10px;

              &.price {
                width: 256px;
              }

              &.copy {
                width: 510px;
              }
              .campaign-list-table-header-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                white-space: nowrap;
              }
            }
            th:first-of-type {
              width: 96px;
              min-width: 96px;
              text-align: right;
            }
            th:last-of-type {
              width: 35px;
              min-width: 35px;
            }
          }
          tbody {
            tr {
              &:hover {
                background-color: #f9fafb;
                transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);

                td {
                  background-color: #f9fafb;
                }
              }
              td {
                padding: 8px;
                height: 52px;
                border-bottom: 1px solid #dce0e8;
                position: relative;
                background-color: #ffffff;
                border-left: 1px solid #dce0e8;

                .disabled-cell-overlay {
                  background-color: #ffffff;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  opacity: 0.4;
                  z-index: 1;
                }
                .template-table-td-title {
                  display: flex;
                  align-items: center;
                  gap: 16px;
                  .title-number {
                    width: 24px;
                    height: 24px;
                    border-radius: 6px;
                    border: solid 1px #dce0e8;
                    background-color: #edf1f6;
                    ${theme.text.input};
                    line-height: 24px;
                    text-align: center;
                    color: ${theme.colors.mainFont};
                  }
                  img {
                    width: 36px;
                    height: 36px;
                    border-radius: 8px;
                    border: solid 1px #dce0e8;
                  }
                }
              }
              td:first-of-type {
                text-align: left;
                border-left: none;
                padding: 8px 8px 8px 12px;
                position: sticky;
                left: 0;
                z-index: 2;
              }
              td:last-of-type {
                border-left: none;
              }
            }

            tr:last-of-type {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }

      .empty-body {
        height: 363px;
        position: relative;
      }

      .empty-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 363px;
        position: absolute;
        top: 0;
        left: 0;
      }

      .add-button-wrapper {
        display: flex;
        margin-top: 16px;
        gap: 12px;
      }
    }
  `;
};
