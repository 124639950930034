import { css, SerializedStyles, Theme } from '@emotion/react';

export const BGRadioButtonWrapperCSS = (theme: Theme): SerializedStyles => {
  const {
    colors: { bg1, primary, mainFont, icon },
  } = theme;

  return css`
    & {
      width: auto;
      height: auto;
      display: flex;
      align-items: center;

      .radio-button-input {
        width: 18px;
        height: 18px;

        &:checked,
        &:not(:checked) {
          position: absolute;
          left: -9999px;
        }

        &:checked + label,
        &:not(:checked) + label {
          position: relative;
          padding-left: 22px;
          cursor: pointer;
          display: inline-block;
          font-size: 14px;
          color: ${mainFont};
        }

        &:checked + label {
          font-weight: bold;
        }

        &:checked + label:before,
        &:not(:checked) + label:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 18px;
          height: 18px;
          border: 1px solid ${icon};
          border-radius: 100%;
          background: ${bg1};
          box-sizing: border-box;
        }
        &:checked + label:before {
          border: 1px solid ${primary};
          border-radius: 100%;
        }
        &:checked + label:after,
        &:not(:checked) + label:after {
          content: '';
          width: 12px;
          height: 12px;
          background: ${primary};
          position: absolute;
          top: 3px;
          left: 3px;
          transform: translate(-3px, -3px);
          border-radius: 100%;
          -webkit-transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
          transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        }
        &:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        &:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }
  `;
};
