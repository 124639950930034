import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import Skeleton from 'react-loading-skeleton';
import { BDSSelectBox } from '@bigin/bigin-ui-components';

import { baseCss } from './FormMall.style';
import { InputError } from '../InputError/InputError';
import { filters } from '../../utils/filter';
import { BGInput } from '../BGInput/BGInput';
import { MonthlyBudget } from '../../enums/MonthlyBudget';

export interface FormMallForm {
  name: string;
  hosting: Hosting;
  companyName: string;
  companyRegistration: string;
  classificationId?: number;
  monthlyBudget?: string;
}

type Mode = 'new-mall' | 'sign-up-step2' | 'sign-up-step3';

export interface FormMallProps {
  mode: Mode;
  classifications?: MallClassification[];
  name?: string;
  hosting?: Hosting;
  companyName?: string;
  companyRegistration?: string;
  classificationId?: number;
  monthlyBudget?: string;
  isLoading: boolean;
  children?: React.ReactNode;
  onChanged?: (form: Partial<FormMallForm>) => void;
  onIsValidChanged?: (isValid: boolean) => void;
  onSubmit: (form: FormMallForm) => void;
}

export const FormMall = (props: FormMallProps): ReactElement => {
  const i18next = useTranslation();

  const uses = useMemo<(keyof FormMallForm)[]>(() => {
    if (props.mode === 'new-mall') {
      return ['name', 'hosting', 'classificationId', 'monthlyBudget'];
    }
    if (props.mode === 'sign-up-step2') {
      return ['name', 'hosting', 'companyName', 'companyRegistration'];
    }
    if (props.mode === 'sign-up-step3') {
      return ['classificationId', 'monthlyBudget'];
    }

    return ['name', 'hosting', 'companyName', 'companyRegistration', 'classificationId', 'monthlyBudget'];
  }, [props.mode]);

  const [parentClassificationId, setParentClassificationId] = useState<number | undefined>(
    (props.classifications || []).find((item) => item.children.find((child) => child.id === props.classificationId))
      ?.id || undefined,
  );
  const childClassifications = useMemo(() => {
    const parent = (props.classifications || []).find((item) => item.id === parentClassificationId);

    return parent ? parent.children : [];
  }, [parentClassificationId]);

  const [name, setName] = useState<string>(props.name || '');
  const [hosting, setHosting] = useState<Hosting>(props.hosting || 'etc');
  const [companyName, setCompanyName] = useState<string>(props.companyName || '');
  const [companyRegistration, setCompanyRegistration] = useState<string>(props.companyRegistration || '');
  const [classificationId, setClassificationId] = useState<number | undefined>(props.classificationId);
  const [monthlyBudget, setMonthlyBudget] = useState<string | undefined>(props.monthlyBudget);

  const MAX_NAME = 25;
  const { register, formState, setValue, handleSubmit, watch, trigger } = useForm<FormMallForm>({ mode: 'all' });
  useEffect(() => {
    register('name', {
      value: name,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > MAX_NAME) {
          return i18next.t('{{length}} byte 이하로 입력해주세요', { length: String(MAX_NAME) }) as string;
        }

        return true;
      },
    });
    register('hosting', { value: hosting, required: i18next.t('필수 입력란입니다.') as string });
    if (uses.indexOf('companyName') > -1) {
      register('companyName', { value: companyName, required: i18next.t('필수 입력란입니다.') as string });
    }
    if (uses.indexOf('companyRegistration') > -1) {
      register('companyRegistration', {
        value: companyRegistration,
        required: i18next.t('필수 입력란입니다.') as string,
        validate: (value: string) => {
          if (!validator.isNumeric(value)) {
            return i18next.t('숫자만 입력해주세요.') as string;
          }
          if (!validator.isLength(value, { min: 10, max: 10 })) {
            return i18next.t('사업자등록번호가 유효하지않습니다.') as string;
          }

          return true;
        },
      });
    }
    if (uses.indexOf('classificationId') > -1) {
      register('classificationId', {
        value: classificationId,
        required: i18next.t('필수 입력란입니다.') as string,
      });
    }
    if (uses.indexOf('monthlyBudget') > -1) {
      register('monthlyBudget', { value: monthlyBudget, required: i18next.t('필수 입력란입니다.') as string });
    }
  }, []);
  useEffect(() => {
    if (!props.onChanged) {
      return () => undefined;
    }

    const subscription = watch(props.onChanged);
    return () => subscription.unsubscribe();
  }, [watch]);
  useEffect(() => {
    if (props.onIsValidChanged) {
      props.onIsValidChanged(formState.isValid);
    }
  }, [formState.isValid]);

  useEffect(() => {
    if (props.name && props.name !== name) {
      setName(props.name);
      setValue('name', props.name);
      trigger('name');
    }
    if (props.hosting && props.hosting !== hosting) {
      setHosting(props.hosting);
      setValue('hosting', props.hosting);
      trigger('hosting');
    }
    if (props.companyName && props.companyName !== companyName) {
      setCompanyName(props.companyName);
      setValue('companyName', props.companyName);
      trigger('companyName');
    }
    if (props.companyRegistration && props.companyRegistration !== companyRegistration) {
      setCompanyRegistration(props.companyRegistration);
      setValue('companyRegistration', props.companyRegistration);
      trigger('companyRegistration');
    }
    if (props.classificationId && props.classificationId !== classificationId) {
      setClassificationId(props.classificationId);
      setValue('classificationId', props.classificationId);
      trigger('classificationId');
    }
    if (props.monthlyBudget && props.monthlyBudget !== monthlyBudget) {
      setMonthlyBudget(props.monthlyBudget);
      setValue('monthlyBudget', props.monthlyBudget);
      trigger('monthlyBudget');
    }
  }, [props]);

  return (
    <form css={[baseCss]} onSubmit={handleSubmit(props.onSubmit)}>
      <div className="mall-form-body">
        <div className="form-input">
          <div className="label">{i18next.t('쇼핑몰명')}</div>
          {props.isLoading ? (
            <Skeleton style={{ height: '36px' }} />
          ) : (
            <BGInput
              error={!!formState.errors.name}
              inputProps={{
                placeholder: i18next.t('{{length}}자 이내의 쇼핑몰명을 입력하세요.', { length: String(MAX_NAME) }),
                value: name,
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('name', event.target.value, { shouldValidate: true });
                  setName(event.target.value);
                },
              }}
            />
          )}
          {formState.errors.name && <InputError message={formState.errors.name.message as string} />}
        </div>

        <div className="form-input">
          <div className="label">{i18next.t('호스팅')}</div>
          {props.isLoading ? (
            <Skeleton style={{ height: '36px' }} />
          ) : (
            <BDSSelectBox
              error={!!formState.errors.hosting}
              appearance="gray"
              defaultValue={undefined}
              displayValue={(value) => {
                switch (value as Hosting) {
                  case 'cafe24':
                    return i18next.t('카페24');
                  case 'smartstore':
                    return i18next.t('스마트스토어');
                  case 'godo':
                    return i18next.t('고도몰');
                  case 'make_shop':
                    return i18next.t('메이크샵');
                  case 'sixshop':
                    return i18next.t('식스샵');
                  case 'imweb':
                    return i18next.t('아임웹');
                  case 'wix':
                    return i18next.t('윅스');
                  case 'shopify':
                    return i18next.t('쇼피파이');
                  case 'wordpress':
                    return i18next.t('워드프레스');
                  case 'gabia':
                    return i18next.t('가비아');
                  case 'self':
                    return i18next.t('자체구축몰');
                  case 'wisa':
                    return i18next.t('위사');
                  case 'ebaystore':
                    return i18next.t('이베이스토어');
                  case 'etc':
                    return i18next.t('기타');
                  default:
                    return i18next.t('기타');
                }
              }}
              handleUpdate={(value) => {
                setValue('hosting', value as Hosting, { shouldValidate: true });
                setHosting(value as Hosting);
              }}
              isSelected={(value) => value === hosting}
              list={[
                'cafe24',
                'smartstore',
                'godo',
                'make_shop',
                'sixshop',
                'imweb',
                'wix',
                'shopify',
                'wordpress',
                'gabia',
                'self',
                'wisa',
                'ebaystore',
                'etc',
              ]}
            />
          )}
          {formState.errors.hosting && <InputError message={formState.errors.hosting.message as string} />}
        </div>

        {uses.indexOf('companyName') > -1 && (
          <div className="form-input">
            <div className="label">{i18next.t('회사명')}</div>
            {props.isLoading ? (
              <Skeleton style={{ height: '36px' }} />
            ) : (
              <BGInput
                error={!!formState.errors.companyName}
                inputProps={{
                  placeholder: i18next.t('회사명을 입력하세요.'),
                  value: companyName,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('companyName', event.target.value, { shouldValidate: true });
                    setCompanyName(event.target.value);
                  },
                }}
              />
            )}
            {formState.errors.companyName && <InputError message={formState.errors.companyName.message as string} />}
          </div>
        )}

        {uses.indexOf('companyRegistration') > -1 && (
          <div className="form-input">
            <div className="label">{i18next.t('사업자등록번호')}</div>
            {props.isLoading ? (
              <Skeleton style={{ height: '36px' }} />
            ) : (
              <BGInput
                error={!!formState.errors.companyRegistration}
                inputProps={{
                  placeholder: i18next.t('사업자등록번호를 - 없이 입력하세요.'),
                  value: companyRegistration,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('companyRegistration', event.target.value, { shouldValidate: true });
                    setCompanyRegistration(event.target.value);
                  },
                }}
              />
            )}
            {formState.errors.companyRegistration && (
              <InputError message={formState.errors.companyRegistration.message as string} />
            )}
          </div>
        )}

        {uses.indexOf('classificationId') > -1 && (
          <div className="form-input">
            <div className="label">{i18next.t('업종 대카테고리')}</div>
            {props.isLoading ? (
              <Skeleton style={{ height: '36px' }} />
            ) : (
              <BDSSelectBox
                appearance="gray"
                defaultValue={parentClassificationId}
                displayValue={(item: any) => i18next.t(item.label)}
                handleUpdate={(item: any) => {
                  setParentClassificationId(item.value);

                  setValue('classificationId', undefined, { shouldValidate: true });
                  setClassificationId(undefined);
                }}
                isSelected={(item: any) => item.value === parentClassificationId}
                list={[
                  { value: undefined, label: i18next.t('업종 대카테고리 선택') },
                  ...(props.classifications || []).map((item) => ({ value: item.id, label: item.name })),
                ]}
              />
            )}
          </div>
        )}

        {uses.indexOf('classificationId') > -1 && (
          <div className="form-input">
            <div className="label">{i18next.t('업종 소카테고리')}</div>
            {props.isLoading ? (
              <Skeleton style={{ height: '36px' }} />
            ) : (
              <BDSSelectBox
                error={!!formState.errors.classificationId}
                appearance="gray"
                defaultValue={classificationId}
                displayValue={(item: any) => i18next.t(item.label)}
                handleUpdate={(item: any) => {
                  setValue('classificationId', item.value, { shouldValidate: true });
                  setClassificationId(item.value);
                }}
                isSelected={(item: any) => item.value === classificationId}
                list={[
                  { value: undefined, label: i18next.t('업종 소카테고리 선택') },
                  ...childClassifications.map((item) => ({ value: item.id, label: item.name })),
                ]}
              />
            )}
            {formState.errors.classificationId && (
              <InputError message={formState.errors.classificationId.message as string} />
            )}
          </div>
        )}

        {uses.indexOf('monthlyBudget') > -1 && (
          <div className="form-input">
            <div className="label">{i18next.t('월 광고 예산')}</div>
            {props.isLoading ? (
              <Skeleton style={{ height: '36px' }} />
            ) : (
              <BDSSelectBox
                error={!!formState.errors.monthlyBudget}
                appearance="gray"
                defaultValue={monthlyBudget}
                displayValue={(item: any) => item.label}
                handleUpdate={(item: any) => {
                  setValue('monthlyBudget', item.value, { shouldValidate: true });
                  setMonthlyBudget(item.value);
                }}
                isSelected={(item: any) => item.value === monthlyBudget}
                list={[
                  { value: undefined, label: i18next.t('월 광고 예산 선택') },
                  ...MonthlyBudget.toSelects(i18next),
                ]}
              />
            )}
            {formState.errors.monthlyBudget && (
              <InputError message={formState.errors.monthlyBudget.message as string} />
            )}
          </div>
        )}
      </div>
      {props.children}
    </form>
  );
};
