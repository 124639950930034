import { css, useTheme, Theme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 100px;
    * {
      box-sizing: border-box;
    }

    .google-preformance-max {
      display: flex;
      gap: 20px;
      .right-wrapper {
        display: flex;
        flex-direction: column;
        gap: 100px;
        .google-preview-responsive {
          display: flex;
        }
      }
    }
    .google-preview-item-wrapper {
      display: flex;
      border: solid 1px #dce0e8;
      background-color: #fff;
      border-radius: 8px;

      .logo {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;

        &.empty {
          background-color: #c9d4dc;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .landscape-logo {
        width: 128px;
        height: 32px;
        overflow: hidden;

        &.empty {
          background-color: #c9d4dc;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      &.responsive {
        // 메인 화면에 있던 것
        &.main {
          min-width: 480px;
          .google-preview-img-box {
            width: 143px;
            height: 143px;
          }

          .google-preview-item-description-wrap {
            width: 260px;
            overflow-wrap: break-word;
          }
        }

        &.type1 {
          width: 250px;
          height: 250px;
          display: flex;
          padding: 32px 16px;
          flex-direction: column;
          justify-content: space-between;

          .google-preview-content-container {
            position: relative;
            display: flex;

            gap: 8px;

            .google-preview-img-box {
              width: 80px;
              height: 80px;
            }
            .google-preview-item-description-wrap {
              width: 130px;
            }
          }

          .google-preview-action-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .google-preview-description {
              width: 182px;
              ${theme.text.p3}
              color: ${theme.colors.mainFont};
            }
          }
        }
        &.type2 {
          width: 300px;
          height: 250px;
          display: flex;
          flex-direction: column;
          gap: 4px;

          .google-preview-content-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }

          .google-preview-img-box {
            width: 300px;
            height: 158px;
          }

          .google-preview-action-wrap {
            display: flex;
            justify-content: space-between;
            padding: 0 8px;
            align-items: center;

            .google-preview-description {
              width: 210px;
              ${theme.text.p3}
              color: ${theme.colors.mainFont};
            }
          }
        }
        &.type3 {
          width: 728px;
          height: 90px;
          display: flex;
          gap: 16px;

          .google-preview-img-box {
            width: 172px;
            height: 90px;
          }
          .google-preview-action-wrap {
            width: 100%;
            padding: 25px 8px;
            display: flex;
            justify-content: space-between;

            .google-preview-long-title {
              width: 189px;
              margin-left: 26px;
            }
            .google-preview-description {
              width: 189px;
            }
          }
        }
        &.type4 {
          width: 135px;
          height: 508px;

          display: flex;
          flex-direction: column;

          .google-preview-img-box {
            width: 135px;
            height: 75px;
          }

          .google-preview-action-wrap {
            height: 100%;
            padding: 10px 8px 19px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .section-center {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .logo {
                margin: 0 auto;
              }
            }
            .section-bottom {
              display: flex;
              flex-direction: column;
              gap: 5px;
              align-items: center;
            }
          }
        }
      }

      &.dynamic {
        &.main {
          min-width: 640px;

          .google-preview-img-box {
            width: 146px;
            height: 146px;
            /* height: 100%; */
          }

          .google-preview-item-description-wrap {
            padding: 16px 8px;
          }
        }

        &.single-box {
          width: 250px;
          height: 250px;
          padding: 32px 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .google-preview-img-box {
            width: 80px;
            height: 80px;
          }

          .google-preview-content-container {
            display: flex;
            gap: 16px;
            flex-direction: column;

            .google-preview-dynamic-remarketing-item {
              display: flex;
              gap: 8px;
              align-items: center;
            }
          }

          .google-preview-action-link {
            width: 100%;
            display: flex;
            justify-content: flex-end;
          }
        }

        &.collection-box {
          width: 368px;
          height: 307px;
          padding: 8px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .google-preview-content-container {
            display: flex;
            gap: 8px;

            .google-preview-dynamic-remarketing-item {
              display: flex;
              flex-direction: column;
              width: 112px;
              gap: 8px;
              align-items: center;
              text-align: center;

              .google-preview-img-box {
                width: 112px;
                height: 112px;
              }

              .google-preview-action-link {
                margin-top: 8px;
              }
            }
          }
        }

        &.wide-collection-box {
          width: 728px;
          height: 90px;
          padding: 8px 24px 8px 8px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .logo,
          .landscape-logo {
            border-radius: unset;
            img {
              border-radius: unset;
            }
          }

          .google-preview-content-container {
            display: flex;
            gap: 24px;

            .google-preview-dynamic-remarketing-item {
              display: flex;
              gap: 8px;
              /* align-items: center; */
              /* text-align: center; */

              .google-preview-img-box {
                width: 74px;
                height: 74px;
              }

              .google-preview-item-description-wrap {
                gap: 2px;

                .google-preview-long-title {
                  width: 112px;
                }
              }
            }
          }
        }

        &.vertical-collection-box {
          width: 135px;
          height: 508px;
          padding: 8px 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          text-align: center;

          .landscape-logo {
            width: 96px;
            height: 24px;
            border-radius: unset;
            img {
              border-radius: unset;
            }
          }

          .google-preview-content-container {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .google-preview-dynamic-remarketing-item {
              display: flex;
              flex-direction: column;
              gap: 4px;
              /* align-items: center; */
              /* text-align: center; */

              .google-preview-img-box {
                width: 64px;
                height: 64px;
                margin: 0 auto;
              }

              .google-preview-item-description-wrap {
                gap: 2px;
              }

              /* .google-preview-action-link {
                margin-top: 8px;
              } */
            }
          }
        }
      }

      &.shopping {
        width: 375px;
        flex-direction: column;

        .google-preview-header-wrap {
          padding: 16px 16px 0;
          box-sizing: border-box;
        }
        .input-wrapper {
          padding: 0 16px;
        }
        .google-preview-shopping-title {
          padding: 0 16px;
        }

        .google-preview-img-box {
          &.gradient {
            padding: 5px 0;
            background-image: linear-gradient(111deg, #d6e8ff 22%, #fff 101%);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &.search {
        width: 375px;
        flex-direction: column;

        .google-preview-header-wrap {
          padding: 16px 16px 0;
          box-sizing: border-box;
        }
        .input-wrapper {
          padding: 0 16px;
        }

        .search-empty-box-list-wrapper {
          width: 100%;
          display: flex;
          overflow-x: hidden;
          list-style: none;
          margin: 16px 0 0;
          padding: 0 0 12px 0;
          border-bottom: 1px solid #dce0e8;

          .empty-box-item {
            width: 50px;
            height: 10px;
            flex-shrink: 0;
            background-color: #edf1f6;
            margin-left: 30px;

            &:nth-of-type(1) {
              width: 24px;
              margin-left: 34px;
              position: relative;
              background-color: #b5bcc9;

              &:before {
                content: '';
                width: 50px;
                height: 3px;
                background-color: #b5bcc9;
                position: absolute;
                top: 19px;
                left: -50%;
              }
            }
          }
        }
        .preveiw-main-wrapper {
          width: 100%;
          padding: 16px 16px 0;
          display: flex;
          flex-direction: column;
          gap: 8px;
          color: #3d4046;
        }
        .preview-search-box {
          width: 100%;
          padding: 14px 18px;
          background: #ffffff;
          border: 1px solid #dce0e8;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .preview-search-box-url-wrapper {
            display: flex;
            align-items: center;
            gap: 8px;
            font-size: 12px;

            .preview-search-box-ad {
              font-weight: 700;
              height: 20px;
              line-height: 20px;
            }
          }

          .preview-search-box-title-list {
            list-style: none;
            padding: 0;
            margin: 0;
            line-height: 20px;
            display: flex;
            align-items: center;

            .preview-search-box-title-item {
              font-weight: 700;
              font-size: 14px;
            }
          }

          .preview-search-box-description-list {
            padding: 0;
            margin: 0;
            font-size: 12px;
            line-height: 20px;
            display: flex;
            align-items: center;
            list-style: none;
            .preview-search-box-description-item {
            }
          }
        }

        .preview-search-result-box {
          width: 100%;
          height: 188px;
          background-color: #f9fafb;
          list-style: none;
          padding: 14px 16px;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .preview-search-result-empty-box {
            width: 100%;
            height: 12px;
            background-color: #edf1f6;

            &:first-of-type {
              margin-bottom: 4px;
              width: 116px;
              height: 16px;
            }
            &:nth-of-type(2) {
              width: 239px;
            }
            &:nth-of-type(5) {
              width: 200px;
            }
            &:nth-of-type(7) {
              width: 280px;
            }
          }
        }
      }
    }

    .google-preview-item-image {
      position: relative;
      overflow: hidden;
    }

    .google-preview-img-box {
      width: 228px;
      height: 100%;
      background-color: ${theme.colors.bg3};
      overflow: hidden;
      position: relative;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &.empty {
        .font-icon-panel {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .google-preview-header-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .google-preview-shopping-title {
      margin-top: 16px;
      ${theme.text.h4}
      color: ${theme.colors.mainFont};
    }

    .google-preview-shopping-item-wrap {
      width: 100%;
      display: flex;
      gap: 8px;
      overflow-x: hidden;
      margin-top: 16px;
      padding: 0 0 40px 16px;
      box-sizing: border-box;
      .google-preview-shopping-item {
        width: 124px;
        min-height: 392px;
        border-radius: 8px;
        background: #fff;
        border: 1px solid ${theme.colors.divider};
        flex: 0 0 35%;
        overflow: hidden;
        position: relative;

        &.empty {
          background: ${theme.colors.bg2};
          border: unset;
        }

        .image-wrap {
          height: 197px;
          position: relative;

          .google-preview-img-box {
            width: 124px;
            height: 124px;
            /* margin: auto 0; */
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        .google-preview-quick-view-wrap {
          width: 100%;
          position: absolute;
          padding: 9px 0;
          bottom: 0;
          border-top: 1px solid ${theme.colors.divider};
          color: #7e8696;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          ${theme.text.input}
        }
      }
    }

    .google-preview-item-description-wrap {
      padding: 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .badge {
        padding: 2px 4px;
        background-color: ${theme.colors.bg3};
        border-radius: 4px;
        ${theme.text.disclaimer}
        margin-right: 4px;
      }
    }

    .text-line-clamp {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .google-preview-long-title {
      ${theme.text.h5}
      color: ${theme.colors.mainFont};
      white-space: pre-wrap;
    }
    .google-preview-body {
      ${theme.text.input}
      color: ${theme.colors.mainFont};
      white-space: pre-wrap;
      word-break: break-word;
    }
    .google-preview-description {
      ${theme.text.p3}
      color: ${theme.colors.mainFont};
    }
    .google-preview-action-link {
      display: flex;

      .google-preview-action-button {
        ${theme.text.p3}
        color: ${theme.colors.mainFont};
        padding: 4px 8px;
        border-radius: 4px;
        border: 1px solid #a5adb2;
      }
    }
  `;
};
