import moment from 'moment';
import { strByteLength } from './utils';
import { CurrenyCode, getCurrencyCodeToLanguageCode, ICurrency } from '../types/currency.d';

export const filters = {
  formatYYYYMMDD: (date?: string) => {
    return moment(date).format('YYYY-MM-DD');
  },
  formatCurrency: ({ value, currencyCode = 'KRW', withoutUnit = false }: ICurrency) => {
    const options: Intl.NumberFormatOptions = {
      currency: currencyCode,
      style: 'currency',
      currencyDisplay: 'symbol', // default
    };
    switch (currencyCode) {
      case 'USD':
      case 'SGD':
        options.maximumFractionDigits = 2;
        options.minimumFractionDigits = 2;
        break;
      default:
      case 'KRW':
        break;
    }
    let formatted = new Intl.NumberFormat(getCurrencyCodeToLanguageCode(currencyCode), options).format(value);

    if (withoutUnit) {
      formatted = formatted.replace(/[₩원$]/g, '');
    }

    return formatted;
  },
  formatNumber: (value: number) => {
    return new Intl.NumberFormat().format(value);
  },
  formatByte: (value: string) => {
    return strByteLength(value);
  },
  formatLenth: (value: string) => {
    if (typeof value === 'string') {
      return value.length;
    }
    return 0;
  },
  formatNumberPoint: (value: string) => {
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    const pointExists = value.indexOf('.', 0);
    const strArr = value.split('.');
    let result = '';
    if (pointExists > -1) {
      result = `${strArr[0].replace(regexp, ',')}.${strArr[1]}`;
    } else {
      result = strArr[0].replace(regexp, ',');
    }
    return result;
  },
  googleRegexRemove: (value: string) => {
    const specialCharRegex = /[{}[\]/?:|)*~`^\-_+<>@#$\\=('"]/;
    const ampRegex = /(&amp;)/;
    const emojiRegex =
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/;
    const doubleCharRegex = /[*]{2,}|[.]{2,}|[&]{2,}|[%]{2,}|[!]{2,}|[,]{2,}|[;]{2,}/;
    const enterRegex = /(Enter)/;
    const doubleSpaceRegex = / {2,}/;

    let changeValue = value;
    const regexMap = [
      specialCharRegex,
      ampRegex,
      emojiRegex,
      doubleCharRegex,
      enterRegex,
      doubleCharRegex,
      doubleSpaceRegex,
    ];
    regexMap.map((regex) => {
      changeValue = changeValue.replace(new RegExp(regex, 'gi'), '');
      return changeValue.replace(new RegExp(regex, 'gi'), '');
    });

    return changeValue;
  },

  formatCurrencySymbol: ({ currencyCode = 'KRW' }: { currencyCode: CurrenyCode }): string => {
    const options: Intl.NumberFormatOptions = {
      currency: currencyCode,
      style: 'currency',
      currencyDisplay: 'symbol', // default
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
    return new Intl.NumberFormat(getCurrencyCodeToLanguageCode(currencyCode), options)
      .format(0)
      .replace(/\d/g, '')
      .trim();
  },
  formatStringComma: (value: string) => {
    return value.replace(/\b0+\.\d+(?:\.\d+)*|\B(?=(\d{3})+(?!\d))/g, ',');
  },
};
