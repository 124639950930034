import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { BDSSelectBox } from '@bigin/bigin-ui-components';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useForm } from 'react-hook-form';
import validator from 'validator';

import { baseCss } from './Step2.style';
import { InputError } from '../../../components/InputError/InputError';
import { filters } from '../../../utils/filter';
import { BGInput } from '../../../components/BGInput/BGInput';

export interface Step2State {
  name: string;
  hosting: Hosting;
  companyName: string;
  companyRegistration: string;
  isValid: boolean;
}

export interface Step2Props {
  isLoading: boolean;
  name: string;
  hosting: Hosting;
  companyName: string;
  companyRegistration: string;
  handleUpdate: (state: Step2State) => void;
}

interface Step2Form {
  name: string;
  companyName: string;
  hosting: Hosting;
  companyRegistration: string;
}

export const Step2 = (props: Step2Props) => {
  const i18next = useTranslation();

  const MAX_NAME = 25;

  const [name, setName] = useState<string>(props.name);
  const [hosting, setHosting] = useState<Hosting>(props.hosting);
  const [companyName, setCompanyName] = useState<string>(props.companyName);
  const [companyRegistration, setCompanyRegistration] = useState<string>(props.companyRegistration);

  const { formState, register, setValue, trigger } = useForm<Step2Form>({ mode: 'onChange' });

  useEffect(() => {
    setTimeout(() => {
      if (name !== props.name) {
        setName(props.name);
        setValue('name', props.name);
        trigger('name');
      }
      if (hosting !== props.hosting) {
        setHosting(props.hosting);
        setValue('hosting', props.hosting);
        trigger('hosting');
      }
      if (companyName !== props.companyName) {
        setCompanyName(props.companyName);
        setValue('companyName', props.companyName);
        trigger('companyName');
      }
      if (companyRegistration !== props.companyRegistration) {
        setCompanyRegistration(props.companyRegistration);
        setValue('companyRegistration', props.companyRegistration);
        trigger('companyRegistration');
      }
    });
  }, [props]);
  useEffect(() => {
    register('name', {
      value: name,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > MAX_NAME) {
          return i18next.t('{{length}} byte 이하로 입력해주세요', { length: String(MAX_NAME) }) as string;
        }

        return true;
      },
    });
    register('hosting', { value: hosting, required: i18next.t('필수 입력란입니다.') as string });
    register('companyName', { value: companyName, required: i18next.t('필수 입력란입니다.') as string });
    register('companyRegistration', {
      value: companyRegistration,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isNumeric(value)) {
          return i18next.t('숫자만 입력해주세요.') as string;
        }
        if (!validator.isLength(value, { min: 10, max: 10 })) {
          return i18next.t('사업자등록번호가 유효하지않습니다.') as string;
        }

        return true;
      },
    });
  }, []);
  useEffect(() => {
    if (props.handleUpdate) {
      props.handleUpdate({ name, hosting, companyName, companyRegistration, isValid: formState.isValid });
    }
  }, [formState, formState.isValid]);

  return (
    <div css={[baseCss]}>
      <div className="form-input">
        <div className="label">{i18next.t('쇼핑몰명')}</div>
        {props.isLoading ? (
          <Skeleton style={{ height: '36px' }} />
        ) : (
          <BGInput
            error={!!formState.errors.name}
            inputProps={{
              placeholder: i18next.t('{{length}}자 이내의 쇼핑몰명을 입력하세요.', { length: String(MAX_NAME) }),
              value: name,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('name', event.target.value, { shouldValidate: true });
                setName(event.target.value);
              },
            }}
          />
        )}
        {formState.errors.name && <InputError message={formState.errors.name.message as string} />}
      </div>

      <div className="form-input">
        <div className="label">{i18next.t('호스팅')}</div>
        {props.isLoading ? (
          <Skeleton style={{ height: '36px' }} />
        ) : (
          <BDSSelectBox
            error={!!formState.errors.hosting}
            appearance="gray"
            defaultValue={null}
            displayValue={(value) => {
              switch (value as Hosting) {
                case 'cafe24':
                  return i18next.t('카페24');
                case 'smartstore':
                  return i18next.t('스마트스토어');
                case 'godo':
                  return i18next.t('고도몰');
                case 'make_shop':
                  return i18next.t('메이크샵');
                case 'sixshop':
                  return i18next.t('식스샵');
                case 'imweb':
                  return i18next.t('아임웹');
                case 'wix':
                  return i18next.t('윅스');
                case 'shopify':
                  return i18next.t('쇼피파이');
                case 'wordpress':
                  return i18next.t('워드프레스');
                case 'gabia':
                  return i18next.t('가비아');
                case 'self':
                  return i18next.t('자체구축몰');
                case 'wisa':
                  return i18next.t('위사');
                case 'ebaystore':
                  return i18next.t('이베이스토어');
                case 'etc':
                  return i18next.t('기타');
                default:
                  return i18next.t('기타');
              }
            }}
            handleUpdate={(value) => {
              setValue('hosting', value as Hosting, { shouldValidate: true });
              setHosting(value as Hosting);
            }}
            isSelected={(value) => value === hosting}
            list={[
              'cafe24',
              'smartstore',
              'godo',
              'make_shop',
              'sixshop',
              'imweb',
              'wix',
              'shopify',
              'wordpress',
              'gabia',
              'self',
              'wisa',
              'ebaystore',
              'etc',
            ]}
          />
        )}
        {formState.errors.hosting && <InputError message={formState.errors.hosting.message as string} />}
      </div>

      <div className="form-input">
        <div className="label">{i18next.t('회사명')}</div>
        {props.isLoading ? (
          <Skeleton style={{ height: '36px' }} />
        ) : (
          <BGInput
            error={!!formState.errors.companyName}
            inputProps={{
              placeholder: i18next.t('회사명을 입력하세요.'),
              value: companyName,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('companyName', event.target.value, { shouldValidate: true });
                setCompanyName(event.target.value);
              },
            }}
          />
        )}
        {formState.errors.companyName && <InputError message={formState.errors.companyName.message as string} />}
      </div>

      <div className="form-input">
        <div className="label">{i18next.t('사업자등록번호')}</div>
        {props.isLoading ? (
          <Skeleton style={{ height: '36px' }} />
        ) : (
          <BGInput
            error={!!formState.errors.companyRegistration}
            inputProps={{
              placeholder: i18next.t('사업자등록번호를 - 없이 입력하세요.'),
              value: companyRegistration,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('companyRegistration', event.target.value, { shouldValidate: true });
                setCompanyRegistration(event.target.value);
              },
            }}
          />
        )}
        {formState.errors.companyRegistration && (
          <InputError message={formState.errors.companyRegistration.message as string} />
        )}
      </div>
    </div>
  );
};
