import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useForm } from 'react-hook-form';
import validator from 'validator';

import { BGUrlSelector, extractLocationFromUrl } from '../../../components/BGUrlSelector/BGUrlSelector';
import { baseCss } from './Step1.style';
import { InputError } from '../../../components/InputError/InputError';

export interface Step1State {
  url: string;
  isValid: boolean;
}

export interface Step1Props {
  url: string;
  handleUpdate: (state: Step1State) => void;
}

interface Step1Form {
  url: string;
}

export const Step1 = (props: Step1Props) => {
  const i18next = useTranslation();

  const [url, setUrl] = useState<string>(props.url);

  const selectedLocation: string = useMemo<string>(() => {
    return extractLocationFromUrl(props.url);
  }, [props.url]);

  const { register, formState, setValue } = useForm<Step1Form>({ mode: 'onChange' });

  useEffect(() => {
    register('url', {
      value: url,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        const host = value.split('://')[1];
        if (host.length !== 0 && !validator.isURL(value)) {
          return i18next.t('잘못된 URL 형식입니다.') as string;
        }

        return true;
      },
    });
  }, []);
  useEffect(() => {
    if (props.handleUpdate) {
      props.handleUpdate({ url, isValid: formState.isValid });
    }
  }, [formState, formState.isValid]);

  return (
    <div css={[baseCss]}>
      <div className="form-input">
        <div className="label">{i18next.t('쇼핑몰 URL')}</div>
        <BGUrlSelector
          validationTrackOn={selectedLocation.length > 0}
          isValid={!formState.errors.url}
          url={url}
          handleUpdate={(s) => {
            setValue('url', s, { shouldValidate: true });
            setUrl(s);
          }}
          placeholder="www.bigin.io"
        />
        {formState.errors.url && <InputError message={formState.errors.url.message as string} />}
      </div>
    </div>
  );
};
