import React, { ReactElement, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';

import { baseCss } from './PlanConfirm.style';
import { LogoFrame } from '../../components/LogoFrame/LogoFrame';
import { PlanSelect } from '../../components/PlanSelect/PlanSelect';
import StorageService from '../../services/StorageService';

export const PlanConfirm = (): ReactElement => {
  const navigate = useNavigate();

  const params = useParams<{ mallId: string; type: PlanType | 'cloud' | 'all' }>();
  const types = useMemo<(PlanType | 'cloud')[]>(() => {
    if (params.type === undefined) {
      return [];
    }
    if (params.type === 'all') {
      const items: (PlanType | 'cloud')[] = [];
      if (StorageService.selectedMall.hosting === 'smartstore') {
        items.push('smartstore');
      } else {
        items.push('brandmall');
        items.push('cloud');
      }
      if (StorageService.user?.type === 'manager') {
        items.push('managed');
      }

      return items;
    }

    return [params.type];
  }, [params.type]);

  const onSubmitted = async (type: PlanType | 'cloud') => {
    navigate(`/malls/${params.mallId}/plans/recommendation/${type}/complete`);
  };

  return (
    <div css={[baseCss]}>
      <LogoFrame>
        <div className="plan-confirm">
          <div className="plans-wrapper">
            {types.map((item) => (
              <PlanSelect mallId={Number(params.mallId)} planType={item} onSubmitted={() => onSubmitted(item)} />
            ))}
          </div>
        </div>
      </LogoFrame>
    </div>
  );
};
