import i18n from '../i18n';
import { CurrenyCode } from '../types/currency.d';

export const multilingual = {
  /** 대시보드 언어 설정 */
  getLanguageByTimezone(timeZone: string): string {
    switch (timeZone) {
      case 'Asia/Singapore':
        return 'en';
      default:
      case 'Asia/Seoul':
        return 'ko';
    }
  },

  /** currencyCode -> 통화 기호 */
  getCurrencySymbolByCurrencyCode({ currencyCode }: { currencyCode: CurrenyCode }): string {
    switch (currencyCode) {
      case 'SGD':
        return '$';
      default:
      case 'KRW':
        return '₩';
    }
  },

  /** 타임존 -> 국가 이름, utc 차이 */
  getCurrencyCodeTimeByTimezone(timeZone: string): { currencyText: string; utcText: string } {
    switch (timeZone) {
      case 'Asia/Singapore':
        return {
          currencyText: i18n.t('싱가포르 달러/싱가포르'),
          utcText: '(UTC+ 8)',
        };
      default:
      case 'Asia/Seoul':
        return {
          currencyText: i18n.t('원/대한민국'),
          utcText: '(UTC+ 9)',
        };
    }
  },

  /**  캠페인 타겟 설정 국가 */
  getTargetNationLabel({ currencyCode }: { currencyCode: CurrenyCode }): string {
    switch (currencyCode) {
      case 'SGD':
        return i18n.t('싱가포르');
      default:
      case 'KRW':
        return i18n.t('대한민국');
    }
  },
  /** 캠페인 생성 최소 예산 */
  getMinimumBudgetByCurrency({ currency }: { currency: CurrenyCode }): number {
    switch (currency) {
      case 'SGD':
      case 'USD':
        return 10;
      default:
      case 'KRW':
        return 10000;
    }
  },

  /** 캠페인 생성시 국가 string 반환 ex) KRW -> KR */
  getCountryByCurrencyCode({ currency }: { currency: CurrenyCode }): string {
    switch (currency) {
      case 'SGD':
        return 'SG';
      default:
      case 'KRW':
        return 'KR';
    }
  },

  /** 국가별로 필요한 부가세 계산 */
  getTaxByCurrencyCode({
    currencyCode,
    requiredCredit,
  }: {
    currencyCode: CurrenyCode;
    requiredCredit: number;
  }): number {
    switch (currencyCode) {
      case 'SGD': {
        return 0; // 삼성전자 닷컴 자체는 부가세 x
      }
      case 'KRW':
      default:
        return Math.floor(requiredCredit * 0.1); // 10%
    }
  },

  /** 국가별로 소수점 몇자리까지 나타내는 지 단위 */
  getDecimalPlaceLength({ currencyCode }: { currencyCode: CurrenyCode }) {
    switch (currencyCode) {
      case 'SGD': {
        return 2; // 싱가포르 달러는 2자리까지
      }
      case 'KRW':
      default:
        return 0;
    }
  },
};
