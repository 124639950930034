import { css, useTheme, Theme } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: 100%;
    min-width: fit-content;

    * {
      box-sizing: border-box;
    }

    .create-target-container {
      font-stretch: normal;
      font-style: normal;
      width: 100%;
      display: flex;
      min-width: fit-content;
      flex-direction: column;
      gap: ${GS.gutter}px;
      position: relative;

      .title-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .title {
          ${theme.text.h2};
          color: ${theme.colors.mainFont};
          margin-right: 16px;
        }
        .description {
          ${theme.text.input};
          color: ${theme.colors.mainFont};
          margin-top: 4px;
        }
      }

      .target-setting-wrapper {
        padding: 20px;
        width: 100%;

        .target-setting-contents {
          margin-top: 18px;
          .table-wrapper {
            width: 100%;

            .row {
              display: flex;
              align-items: center;
              padding-bottom: 16px;

              &.short {
                padding-bottom: 8px;
              }

              .item-title {
                width: 176px;
                ${theme.text.h4};
                color: ${theme.colors.subFont};
              }
              .item-content {
                width: 514px;
                ${theme.text.input};
                color: ${theme.colors.mainFont};
              }

              &:last-child {
                padding-bottom: 4px;
              }
            }

            .row + .row {
              padding-top: 16px;

              &.short {
                padding-top: 8px;
              }
            }
          }
        }
      }
      .campaign-setting-wrapper {
        padding: 20px;
        width: 100%;
        .campaign-setting-contents {
          margin-top: 18px;

          .criterion-campaign-table-wrapper {
            border-radius: 8px;
            border: 1px solid #dce0e8;
            overflow: auto;
            max-height: 600px;
            .criterion-campaign-table {
              width: 100%;
              background: #ffffff;
              border-radius: 8px;
              border-spacing: 0;
              border-collapse: separate;

              .campaign-checkbox-wrapper {
                display: inline-flex;
                align-items: center;
                gap: 4px;
                cursor: pointer;
                ${theme.text.input};
              }

              thead {
                position: sticky;
                top: 0;
                z-index: 2;

                th {
                  ${theme.text.h5};
                  color: #53585f;
                  height: 38px;
                  background-color: #ffffff;
                  border-bottom: 1px solid #dce0e8;
                  text-align: left;
                  padding: 0 20px;
                }
                th:first-of-type {
                  position: sticky;
                  left: 0;
                  background-color: #ffffff;
                }
                th:last-of-type {
                  text-align: right;
                }
              }
              tbody {
                tr {
                  cursor: pointer;
                  border-bottom: 1px solid #dce0e8;
                  td {
                    font-size: 0;
                    ${theme.text.input};
                    color: #53585f;
                    padding: 16px;
                    border-bottom: 1px solid #dce0e8;
                    position: relative;
                    .disabled-cell-overlay {
                      background-color: #ffffff;
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      top: 0;
                      left: 0;
                      opacity: 0.4;
                      z-index: 1;
                    }
                  }
                  td:last-of-type {
                    text-align: right;
                    color: ${theme.colors.primary};
                    font-weight: bold;
                  }
                }
              }
            }
          }
        }
      }
      .empty-body {
        height: 416px;
        position: relative;
      }

      .empty-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 416px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  `;
};
