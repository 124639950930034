import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .linked-bigin-modal-header {
      ${theme.text.h1};
      color: ${theme.colors.mainFont};
    }
    .linked-bigin-modal-contents {
      margin-top: 8px;

      .button-text {
        margin-top: 8px;
        padding: 0;
        ${theme.text.disclaimer};
        border: 0;
        background: none;
        cursor: pointer;
      }

      .crm-project-wrapper {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .project-item {
          padding: 12px;
          border: 1px solid #dce0e8;
          border-radius: 8px;
          cursor: pointer;
          position: relative;

          .description {
            ${theme.text.disclaimer};
            margin-left: 24px;
          }

          &.disabled {
            background: #edf1f6;
            color: ${theme.colors.inputDisabledText};

            .description {
              color: ${theme.colors.inputDisabledText};
            }
          }

          .tag {
            position: absolute;
            right: 12px;
            padding: 1px 4px;
            ${theme.text.p3};
            border-radius: 4px;
            color: #fff;
            background: #3399ff;
            &.disabled {
              color: #626871;
              background: #c9cfda;
            }
          }
        }
      }
    }
    .horizontal-divider {
      width: 100%;
      height: 1px;
      margin-top: 20px;
      background-color: #dce0e8;
    }
    .linked-bigin-modal-footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 16px;
      gap: 12px;
    }
  `;
};
