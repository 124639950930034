import React from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import { BorderSection } from '../../../components/BorderSection/BorderSection';
import { wrapperCss } from './AccountItemGroup.style';

export interface AccountItemGroupPops {
  children: React.ReactNode;
  direction?: 'row' | 'column';
  className?: string;
  style?: object;
}

export const AccountItemGroup = ({
  children,
  direction = 'row',
  className,
  style = {},
}: AccountItemGroupPops): JSX.Element => {
  const classNames = className ? ['bg-button'].concat(className.split(' ')) : ['bg-button'];
  return (
    <div
      className={`account-connect-media-wrapper ${direction} ${classNames.join(' ')}`}
      css={wrapperCss}
      style={style || {}}
    >
      <BorderSection style={{ boxShadow: 'none' }}>
        <div className="media-group-wrapper">{children}</div>
      </BorderSection>
    </div>
  );
};
