import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useMatch } from 'react-router-dom';
import { baseCss } from './LogoFrame.style';

export interface LogoFrameProps {
  children?: React.ReactNode;
  useViewPort?: boolean;
  style?: Object;
  borderStyle?: Object;
}
export const LogoFrame = (props: LogoFrameProps): React.ReactElement => {
  const newMallMatch = useMatch('/new-mall');
  return (
    <div css={[baseCss]}>
      <div className={`logo-frame ${props.useViewPort ? 'view-port' : ''}`}>
        <div className="logo-area" style={{ marginTop: newMallMatch ? '64px' : 'auto' }}>
          <article className="logo-section">
            <img src="/assets/img/sign-up/logo-bigin-ads.svg" alt="logo" />
          </article>
        </div>

        <div className="wrapper">{props.children}</div>

        <div className="copyright">Copyright 2022. biginsight All rights reserved.</div>
      </div>
    </div>
  );
};
