import { css, useTheme, Theme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    border-radius: 8px;
    border: solid 1px #dce0e8;
    background-color: #fff;
    position: relative;

    .google-preview-item-wrapper {
      display: flex;

      .logo {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        overflow: hidden;

        &.empty {
          background-color: #c9d4dc;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .landscape-logo {
        width: 128px;
        height: 32px;
        overflow: hidden;

        &.empty {
          background-color: #c9d4dc;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }

      &.youtube {
        flex-direction: column;
        max-width: 400px;

        &.main {
          .youtube-header-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px 16px 0;

            .icon-wrap {
              display: flex;
              gap: 8px;
              align-items: center;
            }
            .round-wrap {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              background-color: #edf1f6;
              position: relative;

              .font-icon-panel {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }
          }

          .google-preview-img-box {
            margin-top: 8px;
            width: 400px;
            height: 225px;
            background-color: ${theme.colors.bg3};
            overflow: hidden;
            position: relative;
            box-sizing: border-box;

            &.empty {
              .font-icon-panel {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .badge {
            padding: 0 4px;
            background-color: ${theme.colors.bg3};
            border-radius: 4px;
            ${theme.text.disclaimer};
          }

          .preview-body-wrap {
            display: flex;
            flex-direction: column;
            margin-top: 8px;
            gap: 8px;
            padding: 0 16px 16px;
          }

          .preview-body {
            padding: 0 16px;
            ${theme.text.input};
            color: ${theme.colors.mainFont};
            word-break: break-word;
          }

          .preview-action-wrapper {
            display: flex;
            justify-content: space-between;
            background-color: #c9d4dc;
            padding: 8px 16px;
            align-items: center;

            .preview-action-body {
              display: flex;
              flex-direction: column;
              gap: 4px;
              padding: 2px 0;
              max-width: 70%;

              .action-description {
                ${theme.text.input};
                color: ${theme.colors.mainFont};
              }
            }
            .preview-action-link {
              display: flex;
            }
          }
        }
        &.play {
          .google-preview-img-box {
            width: 402px;
            height: 225px;
            border: 1px solid ${theme.colors.bg3};
            background-color: ${theme.colors.bg3};
            overflow: hidden;
            position: relative;
            box-sizing: border-box;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .skip {
              background: rgba(0, 0, 0, 0.6);
              padding: 6px 16px;
              position: absolute;
              right: 0;
              bottom: 0;
              ${theme.text.input};
              text-align: center;
              color: #fff;
            }

            &.empty {
              .font-icon-panel {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .icon {
            width: 20px;
            height: 20px;
          }
          .google-preview-youtube-item {
            display: flex;
            width: 100%;
            flex-direction: column;

            .google-preview-youtube-ad-profile {
              display: flex;
              border-bottom: 1px solid ${theme.colors.divider};
              padding: 9px 16px;
              justify-content: space-between;
              align-items: center;
              gap: 4px;

              .logo {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                overflow: hidden;

                &.empty {
                  background-color: #c9d4dc;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
              .google-preview-ad-info {
                max-width: 220px;
                min-width: 220px;
              }
            }
            .google-preview-action-button {
              ${theme.text.p3}
              color: ${theme.colors.mainFont};
              padding: 4px 8px;
              border-radius: 4px;
              border: 1px solid #a5adb2;
            }
            .google-preview-youtube-button {
              display: flex;
              border-bottom: 1px solid ${theme.colors.divider};
              justify-content: space-between;
              padding: 8px 30px;
            }
            .google-preview-youtube-profile {
              display: flex;
              justify-content: space-between;
              padding: 8px 16px 16px;

              .profile-wrap {
                display: flex;
                gap: 8px;
                align-items: center;
              }
              .subscribe-wrap {
                display: flex;
                gap: 8px;
                align-items: center;
              }

              .round-wrap {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                background-color: #edf1f6;
                position: relative;

                .font-icon-panel {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }

          .badge {
            padding: 0 4px;
            background-color: ${theme.colors.bg3};
            border-radius: 4px;
            ${theme.text.disclaimer}
          }
        }
      }
    }

    .google-preview-img-box {
      width: 228px;
      height: 100%;
      background-color: ${theme.colors.bg3};
      overflow: hidden;
      position: relative;
      box-sizing: border-box;

      &.empty {
        .font-icon-panel {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .google-preview-long-title {
      ${theme.text.h5}
      color: ${theme.colors.mainFont};
    }
    .google-preview-body {
      ${theme.text.input}
      color: ${theme.colors.mainFont};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .google-preview-description {
      ${theme.text.p3}
      color: ${theme.colors.mainFont};
    }
    .google-preview-action-link {
      display: flex;

      .google-preview-action-button {
        ${theme.text.p3}
        color: ${theme.colors.mainFont};
        padding: 4px 8px;
        border-radius: 4px;
        border: 1px solid #a5adb2;
      }
    }
  `;
};
