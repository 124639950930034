import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { BDSToggleButton } from '@bigin/bigin-ui-components';

export interface CampaignStatusToggleProps {
  campaign: UnifiedCampaign;
  onChangeToggleButton: (isActive: boolean, campaign: UnifiedCampaign) => void;
  isDisabled?: (campaign: UnifiedCampaign) => boolean;
}

export const CampaignStatusToggle = (props: CampaignStatusToggleProps): JSX.Element => {
  const { campaign } = props;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <BDSToggleButton
        active={(campaign.status === 'active') as boolean}
        onChangeToggle={(isActive) => props.onChangeToggleButton(isActive, campaign)}
        disabled={props.isDisabled ? props.isDisabled(campaign) : false}
      />
    </div>
  );
};
