import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx, css } from '@emotion/react';
import { BDSButton, BDSFontIcon, BDSSelectBox } from '@bigin/bigin-ui-components';
import { useTranslation, UseTranslationResponse } from 'react-i18next';
import qs from 'qs';
import { useQueryClient } from 'react-query';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { BGButtonGroup } from '../../../components/BGButtonGroup/BGButtonGroup';
import { BGTooltip } from '../../../components/BGTooltip/BGTooltip';
import { BorderSection } from '../../../components/BorderSection/BorderSection';
import { FbAssetModal } from '../../../components/FbAssetModal/FbAssetModal';
import { KkAssetModal } from '../../../components/KkAssetModal/KkAssetModal';
import { AwAssetModal } from '../../../components/AwAssetModal/AwAssetModal';
import { LogoUploadModal } from '../../../components/LogoUploadModal/LogoUploadModal';
import { WrapperModal } from '../../../components/WrapperModal/WrapperModal';
import { AttributionWindow } from '../../../enums/AttributionWindow';
import StorageService from '../../../services/StorageService';
import { AccountItem } from '../AccountItem/AccountItem';
import { AccountItemGroup } from '../AccountItemGroup/AccountItemGroup';
import { ModalEditMallInfo } from '../Modal/EditMallInfo/EditMallInfo';
import { ModalEditMyInfo } from '../Modal/EditMyInfo/EditMyInfo';
import { ModalEditPassword } from '../Modal/EditPassword/EditPassword';
import { ModalManageMember } from '../Modal/ManageMember/ManageMember';
import { accountCardCss, baseCss } from './AccountSetting.style';
import { LinkedBiginCrmModal } from '../../../components/LinkedBiginCrmModal/LinkedBiginCrmModal';
import { CrmProjectInfoModal } from '../../../components/CrmProjectInfoModal/CrmProjectInfoModal';
import { AllColumnType } from '../../../types/BGEventDef';
import { multilingual } from '../../../utils/multilingual.util';

interface AccountSettingProps {
  me: User | null;
  mall: Mall | null;
  isOrphan: boolean;
  isLoading: boolean;
}
export const AccountSetting = (props: AccountSettingProps): ReactElement => {
  const { i18n, ...i18next } = useTranslation();
  const queryClient = useQueryClient();
  const [editMyInfoModal, setEditMyInfoModal] = useState<boolean>(false);
  const [editMallInfoModal, setEditMallInfoModal] = useState<boolean>(false);
  const [editPasswordModal, setEditPasswordModal] = useState<boolean>(false);
  const [manageMemberModal, setManageMemberModal] = useState<boolean>(false);
  const [logoUploadModal, setLogoUploadModal] = useState<boolean>(false);
  const [fbAssetModal, setFbAssetModal] = useState<boolean>(false);
  const [awAssetModal, setAwAssetModal] = useState<boolean>(false);
  const [kkAssetModal, setKkAssetModal] = useState<boolean>(false);
  const [crmProjectInfoModal, setCrmProjectInfoModal] = useState<boolean>(false);
  const [linkedBiginCrmModal, setLinkedBiginCrmModal] = useState<boolean>(false);
  const [translate, setTranslate] = useState<string>(i18n.language);
  const isManager = useMemo(() => StorageService.user.type === 'manager', [StorageService.user.type]);

  const currencyCodeTimeText = useMemo((): { currencyText: string; utcText: string } | null => {
    if (!StorageService.selectedMall?.timezone) return null;
    return multilingual.getCurrencyCodeTimeByTimezone(StorageService.selectedMall.timezone);
  }, [StorageService.selectedMall]);

  const translateList = [
    { label: i18next.t('한국어'), value: 'ko' },
    { label: i18next.t('영어'), value: 'en' },
  ];

  const queryParams: any = useMemo(
    () => qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    [window.location.search],
  );

  useEffect(() => {
    if (queryParams.open === 'linked-bigin-crm-modal') {
      setLinkedBiginCrmModal(true);
    }
  }, [queryParams]);

  const hasMediaType = (medias: MallMedia[], type: MediaAssetType): boolean => {
    return medias.findIndex((mallMedia: MallMedia) => mallMedia.type === type) > -1;
  };

  const handleChangeDashboardLanguage = (item: any) => {
    i18n.changeLanguage(item.value);
    setTranslate(item.value);
    StorageService.translationLanguage = item.value;
  };

  const handleChangeAttribution = (item: any) => {
    // 기여 기간 변경 시 saveGridColums에 xxx_(1d_view_7d_click | 7d_click)을 변경해야 한다.
    const regex = /(7d_click|1d_view_7d_click)/;
    const newGrid = StorageService.saveGridColumns.map((v: AllColumnType) => {
      if (regex.test(v.prop)) {
        return { ...v, prop: v.prop.replace(regex, item.value) };
      }
      return v;
    });
    StorageService.saveGridColumns = newGrid;
    StorageService.attributionWindow = item.value;
  };

  const loadingContents = (contents: string) => {
    return props.isLoading ? <Skeleton style={{ width: '200px' }} /> : contents;
  };

  const mediaList = [
    {
      id: 'facebook',
      direction: 'row',
      isManager: false,
      items: [
        {
          key: 'fb-page',
          title: 'Facebook',
          iconUrl: '/assets/img/card_icons/ic-channel-facebook.png',
          description: i18next.t('Facebook에 노출된 광고의 관리자임을 밝히고 Facebook 페이지 랜딩을 유도합니다.'),
          essential: true,
          beforeRegisterClick: () => setFbAssetModal(true),
          afterRegisterClick: () => setFbAssetModal(true),
        },
        {
          key: 'fb-instagram-account',
          title: 'Instagram',
          iconUrl: '/assets/img/card_icons/ic-channel-instagram.png',
          description: i18next.t('Instagram에 노출된 광고에 계정을 연동하여 프로필 랜딩을 유도할 수 있습니다.'),
          essential: false,
          beforeRegisterClick: () => setFbAssetModal(true),
          afterRegisterClick: () => setFbAssetModal(true),
        },
      ],
    },
    {
      id: 'google',
      direction: 'row',
      items: [
        {
          key: 'youtube',
          title: 'Youtube',
          iconUrl: '/assets/img/card_icons/ic-channel-youtube.png',
          description: i18next.t('Youtube 계정에 등록된 영상을 광고에 활용할 수 있습니다.'),
          essential: false,
          beforeRegisterClick: () => setAwAssetModal(true),
          afterRegisterClick: () => setAwAssetModal(true),
        },
      ],
    },
    {
      id: 'bigin-crm',
      direction: 'row',
      isManager: false,
      items: [
        {
          key: 'bigin-project',
          title: 'Bigin CRM',
          iconUrl: '/assets/img/bigin-logo-only-symbol.png',
          description: i18next.t('광고로 유입된 사용자에게 CRM 캠페인을 진행할 수 있습니다.'),
          essential: false,
          beforeRegisterClick: () => setLinkedBiginCrmModal(true),
          afterRegisterClick: () => setCrmProjectInfoModal(true),
        },
      ],
    },
    {
      id: 'manage-google',
      direction: 'column',
      isManager: true,
      items: [
        {
          key: 'aw-client-account',
          title: 'Google Ads',
          iconUrl: '/assets/img/card_icons/icons-ic-googleads.png',
          description: '',
          essential: false,
          beforeRegisterClick: () => setAwAssetModal(true),
          afterRegisterClick: () => setAwAssetModal(true),
        },
        {
          key: 'ga-account',
          title: 'Google Analytics',
          iconUrl: '/assets/img/card_icons/ic-channel-google-analytics.png',
          description: '',
          essential: false,
          beforeRegisterClick: () => setAwAssetModal(true),
          afterRegisterClick: () => setAwAssetModal(true),
        },
      ],
    },
    {
      id: 'manage-facebook',
      direction: 'column',
      isManager: true,
      items: [
        {
          key: 'fb-ad-account',
          title: 'Facebook 광고 계정',
          iconUrl: '/assets/img/card_icons/ic-channel-facebook.png',
          description: '',
          essential: false,
          beforeRegisterClick: () => setFbAssetModal(true),
          afterRegisterClick: () => setFbAssetModal(true),
        },
        {
          key: 'fb-pixel',
          title: 'Facebook 픽셀',
          iconUrl: '/assets/img/card_icons/ic-channel-facebook.png',
          description: '',
          essential: false,
          beforeRegisterClick: () => setFbAssetModal(true),
          afterRegisterClick: () => setFbAssetModal(true),
        },
        {
          key: 'fb-business',
          title: 'Facebook 비즈니스 관리자',
          iconUrl: '/assets/img/card_icons/ic-channel-facebook.png',
          description: '',
          essential: false,
          beforeRegisterClick: () => setFbAssetModal(true),
          afterRegisterClick: () => setFbAssetModal(true),
        },
      ],
    },
    {
      id: 'manage-kakao',
      direction: 'column',
      isManager: true,
      items: [
        {
          key: 'kk-ad-account',
          title: 'Kakao 광고 계정',
          iconUrl: '/assets/img/card_icons/ic-channel-kakao.png',
          description: '',
          essential: false,
          beforeRegisterClick: () => setKkAssetModal(true),
          afterRegisterClick: () => setKkAssetModal(true),
        },
        {
          key: 'kk-track',
          title: 'Kakao 픽셀',
          iconUrl: '/assets/img/card_icons/ic-channel-kakao.png',
          description: '',
          essential: false,
          beforeRegisterClick: () => setKkAssetModal(true),
          afterRegisterClick: () => setKkAssetModal(true),
        },
      ],
    },
  ];

  return (
    <SkeletonTheme baseColor="#edf1f6" highlightColor="#ffffff">
      <div css={[baseCss]}>
        <div className="account-connect">
          <BorderSection style={{ height: '100%' }}>
            <div className="title-wrapper">
              <div className="title">{i18next.t('계정 연동')}</div>
            </div>
            <div className="account-connect-wrapper">
              {mediaList.map((group) => (
                <AccountItemGroup
                  key={group.id}
                  style={{ display: group.isManager === true && !isManager ? 'none' : 'block' }}
                >
                  {group.items.map((item) => (
                    <AccountItem
                      key={item.key}
                      iconUrl={item.iconUrl}
                      direction={group.direction as 'row' | 'column'}
                      title={item.title}
                      essential={item.essential}
                      isLoading={props.isLoading}
                      description={item.description}
                    >
                      <BGButtonGroup>
                        {item.key === 'fb-instagram-account' && (
                          <BDSButton
                            appearance="secondary"
                            label={i18next.t('계정 연동 방법')}
                            fontIcon="ic-export"
                            iconAlign="right"
                            onClick={() => {
                              window.open('https://bigin.notion.site/592b210f8a5146aea5b53ff78c2e83e9', '_blank');
                            }}
                          />
                        )}
                        {props.mall && (
                          <BDSButton
                            {...(hasMediaType(props.mall.medias, item.key as MediaAssetType)
                              ? {
                                  appearance: item.key === 'bigin-project' && props.isOrphan ? 'primary' : 'secondary',
                                  label:
                                    item.key === 'bigin-project' && props.isOrphan
                                      ? i18next.t('연동')
                                      : i18next.t('관리'),
                                  onClick: () => {
                                    item.afterRegisterClick();
                                  },
                                }
                              : {
                                  appearance: 'primary',
                                  label: i18next.t('연동'),
                                  onClick: () => {
                                    item.beforeRegisterClick();
                                  },
                                })}
                          />
                        )}
                      </BGButtonGroup>
                    </AccountItem>
                  ))}
                </AccountItemGroup>
              ))}
            </div>
          </BorderSection>
        </div>
      </div>
      <div className="account-info-container">
        <div
          css={[
            css`
              width: 100%;
            `,
            accountCardCss,
          ]}
        >
          <div className="account-info-card-wrapper" style={{ position: 'relative' }}>
            <div className="name-wrapper">
              <div className="name">{i18next.t('내 정보')}</div>
              <div className="button-wrapper">
                <BDSButton appearance="secondary" label={i18next.t('수정')} onClick={() => setEditMyInfoModal(true)} />
              </div>
            </div>
            <div className="table-wrapper">
              <div className="row">
                <span className="item-title">{i18next.t('이름')}</span>
                <span className="item-content">{loadingContents(props.me?.name ?? '')}</span>
              </div>
              <div className="row">
                <span className="item-title">{i18next.t('전화번호')}</span>
                <span className="item-content">{loadingContents(props.me?.phone ?? '')}</span>
              </div>
              <div className="row">
                <span className="item-title">{i18next.t('이메일')}</span>
                <span className="item-content">{loadingContents(props.me?.email ?? '')}</span>
              </div>
            </div>
            <div className="secondary-button-wrapper">
              <button type="button" className="button-text" onClick={() => setEditPasswordModal(true)}>
                <u>{i18next.t('비밀번호 변경')}</u>
              </button>
            </div>
          </div>
          <div className="account-info-card-wrapper" style={{ position: 'relative' }}>
            <div className="name-wrapper">
              <div className="name">{i18next.t('쇼핑몰 정보')}</div>
              <div className="button-wrapper">
                <BDSButton
                  appearance="secondary"
                  label={i18next.t('수정')}
                  onClick={() => setEditMallInfoModal(true)}
                />
              </div>
            </div>
            <div className="table-wrapper">
              <div className="row">
                <span className="item-title">{i18next.t('쇼핑몰명')}</span>
                <span className="item-content">{loadingContents(props.mall?.name ?? '')}</span>
              </div>
              <div className="row">
                <span className="item-title">{i18next.t('쇼핑몰 URL')}</span>
                <span className="item-content">{loadingContents(StorageService.selectedMall.url)}</span>
              </div>
              <div className="row">
                <span className="item-title">{i18next.t('업종 카테고리')}</span>
                <span className="item-content">
                  {loadingContents(
                    `${i18next.t((props.mall && props.mall.classification?.parent.name) ?? '')} > ${i18next.t(
                      (props.mall && props.mall.classification?.name) ?? '',
                    )}`,
                  )}
                </span>
              </div>
              <div className="row-horizontal-line" />
              <div className="row" style={{ paddingBottom: '8px' }}>
                <span className="item-title">{i18next.t('쇼핑몰 로고')}</span>
                <span className="item-content">
                  <div className="secondary-button-wrapper">
                    <button
                      type="button"
                      className="button-text"
                      onClick={() => {
                        setLogoUploadModal(true);
                      }}
                    >
                      <u>{i18next.t('로고 등록 및 수정')}</u>
                    </button>
                  </div>
                </span>
              </div>
              <div className="description">{i18next.t('쇼핑몰 로고는 구글, 카카오 광고에 사용됩니다.')}</div>
              <div className="logo-wrapper">
                <div className={`logo-square ${props.mall && !props.mall.square_logo_url ? 'empty' : ''}`}>
                  {props.isLoading && <Skeleton style={{ height: '100%', lineHeight: 'unset' }} />}
                  {!props.isLoading && props.mall && props.mall.square_logo_url && (
                    <img className="logo-img" src={props.mall.square_logo_url} alt="square logo" />
                  )}
                  {!props.isLoading && props.mall && !props.mall.square_logo_url && (
                    <div className="logo-font-icon-wrapper">
                      <BDSFontIcon name="ic-img" size="20px" color="#7e8696" />
                    </div>
                  )}
                </div>
                <div className={`logo-landscape ${props.mall && !props.mall.landscape_logo_url ? 'empty' : ''}`}>
                  {props.isLoading && <Skeleton style={{ height: '100%', lineHeight: 'unset' }} />}
                  {!props.isLoading && props.mall && props.mall.landscape_logo_url && (
                    <img className="logo-img" src={props.mall.landscape_logo_url} alt="square logo" />
                  )}
                  {!props.isLoading && props.mall && !props.mall.landscape_logo_url && (
                    <div className="logo-font-icon-wrapper">
                      <BDSFontIcon name="ic-img" size="20px" color="#7e8696" />
                    </div>
                  )}
                </div>
              </div>
              <div className="row-horizontal-line" />
              <div className="row">
                <span className="item-title">{i18next.t('등록된 팀원')}</span>
                <span className="item-content">
                  {loadingContents(
                    i18next.t('{{count}}명', { count: props.mall ? props.mall.company.users.length : 0 }),
                  )}
                </span>
              </div>
            </div>
            <div className="secondary-button-wrapper">
              <button
                type="button"
                className="button-text"
                onClick={() => {
                  setManageMemberModal(true);
                }}
              >
                <u>{i18next.t('등록된 팀원 관리')}</u>
              </button>
            </div>
          </div>
          <div className="account-info-card-wrapper" style={{ position: 'relative' }}>
            <div className="name-wrapper">
              <div className="name">{i18next.t('대시보드 설정')}</div>
            </div>
            <div className="table-wrapper">
              <div className="row start">
                <span className="item-title" style={{ width: '138px' }}>
                  {i18next.t('기여 기간')}
                  <BGTooltip
                    title={i18next.t('Kakao 정책으로 인해 Kakao의 기여 기간은 “클릭 7일" 고정입니다') as string}
                  >
                    <div className="tooltip-wrapper">
                      <BDSFontIcon name="ic-info" size="20px" color="#7E8696" />
                    </div>
                  </BGTooltip>
                </span>
                <span className="item-content">
                  <BDSSelectBox
                    appearance="gray"
                    style={{ width: '160px', display: 'inline-block' }}
                    list={AttributionWindow.toSelects(i18next as UseTranslationResponse<'translation'>)}
                    defaultValue={AttributionWindow.ONE_DAY_VIEW_SEVEN_DAYS_CLICK}
                    displayValue={(item: any) => {
                      return item.label;
                    }}
                    isSelected={(item: any) => {
                      return StorageService.attributionWindow === item.value;
                    }}
                    handleUpdate={handleChangeAttribution}
                  />
                </span>
              </div>
              <div className="row start">
                <span className="item-title">{i18next.t('언어')}</span>
                <span className="item-content">
                  <BDSSelectBox
                    appearance="gray"
                    style={{ width: '160px', display: 'inline-block' }}
                    list={translateList}
                    defaultValue={translate}
                    displayValue={(curLanguage: any) => {
                      return i18next.t(curLanguage.label as string);
                    }}
                    isSelected={(curLanguage: any) => {
                      return curLanguage.value === translate;
                    }}
                    handleUpdate={(curLanguage: any) => handleChangeDashboardLanguage(curLanguage)}
                  />
                </span>
              </div>
              <div className="row start" style={{ paddingBottom: 0 }}>
                <span className="item-title">{i18next.t('통화 / 시간대')}</span>
                <div className="item-content currency-time-content">
                  <span>{currencyCodeTimeText && currencyCodeTimeText.currencyText}</span>
                  <span>{currencyCodeTimeText && currencyCodeTimeText.utcText}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 내 정보 수정 모달 */}
      {props.me && (
        <WrapperModal
          isOpen={editMyInfoModal}
          close={() => {
            setEditMyInfoModal(false);
          }}
          position={{ width: '320px' }}
        >
          <ModalEditMyInfo
            name={props.me.name}
            phone={props.me.phone}
            close={() => {
              setEditMyInfoModal(false);
            }}
          />
        </WrapperModal>
      )}
      {/* 쇼핑몰 수정 모달 */}
      {props.mall && (
        <WrapperModal
          isOpen={editMallInfoModal}
          close={() => {
            setEditMallInfoModal(false);
          }}
          position={{ width: '320px', display: 'block', overflow: 'unset' }}
        >
          <ModalEditMallInfo
            mall={props.mall}
            close={() => {
              setEditMallInfoModal(false);
            }}
          />
        </WrapperModal>
      )}

      {/* 비밀번호 변경 모달 */}
      <WrapperModal
        isOpen={editPasswordModal}
        close={() => {
          setEditPasswordModal(false);
        }}
        position={{ width: '320px' }}
      >
        <ModalEditPassword
          close={() => {
            setEditPasswordModal(false);
          }}
        />
      </WrapperModal>

      {/* 등록된 팀원 관리 모달 */}
      {props.mall && (
        <WrapperModal
          isOpen={manageMemberModal}
          position={{ height: '640px' }}
          close={() => {
            setManageMemberModal(false);
          }}
        >
          <ModalManageMember
            companyId={props.mall.company.id}
            users={props.mall.company.users}
            close={() => {
              setManageMemberModal(false);
            }}
          />
        </WrapperModal>
      )}

      {/* 쇼핑몰 로고 업로드 모달 */}
      {props.mall && (
        <WrapperModal
          isOpen={logoUploadModal}
          close={() => {
            setLogoUploadModal(false);
          }}
          position={{ width: '360px' }}
        >
          <LogoUploadModal
            square={props.mall.square_logo_url}
            landscape={props.mall.landscape_logo_url}
            close={() => {
              setLogoUploadModal(false);
            }}
            onSubmitted={() => {
              queryClient.invalidateQueries('mallCompany');
            }}
          />
        </WrapperModal>
      )}

      {/* 페이스북 자산 연동 모달 */}
      {props.mall && (
        <WrapperModal
          isOpen={fbAssetModal}
          close={() => {
            setFbAssetModal(false);
          }}
          position={{ width: '360px', maxHeight: '853px' }}
        >
          <FbAssetModal
            types={[
              'fb-page',
              'fb-instagram-account',
              ...(isManager ? (['fb-business', 'fb-ad-account', 'fb-pixel'] as MediaAssetType[]) : []),
            ]}
            assets={props.mall.medias}
            close={() => {
              setFbAssetModal(false);
            }}
            onSubmitted={() => {
              queryClient.invalidateQueries('mallCompany');
            }}
          />
        </WrapperModal>
      )}

      {/* 구글애즈 자산 연동 모달 */}
      {props.mall && (
        <WrapperModal
          isOpen={awAssetModal}
          close={() => {
            setAwAssetModal(false);
          }}
          position={{ width: '640px', overflow: 'visible' }}
        >
          <AwAssetModal
            types={['youtube', ...(isManager ? (['aw-client-account', 'ga-account'] as MediaAssetType[]) : [])]}
            assets={props.mall.medias}
            close={() => {
              setAwAssetModal(false);
            }}
            onSubmitted={() => {
              queryClient.invalidateQueries('mallCompany');
            }}
          />
        </WrapperModal>
      )}

      {/* 카카오 자산 연동 모달 */}
      {props.mall && isManager && (
        <WrapperModal
          isOpen={kkAssetModal}
          close={() => {
            setKkAssetModal(false);
          }}
          position={{ width: '360px', overflow: 'unset' }}
        >
          <KkAssetModal
            types={['kk-ad-account', 'kk-track']}
            assets={props.mall.medias}
            close={() => {
              setKkAssetModal(false);
            }}
            onSubmitted={() => {
              queryClient.invalidateQueries('mallCompany');
            }}
          />
        </WrapperModal>
      )}
      {/* 빅인 연동 모달 */}
      {props.mall && (
        <WrapperModal
          isOpen={linkedBiginCrmModal}
          close={() => {
            setLinkedBiginCrmModal(false);
          }}
          position={{ width: '320px' }}
        >
          <LinkedBiginCrmModal
            assets={props.mall.medias}
            isCreateCampaign={false}
            close={() => {
              setLinkedBiginCrmModal(false);
            }}
            onSubmitted={() => {
              queryClient.invalidateQueries('mallCompany');
            }}
          />
        </WrapperModal>
      )}
      {props.mall && (
        <WrapperModal
          isOpen={crmProjectInfoModal}
          close={() => {
            setCrmProjectInfoModal(false);
          }}
          position={{ width: '320px' }}
        >
          <CrmProjectInfoModal
            assets={props.mall.medias}
            isOrphan={props.isOrphan}
            close={() => {
              setCrmProjectInfoModal(false);
            }}
          />
        </WrapperModal>
      )}
    </SkeletonTheme>
  );
};
