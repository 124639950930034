import React, { ReactElement, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PlanBuilder from '../../services/plans/PlanBuilder';
import { BGButton } from '../BGButton/BGButton';
import { BorderSection } from '../BorderSection/BorderSection';
import { MallApi } from '../../lib/api/MallApi';
import { baseCss } from './PlanSelect.style';
import { useDataStore } from '../../context/Store';

export interface PlanSelectProps {
  mallId: number;
  planType: PlanType | 'cloud';
  onSubmitted?: () => void;
}

export const PlanSelect = (props: PlanSelectProps): ReactElement => {
  const i18next = useTranslation();
  const navigate = useNavigate();
  const { dialogStore } = useDataStore();

  const plan = useMemo(() => PlanBuilder.build(props.planType as PlanType, i18next), [props.planType]);

  const subscribe = async () => {
    let promise;

    if (props.planType === 'cloud') {
      promise = MallApi()
        .createTask(props.mallId, {
          task_type: 'subscribe',
          data: { plan: plan.type },
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            const response = error?.response;
            if (response && response.status === 422) {
              dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

              throw error;
            }
          }

          dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
          throw error;
        });
    } else {
      promise = MallApi()
        .createSubscription(props.mallId, { plan: plan.type })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            const response = error?.response;
            if (response && response.status === 422) {
              dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

              throw error;
            }
          }

          dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
          throw error;
        });
    }

    return promise;
  };

  const onSubmit = async () => {
    return subscribe().then(() => {
      if (props.onSubmitted) {
        props.onSubmitted();
      }
      navigate(`/malls/${props.mallId}/plans/recommendation/${props.planType}/complete`);
    });
  };

  return (
    <div css={[baseCss]}>
      <BorderSection style={{ padding: '20px', width: '320px' }}>
        <div
          css={[baseCss]}
          style={{
            width: '100%',
            minHeight: '488px',
            position: 'relative',
          }}
        >
          <div className="icon-wrapper">
            <img src={plan.image} className="icon" alt="plan-icon" />
          </div>
          <div className="title-wrapper">
            <p className="text-h1 text-color-main">{plan.name}</p>
          </div>
          <div className="desc-wrapper">
            <p className="text-input text-color-main mb-6">{plan.description}</p>
          </div>

          <div className="feature-items">
            {plan.features.map((feature: string) => (
              <div
                className="text-h5 text-color-main mb-2"
                style={{ display: 'flex', flexDirection: 'row' }}
                key={feature}
              >
                <div className="feature-item-icon" />
                <div className="feature-item-desc">
                  <span>· {feature}</span>
                </div>
              </div>
            ))}
          </div>

          <div className="footer">
            <div className="price-wrapper">
              {props.planType !== 'managed' && (props.planType === 'cloud' || plan.subscriptionFee === null) && (
                <React.Fragment>
                  <span className="bold">{i18next.t('구독료 79만원부터')}</span>
                  <span className="description"> {i18next.t('/월')}</span>
                </React.Fragment>
              )}
              <p className="bold">
                {props.planType !== 'cloud' &&
                  plan.subscriptionFee !== null &&
                  i18next.t('구독료 {{number}}원', { number: plan.subscriptionFee })}
                {props.planType === 'managed' && i18next.t('내부 매니저에게만 해당 선택지 노출')}
              </p>
              {plan.feeRate && (
                <p className="description">
                  {i18next.t('환전 수수료 등을 포함한 {{number}}% 종합 수수료 부과', { number: plan.feeRate })}
                </p>
              )}
            </div>

            <div className="buttons-wrapper">
              <BGButton
                label={i18next.t('플랜 선택하기')}
                type="submit"
                style={{ width: '100%', alignSelf: 'center', justifyContent: 'center', marginTop: '20px' }}
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
      </BorderSection>
    </div>
  );
};
