import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSSelectBox, BDSTextArea, BDSButton } from '@bigin/bigin-ui-components';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import moment from 'moment';

import { baseCss, downgradeBodyCss } from './PlanChangeModal.style';
import { BGButtonGroup } from '../../../components/BGButtonGroup/BGButtonGroup';
import { InputError } from '../../../components/InputError/InputError';
import { UserApi } from '../../../lib/api/UserApi';
import Plan from '../../../services/plans/Plan';
import { BGInput } from '../../../components/BGInput/BGInput';
import StorageService from '../../../services/StorageService';

const DowngradeBody = (): ReactElement => {
  const i18next = useTranslation();

  const warnings = [
    i18next.t('고객 데이터 수집 및 활용 제한되어 추후 픽셀 서비스 이용시 비용이 발생됩니다.'),
    i18next.t('광고 집행시 수수료가 발생합니다.'),
    i18next.t('월 최대 예산이 500만원 미만으로 제한됩니다.'),
    i18next.t('전문 컨설턴트의 컨설팅 서비스 제공이 중단됩니다.'),
    i18next.t('카탈로그 광고 사용이 제한되어 추후 서비스 이용시 비용이 발생합니다.'),
  ];

  return (
    <div css={[downgradeBodyCss]}>
      <div className="body">
        <p className="description">{i18next.t('잠시만요! 지금 클라우드 플랜에서 브랜드몰 플랜으로 변경하시면,')}</p>
        <div className="warning-item-wrapper">
          {warnings.map((item, index) => (
            <div key={item} className="warning-item">
              <span className="warning-item-index">{index + 1}</span>
              <span className="warning-item-message">{item}</span>
            </div>
          ))}
        </div>
        <p className="description">{i18next.t('Bigin Ads 솔루션의 온전한 기능을 사용할 수 없게 됩니다.')}</p>
      </div>
    </div>
  );
};

enum ModalType {
  DOWNGRADE,
  UPGRADE,
  TIER,
}

interface IProps {
  fromPlan: PlanType;
  toPlan: PlanType;
  dateToChange: moment.Moment;
  onSubmit: (form: IPlanChangeModalForm) => void;
  close: () => void;
}

export interface IPlanChangeModalForm {
  plan: PlanType;
  reason: string;
  name: string;
  email: string;
  phone: string;
}

interface IItem {
  value: PlanType;
  label: string;
}

export const PlanChangeModal = (props: IProps): ReactElement => {
  const i18next = useTranslation();

  const [plan, setPlan] = useState<PlanType>(props.toPlan);
  const [reason, setReason] = useState<string>('');
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phone, setPhone] = useState<string>();

  const modalType = useMemo(() => {
    if (Plan.isFreeType(props.fromPlan) && Plan.isCloudType(props.toPlan)) {
      return ModalType.UPGRADE;
    }
    if (Plan.isCloudType(props.fromPlan) && Plan.isFreeType(props.toPlan)) {
      return ModalType.DOWNGRADE;
    }
    if (Plan.isCloudType(props.fromPlan) && Plan.isCloudType(props.toPlan)) {
      return ModalType.TIER;
    }

    return ModalType.UPGRADE;
  }, [props.fromPlan, props.toPlan]);

  const items = useMemo((): IItem[] => {
    if (props.fromPlan === 'smartstore') {
      return [];
    }
    if (props.fromPlan === 'brandmall') {
      return [];
    }
    if (props.fromPlan === 'cloud_tier_1') {
      return [{ value: 'cloud_tier_2', label: i18next.t('월 최대 예산보다 증액') }];
    }
    if (props.fromPlan === 'cloud_tier_2') {
      return [
        { value: 'cloud_tier_3', label: i18next.t('월 최대 예산보다 증액') },
        { value: 'cloud_tier_1', label: i18next.t('월 최대 예산보다 감액') },
      ];
    }
    if (props.fromPlan === 'cloud_tier_3') {
      return [
        { value: 'cloud_tier_4', label: i18next.t('월 최대 예산보다 증액') },
        { value: 'cloud_tier_2', label: i18next.t('월 최대 예산보다 감액') },
      ];
    }
    if (props.fromPlan === 'cloud_tier_4') {
      return [
        { value: 'cloud_tier_5', label: i18next.t('월 최대 예산보다 증액') },
        { value: 'cloud_tier_3', label: i18next.t('월 최대 예산보다 감액') },
      ];
    }
    if (props.fromPlan === 'cloud_tier_5') {
      return [{ value: 'cloud_tier_4', label: i18next.t('월 최대 예산보다 감액') }];
    }

    return [];
  }, [props.fromPlan]);

  const { register, formState, setValue, handleSubmit } = useForm<IPlanChangeModalForm>({
    mode: 'all',
  });
  useEffect(() => {
    register('plan', { value: plan, required: i18next.t('필수 입력란입니다.') as string });
    register('reason', {
      value: reason,
      required: modalType !== ModalType.DOWNGRADE ? (i18next.t('필수 입력란입니다.') as string) : undefined,
    });
    register('name', { value: name, required: i18next.t('필수 입력란입니다.') as string });
    register('email', { value: email, required: i18next.t('필수 입력란입니다.') as string });
    register('phone', { value: phone, required: i18next.t('필수 입력란입니다.') as string });
  }, []);

  useQuery<User>('me', async () => (await UserApi().getMe()).data.data, {
    onSuccess: (data) => {
      setName(StorageService.selectedMall.name);
      setValue('name', StorageService.selectedMall.name, { shouldValidate: true });

      setEmail(data.email);
      setValue('email', data.email, { shouldValidate: true });

      setPhone(data.phone);
      setValue('phone', data.phone, { shouldValidate: true });
    },
  });

  return (
    <div css={[baseCss]}>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        {modalType === ModalType.DOWNGRADE && (
          <div className="header">
            <div className="text-h1 text-color-main">{i18next.t('클라우드 플랜 구독 종료')}</div>
          </div>
        )}
        {modalType === ModalType.UPGRADE && (
          <div className="header">
            <div className="text-h1 text-color-main">{i18next.t('플랜 변경 신청')}</div>
            <p className="text-input text-color-sub">
              {i18next.t('플랜을 변경합니다. 상담을 위해 광고주님의 정보를 확인합니다.')}
            </p>
          </div>
        )}
        {modalType === ModalType.TIER && (
          <div className="header">
            <div className="text-h1 text-color-main">{i18next.t('월 최대 예산 변경 신청')}</div>
            <p className="text-input text-color-sub">
              {i18next.t(
                '현재 구독중인 클라우드 플랜의 월 최대 예산을 변경합니다. 상담을 위해 광고주님의 정보를 확인합니다.',
              )}
            </p>
          </div>
        )}
        {modalType === ModalType.DOWNGRADE ? (
          <DowngradeBody />
        ) : (
          <div className="body">
            {modalType === ModalType.TIER && (
              <div className="form-input-wrapper">
                <p className="form-input-label">{i18next.t('변경 내용')}</p>
                <BDSSelectBox
                  appearance="gray"
                  defaultValue={undefined}
                  displayValue={(item: any) => item?.label || i18next.t('선택하기')}
                  handleUpdate={(item: any) => {
                    setValue('plan', item.value, { shouldValidate: true });
                    setPlan(item.value);
                  }}
                  isSelected={(item: any) => item?.value === plan}
                  list={items}
                />
                {formState.errors.plan && <InputError message={formState.errors.plan.message as string} />}
              </div>
            )}
            <div className="form-input-wrapper">
              <p className="form-input-label">{i18next.t('변경 사유')}</p>
              <BDSTextArea
                textAreaProps={{
                  value: reason,
                  placeholder: i18next.t('상담을 위해 변경 사유를 자세하게 입력해주세요.'),
                  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setValue('reason', event.target.value, { shouldValidate: true });
                    setReason(event.target.value);
                  },
                }}
              />
              {formState.errors.reason && <InputError message={formState.errors.reason.message as string} />}
            </div>
            <div className="form-input-wrapper">
              <p className="form-input-label">{i18next.t('쇼핑몰명')}</p>
              <BGInput
                inputProps={{
                  disabled: true,
                  value: name,
                  type: 'text',
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('name', event.target.value, { shouldValidate: true });
                    setName(event.target.value);
                  },
                }}
              />
              {formState.errors.name && <InputError message={formState.errors.name.message as string} />}
            </div>
            <div className="form-input-wrapper">
              <span className="form-input-label">{i18next.t('이메일')}</span>
              <BGInput
                inputProps={{
                  disabled: true,
                  value: email,
                  type: 'text',
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('email', event.target.value, { shouldValidate: true });
                    setEmail(event.target.value);
                  },
                }}
              />
              {formState.errors.email && <InputError message={formState.errors.email.message as string} />}
            </div>
            <div className="form-input-wrapper">
              <p className="form-input-label">{i18next.t('전화번호')}</p>
              <BGInput
                inputProps={{
                  disabled: true,
                  value: phone,
                  type: 'text',
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('phone', event.target.value, { shouldValidate: true });
                    setPhone(event.target.value);
                  },
                }}
              />
              {formState.errors.phone && <InputError message={formState.errors.phone.message as string} />}
            </div>
            <div className="form-input-wrapper">
              <p className="form-input-label">{i18next.t('변경 예정일')}</p>
              <p>{props.dateToChange.format('YYYY.MM.DD')}</p>
            </div>
          </div>
        )}
        <div className="footer">
          {modalType === ModalType.DOWNGRADE ? (
            <BGButtonGroup rightAlign>
              <BDSButton
                label={i18next.t('플랜 변경')}
                type="submit"
                appearance="secondary"
                isDisabled={!formState.isValid}
              />
              <BDSButton
                label={i18next.t('취소')}
                onClick={() => {
                  if (props.close) {
                    props.close();
                  }
                }}
              />
            </BGButtonGroup>
          ) : (
            <BGButtonGroup rightAlign>
              <BDSButton
                label={i18next.t('취소')}
                appearance="secondary"
                onClick={() => {
                  if (props.close) {
                    props.close();
                  }
                }}
              />
              <BDSButton label={i18next.t('신청')} type="submit" isDisabled={!formState.isValid} />
            </BGButtonGroup>
          )}
        </div>
      </form>
    </div>
  );
};
