import { UseTranslationResponse } from 'react-i18next';

import Plan from './Plan';

export default class CloudPlan extends Plan {
  constructor(i18next: UseTranslationResponse<'translation'>, tier: 1 | 2 | 3 | 4 | 5 = 1) {
    super();

    this.type = (() => {
      if (tier === 5) {
        return 'cloud_tier_5';
      }
      if (tier === 4) {
        return 'cloud_tier_4';
      }
      if (tier === 3) {
        return 'cloud_tier_3';
      }
      if (tier === 2) {
        return 'cloud_tier_2';
      }
      if (tier === 1) {
        return 'cloud_tier_1';
      }

      return 'cloud_tier_1';
    })();
    this.image = '/assets/img/plan/ic-plan-cloud.svg';
    this.name = `${i18next.t('클라우드')} ${i18next.t('플랜')}`;
    this.description = i18next.t('적극적인 마케팅을 계획하시는군요!\n컨설턴트와 함께 최적의 마케팅을 진행해보세요.');
    this.features = [
      i18next.t('통합 캠페인 생성 및 관리'),
      i18next.t('전체 광고 목표 활성화'),
      i18next.t('월 광고 예산 한도 커스텀'),
      i18next.t('Bigin SDK 자동 설치'),
      i18next.t('광고 소재 제작 지원 및 대행'),
      i18next.t('전담 컨설턴트 배정'),
      i18next.t('CRM 데이터 연계 (bigin)'),
    ];
    this.subscriptionFee = (() => {
      if (tier === 5) {
        return 4990000;
      }
      if (tier === 4) {
        return 3990000;
      }
      if (tier === 3) {
        return 2790000;
      }
      if (tier === 2) {
        return 1590000;
      }
      if (tier === 1) {
        return 790000;
      }

      return null;
    })();
    this.feeRate = null;
    this.policy = {
      management: {
        budgetLimit: i18next.t('무제한') as string,
        commission: true,
        consult: true,
        oneOnOneChat: true,
        alimtalkPro: true,
        solutionEducation: true,
      },
      analytics: {
        biginCrm: true,
        biginSdk: true,
      },
      report: {
        campaign: true,
        weeklyPerformance: true,
      },
      creative: {
        createCatalog: true,
        updateProductImage: true,
        editTool: true,
        support: true,
      },
      media: {
        facebookImage: true,
        facebookVideo: true,
        facebookCollection: true,
        facebookDynamic: true,
        googleResponsive: true,
        googleDynamic: true,
        googleShopping: true,
        youtube: true,
        kakaoBizboard: true,
        kakaoNative: true,
      },
    };
  }
}
