import React, { useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, Theme, useTheme } from '@emotion/react';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { CampaignStatusToggle } from './CampaignStatusToggle';
import { CampaignMoreButton } from './CampaignMoreButton';
import { getMoreButtonObject } from '../CampaignDashboardDef';
import StorageService from '../../../services/StorageService';
import CampaignSetting from '../../../recoil/CampaignSetting';
import { goalTypeText } from '../../../utils/text';
import { BGTooltip } from '../../../components/BGTooltip/BGTooltip';
import { IMAGE_PROXY_URL } from '../../../utils/utils';
import { filters } from '../../../utils/filter';
import { CurrencyCodeRecoil } from '../../../recoil/Currency';

export interface CampaignTitleProps {
  campaign?: UnifiedCampaign;
  isSkeleton?: boolean;
  handleStatusToggle?: (campaign: UnifiedCampaign) => void;
  handleClick?: () => void;
  handleShowChangeDailyBudget?: (campaign: UnifiedCampaign) => void;
}

export const CampaignTitle = (props: CampaignTitleProps): JSX.Element => {
  const i18next = useTranslation();
  const { campaign } = props;
  const navigate = useNavigate();
  const theme: Theme = useTheme();
  const moreButtonObject = getMoreButtonObject(i18next);
  const resetCampaignState = useResetRecoilState(CampaignSetting.campaignSetting);
  const resetCampaignAdState = useResetRecoilState(CampaignSetting.campaignAdSetting);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const statusColor = () => {
    if (!campaign) return 'warning';
    if (campaign.status_tag.code === 'CAMPAIGN_STATUS_LIVE') {
      return 'success';
    }

    if (campaign.status_tag.code === 'CAMPAIGN_STATUS_REJECTED') {
      return 'error';
    }

    if (
      campaign.status_tag.code === 'CAMPAIGN_STATUS_END' ||
      campaign.status_tag.code === 'CAMPAIGN_STATUS_NON_ACTIVE'
    ) {
      return 'icon';
    }

    return 'warning';
  };

  const statusText = () => {
    if (!campaign) return i18next.t('심사 중');

    if (campaign.status_tag.code === 'CAMPAIGN_STATUS_LIVE') {
      return i18next.t('활성');
    }

    if (campaign.status_tag.code === 'CAMPAIGN_STATUS_REJECTED') {
      return i18next.t('운영 불가');
    }

    if (
      campaign.status_tag.code === 'CAMPAIGN_STATUS_END' ||
      campaign.status_tag.code === 'CAMPAIGN_STATUS_NON_ACTIVE'
    ) {
      return i18next.t('비활성');
    }

    return i18next.t('심사 중');
  };

  const settingValue = useMemo(() => {
    if (!props.campaign) return [];

    const budget = i18next.t('일 {{budget}}', {
      budget: filters.formatCurrency({ value: props.campaign?.daily_budget_e6 / 1e6, currencyCode }),
    });
    const goal = i18next.t(goalTypeText[props.campaign?.goals[0]]);
    const date = moment(props.campaign.created_at).format('YYYY.MM.DD');

    return [goal, budget, date];
  }, [props.campaign]);

  return (
    <div
      className="text-h5 text-color-main d-flex align-center"
      css={css`
        word-break: break-all;
      `}
    >
      {props.isSkeleton && <Skeleton className="skeleton" style={{ width: '448px' }} />}
      {campaign && (
        <div className="d-flex fill-width gap-2">
          <div
            className="d-flex gap-2"
            css={css`
              cursor: pointer;
            `}
            onClick={() => {
              if (props.handleClick) {
                props.handleClick();
              }
            }}
          >
            {campaign.status_tag.code === 'CAMPAIGN_STATUS_END' && (
              <div className="disabled-cell-overlay" style={{ width: 'calc(100% - 44px)' }} />
            )}
            {campaign?.thumbnail_image ? (
              <img
                className="border-divider cell-icon"
                css={css`
                  width: 40px;
                  height: 40px;
                  border-radius: 3.3px;
                `}
                src={`${IMAGE_PROXY_URL}${campaign?.thumbnail_image}`}
                alt={campaign?.name}
              />
            ) : (
              <div
                className="d-flex align-center justify-center border-divider bg-color-bg3"
                css={css`
                  width: 40px;
                  height: 40px;
                  border-radius: 3.3px;
                `}
              >
                <BDSFontIcon name="ic-img" size="20px" color="#7e8696" />
              </div>
            )}
            <div
              css={css`
                width: 312px;
                margin-right: 8px;
              `}
            >
              <div className="text-disclaimer">
                <BGTooltip title={statusText()} placement="top">
                  <div
                    css={css`
                      height: 8px;
                      width: 8px;
                      border-radius: 10px;
                      margin-top: 3px;
                      margin-right: 8px;
                      display: inline-flex;
                    `}
                    style={{
                      border: `solid 2px ${theme.colors[statusColor()]}`,
                      backgroundColor: theme.colors[statusColor()],
                    }}
                  />
                </BGTooltip>
                {settingValue.map((value) => value).join(' · ')}
              </div>
              <div
                css={css`
                  width: fit-content;
                  max-width: 312px;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                `}
              >
                {props.campaign && i18next.t(campaign.name || '')}
              </div>
            </div>
          </div>
          <div
            css={css`
              display: inline-flex;
              gap: 16px;
            `}
          >
            <CampaignStatusToggle
              campaign={campaign as UnifiedCampaign}
              onChangeToggleButton={(isActive: boolean, curCampaign) => {
                if (props.handleStatusToggle) {
                  props.handleStatusToggle(curCampaign as UnifiedCampaign);
                }
              }}
            />
            <CampaignMoreButton
              campaign={campaign as UnifiedCampaign}
              getMoreButtonList={() => {
                const buttonItems = [];
                buttonItems.push(moreButtonObject.copy);
                buttonItems.push(moreButtonObject.modify);

                return buttonItems;
              }}
              handleMoreButton={(curCampaign, value: string) => {
                if (value === 'copy') {
                  resetCampaignState();
                  resetCampaignAdState();
                  navigate(`/malls/${StorageService.selectedMall.id}/copy-campaign?id=${curCampaign.id}`);
                }
                if (value === 'modify' && props.handleShowChangeDailyBudget) {
                  resetCampaignState();
                  resetCampaignAdState();
                  props.handleShowChangeDailyBudget(curCampaign);
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
