import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';

import { useTranslation } from 'react-i18next';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { baseCss } from './WhatIsConsultant.style';

interface WhatIsConsultantProps {
  close: () => void;
}

export const WhatIsConsultant = (props: WhatIsConsultantProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <div css={[baseCss]}>
      <div className="header">
        <span className="text-h1 text-color-main">{i18next.t('Bigin Ads 컨설턴트란?')}</span>
        <BDSFontIcon
          name="ic-close"
          handleClick={() => {
            props.close();
          }}
        />
      </div>
      {/* TODO backgroundImage: 'url(/...)' 삽입 */}
      <div className="image-wrapper" style={{ backgroundImage: 'url(/assets/img/sign-up/what-is-consultant.webp)' }} />
      <div className="contents">
        <div className="content-item">
          <p className="content-title">
            {i18next.t('Bigin Ads 컨설턴트는 솔루션을 만들고 운영하는\n전문가 집단이에요.')}
          </p>
          <p className="content-desc">
            {i18next.t(
              'Bigin Ads 컨설턴트는 광고를 진행하면서 마주하는 다양한 어려움을 함께 고민하고 해결책을 제안해드려요.',
            )}
          </p>
        </div>
        <div className="content-item">
          <p className="content-title">{i18next.t('Bigin Ads 컨설턴트의 도움이 가장 필요하신 분')}</p>
          <p className="content-desc">· {i18next.t('디지털 광고를 처음 진행해보시는 분')}</p>
          <p className="content-desc">· {i18next.t('디지털 광고 매체가 복잡하고 어려운 분')}</p>
          <p className="content-desc">· {i18next.t('디지털 광고 관리할 시간이 없으신 분')}</p>
          <p className="content-desc">· {i18next.t('전문가의 도움을 통해 폭발적 매출 성장을 원하는 분')}</p>
        </div>
      </div>
    </div>
  );
};
