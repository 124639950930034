import React, { ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { BDSButton } from '@bigin/bigin-ui-components';
import { baseCss } from './ManageMember.style';
import { ModalInviteMember } from '../InviteMember/InviteMember';
import { WrapperModal } from '../../../../components/WrapperModal/WrapperModal';
import { UserApi } from '../../../../lib/api/UserApi';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useDataStore } from '../../../../context/Store';
import { MoreButton } from '../../../../components/MoreButton/MoreButton';

interface UserRowProps {
  user: User;
  isPending: boolean;
}

const UserRow = (props: UserRowProps): ReactElement => {
  const { ...i18next } = useTranslation();
  const queryClient = useQueryClient();
  const { dialogStore } = useDataStore();

  const deletePendingUser = (id: number) => {
    return UserApi()
      .deletePendingUser(id)
      .then(() => queryClient.invalidateQueries('pendingUsers'))
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };
  const deleteUser = (id: number) => {
    return UserApi()
      .deleteCompanyUser(id)
      .then(() => queryClient.invalidateQueries('company'))
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <tr>
      <td>
        {props.user.name} {props.isPending ? <div className="badge">{i18next.t('수락 대기중')}</div> : null}
      </td>
      <td>{props.user.email}</td>
      <td>
        {props.user.phone}
        <span style={{ float: 'right' }}>
          <MoreButton
            items={[
              {
                value: 'delete',
                label: i18next.t('팀원 제외하기'),
              },
            ]}
            onMenuButtonClick={() => {
              if (props.isPending) {
                deletePendingUser(props.user.id);
              } else {
                deleteUser(props.user.id);
              }
            }}
          />
        </span>
      </td>
    </tr>
  );
};

export interface ModalManageMemberProps {
  companyId: number;
  users: User[];
  close: () => void;
}

export const ModalManageMember = (props: ModalManageMemberProps): ReactElement => {
  const { ...i18next } = useTranslation();
  const [inviteModal, setInviteModal] = useState<boolean>(false);

  const { data: pendingUsers } = useQuery<User[]>(
    'pendingUsers',
    async () => (await UserApi().getPendingUsers(props.companyId)).data.data,
  );

  return (
    <div css={[baseCss]}>
      <div className="manage-member-pannel-wrapper">
        <div className="manage-member-title-wrapper">
          <div
            className="text-h1 text-color-main"
            style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
          >
            {i18next.t('등록된 팀원 관리')}
            <BDSButton
              fontIcon="ic-add"
              appearance="secondary"
              label={i18next.t('팀원 추가')}
              onClick={() => {
                setInviteModal(true);
              }}
              style={{
                marginLeft: '8px',
              }}
            />
            <BDSButton
              appearance="secondary"
              fontIcon="ic-close"
              fontIconColor="#7e8696"
              style={{
                border: 'none',
                backgroundColor: '#fff',
                position: 'absolute',
                right: '-8px',
                top: '-8px',
              }}
              onClick={() => {
                props.close();
              }}
            />
          </div>
        </div>
        <div className="manage-member-table-wrapper">
          <table>
            <thead>
              <th className="name">{i18next.t('이름')}</th>
              <th className="email">{i18next.t('메일 주소')}</th>
              <th className="phone">{i18next.t('핸드폰 번호')}</th>
            </thead>
            <tbody>
              {pendingUsers &&
                pendingUsers
                  .map((item) => <UserRow user={item} isPending />)
                  .concat(props.users.map((item) => <UserRow user={item} isPending={false} />))}
            </tbody>
          </table>
        </div>
      </div>

      {/* 팀원 추가 모달 */}
      <WrapperModal
        isOpen={inviteModal}
        close={() => {
          setInviteModal(false);
        }}
        position={{ width: '320px' }}
      >
        <ModalInviteMember
          companyId={props.companyId}
          close={() => {
            setInviteModal(false);
          }}
        />
      </WrapperModal>
    </div>
  );
};
