import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .logo-frame {
      width: 100%;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      background-color: #f9f9fa;

      &.view-port {
        width: 100vw;
      }

      & > .logo-area {
        background-color: #f9f9fa;
        width: 100%;
        margin-top: auto;

        .logo-section {
          min-width: 216px;
          max-width: 60px;
          width: 100vw;
          max-height: 341px;
          height: 100%;
          margin: 0 auto 40px;
          position: relative;

          img {
            width: 100%;
          }
        }
      }

      & > .wrapper {
        margin: 0 auto;
      }

      .copyright {
        width: 100%;
        ${theme.text.disclaimer};
        text-align: center;
        margin-bottom: 32px;
        margin-top: auto;
      }
    }
  `;
};
