import React, { useMemo, useState, useEffect } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { BDSButton } from '@bigin/bigin-ui-components';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import { range } from 'lodash';
import { setCategory } from '../../../recoil/Category';
import Products, { setProducts } from '../../../recoil/Products';
import StorageService from '../../../services/StorageService';
import { ProductApi } from '../../../lib/api/ProductApi';
import { MallApi } from '../../../lib/api/MallApi';
import { getUniqKey } from '../../../utils/array.utils';
import { BGPagination } from '../../../components/BGPagination/BGPagination';
import { ProductImageContainer } from '../ProductImageContainer/ProductImageContainer';
import { BGInput } from '../../../components/BGInput/BGInput';
import { baseCss } from './ImageManageView.style';

export interface ImageManageViewProps {
  templateType?: ViewType;
  selectType?: SelectType;
  type?: { type: 'creative' | 'create-campaign'; value: CampaignType | String; ratio: '1:1' | '1.91:1' | '2:1' };
  selectedImages?: ImageTypes[];
  isRatioSelectionMode?: boolean;
  setSelectedImages?: (images: ImageTypes[]) => void;
  modalClose?: () => void;
}

export const ImageManageView = ({
  templateType = 'page',
  selectType = 'none',
  type = { type: 'creative', value: '', ratio: '1:1' },
  selectedImages = [],
  // isRatioSelectionMode = false,
  setSelectedImages,
  modalClose,
}: ImageManageViewProps): JSX.Element => {
  const i18next = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedImageList, setSelectedImageList] = useState<ImageTypes[]>([]);
  const [defaultProductState, setDefaultProductState] = useRecoilState(Products.defaultProduct);
  const [productsState, setProductsState] = useRecoilState(setProducts);
  const [categoriesState, setCategoriesState] = useRecoilState(setCategory);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1);

  // Pagination state
  const [totalCount, setTotalCount] = useState<number>(0);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [activePage, setActivePage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(0);

  const handleSelectCategory = (id: number) => {
    setSelectedCategoryId(id);
    setActivePage(1);
  };
  const isSeletMode = useMemo(() => {
    return selectType !== 'none' && selectType !== 'detail';
  }, [selectType]);
  const prevQueryParams = useMemo(() => {
    const parsedQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    return {
      ...parsedQueryParams,
      activePage: parsedQueryParams?.activePage ? parseInt(parsedQueryParams.activePage as string, 10) : 1,
      countPerPage: parsedQueryParams?.countPerPage ? parseInt(parsedQueryParams.countPerPage as string, 10) : 10,
      searchText: parsedQueryParams?.searchText || '',
    };
  }, [location.search]);

  const fetchImages = async () => {
    const res = await ProductApi().getMallUploadImages(StorageService.selectedMall?.id ?? null);
    setDefaultProductState({
      id: -1,
      name: i18next.t('상품 정보 없는 이미지'),
      images: res.data.data as ProductImage[],
    } as Product);
    return res.data.data;
  };

  const fetchCategories = async () => {
    const res = await MallApi().getCategories(StorageService.selectedMall?.id ?? null);
    setCategoriesState(res.data.data);
    return res.data.data as Category[];
  };

  const query = useMemo(() => {
    const queryArray = [] as string[];

    if (searchValue.length > 0) {
      queryArray.push(`(name ct "${searchValue}")`);
    }
    if (selectedCategoryId !== -1 && selectedCategoryId !== -2) {
      queryArray.push(`((categories ct ${selectedCategoryId}))`);
    }

    return queryArray.length > 0 ? queryArray.join(' and ') : '';
  }, [selectedCategoryId, searchValue]);

  const { refetch: productsRefetch, isFetching: isProductsFetching } = useQuery('fetchProducts', async () => {
    const res = await ProductApi().getProducts(StorageService.selectedMall?.id ?? null, {
      query,
      page: activePage,
      per_page: countPerPage,
      includes: ['images', 'categories'],
    });
    setProductsState([...res.data.data]);
    setTotalCount(res.data.total);
    setLastPage(res.data.last_page);
    return res.data.data;
  });

  const updateQueryParams = (queryParams: object) => {
    const resultQueryParams = qs.stringify(queryParams);

    const url = `${location.pathname}?${resultQueryParams}`;
    navigate(url, { replace: true });
  };

  const deleteSelectImage = (image: ImageTypes) => {
    setSelectedImageList(selectedImageList.filter((selectedImage) => selectedImage.image_id !== image.image_id));
  };

  const handleClickImage = (image: ImageTypes, ratio: ImageRatioType) => {
    if (selectType === 'single') {
      setSelectedImageList([image]);
    } else if (selectType === 'multiple') {
      const isCurrentClickedImgWasSelected = selectedImageList.find(
        (selectedImage) => selectedImage?.image_id === image.image_id,
      );

      if (isCurrentClickedImgWasSelected) {
        deleteSelectImage(image);
      } else {
        setSelectedImageList([...selectedImageList, image]);
      }
    } else if (selectType === 'ratio') {
      if (ratio === '') {
        selectedImageList[2] = image; // *selectedImages[2]는 이미지 클릭 판별을 위한 용도
      } else {
        // eslint-disable-next-line no-unused-expressions
        ratio === '1:1' ? (selectedImageList[0] = image) : (selectedImageList[1] = image); // 1.91:1
        delete selectedImageList[2];
      }
    }
  };

  const imageRefetch = () => {
    productsRefetch();
    fetchImages();
  };

  useEffect(() => {
    fetchImages();
    fetchCategories();
    if (selectedImages.length > 0) {
      setSelectedImageList([...selectedImages]);
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (query.includes('name')) {
      timer = setTimeout(() => {
        productsRefetch();
      }, 500);
    } else {
      productsRefetch();
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [query, activePage]);

  const renderSkeleton = () => (
    <div style={{ width: '100%', display: 'flex' }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        {range(3).map((index) => (
          <div
            key={getUniqKey('skeleton-wrapper', index)}
            style={{
              width: '100%',
              height: '100%',
              display: 'inline-block',
            }}
          >
            <div style={{ width: '100%', height: '44px' }} />
            <div style={{ display: 'flex', gap: '8px', padding: '20px', borderBottom: 'solid 1px #dce0e8' }}>
              {range(7).map((boxIndex) => (
                <Skeleton
                  key={getUniqKey('skeleton-image-box', boxIndex)}
                  className="skeleton"
                  style={{ width: '88px', height: '88px' }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div css={[baseCss]}>
        <div className="search-wrapper">
          <BGInput
            inputProps={{
              value: searchValue,
              placeholder: i18next.t('검색'),
              onChange: (event) => {
                setSearchValue(event.target.value);
              },
            }}
          />
          <div className="category-menu-wrapper">
            <button
              key={-1}
              type="button"
              className={`category-menu-button ${selectedCategoryId === -1 ? 'active' : ''}`}
              onClick={() => handleSelectCategory(-1)}
            >
              <span>{i18next.t('모든 상품')}</span>
            </button>
            {categoriesState &&
              categoriesState.map((item) => (
                <button
                  key={item.id}
                  type="button"
                  className={`category-menu-button ${selectedCategoryId === item.id ? 'active' : ''}`}
                  onClick={() => handleSelectCategory(item.id)}
                >
                  <span>{item.name}</span>
                </button>
              ))}
            <button
              key={-2}
              type="button"
              className={`category-menu-button ${selectedCategoryId === -2 ? 'active' : ''}`}
              onClick={() => handleSelectCategory(-2)}
            >
              <span>{i18next.t('카테고리 정보 없음')}</span>
            </button>
          </div>
        </div>
        <div className="vertical-line" />
        <div className="item-wrapper">
          <div className="title-container">
            <div className="title-wrapper flex-row">
              <div className="title-wrapper-text text-h2 text-color-main">
                {selectedCategoryId === -1 && i18next.t('모든 상품')}
                {selectedCategoryId === -2 && i18next.t('카테고리 정보 없음')}
                {selectedCategoryId !== -1 &&
                  selectedCategoryId !== -2 &&
                  categoriesState &&
                  categoriesState.find((category: Category) => category.id === selectedCategoryId)?.name}
              </div>
              <div className="title-item-wrapper">
                {productsState.length > 0 && (
                  <BGPagination
                    isLoaded
                    curPageItemLength={10}
                    totalCount={totalCount}
                    countPerPage={countPerPage}
                    activePage={activePage}
                    isHideCountSelector
                    handleCountPerPage={(curCountPerPage) => {
                      setActivePage(1);
                      setCountPerPage(curCountPerPage);

                      // set Query Parameter
                      updateQueryParams({
                        ...prevQueryParams,
                        activePage: 1,
                        countPerPage: curCountPerPage,
                      });
                    }}
                    handleActivePage={(curActivePage) => {
                      setActivePage(curActivePage);

                      // set Query Parameter
                      updateQueryParams({
                        ...prevQueryParams,
                        activePage: curActivePage,
                      });
                    }}
                    selectBoxPosition="top"
                  />
                )}
              </div>
            </div>
          </div>
          {isProductsFetching && renderSkeleton()}
          {!isProductsFetching && (
            <div className={`image-container ${templateType === 'dialog' && 'dialog'}`}>
              {productsState.length <= 0 && selectedCategoryId !== -1 && selectedCategoryId !== -2 && (
                <div className="empty-wrapper text-h4 text-color-sub">
                  {i18next.t('등록된 상품이 없어 이미지를 불러올 수 없습니다.')}
                </div>
              )}
              {selectedCategoryId !== -2 &&
                productsState &&
                productsState.map((product) => (
                  <ProductImageContainer
                    key={product.id}
                    product={product}
                    selectMode={isSeletMode}
                    type={type}
                    isRatioSelectionMode={false}
                    selectedImageIds={selectedImageList.map((image) => image.image_id)}
                    clickImage={handleClickImage}
                    imageRefetch={imageRefetch}
                  />
                ))}
              {((selectedCategoryId === -1 && lastPage === activePage) || selectedCategoryId === -2) && (
                <ProductImageContainer
                  product={defaultProductState as Product}
                  selectMode={selectType !== 'none' && selectType !== 'detail'}
                  type={type}
                  isRatioSelectionMode={false}
                  isLast
                  selectedImageIds={selectedImageList.map((image) => image.image_id)}
                  clickImage={handleClickImage}
                  imageRefetch={imageRefetch}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {isSeletMode && setSelectedImages && (
        <div
          style={{
            width: '100%',
            height: '76px',
            padding: '20px',
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: 'solid 1px #ededf2',
          }}
        >
          <BDSButton appearance="secondary" style={{ marginRight: '12px' }} onClick={modalClose}>
            {i18next.t('취소')}
          </BDSButton>
          <BDSButton isDisabled={selectedImageList.length <= 0} onClick={() => setSelectedImages(selectedImageList)}>
            {i18next.t('등록')}
          </BDSButton>
        </div>
      )}
    </React.Fragment>
  );
};
