import { css } from '@emotion/react';

export const containerCss = css`
  * {
    box-sizing: border-box;
  }

  & {
    width: 100%;
    min-width: fit-content;
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 28px;
  }

  .horizontal-line {
    width: calc(100% - 24px);
    height: 1px;
    background-color: #dce0e8;
    margin: 0 12px;
  }

  .account-info-container {
    display: flex;
  }
`;
