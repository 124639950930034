import { css, useTheme, Theme } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;

    .detail-info-container {
      width: 100%;
      display: flex;
      gap: 20px;
    }
    .setting-info-container {
      overflow: hidden;
      .table-wrapper {
        width: 100%;
        margin-top: 7px;

        .row {
          display: flex;
          justify-content: space-between;
          padding-bottom: 16px;

          &.short {
            padding-bottom: 8px;
          }

          &:last-child {
            padding-bottom: 4px;
          }
        }
        .option-row {
          border-bottom: 1px solid #dce0e8;
          display: flex;
          justify-content: flex-end;
          padding-bottom: 16px;
        }

        .row + .row {
          padding-top: 16px;
          border-top: 1px solid #dce0e8;

          &.short {
            padding-top: 8px;
          }
        }
      }
      .status-toggle-container {
        padding: 20px;
        border-top: 1px solid #dce0e8;
        background-color: #edf1f6;
      }
    }

    .contents-container {
      box-sizing: border-box;
      width: 100%;
      gap: 16px;
      padding: 0 ${GS.gutter}px ${GS.gutter}px;
    }

    .campaign-detail-content-wrapper {
      margin-bottom: 20px;
      box-sizing: border-box;
      width: 100%;
      height: 80px;
      border: solid 1px #dce0e8;
      border-radius: 4px;
    }
    .campaign-event-selector-list {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .campaign-event-selector {
      position: relative;
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
      padding: 0 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      border-right: 1px solid #ededf2;

      &:hover {
        cursor: pointer;
      }

      &.inactive {
        background-color: #f9fafb;
        & > * {
          opacity: 0.5;
        }
      }

      &:last-of-type {
        border-right: none;
      }

      .font-icon-panel {
        margin-right: 8px;
      }
      .campaign-event-title-wrapper {
        display: flex;
        width: auto;
        height: auto;
        .campaign-event-circle-check {
          height: 8px;
          width: 8px;
          border-radius: 10px;
          margin-top: 3px;
          margin-right: 8px;
        }
      }
    }

    .title-container {
      width: 100%;
      max-height: 88px;
      border-bottom: solid 1px #ededf2;

      .title-wrapper {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        background-color: #ffffff;
        padding: 20px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .title-wrapper-text {
          width: fit-content;
          white-space: nowrap;
          color: #3d4046;
          ${theme.text.h2}
        }

        .edit-button {
          background: transparent;
          width: 20px;
          height: 20px;
          padding: 0;
          margin: 0;
          cursor: pointer;
        }

        .title-item-wrapper {
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }
    }
  `;
};

export const editCampaignSettingCss = () => {
  const theme: Theme = useTheme();

  return css`
    .title-wrapper {
      .title-wrapper-text {
        width: fit-content;
        white-space: nowrap;
        color: #3d4046;
        ${theme.text.h2}
      }
    }
    .contents-wrapper {
      margin-top: 20px;
    }
    .footer-wrapper {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 20px;
    }
  `;
};

export const previewModalCss = () => {
  const theme: Theme = useTheme();

  return css`
    .preview-modal-header {
      display: flex;
      justify-content: space-between;

      .close-button {
        border: none;
        background: transparent;
        cursor: pointer;
      }
    }
    .preview-modal-contents {
      margin-top: 8px;
      .material-facebook-preview-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: solid 1px #dce0e8;
        background-color: #f9fafb;

        .material-preview {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          min-height: 588px;
          height: 100%;
          display: grid;
          grid-template-columns: minmax(auto, 50%) minmax(auto, 50%);

          .preview-media-title-wrapper {
            padding-bottom: 16px;
            ${theme.text.h4};
            color: ${theme.colors.mainFont};
          }

          .facebook-title-box {
            padding: 20px 20px 8px 16px;
            display: flex;
            gap: 8px;

            &.instagram {
              padding-bottom: 16px;
            }

            .logo {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              border: solid 1px #edf1f6;
              overflow: hidden;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
              }
            }
          }

          .facebook-wrapper {
            padding: 20px;
          }
          .instagram-wrapper {
            border-left: 1px solid ${theme.colors.divider};
            padding: 20px;
          }
        }
      }
      .material-youtube-preview-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: solid 1px #dce0e8;
        background-color: #f9fafb;
        margin-bottom: 60px;

        .material-preview-top-bar {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 6px;
          width: 100%;
          padding: 8px;
          border-bottom: solid 1px #dce0e8;
          background-color: #f9fafb;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          .top-bar-oval {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #dce0e8;
          }
        }

        .material-preview {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          min-height: 588px;
          height: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;

          .preview-media-title-wrapper {
            padding-bottom: 16px;
            ${theme.text.h4};
            color: ${theme.colors.mainFont};
          }

          .left-wrapper {
            padding: 20px;
          }
          .right-wrapper {
            border-left: 1px solid ${theme.colors.divider};
            padding: 20px;
          }
        }
      }

      .material-google-pmax-preview-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: solid 1px #dce0e8;
        background-color: #f9fafb;

        .material-preview {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          min-height: 1000px;
          height: 100%;
        }
      }
      .material-google-preview-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: solid 1px #dce0e8;
        background-color: #f9fafb;

        .material-preview-top-bar {
          box-sizing: border-box;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 6px;
          padding: 8px;
          border-bottom: solid 1px #dce0e8;
          background-color: #f9fafb;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;

          .top-bar-oval {
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background-color: #dce0e8;
          }
        }

        .material-preview {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          min-height: 588px;
          height: 100%;

          .google-preview {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #f9fafb;
            min-height: 700px;
            position: relative;
          }

          .preview-media-title-wrapper {
            padding-bottom: 16px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .title {
              ${theme.text.h4}
              color: ${theme.colors.mainFont};
            }
            .preview-type-selector-wrap {
              display: flex;
              flex-direction: row;
              gap: 8px;
              align-items: center;

              button {
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                cursor: pointer;
                width: 28px;
                height: 28px;
                border-radius: 8px;
                border: solid 1px ${theme.colors.divider};
              }

              .preview-type {
                ${theme.text.disclaimer}
              }

              .pre-button {
                &:hover {
                  background-color: ${theme.colors.bg3};
                }
              }
              .next-button {
                &:hover {
                  background-color: ${theme.colors.bg3};
                }
              }
            }
          }

          .google-wrapper {
            padding: 20px;
          }
        }
      }

      .material-kakao-preview-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        border: solid 1px #dce0e8;
        background-color: #f9fafb;

        .material-preview {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          min-height: 700px;
          height: 100%;

          .kakao-wrapper {
            padding: 20px;
          }
        }
      }
    }
  `;
};
