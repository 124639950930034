import i18n from '../i18n';

export const enum GoogleField {
  AW_LONG_TITLE = 'aw_long_title',
  AW_SHORT_TITLE = 'aw_short_title',
  AW_DESCRIPTION = 'aw_description',
  AW_SHORT_DESCRIPTION = 'aw_short_description',
  AW_SHORT_TITLE1 = 'aw_short_title1',
  AW_SHORT_TITLE2 = 'aw_short_title2',
  AW_SHORT_TITLE3 = 'aw_short_title3',
}

export const enum StartOption {
  ENDLESS = 'endless',
  PERIOD = 'period',
}

export const enum TargetExclude {
  PURCHASE_30DAYS = 'purchase_30days',
  PURCHASE_180DAYS = 'purchase_180days',
  PAGE_VIEW_180DAYS = 'page_view_180days',
}

export const enum RevisitOptimize {
  VIEW_CONTENT_OR_INTEREST = 'view_content_or_interest',
  PURCHASE = 'purchase',
}

export const facebookCTA: { label: string; value: FacebookCTA }[] = [
  { label: i18n.t('지금 쇼핑하기'), value: 'shop_now' },
  { label: i18n.t('지금 신청하기'), value: 'apply_now' },
  { label: i18n.t('지금 예약하기'), value: 'book_travel' },
  { label: i18n.t('더 알아보기'), value: 'learn_more' },
  { label: i18n.t('가입하기'), value: 'sign_up' },
];

export const googleCTA: { label: string; value: GoogleCTA }[] = [
  { label: i18n.t('구매하기'), value: 'shop_now' },
  { label: i18n.t('자세히 알아보기'), value: 'learn_more' },
  { label: i18n.t('사이트 방문하기'), value: 'visit_site' },
  { label: i18n.t('예약하기'), value: 'book_now' },
  { label: i18n.t('가입하기'), value: 'sign_up' },
  { label: i18n.t('더보기'), value: 'see_more' },
];
