import axios, { AxiosPromise } from 'axios';
import qs from 'qs';

export type ProductIncludeType = 'tags' | 'analytics' | 'recent3monthAnalytics' | 'images' | 'categories';

export const ProductApi = () => ({
  getProducts(
    mallId: string | number,
    params?: {
      query?: string;
      per_page?: number;
      page?: number;
      analytics_to?: string;
      analytics_from?: string;
      includes?: ProductIncludeType[];
    },
  ): AxiosPromise<{
    current_page: number;
    data: Product[];
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
  }> {
    return axios.get(
      `malls/${mallId}/products/search?${qs.stringify(params, {
        arrayFormat: 'brackets',
      })}`,
    );
  },
  postProductImage(
    mallId: number,
    productId: string | number,
    type: string,
    image: Blob,
    originalId?: number,
    template?: string,
  ): AxiosPromise<{ data: ProductImage }> {
    const fromedImage = new FormData();
    fromedImage.append('type', type);
    fromedImage.append('image', image);
    if (template) fromedImage.append('square_template', template);
    if (originalId) fromedImage.append('original_id', `${originalId}`);
    // if (squareTemplate) fromedImage.append('square_template', squareTemplate);
    // if (bannerTemplate) fromedImage.append('banner_template', bannerTemplate);
    // if (banner) {
    //   fromedImage.append('banner', banner);
    // }
    return axios.post(`/malls/${mallId}/products/${productId}/images`, fromedImage);
  },
  updateDefaultImage(mallId: number, productId: number, imageId: number): AxiosPromise<{ data: ProductImage }> {
    return axios.put(`/malls/${mallId}/products/${productId}/images/default?id=${imageId}`);
  },
  getMallUploadImages(mallId: number): AxiosPromise<{ data: ProductImage[] }> {
    return axios.get(`/malls/${mallId}/images`);
  },
  getMallUploadImage(mallId: number, imageId: number): AxiosPromise<{ data: ProductImage }> {
    return axios.get(`/malls/${mallId}/images/${imageId}`);
  },
  updateMallUploadImage(
    mallId: number,
    image: File | Blob,
    originalId?: number,
    template?: string,
  ): AxiosPromise<{ data: ProductImage }> {
    const fromedImage = new FormData();
    fromedImage.append('image', image);
    if (originalId) fromedImage.append('original_id', `${originalId}`);
    if (template) fromedImage.append('square_template', template);
    // if (squareTemplate) fromedImage.append('square_template', squareTemplate);
    // if (bannerImage) {
    //   fromedImage.append('banner', bannerImage);
    //   if (bannerTemplate) fromedImage.append('banner_template', bannerTemplate);
    // }
    return axios.post(`/malls/${mallId}/images`, fromedImage);
  },
  getVideos(mallId: number): AxiosPromise<{ data: Video[] }> {
    const include = { include: ['video.thumbnails'] };
    const params = qs.stringify(include, { arrayFormat: 'brackets' });
    return axios.get(`malls/${mallId}/videos?${params}`);
  },
  postVideo(video: File, mallId: number): AxiosPromise<{ data: Video }> {
    const fromedImage = new FormData();
    fromedImage.append('video', video);
    return axios.post(`malls/${mallId}/videos`, fromedImage);
  },
  deleteVideo(videoId: number, mallId: number): AxiosPromise<{ data: Video }> {
    return axios.delete(`malls/${mallId}/videos/${videoId}`);
  },
  getProductImage(mallId: number, productId: number, imageId: number): AxiosPromise<{ data: ProductImage }> {
    return axios.get(`malls/${mallId}/products/${productId}/images/${imageId}`);
  },
  deleteImage(mallId: number, productId: number, imageId: number): AxiosPromise<{ data: ProductImage }> {
    return axios.delete(`/malls/${mallId}/products/${productId}/images/${imageId}`);
  },
  deleteMallUploadImage(mallId: number, imageId: number): AxiosPromise {
    return axios.delete(`/malls/${mallId}/images/${imageId}`);
  },
});
