import { selector } from 'recoil';
import atom from './atom';

const setVideos = selector<Video[]>({
  key: 'setVideos',
  get: ({ get }) => get(atom.videos),
  set: ({ set }, video) => {
    return set(atom.videos, () => {
      return video;
    });
  },
});

export { setVideos };
