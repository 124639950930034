import { makeAutoObservable } from 'mobx';
import _ from 'lodash';

type Button = {
  label: string;
  handleClick?: () => void;
  appearance?: string;
};

class DialogStore {
  title = '';

  message = '';

  type = 'message';

  buttons: Array<Button> = [];

  isShow = false;

  confirmResolve: any = null;

  confirmReject: any = null;

  cancelReject: any = null;

  children?: JSX.Element | JSX.Element[];

  option?: {
    disableClose: false;
    isDialogConfirm?: false;
  };

  showConfirm = (title: string, message: string): Promise<any> => {
    this.title = title;
    this.message = message;
    this.isShow = true;
    this.type = 'confirm';

    const confirmPromise = new Promise((resolve, reject) => {
      this.confirmResolve = resolve;
      this.cancelReject = reject;
    });

    return confirmPromise;
  };

  showMessage = (title: string, message: string) => {
    this.title = title;
    this.type = 'message';
    this.message = message;
    this.isShow = true;

    const confirmPromise = new Promise((resolve, reject) => {
      this.confirmResolve = resolve;
      this.confirmReject = reject;
    });

    return confirmPromise;
  };

  showDialog = (title: string, message: string, buttons: Array<Button>, option?: any) => {
    this.title = title;
    this.message = message;
    this.buttons = buttons;
    this.isShow = true;
    this.type = 'dialog';

    if (option) {
      this.option = _.assign(this.option, option);
    }
  };

  open = (childComponent: JSX.Element | JSX.Element[], option?: any) => {
    this.type = 'component';
    this.children = childComponent;
    this.isShow = true;

    if (option) {
      this.option = _.assign(this.option, option);
    }
  };

  hideDialog = () => {
    this.isShow = false;
    if (this.option?.isDialogConfirm) this.option.isDialogConfirm = false;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default DialogStore;
