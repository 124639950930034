import { css } from '@emotion/react';

export const LineChartStyle = css`
  width: 100%;
  .header-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;

    .chart-shape-btns {
      display: flex;
      margin-right: 20px;
    }

    .chart-type-btns {
      display: flex;
      top: 16px;
      justify-content: center;
    }
  }

  .chart-wrapper {
    background: #ffffff;
    border-radius: 8px;

    .area_hidden {
      visibility: hidden;
    }

    .line {
      stroke-width: 1.5;
      fill: none;
    }

    .line_dashed {
      stroke-dasharray: 3, 3;
    }

    .area {
      fill-opacity: 0.1;
    }

    .axis_x {
      text {
        text-anchor: start;
      }
    }

    .selectedLine {
      stroke-dasharray: 1, 1;
    }

    .point {
      stroke-width: 1;
    }
    .selectedLine {
      stroke-opacity: 1 !important;
    }

    svg {
      display: block;
    }

    .axis_x {
      path,
      line {
        display: none;
      }

      text {
        fill-opacity: 0.5;
      }
    }

    .axis {
      line {
        display: none;
      }
    }

    .axis_y,
    .axis_y2 {
      path {
        display: none;
      }

      .tick text {
        font-weight: 700;
        fill-opacity: 0.5;
      }
    }

    .grid {
      line {
        stroke-width: 1;
        stroke-opacity: 0.1;
      }

      path,
      text {
        display: none;
      }
    }
  }
`;
