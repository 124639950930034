import { atom } from 'recoil';
import { i18nextScanKey } from '../../i18n';

const products = atom<Product[]>({
  key: 'products',
  default: [],
});

const selectParentCategory = atom<Category>({
  key: 'selectParentCategory',
  default: {} as Category,
});
const selectCategoryId = atom<number>({
  key: 'selectCategoryId',
  default: -1,
});

const defaultProduct = atom<Product>({
  key: 'defaultProduct',
  default: {
    id: -1,
    name: i18nextScanKey('상품 정보 없는 이미지'),
    images: [] as ProductImage[],
  } as Product,
});

export default {
  products,
  selectParentCategory,
  selectCategoryId,
  defaultProduct,
};
