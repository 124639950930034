import { css } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = () => {
  return css`
    width: 100%;
    min-width: fit-content;

    * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: ${GS.gutter}px;
    }

    .add-campaign-container {
      font-stretch: normal;
      font-style: normal;
      width: 100%;
      display: flex;
      position: relative;

      .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }
  `;
};

export const leftWrapper = css`
  width: 100%;
  min-width: ${GS.contentMinWidth}px;
  max-width: ${GS.contentMaxWidth}px;
`;
export const rightWrapper = css`
  width: 260px;
  margin-left: 20px;
  position: relative;
`;

export const addCampaignModalCss = css`
  .add-campaign-header {
    margin-bottom: 4px;

    .title {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #3d4046;
    }
  }
  .add-campaign-body {
    margin-bottom: 24px;

    .content {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #3d4046;

      margin-bottom: 8px;
    }
    .info {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #626871;
    }
    .field-title {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #53585f;
    }
  }
  .add-campaign-footer {
    //
  }
`;
