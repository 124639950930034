import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .fb-asset-modal-header {
      margin-bottom: 20px;

      & > *:not(:last-child) {
        margin-bottom: 8px;
      }

      .desc {
        ${theme.text.input};
        color: ${theme.colors.mainFont};
      }
    }
    .fb-asset-modal-body {
      margin-bottom: 20px;

      .form-input-group {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        & > *:not(:last-child) {
          margin-bottom: 8px;
        }

        .form-input-label {
          ${theme.text.h4};
          color: ${theme.colors.mainFont};
        }

        .desc {
          ${theme.text.disclaimer}
        }
      }

      // BGSelectBox가 disabled처리 되어도 UI상 변경 없도록 하는 꼼수
      .select-wrapper {
        .gray.disabled .material-dropdown-selected-item-wrapper {
          border: solid 1px #dce0e8 !important;
          background-color: #f9fafb !important;
        }
        .gray.disabled .material-dropdown-item.gray {
          background-color: #f9fafb !important;
          opacity: initial !important;
        }
      }
    }
    .fb-asset-modal-footer {
      display: flex;
      justify-content: space-between;

      .logout-container {
        ${theme.text.disclaimer}

        .logout {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  `;
};
