import React, { ChangeEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { BDSButton, BDSFontIcon, BDSToggleButton, DateRange } from '@bigin/bigin-ui-components';
import moment from 'moment';
import qs from 'qs';
import { orderBy } from 'lodash';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useRecoilState, useRecoilValue } from 'recoil';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { BorderSection } from '../../components/BorderSection/BorderSection';
import { baseCss, editCampaignSettingCss, previewModalCss } from './CampaignReport.style';
import { CampaignApi } from '../../lib/api/CampaignApi';
import StorageService from '../../services/StorageService';
import { useDataStore } from '../../context/Store';
import { CampaignSortData } from '../CampaignDashboard/CampaignDashboard';
import { BaseQueryParameter } from '../../object-model/model';
import { BGActivatedFilter } from '../../components/BGListFilterV2/BGListFilterV2';
import { dataFormatting } from '../../utils/dataFormatting.util';
import { CampaignStats } from '../CampaignDashboard/CampaignItem/CampaignStats';
import { getInsightType, getUnitText } from '../../utils/insight';
import { CampaignAdsTable, CampaignAdsTableHeader } from '../../components/CampaignAdsTable/CampaignAdsTable';
import { AdStats } from './ReportItem/AdStats';
// eslint-disable-next-line import/namespace
import { AdTitle } from './ReportItem/AdTitle';
import { CampaignChart, ChartConfig, ChartField } from '../../components/CampaignChart/CampaignChart';
import { CampaignMetrics, CampaignMetricType } from './CampiagnReportDef';
import { Calendar } from '../../components/Calendar/Calendar';
import { MaterialDropDown } from '../../components/PositionSelector/PositionSelector';
import { i18nextScanKey } from '../../i18n';
import { AdTargetTitle } from './ReportItem/AdTargetTitle';
import { WrapperModal } from '../../components/WrapperModal/WrapperModal';
import { BGInput } from '../../components/BGInput/BGInput';
import { filters } from '../../utils/filter';
import { PreviewFacebookItem } from '../CreateCampaign/Step2AssetSetting/PreviewFacebookItem/PreviewFacebookItem';
import { PreviewGoogleItem } from '../CreateCampaign/Step2AssetSetting/PreviewGoogleItem/PreviewGoogleItem';
import { PreviewKakaoItem } from '../CreateCampaign/Step2AssetSetting/PreviewKakaoItem/PreviewKakaoItem';
import { ProductApi } from '../../lib/api/ProductApi';
import { goalTypeText, involvementTypeText, revisitOptimizeTypeText, targetExcludeTypeText } from '../../utils/text';
import { getUniqKey } from '../../utils/array.utils';
import { PreviewYoutubeVideo } from '../CreateCampaign/Step2AssetSetting/PreviewYoutubeVideo/PreviewYoutubeVideo';
import { AllColumnType } from '../../types/BGEventDef';
import CampaignList from '../../recoil/CampaignList';
import { BGPage } from '../../components/BGPage/BGPage';
import { BGLoadingSpinner } from '../../components/BGLoadingSpinner/BGLoadingSpinner';
import { MallApi } from '../../lib/api/MallApi';
import { initGridColumn } from '../CampaignDashboard/CampaignDashboardDef';
import { DialogConfirm } from '../../components/DialogConfirm/DialogConfirm';
import { InputError } from '../../components/InputError/InputError';
import { needBiginSdkCheck } from '../../utils/utils';
import { PaymentApi } from '../../lib/api/PaymentApi';
import { CurrencyCodeRecoil, showBudgetValueRecoil } from '../../recoil/Currency';
import { multilingual } from '../../utils/multilingual.util';

export interface CampaignQueryParams extends BaseQueryParameter {
  id?: number;
  sortData?: {
    key: string;
    direction: string;
  };
  filter?: BGActivatedFilter;
}

export interface Selector {
  label: string;
  value: string;
  disabled?: boolean;
}

export const CampaignReport = (): ReactElement => {
  const i18next = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { dialogStore } = useDataStore();
  const [showModalPreview, setShowModalPreview] = useState<boolean>(false);
  const [date, setDate] = useState<DateRange>();
  const { toastStore } = useDataStore();

  const queryClient = useQueryClient();
  const selectableDateRange = useMemo(() => {
    if (date && date.start && date.end && moment.isMoment(date.start) && moment.isMoment(date.end)) {
      return {
        start: date.start.startOf('day'),
        end: date.end.endOf('day'),
      };
    }
    return {
      start: moment().subtract(180, 'day').startOf('day'),
      end: moment().endOf('day'),
    };
  }, []);

  const prevQueryParams = useMemo(() => {
    const parsedQueryParams: CampaignQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    return {
      ...parsedQueryParams,
      id: parsedQueryParams?.id as number,
      sortData: parsedQueryParams?.sortData
        ? ({
            key: parsedQueryParams.sortData.key as string,
            direction: parseInt(parsedQueryParams.sortData.direction as string, 10),
          } as CampaignSortData)
        : null,
    };
  }, [location.search]);

  const initSelectedDateRange = useMemo(() => {
    const queryParams = qs.stringify({
      ...prevQueryParams,
    });
    const urlSearchParams = new URLSearchParams(queryParams);
    const params = Object.fromEntries(urlSearchParams.entries());
    const savedDate = StorageService.saveDateRange;

    if (savedDate) {
      const diff = moment(savedDate.end).diff(moment(savedDate.start), 'days');

      return {
        start: moment(savedDate.start).startOf('day'),
        end: moment(savedDate.end).endOf('day'),
        compareStart: moment(savedDate.start)
          .subtract(diff + 1, 'day')
          .startOf('day'),
        compareEnd: moment(savedDate.start).subtract(1, 'day').endOf('day'),
      };
    }

    if (params.startAt && params.endAt) {
      const startDate = moment(parseInt(params.startAt, 10));
      const endDate = moment(parseInt(params.endAt, 10));

      const diff = endDate.diff(startDate, 'days');

      const paramsDate = {
        start: startDate,
        end: endDate,
        compareStart: startDate
          .clone()
          .subtract(diff + 1, 'day')
          .startOf('day'),
        compareEnd: startDate.clone().subtract(1, 'day').endOf('day'),
      };
      StorageService.saveDateRange = paramsDate;

      return paramsDate;
    }

    const initDate = {
      start: moment().subtract(7, 'day').startOf('day'),
      end: moment().subtract(1, 'day').endOf('day'),
      compareStart: moment().subtract(14, 'day').startOf('day'),
      compareEnd: moment().subtract(8, 'day').endOf('day'),
    };
    StorageService.saveDateRange = initDate;
    return initDate;
  }, []);

  const { data: gtmData } = useQuery<GtmData>(
    'gtmData',
    async () => (await MallApi().getGtmCode(StorageService.selectedMall.id)).data.data,
    { refetchOnMount: 'always' },
  );

  const { data: autoCharge } = useQuery<boolean | undefined>(
    'autoCharge',
    async () => (await PaymentApi().getAutoCharge(StorageService.selectedMall.id)).data.data,
  );

  const needBiginSdk = useMemo(() => {
    if (StorageService.activePlan && gtmData) {
      return needBiginSdkCheck(StorageService.activePlan, gtmData);
    }
    return true;
  }, [StorageService.activePlan, gtmData]);

  const filterSelector: Selector[] = [
    { label: i18nextScanKey('모든 소재'), value: 'CAMPAIGN_STATUS_ALL' },
    { label: i18nextScanKey('라이브 중인 소재'), value: 'CAMPAIGN_STATUS_LIVE' },
    { label: i18nextScanKey('심사 중인 소재'), value: 'CAMPAIGN_STATUS_PENDING' },
    { label: i18nextScanKey('심사 거절된 소재'), value: 'CAMPAIGN_STATUS_REJECTED' },
    { label: i18nextScanKey('비활성된 소재'), value: 'CAMPAIGN_STATUS_NON_ACTIVE' },
    { label: i18nextScanKey('종료된 소재'), value: 'CAMPAIGN_STATUS_END' },
  ];

  const insightSelector = (): Selector[] => {
    const defaultList = [
      { label: i18nextScanKey('지출 금액'), value: 'spend' },
      { label: i18nextScanKey('노출 수'), value: 'impressions' },
      { label: i18nextScanKey('노출 빈도'), value: 'frequency' },
      { label: 'CPM', value: 'cpm' },
      { label: i18nextScanKey('클릭 수'), value: 'inline_link_clicks' },
      { label: 'CTR', value: 'inline_link_click_ctr' },
      { label: 'CPC', value: 'inline_link_click_cpc' },
    ];

    if (StorageService.activePlan?.type === 'smartstore') {
      return defaultList;
    }

    return [
      ...defaultList,
      {
        label: i18nextScanKey('구매 수'),
        value: `actions_${StorageService.attributionWindow}`,
        disabled: needBiginSdk,
      },
      {
        label: i18nextScanKey('구매 전환율'),
        value: `cvr_${StorageService.attributionWindow}`,
        disabled: needBiginSdk,
      },
      { label: 'CPA', value: `cpa_${StorageService.attributionWindow}`, disabled: needBiginSdk },
      {
        label: 'ROAS',
        value: `roas_${StorageService.attributionWindow}`,
        disabled: needBiginSdk,
      },
      {
        label: i18nextScanKey('구매 전환 값'),
        value: `action_values_${StorageService.attributionWindow}`,
        disabled: needBiginSdk,
      },
    ];
  };

  const [showModalEditCampaignSetting, setShowModalEditCampaignSetting] = useState<boolean>(false);
  const [showModalSDKInstall, setShowModalSDKInstall] = useRecoilState<boolean>(CampaignList.sdkModal);
  const [showBudgetValue, setShowBudgetValue] = useRecoilState(showBudgetValueRecoil);
  const [sortData, setSortData] = useState<CampaignSortData | null>(
    prevQueryParams.sortData ?? { key: '', direction: 1 },
  );
  const [selectedInsight, setSelectedInsight] = useState<Selector>(insightSelector()[0]);
  const [selectedFilter, setSelectedFilter] = useState<Selector>(filterSelector[0]);
  const [metricList, setMetricList] = useState<CampaignMetricType[]>(CampaignMetrics);
  const [timelineMetric, setTimelineMetric] = useState<ChartField[]>(CampaignMetrics);
  const [filteredChartData, setFilteredChartData] = useState();
  const [tempCampaign, setTempCampaign] = useState<any>();

  const [campaignName, setCampaignName] = useState<string>('');
  const [dailyBudget, setDailyBudget] = useState<number>(0);
  const { register, formState, setValue, handleSubmit, resetField } = useForm<{ name: string; budget: number }>({
    mode: 'all',
  });

  const [interval, setInterval] = useState<DateRange>({
    ...initSelectedDateRange,
    start: initSelectedDateRange.start.startOf('day'),
    end: initSelectedDateRange.end.endOf('day'),
  });
  const [categoryProducts, setCategoryProducts] = useState<Product[]>([] as Product[]);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const fetchCategoryProducts = async (categoryId: number) => {
    const query = `((categories ct ${categoryId}))`;
    const res = await ProductApi().getProducts(StorageService.selectedMall?.id ?? null, {
      query,
      page: 1,
      per_page: 3,
      includes: ['images'],
    });
    setCategoryProducts(res.data.data);
  };

  const chartDuration = useMemo(() => {
    return { start: interval.start, end: interval.end };
  }, [interval]);

  const updateInitialQueryParams = () => {
    let queryParams;
    if (interval && interval.start && interval.end) {
      queryParams = qs.stringify({
        ...prevQueryParams,
        startAt: interval.start.valueOf(),
        endAt: interval.end.valueOf(),
      });
    } else {
      queryParams = qs.stringify({
        ...prevQueryParams,
      });
    }

    const url = `${location.pathname}?${queryParams}`;
    setDate(interval);
    navigate(url, { replace: true });
  };

  const {
    data: campaign,
    refetch: campaignRefetch,
    isFetching: isCampaignFetching,
  } = useQuery('getUnifiedCampaign', async () => {
    const res = await CampaignApi().getUnifiedCampaign(
      StorageService.selectedMall.id,
      prevQueryParams.id,
      moment(interval.start).format('YYYY-MM-DD'),
      moment(interval.end).format('YYYY-MM-DD'),
      'created',
    );

    const decimalPlaceLength = multilingual.getDecimalPlaceLength({ currencyCode });

    setCampaignName(res.data.data.name);
    setDailyBudget(res.data.data.daily_budget_e6 / 1e6);
    setShowBudgetValue((res.data.data.daily_budget_e6 / 1e6).toFixed(decimalPlaceLength));

    return res;
  });

  const onCompleteFetchEvent = async (chartDataInsight: any[]) => {
    const clonedMetricList = [...metricList];

    if (!chartDataInsight) return;

    clonedMetricList.forEach((metric) => {
      let count = 0;
      chartDataInsight.forEach((data) => {
        const media = metric.prop as any;
        count += data[media][selectedInsight.value];
      });
      // eslint-disable-next-line no-param-reassign
      metric.isLoading = false;
      // eslint-disable-next-line no-param-reassign
      metric.count = count;
    });

    setMetricList(clonedMetricList);
  };

  const { data: chartData, refetch: chartDataRefetch } = useQuery(
    'getUnifiedCampaignChartData',
    async () => {
      return CampaignApi().getUnifiedCampaignChartData(
        StorageService.selectedMall.id,
        prevQueryParams.id,
        moment(interval.start).format('YYYY-MM-DD'),
        moment(interval.end).format('YYYY-MM-DD'),
      );
    },
    {
      onSuccess: async (res) => {
        const chartDataFormat = res.data.data.insights.reduce((acc: any, cur: any) => {
          acc.push({
            event: {
              totals: cur.totals[selectedInsight.value],
              facebook: cur.facebook[selectedInsight.value],
              google: cur.google[selectedInsight.value],
            },
            timestamp: cur.date,
          });

          return acc;
        }, [] as { event: { totals: number; facebook: number; google: number }; timestamp: string }[]);

        await onCompleteFetchEvent(res.data.data.insights);
        setFilteredChartData(chartDataFormat);
      },
    },
  );

  useEffect(() => {
    const savedDate = StorageService.saveDateRange;

    if (savedDate) {
      setDate(savedDate);
    }
  }, []);

  useEffect(() => {
    updateInitialQueryParams();
    chartDataRefetch();
    campaignRefetch();
  }, [interval]);

  useEffect(() => {
    updateInitialQueryParams();
  }, [sortData]);

  const minBudget = useMemo(() => {
    const generalTypeMinimumBudget = multilingual.getMinimumBudgetByCurrency({
      currency: currencyCode,
    });
    if (campaign && campaign.data.data) {
      return campaign.data.data.uni_ads_count * generalTypeMinimumBudget;
    }
    return generalTypeMinimumBudget;
  }, [campaign]);

  useEffect(() => {
    register('name', { required: false });
    register('budget', {
      required: false,
      min: {
        value: minBudget,
        message:
          (i18next.t('최소 일 예산은 {{minBudget}} 입니다.', {
            minBudget: filters.formatCurrency({ value: minBudget, currencyCode }),
          }) as string) || '',
      },
    });
  }, [campaign, campaignName]);

  const settingValuesKey = ['name', 'goal', 'gender', 'age', 'budget', 'date'];

  function convertGoal(
    goal: CampaignGoal,
    revisitOptimize: RevisitOptimizeType,
    targetExclude: TargetExcludeType,
    involvements: InvolvementType[],
  ) {
    let goalOption = '';
    const goalText = i18next.t(goalTypeText[`${goal}`]);
    if (goal === 'buying_acquisition') {
      goalOption = targetExclude
        ? i18next.t(targetExcludeTypeText[`${targetExclude}`])
        : i18next.t(targetExcludeTypeText.not_exclude);
    }
    if (goal === 'revisit_leads') {
      goalOption = i18next.t(revisitOptimizeTypeText[`${revisitOptimize}`]);
    }
    if (goal === 'purchase_leads') {
      goalOption = involvements.map((involvement) => i18next.t(involvementTypeText[`${involvement}`])).join(', ');
    }

    return `${goalText}${goalOption !== '' ? ` (${goalOption})` : ''} `;
  }

  const settingValues = useMemo(() => {
    if (!campaign)
      return {
        name: {
          title: i18next.t('제목'),
          value: '',
        },
        goal: {
          title: i18next.t('목표'),
          value: '',
        },
        gender: {
          title: i18next.t('타겟 성별'),
          value: '',
        },
        age: {
          title: i18next.t('타겟 연령대'),
          value: '',
        },
        budget: {
          title: i18next.t('일예산'),
          value: '',
        },
        date: {
          title: i18next.t('기간'),
          value: '',
        },
      };

    let gender = '';
    if (campaign.data.data.genders.length >= 2) {
      gender = i18next.t('모든 성별');
    } else {
      gender = campaign.data.data.genders[0] === 'men' ? i18next.t('남성') : i18next.t('여성');
    }

    const value = {
      name: {
        title: i18next.t('제목'),
        value: campaign.data.data.name,
      },
      goal: {
        title: i18next.t('목표'),
        value: convertGoal(
          campaign.data.data.goals[0],
          campaign.data.data.revisit_optimize,
          campaign.data.data.target_exclude,
          campaign.data.data.involvements,
        ),
      },
      gender: {
        title: i18next.t('타겟 성별'),
        value: gender,
      },
      age: {
        title: i18next.t('타겟 연령대'),
        value: i18next.t('{{ageMin}}세 ~ {{ageMax}}세', {
          ageMin: campaign.data.data.age_min,
          ageMax: campaign.data.data.age_max,
        }),
      },
      budget: {
        title: i18next.t('일 예산'),
        value: filters.formatCurrency({ value: campaign.data.data.daily_budget_e6 / 1e6, currencyCode }),
      },
      date: {
        title: i18next.t('기간'),
        value: `${campaign.data.data.start_date}~${
          campaign.data.data.end_date ? campaign.data.data.end_date : i18next.t('종료일 없음')
        }`,
      },
    } as any;

    return value;
  }, [campaign]);

  const updateQueryParams = (queryParams: object) => {
    const resultQueryParams = qs.stringify(queryParams);

    const url = `${location.pathname}?${resultQueryParams}`;
    navigate(url, { replace: true });
  };

  const handleUpdateSort = (key: string) => {
    let resultSortData: CampaignSortData;
    if (sortData?.key === key) {
      resultSortData = {
        ...sortData,
        direction: sortData.direction === 1 ? -1 : 1,
      };
    } else {
      resultSortData = {
        key,
        direction: -1,
      };
    }

    setSortData((prev) => {
      if (!prev) return prev;
      // eslint-disable-next-line no-param-reassign
      prev.key = resultSortData.key;
      // eslint-disable-next-line no-param-reassign
      prev.direction = resultSortData.direction;
      return prev;
    });

    // set Query Parameter
    updateQueryParams({
      ...prevQueryParams,
      sortData: resultSortData,
    });
  };

  const editCampaignSetting = useMutation(
    (campaignId: number) => {
      return CampaignApi().patchUnifiedCampaignSetting(
        StorageService.selectedMall.id,
        campaignId,
        campaignName,
        dailyBudget * 1e6,
      );
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('getUnifiedCampaign').then(() => {
          setShowModalEditCampaignSetting(false);
        });

        if (autoCharge) {
          // 프론트에서 자동충전이 되었는 지 알 수 있는 방법이 없으므로 이전 잔액과 현재 잔액을 비교하는 로직을 추가
          const prevRemain = queryClient.getQueryData<number>('remain');
          const currentRemain = (await PaymentApi().getBalance(StorageService.selectedMall.id)).data.data.remain;

          if (prevRemain !== undefined && currentRemain > prevRemain) {
            toastStore.showToast(
              'basic',
              'success',
              i18next.t('광고 집행을 위해 필요한 크레딧이 자동 충전되었습니다.'),
            );
            queryClient.invalidateQueries('remain'); // 잔액 최신화
          } else {
            queryClient.invalidateQueries('remain'); // 잔액 최신화
          }
        }
      },
    },
  );

  const closeModalEditCampaignSetting = () => {
    if (!campaign) return;
    const decimalPlaceLength = multilingual.getDecimalPlaceLength({ currencyCode });
    setShowModalEditCampaignSetting(false);
    setCampaignName(campaign.data.data.name);
    setDailyBudget(campaign.data.data.daily_budget_e6 / 1e6);
    setShowBudgetValue((campaign.data.data.daily_budget_e6 / 1e6).toFixed(decimalPlaceLength));
    resetField('name');
    resetField('budget');
  };

  const onSubmit = async () => {
    if (!campaign) return;
    editCampaignSetting.mutate(campaign.data.data.id);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
  }, []);
  const filteredCampaigns = useMemo(() => {
    if (!campaign) return [] as UniAdsByType[];

    let result = campaign.data.data.uni_ads_by_type as UniAdsByType[];

    result = orderBy(result, [`summary.${sortData?.key}`], sortData?.direction === 1 ? ['asc'] : ['desc']);

    setIsLoading(false);
    return result as UniAdsByType[];
  }, [campaign, sortData?.key, sortData?.direction]);

  const timeLineBoxRef = useRef<HTMLDivElement>(null);
  const lineChartConfig = useMemo<ChartConfig>((): ChartConfig => {
    let timelineWidth = 0;
    if (timeLineBoxRef.current && timeLineBoxRef.current.clientWidth) {
      timelineWidth = timeLineBoxRef.current.clientWidth - 58;
    }
    return {
      metrics:
        (timelineMetric || []).map((curMetric) => {
          return {
            ...curMetric,
            campaign: '1',
            conversion: {
              goalEvent: '' as string,
              value: 0 as number,
              trackingTime: 24 as number,
              filters: [{ type: '', dimension: '', value: '' }],
            },
          };
        }) || ([] as ChartField[]),
      selectedChart: 'Line',
      width: timelineWidth,
      tooltipType: 'Line',
    };
  }, [interval, timelineMetric]);

  const updateMetricList = (index: number) => {
    if (!metricList) return;
    if (!campaign) return;

    const clonedMetricList = [...metricList];
    clonedMetricList.splice(index, 1, {
      ...metricList[index],
      active: !metricList[index].active,
    });
    setMetricList(clonedMetricList);
    setTimelineMetric(
      clonedMetricList
        .filter((curMetric) => {
          return curMetric.active;
        })
        .map((metric) => {
          return {
            ...metric,
            ...{
              filters: [
                {
                  type: 'selector',
                  dimension: 'segment_campaign',
                  value: metric.prop as string,
                },
              ],
            },
          };
        }),
    );
  };

  const toggleCampaignStatus = useMutation(
    (curCampaign: UnifiedCampaign) => {
      const status = curCampaign.status === 'active' ? 'paused' : 'active';
      return CampaignApi().patchUnifiedCampaignStatus(curCampaign.mall_id, curCampaign.id, status);
    },
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('getUnifiedCampaign');
        if (autoCharge) {
          // 프론트에서 자동충전이 되었는 지 알 수 있는 방법이 없으므로 이전 잔액과 현재 잔액을 비교하는 로직을 추가
          const prevRemain = queryClient.getQueryData<number>('remain');
          const currentRemain = (await PaymentApi().getBalance(StorageService.selectedMall.id)).data.data.remain;

          if (prevRemain !== undefined && currentRemain > prevRemain) {
            toastStore.showToast(
              'basic',
              'success',
              i18next.t('광고 집행을 위해 필요한 크레딧이 자동 충전되었습니다.'),
            );
            queryClient.invalidateQueries('remain'); // 잔액 최신화
          } else {
            queryClient.invalidateQueries('remain'); // 잔액 최신화
          }
        }
      },
    },
  );

  const toggleBiginConnected = useMutation(
    (curCampaign: UnifiedCampaign) => {
      const is_bigin_connected = !curCampaign.is_bigin_connected;
      return CampaignApi().patchUnifiedCampaignBiginConnected(curCampaign.mall_id, curCampaign.id, is_bigin_connected);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getUnifiedCampaign');
      },
    },
  );

  const toggleAdStatus = useMutation(
    (curAd: UniAdsByType | UniAd | FbAd | AwAd | KkCreatives) => {
      const status = (curAd as UniAd).status === 'active' ? 'paused' : 'active';
      return CampaignApi()
        .patchUnifiedAdsStatus(StorageService.selectedMall.id, (curAd as UniAd).id, status)
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            const response = error?.response;
            if (response && response.status === 422) {
              dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);
              return;
            }
            if (response && response.status === 409) {
              dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);
            }
          }
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getUnifiedCampaign');
      },
    },
  );
  const toggleTargetStatus = useMutation(
    (value: { curTarget: AdTargetType; uniAd: UniAd; media: string }) => {
      const status = !(value.curTarget as AdTargetType).is_use;

      if (value.media === 'facebook') {
        return CampaignApi()
          .patchFacebookAdTargetStatus(StorageService.selectedMall.id, value.uniAd.id, value.curTarget.id, status)
          .catch((error) => {
            if (axios.isAxiosError(error)) {
              const response = error?.response;
              if (response && response.status === 422) {
                dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

                return;
              }
              if (response && response.status === 409) {
                dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);
              }
            }
          });
      }
      if (value.media === 'kakao') {
        return CampaignApi()
          .patchKakaoAdTargetStatus(StorageService.selectedMall.id, value.uniAd.id, value.curTarget.id, status)
          .catch((error) => {
            if (axios.isAxiosError(error)) {
              const response = error?.response;
              if (response && response.status === 422) {
                dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

                return;
              }
              if (response && response.status === 409) {
                dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);
              }
            }
          });
      }
      return CampaignApi()
        .patchGoogleAdTargetStatus(StorageService.selectedMall.id, value.uniAd.id, value.curTarget.id, status)
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            const response = error?.response;
            if (response && response.status === 422) {
              dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

              return;
            }
            if (response && response.status === 409) {
              dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);
            }
          }
        });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getUnifiedCampaign');
      },
    },
  );

  const handleChangeBiginConnectedToggle = async () => {
    if (!campaign) return;
    toggleBiginConnected.mutate(campaign.data.data);
  };

  const handleChangeToggleButton = async () => {
    if (!campaign) return;
    toggleCampaignStatus.mutate(campaign.data.data);
  };

  const handleAdStatusToggle = async (curAd: UniAdsByType | UniAd | FbAd | AwAd | KkCreatives) => {
    toggleAdStatus.mutate(curAd);
  };
  const handleTargetToggle = async (curTarget: AdTargetType, uniAd: UniAd, media: string) => {
    toggleTargetStatus.mutate({ curTarget, uniAd, media });
  };

  const handleShowPreview = (curAd: UniAd) => {
    const category_id = curAd.fb_collection_category_id || curAd.fb_dynamic_slide_category_id;

    const slides = curAd.slides.map((slide) => {
      const slideSrc = slide.mall_image?.url || slide.product_image?.url || '';
      const slideImageId = slide.mall_image?.image_id || slide.product_image?.image_id;

      return {
        ...slide,
        image_id: slideImageId,
        src: slideSrc,
      };
    });

    let fb_dynamic_collection_body = '';
    let fb_dynamic_catalog_body = '';
    if (curAd.types[0].includes('fb_dynamic')) {
      if (['fb_dynamic_dynamic_video', 'fb_dynamic_image', 'fb_dynamic_video'].includes(curAd.types[0])) {
        fb_dynamic_collection_body = curAd.fb_body;
      } else {
        fb_dynamic_catalog_body = curAd.fb_body;
      }
    }

    const result = {
      ...curAd,
      slides,
      category_id,
      fb_dynamic_catalog_body,
      fb_dynamic_collection_body,
      type: curAd.types[0],
    };

    setTempCampaign(result);

    if (category_id) {
      fetchCategoryProducts(category_id).then(() => {
        setShowModalPreview(true);
      });
    } else {
      setShowModalPreview(true);
    }
  };

  const setHeaderFormat = (column: AllColumnType[]) => {
    const insightTypeText = {
      spend: i18next.t('지출 금액'),
      impressions: i18next.t('노출 수'),
      frequency: i18next.t('노출 빈도'),
      cpm: 'CPM',
      inline_link_clicks: i18next.t('클릭 수'),
      inline_link_click_ctr: 'CTR',
      inline_link_click_cpc: 'CPC',
      actions_1d_view_7d_click: i18next.t('구매 수'),
      actions_7d_click: i18next.t('구매 수'),
      cvr_1d_view_7d_click: i18next.t('구매 전환율'),
      cvr_7d_click: i18next.t('구매 전환율'),
      cpa_1d_view_7d_click: 'CPA',
      cpa_7d_click: 'CPA',
      roas: 'ROAS',
      roas_1d_view_7d_click: 'ROAS',
      roas_7d_click: 'ROAS',
      action_values_1d_view_7d_click: i18next.t('구매 전환 값'),
      action_values_7d_click: i18next.t('구매 전환 값'),
    } as any;

    const pinnedHeader = {
      key: 'name',
      displayName: i18next.t('목록'),
      dataFormat: 'string',
      getGroupDataCellElement: function adTitle(key: string, item: UniAdsByType) {
        return (
          <AdTitle
            item={item}
            key={`group-${item.type}`}
            type="group"
            campaignId={prevQueryParams.id}
            campaignType={item.type}
            handleStatusToggle={handleAdStatusToggle}
            handleShowPreview={handleShowPreview}
          />
        );
      },
      getDataCellElement: function adTitle(key: string, campaignType: CampaignType, item: UniAd, toggle?: boolean) {
        return (
          <AdTitle
            item={item}
            key={`default-${item.types[0]}`}
            type="default"
            campaignId={prevQueryParams.id}
            campaignType={campaignType}
            handleStatusToggle={handleAdStatusToggle}
            handleShowPreview={handleShowPreview}
            toggle={toggle}
            hideToggleIcon={campaignType === 'aw_search' || campaignType === 'aw_pmax'}
          />
        );
      },
      getTargetCellElement: function adTitle(key: string, uniAd: UniAd, item: AdTargetType, media: string) {
        return (
          <AdTargetTitle item={item} uniAd={uniAd} handleStatusToggle={handleTargetToggle} media={media as string} />
        );
      },
      headerStyle: {
        justifyContent: 'flex-start',
      },
      cellStyle: {
        width: '456px',
        padding: '8px 20px',
      },
    };

    const dataHeaders = column.reduce((acc, cur) => {
      const header = {
        key: cur.prop,
        displayName: insightTypeText[cur.prop],
        dataFormat: 'string',
        handleSort: handleUpdateSort,
        getGroupDataCellElement: function campaignStats(key: string, item: UnifiedCampaign) {
          return (
            <CampaignStats
              isLoaded
              campaign={item as UnifiedCampaign}
              targetStats={(item as UnifiedCampaign).summary[cur.prop as UnifiedCampaignSummaryType]}
              propName={cur.prop}
              formatType={getInsightType(cur.prop)}
              unit={i18next.t(getUnitText(cur.prop))}
              lock={cur.fieldLock}
            />
          );
        },
        getDataCellElement: function campaignStats(key: string, campaignType: CampaignType, item: UniAd) {
          return (
            <AdStats
              isLoaded
              ad={item as UniAd}
              targetStats={(item as UniAd).summary[cur.prop as UnifiedCampaignSummaryType]}
              propName={cur.prop}
              formatType={getInsightType(cur.prop)}
              unit={i18next.t(getUnitText(cur.prop))}
              lock={cur.fieldLock}
            />
          );
        },
        getTargetCellElement: function adStats(key: string, uniAd: UniAd, item: AdTargetType) {
          return (
            <AdStats
              isLoaded
              ad={uniAd as UniAd}
              targetStats={(item as AdTargetType).summary[cur.prop as UnifiedCampaignSummaryType]}
              propName={cur.prop}
              formatType={getInsightType(cur.prop)}
              unit={i18next.t(getUnitText(cur.prop))}
              lock={cur.fieldLock}
            />
          );
        },
        headerStyle: {
          justifyContent: 'flex-end',
        },
        cellStyle: { textAlign: 'right' },
      };

      acc.push(header);
      return acc;
    }, [] as CampaignAdsTableHeader[]);

    return [pinnedHeader, ...dataHeaders] as CampaignAdsTableHeader[];
  };

  // gridColumn state
  const [gridColumns, setGridColumns] = useState<AllColumnType[]>(StorageService.saveGridColumns ?? initGridColumn());
  const [headers, setHeaders] = useState(setHeaderFormat(gridColumns.filter((value) => value.checked)));

  const handleGridColumnsUpdate = (curGridColumns: AllColumnType[]) => {
    const newGrid = curGridColumns.map((item) => {
      const fieldLock = needBiginSdk && item.lock;

      return {
        ...item,
        fieldLock,
      };
    });
    setGridColumns(newGrid);
    StorageService.saveGridColumns = newGrid;
    setHeaders(setHeaderFormat(newGrid.filter((value) => value.checked)));
  };
  const handleBudgetChange = (e: ChangeEvent) => {
    const targetValue = (e.target as HTMLInputElement).value.replace(/[^0-9.]/gi, '').replace(/(\..*)\./gi, '$1');

    switch (currencyCode) {
      case 'SGD': {
        const value = parseFloat(parseFloat(targetValue.replaceAll(',', '')).toFixed(2));
        setDailyBudget(Number.isNaN(value) ? 0 : value);
        setValue('budget', Number.isNaN(value) ? 0 : value, { shouldValidate: true });

        const regex = /^[\d]*\.?[\d]{0,2}$/g; // 소수점 2자리까지만
        if (regex.test(targetValue.replaceAll(',', ''))) {
          setShowBudgetValue(Number.isNaN(value) ? '' : targetValue.replaceAll(',', ''));
        }
        break;
      }
      default:
      case 'KRW': {
        const value = parseInt(targetValue.replaceAll(',', ''), 10);

        setDailyBudget(Number.isNaN(value) ? 0 : value);
        setValue('budget', Number.isNaN(value) ? 0 : value, { shouldValidate: true });
        setShowBudgetValue(Number.isNaN(value) ? '' : value.toString());
        break;
      }
    }
  };

  useEffect(() => {
    if (!chartData) return;
    const result = chartData.data.data.insights.reduce((acc: any, cur: any) => {
      acc.push({
        event: {
          totals: cur.totals[selectedInsight.value],
          facebook: cur.facebook[selectedInsight.value],
          google: cur.google[selectedInsight.value],
        },
        timestamp: cur.date,
      });

      return acc;
    }, [] as { event: { totals: number; facebook: number; google: number }; timestamp: string }[]);
    setFilteredChartData(result);
    onCompleteFetchEvent(chartData.data.data.insights);
  }, [selectedInsight, chartDuration]);

  useEffect(() => {
    setHeaders(setHeaderFormat(gridColumns.filter((value) => value.checked)));
  }, [gridColumns]);

  useEffect(() => {
    if (!StorageService.saveGridColumns) {
      StorageService.saveGridColumns = initGridColumn();
    }

    const newGrid = StorageService.saveGridColumns.map((item: AllColumnType) => {
      const fieldLock = needBiginSdk && item.lock;

      return {
        ...item,
        fieldLock,
      };
    });
    setGridColumns(newGrid);
    StorageService.saveGridColumns = newGrid;
  }, [needBiginSdk]);

  const preview = () => {
    if (tempCampaign.type.includes('fb')) {
      if (tempCampaign.type.includes('fb_dynamic')) {
        if (tempCampaign.type === 'fb_dynamic_slide') {
          return (
            <div className="material-facebook-preview-wrapper">
              <div className="material-preview">
                <div className="facebook-wrapper">
                  <div className="preview-media-title-wrapper">Facebook</div>
                  <PreviewFacebookItem
                    key={getUniqKey('preview-facebook-collection', 1)}
                    setting={tempCampaign as CampaignAdSettingState}
                    dynamic="slide"
                    products={categoryProducts}
                  />
                </div>
                <div className="instagram-wrapper">
                  <div className="preview-media-title-wrapper">Instagram</div>
                  <PreviewFacebookItem
                    key={getUniqKey('preview-instagram-collection', 1)}
                    setting={tempCampaign as CampaignAdSettingState}
                    dynamic="slide"
                    instagram
                    products={categoryProducts}
                  />
                </div>
              </div>
            </div>
          );
        }
        return (
          <div className="material-facebook-preview-wrapper">
            <div className="material-preview">
              <div className="facebook-wrapper">
                <div className="preview-media-title-wrapper">Facebook</div>
                <PreviewFacebookItem
                  key={getUniqKey('preview-facebook-collection', 0)}
                  setting={tempCampaign as CampaignAdSettingState}
                  dynamic="collection"
                  products={categoryProducts}
                />
              </div>
              <div className="instagram-wrapper">
                <div className="preview-media-title-wrapper">Instagram</div>
                <PreviewFacebookItem
                  key={getUniqKey('preview-instagram-collection', 0)}
                  setting={tempCampaign as CampaignAdSettingState}
                  dynamic="collection"
                  instagram
                  products={categoryProducts}
                />
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="material-facebook-preview-wrapper">
          <div className="material-preview">
            <div className="facebook-wrapper">
              <div className="preview-media-title-wrapper">Facebook</div>
              <PreviewFacebookItem setting={tempCampaign as CampaignAdSettingState} products={categoryProducts} />
            </div>
            <div className="instagram-wrapper">
              <div className="preview-media-title-wrapper">Instagram</div>
              <PreviewFacebookItem
                setting={tempCampaign as CampaignAdSettingState}
                products={categoryProducts}
                instagram
              />
            </div>
          </div>
        </div>
      );
    }
    if (tempCampaign.type === 'kk_image_banner') {
      return (
        <div className="material-kakao-preview-wrapper">
          <div className="material-preview">
            <div className="kakao-wrapper">
              <PreviewKakaoItem setting={tempCampaign as CampaignAdSettingState} />
            </div>
          </div>
        </div>
      );
    }

    if (tempCampaign.type === 'kk_image_native') {
      const nativeImage = tempCampaign.kk_native_product_image || tempCampaign.kk_native_mall_image;
      const ratio = nativeImage.width === nativeImage.height ? '1:1' : '2:1';
      return (
        <div className="material-kakao-preview-wrapper">
          <div className="material-preview">
            <div className="kakao-wrapper">
              <PreviewKakaoItem setting={tempCampaign as CampaignAdSettingState} ratio={ratio} />
            </div>
          </div>
        </div>
      );
    }

    if (tempCampaign.type === 'aw_youtube') {
      return (
        <div className="material-youtube-preview-wrapper">
          <div className="material-preview">
            <div className="left-wrapper">
              <PreviewYoutubeVideo setting={tempCampaign} type={1} />
            </div>
            <div className="right-wrapper">
              <PreviewYoutubeVideo setting={tempCampaign} type={2} />
            </div>
          </div>
        </div>
      );
    }

    if (tempCampaign.type === 'aw_pmax') {
      return (
        <div className="material-google-pmax-preview-wrapper">
          <div className="material-preview">
            <PreviewGoogleItem setting={tempCampaign} />
          </div>
        </div>
      );
    }
    return (
      <div className="material-google-preview-wrapper">
        <div className="material-preview">
          <div className="google-wrapper">
            <div className="preview-media-title-wrapper">
              <div className="title">{i18next.t('노출 소재')}</div>
            </div>
            <div className="google-preview">
              <PreviewGoogleItem setting={tempCampaign} products={categoryProducts} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const gtmModalBtns = (): { label: string; handleClick?: () => void; appearance?: string; disabled?: boolean }[] => {
    return [
      {
        label: i18next.t('취소'),
        appearance: 'secondary',
        handleClick: () => {
          setShowModalSDKInstall(false);
        },
      },
      {
        label: i18next.t('스크립트 직접 설치'),
        appearance: 'secondary',
        handleClick: () => {
          setShowModalSDKInstall(false);
          navigate(`/malls/${StorageService.selectedMall.id}/accounts?tab=bigin-sdk`);
        },
      },
      {
        label: i18next.t('플랜 업그레이드'),
        disabled: StorageService?.activePlan?.type === 'smartstore',
        handleClick: () => {
          if (StorageService?.activePlan?.type !== 'smartstore') {
            setShowModalSDKInstall(false);
            navigate(`/malls/${StorageService.selectedMall.id}/plan`);
          }
        },
      },
    ];
  };

  return (
    <SkeletonTheme baseColor="#edf1f6" highlightColor="#ffffff">
      <BGPage>
        <TopNavBar style={{ zIndex: '50' }} title={i18next.t('캠페인 상세 정보')} />
        <div css={[baseCss]}>
          <div className="detail-info-container">
            <BorderSection
              style={{ width: 'calc(100% - 260px)', overflow: 'auto', borderRadius: '8px', display: 'inline-block' }}
            >
              <div className="chart-container flex-col">
                <div className="title-container" style={{ border: 'none' }}>
                  <div className="title-wrapper flex-row">
                    <div className="title-wrapper-text">{i18next.t('캠페인 성과')}</div>
                    <div className="title-item-wrapper">
                      <MaterialDropDown
                        appearance="gray"
                        style={{ height: '36px', width: '220px', marginLeft: '16px' }}
                        list={insightSelector()}
                        defaultValue={insightSelector()[0].label}
                        displayValue={(curInsight) => i18next.t((curInsight as Selector).label)}
                        isSelected={(curInsight) => {
                          return (curInsight as Selector).label === selectedInsight.label;
                        }}
                        handleUpdate={(curInsight) => {
                          setSelectedInsight(curInsight as Selector);
                        }}
                      />
                      <Calendar
                        style={{ height: '36px' }}
                        preLabel=""
                        disableFuture
                        selectableRange={selectableDateRange}
                        initDateRange={initSelectedDateRange}
                        dateHandle={(dateRange: DateRange) => {
                          const diff = dateRange.end.diff(dateRange.start, 'days');

                          const compareStart = moment(dateRange.start)
                            .subtract(diff + 1, 'day')
                            .startOf('day');
                          const compareEnd = moment(dateRange.start).subtract(1, 'day').endOf('day');

                          const changeDate = {
                            start: dateRange.start.startOf('day'),
                            end: dateRange.end.endOf('day'),
                            compareStart,
                            compareEnd,
                          };

                          StorageService.saveDateRange = changeDate;
                          setInterval(changeDate);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="contents-container">
                  <div className="campaign-detail-content-wrapper">
                    <div className="campaign-event-selector-list">
                      {metricList
                        .filter((metric) => {
                          return !metric.disabled;
                        })
                        .map((metric, index) => {
                          return (
                            <div
                              className={`campaign-event-selector ${metric.active ? '' : 'inactive'}`}
                              // eslint-disable-next-line react/no-array-index-key
                              key={metric.prop}
                              style={{ position: 'relative' }}
                              onClick={() => {
                                updateMetricList(index);
                              }}
                            >
                              {metric.isLoading ? (
                                <BGLoadingSpinner isLoading={metric.isLoading} />
                              ) : (
                                <React.Fragment>
                                  <div className="campaign-event-title-wrapper">
                                    {metric.active ? (
                                      <div
                                        className="campaign-event-circle-check"
                                        style={{
                                          border: `solid 2px ${metric.color}`,
                                          backgroundColor: metric.color,
                                        }}
                                      />
                                    ) : (
                                      <div
                                        className="campaign-event-circle-check"
                                        style={{
                                          border: `solid 2px ${metric.color}`,
                                          backgroundColor: '#f9fafb',
                                        }}
                                      />
                                    )}
                                    <div className="text-h5 text-color-main">{i18next.t(metric.displayName)}</div>
                                  </div>
                                  <div className="text-h5 text-color-main">
                                    {getInsightType(selectedInsight.value) === 'Currency'
                                      ? filters.formatCurrency({ value: metric.count, currencyCode })
                                      : `${dataFormatting('Number', metric.count)} ${i18next.t(
                                          getUnitText(selectedInsight.value),
                                        )}`}
                                  </div>
                                </React.Fragment>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div
                    className="campaign-line-chart"
                    style={{ position: 'relative', minHeight: '320px' }}
                    ref={timeLineBoxRef}
                  >
                    <CampaignChart
                      duration={chartDuration}
                      config={lineChartConfig}
                      data={filteredChartData ?? []}
                      isSimpleLegend
                      disabledLegend
                    />
                  </div>
                </div>
              </div>
            </BorderSection>
            <BorderSection style={{ width: '260px', overflow: 'auto', borderRadius: '8px', position: 'relative' }}>
              <div className="setting-info-container flex-col">
                <div className="title-container" style={{ border: 'none' }}>
                  <div className="title-wrapper flex-row">
                    <div className="title-wrapper-text">{i18next.t('캠페인 설정 정보')}</div>
                    <button type="button" className="edit-button" onClick={() => setShowModalEditCampaignSetting(true)}>
                      <BDSFontIcon name="ic-edit" size="20px" color="#b5bcc9" />
                    </button>
                  </div>
                </div>
                <div className="contents-container" style={{ padding: '0 20px 8px' }}>
                  <div className="table-wrapper">
                    {settingValuesKey.map((key) => (
                      <div className="row short" key={key}>
                        <span
                          className="text-p3 text-color-main text-bold"
                          style={{ width: `${key === 'name' || key === 'goal' ? '26px' : 'unset'}` }}
                        >
                          {settingValues[key].title}
                        </span>
                        <span
                          className="text-p3 text-color-main"
                          style={{ maxWidth: `${key === 'name' || key === 'goal' ? 'calc(100% - 34px)' : 'unset'}` }}
                        >
                          {settingValues[key].value}
                        </span>
                      </div>
                    ))}
                    <div className="row short">
                      <span className="text-p3 text-color-main text-bold" style={{ width: `unset` }}>
                        {i18next.t('연계 캠페인 사용 여부')}
                      </span>
                      <span className="text-p3 text-color-main" style={{ maxWidth: `unset` }}>
                        <BDSToggleButton
                          active={campaign?.data.data.is_bigin_connected as boolean}
                          onChangeToggle={handleChangeBiginConnectedToggle}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="status-toggle-container">
                  <div className="table-wrapper" style={{ margin: '0' }}>
                    <div className="row" style={{ padding: '0' }}>
                      <span className="text-h5 text-color-main">{i18next.t('캠페인 활성화')}</span>
                      <BDSToggleButton
                        active={(campaign?.data.data.status === 'active') as boolean}
                        onChangeToggle={handleChangeToggleButton}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </BorderSection>
          </div>
          <BorderSection style={{ width: '100%', borderRadius: '8px', marginBottom: '35px' }}>
            <div className="table-container flex-col">
              <div className="title-container">
                <div className="title-wrapper flex-row">
                  <div className="title-wrapper-text">{i18next.t('캠페인 소재')}</div>
                  <div className="title-item-wrapper">
                    {filterSelector && (
                      <MaterialDropDown
                        appearance="gray"
                        style={{
                          height: '36px',
                          width: '220px',
                          marginLeft: '16px',
                          zIndex: '20',
                          boxSizing: 'border-box',
                        }}
                        list={filterSelector}
                        defaultValue={filterSelector[0].label}
                        displayValue={(curFilter) => i18next.t((curFilter as Selector).label)}
                        isSelected={(curFilter) => {
                          return (curFilter as Selector).label === selectedFilter.label;
                        }}
                        handleUpdate={(curFilter) => {
                          setSelectedFilter(curFilter as Selector);
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="contents-container" style={{ padding: '0' }}>
                <CampaignAdsTable
                  isLoaded={!isLoading}
                  headers={headers}
                  lists={filteredCampaigns || []}
                  filterValue={selectedFilter.value}
                  containerStyle={{
                    width: '100%',
                  }}
                  defaultHeaderStyle={{
                    justifyContent: 'center',
                  }}
                  gridColumns={gridColumns}
                  handleGridColumnsUpdate={handleGridColumnsUpdate}
                />
              </div>
            </div>
          </BorderSection>
        </div>
        {showModalEditCampaignSetting && campaign && (
          <WrapperModal
            isOpen={showModalEditCampaignSetting}
            close={() => {
              closeModalEditCampaignSetting();
            }}
            position={{ width: '320px' }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="edit-campaign-setting" css={[editCampaignSettingCss]}>
                <div className="title-wrapper">
                  <div className="title-wrapper-text">{i18next.t('캠페인 설정 수정')}</div>
                  <div className="text-input text-color-main mt-2">
                    {i18next.t('목표, 타겟 성별 및 연령대, 기간은 수정할 수 없습니다.')}
                  </div>
                </div>
                <div className="contents-wrapper">
                  <div className="text-h5 text-color-sub mt-2 mb-2">{i18next.t('제목')}</div>
                  <BGInput
                    inputProps={{
                      type: 'text',
                      name: 'name',
                      placeholder: i18next.t('제목을 입력하세요.'),
                      value: campaignName,
                      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                        setCampaignName(event.target.value);
                        setValue('name', event.target.value);
                      },
                    }}
                  />
                  <div className="text-h5 text-color-sub mt-2 mb-2">{i18next.t('일 예산')}</div>
                  <BGInput
                    inputProps={{
                      type: 'text',
                      name: 'budget',
                      placeholder: i18next.t('일 예산을 입력하세요.'),
                      value: filters.formatStringComma(showBudgetValue),
                      onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                        handleBudgetChange(event);
                      },
                    }}
                    leftLabelComponent={
                      <span className="text-h5 text-right text-color-sub">
                        {multilingual.getCurrencySymbolByCurrencyCode({ currencyCode })}
                      </span>
                    }
                    rightLabelComponent={<span className="text-h5 text-right text-color-sub">{currencyCode}</span>}
                  />
                  {formState.errors.budget && <InputError message={formState.errors.budget.message as string} />}
                  <p className="text-disclaimer mt-2">
                    {i18next.t('*유효한 광고 성과를 위해, 광고 유형 당 {{budget}} 이상의 일 예산이 필요합니다.', {
                      budget: filters.formatCurrency({
                        value: multilingual.getMinimumBudgetByCurrency({
                          currency: currencyCode,
                        }),
                        currencyCode,
                      }),
                    })}
                  </p>
                </div>
                <div className="footer-wrapper">
                  <BDSButton
                    appearance="secondary"
                    label={i18next.t('취소')}
                    onClick={() => {
                      closeModalEditCampaignSetting();
                    }}
                  />
                  <BDSButton
                    label={i18next.t('확인')}
                    type="submit"
                    isDisabled={minBudget > dailyBudget || isCampaignFetching}
                  />
                </div>
              </div>
            </form>
          </WrapperModal>
        )}
        {showModalPreview && tempCampaign && (
          <WrapperModal
            isOpen={showModalPreview}
            position={{
              minWidth: '1000px',
              maxHeight: 'unset',
            }}
            close={() => setShowModalPreview(false)}
          >
            <div css={[previewModalCss]}>
              <div className="preview-modal-header">
                <div className="text-h1 text-color-main">{i18next.t('소재 미리보기')}</div>
                <button className="close-button" type="button" onClick={() => setShowModalPreview(false)}>
                  <BDSFontIcon name="ic-close" color="#7e8696" size="20px" />
                </button>
              </div>
              <div className="preview-modal-contents">{preview()}</div>
            </div>
          </WrapperModal>
        )}

        {showModalSDKInstall && (
          <WrapperModal
            isOpen={showModalSDKInstall}
            close={() => {
              setShowModalSDKInstall(false);
            }}
            position={{ width: '370px' }}
          >
            <DialogConfirm
              title={i18next.t('Bigin SDK 설치 필요')}
              desc={i18next.t(
                '해당 지표를 확인하기 위해선 Bigin SDK 설치가 필요합니다.\n플랜을 업그레이드하면 전문가가 대신 Bigin SDK를 설치해드립니다',
              )}
              buttons={[...gtmModalBtns()]}
            />
          </WrapperModal>
        )}
      </BGPage>
    </SkeletonTheme>
  );
};
