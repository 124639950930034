import moment from 'moment';
import { atom } from 'recoil';

const today = moment();
const campaignSetting = atom<CampaignSettingState>({
  key: 'campaignSetting',
  default: {
    name: '',
    selectedTypes: [] as string[],
    selectedGoal: 'maximize_clicks',
    ageRange: [0, 100],
    involvements: ['low', 'high', 'purchased'],
    gender: ['men', 'women'] as GenderType[],
    targetExclude: null,
    revisitOptimize: 'view_content_or_interest',
    date: {
      start: today.format('YYYY-MM-DD'),
      end: null,
    },
    budget: 0,
    autoCharge: false,
    chargeCredit: 0,
    startOption: 'endless',
    chargeAgree: false,
    isBiginConnected: false,
  },
});

const campaignAdSetting = atom<CampaignAdSettingState[]>({
  key: 'campaignAdSetting',
  default: [] as CampaignAdSettingState[],
});

export default {
  campaignSetting,
  campaignAdSetting,
};
