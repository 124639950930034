import { css } from '@emotion/react';

export const baseCss = () => {
  return css`
    width: 100%;
    height: 760px;

    .upload-file-container {
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }

    .upload-file-wrapper {
      width: 100%;
      height: 100%;
    }

    .empty-file-wrapper {
      width: 100%;
      height: calc(100% - 76px);
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: auto;
    }
    .footer-button-wrapper {
      width: 100%;
      height: 76px;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
    }
  `;
};
