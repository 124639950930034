import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import axios from 'axios';

import { baseCss } from './PlanChange.style';
import { PlanItem } from './PlanItem/PlanItem';
import { Period, PeriodValue } from '../../object-model/model';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { PlanDetail } from './PlanDetail/PlanDetail';
import { PlanPayment } from './PlanPayment/PlanPayment';
import Plan from '../../services/plans/Plan';
import StorageService from '../../services/StorageService';
import SmartstorePlan from '../../services/plans/SmartstorePlan';
import CloudPlan from '../../services/plans/CloudPlan';
import BrandmallPlan from '../../services/plans/BrandmallPlan';
import { BGButton } from '../../components/BGButton/BGButton';
import { WrapperModal } from '../../components/WrapperModal/WrapperModal';
import { IPlanChangeModalForm, PlanChangeModal } from './PlanChangeModal/PlanChangeModal';
import { MallApi } from '../../lib/api/MallApi';
import { useDataStore } from '../../context/Store';

export const PlanChange = (): ReactElement => {
  const i18next = useTranslation();
  const { dialogStore } = useDataStore();

  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [selectedPeriod, setSelectedPeriod] = useState<Period>({
    label: i18next.t('월간 결제'),
    value: PeriodValue.MONTHLY,
  });
  const [planChangeModal, setPlanChangeModal] = useState<boolean>(false);

  const dateToChange = moment(StorageService.activePlan.expired_at).add(1, 'day');
  const isCloudActivated = Plan.cloudTypes.indexOf(StorageService.activePlan.type) > -1;

  const changePeriod = (clickedPeriod: Period): void => {
    setSelectedPeriod(clickedPeriod);
  };

  const freePlan = useMemo(() => {
    const { hosting } = StorageService.selectedMall;
    if (hosting === 'smartstore') {
      return new SmartstorePlan(i18next);
    }

    return new BrandmallPlan(i18next);
  }, [StorageService.selectedMall]);
  const cloudPlan = (() => {
    const plan = new CloudPlan(i18next);
    plan.subscriptionFee = null; // tier가 정해져있지 않아서 null로 우회, 좋은 방법 없을까..?

    return plan;
  })();
  const plans: Plan[] = [freePlan, cloudPlan];

  const isActivatedPlanType = (type: PlanType) => {
    return type === StorageService.activePlan.type || (type === new CloudPlan(i18next).type && isCloudActivated);
  };

  useEffect(() => {
    const found = plans.find((item) => isActivatedPlanType(item.type));
    if (found) {
      setSelectedPlan(found);
    }
  }, []);

  const postChangePlanTask = (form: IPlanChangeModalForm, period?: Period) => {
    return MallApi()
      .createTask(StorageService.selectedMall.id, {
        task_type: 'change_plan',
        data: {
          plan_from: StorageService.activePlan.type,
          plan_to: form.plan,
          period: period?.value || undefined,
          contact_name: form.name,
          contact_email: form.email,
          contact_phone: form.phone,
          reason: form.reason,
        },
      })
      .then(() => {
        dialogStore.showMessage(
          i18next.t('플랜 변경 신청 완료'),
          i18next.t('입력한 정보로 3영업일 이내에 연락을 드리겠습니다.'),
        );
        setPlanChangeModal(false);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <div css={[baseCss]}>
      <TopNavBar title={i18next.t('플랜 변경하기')} />
      <div className="plan-container">
        <div className="plan-content">
          <div className="plan-panel flex-row">
            <div className="plan-box">
              {/* 플랜 리스트 */}
              <div className="plan-list">
                <div className="plan-group-panel flex-row">
                  {plans.length > 0 && (
                    <React.Fragment>
                      {plans.map((plan: Plan) => (
                        <PlanItem
                          key={`PlanSelect_${plan.type}`}
                          selected={selectedPlan?.type === plan.type}
                          disabled={isActivatedPlanType(plan.type)}
                          planItem={plan}
                          period={selectedPeriod}
                          changePlan={setSelectedPlan}
                        />
                      ))}
                    </React.Fragment>
                  )}
                </div>
                <div className="vat-msg-wrapper flex-row">
                  <span className="text-disclaimer">*{i18next.t('부가세는 포함되어 있지 않습니다. (VAT 별도)')}</span>
                </div>

                {isCloudActivated && (
                  <div className="tier-upgrade-banner-container">
                    <div className="typography-wrapper">
                      <div className="text-h3 text-color-main">
                        {i18next.t('구독중인 클라우드 플랜의 월 최대 예산을 변경하고 싶으신가요?')}
                      </div>
                      <div className="text-input text-color-main">
                        {i18next.t('월 최대 예산에 따라 Bigin Ads 컨설턴트의 컨설팅 횟수도 달라집니다.')}
                      </div>
                    </div>
                    <BGButton
                      label={i18next.t('월 최대 예산 변경하기')}
                      onClick={() => {
                        setSelectedPlan(cloudPlan);
                        setPlanChangeModal(true);
                      }}
                    />
                  </div>
                )}

                {/* 플랜 별 상세 가격정책 */}
                <PlanDetail planList={plans} />
              </div>
            </div>
            {/* 결제 요청 및 프로모션 쿠폰 */}
            <PlanPayment
              isDisabled={selectedPlan ? isActivatedPlanType(selectedPlan.type) : true}
              dateToChange={dateToChange}
              period={selectedPeriod}
              selectedPlan={selectedPlan!}
              changePeriod={changePeriod}
              onPlanChangeClicked={() => {
                setPlanChangeModal(true);
              }}
            />
          </div>
        </div>
      </div>

      {/* 플랜 변경 신청 모달 */}
      {selectedPlan && (
        <WrapperModal
          isOpen={planChangeModal}
          close={() => {
            setPlanChangeModal(false);
          }}
          position={{ width: 'fit-content', maxHeight: '710px' }}
        >
          <PlanChangeModal
            fromPlan={StorageService.activePlan.type}
            toPlan={selectedPlan.type}
            dateToChange={dateToChange}
            onSubmit={(form) => {
              postChangePlanTask(form, Plan.isFreeType(StorageService.activePlan.type) ? selectedPeriod : undefined);
            }}
            close={() => {
              setPlanChangeModal(false);
            }}
          />
        </WrapperModal>
      )}
    </div>
  );
};
