import React, { InputHTMLAttributes, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, SerializedStyles, Theme, useTheme } from '@emotion/react';
import { BGTagInputWrapperCSS, descriptionCss } from './BGTagInput.style';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  style?: object;
}

export interface BGTagInputProps {
  inputProps: InputProps;
  className?: string;
  style?: object;
  css?: SerializedStyles;
  error?: boolean;
  defaultTags?: string[];
  duplicateText?: string;
  max?: number;
  handleUpdate?: (tags: string[]) => void;
}

enum Key {
  BACKSPACE = 'Backspace',
  ENTER = 'Enter',
  LEFT_ARROW = 'ArrowLeft',
  UP_ARROW = 'ArrowUp',
  RIGHT_ARROW = 'ArrowRight',
  DOWN_ARROW = 'ArrowDown',
  DELETE = 'Delete',
  SPACEBAR = ' ',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const BGTagInput = React.forwardRef((props: BGTagInputProps, ref: React.Ref<HTMLInputElement>): ReactElement => {
  const i18next = useTranslation();
  const { inputProps, className = '', css: wrapperCSS, style = {}, error, defaultTags, duplicateText, max } = props;
  const {
    className: inputClassName = '',
    style: inputStyle = {},
    placeholder = '',
    disabled = false,
    type = 'text',
  } = inputProps;

  const theme: Theme = useTheme();

  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>(defaultTags || []);

  const inputRef = useRef<HTMLInputElement>(null);
  const [currentValue, setCurrentValue] = useState<string>('');

  const [doNotChange, setDoNotChange] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);

  const limit = max ?? 5;

  useEffect(() => {
    if (props.handleUpdate) props.handleUpdate(tags);
  }, [tags]);

  return (
    <React.Fragment>
      <div
        style={style}
        className={`${className} ${disabled ? 'isDisabled' : ''} ${error ? 'isError' : ''}`}
        css={[BGTagInputWrapperCSS(theme), wrapperCSS]}
        onClick={() => {
          if (inputRef?.current) {
            inputRef.current.focus();
          }
        }}
      >
        {tags.map((tag) => (
          <span className="badge" id={`tag-${tag}`}>
            {tag}
          </span>
        ))}
        <span className="placeholder" style={{ display: tags.length !== 0 || hasFocus ? 'none' : 'block' }}>
          {placeholder}
        </span>
        <span className="tag-input-wrap">
          <input
            {...inputProps}
            ref={inputRef}
            className={inputClassName}
            style={inputStyle}
            type={type}
            placeholder=""
            value={currentValue}
            onChange={(event) => {
              if (inputProps.onChange) {
                inputProps.onChange(event);
              }

              if (!doNotChange) {
                setCurrentValue(event.target.value);
              }
            }}
            onFocus={(event) => {
              if (inputProps.onFocus) {
                inputProps.onFocus(event);
              }

              setHasFocus(true);
            }}
            onBlur={(event) => {
              if (inputProps.onBlur) {
                inputProps.onBlur(event);
              }

              setHasFocus(false);
            }}
            onKeyDown={(event) => {
              event.stopPropagation();
              if (inputProps.onKeyDown) {
                inputProps.onKeyDown(event);
              }

              const tempValue = currentValue;
              if (event.key === Key.SPACEBAR || event.key === Key.ENTER) {
                setDoNotChange(true);
                if (tags.includes(tempValue)) {
                  setIsDuplicate(true);
                  return;
                }
                setCurrentValue('');

                if (currentValue && tags.length < limit) {
                  setTags([...tags, tempValue]);
                }
              }

              if (inputRef && inputRef?.current?.value.length === 0 && event.key === Key.BACKSPACE) {
                const deleteTagIndex = tags.length - 1;
                const cloneTags = _.cloneDeep(tags);
                cloneTags.splice(deleteTagIndex, 1);
                setTags([...cloneTags]);
              }
            }}
            onKeyUp={(event) => {
              if (inputProps.onKeyUp) {
                inputProps.onKeyUp(event);
              }

              if (event.key === Key.SPACEBAR || event.key === Key.ENTER) {
                setDoNotChange(false);
              } else {
                setIsDuplicate(false);
              }
            }}
          />
        </span>
      </div>
      {isDuplicate && <div css={[descriptionCss]}>{duplicateText || i18next.t('이미 등록된 태그입니다.')}</div>}
    </React.Fragment>
  );
});
