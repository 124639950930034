import { css, useTheme, Theme } from '@emotion/react';
import { GS } from '../../../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .horizontal-line {
      width: 100%;
      height: 1px;
      background-color: #ebeef4;
    }

    .create-section-content {
      display: flex;
      justify-content: flex-start;
      position: relative;
      min-height: 500px;
      padding: 0;
      width: 100%;
      /* align-items: flex-start; // Sticky 옵션을 사용할 거라면 활성화 */

      .preview-container {
        width: calc(100% - 400px);
        padding: 0 ${GS.gutter}px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .sticky-wrap {
          position: sticky;
          top: 106px;
          padding-bottom: 87px;
          width: 100%;
        }

        .title-panel {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 20px;
          padding-bottom: 16px;

          .title {
            ${theme.text.h4};
            color: ${theme.colors.mainFont};
          }
          .info {
            ${theme.text.disclaimer}
          }
        }

        .material-preview-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          border: solid 1px #dce0e8;
          background-color: #f9fafb;
          margin-bottom: 60px;

          .material-preview-top-bar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 6px;
            width: 100%;
            padding: 8px;
            border-bottom: solid 1px #dce0e8;
            background-color: #f9fafb;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .top-bar-oval {
              width: 8px;
              height: 8px;
              border-radius: 4px;
              background-color: #dce0e8;
            }
          }

          .material-preview {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 588px;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .left-wrapper {
              padding: 20px;
            }
            .right-wrapper {
              border-left: 1px solid ${theme.colors.divider};
              padding: 20px;
            }
          }
        }
      }

      .form-container {
        border-left: 1px solid ${theme.colors.divider};
        width: 400px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 20px;

        .material-field-header {
          font-size: 16px;
          font-weight: bold;
          color: ${theme.colors.mainFont};
          margin: 0;
          padding: 16px 20px 20px;
        }

        .material-field-selector-wrapper {
          width: 100%;
          height: auto;
          padding: 0 20px 20px;

          .description {
            margin-top: 8px;
            ${theme.text.disclaimer}
          }

          .material-field-input-wrapper {
            position: relative;

            textarea {
              height: 49px;
            }
          }

          .material-dropdown.gray {
            border-radius: 8px;

            .material-dropdown-item.gray {
              border-radius: 8px;
            }
          }
        }
      }
    }

    .content-upload-container {
      width: 100%;
      height: auto;
      border-radius: 8px;
      border: solid 1px #dce0e8;
      background-color: #f9fafb;
      overflow: hidden;
    }

    .uploader-wrapper {
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .image-uploader-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }

      .image-uploader-subtitle {
        // view
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > div {
          //text
          ${theme.text.disclaimer}
        }
      }
    }

    .single-image-uploader-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .image-uploader-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding: 16px;
      border-radius: 8px;
      background-color: ${theme.colors.bg3};
      position: relative;

      .image-uploader-item-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }
    }
  `;
};

export const smallButtonCss = css`
  // view
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dce0e8;
`;
