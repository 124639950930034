import { css } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = css`
  * {
    box-sizing: border-box;
  }

  & {
    width: 100%;
    min-width: fit-content;
    display: flex;
    flex-direction: column;
    gap: ${GS.gutter}px;
    margin-top: 28px;
    margin-bottom: 35px;
  }
`;
