import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { MoreButton } from '../../../components/MoreButton/MoreButton';

export interface AdMoreButtonProps {
  ad: UniAd;
  getMoreButtonList: (ad: UniAd) => {
    value: string;
    label: string;
  }[];
  handleMoreButton: (item: UniAd, value: string) => void;
}

export const AdMoreButton = (props: AdMoreButtonProps): JSX.Element => {
  const { ad } = props;

  if (props.getMoreButtonList(ad).length > 0) {
    return (
      <MoreButton
        items={props.getMoreButtonList(ad)}
        onMenuButtonClick={(value) => props.handleMoreButton(ad, value)}
      />
    );
  }
  return <div />;
};
