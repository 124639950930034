import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, Theme, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { baseCss, StyledDefaultEmptyOverlayComponent } from './CampaignListTable.style';
import { FontIcon } from '../FontIcon/FontIcon';
import { getUniqKey } from '../../utils/array.utils';
import { ColumnPicker } from '../ColumnPicker/ColumnPicker';
import { AllColumnType } from '../../types/BGEventDef';
import { BGEmpty } from '../BGEmpty/BGEmpty';

export interface CampaignListTableHeader {
  key: string;
  displayName: string;
  dataFormat?: string;
  getDataCellElement: (key: string, item: any) => JSX.Element;
  handleSort?: (key: string) => void;

  headerStyle?: object;
  cellStyle?: object;

  skeletonComponent?: () => JSX.Element;
}

export interface CampaignListTableProps {
  pinnedHeader: CampaignListTableHeader[];
  headers: CampaignListTableHeader[];
  lists: UnifiedCampaign[];
  isLoaded: boolean;

  // TODO: 개발 후 추가
  // isEmpty: boolean;
  // isSearch: boolean;
  // isError: boolean;
  onClickTableRow?: (item: UnifiedCampaign) => void;

  containerStyle?: object;
  tableStyle?: object;
  defaultHeaderStyle?: object;
  defaultRowStyle?: object;
  defaultCellStyle?: (item: UnifiedCampaign) => object;
  gridColumns: AllColumnType[];
  handleGridColumnsUpdate?: (gridColumns: AllColumnType[]) => void;
}
export const CampaignListTable = (props: CampaignListTableProps) => {
  const i18next = useTranslation();
  const theme: Theme = useTheme();
  const {
    containerStyle = {},
    tableStyle = {},
    defaultHeaderStyle = {},
    defaultRowStyle = () => {
      return {};
    },
    defaultCellStyle = () => {
      return {};
    },
  } = props;

  const renderSkeleton = () => {
    const result = [];
    for (let i = 0; i < 10; i += 1) {
      result.push(
        <tr className="bg-list-table-row" key={getUniqKey('bg-list-table-skeleton-row', i)}>
          {props.pinnedHeader.map((header: CampaignListTableHeader, cellIndex) => {
            const { cellStyle = {} } = header;
            return (
              <td
                key={getUniqKey('bg-list-table-pinned-header-skeleton-cell', cellIndex)}
                css={css`
                  .skeleton {
                    border-radius: 0;
                  }
                `}
                style={{ padding: '16px', ...cellStyle }}
              >
                {header?.skeletonComponent ? (
                  header.skeletonComponent()
                ) : (
                  <Skeleton className="skeleton" style={{ width: '100%' }} />
                )}
              </td>
            );
          })}
          {props.headers.map((header: CampaignListTableHeader, cellIndex) => {
            const { cellStyle = {} } = header;
            return (
              <td
                key={getUniqKey('bg-list-table-header-skeleton-cell', cellIndex)}
                css={css`
                  .skeleton {
                    border-radius: 0;
                  }
                `}
                style={{ padding: '16px', ...cellStyle }}
              >
                {header?.skeletonComponent ? (
                  header.skeletonComponent()
                ) : (
                  <Skeleton className="skeleton" style={{ width: '100%' }} />
                )}
              </td>
            );
          })}
        </tr>,
      );
    }

    return result;
  };

  return (
    <div className="campiagn-dashboard-table-container" css={[baseCss]} style={{ ...containerStyle }}>
      <div
        className="column-picker-wrapper"
        style={{
          width: '45px',
          height: '45px',
          position: 'absolute',
          right: '0',
          backgroundColor: theme.colors.bg1,
          zIndex: '5',
        }}
      >
        {props.gridColumns && props.handleGridColumnsUpdate && (
          <ColumnPicker
            columns={props.gridColumns}
            handleUpdate={props.handleGridColumnsUpdate}
            customCss={css`
              border-bottom: solid 1px #b8beca !important;
            `}
          />
        )}
      </div>
      <div className="campaign-list-table-wrapper">
        <table className="campaign-list-table" style={{ ...tableStyle }}>
          <thead>
            <tr>
              {props.pinnedHeader.map((header, index) => {
                const { headerStyle = {} } = header;
                return (
                  <th key={getUniqKey('campaign-list-table-pinned-header', index)}>
                    <div
                      className="campaign-list-table-header-wrapper"
                      style={{ ...defaultHeaderStyle, ...headerStyle }}
                    >
                      {header.displayName}
                      {header.handleSort && (
                        <FontIcon
                          className="header-sort-icon"
                          name="ic-asc"
                          size="20px"
                          color="#7e8696"
                          handleClick={() => {
                            if (header.handleSort) {
                              header.handleSort(header.key);
                            }
                          }}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
              {props.headers.map((header, index) => {
                const { headerStyle = {} } = header;
                return (
                  <th key={getUniqKey('campaign-list-table-header', index)}>
                    <div
                      className="campaign-list-table-header-wrapper"
                      style={{ ...defaultHeaderStyle, ...headerStyle }}
                    >
                      {header.displayName}
                      {header.handleSort && (
                        <FontIcon
                          className="header-sort-icon"
                          name="ic-asc"
                          size="20px"
                          color="#7e8696"
                          handleClick={() => {
                            if (header.handleSort) {
                              header.handleSort(header.key);
                            }
                          }}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={props.isLoaded && props.lists.length === 0 ? 'empty-body' : ''}>
            {!props.isLoaded && renderSkeleton()}
            {props.isLoaded && props.lists.length === 0 && (
              <div className="empty-container">
                <StyledDefaultEmptyOverlayComponent>
                  <div style={{ width: '310px' }}>
                    <BGEmpty
                      type="default"
                      title={i18next.t('생성된 캠페인이 없습니다.')}
                      description={i18next.t(
                        "화면 오른쪽 상단의 '통합 캠페인 생성'버튼을 클릭하여 캠페인을 생성하세요.",
                      )}
                    />
                  </div>
                </StyledDefaultEmptyOverlayComponent>
              </div>
            )}
            {props.isLoaded &&
              props.lists.length > 0 &&
              props.lists.map((item: UnifiedCampaign, rowIndex) => {
                return (
                  <tr
                    className="campaign-list-table-row"
                    key={getUniqKey('campaign-list-table-row', rowIndex)}
                    style={{ ...defaultRowStyle }}
                    onClick={() => {
                      if (props.onClickTableRow) {
                        props.onClickTableRow(item);
                      }
                    }}
                  >
                    {props.pinnedHeader.map((header: CampaignListTableHeader, cellIndex) => {
                      const { cellStyle = {}, key } = header;
                      return (
                        <td
                          key={getUniqKey('campaign-list-table-data-pinned-cell', cellIndex)}
                          style={{ ...defaultCellStyle(item), ...cellStyle }}
                        >
                          <React.Fragment>{header.getDataCellElement(key, item)}</React.Fragment>
                        </td>
                      );
                    })}
                    {props.headers.map((header: CampaignListTableHeader, cellIndex) => {
                      const { cellStyle = {}, key } = header;
                      return (
                        <td
                          key={getUniqKey('campaign-list-table-data-cell', cellIndex)}
                          style={{ ...defaultCellStyle(item), ...cellStyle }}
                        >
                          {item.status_tag.code === 'CAMPAIGN_STATUS_END' && <div className="disabled-cell-overlay" />}
                          <React.Fragment>{header.getDataCellElement(key, item)}</React.Fragment>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
