import { selector } from 'recoil';
import atom from './atom';

const setParentCategory = selector<Category>({
  key: 'setParentCategory',
  get: ({ get }) => get(atom.selectParentCategory),
  set: ({ set }, parentCategory) => {
    set(atom.selectParentCategory, parentCategory);
  },
});

const setCategoryId = selector<number>({
  key: 'setCategoryId',
  get: ({ get }) => get(atom.selectCategoryId),
  set: ({ set }, id) => {
    set(atom.selectCategoryId, id);
  },
});

const setProducts = selector<Product[]>({
  key: 'setProducts',
  get: ({ get }) => get(atom.products),
  set: ({ set }, product) => {
    return set(atom.products, () => {
      return product;
    });
  },
});

export { setProducts, setCategoryId, setParentCategory };
