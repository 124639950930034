import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: 100%;

    .bigin-sdk-script-container {
      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          ${theme.text.h2};
          color: ${theme.colors.mainFont};
        }
      }

      .description-wrppaer {
        ${theme.text.input};
        color: #000;
        padding: 16px 0;
        ul {
          margin: 0;
          padding-left: 20px;

          li {
            &::marker {
              color: #000;
            }
          }
        }
      }

      .cafe24-box {
        display: flex;
        align-items: center;
        background: #f9fafb;
        border: 1px solid #dce0e8;
        border-radius: 12px;
        width: 100%;
        height: 56px;
        padding: 12px;
        gap: 8px;
      }

      .tracking-source-code {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        .clipboard-copy {
          cursor: pointer;
          position: absolute;
          top: 16px;
          right: 16px;
        }

        .index-list {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 40px;
          border: 1px solid #ededf2;
          border-radius: 2px;
          text-align: center;
          padding: 24px 8px;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: #53585f;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        .source-code {
          font-family: inherit;
          margin: 0;
          padding: 24px 22px;
          width: 100%;
          letter-spacing: normal;
          border: 1px solid #ededf2;
          border-left: none;
          overflow: hidden;
          font-size: 14px;
          line-height: 1.43;
          color: #53585f;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    .skeleton {
      width: 80%;
      border-radius: 0;
    }
  `;
};

export const createScriptModalCss = () => {
  const theme: Theme = useTheme();

  return css`
    .create-script-modal-header {
      ${theme.text.h1};
      color: ${theme.colors.mainFont};
    }
    .create-script-modal-contents {
      margin-top: 8px;

      .button-text {
        margin-top: 8px;
        padding: 0;
        ${theme.text.disclaimer};
        border: 0;
        background: none;
        cursor: pointer;
      }
    }
    .horizontal-divider {
      width: 100%;
      height: 1px;
      margin-top: 20px;
      background-color: #dce0e8;
    }
    .create-script-modal-footer {
      display: flex;
      justify-content: flex-end;
      padding-top: 16px;
      gap: 12px;
    }
  `;
};
