import { UseTranslationResponse } from 'react-i18next';

import Plan from './Plan';

export default class BrandmallPlan extends Plan {
  constructor(i18next: UseTranslationResponse<'translation'>) {
    super();

    this.type = 'brandmall';
    this.image = '/assets/img/plan/ic-plan-free.svg';
    this.name = `${i18next.t('프리')} ${i18next.t('플랜')}`;
    this.description = i18next.t('무료로 디지털 광고 자동화 솔루션을 경험해보세요!');
    this.features = [
      i18next.t('통합 캠페인 생성 및 관리'),
      i18next.t('유입 중심 광고 목표 최적화'),
      i18next.t('월 광고 예산 한도 500만원 미만'),
    ];
    this.subscriptionFee = 0;
    this.feeRate = 5.5;
    this.policy = {
      management: {
        budgetLimit: i18next.t('{{price}}만원 미만', { price: 500 }) as string,
        commission: false,
        consult: false,
        oneOnOneChat: false,
        alimtalkPro: false,
        solutionEducation: false,
      },
      analytics: {
        biginCrm: false,
        biginSdk: false,
      },
      report: {
        campaign: true,
        weeklyPerformance: false,
      },
      creative: {
        createCatalog: false,
        updateProductImage: false,
        editTool: true,
        support: false,
      },
      media: {
        facebookImage: true,
        facebookVideo: true,
        facebookCollection: false,
        facebookDynamic: false,
        googleResponsive: true,
        googleDynamic: false,
        googleShopping: false,
        youtube: false,
        kakaoBizboard: true,
        kakaoNative: true,
      },
    };
  }
}
