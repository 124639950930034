import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { MoreButton } from '../../../components/MoreButton/MoreButton';

export interface CampaignMoreButtonProps {
  campaign: UnifiedCampaign;
  getMoreButtonList: (campaign: UnifiedCampaign) => {
    value: string;
    label: string;
  }[];
  handleMoreButton: (item: UnifiedCampaign, value: string) => void;
}

export const CampaignMoreButton = (props: CampaignMoreButtonProps): JSX.Element => {
  const { campaign } = props;

  if (props.getMoreButtonList(campaign).length > 0) {
    return (
      <MoreButton
        items={props.getMoreButtonList(campaign)}
        onMenuButtonClick={(value) => props.handleMoreButton(campaign, value)}
      />
    );
  }
  return <div />;
};
