import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import StorageService from '../../services/StorageService';

export const TitleChange = (): null => {
  const i18next = useTranslation();
  const { pathname } = useLocation();

  const getTitleMallURL = (name: string): string => {
    let result = '';
    switch (name) {
      case 'creative':
      case 'create-image':
        result = i18next.t('Bigin Ads | 크리에이티브');
        break;
      case 'accounts':
        result = i18next.t('Bigin Ads | 설정');
        break;
      case 'payment':
        result = i18next.t('Bigin Ads | 결제 관리');
        break;
      default:
        result = i18next.t('Bigin Ads | 캠페인 관리');
        break;
    }
    return result;
  };

  const getTitle = useCallback(
    (path: string): string => {
      const parsedPath = path.split('/');

      let willChangeTitle = document.title;
      switch (true) {
        case parsedPath.includes('login'):
          willChangeTitle = i18next.t('Bigin Ads | 로그인');
          break;
        case parsedPath.includes('sign-up'):
          willChangeTitle = i18next.t('Bigin Ads | 회원가입');
          break;
        case parsedPath.includes('new-mall'):
          willChangeTitle = i18next.t('Bigin Ads | 쇼핑몰 추가');
          break;
        case parsedPath.includes('malls'):
          willChangeTitle = getTitleMallURL(parsedPath[3] ?? '');
          break;
        default:
          break;
      }

      return willChangeTitle;
    },
    [pathname, StorageService.translationLanguage],
  );

  useEffect(() => {
    document.title = getTitle(pathname);
  }, [pathname, StorageService.translationLanguage]);

  return null;
};
