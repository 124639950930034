import { css } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = css`
  z-index: 51;
  display: flex;
  height: 100vh;
  width: ${GS.sideBarMaxWidth}px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  transition: width 0.4s;
  overflow: auto;

  &:hover {
    .side-nav-logo-panel {
      .side-nav-bar-button {
        opacity: 1;
      }
    }
  }

  &.mini {
    width: ${GS.sideBarMinWidth}px;

    .side-nav-logo-panel {
      padding: 0 18px;
      margin-top: 20px;
      margin-bottom: 24px;
    }

    .side-nav-project-id {
      display: none;
    }

    .side-nav-bar-button {
      top: 16px;
      right: 16px;
    }
  }

  &.mini-hover {
    //position: absolute !important;
    width: ${GS.sideBarMaxWidth}px;
    box-shadow: 0 8px 36px 0 rgba(79, 86, 97, 0.1);

    .side-nav-logo-panel {
      padding: 0 20px;
    }

    .side-nav-bar-button {
      top: 18px;
      right: 18px;
    }
  }

  .side-nav-logo-panel {
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;

    .side-nav-logo {
      width: 162px;
      margin-top: 20px;
      margin-bottom: 12px;
      background-position: center;
      background-size: contain;
    }
    .side-nav-logo-mini {
      height: 26px;
    }
    .side-nav-bar-button {
      box-sizing: border-box;
      position: absolute;
      top: 18px;
      right: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      padding: 4px;
      border-radius: 8px;
      border: solid 1px #dce0e8;
      background-color: #f9fafb;
      opacity: 0;
      transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
      cursor: pointer;

      &:hover {
        border: solid 1px #39f;

        .font-icon-panel {
          color: #39f !important;
        }
      }
    }
  }
  .side-nav-project-id {
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: normal;
    color: #53585f;
    display: flex;
    justify-content: flex-start;
    gap: 4px;
    width: 100%;
    white-space: pre;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .side-nav-wallet-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 16px 20px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      background-color: #f9fafb;
    }

    .side-nav-wallet-item {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.14;
      color: #3d4046;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 16px;
        margin-left: 8px;
        &.red {
          color: #e65c5c;
        }
      }
    }
  }
  .wallet-warning-msg {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 9px 16px;
    width: 100%;
    font-size: 12px;
    color: #ffffff;
    background-color: #e65c5c;
    white-space: nowrap;

    .wallet-charge-btn {
      font-weight: bold;
    }
  }
  .side-nav-bottom-list {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
    padding-bottom: 14px;

    .side-nav-menu {
      padding-top: 12px;
    }

    .demo {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 31px 34px;

      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .description-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 18px;

          .description {
            margin-left: 4px;
            font-size: 14px;
            color: #53585f;
          }
        }
      }
    }
  }

  .side-nav-menu {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    margin-bottom: 10px;
  }
`;
