import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { BGUrlSelectorStyle } from './BGUrlSelector.style';
import { FontIcon } from '../FontIcon/FontIcon';
import { Backdrop } from '../Backdrop/Backdrop';
import { i18nextScanKey } from '../../i18n';
import { IMAGE_PROXY_URL } from '../../utils/utils';
import { InputError } from '../InputError/InputError';

const ProtocolRegex = new RegExp(/^http(s?):\/\//);
const protocolList: string[] = ['https://', 'http://'];

export const extractProtocolFromUrl = (s: string): string => {
  if (!s) return 'http://';
  if (!s.match(ProtocolRegex)) return 'http://';
  const matchedProtocols = s.match(ProtocolRegex);
  return (matchedProtocols && matchedProtocols[0]) || 'http://';
};

export const extractLocationFromUrl = (s: string): string => {
  if (!s) return '';
  return s.replace(ProtocolRegex, '');
};

export const checkUrlPatten = (s: string): boolean => {
  const pattern = /^(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&//=]*)/i;
  if (!s) return false;
  return pattern.test(s);
};

export interface BGUrlSelectorType {
  url: string; // Material 의 URL
  handleUpdate?: (s: string, removable?: boolean) => void;
  style?: Object;
  isValid?: boolean;
  validationTrackOn?: boolean;
  showDeleteIcon?: boolean;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  linkButton?: boolean;
}
export const BGUrlSelector = React.forwardRef(
  (props: BGUrlSelectorType, ref: React.Ref<HTMLInputElement>): ReactElement => {
    const i18next = useTranslation();
    const [isOver, setIsOver] = useState(false);
    const [urlValid, setUrlValid] = useState<boolean>(true);
    const [protocolOpened, setProtocolOpened] = useState<boolean>(false);

    const selectedProtocol: string = useMemo<string>(() => {
      return extractProtocolFromUrl(props.url);
    }, [props.url]);
    const selectedLocation: string = useMemo<string>(() => {
      return extractLocationFromUrl(props.url);
    }, [props.url]);

    const checkUrl: boolean = useMemo<boolean>(() => {
      return checkUrlPatten(selectedLocation);
    }, [props.url]);

    const updateProtocol = (curProtocol: string) => {
      // 전체 URL에서 protocol을 replace 한 결과를 업데이트
      // ex. http://bigin.io -> https://bigin.io
      let updatingUrl = '';
      if (typeof props.url !== 'string') updatingUrl = 'https://';
      else if (!props.url) updatingUrl = 'https://';
      else if (!props.url.match(ProtocolRegex)) updatingUrl = 'https://';
      else updatingUrl = props.url.replace(ProtocolRegex, curProtocol);

      setProtocolOpened(false);
      if (props.handleUpdate) props.handleUpdate(updatingUrl);
    };

    const updateLocation = (curLocation: string, removable = false) => {
      // 전체 URL에서 location을 replace 한 결과를 업데이트
      // ex. https://bigin.io -> https://biginsight.io

      let updatingUrl = '';
      if (typeof props.url !== 'string') updatingUrl = 'https://';
      else if (!props.url) updatingUrl = 'https://';
      else if (!props.url.match(ProtocolRegex)) updatingUrl = 'https://';

      const protocolRemoveLocation = curLocation.replace(ProtocolRegex, '');

      if (props.url.indexOf('http://') > -1) {
        updatingUrl = `http://${protocolRemoveLocation}`;
      } else if (props.url.indexOf('https://') > -1) {
        updatingUrl = `https://${protocolRemoveLocation}`;
      } else if (!props.url && updatingUrl === 'https://') {
        updatingUrl = `https://${protocolRemoveLocation}`;
      } else {
        updatingUrl = 'https://';
      }

      setProtocolOpened(false);
      if (props.handleUpdate) props.handleUpdate(updatingUrl, removable);
    };

    const renderDeleteIcon = (): JSX.Element => {
      return (
        <div
          className="delete-icon-wrapper"
          onClick={() => {
            updateLocation('', true);
          }}
        >
          <FontIcon className="delete-icon" name="ic-delete" color="#bbbbcc" size="14px" />
        </div>
      );
    };

    const urlCheck = (curUrl: string) => {
      const uninterceptedAxiosInstance = axios.create();
      uninterceptedAxiosInstance
        .get(`${IMAGE_PROXY_URL}${curUrl}`)
        .then((res) => {
          if (res.status === 200) {
            setUrlValid(true);
          }
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            const response = error?.response;
            if (response?.status === 404) {
              setUrlValid(false);
            }
          }
        });
    };

    useEffect(() => {
      const timer = setTimeout(() => {
        urlCheck(props.url);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }, [props.url]);

    return (
      <div className="url-selector-wrapper" css={[BGUrlSelectorStyle]}>
        <div
          className={`url-selector ${
            props.validationTrackOn && (!props.isValid || !checkUrl || !urlValid) && 'isError'
          }`}
          style={props.style}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {protocolOpened && (
            <Backdrop
              handleClick={(event) => {
                event.stopPropagation();
                setProtocolOpened(false);
              }}
              style={{ zIndex: 1, backgroundColor: 'rgba(0,0,0,0)' }}
            />
          )}
          <div className={`url-selector-wrapper ${protocolOpened ? 'relative' : ''} ${props.disabled && 'disabled'}`}>
            <div className="protocol-selected-item-wrapper">
              <div
                className="protocol-select-item"
                onClick={(event) => {
                  event.stopPropagation();
                  if (props.disabled) return;
                  setProtocolOpened(true);
                }}
              >
                {
                  // 전체 URL에서 protocol 에 해당하는 부분만 추출.
                  selectedProtocol
                }
                <FontIcon name="ic-arrow-down" size="16px" color="#bbc" />
              </div>
            </div>
            {protocolOpened && (
              <div className="protocol-select-box">
                {protocolList.map((p) => {
                  return (
                    <div
                      className="protocol-select-item"
                      key={p}
                      onClick={(event) => {
                        event.stopPropagation();
                        updateProtocol(p);
                      }}
                    >
                      {p}
                    </div>
                  );
                })}
              </div>
            )}
            {props.showDeleteIcon ? (
              <React.Fragment>
                <div
                  className={`removable-wrapper ${isOver ? 'show-delete-icon' : ''}`}
                  onFocus={() => true}
                  onMouseOver={() => {
                    setIsOver(true);
                  }}
                  onMouseLeave={() => {
                    setIsOver(false);
                  }}
                >
                  <input
                    className={`url-input ${props.disabled && 'disabled'}`}
                    name={props.name}
                    placeholder={props.placeholder}
                    defaultValue={selectedLocation || ''}
                    value={selectedLocation}
                    disabled={props.disabled}
                    ref={ref}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    onChange={(event) => {
                      updateLocation(event.target.value);
                    }}
                  />
                  {isOver && renderDeleteIcon()}
                </div>
              </React.Fragment>
            ) : (
              <input
                className={`url-input ${props.disabled && 'disabled'}`}
                name={props.name}
                placeholder={i18next.t(props.placeholder || '')}
                defaultValue={selectedLocation || ''}
                value={selectedLocation}
                disabled={props.disabled}
                ref={ref}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onChange={(event) => {
                  setUrlValid(true);
                  updateLocation(event.target.value);
                }}
              />
            )}
            {props.linkButton && (
              <div className="link-button" onClick={() => window.open(props.url, '_blank')}>
                <FontIcon name="ic-export" size="20px" color="#525252" />
              </div>
            )}
          </div>
        </div>
        {checkUrl && !urlValid && <InputError message={i18next.t('입력된 URL과 연결된 페이지를 찾을 수 없습니다.')} />}
      </div>
    );
  },
);

BGUrlSelector.defaultProps = {
  url: 'http://',
  placeholder: i18nextScanKey('링크의 URL 을 입력하세요.'),
};
