import { css } from '@emotion/react';

export const baseCss = css`
  width: 100%;
  height: fit-content;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0a0f32;
  background-size: cover;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

  .banner-graphic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .flex {
    z-index: 1;
    display: flex;
    align-items: center;
  }
  .success-back-ground-color {
    background-color: #27c28a;
  }
  .primary-back-ground-color {
    background-color: #006fff;
  }
  .primary-color {
    color: #006fff;
  }
  .img-wrapper {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    img {
      width: 24px;
      height: 24px;
    }
    .oval {
      width: 8px;
      height: 8px;
      border: solid 2px #000a29;
      border-radius: 50%;
      background-color: #e65c5c;
      position: absolute;
      top: -4px;
      right: -5px;
    }
  }
  .banner-text {
    font-size: 14px;
    color: #fff;
    margin: 0 16px 0 8px;
  }
  .link-comment {
    font-size: 14px;
    font-weight: bold;
    margin-right: 16px;
    cursor: pointer;
    white-space: nowrap;
  }
  .delete-icon-wrapper {
    cursor: pointer;
    z-index: 1;
  }
`;
