import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, Theme, useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { baseCss } from './CampaignAdsTable.style';
import { FontIcon } from '../FontIcon/FontIcon';
import { getUniqKey } from '../../utils/array.utils';
import { BGEmpty } from '../BGEmpty/BGEmpty';
// eslint-disable-next-line import/no-cycle
import { AdTableRow } from '../../pages/CampaignReport/ReportItem/AdTableRow';
import { ColumnPicker } from '../ColumnPicker/ColumnPicker';
import { AllColumnType } from '../../types/BGEventDef';
import { StyledDefaultEmptyOverlayComponent } from '../CampiagnListTable/CampaignListTable.style';

export interface CampaignAdsTableHeader {
  key: string;
  displayName: string;
  dataFormat?: string;
  getGroupDataCellElement: (key: string, item: any) => JSX.Element;
  getDataCellElement: (key: string, campaignType: CampaignType, item: any, toggle?: boolean) => JSX.Element;
  getTargetCellElement: (key: string, uniAd: UniAd, item: any, meida: string) => JSX.Element;
  handleSort?: (key: string) => void;
  headerStyle?: object;
  cellStyle?: object;
  skeletonComponent?: () => JSX.Element;
}

export interface CampaignAdsTableProps {
  // pinnedHeader: CampaignListTableHeader[];
  headers: CampaignAdsTableHeader[];
  lists: UniAdsByType[];
  isLoaded: boolean;

  // TODO: 개발 후 추가
  // isEmpty: boolean;
  // isSearch: boolean;
  // isError: boolean;
  // onClickTableRow?: (item: UnifiedCampaign) => void;

  containerStyle?: object;
  tableStyle?: object;
  defaultHeaderStyle?: object;
  defaultRowStyle?: object;
  defaultCellStyle?: (item: UnifiedCampaign) => object;
  filterValue: string;
  gridColumns: AllColumnType[];
  handleGridColumnsUpdate?: (gridColumns: AllColumnType[]) => void;
}
export const CampaignAdsTable = (props: CampaignAdsTableProps) => {
  const i18next = useTranslation();
  const theme: Theme = useTheme();
  const { containerStyle = {}, tableStyle = {}, defaultHeaderStyle = {} } = props;

  const setAdFilterData = (ads: UniAd[]) => {
    let result = [] as UniAd[];
    if (props.filterValue !== 'CAMPAIGN_STATUS_ALL') {
      result = ads.filter((unifiedCampaign) => unifiedCampaign.status_tag.code === props.filterValue);
    } else {
      result = ads;
    }
    return result;
  };
  const renderSkeleton = () => {
    const result = [];
    for (let i = 0; i < 10; i += 1) {
      result.push(
        <tr className="bg-list-table-row" key={getUniqKey('bg-list-table-skeleton-row', i)}>
          {props.headers.map((header: CampaignAdsTableHeader, cellIndex) => {
            const { cellStyle = {} } = header;
            return (
              <td
                key={getUniqKey('bg-list-table-data-skeleton-cell', cellIndex)}
                css={css`
                  .skeleton {
                    border-radius: 0;
                  }
                `}
                style={{ padding: '16px', ...cellStyle }}
              >
                {header?.skeletonComponent ? (
                  header.skeletonComponent()
                ) : (
                  <Skeleton className="skeleton" style={{ width: '100%' }} />
                )}
              </td>
            );
          })}
        </tr>,
      );
    }
    return result;
  };

  return (
    <div className="campiagn-dashboard-table-container" css={[baseCss]} style={{ ...containerStyle }}>
      <div
        className="column-picker-wrapper"
        style={{
          width: '43px',
          height: '43px',
          position: 'absolute',
          right: '0',
          backgroundColor: theme.colors.bg1,
          zIndex: '5',
        }}
      >
        {props.gridColumns && props.handleGridColumnsUpdate && (
          <ColumnPicker
            columns={props.gridColumns}
            handleUpdate={props.handleGridColumnsUpdate}
            customCss={css`
              border-bottom: solid 1px #b8beca !important;
            `}
          />
        )}
      </div>
      <div className="ad-list-table-wrapper">
        <table className="ad-list-table" style={{ ...tableStyle }}>
          <thead>
            <tr>
              {props.headers.map((header, index) => {
                const { headerStyle = {} } = header;
                return (
                  <th key={getUniqKey('ad-list-table-header', `${header.key}-${index}`)}>
                    <div className="ad-list-table-header-wrapper" style={{ ...defaultHeaderStyle, ...headerStyle }}>
                      {header.displayName}
                      {header.handleSort && (
                        <FontIcon
                          className="header-sort-icon"
                          name="ic-asc"
                          size="20px"
                          color="#7e8696"
                          handleClick={() => {
                            if (header.handleSort) {
                              header.handleSort(header.key);
                            }
                          }}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={props.isLoaded && props.lists.length === 0 ? 'empty-body' : ''}>
            {!props.isLoaded && renderSkeleton()}
            {props.isLoaded &&
              props.lists.length > 0 &&
              props.lists.map((item: UniAdsByType, groupRowIndex) => {
                return (
                  <React.Fragment key={getUniqKey('ad-group-row-container', groupRowIndex)}>
                    {setAdFilterData(item.uni_ads).length > 0 && (
                      <React.Fragment>
                        <tr
                          className="ad-group-row"
                          css={css`
                            .skeleton {
                              background-color: #c9cfda !important;
                            }
                          `}
                        >
                          <td className="ad-group-cell">
                            <React.Fragment>
                              {props.headers[0].getGroupDataCellElement(
                                getUniqKey('ad-group-stats', groupRowIndex),
                                item,
                              )}
                            </React.Fragment>
                          </td>
                          {props.headers.map((header: CampaignAdsTableHeader, cellIndex) => {
                            const { cellStyle = {}, key } = header;

                            if (cellIndex === 0)
                              return (
                                <React.Fragment
                                  key={getUniqKey('ad-list-table-group-cell', `${groupRowIndex}-${cellIndex}`)}
                                />
                              );
                            return (
                              <td
                                key={getUniqKey('ad-list-table-group-cell', `${groupRowIndex}-${cellIndex}`)}
                                // style={{ ...defaultCellStyle(item), ...cellStyle }}
                                style={{ ...cellStyle }}
                              >
                                <React.Fragment>{header.getGroupDataCellElement(key, item)}</React.Fragment>
                              </td>
                            );
                          })}
                        </tr>
                        {setAdFilterData(item.uni_ads).map((ad, rowIndex) => (
                          <AdTableRow
                            headers={props.headers}
                            defaultRowStyle={props.defaultRowStyle}
                            campaignType={item.type}
                            ad={ad}
                            key={getUniqKey('ad-list-table-row', rowIndex)}
                          />
                        ))}
                      </React.Fragment>
                    )}
                    {props.isLoaded && setAdFilterData(item.uni_ads).length === 0 && (
                      <div className="empty-container">
                        <StyledDefaultEmptyOverlayComponent>
                          <div style={{ width: '310px' }}>
                            <BGEmpty type="default" title={i18next.t('조건에 맞는 소재가 없습니다.')} />
                          </div>
                        </StyledDefaultEmptyOverlayComponent>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
