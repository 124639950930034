import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    border: solid 1px #dce0e8;
    box-shadow: 0 4px 24px 0 rgba(79, 86, 97, 0.05);
    //padding: 0 24px 24px;
    position: relative;

    .help-panel {
      background-color: #333344;

      .help-desc {
        color: #ffffff;
      }
    }

    .shadow {
      box-shadow: 0 4px 24px 0 rgba(79, 86, 97, 0.05);
    }

    .plan-detail-table {
      align-items: flex-start;
      border-radius: 8px;
      background-color: #ffffff;

      .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .table-detail-item {
        width: 33.33%;

        .table-title-category {
          ${theme.text.h2};
          color: ${theme.colors.mainFont};
          text-align: center;
          border-bottom: solid 1px #ebeef4;
          min-height: 64px;
          padding-left: 40px;
          padding-right: 40px;

          &.property {
            height: auto;
            display: flex;
            align-items: center;
            text-align: left;
            border-bottom: 1px solid #fff;
            margin-top: 10px;
          }

          &.sticky {
            background: #fff;
            position: sticky;
            top: 76px;
            z-index: 16;
          }
        }

        .table-detail-list {
          :not(:last-child) {
            margin-bottom: 30px;
          }

          .hidden {
            display: none;
          }

          .table-detail-title {
            ${theme.text.input};
            min-height: 44px;
            border-bottom: solid 1px #ebeef4;
            display: flex;
            align-items: center;
            color: ${theme.colors.subFont};
            &.first-column {
              position: relative;
              margin-left: 20px;
              padding-left: 20px;
              height: 44px;
              color: ${theme.colors.mainFont};

              &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0%, -50%);
                height: 20px;
              }
            }

            &.last-column {
              margin-right: 20px;
              padding-left: 20px;
            }
            &.custom {
              padding-right: 0;
            }

            &.disabled {
              color: #bbbbcc;
            }

            &.bold {
              font-weight: bold;
            }
            .default-icon {
              width: 14px;
              height: 1px;
              top: 50%;
              left: 3px;
              background: #7e8696;
            }
          }
        }
      }
    }

    .plan-detail-info {
      margin: 24px;
      justify-content: flex-end;
    }
  `;
};
