import { css } from '@emotion/react';

export const baseCss = css`
  & * {
    box-sizing: border-box;
    font-weight: normal;
    letter-spacing: normal;
  }

  width: auto;
  height: 48px;
  padding-left: 12px;
  padding-right: 8px;
  cursor: pointer;
  border-radius: 8px;
  border: solid 1px #dce0e8;
  background-color: #f9fafb;
  position: relative;
  display: inline-block;
  transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);

  &.empty {
    padding-left: 8px;
    padding-right: 8px;
    background-color: #ffffff;
    border: solid 1px #d4d9e2;
  }

  &:hover {
    border: solid 1px #b8beca;
    //box-shadow: 0 4px 8px -2px #d1d7dd;
  }

  &.active {
    border: solid 1px #b8beca;
  }

  .bg-calendar-display {
    width: 100%;
    height: 100%;
    padding: 0;
    cursor: pointer;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .bg-calendar-display-label {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #53585f;
      margin-right: 4px;
    }

    .bg-calendar-display-inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 8px;
      .bg-calendar-current-date {
        font-weight: normal;
        font-size: 14px;
        color: #53585f;
        white-space: nowrap;
      }
    }
    .font-icon-panel {
      transform: rotate(90deg);
    }
  }

  .position-bottom {
    z-index: 10;
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 8px;
  }

  .position-top {
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 100%;
    margin-top: 12px;
  }

  .bg-calendar-selector-panel {
    cursor: default;
    background-color: white;
    width: 450px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .bg-calendar-control-panel {
      padding: 16px;
      height: 100%;
      flex-grow: 2;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .bg-calendar-control-btn {
        cursor: pointer;
        width: 120px;
        height: 36px;
        font-size: 14px;
        font-weight: bold;
        color: #767676;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span {
          line-height: 1.43;

          margin-left: 12px;
        }
        &.select {
          border-radius: 8px;
          background-color: #edf1f6;
        }
      }
    }
    .bg-calendar-display-panel {
      flex-grow: 5;
      border-left: solid 1px #eeeeee;
      .bg-calendar-display-header {
        margin-top: 12px;
        margin-left: 15px;
        .bg-calendar-display-header-label {
          font-size: 12px;
          font-weight: bold;
          letter-spacing: normal;
          color: #656872;
        }
        .bg-calendar-display-header-value {
          line-height: 1.25;
          font-size: 16px;
          font-weight: bold;
          margin-top: 4px;
          letter-spacing: normal;
          color: #53585f;
        }
      }
      .bg-calendar-display-content {
        .bg-calendar-display-content-day-panel {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 10px;
          margin-right: 10px;
          margin-top: 4px;
          .bg-calendar-display-content-day {
            width: 32px;
            height: 32px;
            font-size: 12px;
            font-weight: normal;
            color: #696c7d;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .bg-calendar-month-panel {
          height: 320px;
          overflow: hidden;
          border-top: solid 1px #eeeeee;
          border-bottom: solid 1px #eeeeee;
          .ReactVirtualized__Grid__innerScrollContainer {
            > div {
              display: flex;
              justify-content: center;
              align-items: center;
              .bg-calendar-display-month-detail {
                width: 100%;
              }
            }
          }
        }
        .bg-calendar-select-panel {
          width: 100%;
          height: 75px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          .bg-calendar-select-cancel {
            cursor: pointer;
            width: 70px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #333443;
            margin-right: 7px;
          }
          .bg-calendar-select-apply {
            cursor: pointer;
            width: 70px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 16px;
            color: #ffffff;
            border-radius: 8px;
            box-shadow: 0 4px 16px -4px rgba(62, 82, 204, 0.4);
          }
        }
        .bg-calendar-display-month {
          margin: 4px 18px;
          font-size: 14px;
          font-weight: normal;
          color: #53585f;
        }
        .bg-calendar-display-date {
          font-size: 12px;
          font-weight: normal;
          color: #696c7d;
          .bg-week {
            margin-top: 4px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .bg-date {
              &:first-of-type {
                padding-left: 10px;
              }
              &:last-of-type {
                padding-right: 10px;
              }
              display: flex;
              justify-content: center;
              align-items: center;
              flex-grow: 1;
              position: relative;
              .bg-inner-start {
                position: absolute;
                width: 50%;
                height: 32px;
                z-index: 0;
                right: 0;
                &.highlight {
                  background-color: #f7f7f9;
                }
                &.active {
                  background-color: #edf1f6;
                }
              }
              .bg-inner-end {
                position: absolute;
                width: 50%;
                height: 32px;
                left: 0;
                &.highlight {
                  background-color: #f7f7f9;
                }
                &.active {
                  background-color: #edf1f6;
                }
              }
              &.highlight {
                background-color: #f7f7f9;
              }
              &.active {
                background-color: #edf1f6;
              }
              &.hide {
                opacity: 0;
              }

              .bg-inner-date {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                width: 32px;
                height: 32px;
                z-index: 10;
                &.highlight {
                  color: white;
                  background-color: #d9dbe7;
                }
                &.active {
                }
                &.hide {
                  opacity: 0;
                  cursor: default;
                }
                &.today {
                  border: solid 1px #00c4ff;
                }
                &.disabled {
                  color: #dddddd;
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }
  }
`;
