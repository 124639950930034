import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .kk-asset-modal-header {
      margin-bottom: 20px;

      & > *:not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .kk-asset-modal-body {
      margin-bottom: 20px;

      .form-input-group {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        & > *:not(:last-child) {
          margin-bottom: 8px;
        }

        .form-input-label {
          ${theme.text.h4};
          color: ${theme.colors.mainFont};
        }
      }

      // BGSelectBox가 disabled처리 되어도 UI상 변경 없도록 하는 꼼수
      .select-wrapper {
        .gray.disabled .material-dropdown-selected-item-wrapper {
          border: solid 1px #dce0e8 !important;
          background-color: #f9fafb !important;
        }
        .gray.disabled .material-dropdown-item.gray {
          background-color: #f9fafb !important;
          opacity: initial !important;
        }
      }
    }
    .kk-asset-modal-footer {
      display: flex;
      justify-content: end;
    }
  `;
};
