import React, { useState } from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, useTheme, Theme } from '@emotion/react';
import { Link, useMatch } from 'react-router-dom';
import { MenuButtonStyle } from './MenuButton.style';
import { MenuSubButton } from '../MenuSubButton/MenuSubButton';
import { BGNewAlarm } from '../BGNewAlarm/BGNewAlarm';
import { FontIcon, FontIconName } from '../FontIcon/FontIcon';

export interface MenuButtonProps {
  label: string;
  image: string;
  activeImage?: string;
  to?: string;
  menu?: Array<{ label: string; id: number; link: string }>;
  childMenu?: Array<{ id: number; link: string }>;
  rootMenu?: any;
  isActiveAlarm?: boolean;
  style?: Object;
  handleOnClick?: () => void;
  isSmall?: boolean;
  externalLink: boolean;
}

const styleCss = MenuButtonStyle;

export const MenuButton: React.FC<MenuButtonProps> = (props: MenuButtonProps) => {
  const match = useMatch({
    path: `${props.to}/*`,
  });
  const theme: Theme = useTheme();
  const themeCss = css`
    background-color: ${theme.colors.bg1};
    span,
    .sub-button-panel button {
      color: ${theme.colors.subFont};
    }
  `;
  const [isOver, setIsOver] = useState<boolean>(false);
  const propsObj: MenuButtonProps = props;

  const linkButton = () => {
    return (
      <button
        type="button"
        className="icon-button-item"
        onClick={() => {
          if (props.handleOnClick) {
            props.handleOnClick();
          }
        }}
        onMouseOver={() => {
          setIsOver(true);
        }}
        onMouseLeave={() => {
          setIsOver(false);
        }}
        onFocus={() => true}
      >
        {propsObj.image && (
          <BGNewAlarm isActiveAlarm={propsObj?.isActiveAlarm || false} wrapperStyle={{ marginRight: '10px' }}>
            <FontIcon name={propsObj.image as FontIconName} color={match ? '#3D4046' : '#7E8696'} size="20px" />
          </BGNewAlarm>
        )}
        <div className="icon-button-content">
          {!props.isSmall && propsObj.label && <span>{propsObj.label}</span>}
          {props.externalLink && isOver && <FontIcon name="ic-export" color="#7e8696" size="20px" />}
        </div>
      </button>
    );
  };

  return (
    <div
      className={`icon-button-panel ${match ? 'active' : ''}`}
      css={[styleCss, themeCss]}
      style={propsObj.style ? propsObj.style : {}}
    >
      {propsObj.externalLink ? (
        <a
          target="_blank"
          href={propsObj.menu && propsObj.menu.length > 0 ? propsObj.menu[0].link : propsObj.rootMenu.link}
          rel="noreferrer"
        >
          {linkButton()}
        </a>
      ) : (
        <Link
          to={propsObj.menu && propsObj.menu.length > 0 ? propsObj.menu[0].link : propsObj.rootMenu.link}
          style={{ textDecoration: 'none' }}
        >
          {linkButton()}
        </Link>
      )}

      {!props.isSmall &&
        propsObj.menu &&
        propsObj.menu.map((menuItem: { label: string; id: number; link: string }) => (
          <MenuSubButton
            id={menuItem.id}
            link={menuItem.link}
            label={menuItem.label}
            key={menuItem.id}
            handleOnClick={props?.handleOnClick}
          />
        ))}
    </div>
  );
};
