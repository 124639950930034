import { css, useTheme, Theme } from '@emotion/react';
import { GS } from '../../../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .horizontal-line {
      width: 100%;
      height: 1px;
      background-color: #ebeef4;
    }

    .create-section-header-wrapper {
      padding: ${GS.gutter}px;
      width: 100%;
      border-bottom: 1px solid ${theme.colors.divider};

      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .icon-wrap {
          display: flex;
          gap: 8px;

          .icon {
            width: 20px;
            height: 20px;
          }
        }

        .title {
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.56;
          letter-spacing: normal;
          color: ${theme.colors.mainFont};
          margin-left: 4px;
          width: 100%;
        }
      }
    }

    .create-section-content {
      display: flex;
      justify-content: flex-start;
      position: relative;
      min-height: 500px;
      padding: 0;
      width: 100%;

      .preview-container {
        width: calc(100% - 400px);
        padding: 0 ${GS.gutter}px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .title-panel {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 20px;
          padding-bottom: 16px;

          .title {
            ${theme.text.h3}
            color: ${theme.colors.mainFont};
          }
          .info {
            ${theme.text.disclaimer}
          }
        }

        .material-preview-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          border: solid 1px #dce0e8;
          background-color: #f9fafb;
          /* margin-bottom: 60px; */

          .material-preview {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 588px;
            height: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;

            .preview-media-title-wrapper {
              padding-bottom: 16px;
              ${theme.text.h4};
              color: ${theme.colors.mainFont};
            }

            .facebook-title-box {
              padding: 20px 20px 8px 16px;
              display: flex;
              gap: 8px;

              &.instagram {
                padding-bottom: 16px;
              }

              .logo {
                width: 42px;
                height: 42px;
                border-radius: 50%;
                border: solid 1px #edf1f6;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
            }

            .facebook-wrapper {
              padding: 20px;
            }
            .instagram-wrapper {
              border-left: 1px solid ${theme.colors.divider};
              padding: 20px;
            }
          }
        }
      }

      .form-container {
        border-left: 1px solid ${theme.colors.divider};
        width: 400px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 20px;

        .material-field-header {
          ${theme.text.h4}
          color: ${theme.colors.mainFont};
          margin: 0;
          padding: 16px 20px 20px;
        }

        .material-field-selector-wrapper {
          width: 100%;
          height: auto;
          padding: 0 20px 20px;

          .description {
            margin-top: 8px;
            ${theme.text.disclaimer}
          }

          .material-field-input-wrapper {
            position: relative;

            textarea {
              height: 49px;
            }
          }

          .material-dropdown.gray {
            border-radius: 8px;

            .material-dropdown-item.gray {
              border-radius: 8px;
            }
          }
        }
      }
    }

    .content-upload-container {
      width: 100%;
      height: auto;
      border-radius: 8px;
      border: solid 1px #dce0e8;
      background-color: #f9fafb;
      overflow: hidden;
    }

    .uploader-wrapper {
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .image-uploader-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }

      .image-uploader-subtitle {
        // view
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > div {
          //text
          ${theme.text.disclaimer}
        }
      }
    }

    .single-image-uploader-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .multiple-image-uploader-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding: 16px;
      border-radius: 8px;
      background-color: ${theme.colors.bg3};
      position: relative;
      border: 1px solid ${theme.colors.divider};

      .delete-icon {
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 10;
      }

      .multiple-image-heading {
        ${theme.text.h4}
        color: ${theme.colors.mainFont};
        margin: 0;
      }

      .multiple-image-uploader-item-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .multiple-image-url-selector-wrapper {
          width: 100%;
        }
        .file-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;

          .file-name {
            font-size: 14px;
            line-height: 1.43;
            color: #3d4046;
            font-weight: bold;
          }
        }
      }
    }
  `;
};

export const smallButtonCss = css`
  // view
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dce0e8;
`;
