import { atom } from 'recoil';
import { Properties, TemplateFormList } from '../../lib/api/AssetsApi';

const selectedTemplate = atom<any>({
  key: 'selectedTemplate',
  default: {},
});
const templateForm = atom<Properties[]>({
  key: 'templateForm',
  default: [],
});
const templateFormList = atom<TemplateFormList[]>({
  key: 'templateFormList',
  default: [],
});

export default {
  selectedTemplate,
  templateForm,
  templateFormList,
};
