import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSRadioButton, BDSButton } from '@bigin/bigin-ui-components';
import { useForm } from 'react-hook-form';

import { baseCss } from './Step1.style';
import { InputError } from '../../../components/InputError/InputError';
import { BGButtonGroup } from '../../../components/BGButtonGroup/BGButtonGroup';

enum YesOrNo {
  YES = 'yes',
  NO = 'no',
}

export interface Step1Form {
  hasExperienced: boolean;
}

export interface Step1Props {
  prev: () => void;
  onSubmit: (form: Step1Form) => void;
}

export const Step1 = (props: Step1Props): ReactElement => {
  const i18next = useTranslation();

  const [hasExperienced, setHasExperienced] = useState<string | undefined>(undefined);
  const { formState, register, setValue, handleSubmit } = useForm<Step1Form>({ mode: 'all' });

  useEffect(() => {
    register('hasExperienced', {
      validate: (value: boolean | undefined) => {
        if (value === undefined) {
          return i18next.t('필수 입력란입니다.') as string;
        }

        return true;
      },
    });
  });

  return (
    <div css={[baseCss]}>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <div className="form-input-wrapper">
          <p className="form-label">{i18next.t('디지털 광고를 집행해 본 경험이 있으신가요?')}</p>
          <BDSRadioButton
            inputProps={{
              id: `hasExperienced[${YesOrNo.YES}]`,
              name: 'hasExperienced',
              value: YesOrNo.YES,
              checked: hasExperienced === YesOrNo.YES,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('hasExperienced', event.target.value === YesOrNo.YES, { shouldValidate: true });
                setHasExperienced(event.target.value);
              },
            }}
            style={{ marginBottom: '12px' }}
            labelText={i18next.t('네, 경험이 있어요.')}
          />
          <BDSRadioButton
            inputProps={{
              id: `hasExperienced[${YesOrNo.NO}]`,
              name: 'hasExperienced',
              value: YesOrNo.NO,
              checked: hasExperienced === YesOrNo.NO,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('hasExperienced', event.target.value === YesOrNo.YES, { shouldValidate: true });
                setHasExperienced(event.target.value);
              },
            }}
            style={{ marginBottom: '12px' }}
            labelText={i18next.t('아니요, 처음이에요.')}
          />
          {formState.errors.hasExperienced && (
            <InputError message={formState.errors.hasExperienced.message as string} />
          )}
        </div>
        <BGButtonGroup rightAlign>
          <BDSButton
            key={i18next.t('이전') as string}
            label={i18next.t('이전')}
            onClick={() => {
              props.prev();
            }}
            appearance="secondary"
          />
          <BDSButton
            key={i18next.t('다음') as string}
            label={i18next.t('다음')}
            isDisabled={!formState.isValid}
            type="submit"
          />
        </BGButtonGroup>
      </form>
    </div>
  );
};
