import axios, { AxiosPromise } from 'axios';
import { CurrenyCode } from '../../types/currency.d';
// import { getGroup, convertGroups } from '../../model/CampaignModel';
import { filters } from '../../utils/filter';

export interface CampaignSettingState {
  name: string;
  selectedTypes: string[];
  selectedGoal: CampaignGoal;
  ageRange: number[];
  gender: GenderType[];
  involvements: InvolvementType[];
  targetExclude: TargetExcludeType;
  revisitOptimize: RevisitOptimizeType;
  date: {
    start: string;
    end: string | null;
  };
  budget: number;
  autoCharge: boolean;
  chargeCredit: number;
  startOption: CampaignStartOption;
  isBiginConnected: boolean;
}

export interface CreateCampaignGroups {
  type: 'image' | 'video' | 'catalog' | 'dynamic' | 'search';
  ads: CampaignAdSettingState[];
  category_id?: number;
}

// const dynamicTypes: CampaignType[] = ['fb_dynamic', 'fb_dynamic_image', 'fb_dynamic_video', 'fb_dynamic_dynamic_video'];

// TODO: CampaignModel 에러
// function convertGroups(campaigns: CampaignModel[], type: 'create' | 'copy' = 'create') {
//   const groups = campaigns.reduce((acc, cur) => {
//     const { group } = cur;
//     const ads = [] as CampaignModel[];
//     const findGroup = acc.find((item) => item.type === group);

//     const campaignModel = new CampaignModel({
//       ...cur,
//       url: cur.url === '' ? undefined : cur.url,
//     });

//     if (type === 'create') {
//       if (dynamicTypes.includes(campaignModel.type)) {
//         const collection = new CampaignModel({
//           ...campaignModel,
//           fb_body: campaignModel.fb_dynamic_collection_body,
//           name: campaignModel.fb_dynamic_collection_name,
//           slides: [],
//           type: campaignModel.type === 'fb_dynamic' ? 'fb_dynamic_dynamic_video' : campaignModel.type,
//         });
//         const catalog = new CampaignModel({
//           ...campaignModel,
//           fb_body: campaignModel.fb_dynamic_catalog_body,
//           name: campaignModel.fb_dynamic_catalog_name,
//           slides: [],
//           url: undefined,
//           type: 'fb_dynamic_slide',
//         });
//         ads.push(...[collection, catalog]);
//       } else if (campaignModel.type === 'fb_catalog_slide') {
//         ads.push(
//           new CampaignModel({
//             ...campaignModel,
//             slides: [],
//           }),
//         );
//       } else {
//         ads.push(campaignModel);
//       }
//     } else if (type === 'copy') {
//       ads.push(campaignModel);
//     }

//     if (findGroup) {
//       findGroup.ads.push(...ads);
//     } else {
//       acc.push({
//         type: group,
//         ads: [...ads],
//         category_id: group === 'dynamic' ? (ads[0].category_id as number) : undefined,
//       });
//     }
//     return acc;
//   }, [] as { type: 'image' | 'video' | 'catalog' | 'dynamic'; ads: CampaignModel[]; category_id?: number }[]);
//   return groups;
// }

// export const getGroup = (type: CampaignType) => {
//   if (ImageGroupTypes.includes(type)) return 'image';
//   if (VideoGroupTypes.includes(type)) return 'video';
//   if (CatalogGroupTypes.includes(type)) return 'catalog';
//   if (DynamicGroupTypes.includes(type)) return 'dynamic';
//   return 'image';
// };

// export const convertGroups = (campaigns: CampaignAdSettingState[], type: 'create' | 'copy' = 'create') => {
//   const groups = campaigns.reduce((acc, cur) => {
//     const group = getGroup(cur.type);
//     const ads = [] as CampaignAdSettingState[];
//     const findGroup = acc.find((item) => item.type === group);

//     const campaignModel = {
//       ...cur,
//       url: cur.url === '' ? '' : cur.url,
//     };

//     if (type === 'create') {
//       if (dynamicTypes.includes(campaignModel.type)) {
//         const collection = {
//           ...campaignModel,
//           fb_body: campaignModel.fb_dynamic_collection_body,
//           name: campaignModel.fb_dynamic_collection_name,
//           slides: [],
//           type: campaignModel.type === 'fb_dynamic' ? 'fb_dynamic_dynamic_video' : campaignModel.type,
//         };
//         const catalog = {
//           ...campaignModel,
//           fb_body: campaignModel.fb_dynamic_catalog_body,
//           name: campaignModel.fb_dynamic_catalog_name,
//           slides: [],
//           url: '',
//           type: 'fb_dynamic_slide' as CampaignType,
//         };
//         ads.push(...[collection, catalog]);
//       } else if (campaignModel.type === 'fb_catalog_slide') {
//         ads.push({
//           ...campaignModel,
//           slides: [],
//         });
//       } else {
//         ads.push(campaignModel);
//       }
//     } else if (type === 'copy') {
//       ads.push(campaignModel);
//     }

//     if (findGroup) {
//       findGroup.ads.push(...ads);
//     } else {
//       acc.push({
//         type: group,
//         ads: [...ads],
//         category_id: group === 'dynamic' ? (ads[0].category_id as number) : undefined,
//       });
//     }
//     return acc;
//   }, [] as { type: 'image' | 'video' | 'catalog' | 'dynamic'; ads: CampaignAdSettingState[]; category_id?: number }[]);
//   return groups;
// };

export const CampaignApi = () => ({
  postUnifiedCampaign({
    mallId,
    plan,
    settings,
    country = 'KR',
    currency = 'KRW',
    // campaigns,
    groups,
    // type = 'create',
    config,
  }: {
    mallId: number;
    plan: string;
    settings: CampaignSettingState;
    campaigns: CampaignAdSettingState[];
    groups: CreateCampaignGroups[];
    currency?: CurrenyCode;
    country?: string;
    type?: 'create' | 'copy';
    config: any;
  }): AxiosPromise {
    const convertAge = {
      '0': 18,
      '20': 25,
      '40': 35,
      '60': 45,
      '80': 55,
      '100': 65,
    } as any;

    const postSettings = {
      plan,
      name: settings.name,
      goal: settings.selectedGoal,
      target_exclude: settings.targetExclude,
      revisit_optimize: settings.revisitOptimize,
      involvements: settings.involvements,
      daily_budget_e6: settings.budget * 1e6,
      currency,
      country,
      start_date: filters.formatYYYYMMDD(settings.date.start),
      age_min: convertAge[settings.ageRange[0]],
      age_max: convertAge[settings.ageRange[1]],
      genders: settings.gender,
      is_bigin_connected: settings.isBiginConnected,
    } as any;

    if (settings.date.end) {
      postSettings.end_date = filters.formatYYYYMMDD(settings.date.end);
    }

    return axios.post(
      `/malls/${mallId}/unified-campaigns`,
      {
        ...postSettings,
        groups,
      },
      config,
    );
  },
  // 캠페인 관리 하단 리스트
  getUnifiedCampaigns(
    mallId: number,
    since: string,
    until: string,
    page: number,
    perPage: number,
  ): AxiosPromise<{ data: UnifiedCampaign[] }> {
    return axios.get(`/malls/${mallId}/unified-campaigns`, {
      params: {
        since,
        until,
        page,
        per_page: perPage,
      },
    });
  },
  // 캠페인 관리 상단 요약
  getUnifiedCampaignsSummary(
    mallId: number,
    since: string,
    until: string,
    status_tag: string,
  ): AxiosPromise<{ data: UnifiedCampaignsSummary }> {
    return axios.get(`/malls/${mallId}/unified-campaigns/summary`, {
      params: {
        since,
        until,
        status_tag,
      },
    });
  },
  // 캠페인 상세 하단
  getUnifiedCampaign(
    mallId: number,
    unifiedCampaignId: number,
    since: string,
    until: string,
    sort: string,
  ): AxiosPromise<{
    data: UnifiedCampaign;
  }> {
    return axios.get(`/malls/${mallId}/unified-campaigns/${unifiedCampaignId}`, {
      params: {
        since,
        until,
        sort,
      },
    });
  },
  // 캠페인 상세 차트
  getUnifiedCampaignChartData(
    mallId: number,
    unifiedCampaignId: number,
    since: string,
    until: string,
  ): AxiosPromise<{
    data: UnifiedCampaignInsightData;
  }> {
    return axios.get(`/malls/${mallId}/unified-campaigns/${unifiedCampaignId}/insights`, {
      params: {
        since,
        until,
      },
    });
  },
  patchUnifiedCampaignBiginConnected(
    mallId: number,
    unifiedCampaignId: number,
    is_bigin_connected: boolean,
  ): AxiosPromise<{ data: UnifiedCampaign }> {
    return axios.patch(`malls/${mallId}/unified-campaigns/${unifiedCampaignId}`, { is_bigin_connected });
  },
  patchUnifiedCampaignStatus(
    mallId: number,
    unifiedCampaignId: number,
    status: 'active' | 'paused',
  ): AxiosPromise<{ data: UnifiedCampaign }> {
    return axios.patch(`malls/${mallId}/unified-campaigns/${unifiedCampaignId}`, { status });
  },
  patchUnifiedCampaignSetting(
    mallId: number,
    unifiedCampaignId: number,
    name: string,
    dailyBudget: number,
  ): AxiosPromise {
    return axios.patch(`malls/${mallId}/unified-campaigns/${unifiedCampaignId}`, {
      name,
      daily_budget_e6: dailyBudget,
    });
  },
  patchUnifiedCampaignBudget(
    mallId: number,
    unifiedCampaignId: number,
    dailyBudget: number,
  ): AxiosPromise<{ data: UnifiedCampaign }> {
    return axios.patch(`malls/${mallId}/unified-campaigns/${unifiedCampaignId}`, { daily_budget_e6: dailyBudget });
  },
  patchUnifiedCampaignName(mallId: number, unifiedCampaignId: number, name: string): AxiosPromise {
    return axios.patch(`malls/${mallId}/unified-campaigns/${unifiedCampaignId}`, { name });
  },
  patchUnifiedAdsStatus(
    mallId: number,
    unifiedAdsId: number,
    status: 'active' | 'paused',
  ): AxiosPromise<{ data: UniAd }> {
    return axios.patch(`malls/${mallId}/uni-ads/${unifiedAdsId}`, { status });
  },
  patchUnifiedCampiagnOperationStatus(
    mallId: number,
    unifiedCampaignId: number,
    status: boolean,
  ): AxiosPromise<{ data: UnifiedCampaign }> {
    return axios.patch(`malls/${mallId}/unified-campaigns/${unifiedCampaignId}`, {
      use_operation: status,
    });
  },
  patchUnifiedAdsOperationStatus(mallId: number, unifiedAdsId: number, status: boolean): AxiosPromise<{ data: UniAd }> {
    return axios.patch(`malls/${mallId}/uni-ads/${unifiedAdsId}`, {
      use_operation: status,
    });
  },

  addUnifiedCampaign(mallId: number, unifiedCampaignId: number, groups: CreateCampaignGroups[], config: any) {
    return axios.post(
      `malls/${mallId}/unified-campaigns/${unifiedCampaignId}/uni-ads`,
      {
        groups,
      },
      config,
    );
  },

  putUnifiedAds(mallId: number, unifiedAdsId: number, campaign: CampaignAdSettingState) {
    return axios.put(`malls/${mallId}/uni-ads/${unifiedAdsId}`, {
      ...campaign,
    });
  },
  getUnifiedCampaignInfo(
    mallId: number,
    unifiedCampaignId: number,
  ): AxiosPromise<{
    data: UnifiedCampaign;
  }> {
    return axios.get(`malls/${mallId}/unified-campaigns/${unifiedCampaignId}/info`);
  },
  patchFacebookAdTargetStatus(
    mallId: number,
    unifiedAdsId: number,
    targetAdId: number,
    status: boolean,
  ): AxiosPromise<{
    data: UnifiedCampaign;
  }> {
    return axios.patch(`malls/${mallId}/uni-ads/${unifiedAdsId}/facebook/${targetAdId}`, {
      is_use: status,
    });
  },
  patchGoogleAdTargetStatus(
    mallId: number,
    unifiedAdsId: number,
    targetAdId: number,
    status: boolean,
  ): AxiosPromise<{
    data: UnifiedCampaign;
  }> {
    return axios.patch(`malls/${mallId}/uni-ads/${unifiedAdsId}/google-ads/${targetAdId}`, {
      is_use: status,
    });
  },
  patchKakaoAdTargetStatus(
    mallId: number,
    unifiedAdsId: number,
    targetAdId: number,
    status: boolean,
  ): AxiosPromise<{
    data: UnifiedCampaign;
  }> {
    return axios.patch(`malls/${mallId}/uni-ads/${unifiedAdsId}/kakao/${targetAdId}`, {
      is_use: status,
    });
  },
});
