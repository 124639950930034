import { css } from '@emotion/react';

export const baseCss = () => {
  return css`
    width: 100%;

    .title-wrapper {
      width: 100%;
      padding: 20px;
      border-bottom: solid 1px #dce0e8;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .contents-wrapper {
      padding: 20px;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;

      &.dialog {
        max-height: 608px;
        overflow-y: scroll;
      }
    }
    .footer-button-wrapper {
      width: 100%;
      height: 76px;
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      border-top: solid 1px #ededf2;
    }
  `;
};

export const videoDetailCss = css`
  .detail-modal-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 20px;
  }
  .detail-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
`;
