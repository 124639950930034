import React, { ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { baseCss } from './PreviewGoogleItem.style';
import { getUniqKey } from '../../../../utils/array.utils';
import StorageService from '../../../../services/StorageService';
import { filters } from '../../../../utils/filter';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { i18nextScanKey } from '../../../../i18n';
import { IMAGE_PROXY_URL } from '../../../../utils/utils';
import { CurrencyCodeRecoil } from '../../../../recoil/Currency';

export interface PreviewGoogleItemProps {
  setting: CampaignAdSettingState;
  type?: number;
  products?: Product[];
}

export interface PreviewDynamicItemProps {
  cta: GoogleCTA;
  wrappedCTA?: boolean;
  product?: Product;
}

export interface PreviewGoogleTextItemProps {
  type: 'long-title' | 'description' | 'body';
  text: string;
  item:
    | 'aw_long_title'
    | 'aw_short_title'
    | 'aw_description'
    | 'aw_description_badge'
    | 'mall_name'
    | 'product_name'
    | 'price'
    | 'category_long_product_name'
    | 'category_long_price'
    | 'category_product_name'
    | 'category_price';
}

export interface SingleImageBoxProps {
  image: MallImage | ProductImage | null | undefined;
}

const googleCTALabel = {
  shop_now: i18nextScanKey('구매하기'),
  see_more: i18nextScanKey('더보기'),
  book_now: i18nextScanKey('예약하기'),
  learn_more: i18nextScanKey('자세히 알아보기'),
  sign_up: i18nextScanKey('가입하기'),
  visit_site: i18nextScanKey('사이트 방문하기'),
};

const getDefaultImage = (product: Product) => {
  const defaultImages = product.images.filter((image) => image.is_default);
  return defaultImages[0];
};

const PreviewSingleImageBox = ({ image }: SingleImageBoxProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <div className={`google-preview-img-box  ${image === null || image === undefined ? 'empty' : ''}`}>
      {image && <img src={`${IMAGE_PROXY_URL}${image.url}`} alt={i18next.t('이미지')} />}
      {(image === null || image === undefined) && <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />}
    </div>
  );
};

const PreviewTextItem = ({ type, text, item }: PreviewGoogleTextItemProps): ReactElement => {
  const i18next = useTranslation();

  if (text !== '') {
    return (
      <div className="text-line-clamp">
        <div className={`google-preview-${type}`}>
          {item.includes('badge') && <span className="badge">{i18next.t('광고')}</span>}
          {text.split('\n').map((bodyTxt, index) => {
            return (
              <span key={getUniqKey(bodyTxt, index)}>
                {bodyTxt}
                <br />
              </span>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className={`google-preview-${type}`}>
      {item === 'aw_long_title' && i18next.t('입력하신 긴 광고 제목이 여기에 보여집니다.')}
      {item === 'aw_short_title' && i18next.t('입력하신 광고 제목이 여기에 보여집니다.')}
      {item === 'aw_description' && i18next.t('입력하신 광고 문구가 여기에 보여집니다.')}
      {item === 'aw_description_badge' && (
        <React.Fragment>
          <span className="badge">{i18next.t('광고')}</span> {i18next.t('입력하신 광고 문구가 여기에 보여집니다.')}
        </React.Fragment>
      )}
      {item === 'mall_name' && i18next.t('쇼핑몰명')}
      {item === 'product_name' && i18next.t('상품명')}
      {item === 'price' && i18next.t('가격')}
      {item === 'category_long_product_name' && i18next.t('카테고리에 등록된 상품명이 여기에 보여집니다.')}
      {item === 'category_long_price' && i18next.t('해당 상품의 가격이 여기에 보여집니다.')}
      {item === 'category_product_name' && i18next.t('카테고리에 등록된 상품명')}
      {item === 'category_price' && i18next.t('상품의 가격')}
    </div>
  );
};

const PreviewDynamicItem = ({ cta, wrappedCTA = false, product }: PreviewDynamicItemProps): ReactElement => {
  const i18next = useTranslation();
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  return (
    <div className="google-preview-dynamic-remarketing-item">
      <PreviewSingleImageBox image={product ? getDefaultImage(product) : null} />
      <div className="google-preview-item-description-wrap" style={{ padding: 0 }}>
        <PreviewTextItem item="product_name" text={product && product?.name ? product?.name : ''} type="long-title" />
        <PreviewTextItem
          item="price"
          text={
            product && product?.sale_price_e6
              ? filters.formatCurrency({ value: product.sale_price_e6 / 1e6, currencyCode })
              : ''
          }
          type="description"
        />
        {wrappedCTA && (
          <div className="google-preview-action-link">
            <div className="google-preview-action-button">{cta && i18next.t(googleCTALabel[cta])}</div>
          </div>
        )}
      </div>
      {!wrappedCTA && (
        <div className="google-preview-action-link">
          <div className="google-preview-action-button">{cta && i18next.t(googleCTALabel[cta])}</div>
        </div>
      )}
    </div>
  );
};

const PreviewGoogleResponsiveImage = ({ setting }: PreviewGoogleItemProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <React.Fragment>
      <div className="google-preview-responsive">
        <div className="google-preview-item-wrapper responsive main">
          <div className="google-preview-item-image">
            <PreviewSingleImageBox image={setting?.mall_image || setting?.product_image} />
          </div>
          <div className="google-preview-item-description-wrap">
            <PreviewTextItem item="aw_long_title" text={setting?.aw_long_title} type="long-title" />
            <PreviewTextItem item="aw_description_badge" text={setting?.aw_description} type="description" />
            <div className="google-preview-action-link">
              <div className="google-preview-action-button">
                {setting?.aw_call_to_action && i18next.t(googleCTALabel[setting?.aw_call_to_action])}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="google-preview-responsive">
        <div className="google-preview-item-wrapper responsive type2">
          <div className="google-preview-content-container">
            <PreviewSingleImageBox image={setting?.aw_landscape_mall_image || setting?.aw_landscape_product_image} />
            <div className="google-preview-item-description-wrap" style={{ padding: '0 8px' }}>
              <PreviewTextItem item="aw_short_title" text={setting?.aw_short_title} type="long-title" />
            </div>
          </div>
          <div className="google-preview-action-wrap">
            <div className="logo">
              {StorageService.selectedMall?.square_logo_url ? (
                <img src={StorageService.selectedMall?.square_logo_url} alt={StorageService.selectedMall?.name} />
              ) : (
                ''
              )}
            </div>
            <PreviewTextItem item="aw_description" text={setting?.aw_description} type="description" />
            <BDSFontIcon name="ic-arrow-right" color="#7e8696" size="20px" />
          </div>
        </div>
      </div>

      {/* 구분 */}
    </React.Fragment>
  );
};

const PreviewGoogleShopping = ({ setting, type }: PreviewGoogleItemProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <React.Fragment>
      {type === 1 && (
        <div className="google-preview-responsive">
          <div className="google-preview-item-wrapper responsive main">
            <div className="google-preview-item-image">
              <PreviewSingleImageBox image={setting?.mall_image || setting?.product_image} />
            </div>
            <div className="google-preview-item-description-wrap">
              <PreviewTextItem item="aw_long_title" text={setting?.aw_long_title} type="long-title" />
              <PreviewTextItem item="aw_description_badge" text={setting?.aw_description} type="description" />
              <div className="google-preview-action-link">
                <div className="google-preview-action-button">
                  {setting?.aw_call_to_action && i18next.t(googleCTALabel[setting?.aw_call_to_action])}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {type === 2 && (
        <div className="google-preview-shopping">
          <div className="google-preview-item-wrapper shopping">
            <div className="google-preview-header-wrap">
              <span className="empty" style={{ width: 24 }} />
              <img src="/assets/img/campaign/logo-google.svg" alt="Google" style={{ width: 74, height: 24 }} />
              <BDSFontIcon name="ic-user" color="#7E8696" size="20px" />
            </div>
            <div className="input-wrapper" style={{ marginTop: 8 }}>
              <BGInput
                inputProps={{
                  value: i18next.t('키워드') as string,
                }}
                leftLabelComponent={<BDSFontIcon name="ic-search" size="20px" color="#7E8696" />}
                style={{
                  width: 'auto',
                  height: '40px',
                  padding: '8px',
                  border: 'solid 1px #dce0e8',
                  borderRadius: '8px',
                }}
              />
            </div>
            <div className="google-preview-shopping-title">{i18next.t('광고')}</div>
            <div className="google-preview-shopping-item-wrap">
              <div className="google-preview-shopping-item">
                <div className="image-wrap">
                  <div className="google-preview-img-box gradient">
                    <img src="/assets/img/campaign/taggers-graphic.svg" alt={i18next.t('이미지')} />
                  </div>
                </div>
                <div className="google-preview-item-description-wrap">
                  <div className="google-preview-body">{i18next.t('상품명이 보여집니다.')}</div>
                  <div className="collapse-wrap">
                    <div className="google-preview-description" style={{ fontWeight: 'bold' }}>
                      {i18next.t('가격이 보여집니다.')}
                    </div>
                    <div className="google-preview-description">{i18next.t('배송비가 보여집니다.')}</div>
                    <div className="google-preview-body">{i18next.t('쇼핑몰명')}</div>
                  </div>
                </div>
                <div className="google-preview-quick-view-wrap">
                  <BDSFontIcon name="ic-eye" color="#7e8696" size="21px" />
                  {i18next.t('빠른 보기')}
                </div>
              </div>
              <div className="google-preview-shopping-item empty" />
              <div className="google-preview-shopping-item empty" />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const PreviewDynamicRemarketing = ({ setting, products }: PreviewGoogleItemProps): ReactElement => {
  const i18next = useTranslation();
  return (
    <React.Fragment>
      <div className="google-preview-dynamic">
        <div className="google-preview-item-wrapper responsive main">
          <div className="google-preview-item-image">
            <PreviewSingleImageBox image={setting?.mall_image || setting?.product_image} />
          </div>
          <div className="google-preview-item-description-wrap">
            <PreviewTextItem item="aw_long_title" text={setting?.aw_long_title} type="long-title" />
            <PreviewTextItem item="aw_description_badge" text={setting?.aw_description} type="description" />
            <div className="google-preview-action-link">
              <div className="google-preview-action-button">
                {setting?.aw_call_to_action && i18next.t(googleCTALabel[setting?.aw_call_to_action])}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="google-preview-dynamic">
        <div className="google-preview-item-wrapper dynamic collection-box">
          <div className="google-preview-content-container">
            {products &&
              products
                ?.slice(0, 3)
                .map((product) => <PreviewDynamicItem cta={setting?.aw_call_to_action} product={product} />)}
            {products &&
              products.length === 0 &&
              new Array(3)
                .fill('')
                .map(() => <PreviewDynamicItem cta={setting?.aw_call_to_action} product={undefined} />)}
          </div>
          <div className={`landscape-logo ${!StorageService.selectedMall?.landscape_logo_url ? 'empty' : ''}`}>
            {StorageService.selectedMall?.landscape_logo_url ? (
              <img src={StorageService.selectedMall?.landscape_logo_url} alt={StorageService.selectedMall?.name} />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const PreviewGooglePerformanceMax = ({ setting }: PreviewGoogleItemProps): JSX.Element => {
  const i18next = useTranslation();

  const [inputValue, setInputValue] = useState<string>(i18next.t('키워드'));

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  return (
    <div className="google-preformance-max">
      <div className="left-wrapper">
        <div className="google-preview-shopping">
          <div className="google-preview-item-wrapper shopping">
            <div className="google-preview-header-wrap">
              <span className="empty" style={{ width: 24 }} />
              <img src="/assets/img/campaign/logo-google.svg" alt="Google" style={{ width: 74, height: 24 }} />
              <BDSFontIcon name="ic-user" color="#7E8696" size="20px" />
            </div>
            <div className="input-wrapper" style={{ marginTop: 8 }}>
              <BGInput
                inputProps={{
                  value: inputValue,
                  onChange: handleChangeInput,
                }}
                leftLabelComponent={<BDSFontIcon name="ic-search" size="20px" color="#7E8696" />}
                style={{
                  width: 'auto',
                  height: '40px',
                  padding: '8px',
                  border: 'solid 1px #dce0e8',
                  borderRadius: '8px',
                }}
              />
            </div>
            <div className="google-preview-shopping-title">{i18next.t('광고')}</div>
            <div className="google-preview-shopping-item-wrap">
              <div className="google-preview-shopping-item">
                <div className="image-wrap">
                  <div className="google-preview-img-box gradient">
                    <img src="/assets/img/campaign/taggers-graphic.svg" alt={i18next.t('이미지')} />
                  </div>
                </div>
                <div className="google-preview-item-description-wrap">
                  <div className="google-preview-body">{i18next.t('상품명이 보여집니다.')}</div>
                  <div className="collapse-wrap">
                    <div className="google-preview-description" style={{ fontWeight: 'bold' }}>
                      {i18next.t('가격이 보여집니다.')}
                    </div>
                    <div className="google-preview-description">{i18next.t('배송비가 보여집니다.')}</div>
                    <div className="google-preview-body">{i18next.t('쇼핑몰명')}</div>
                  </div>
                </div>
                <div className="google-preview-quick-view-wrap">
                  <BDSFontIcon name="ic-eye" color="#7e8696" size="21px" />
                  {i18next.t('빠른 보기')}
                </div>
              </div>
              <div className="google-preview-shopping-item empty" />
              <div className="google-preview-shopping-item empty" />
            </div>
          </div>
        </div>
      </div>
      <div className="right-wrapper">
        <div className="google-preview-responsive">
          <div className="google-preview-item-wrapper responsive main">
            <div className="google-preview-item-image google-performance-max-preview-item-image">
              <PreviewSingleImageBox image={setting?.mall_image || setting?.product_image} />
            </div>
            <div className="google-preview-item-description-wrap">
              <PreviewTextItem item="aw_short_title" text={setting?.aw_pmax_headlines[0] ?? ''} type="long-title" />
              <PreviewTextItem
                item="aw_description_badge"
                text={setting?.aw_pmax_descriptions[0] ?? ''}
                type="description"
              />
              <div className="google-preview-action-link">
                <div className="google-preview-action-button">
                  {setting?.aw_pmax_call_to_action_selection &&
                    i18next.t(googleCTALabel[setting?.aw_pmax_call_to_action_selection])}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="google-preview-responsive">
          <div className="google-preview-item-wrapper responsive type2">
            <div className="google-preview-content-container google-performance-max-preview-item-image">
              <PreviewSingleImageBox image={setting?.aw_landscape_mall_image || setting?.aw_landscape_product_image} />
              <div className="google-preview-item-description-wrap" style={{ padding: '0 8px' }}>
                <PreviewTextItem
                  item="aw_long_title"
                  text={setting?.aw_pmax_long_headlines[0] ?? ''}
                  type="long-title"
                />
              </div>
            </div>
            <div className="google-preview-action-wrap">
              <div className="logo">
                {StorageService.selectedMall?.square_logo_url ? (
                  <img src={StorageService.selectedMall?.square_logo_url} alt={StorageService.selectedMall?.name} />
                ) : (
                  ''
                )}
              </div>
              <PreviewTextItem item="aw_description" text={setting?.aw_pmax_descriptions[0] ?? ''} type="description" />
              <BDSFontIcon name="ic-arrow-right" color="#7e8696" size="20px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PreviewGoogleSearch = (): JSX.Element => {
  const i18next = useTranslation();
  const [inputValue, setInputValue] = useState<string>(i18next.t('키워드'));

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };
  return (
    <div className="google-preview-search">
      <div className="google-preview-item-wrapper search">
        <div className="google-preview-header-wrap">
          <span className="empty" style={{ width: 24 }} />
          <img src="/assets/img/campaign/logo-google.svg" alt="Google" style={{ width: 74, height: 24 }} />
          <BDSFontIcon name="ic-user" color="#7E8696" size="20px" />
        </div>
        <div className="input-wrapper" style={{ marginTop: 8 }}>
          <BGInput
            inputProps={{
              value: inputValue,
              onChange: handleChangeInput,
            }}
            leftLabelComponent={<BDSFontIcon name="ic-search" size="20px" color="#7E8696" />}
            style={{
              width: 'auto',
              height: '36px',
              padding: '8px',
              border: 'solid 1px #dce0e8',
              borderRadius: '8px',
            }}
          />
        </div>
        <ul className="search-empty-box-list-wrapper">
          <li className="empty-box-item" />
          <li className="empty-box-item" />
          <li className="empty-box-item" />
          <li className="empty-box-item" />
          <li className="empty-box-item" />
        </ul>
        <div className="preveiw-main-wrapper">
          <div className="preview-search-box">
            <div className="preview-search-box-url-wrapper">
              <div className="preview-search-box-ad">{i18next.t('광고')}</div>
              <div className="preview-search-box-url">{i18next.t('입력하신 URL이 여기에 보여집니다.')}</div>
            </div>
            <ul className="preview-search-box-title-list">
              <li className="preview-search-box-title-item">Headline 1</li>
              <li className="preview-search-box-title-item">&nbsp;|&nbsp;Headline 2</li>
              <li className="preview-search-box-title-item">&nbsp;|&nbsp; Headline 3</li>
            </ul>
            <ul className="preview-search-box-description-list">
              <li className="preview-search-box-description-item">Description 1.&nbsp;</li>
              <li className="preview-search-box-description-item">Description 2.</li>
            </ul>
          </div>
          <ul className="preview-search-result-box">
            <li className="preview-search-result-empty-box" />
            <li className="preview-search-result-empty-box" />
            <li className="preview-search-result-empty-box" />
            <li className="preview-search-result-empty-box" />
            <li className="preview-search-result-empty-box" />
            <li className="preview-search-result-empty-box" />
            <li className="preview-search-result-empty-box" />
            <li className="preview-search-result-empty-box" />
          </ul>
        </div>
      </div>
    </div>
  );
};

export const PreviewGoogleItem = ({ setting, type, products }: PreviewGoogleItemProps): ReactElement => {
  return (
    <React.Fragment>
      <div css={[baseCss]} className="google-preview-item">
        {setting.type === 'aw_dynamic_remarketing' && (
          <PreviewDynamicRemarketing setting={setting} type={type} products={products} />
        )}
        {setting.type === 'aw_shopping' && <PreviewGoogleShopping setting={setting} type={2} />}
        {setting.type === 'aw_smart_shopping' && <PreviewGoogleShopping setting={setting} type={type} />}
        {setting.type === 'aw_responsive_image' && <PreviewGoogleResponsiveImage setting={setting} type={type} />}
        {setting.type === 'aw_search' && <PreviewGoogleSearch />}
        {setting.type === 'aw_pmax' && <PreviewGooglePerformanceMax setting={setting} />}
      </div>
    </React.Fragment>
  );
};
