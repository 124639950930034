import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .account-connect {
      width: 944px;
      min-width: fit-content;
      height: 100%;

      .title-wrapper {
        padding: 16px 16px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .title {
          ${theme.text.h2};
          color: ${theme.colors.mainFont};
          margin-right: 16px;
        }
      }
    }

    .skeleton {
      width: 80%;
      border-radius: 0;
    }
  `;
};

export const accountCardCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .account-info-card-wrapper {
      width: 360px;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding: 16px;
      border-radius: 8px;
      border: solid 1px #dce0e8;
      box-sizing: border-box;
      background-color: #fff;

      .name-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        ${theme.text.h2};
        color: ${theme.colors.mainFont};
        margin-bottom: 18px;

        .name {
          min-height: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .tooltip-icon-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 4px;
          cursor: default;
        }
      }

      .button-wrapper {
        display: block;
        position: absolute;
        top: 16px;
        right: 16px;
      }

      .table-wrapper {
        width: 100%;

        .row {
          display: flex;
          justify-content: space-between;
          padding-bottom: 12px;

          .item-title {
            ${theme.text.h5};
            color: ${theme.colors.subFont};
            display: flex;
            align-items: flex-start;
            gap: 4px;
          }

          .tooltip-wrapper {
            font-size: 0;
            cursor: pointer;
          }

          .item-content {
            word-break: break-all;
            width: calc(100% - 85px);
            text-align: right;
            ${theme.text.input};
            color: ${theme.colors.mainFont};

            &.currency-time-content {
              width: 160px;
              text-align: left;
              color: #3d4046;
              font-size: 14px;
              display: flex;
              gap: 4px;
              line-height: 20px;
              display: flex;
              flex-wrap: wrap;
            }
          }

          &.start {
            align-items: center;
          }

          &:last-child {
            padding-bottom: 4px;
          }
        }

        .row + .row {
          padding-top: 12px;
          border-top: 1px solid #dce0e8;
        }

        .row-horizontal-line {
          position: relative;
          left: -17px;
          width: 360px;
          height: 1px;
          background-color: #dce0e8;
          margin-bottom: 12px;
        }
      }

      .logo-wrapper {
        width: 100%;
        display: flex;
        padding: 4px 0 12px;

        .logo-square {
          width: 48px;
          height: 48px;
          border-radius: 4px;
          overflow: hidden;
          border: solid 1px #edf1f6;

          &.empty {
            background-color: #edf1f6;
            border: none;
          }

          .logo-font-icon-wrapper {
            padding: 14px;
          }
        }
        .logo-landscape {
          width: 192px;
          height: 48px;
          border-radius: 4px;
          margin-left: 8px;
          overflow: hidden;
          border: solid 1px #edf1f6;

          &.empty {
            background-color: #edf1f6;
            border: none;
          }

          .logo-font-icon-wrapper {
            padding: 14px 86px;
          }
        }
        .logo-img {
          width: 100%;
          height: 100%;
        }
      }

      .description {
        width: 100%;
        ${theme.text.disclaimer};
      }

      .secondary-button-wrapper {
        width: 100%;
        text-align: right;
        font-size: 0;

        .button-text {
          padding: 0;
          ${theme.text.disclaimer};
          border: 0;
          background: none;
          cursor: pointer;
        }
      }
    }
  `;
};
