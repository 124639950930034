import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();
  return css`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.5s cubic-bezier(0.33, 1, 0.68, 1);
    opacity: 0;

    &.visible {
      opacity: 1;
    }

    .modal-contents-wrapper {
      .modal-header {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-bottom: solid 1px #ededf2;
        color: ${theme.colors.mainFont};
        ${theme.text.h1};
      }
      width: 1320px;
      border-radius: 8px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px #ededf2;
      background-color: #ffffff;
      overflow: auto;
      max-height: 840px;
      z-index: 100;
      box-sizing: border-box;
    }
  `;
};

export const searchPaginationWrapperCss = css`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #ededf2;
  align-items: center;

  .search-wrapper {
    width: 260px;
    height: 100%;
    padding: 20px 20px 14px;
    display: inline-flex;
    flex-direction: column;
  }

  .pagination-item-wrapper {
    padding-right: 22px;

    display: flex;
    gap: 16px;

    .bg-pagination {
      margin: 0 !important;
    }
  }
`;

export const imageManageCss = css`
  .image-container {
    border-left: 1px solid #dce0e8;
    &.dialog {
      height: 603px;
      overflow-y: scroll;
    }
  }
  .footer-button-wrapper {
    width: 100%;
    height: 76px;
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    border-top: solid 1px #ededf2;
  }
`;
