import { css } from '@emotion/react';

export const baseCss = css`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 8px;
  border-radius: 8px;
  border: solid 1px #dce0e8;
  background-color: #f9fafb;
  transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);

  &:hover {
    border: solid 1px #39f;

    .font-icon-panel {
      color: #39f !important;
    }
  }
`;
