import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { BDSButton, BDSCheckbox } from '@bigin/bigin-ui-components';

import { baseCss } from './Step2Yes.style';
import { BGButtonGroup } from '../../../components/BGButtonGroup/BGButtonGroup';
import { BGInput } from '../../../components/BGInput/BGInput';

enum ExperiencedMedia {
  FB_IG = 'fb_ig',
  GOOGLE_ADS = 'google_ads',
  KAKAO_MOMENT = 'kakao_moment',
  NAVER_SEARCHAD = 'naver_searchad',
  ETC = 'etc',
}

export interface Step2YesForm {
  experiencedMedias: ExperiencedMedia[];
  experiencedEtcMedia: string;
}

export interface Step2YesProps {
  prev: () => void;
  onSubmit: (form: Step2YesForm) => void;
}

export const Step2Yes = (props: Step2YesProps): ReactElement => {
  const i18next = useTranslation();

  const [experiencedMedias, setExperiencedMedias] = useState<ExperiencedMedia[]>([]);
  const [experiencedEtcMedia, setExperiencedEtcMedia] = useState<string>();
  const { formState, register, setValue, handleSubmit } = useForm<Step2YesForm>({ mode: 'all' });

  useEffect(() => {
    register('experiencedMedias');
    register('experiencedEtcMedia');
  });

  const toggleExperiencedMedia = (experiencedMedia: ExperiencedMedia) => {
    const checked = experiencedMedias.indexOf(experiencedMedia) === -1;
    const news = experiencedMedias.filter((item) => item !== experiencedMedia);
    if (checked) {
      news.push(experiencedMedia);
    }

    setValue('experiencedMedias', news, { shouldValidate: true });
    setExperiencedMedias(news);
  };

  return (
    <div css={[baseCss]}>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <div className="form-input-wrapper">
          <p className="form-label">
            {i18next.t('어떤 매체에서 디지털 광고를 진행해보셨나요?\n경험이 있는 매체를 모두 선택해주세요.')}
          </p>
          {[
            ExperiencedMedia.FB_IG,
            ExperiencedMedia.GOOGLE_ADS,
            ExperiencedMedia.KAKAO_MOMENT,
            ExperiencedMedia.NAVER_SEARCHAD,
            ExperiencedMedia.ETC,
          ].map((experiencedMedia) => (
            <div className="checkbox-wrapper">
              <BDSCheckbox
                size={16}
                checked={experiencedMedias.indexOf(experiencedMedia) > -1}
                onChange={() => {
                  toggleExperiencedMedia(experiencedMedia);
                }}
              />
              <span
                className="text-input text-color-main ml-1"
                onClick={() => {
                  toggleExperiencedMedia(experiencedMedia);
                }}
              >
                {experiencedMedia === ExperiencedMedia.FB_IG && i18next.t('페이스북/인스타그램')}
                {experiencedMedia === ExperiencedMedia.GOOGLE_ADS && i18next.t('구글 광고')}
                {experiencedMedia === ExperiencedMedia.KAKAO_MOMENT && i18next.t('카카오 모먼트')}
                {experiencedMedia === ExperiencedMedia.NAVER_SEARCHAD && i18next.t('네이버 검색광고')}
                {experiencedMedia === ExperiencedMedia.ETC && i18next.t('기타')}
              </span>
            </div>
          ))}
          {experiencedMedias.includes(ExperiencedMedia.ETC) && (
            <BGInput
              inputProps={{
                type: 'text',
                placeholder: i18next.t('경험이 있는 매체를 모두 작성해주세요.'),
                value: experiencedEtcMedia,
                onChange: (event) => {
                  setValue('experiencedEtcMedia', event.target.value, { shouldValidate: true });
                  setExperiencedEtcMedia(event.target.value);
                },
              }}
            />
          )}
        </div>
        <BGButtonGroup rightAlign>
          <BDSButton
            key={i18next.t('이전') as string}
            label={i18next.t('이전')}
            onClick={() => {
              props.prev();
            }}
            appearance="secondary"
          />
          <BDSButton
            key={i18next.t('다음') as string}
            label={i18next.t('다음')}
            isDisabled={!formState.isValid}
            type="submit"
          />
        </BGButtonGroup>
      </form>
    </div>
  );
};
