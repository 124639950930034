import React, { ReactNode, useMemo, useEffect } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, Theme, useTheme } from '@emotion/react';
// import { observer } from 'mobx-react-lite';
import { useMatch } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { baseCss } from './Wrapper.style';
import SideNavBarRecoil from '../../recoil/SideNavBar';
import { SideNavBar } from '../SideNavBar/SideNavBar';
import StorageService from '../../services/StorageService';
import { CurrencyCodeRecoil } from '../../recoil/Currency';
// import { useDataStore } from '../../context/Store';

export interface WrapperProps {
  children: ReactNode | undefined;
  noPadding?: boolean;
}

export const Wrapper = (props: WrapperProps) => {
  const propsObj = props;
  const theme: Theme = useTheme();
  const sideNavBarIsCollapsed = useRecoilValue(SideNavBarRecoil.isCollapsed);
  const [currencyCode, setCurrencyCode] = useRecoilState(CurrencyCodeRecoil);

  const planMatch = useMatch({
    path: '/malls/:mallId/plan',
  });

  const wideMatch =
    useMatch('/malls/:mallId/create-campaign') ||
    useMatch('/malls/:mallId/creative') ||
    useMatch('/malls/:mallId/payment') ||
    useMatch('/malls/:mallId/create-image');

  const fullMatch = useMatch('/malls/:mallId/campaign-dashboard') || useMatch('/malls/:mallId/campaign-report');

  const themeCss = css`
    background-color: ${theme.colors.bg2};
  `;

  const matchWidthClass = useMemo(() => {
    if (planMatch) return 'plan';
    if (wideMatch) return 'wide';
    if (fullMatch) return 'full';
    return '';
  }, []);

  /**
   * Recoil currencyCode 세팅, Mall 객체 내의 currency로 구분해서 통화 표시
   */
  useEffect(() => {
    if (StorageService.selectedMall?.currency && StorageService.selectedMall?.currency !== currencyCode) {
      setCurrencyCode(StorageService.selectedMall?.currency);
    }
  }, [StorageService.selectedMall?.currency]);

  return (
    // todo : route guard 되기전 demo를 테스트하기 위해 <Redirect to={{ pathname: '/login' }} /> 코드 변경 hoyoung
    <div className={`Wrapper ${sideNavBarIsCollapsed ? 'side-nav-collapsed' : ''} ${matchWidthClass}`} css={[baseCss]}>
      <SideNavBar style={{ position: 'fixed' }} />
      <div className="top-nav-bar-panel" css={[themeCss]}>
        <div className={`wrapper-container ${props.noPadding ? 'no-padding' : ''}`}>{propsObj.children}</div>
      </div>
    </div>
  );
};
