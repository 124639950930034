import _ from 'lodash';
import moment from 'moment';
import { selector, selectorFamily } from 'recoil';
import atom from './atom';

const campaignGoalSelector = selector<CampaignGoal>({
  key: 'campaignGoal',
  get: ({ get }) => get(atom.campaignSetting).selectedGoal,
  set: ({ set }, goal) => {
    set(atom.campaignSetting, (prevState) => ({
      ...prevState,
      selectedGoal: goal as CampaignGoal,
    }));
  },
});

const targetGenderSelector = selector<GenderType | GenderType[]>({
  key: 'campaignTargetGender',
  get: ({ get }) => get(atom.campaignSetting).gender,
  set: ({ set }, gender) => {
    set(atom.campaignSetting, (prevState) => {
      const typeGender = gender as GenderType;
      let filteredGender = prevState.gender;
      if (prevState.gender.includes(typeGender)) {
        if (filteredGender.length >= 2) {
          filteredGender = filteredGender.filter((item) => item !== typeGender);
        }
      } else {
        filteredGender = [...prevState.gender, typeGender];
      }
      return {
        ...prevState,
        gender: filteredGender,
      };
    });
  },
});

const ageRangeSelector = selector<number[]>({
  key: 'campaignAgeRange',
  get: ({ get }) => get(atom.campaignSetting).ageRange,
  set: ({ set }, ageRange) => {
    set(atom.campaignSetting, (prevState) => {
      return {
        ...prevState,
        ageRange: ageRange as number[],
      };
    });
  },
});

const chargeCreditSelector = selector<number>({
  key: 'campaignChargeCredit',
  get: ({ get }) => get(atom.campaignSetting).chargeCredit,
  set: ({ set }, chargeCredit) => {
    set(atom.campaignSetting, (prevState) => {
      return {
        ...prevState,
        chargeCredit: chargeCredit as number,
      };
    });
  },
});

const campaignTypeSelector = selector<string[] | string>({
  key: 'campaignType',
  get: ({ get }) => get(atom.campaignSetting).selectedTypes,
  set: ({ set }, selectedType) => {
    set(atom.campaignSetting, (prevState) => {
      const type = selectedType as string;
      let filteredTypes = prevState.selectedTypes;
      if (prevState.selectedTypes.includes(type)) {
        filteredTypes = filteredTypes.filter((item) => item !== type);
      } else {
        filteredTypes = [...prevState.selectedTypes, type];
      }
      return {
        ...prevState,
        selectedTypes: filteredTypes,
      };
    });
  },
});

const campaignTypeResetSelector = selector<string[] | string>({
  key: 'campaignTypeReset',
  get: ({ get }) => get(atom.campaignSetting).selectedTypes,
  set: ({ set }) => {
    set(atom.campaignSetting, (prevState) => {
      return {
        ...prevState,
        selectedTypes: [],
      };
    });
  },
});

const campaignBudgetSelector = selector<number>({
  key: 'campaignBudget',
  get: ({ get }) => get(atom.campaignSetting).budget,
  set: ({ set }, budget) => {
    set(atom.campaignSetting, (prevState) => {
      const newBudget = (budget as number) || 0;
      return {
        ...prevState,
        budget: newBudget,
      };
    });
  },
});

const campaignStartOptionSelector = selector<CampaignStartOption>({
  key: 'campaignStartOption',
  get: ({ get }) => get(atom.campaignSetting).startOption,
  set: ({ set }, startOption) => {
    set(atom.campaignSetting, (prevState) => {
      const newStartOption = startOption as CampaignStartOption;
      return {
        ...prevState,
        startOption: newStartOption,
      };
    });
  },
});

const campaignRevisitOptimizeSelector = selector<RevisitOptimizeType>({
  key: 'campaignRevisitOptimize',
  get: ({ get }) => get(atom.campaignSetting).revisitOptimize,
  set: ({ set }, revisitOptimize) => {
    set(atom.campaignSetting, (prevState) => {
      const newRevisitOptimize = revisitOptimize as RevisitOptimizeType;
      return {
        ...prevState,
        revisitOptimize: newRevisitOptimize,
      };
    });
  },
});

const campaignTargetExcludeSelector = selector<TargetExcludeType>({
  key: 'campaignTargetExclude',
  get: ({ get }) => get(atom.campaignSetting).targetExclude,
  set: ({ set }, targetExclude) => {
    set(atom.campaignSetting, (prevState) => {
      const newTargetExclude = targetExclude as TargetExcludeType;
      return {
        ...prevState,
        targetExclude: newTargetExclude,
      };
    });
  },
});

const campaignInvolvementsSelector = selector<InvolvementType | InvolvementType[]>({
  key: 'camapignInvolvements',
  get: ({ get }) => get(atom.campaignSetting).involvements,
  set: ({ set }, involvement) => {
    set(atom.campaignSetting, (prevState) => {
      const typeInvolvement = involvement as InvolvementType;
      let filteredInvolvements = prevState.involvements;
      if (prevState.involvements.includes(typeInvolvement)) {
        filteredInvolvements = filteredInvolvements.filter((item) => item !== typeInvolvement);
      } else {
        // prevState.gender.push(typeGender);
        filteredInvolvements = [...prevState.involvements, typeInvolvement];
      }
      return {
        ...prevState,
        involvements: filteredInvolvements,
      };
    });
  },
});

const campaignStartDateSelector = selector<string>({
  key: 'campaignStartDate',
  get: ({ get }) => get(atom.campaignSetting).date.start,
  set: ({ set }, startDate) => {
    set(atom.campaignSetting, (prevState) => {
      const newStartDate = startDate as string;
      const date = { ...prevState.date };
      date.start = newStartDate;
      return {
        ...prevState,
        date,
      };
    });
  },
});

const campaignEndDateSelector = selector<string | null>({
  key: 'campaignEndDate',
  get: ({ get }) => get(atom.campaignSetting).date.end,
  set: ({ set }, endDate) => {
    set(atom.campaignSetting, (prevState) => {
      const newEndDate = endDate != null ? (endDate as string) : null;
      const date = { ...prevState.date };
      date.end = newEndDate;
      return {
        ...prevState,
        date,
      };
    });
  },
});

const campaignChargeAgreeSelector = selector<boolean>({
  key: 'campaignChargeAgree',
  get: ({ get }) => get(atom.campaignSetting).chargeAgree,
  set: ({ set }, chargeAgree) => {
    set(atom.campaignSetting, (prevState) => {
      const newChargeAgree = chargeAgree as boolean;
      return {
        ...prevState,
        chargeAgree: newChargeAgree,
      };
    });
  },
});

const campaignIsBiginConnectedSelector = selector<boolean>({
  key: 'campaignIsBiginConnected',
  get: ({ get }) => get(atom.campaignSetting).isBiginConnected,
  set: ({ set }, isBiginConnected) => {
    set(atom.campaignSetting, (prevState) => {
      const newIsBiginConnected = isBiginConnected as boolean;
      return {
        ...prevState,
        isBiginConnected: newIsBiginConnected,
      };
    });
  },
});

const addCampaignAdSettingSelector = selector<CampaignAdSettingState | CampaignAdSettingState[]>({
  key: 'addCampaignAdSettingSelector',
  get: ({ get }) => get(atom.campaignAdSetting),
  set: ({ set }, newAd: unknown) => {
    set(atom.campaignAdSetting, (prevState) => {
      let tempCampaigns = [] as CampaignAdSettingState[];
      const convertNewAd = newAd as CampaignAdSettingState;

      tempCampaigns = [...prevState];
      tempCampaigns.push(convertNewAd);

      return tempCampaigns;
    });
  },
});
const changeCampaignAdSettingSelector = selector<CampaignAdSettingState | CampaignAdSettingState[]>({
  key: 'changeCampaignAdSettingSelector',
  get: ({ get }) => get(atom.campaignAdSetting),
  set: ({ set }, newAd: unknown) => {
    set(atom.campaignAdSetting, () => {
      const tempCampaigns = [] as CampaignAdSettingState[];
      const convertNewAd = newAd as CampaignAdSettingState;

      tempCampaigns.push(convertNewAd);

      return tempCampaigns;
    });
  },
});
const campaignConvertCampaignAdSettingSelector = selector<CampaignAdSettingState | CampaignAdSettingState[]>({
  key: 'campaignConvertCampaignAdSettingSelector',
  get: ({ get }) => get(atom.campaignAdSetting),
  set: ({ set }, newAd: unknown) => {
    const convertAd = newAd as UniAd;

    set(atom.campaignAdSetting, (prevState) => {
      let tempCampaigns = [] as CampaignAdSettingState[];
      const categoryId = convertAd.fb_collection_category_id || convertAd.fb_dynamic_slide_category_id;
      const convertNewAd = {
        ...convertAd,
        type: convertAd.types[0],
        category_id: categoryId,
      } as unknown as CampaignAdSettingState;

      tempCampaigns = [...prevState];
      tempCampaigns.push(convertNewAd);

      return tempCampaigns;
    });
  },
});

const campaignAdSettingSelector = selector<CampaignAdSettingState[] | { types: string[]; goal: CampaignGoal }>({
  key: 'campaignAdSettingSelector',
  get: ({ get }) => get(atom.campaignAdSetting),
  set: ({ set }, selected: unknown) => {
    set(atom.campaignAdSetting, (prevState) => {
      const tempCampaigns = [] as CampaignAdSettingState[];
      const convertSelected = selected as { types: string[]; goal: CampaignGoal };

      const undefinedObject = {
        mall_video_id: undefined,
        mall_video: undefined,
        mall_image_id: undefined,
        mall_image: undefined,
        product_image_id: undefined,
        product_image: undefined,
        aw_landscape_mall_image: undefined,
        aw_landscape_mall_image_id: undefined,
        aw_landscape_product_image: undefined,
        aw_landscape_product_image_id: undefined,
        kk_banner_product_image: undefined,
        kk_banner_product_image_id: undefined,
        kk_banner_mall_image: undefined,
        kk_banner_mall_image_id: undefined,
        kk_native_product_image: undefined,
        kk_native_product_image_id: undefined,
        kk_native_mall_image: undefined,
        kk_native_mall_image_id: undefined,
      };

      const defaultState: CampaignAdSettingState = {
        type: '' as CampaignType,
        url: '',
        name: '',
        thumbnail_image: '',
        fb_body: '',
        fb_dynamic_collection_body: '',
        fb_dynamic_catalog_body: '',
        fb_single_description: '',
        fb_collection_title: '',
        fb_dynamic_slide_title: '',
        fb_dynamic_slide_description: '',
        fb_single_title: '',
        aw_short_title: '',
        aw_long_title: '',
        aw_description: '',
        aw_short_description: '',
        aw_youtube_url: '',
        aw_pmax_call_to_action_selection: 'shop_now',
        aw_pmax_descriptions: [],
        aw_pmax_headlines: [],
        aw_pmax_long_headlines: [],
        fb_call_to_action: 'shop_now',
        aw_call_to_action: 'shop_now',
        slides: [] as SlideContent[],
        bigin_keywords: [],
        kk_title: '',
        kk_profile_name: '',
        kk_alt_text: '',
        kk_action_button: 'LINK',
        ...undefinedObject,
      };

      const slideTypes: CampaignType[] = ['fb_slide_image', 'fb_catalog_slide', 'fb_dynamic', 'fb_dynamic_slide'];
      const today = moment().format('YYMMDD');

      convertSelected.types.forEach((campaignType, index) => {
        const convertedType = campaignType as CampaignType;
        const slides = slideTypes.includes(convertedType)
          ? [
              {
                src: '',
                url: '',
                title: '',
                description: '',
              },
              {
                src: '',
                url: '',
                title: '',
                description: '',
              },
            ]
          : [];

        const name = `${today}_${index + 1}`;

        if (campaignType === 'aw_shopping' || campaignType === 'aw_smart_shopping') {
          // 구글 쇼핑광고
          const usedCampaign = _.cloneDeep(
            prevState.find((campaign) => campaign.type === 'aw_smart_shopping' || campaign.type === 'aw_shopping'),
          );
          const shoppingType = convertSelected.goal !== 'maximize_clicks' ? 'aw_smart_shopping' : 'aw_shopping';
          if (usedCampaign) {
            usedCampaign.type = shoppingType;
            tempCampaigns.push(usedCampaign);
          } else {
            tempCampaigns.push({ ...defaultState, type: shoppingType, slides, name });
            // tempCampaigns.push(action.createCampaign(shoppingType));
          }
        } else if (campaignType.includes('fb_dynamic')) {
          // dynamic
          const usedCampaign = prevState.find((campaign) => campaign.type.includes('fb_dynamic'));
          if (usedCampaign) {
            tempCampaigns.push(usedCampaign);
          } else {
            tempCampaigns.push({ ...defaultState, type: convertedType, slides, name });
          }
        } else if (campaignType.includes('fb_collection')) {
          // collection
          const usedCampaign = prevState.find((campaign) => campaign.type.includes('fb_collection'));
          if (usedCampaign) {
            tempCampaigns.push(usedCampaign);
          } else {
            tempCampaigns.push({ ...defaultState, type: convertedType, slides, name });
          }
        } else {
          const usedCampaign = prevState.find((campaign) => campaign.type === campaignType);
          if (usedCampaign) {
            tempCampaigns.push(usedCampaign);
          } else {
            tempCampaigns.push({ ...defaultState, type: convertedType, slides, name });
          }
        }
      });

      return tempCampaigns;
    });
  },
});

const campaignAdSettingUpdateSelector = selector<CampaignAdSettingState>({
  key: 'updateCampaignAd',
  get: ({ get }) => get(atom.campaignAdSetting)[0],
  set: ({ get, set }, changeCampaign) => {
    const updateCampaign = changeCampaign as CampaignAdSettingState;
    const index = get(atom.campaignAdSetting).findIndex((campaign) => {
      if (campaign.type.includes('fb_collection')) {
        return campaign.type.includes('fb_collection_') && updateCampaign.type.includes('fb_collection_');
      }
      if (campaign.type.includes('fb_dynamic')) {
        return (
          (campaign.type.includes('fb_dynamic_') && updateCampaign.type.includes('fb_dynamic_')) ||
          (campaign.type.includes('fb_dynamic') && updateCampaign.type.includes('fb_dynamic_'))
        );
      }
      return campaign.type === updateCampaign.type;
    });
    if (index !== -1) {
      return set(atom.campaignAdSetting, (prevState) => {
        const prevCampaignList = _.cloneDeep(prevState);
        prevCampaignList[index] = { ...updateCampaign };
        return prevCampaignList;
      });
    }
    return set(atom.campaignAdSetting, (prevState) => {
      const prevCampaignList = prevState;
      return prevCampaignList;
    });
  },
});

const campaignAdSettingSingleSelector = selectorFamily<CampaignAdSettingState, { campaignType: string }>({
  key: 'campaignAdSettingSingle',
  get:
    (param) =>
    ({ get }) => {
      const findIndex = get(atom.campaignAdSetting).findIndex((campaign) => campaign.type === param.campaignType);
      return get(atom.campaignAdSetting)[findIndex];
    },
});

const campaignSlideUpdateSelector = selectorFamily<
  SlideContent,
  { type: string; name: string; value?: string; index: number }
>({
  key: 'campaignSlideUpdate',
  get:
    (param) =>
    ({ get }) => {
      const findIndex = get(atom.campaignAdSetting).findIndex((campaign) => campaign.type === param.type);
      const slide = get(atom.campaignAdSetting)[findIndex].slides[param.index];
      return slide;
    },
  set:
    (param) =>
    ({ get, set }) => {
      const findIndex = get(atom.campaignAdSetting).findIndex((campaign) => campaign.type === param.type);
      const newSlides = get(atom.campaignAdSetting)[findIndex].slides;
      newSlides[param.index][param.name] = param.value;
      return set(atom.campaignAdSetting, (prevState) => {
        const prevCampaignList = _.cloneDeep(prevState);
        prevCampaignList[findIndex].slides = newSlides;
        return prevCampaignList;
      });
    },
});

export {
  campaignGoalSelector,
  targetGenderSelector,
  ageRangeSelector,
  campaignTypeSelector,
  campaignBudgetSelector,
  campaignStartOptionSelector,
  campaignRevisitOptimizeSelector,
  campaignTargetExcludeSelector,
  campaignInvolvementsSelector,
  campaignStartDateSelector,
  campaignEndDateSelector,
  campaignAdSettingSelector,
  campaignAdSettingSingleSelector,
  campaignAdSettingUpdateSelector,
  campaignSlideUpdateSelector,
  addCampaignAdSettingSelector,
  changeCampaignAdSettingSelector,
  chargeCreditSelector,
  campaignTypeResetSelector,
  campaignConvertCampaignAdSettingSelector,
  campaignChargeAgreeSelector,
  campaignIsBiginConnectedSelector,
};
