import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton } from '@bigin/bigin-ui-components';
import { useNavigate } from 'react-router-dom';
import { range } from 'lodash';
import { useQueryClient } from 'react-query';
import { BorderSection } from '../../../components/BorderSection/BorderSection';
import { baseCss, createScriptModalCss } from './BiginSdkSetting.style';
import { useDataStore } from '../../../context/Store';
import { WrapperModal } from '../../../components/WrapperModal/WrapperModal';
import { MallApi } from '../../../lib/api/MallApi';
import StorageService from '../../../services/StorageService';
import { BGLoadingSpinner } from '../../../components/BGLoadingSpinner/BGLoadingSpinner';

interface BiginSdkSettingProps {
  mall: Mall;
}
export const BiginSdkSetting = (props: BiginSdkSettingProps): ReactElement => {
  const { ...i18next } = useTranslation();
  const { toastStore, dialogStore } = useDataStore();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isCafe24Plugin, setIsCafe24Plugin] = useState<{ is_cafe24_plugin_installed: boolean }>();
  const [gtmData, setGtmData] = useState<GtmData>();
  const [createScriptModal, setCreateScriptModal] = useState<boolean>(false);
  const [isCafe24Fetching, setIsCafe24Fetching] = useState<boolean>(false);
  const [gtmDataFetching, setGtmDataFetching] = useState<boolean>(false);
  const hasBiginProjectType = useMemo(() => {
    return props.mall.medias.findIndex((mallMedia: MallMedia) => mallMedia.type === 'bigin-project') > -1;
  }, [props.mall]);

  useEffect(() => {
    if (hasBiginProjectType) {
      setIsCafe24Fetching(true);

      MallApi()
        .getBiginCrmCafe24Plugin(StorageService.selectedMall.id)
        .then((res) => {
          setIsCafe24Plugin(res.data.data);
          setIsCafe24Fetching(false);
        });
      setGtmDataFetching(true);
      MallApi()
        .getGtmCode(StorageService.selectedMall.id)
        .then((res) => {
          setGtmData(res.data.data);
          setGtmDataFetching(false);
        });
    }
  }, [hasBiginProjectType]);

  const copyText = (val: string) => {
    const calcString = val.replace(/&lt;/g, '<').replace(/&gt;/g, '>');

    const t = document.createElement('textarea');
    t.style.position = 'fixed';
    t.style.bottom = '0';
    t.style.left = '0';
    document.body.appendChild(t);
    t.value = calcString;
    t.select();
    document.execCommand('copy');
    document.body.removeChild(t);
    toastStore.showToast('basic', 'info', i18next.t('클립보드에 복사되었습니다.'));
  };

  const likedCrmProject = () => {
    const url = `/malls/${StorageService.selectedMall.id}/accounts?tab=account&open=linked-bigin-crm-modal&redirect=/malls/${StorageService.selectedMall.id}/accounts?tab=bigin-sdk`;
    navigate(url, { replace: true });
  };

  const handleCreateScript = async () => {
    if (hasBiginProjectType) {
      await MallApi()
        .putGtmCode(StorageService.selectedMall.id)
        .then((res) => {
          setGtmData(res.data.data);
          toastStore.showToast(
            'basic',
            'success',
            i18next.t('스크립트가 생성되었습니다. 스크립트를 복사하여 쇼핑몰에 설치를 완료해주세요.'),
          );
        });
    } else {
      setCreateScriptModal(true);
    }
  };

  const lineNumbering = useMemo(() => {
    if (gtmData && gtmData.script_code) {
      const splitGtmData = gtmData.script_code.split('\n');
      return splitGtmData.length;
    }
    return 0;
  }, [gtmData]);

  return (
    <React.Fragment>
      <div css={[baseCss]}>
        <div className="bigin-sdk-script-container">
          <BorderSection style={{ width: '100%', padding: '16px' }}>
            <div className="title-wrapper">
              <div className="title">{i18next.t('Bigin SDK 스크립트')}</div>

              {(!hasBiginProjectType || gtmData?.script_code === null) && (
                <BDSButton label={i18next.t('스크립트 생성')} onClick={() => handleCreateScript()} />
              )}
            </div>
            <div className="description-wrppaer">
              <ul>
                <li>{i18next.t('Bigin SDK는 Bigin Ads와 Bigin CRM에서 공통 적용되는 스크립트입니다.')}</li>
                <li className="text-color-error">
                  {i18next.t(
                    'Bigin SDK 스크립트를 쇼핑몰에 설치해야 구매 전환 지표 및 재방문 유도 목표를 정상적으로 이용할 수 있습니다.',
                  )}
                </li>
                <li>
                  {i18next.t(
                    'Bigin Ads, Bigin CRM, Google Analyics, Facebook Pixel, Google Ads Tag, Kakao Pixel&SDK 스크립트가 설치되어 있을 경우 데이터 전송 중복이 발생하므로 일시정지 혹은 제거하시기 바랍니다.',
                  )}
                </li>
                <li>
                  {i18next.t('쇼핑몰의 HTML <header>영역에 Bigin SDK 스크립트를 붙여 넣으시면 설치가 완료됩니다.')}
                </li>
                <li>{i18next.t('픽셀 직접 설치 시, 쇼핑몰 환경에 따라 전환 값이 온전하지 않을 수 있습니다.')}</li>
              </ul>
            </div>
            {isCafe24Fetching || gtmDataFetching ? (
              <div style={{ position: 'relative', width: '100%', height: '56px' }}>
                <BGLoadingSpinner isLoading={isCafe24Fetching || gtmDataFetching} />
              </div>
            ) : (
              <React.Fragment>
                {isCafe24Plugin?.is_cafe24_plugin_installed && (
                  <div className="cafe24-box">
                    <img src="assets/img/ic-cafe24.svg" alt="" />
                    <span className="text-input">
                      {i18next.t('cafe24를 연동하여 Bigin CRM을 사용하는 경우 Bigin SDK 스크립트가 자동 설치됩니다.')}
                    </span>
                  </div>
                )}
                {gtmData?.script_code && !isCafe24Plugin?.is_cafe24_plugin_installed && (
                  <div className="tracking-source-code flex-row">
                    <BDSButton
                      className="clipboard-copy"
                      label={i18next.t('클립보드에 코드 복사')}
                      onClick={() => {
                        copyText(gtmData.script_code);
                      }}
                    />
                    <div className="index-list flex-col">
                      {range(lineNumbering - 1).map((index) => (
                        <div key={index}>{index + 1}</div>
                      ))}
                    </div>
                    <pre className="source-code">{gtmData.script_code}</pre>
                  </div>
                )}
              </React.Fragment>
            )}
          </BorderSection>
        </div>
      </div>

      {/* 빅인 연동 모달 */}

      <WrapperModal
        isOpen={createScriptModal}
        close={() => {
          setCreateScriptModal(false);
        }}
        position={{ width: '320px' }}
      >
        <div className="create-script-modal-container" css={[createScriptModalCss]}>
          <div className="create-script-modal-header">{i18next.t('스크립트 생성 안내')}</div>
          <div className="create-script-modal-contents">
            <div className="text-input text-color-main">
              {i18next.t(
                '스크립트 생성을 위해 Bigin CRM 연동이 필요합니다. Bigin CRM을 이용하고 있다면 연동 후 스크립트를 생성해주세요.',
              )}
            </div>

            <button
              type="button"
              className="button-text"
              onClick={() => {
                setCreateScriptModal(false);
                const buttons = [
                  {
                    label: i18next.t('취소'),
                    handleClick: () => {
                      dialogStore.hideDialog();
                    },
                    appearance: 'secondary',
                  },
                  {
                    label: i18next.t('확인'),
                    handleClick: async () => {
                      // requestTemplate(taskData);

                      await MallApi()
                        .createBiginCrmProject(StorageService.selectedMall.id)
                        .then(async (res) => {
                          await MallApi().updateMallMedia(
                            StorageService.selectedMall.id,
                            'bigin-project',
                            res.data.data.id,
                            res.data.data.name,
                          );

                          await MallApi()
                            .putGtmCode(StorageService.selectedMall.id)
                            .then(() => {
                              toastStore.showToast(
                                'basic',
                                'success',
                                i18next.t(
                                  '스크립트가 생성되었습니다. 스크립트를 복사하여 쇼핑몰에 설치를 완료해주세요.',
                                ),
                              );
                            });

                          queryClient.invalidateQueries('mallCompany');
                        });

                      dialogStore.hideDialog();
                    },
                  },
                ];
                dialogStore.showDialog(
                  i18next.t('Bigin CRM 계정이 없으신가요?'),
                  i18next.t(
                    'Bigin CRM 계정이 있으나 연동하지 않고 스크립트를 생성하는 경우, 비정상 스크립트가 생성되니 유의해 주세요.',
                  ),
                  buttons,
                );
              }}
            >
              <u>{i18next.t('Bigin CRM 계정 없이 스크립트 생성')}</u>
            </button>
          </div>
          <div className="horizontal-divider" style={{ marginLeft: '-20px', width: '320px' }} />
          <div className="create-script-modal-footer">
            <BDSButton appearance="secondary" label={i18next.t('취소')} onClick={() => setCreateScriptModal(false)} />
            <BDSButton label={i18next.t('연동하러 가기')} onClick={() => likedCrmProject()} />
          </div>
        </div>
      </WrapperModal>
    </React.Fragment>
  );
};
