import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import _ from 'lodash';
import { baseCss } from './PreviewKakaoItem.style';
import { getUniqKey } from '../../../../utils/array.utils';
import StorageService from '../../../../services/StorageService';
import { i18nextScanKey } from '../../../../i18n';
import { IMAGE_PROXY_URL } from '../../../../utils/utils';

export interface PreviewKakaoItemProps {
  setting: CampaignAdSettingState;
  type?: number;
  products?: Product[];
  ratio?: KakaoNativeImageRatioType;
}

export interface SingleImageBoxProps {
  image: MallImage | ProductImage | null | undefined;
}

const kakaoCTALabel = {
  LINK: i18nextScanKey('바로가기'),
  MORE: i18nextScanKey('알아보기'),
  RESERVATION: i18nextScanKey('예약하기'),
  PURCHASE: i18nextScanKey('구매하기'),
  REQUEST: i18nextScanKey('신청하기'),
  INQUIRE: i18nextScanKey('문의하기'),
  INSTALL: i18nextScanKey('설치하기'),
  RUN: i18nextScanKey('실행하기'),
  DOWNLOAD: i18nextScanKey('다운로드'),
  LAUNCH: i18nextScanKey('사용하기'),
  ADD_FRIEND: i18nextScanKey('채널추가'),
  SUBSCRIBE: i18nextScanKey('구독하기'),
  MORE_VIDEO: i18nextScanKey('영상 더보기'),
  JOIN: i18nextScanKey('가입하기'),
  COUPON: i18nextScanKey('쿠폰받기'),
};

const PreviewSingleImageBox = ({ image }: SingleImageBoxProps): ReactElement => {
  return (
    <div className={`preview-img-box ${image === null || image === undefined ? 'empty' : ''}`}>
      {image && <img src={`${IMAGE_PROXY_URL}${image.url}`} alt="싱글 이미지" />}
      {(image === null || image === undefined) && <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />}
    </div>
  );
};

const PreviewImageBanner = ({ setting }: PreviewKakaoItemProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <React.Fragment>
      <div className="kakao-preview-banner">
        <div className="kakao-preview-item-wrapper">
          <div className="kakao-header-item">
            <div className="header-text">{i18next.t('채팅')}</div>
            <div className="icon-wrap">
              <BDSFontIcon name="ic-search" color="#738696" size="20px" />
              <img className="icon" src="/assets/img/card_icons/ic-add-talk.svg" alt="" />
              <img className="icon" src="/assets/img/card_icons/ic-setting.svg" alt="" />
            </div>
          </div>
          <div className="kakao-preview-item-image" style={{ height: '74px' }}>
            <PreviewSingleImageBox image={setting?.kk_banner_mall_image || setting?.kk_banner_product_image} />
          </div>
          <div className="kakao-preview-item-chat-wrap">
            {_.range(6).map((index) => (
              <div className="chat-item" key={getUniqKey('chat-item', index)}>
                <div className="circle-skeleton" />
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                  <div className="text-skeleton" style={{ width: '116px', height: '16px' }} />
                  <div className="text-skeleton" style={{ width: '239px', height: '12px', marginTop: '4px' }} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const PreviewImageNative = ({ setting, ratio }: PreviewKakaoItemProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <React.Fragment>
      {ratio === '2:1' && (
        <div className="kakao-preview-native">
          <div className="kakao-preview-item-wrapper" style={{ padding: '16px 0' }}>
            <div className="kakao-header-wrapper">
              <div className="kakao-header-item" style={{ padding: '0 16px' }}>
                <div className="header-text">
                  {i18next.t('뷰')} <span style={{ color: '#99a0a9', marginLeft: '8px' }}>{i18next.t('My뷰')}</span>
                </div>
                <div className="icon-wrap">
                  <BDSFontIcon name="ic-search" color="#738696" size="20px" />
                  <img className="icon" src="/assets/img/card_icons/ic-add-talk.svg" alt="" />
                  <img className="icon" src="/assets/img/card_icons/ic-setting.svg" alt="" />
                </div>
              </div>
              <div className="kakao-header-tab">
                <div className="tab-skeleton" style={{ backgroundColor: '#b5bcc9' }} />
                <div className="tab-skeleton" />
                <div className="tab-skeleton" />
                <div className="tab-skeleton" />
              </div>
            </div>
            <div style={{ padding: '16px' }}>
              <div
                style={{
                  width: '100%',
                  height: '172px',
                  marginBottom: '20px',
                  backgroundColor: '#f9fafb',
                }}
              />
              <div className="kakao-preview-item-image" style={{ position: 'relative', marginBottom: '112px' }}>
                <PreviewSingleImageBox image={setting?.kk_native_mall_image || setting?.kk_native_product_image} />

                <div
                  className="kakao-preview-title-wrapper"
                  style={{ position: 'absolute', bottom: '0px', right: '0px' }}
                >
                  <span className="kakao-title">
                    {setting?.kk_title ? setting?.kk_title : i18next.t('입력하신 광고 제목이 여기에 보여집니다.')}
                  </span>
                  <div className="kakao-preview-action-button">
                    {setting?.kk_action_button && i18next.t(kakaoCTALabel[setting?.kk_action_button])}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {ratio === '1:1' && (
        <div className="kakao-preview-native">
          <div className="kakao-preview-item-wrapper">
            <div className="kakao-header-item">
              <div className="header-text">KakaoStory</div>
              <div className="icon-wrap">
                <img className="icon" src="/assets/img/card_icons/ic-camera.svg" alt="" />
              </div>
            </div>
            <div className="kakao-title-box">
              <div className="profile-wrap">
                <div className="logo">
                  {StorageService.selectedMall?.square_logo_url ? (
                    <img src={StorageService.selectedMall?.square_logo_url} alt={StorageService.selectedMall?.name} />
                  ) : (
                    ''
                  )}
                </div>
                <div className="media-title-wrapper">
                  <div className="title">
                    {StorageService.selectedMall.name ? StorageService.selectedMall.name : ''}
                  </div>
                  <div className="info">sponsered</div>
                </div>
              </div>
              <BDSFontIcon name="ic-option" color="#738696" size="20px" />
            </div>
            <div className="kakao-preview-item-image" style={{ height: '343px' }}>
              <PreviewSingleImageBox image={setting?.kk_native_mall_image || setting?.kk_native_product_image} />
            </div>
            <div className="kakao-preview-title-wrapper" style={{ marginBottom: '93px' }}>
              <span className="kakao-title">
                {setting?.kk_title ? setting?.kk_title : i18next.t('입력하신 광고 제목이 여기에 보여집니다.')}
              </span>
              <div className="kakao-preview-action-button">
                {setting?.kk_action_button && i18next.t(kakaoCTALabel[setting?.kk_action_button])}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export const PreviewKakaoItem = ({ setting, ratio }: PreviewKakaoItemProps): ReactElement => {
  return (
    <div css={[baseCss]} className="kakao-preview-item">
      {setting.type === 'kk_image_banner' && <PreviewImageBanner setting={setting} />}
      {setting.type === 'kk_image_native' && <PreviewImageNative setting={setting} ratio={ratio} />}
    </div>
  );
};
