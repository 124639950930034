import React, { useEffect, ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import axios from 'axios';
import { useQueryClient } from 'react-query';

import { BDSButton } from '@bigin/bigin-ui-components';
import { baseCss } from './InviteMember.style';
import { BGButtonGroup } from '../../../../components/BGButtonGroup/BGButtonGroup';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { InputError } from '../../../../components/InputError/InputError';
import { UserApi } from '../../../../lib/api/UserApi';
import { useDataStore } from '../../../../context/Store';

interface IInviteMemberForm {
  email: string;
}

export interface InviteMemberProps {
  companyId: number;
  close: () => void;
}

export const ModalInviteMember = (props: InviteMemberProps): ReactElement => {
  const { ...i18next } = useTranslation();
  const { dialogStore } = useDataStore();
  const queryClient = useQueryClient();

  const [email, setEmail] = useState<string>('');

  const { register, formState, setValue, handleSubmit } = useForm<IInviteMemberForm>({
    mode: 'all',
  });

  useEffect(() => {
    register('email', {
      value: email,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isEmail(value)) {
          return i18next.t('이메일이 올바르지않습니다.') as string;
        }

        return true;
      },
    });
  }, []);

  const onSubmit = (data: IInviteMemberForm) => {
    return UserApi()
      .invitePendingUser(props.companyId, data.email)
      .then(() => {
        return queryClient.invalidateQueries('pendingUsers').then(() => {
          props.close();
        });
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
          if (response && response.status === 409) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <div css={[baseCss]}>
      <div className="invite-pannel-wrapper">
        <div className="invite-title-wrapper">
          <div className="text-h1 text-color-main">{i18next.t('팀원 추가')}</div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="invite-pannel-email">
            <div className="invite-email-box flex-col">
              <div className="text-h5 text-color-sub mt-5 mb-2">{i18next.t('이메일 주소')}</div>
              <BGInput
                error={!!formState.errors.email}
                inputProps={{
                  type: 'text',
                  placeholder: i18next.t('초대할 팀원의 이메일 주소를 입력하세요.'),
                  value: email,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('email', event.target.value, { shouldValidate: true });
                    setEmail(event.target.value);
                  },
                }}
              />
              {formState.errors.email && <InputError message={formState.errors.email.message as string} />}
            </div>
          </div>
          <BGButtonGroup rightAlign style={{ marginTop: '24px' }}>
            <BDSButton
              key={i18next.t('취소') as string}
              label={i18next.t('취소')}
              onClick={() => {
                props.close();
              }}
              appearance="secondary"
            />
            <BDSButton key={i18next.t('초대') as string} label={i18next.t('초대')} type="submit" />
          </BGButtonGroup>
        </form>
      </div>
    </div>
  );
};
