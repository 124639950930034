import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState, useRecoilState, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { BDSButton } from '@bigin/bigin-ui-components';
import validator from 'validator';
import qs from 'qs';
import axios from 'axios';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { BGButtonProps } from '../../components/BGButton/BGButton';
import CampaignSetting, {
  campaignIsBiginConnectedSelector,
  changeCampaignAdSettingSelector,
} from '../../recoil/CampaignSetting';
import StorageService from '../../services/StorageService';
import { CampaignApi } from '../../lib/api/CampaignApi';
import { useDataStore } from '../../context/Store';
import { WrapperModal } from '../../components/WrapperModal/WrapperModal';
import { filters } from '../../utils/filter';
import { Step2AssetSetting } from '../CreateCampaign/Step2AssetSetting/Step2AssetSetting';
import { addCampaignModalCss, baseCss, leftWrapper } from './EditAd.style';
import { BGButtonGroup } from '../../components/BGButtonGroup/BGButtonGroup';
import { AW_MAX_BYTE, AW_SHORT_TITLE_MAX_BYTE, KK_MAX_LENGTH } from '../../utils/utils';

interface QueryParams {
  id?: number;
  campaignId?: number;
  type?: string;
}

interface EditAdModalProps {
  name: string;
  close: () => void;
}

const EditAdModal = (props: EditAdModalProps): ReactElement => {
  const i18next = useTranslation();
  const { toastStore, dialogStore } = useDataStore();

  const location = useLocation();
  const campaignAdState = useRecoilValue(CampaignSetting.campaignAdSetting);
  const navigate = useNavigate();
  const resetCampaignState = useResetRecoilState(CampaignSetting.campaignSetting);
  const resetCampaignAdState = useResetRecoilState(CampaignSetting.campaignAdSetting);

  const editAd = async () => {
    const parsedQueryParams: QueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!parsedQueryParams) return;

    const adId = parsedQueryParams.id as number;
    const campaignId = parsedQueryParams.campaignId as number;

    const campaign = campaignAdState[0];

    try {
      await CampaignApi().putUnifiedAds(StorageService.selectedMall?.id, adId, campaign);
      navigate(`/malls/${StorageService.selectedMall?.id}/campaign-report?id=${campaignId}`);
      toastStore.showToast('basic', 'success', i18next.t('소재 수정이 완료되었습니다.'));
      resetCampaignState();
      resetCampaignAdState();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const response = error?.response;
        if (response && response.status === 404) {
          dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);
        }
      }
    } finally {
      props.close();
    }
  };

  return (
    <div css={[addCampaignModalCss]}>
      <div className="edit-ad-header">
        <div className="text-h1 text-color-main">{i18next.t('광고 소재 수정')}</div>
      </div>
      <div className="edit-ad-body">
        <div className="text-input text-color-main">{i18next.t('설정한 내용으로 광고 소재를 수정하시겠습니까?')}</div>
        <div className="text-disclaimer">{i18next.t('*소재 수정 후, 검토 과정이 이루어집니다.')}</div>
      </div>
      <div className="edit-ad-footer">
        <BGButtonGroup rightAlign>
          <BDSButton
            key={i18next.t('취소') as string}
            label={i18next.t('취소')}
            onClick={() => {
              props.close();
            }}
            appearance="secondary"
          />
          <BDSButton label={i18next.t('수정')} onClick={() => editAd()} />
        </BGButtonGroup>
      </div>
    </div>
  );
};

export const EditAd = (): ReactElement => {
  const i18next = useTranslation();
  const [topNavButton, setTopNavButton] = useState<BGButtonProps[] | []>([]);
  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);
  const campaignAdState = useRecoilValue(CampaignSetting.campaignAdSetting);
  const changeCampaignAdState = useSetRecoilState(changeCampaignAdSettingSelector);
  const [, updateIsBiginConnected] = useRecoilState(campaignIsBiginConnectedSelector);
  const location = useLocation();
  const navigate = useNavigate();

  const [editAdModal, setEditAdModal] = useState<boolean>(false);
  const [campaignValidateState, setCampaignValidateState] = useState<CampaignValidateState[]>([]);

  const [disabledEditAd, setDisabledEditAd] = useState<boolean>(true);

  const campaignId = useMemo(() => {
    const parsedQueryParams: QueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!parsedQueryParams) return null;

    return parsedQueryParams.campaignId as number;
  }, [location]);

  const campaignType = useMemo(() => {
    const parsedQueryParams: QueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!parsedQueryParams) return null;

    return parsedQueryParams.type as string;
  }, [location]);
  const adId = useMemo(() => {
    const parsedQueryParams: QueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!parsedQueryParams) return null;

    return parsedQueryParams.id;
  }, [location]);

  const { data: unifiedCampaign, isFetching: isUnifiedCampaignFetching } = useQuery('getUnifiedCampaign', async () => {
    if (!campaignId) return;

    const res = await CampaignApi().getUnifiedCampaign(StorageService.selectedMall.id, campaignId, '', '', 'created');
    // eslint-disable-next-line consistent-return
    return res;
  });

  const findAd = useMemo(() => {
    if (!unifiedCampaign || !campaignType || !adId) return;

    const findUniAd = unifiedCampaign.data.data.uni_ads_by_type.find((unidAd) => unidAd.type === campaignType);
    if (!findUniAd) return;

    const result = findUniAd.uni_ads.find((ad) => {
      return ad.id === Number(adId);
    });

    updateIsBiginConnected(unifiedCampaign.data.data.is_bigin_connected);

    // eslint-disable-next-line consistent-return
    return result;
  }, [unifiedCampaign, campaignType, adId]);
  useEffect(() => {
    if (!findAd) return;

    const tempFindAd = _.cloneDeep(findAd);
    const category_id = tempFindAd.fb_collection_category_id || tempFindAd.fb_dynamic_slide_category_id;

    let slides = [];
    if (findAd.slides) {
      slides = findAd.slides.map((slide) => {
        const slideSrc = slide.mall_image?.url || slide.product_image?.url || '';
        const slideImageId = slide.mall_image?.image_id || slide.product_image?.image_id;

        return {
          ...slide,
          image_id: slideImageId,
          src: slideSrc,
        };
      });
    } else {
      slides = [
        {
          src: '',
          url: '',
          title: '',
          description: '',
        },
        {
          src: '',
          url: '',
          title: '',
          description: '',
        },
      ];
    }

    let fb_dynamic_collection_body = '';
    let fb_dynamic_catalog_body = '';
    let fb_collection_title = '';
    if (tempFindAd.types[0].includes('fb_dynamic')) {
      if (tempFindAd.types[0] === 'fb_dynamic_dynamic_video') {
        fb_dynamic_collection_body = tempFindAd.fb_body;
      } else {
        fb_dynamic_catalog_body = tempFindAd.fb_body;
      }
    }
    if (tempFindAd.types[0].includes('fb_collection')) {
      fb_collection_title = tempFindAd.fb_collection_title;
    }

    const result = {
      ...tempFindAd,
      slides,
      category_id,
      fb_dynamic_catalog_body,
      fb_dynamic_collection_body,
      fb_collection_title,
      type: tempFindAd.types[0],
    } as unknown as CampaignAdSettingState;

    changeCampaignAdState(result as CampaignAdSettingState);
  }, [findAd, unifiedCampaign]);

  const requireVariables = (type: CampaignType): CampaignContent[] => {
    // 단일 슬라이드
    if (type === 'fb_single_image') return ['fb_body', 'fb_single_title', 'fb_single_description', 'src', 'url'];
    if (type === 'fb_slide_image') return ['fb_body', 'slides'];
    if (type === 'aw_responsive_image') return ['src', 'aw_description', 'aw_long_title', 'aw_short_title', 'url'];
    if (type === 'kk_image_banner') return ['src', 'url'];
    if (type === 'kk_image_native') return ['kk_title', 'src', 'url'];

    // 영상
    if (type === 'fb_single_video') return ['fb_body', 'fb_single_description', 'mall_video_id', 'url'];
    if (type === 'aw_youtube') return ['aw_description', 'aw_short_title', 'url', 'aw_youtube_url', 'aw_long_title'];

    // 컬렉션
    if (type === 'fb_catalog_slide')
      return ['fb_body', 'fb_dynamic_slide_description', 'fb_dynamic_slide_title', 'category_id'];
    if (type === 'fb_collection_image') return ['fb_body', 'fb_collection_title', 'url', 'src', 'category_id'];
    if (type === 'fb_collection_video')
      return ['fb_body', 'fb_collection_title', 'url', 'mall_video_id', 'category_id'];
    if (type === 'fb_collection_dynamic_video') return ['fb_body', 'fb_collection_title', 'url', 'category_id'];

    // 구글 쇼핑
    if (type === 'aw_shopping') return [];
    if (type === 'aw_smart_shopping') return ['aw_description', 'aw_long_title', 'aw_short_title', 'url', 'src'];

    // 다이나믹
    if (
      type === 'fb_dynamic' ||
      type === 'fb_dynamic_image' ||
      type === 'fb_dynamic_video' ||
      type === 'fb_dynamic_dynamic_video'
    )
      return [
        'url',
        'fb_dynamic_collection_body',
        'fb_dynamic_catalog_body',
        'fb_collection_title',
        'category_id',
        'fb_dynamic_slide_description',
        'fb_dynamic_slide_title',
      ];
    if (type === 'aw_dynamic_remarketing')
      return ['category_id', 'aw_description', 'aw_long_title', 'aw_short_title', 'url', 'src'];

    // 구글 검색
    if (type === 'aw_search') return [];
    // 구글 실적 최대화
    if (type === 'aw_pmax')
      return ['aw_pmax_long_headlines', 'aw_pmax_headlines', 'url', 'aw_pmax_descriptions', 'aw_pmax_src'];
    return [];
  };

  const errors = () => {
    const totalErrorArray = [] as any;
    campaignAdState.forEach((campaign) => {
      const variables = requireVariables(campaign.type);
      const errorArray = [] as {
        variable: CampaignContent;
        message: string;
      }[];

      variables.forEach((variable) => {
        if (variable === 'src') {
          if (!campaign.mall_image_id && !campaign.product_image_id)
            errorArray.push({
              variable,
              message: `no ${variable}`,
            });
        } else if (variable === 'aw_pmax_src') {
          // 구글 실적최대화 캠페인은 1:1 비율, 1.91:1 비율 이미지 id 둘다 있어야함. (상품 정보가 있다면 product_id 없다면 mall_id)
          if (!campaign.mall_image_id && !campaign.product_image_id) {
            errorArray.push({
              variable,
              message: `no ${variable}`,
            });
          }

          if (!campaign.aw_landscape_product_image_id && !campaign.aw_landscape_mall_image_id) {
            errorArray.push({
              variable,
              message: `no ${variable}`,
            });
          }
        } else if (variable === 'slides') {
          const validLength = campaign.slides.length > 1;
          const validVariable = campaign.slides.every((slide) => {
            return (
              (slide.product_image_id || slide.mall_image_id) &&
              slide.description.length > 0 &&
              slide.title.length > 0 &&
              slide.url.length > 0
            );
          });
          if (!validLength || !validVariable)
            errorArray.push({
              variable,
              message: `no ${variable}`,
            });
        } else if (['aw_short_title', 'aw_long_title', 'aw_description'].includes(variable)) {
          const awValue = campaign[variable] as string;
          let awResult = !!campaign[variable] || (i18next.t('필수 입력란 형식입니다.') as string);

          if (variable === 'aw_short_title') {
            if (filters.formatByte(campaign[variable]) > AW_SHORT_TITLE_MAX_BYTE)
              awResult = i18next.t('최대 입력 byte를 초과하였습니다.') as string;
          } else if (filters.formatByte(awValue) > AW_MAX_BYTE)
            awResult = i18next.t('최대 입력 byte를 초과하였습니다.') as string;

          if (awResult !== true) errorArray.push({ variable, message: awResult });
        } else if (variable === 'kk_title') {
          let kkResult = !!campaign[variable] || (i18next.t('필수 입력란 형식입니다.') as string);

          if (campaign[variable].length > KK_MAX_LENGTH)
            kkResult = i18next.t('최대 입력 글자 수를 초과하였습니다.') as string;
          if (kkResult !== true) errorArray.push({ variable, message: kkResult });
        } else if (variable === 'url') {
          let urlResult = !!campaign[variable] || (i18next.t('필수 입력란 형식입니다.') as string);

          if (!validator.isURL(campaign[variable])) urlResult = i18next.t('잘못된 URL 형식입니다.') as string;
          if (urlResult !== true) errorArray.push({ variable, message: urlResult });
        } else if (['aw_pmax_long_headlines', 'aw_pmax_headlines', 'aw_pmax_descriptions'].includes(variable)) {
          // 구글 실적 최대화 유효 검사.
          if (variable === 'aw_pmax_long_headlines') {
            const result = campaign[variable].length === 1 || (i18next.t('필수 입력란 입니다.') as string);
            if (result !== true) errorArray.push({ variable, message: result });
          } else if (variable === 'aw_pmax_descriptions') {
            const result =
              (!!campaign[variable][0] && !!campaign[variable][1]) || (i18next.t('필수 입력란 입니다.') as string);
            if (result !== true) errorArray.push({ variable, message: result });
          } else if (variable === 'aw_pmax_headlines') {
            const result =
              (!!campaign[variable][0] && !!campaign[variable][1] && !!campaign[variable][2]) ||
              (i18next.t('필수 입력란 입니다.') as string);
            if (result !== true) errorArray.push({ variable, message: result });
          }
        } else {
          const result = !!campaign[variable] || (i18next.t('필수 입력란 입니다.') as string);
          if (result !== true) errorArray.push({ variable, message: result });
        }
      });
      if (errorArray.length !== 0) {
        totalErrorArray.push(errorArray);
      }
    });

    return totalErrorArray.length !== 0;
  };

  const setTopNavBtn = () => {
    const btn = [];
    btn.push({
      id: 0,
      label: i18next.t('취소'),
      appearance: 'secondary',
      onClick: () => {
        navigate(`/malls/${StorageService.selectedMall?.id}/campaign-report?id=${campaignId}`);
      },
      style: { marginRight: '8px' },
    });

    btn.push({
      id: 1,
      isDisabled: disabledEditAd,
      label: i18next.t('수정'),
      onClick: async () => {
        setEditAdModal(true);
      },
    });

    setTopNavButton(btn);
  };

  const setCurrentValid = (status: CampaignValidateState[]) => {
    setCampaignValidateState(status);
  };

  useEffect(() => {
    setTopNavBtn();
    const formValid = campaignValidateState.every((item) => item.isValid === true);
    setDisabledEditAd(errors() && !formValid);
  }, [disabledEditAd, campaignState, campaignAdState, campaignValidateState]);

  return (
    <React.Fragment>
      <div css={[baseCss]}>
        <TopNavBar
          title={
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {i18next.t('광고 소재 수정')}
            </div>
          }
          button={topNavButton}
        />
        <div className="edit-ad-container flex-row">
          <div css={[leftWrapper]} className="left-wrapper flex-col">
            {campaignAdState && !isUnifiedCampaignFetching && (
              <Step2AssetSetting key="edit-ad" setCurrentValid={setCurrentValid} />
            )}
          </div>
        </div>
      </div>

      <WrapperModal
        position={{ width: '360px' }}
        isOpen={editAdModal}
        close={() => {
          setEditAdModal(false);
        }}
      >
        <EditAdModal
          name={campaignState.name}
          close={() => {
            setEditAdModal(false);
          }}
        />
      </WrapperModal>
    </React.Fragment>
  );
};
