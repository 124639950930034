import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = css``;

export const templateCss = () => {
  const theme: Theme = useTheme();

  return css`
    margin: 0 auto;
    width: 318px;

    .find-password-title-wrapper {
      ${theme.text.h1};
      color: ${theme.colors.mainFont};

      &.add-space {
        margin-bottom: 2.5rem;
      }
    }

    .find-email-description {
      ${theme.text.input};
      color: ${theme.colors.mainFont};
      white-space: pre-line;
      margin-bottom: 20px;
    }

    .label-email {
      ${theme.text.h4};
      color: ${theme.colors.mainFont};
      margin-bottom: 8px;

      &.red {
        ${theme.text.input};
        color: ${theme.colors.error};
        margin-bottom: 0.5rem;
      }
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 1rem;
      }
    }
  `;
};
