import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: auto;
    .dialog-confirm-content {
      margin-bottom: 20px;
      .dialog-confirm-title {
        ${theme.text.h1};
        color: ${theme.colors.mainFont};
        margin-bottom: 8px;
      }
      .dialog-confirm-desc {
        ${theme.text.input};
        color: ${theme.colors.mainFont};
        white-space: pre-wrap;
      }
    }
    .dialog-confirm-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  `;
};
