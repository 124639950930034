import { css } from '@emotion/react';
import { createStyles, Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core';
import { GS } from '../../styles/size';

export const TopNavBarHeight = 76;

export const baseCss = (isSideNavBarCollapsed: boolean) => {
  return css`
    height: ${TopNavBarHeight}px;
    position: relative;
    left: ${isSideNavBarCollapsed ? `${GS.sideBarMinWidth}px` : `${GS.sideBarMaxWidth}px`};
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1000px;
    background-color: #ffffff !important;
    padding: 20px;
    box-sizing: border-box;
    transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);

    .top-nav-bar-contents {
      display: flex;
      align-items: center;
      min-width: 0;

      .top-nav-title {
        font-size: 24px;
        font-weight: bold;
        color: #3d4046;
        margin-right: 18px;
        min-width: 0;
      }
    }
    .top-nav-bar-buttons {
      display: flex;
    }
  `;
};

export interface StyleProps {
  isCollapsed: boolean;
  customTop?: number;
}

export const useLinearProgressStyle = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    root: {
      width: '100%',
      top: ({ customTop }) => (customTop ? `${customTop}px` : `${TopNavBarHeight}px`),
      left: ({ isCollapsed }) => (isCollapsed ? `${GS.sideBarMinWidth}px` : `${GS.sideBarMaxWidth}px`),
      position: 'fixed',
      zIndex: 11,
    },
    colorPrimary: {
      backgroundColor: '#dce0e8',
    },
    bar: {
      backgroundColor: '#3399ff',
    },
  }),
);
