import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import koKR from './locales/ko-KR/translation.json';
import enUS from './locales/en-US/translation.json';
import StorageService from '../services/StorageService';

const resources = {
  ko: { translation: koKR },
  en: { translation: enUS },
};

const browserLang = window.navigator.language.split('-')[0];
const userLanguage = StorageService.translationLanguage || browserLang;

i18n.use(initReactI18next).init({
  resources,
  lng: userLanguage || 'ko',
  fallbackLng: 'ko',
  debug: false,
  defaultNS: 'translation',
  ns: 'translation',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    alwaysFormat: true,
    format(value, format, lng) {
      if (format === 'uppercase') return value.toUpperCase();
      if (typeof value === 'number') return value.toLocaleString(lng);
      return value;
    },
  },
  react: {
    defaultTransParent: 'div',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'button', 'a', 'span', 'div', 'input'],
    transWrapTextNodes: '',
  },
  nsSeparator: '=>',
});

export default i18n;

export interface I18Next {
  t: (str: string, option?: Object) => string;
}

// dummy function
export const i18nextScanKey = (key: string): string => key;
