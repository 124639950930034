import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    position: relative;
    height: 100%;

    .plan-modal-header {
      display: flex;
      justify-content: space-between;

      margin-bottom: 24px;
    }
    .plan-modal-body {
      .icon-wrapper {
        margin-bottom: 8px;
        width: 64px;
        height: 64px;
        background-color: #f9fafb;
        border-radius: 8px;
        border: solid 1px #dce0e8;

        .icon {
          width: 100%;
          height: 100%;
        }
      }
      .title-wrapper {
        margin-bottom: 4px;

        .title {
          ${theme.text.h2};
          color: ${theme.colors.mainFont};
        }
      }
      .feature-items {
        .feature-item {
          ${theme.text.input}
          color: ${theme.colors.mainFont};
          margin-bottom: 8px;
        }
      }
    }
    .plan-modal-footer {
      margin-top: 64px;
    }
  `;
};
