import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { baseCss } from './Step2.style';

interface IStep2Props {
  email: string;
  onAnotherClicked: () => void;
}

export const Step2 = (props: IStep2Props): ReactElement => {
  const i18next = useTranslation();
  const navigate = useNavigate();

  return (
    <div css={[baseCss]}>
      <div className="header">
        <div className="image-wrapper">
          <img src="/assets/img/find-password/graphic-mail.png" alt="mail" />
        </div>
        <p className="text-h3 tex-gray1">{props.email}</p>
        <p className="text-h4 text-color-main">{i18next.t('메일함을 확인해보세요!')}</p>
      </div>
      <div className="text-input text-color-main mb-5">
        <p>{i18next.t('메일에 첨부된 임시 비밀번호를 이용하여 로그인해주세요.')}</p>
        <div className="back-btn-container">
          <span
            className="back-btn"
            onClick={() => {
              navigate('/login');
            }}
          >
            {i18next.t('로그인 화면으로 이동')}
          </span>
        </div>
      </div>
      <div className="footer">
        <p style={{ whiteSpace: 'pre-line', marginBottom: '8px' }}>
          {i18next.t('메일을 받지 못하셨나요?\n3분이 지나도 메일을 받지 못하셨다면 스팸함을 확인하시거나,')}
        </p>
        <span className="button" onClick={props.onAnotherClicked}>
          {i18next.t('이메일 주소를 다시 입력')}
        </span>
        <span>{i18next.t('해주세요.')}</span>
      </div>
    </div>
  );
};
