/* eslint-disable */
import { AttributionWindow, TAttributionWindow } from '../enums/AttributionWindow';

class StorageService {
  get selectedMall(): any {
    let application = window.localStorage.getItem('bigin-web-app');

    try {
      if (application != null) {
        application = JSON.parse(application);
      }
      return application;
    } catch (e) {
      return null;
    }
  }

  set selectedMall(application) {
    if (application) {
      window.localStorage.setItem('bigin-web-app', JSON.stringify(application));
      window.localStorage.setItem('bigin-web-appId', JSON.stringify(application.id));
    } else {
      window.localStorage.removeItem('bigin-web-app');
      window.localStorage.removeItem('bigin-web-appId');
    }
  }

  get activePlan(): any {
    let item = window.localStorage.getItem('bigin-web-active-plan');

    try {
      if (item !== null) {
        item = JSON.parse(item);
      }
      return item;
    } catch (e) {
      return null;
    }
  }

  set activePlan(activePlan) {
    if (activePlan) {
      window.localStorage.setItem('bigin-web-active-plan', JSON.stringify(activePlan));
    } else {
      window.localStorage.removeItem('bigin-web-active-plan');
    }
  }

  get user(): any {
    let user = window.localStorage.getItem('bigin-web-user');

    try {
      if (typeof user === 'string') {
        user = JSON.parse(user);
      }
      return user;
    } catch (e) {
      return null;
    }
  }

  set user(_user) {
    if (_user) window.localStorage.setItem('bigin-web-user', JSON.stringify(_user));
    else window.localStorage.removeItem('bigin-web-user');
  }

  set token(_token: any) {
    if (_token) window.localStorage.setItem('bigin-web-token', JSON.stringify(_token));
    else window.localStorage.removeItem('bigin-web-token');
  }

  get token(): any {
    let token = window.localStorage.getItem('bigin-web-token');
    try {
      if (token != null) {
        token = JSON.parse(token);
      }
      return token;
    } catch (e) {
      return null;
    }
  }

  set refreshToken(_refreshToken: any) {
    if (_refreshToken) window.localStorage.setItem('bigin-web-refreshToken', JSON.stringify(_refreshToken));
    else window.localStorage.removeItem('bigin-web-refreshToken');
  }

  get refreshToken(): any {
    let refreshToken = window.localStorage.getItem('bigin-web-refreshToken');
    try {
      if (refreshToken != null) {
        refreshToken = JSON.parse(refreshToken);
      }
      return refreshToken;
    } catch (e) {
      return null;
    }
  }

  get translationLanguage(): string | null {
    return window.localStorage.getItem('bigin-translation-language');
  }

  set translationLanguage(languageCode: string | null) {
    if (languageCode) window.localStorage.setItem('bigin-translation-language', languageCode);
    else window.localStorage.removeItem('bigin-translation-language');
  }

  get attributionWindow(): TAttributionWindow {
    const item = window.localStorage.getItem('bigin-attribution-window');
    if (AttributionWindow.values().includes(item)) {
      return item as TAttributionWindow;
    } else {
      return AttributionWindow.ONE_DAY_VIEW_SEVEN_DAYS_CLICK;
    }
  }

  set attributionWindow(item: TAttributionWindow) {
    if (item) {
      window.localStorage.setItem('bigin-attribution-window', String(item));
    } else {
      window.localStorage.removeItem('bigin-attribution-window');
    }
  }

  get saveDateRange(): any {
    let item = window.localStorage.getItem('saved_date_range');
    try {
      if (item !== null) {
        item = JSON.parse(item);
      }
      return item;
    } catch (e) {
      return null;
    }
  }

  set saveDateRange(item: any) {
    if (item) {
      window.localStorage.setItem('saved_date_range', JSON.stringify(item));
    } else {
      window.localStorage.removeItem('saved_date_range');
    }
  }

  get saveGridColumns() {
    let item = window.localStorage.getItem('saved_grid_colums');
    try {
      if (item !== null) {
        item = JSON.parse(item);
      }
      return item;
    } catch (e) {
      return null;
    }
  }

  set saveGridColumns(item: any) {
    if (item) {
      window.localStorage.setItem('saved_grid_colums', JSON.stringify(item));
    } else {
      window.localStorage.removeItem('saved_grid_colums');
    }
  }

  private static instance: StorageService;

  private constructor() {}

  public static getInstance() {
    return this.instance || (this.instance = new this());
  }

  public clear() {
    this.selectedMall = null;
    this.activePlan = null;
    this.user = null;
    this.token = null;
    this.refreshToken = null;
    this.translationLanguage = null;
    this.attributionWindow = AttributionWindow.ONE_DAY_VIEW_SEVEN_DAYS_CLICK;
    this.saveDateRange = null;
    this.saveGridColumns = null;
  }
}

export default StorageService.getInstance();
