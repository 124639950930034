import React, { MouseEvent, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { baseCss } from './FontIcon.style';

export type FontIconName =
  | 'ic-graph-chart-fill'
  | 'icon-ic-arrow-right-alt'
  | 'icon-ic-emoji'
  | 'icon-ic-merge-tag'
  | 'ic-graph-chart'
  | 'ic-graph-donut'
  | 'ic-graph-line'
  | 'ic-graph-table'
  | 'ic-delete-alt'
  | 'ic-check-alt'
  | 'ic-eye-off'
  | 'ic-eye'
  | 'ic-link'
  | 'ic-aa'
  | 'ic-arrow-up'
  | 'ic-arrow-down'
  | 'ic-warning'
  | 'ic-card'
  | 'ic-img'
  | 'ic-contact'
  | 'ic-credit'
  | 'ic-point'
  | 'ic-asc'
  | 'ic-notice'
  | 'ic-option'
  | 'ic-checkbox-active'
  | 'ic-checkbox-default'
  | 'ic-save'
  | 'ic-add'
  | 'ic-align-center'
  | 'ic-align-left'
  | 'ic-arrow-down-alt'
  | 'ic-arrow-left'
  | 'ic-arrow-right'
  | 'ic-arrow-up-alt'
  | 'ic-automation'
  | 'ic-bookmark-active'
  | 'ic-bookmark'
  | 'ic-check'
  | 'ic-delete'
  | 'ic-desktop'
  | 'ic-drag'
  | 'ic-duplicate'
  | 'ic-edit'
  | 'ic-export'
  | 'ic-file'
  | 'ic-filter'
  | 'ic-funnel'
  | 'ic-heart'
  | 'ic-info'
  | 'ic-mobile'
  | 'ic-opentooltip-fill'
  | 'ic-push'
  | 'ic-refresh'
  | 'ic-search'
  | 'ic-setting'
  | 'ic-sms'
  | 'ic-trash'
  | 'ic-user'
  | 'ic-usergroup'
  | 'ic-double-arrow-up'
  | 'ic-double-arrow-down'
  | 'ic-double-arrow-left'
  | 'ic-double-arrow-right'
  | 'ic-help-center';

export interface FontIconProps {
  name: FontIconName;
  size?: string;
  color?: string;
  handleClick?: (e: MouseEvent<HTMLDivElement>) => void;
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

export const FontIcon: React.FC<FontIconProps> = (props: FontIconProps) => {
  const themeCss = css``;
  const [isOver, setIsOver] = useState(false);
  const propsObj = props;
  return (
    <React.Fragment>
      <div
        className={`font-icon-panel ${propsObj.handleClick ? `pointer` : ''} ${props.className || ''}`}
        style={{ fontSize: propsObj.size, color: propsObj.color }}
        css={[baseCss, themeCss]}
        onClick={propsObj.handleClick}
        onMouseOver={() => {
          setIsOver(true);
        }}
        onMouseLeave={() => {
          setIsOver(false);
        }}
        onFocus={() => false}
      >
        {propsObj.name}
      </div>
      {isOver && propsObj.children}
    </React.Fragment>
  );
};
