import { css, useTheme, Theme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .guide {
      position: absolute;
      border-radius: 4px;
      border: solid 1px rgba(0, 111, 255, 0.6);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .guide-title {
        position: absolute;
        top: -1px;
        left: -1px;
        display: inline-block;
        width: fit-content;
        ${theme.text.p3};
        text-align: center;
        color: #fff;
        padding: 0 4px;
        border-radius: 4px;
        background-color: #006fff;
      }
    }
  `;
};
