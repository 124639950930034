import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { BDSRadioButton, BDSTextArea, BDSButton } from '@bigin/bigin-ui-components';

import { baseCss } from './Step3No.style';
import { BGButtonGroup } from '../../../components/BGButtonGroup/BGButtonGroup';
import { InputError } from '../../../components/InputError/InputError';
import { WrapperModal } from '../../../components/WrapperModal/WrapperModal';
import { WhatIsConsultant } from '../WhatIsConsultant/WhatIsConsultant';

enum YesOrNo {
  YES = 'yes',
  NO = 'no',
}

export interface Step3NoForm {
  needHelp: boolean;
  message: string;
}

export interface Step3NoProps {
  prev: () => void;
  onSubmit: (form: Step3NoForm) => void;
}

export const Step3No = (props: Step3NoProps): ReactElement => {
  const i18next = useTranslation();

  const [needHelp, setNeedHelp] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [whatIsConsultant, setWhatIsConsultant] = useState<boolean>(false);
  const { formState, register, setValue, handleSubmit } = useForm<Step3NoForm>({ mode: 'all' });

  useEffect(() => {
    register('needHelp', {
      validate: (value: boolean | undefined) => {
        if (value === undefined) {
          return i18next.t('필수 입력란입니다.') as string;
        }

        return true;
      },
    });
    register('message');
  });

  return (
    <div css={[baseCss]}>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <div className="form-input-wrapper">
          <p className="form-label">
            {i18next.t('Bigin Ads 컨설턴트의 도움이 필요하신가요?')}
            <br />
            <span
              className="form-label-help"
              onClick={() => {
                setWhatIsConsultant(true);
              }}
            >
              {i18next.t('Bigin Ads 컨설턴트가 뭔가요?')}
            </span>
          </p>
          <BDSRadioButton
            inputProps={{
              id: `needHelp[${YesOrNo.YES}]`,
              name: 'needHelp',
              value: YesOrNo.YES,
              checked: needHelp === YesOrNo.YES,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('needHelp', event.target.value === YesOrNo.YES, { shouldValidate: true });
                setNeedHelp(event.target.value);
              },
            }}
            style={{ marginBottom: '12px' }}
            labelText={i18next.t('네, 도움이 필요해요.')}
          />
          <BDSRadioButton
            inputProps={{
              id: `needHelp[${YesOrNo.NO}]`,
              name: 'needHelp',
              value: YesOrNo.NO,
              checked: needHelp === YesOrNo.NO,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('needHelp', event.target.value === YesOrNo.YES, { shouldValidate: true });
                setNeedHelp(event.target.value);
              },
            }}
            style={{ marginBottom: '12px' }}
            labelText={i18next.t('아니요, 필요하지 않아요.')}
          />
          {formState.errors.needHelp && <InputError message={formState.errors.needHelp.message as string} />}
        </div>
        <div className="form-input-wrapper">
          <p className="form-label">{i18next.t('추가로 하고 싶은 말이 있으신가요? (선택)')}</p>
          <BDSTextArea
            textAreaProps={{
              value: message,
              placeholder: i18next.t('예) 광고 성과를 올리고 싶은데 방법을 모르겠어요.'),
              onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setValue('message', event.target.value, { shouldValidate: true });
                setMessage(event.target.value);
              },
            }}
          />
        </div>
        <BGButtonGroup rightAlign>
          <BDSButton
            key={i18next.t('이전') as string}
            label={i18next.t('이전')}
            onClick={() => {
              props.prev();
            }}
            appearance="secondary"
          />
          <BDSButton
            key={i18next.t('추천 플랜 확인') as string}
            label={i18next.t('추천 플랜 확인')}
            isDisabled={!formState.isValid}
            type="submit"
          />
        </BGButtonGroup>
      </form>

      {/* Bigin Ads 컨설턴트가 뭔가요? */}
      <WrapperModal
        isOpen={whatIsConsultant}
        close={() => {
          setWhatIsConsultant(false);
        }}
        position={{ width: '360px' }}
      >
        <WhatIsConsultant
          close={() => {
            setWhatIsConsultant(false);
          }}
        />
      </WrapperModal>
    </div>
  );
};
