import React, { ReactElement, useState } from 'react';
// eslint-disable-next-line import/no-cycle
import { CampaignAdsTableHeader } from '../../../components/CampaignAdsTable/CampaignAdsTable';
import { getUniqKey } from '../../../utils/array.utils';

export interface AdTableRowProps {
  headers: CampaignAdsTableHeader[];
  campaignType: CampaignType;
  ad: UniAd;
  defaultRowStyle?: object;
}

export const AdTableRow = (props: AdTableRowProps): ReactElement => {
  const [showTargetAd, setShowTargetAd] = useState<boolean>(false);

  return (
    <React.Fragment>
      <tr
        className="ad-list-table-row"
        style={{ ...props.defaultRowStyle }}
        onClick={() => setShowTargetAd(!showTargetAd)}
      >
        {props.headers.map((header: CampaignAdsTableHeader, cellIndex) => {
          const { cellStyle = {}, key } = header;

          return (
            <td key={getUniqKey('ad-list-table-data-cell', cellIndex)} style={{ ...cellStyle }}>
              <React.Fragment>
                {header.getDataCellElement(key, props.campaignType, props.ad, showTargetAd)}
              </React.Fragment>
            </td>
          );
        })}
      </tr>
      <React.Fragment>
        {showTargetAd && (
          <React.Fragment>
            {props.ad.fb_ads.map((fb_ad, rowIndex) => (
              <tr
                className="fb-target-table-row"
                key={getUniqKey('fb-target-table-row', rowIndex)}
                style={{ ...props.defaultRowStyle }}
              >
                {props.headers.map((header: CampaignAdsTableHeader, cellIndex) => {
                  const { cellStyle = {}, key } = header;

                  return (
                    <td key={getUniqKey('fb-target-table-cell', cellIndex)} style={{ ...cellStyle }}>
                      <React.Fragment>{header.getTargetCellElement(key, props.ad, fb_ad, 'facebook')}</React.Fragment>
                    </td>
                  );
                })}
              </tr>
            ))}
            {props.ad.aw_ads.map((aw_ad, rowIndex) => (
              <tr className="aw-target-table-row" key={getUniqKey('aw-target-table-row', rowIndex)}>
                {props.headers.map((header: CampaignAdsTableHeader, cellIndex) => {
                  const { cellStyle = {}, key } = header;

                  return (
                    <td key={getUniqKey('aw-target-table-cell', cellIndex)} style={{ ...cellStyle }}>
                      <React.Fragment>{header.getTargetCellElement(key, props.ad, aw_ad, 'google')}</React.Fragment>
                    </td>
                  );
                })}
              </tr>
            ))}
            {props.ad.kk_creatives.map((kk_ad, rowIndex) => (
              <tr className="kk-target-table-row" key={getUniqKey('kk-target-table-row', rowIndex)}>
                {props.headers.map((header: CampaignAdsTableHeader, cellIndex) => {
                  const { cellStyle = {}, key } = header;

                  return (
                    <td key={getUniqKey('kk-target-table-cell', cellIndex)} style={{ ...cellStyle }}>
                      <React.Fragment>{header.getTargetCellElement(key, props.ad, kk_ad, 'kakao')}</React.Fragment>
                    </td>
                  );
                })}
              </tr>
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};
