import React, { useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { BDSButton } from '@bigin/bigin-ui-components';
import { useMatch } from 'react-router-dom';
import { appendDefaultEditor, createMarkupEditorShapeStyleControls, PinturaEditor } from '../../../../pintura/pintura';
import locale from '../../../../pintura/locale';
import '../../../../pintura/pintura.css';
import '../../../../pintura/font.css';
import { IMAGE_PROXY_URL } from '../../../../utils/utils';
import StorageService from '../../../../services/StorageService';
import { useDataStore } from '../../../../context/Store';
import { ProductApi } from '../../../../lib/api/ProductApi';

export interface ImageEditorProps {
  product?: Product;
  image: ImageTypes;
  type?: { type: 'creative' | 'create-campaign'; value: String; ratio: '1:1' | '1.91:1' | '2:1' };
  onEditImage: ({ image, template }: { image: File; template: string }) => void;
  handleClose: () => void;
}
export const ImageEditor = ({
  product,
  image,
  type = { type: 'creative', value: '', ratio: '1:1' },
  onEditImage,
  handleClose,
}: ImageEditorProps) => {
  const i18next = useTranslation();
  const { toastStore } = useDataStore();

  const [pin, setPin] = useState<PinturaEditor | null>(null);
  const [, setIsImageOverMinimumSize] = useState<{ isChecked: boolean; sizeInfo: string }>({
    isChecked: true,
    sizeInfo: '',
  });
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const creativeMatch = useMatch('/malls/:mallId/creative');

  const logos = useMemo(() => {
    const results: string[] = [];
    const mall = StorageService.selectedMall;
    if (!mall) return results;

    if (mall.square_logo_url) results.push(`${IMAGE_PROXY_URL}${mall.square_logo_url}`);
    if (mall.landscape_logo_url) results.push(`${IMAGE_PROXY_URL}${mall.landscape_logo_url}`);
    return results;
  }, []);

  const removeImageState = () => {
    localStorage.removeItem(`${StorageService.selectedMall.id}_saved_imageState${image.original_id ? '(new)' : ''}`);
  };

  const loadImageTemplate = () => {
    return JSON.parse(image.square_template) || undefined;
  };

  const setEditor = async (src: string) => {
    const imageState = loadImageTemplate();

    const pintura = appendDefaultEditor('#pintura-editor', {
      imageState,
      src: `${IMAGE_PROXY_URL}${src}`,
      locale,
      cropImageSelectionCornerStyle: 'hook',
      enableButtonExport: false,
      cropEnableInfoIndicator: true,
      cropAutoCenterImageSelectionTimeout: 1,
      stickers: logos,
      cropSelectPresetOptions: [
        [undefined, 'Custom'],
        [1, '1 : 1'],
        [1.91, '1.91 : 1'],
        [2, '2 : 1'],
      ],
      utils: ['crop', 'filter', 'finetune', 'annotate', 'sticker'],
      markupEditorShapeStyleControls: createMarkupEditorShapeStyleControls({
        fontFamilyOptions: [
          [`Apple SD Gothic Neo`, 'Apple SD Gothic Neo'],
          [`'Noto Sans KR', sans-serif`, 'Noto Sans'],
          [`GmarketSansMedium`, '지마켓 산스'],
          [`'Nanum Gothic', sans-serif`, '나눔 고딕'],
          [`'Nanum Myeongjo', serif`, '나눔 명조'],
          [`'NanumSquare', sans-serif`, '나눔 스퀘어'],
          [`CookieRunOTF`, '쿠키런'],
          [`KOMACON`, '만화진흥원체'],
          [`Cafe24Shiningstar`, '카페24 빛나는별'],
          [`SDSamliphopangche_Outline`, '산돌삼립호빵체 Outline'],
          [`BMDOHYEON`, '배민 도현체'],
          [`InkLipquid`, '잉크립퀴드'],
          [`'Black Han Sans', sans-serif`, '블랙 한 산스'],
        ],
      }),
    });

    setPin(pintura);
  };

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    const handleEditor = async () => {
      if (!image.original_id) return setEditor(image.url);
      const imageId = image.original_id ? image.original_id : image.id;

      const getImageApi =
        !isEmpty(product) && product && product.id !== -1
          ? ProductApi().getProductImage(StorageService.selectedMall.id, product?.id, imageId)
          : ProductApi().getMallUploadImage(StorageService.selectedMall.id, imageId);

      getImageApi.then((res) => {
        return setEditor(res.data.data.url);
      });
    };
    handleEditor();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkMinimumSize = (width: number, height: number, cropAspectRatio: 1 | 1.91 | undefined) => {
    if (type.type === 'create-campaign') {
      if (type.value.includes('aw')) {
        if (type.ratio === '1.91:1') {
          return { isChecked: width >= 600 && height >= 314, sizeInfo: '600x314' };
        }
        return { isChecked: width >= 300 && height >= 300, sizeInfo: '300x300' };
      }
      if (type.value.includes('kk')) {
        if (type.value === 'kk_image_banner') {
          return { isChecked: width === 1029 && height === 258, sizeInfo: '1029x258' };
        }
        if (type.value === 'kk_image_native') {
          if (type.ratio === '2:1') {
            return { isChecked: width >= 1200 && height >= 600, sizeInfo: '1200x600' };
          }
          return { isChecked: width >= 500 && height >= 500, sizeInfo: '500x500' };
        }
      } else {
        return { isChecked: width >= 600 && height >= 600, sizeInfo: '600x600' };
      }
    }
    return { isChecked: true, sizeInfo: '' };
  };

  const handleEditImage = async () => {
    if (!pin) return;
    const { width, height } = pin.imageState.crop;
    const { cropAspectRatio } = pin.imageState;
    await setIsImageOverMinimumSize((prev) => {
      return { ...prev, ...checkMinimumSize(width, height, cropAspectRatio) };
    });

    if (checkMinimumSize(width, height, cropAspectRatio).isChecked || creativeMatch) {
      const res = await pin.processImage();

      onEditImage({
        image: res.dest,
        template: JSON.stringify(res.imageState),
      });

      pin.imageState.annotation = [];
      removeImageState();
    }

    if (!checkMinimumSize(width, height, cropAspectRatio).isChecked && !creativeMatch) {
      if (type.value === 'kk_image_banner') {
        toastStore.showToast(
          'basic',
          'warning',
          i18next.t('권장 이미지 사이즈는 {{sizeInfo}} 입니다.', {
            sizeInfo: checkMinimumSize(width, height, cropAspectRatio).sizeInfo,
          }),
        );
      } else {
        toastStore.showToast(
          'basic',
          'warning',
          i18next.t('최소 이미지 사이즈는 {{sizeInfo}} 입니다.', {
            sizeInfo: checkMinimumSize(width, height, cropAspectRatio).sizeInfo,
          }),
        );
      }
    }

    setTimeout(() => {
      setIsImageOverMinimumSize((prev) => {
        return { ...prev, isChecked: true };
      });
    }, 4200);
  };

  const handleDownload = async () => {
    if (pin) {
      setIsDownloading(true);
      const res = await pin.processImage();
      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = URL.createObjectURL(res.dest);
      link.download = `${product?.name}`;
      link.click();
      setIsDownloading(false);
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <div id="pintura-editor" style={{ width: '100%', height: '652px', marginTop: '8px' }} />
      <BDSButton
        appearance="secondary"
        fontIcon="ic-save"
        fontIconColor="#7e8696"
        label={i18next.t('수정 이미지 다운로드')}
        style={{ position: 'absolute', top: '20px', right: '20px' }}
        isDisabled={isDownloading}
        onClick={handleDownload}
      />
      <div className="editor-modal-footer">
        <BDSButton appearance="secondary" onClick={() => handleClose()} label={i18next.t('취소')} />
        <BDSButton onClick={() => handleEditImage()} label={i18next.t('완료')} />
      </div>
    </div>
  );
};
