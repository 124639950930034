import { css } from '@emotion/react';

export const baseCss = css`
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    margin-bottom: 24px;
  }
`;
