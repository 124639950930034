import React, { ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { BorderSection } from '../../../components/BorderSection/BorderSection';
import { baseCss } from './CreateImageSummary.style';
import Template from '../../../recoil/Template';
import { ImageGuide } from '../ImageGuide/ImageGuide';

export interface CreateImageSummaryProps {
  step: number;
  isRequestTemplate: boolean;
  imageRemains: number;
}

export const CreateImageSummary = (props: CreateImageSummaryProps): ReactElement => {
  const i18next = useTranslation();
  const selectedTemplateState = useRecoilValue(Template.selectedTemplate);
  const [isOver, setIsOver] = useState(false);

  return (
    <div style={{ position: 'sticky', background: '#f9fafb', top: 124 }}>
      <section css={[baseCss]} className="campaign-summary-floating-section">
        <BorderSection>
          <div className="create-image-summary-area detail">
            <div className="name-wrapper">
              <div className="name">{i18next.t('무료 생성 가능 이미지 수')}</div>
            </div>
            <div className="table-wrapper">
              <div className="row short">
                <span className="text-disclaimer">{i18next.t('잔여 수량')}</span>
                <span className="text-p3 text-right text-color-main ">
                  {i18next.t('{{remains}}개', {
                    remains: props.imageRemains,
                  })}
                </span>
              </div>
              <div className="row short">
                <span className="text-p3 text-left text-color-main">
                  {i18next.t('매월 100개의 이미지 생성 초과 시, 개당 1,000 크레딧 부과됩니다.')}
                </span>
              </div>
            </div>
          </div>
        </BorderSection>
        {props.step === 2 && (
          <BorderSection>
            <div className="create-image-summary-area detail">
              <div className="name-wrapper">
                <div className="name">{i18next.t('선택한 템플릿')}</div>
              </div>
              <div className="table-wrapper">
                <div className="row " style={{ paddingBottom: 0 }}>
                  <div
                    className="example-image-wrapper"
                    onMouseOver={() => {
                      setIsOver(true);
                    }}
                    onMouseLeave={() => {
                      setIsOver(false);
                    }}
                    onFocus={() => false}
                  >
                    {!isOver && (
                      <div className="image-guide-wrapper">
                        <ImageGuide id={selectedTemplateState.template_id} />
                      </div>
                    )}
                    <img
                      className="example-image"
                      src={selectedTemplateState.thumbnail_url}
                      alt={selectedTemplateState.name}
                    />
                  </div>
                </div>
                {!props.isRequestTemplate && (
                  <div className="row short" style={{ border: 'none' }}>
                    <span className="text-p3 text-left text-color-main">
                      {i18next.t('해당 영역 안에 마우스를 올리면 \n예시 이미지만 볼 수 있습니다.')}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </BorderSection>
        )}
      </section>
    </div>
  );
};
