import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { BDSRadioButton, BDSTextArea, BDSButton, BDSCheckbox } from '@bigin/bigin-ui-components';

import { baseCss } from './Step3Yes.style';
import { BGButtonGroup } from '../../../components/BGButtonGroup/BGButtonGroup';
import { InputError } from '../../../components/InputError/InputError';
import { WrapperModal } from '../../../components/WrapperModal/WrapperModal';
import { WhatIsConsultant } from '../WhatIsConsultant/WhatIsConsultant';
import { BGInput } from '../../../components/BGInput/BGInput';

enum YesOrNo {
  YES = 'yes',
  NO = 'no',
}

enum Difficult {
  IMAGE_CREATIVE = 'image_creative',
  TARGETING = 'targeting',
  ANALYSIS = 'analysis',
  DASHBOARD = 'dashboard',
  ETC = 'etc',
}

export interface Step3YesForm {
  difficults: Difficult[];
  difficultEtc: string;
  needHelp: boolean;
  message: string;
}

export interface Step3YesProps {
  prev: () => void;
  onSubmit: (form: Step3YesForm) => void;
}

export const Step3Yes = (props: Step3YesProps): ReactElement => {
  const i18next = useTranslation();

  const [difficults, setDifficults] = useState<Difficult[]>([]);
  const [difficultEtc, setDifficultEtc] = useState<string>();
  const [needHelp, setNeedHelp] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [whatIsConsultant, setWhatIsConsultant] = useState<boolean>(false);
  const { formState, register, setValue, handleSubmit } = useForm<Step3YesForm>({ mode: 'all' });

  useEffect(() => {
    register('difficults');
    register('difficultEtc');
    register('needHelp', {
      validate: (value: boolean | undefined) => {
        if (value === undefined) {
          return i18next.t('필수 입력란입니다.') as string;
        }

        return true;
      },
    });
    register('message');
  });

  const toggleDifficult = (difficult: Difficult) => {
    const checked = difficults.indexOf(difficult) === -1;
    const news = difficults.filter((item) => item !== difficult);
    if (checked) {
      news.push(difficult);
    }

    setValue('difficults', news, { shouldValidate: true });
    setDifficults(news);
  };

  return (
    <div css={[baseCss]}>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <div className="form-input-wrapper">
          <p className="form-label">{i18next.t('디지털 광고를 집행하면서\n가장 어려웠던 부분이 무엇인가요?')}</p>
          {[Difficult.IMAGE_CREATIVE, Difficult.TARGETING, Difficult.ANALYSIS, Difficult.DASHBOARD, Difficult.ETC].map(
            (difficult) => (
              <div className="checkbox-wrapper">
                <BDSCheckbox
                  size={16}
                  checked={difficults.indexOf(difficult) > -1}
                  onChange={() => {
                    toggleDifficult(difficult);
                  }}
                />
                <span
                  className="checkbox-label"
                  onClick={() => {
                    toggleDifficult(difficult);
                  }}
                >
                  {difficult === Difficult.IMAGE_CREATIVE && i18next.t('이미지 소재 제작 및 관리')}
                  {difficult === Difficult.TARGETING && i18next.t('광고 타겟팅 및 구조 개선')}
                  {difficult === Difficult.ANALYSIS && i18next.t('데이터 분석 및 인사이트 도출')}
                  {difficult === Difficult.DASHBOARD && i18next.t('마케팅 대시보드 구축')}
                  {difficult === Difficult.ETC && i18next.t('기타')}
                </span>
              </div>
            ),
          )}
          {difficults.includes(Difficult.ETC) && (
            <BGInput
              inputProps={{
                type: 'text',
                placeholder: i18next.t(''),
                value: difficultEtc,
                onChange: (event) => {
                  setValue('difficultEtc', event.target.value, { shouldValidate: true });
                  setDifficultEtc(event.target.value);
                },
              }}
            />
          )}
        </div>
        <div className="form-input-wrapper">
          <p className="form-label">
            {i18next.t('Bigin Ads 컨설턴트의 도움이 필요하신가요?')}
            <br />
            <span
              className="form-label-help"
              onClick={() => {
                setWhatIsConsultant(true);
              }}
            >
              {i18next.t('Bigin Ads 컨설턴트가 뭔가요?')}
            </span>
          </p>
          <BDSRadioButton
            inputProps={{
              id: `needHelp[${YesOrNo.YES}]`,
              name: 'needHelp',
              value: YesOrNo.YES,
              checked: needHelp === YesOrNo.YES,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('needHelp', event.target.value === YesOrNo.YES, { shouldValidate: true });
                setNeedHelp(event.target.value);
              },
            }}
            style={{ marginBottom: '12px' }}
            labelText={i18next.t('네, 도움이 필요해요.')}
          />
          <BDSRadioButton
            inputProps={{
              id: `needHelp[${YesOrNo.NO}]`,
              name: 'needHelp',
              value: YesOrNo.NO,
              checked: needHelp === YesOrNo.NO,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('needHelp', event.target.value === YesOrNo.YES, { shouldValidate: true });
                setNeedHelp(event.target.value);
              },
            }}
            style={{ marginBottom: '12px' }}
            labelText={i18next.t('아니요, 필요하지 않아요.')}
          />
          {formState.errors.needHelp && <InputError message={formState.errors.needHelp.message as string} />}
        </div>
        <div className="form-input-wrapper">
          <p className="form-label">{i18next.t('추가로 하고 싶은 말이 있으신가요? (선택)')}</p>
          <BDSTextArea
            textAreaProps={{
              value: message,
              placeholder: i18next.t('예) 광고 성과를 올리고 싶은데 방법을 모르겠어요.'),
              onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
                setValue('message', event.target.value, { shouldValidate: true });
                setMessage(event.target.value);
              },
            }}
          />
        </div>
        <BGButtonGroup rightAlign>
          <BDSButton
            key={i18next.t('이전') as string}
            label={i18next.t('이전')}
            onClick={() => {
              props.prev();
            }}
            appearance="secondary"
          />
          <BDSButton
            key={i18next.t('추천 플랜 확인') as string}
            label={i18next.t('추천 플랜 확인')}
            isDisabled={!formState.isValid}
            type="submit"
          />
        </BGButtonGroup>
      </form>

      {/* Bigin Ads 컨설턴트가 뭔가요? */}
      <WrapperModal
        isOpen={whatIsConsultant}
        close={() => {
          setWhatIsConsultant(false);
        }}
        position={{ width: '360px' }}
      >
        <WhatIsConsultant
          close={() => {
            setWhatIsConsultant(false);
          }}
        />
      </WrapperModal>
    </div>
  );
};
