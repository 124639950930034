import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx, css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton, BDSCheckbox, BDSFontIcon, BDSInput, BDSSelectBox } from '@bigin/bigin-ui-components';
import { useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import { difference } from 'lodash';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { baseCss } from './CreateTarget.style';
import { BorderSection } from '../../components/BorderSection/BorderSection';
import { CampaignForAudience, FacebookApi } from '../../lib/api/FacebookApi';
import StorageService from '../../services/StorageService';
import { MediaApi } from '../../lib/api/MediaApi';
import { useDataStore } from '../../context/Store';
import { MallApi } from '../../lib/api/MallApi';
import { InputError } from '../../components/InputError/InputError';
import useScript from '../../hooks/useScript';
import { getUniqKey } from '../../utils/array.utils';
import { BGEmpty } from '../../components/BGEmpty/BGEmpty';
import { StyledDefaultEmptyOverlayComponent } from '../../components/CampiagnListTable/CampaignListTable.style';

type FbAdAccount = {
  id: string;
  name: string;
  business?: {
    id: string;
  };
  user_tasks: string[];
};

interface FbABAudienceForm {
  selectedFbAdAccount: FbAdAccount;
  name: string;
  ratio: string;
}

const MIN_RESULTS = 100;
const MIN_RATIO = 1;
const MAX_RATIO = 20;
const MAX_NAME_LENGTH = 50;
const REQUIRED_USER_TASK = 'ADVERTISE';

export const CreateTarget = (): ReactElement => {
  useScript('https://connect.facebook.net/en_US/sdk.js');
  const promisifiedFB = useMemo(
    () => ({
      init: (params: InitParams): void => {
        return window.FB.init(params);
      },
      getLoginStatus: (force?: boolean): Promise<LoginResponse> => {
        return new Promise((resolve) => {
          window.FB.getLoginStatus((response: LoginResponse) => {
            resolve(response);
          }, force);
        });
      },
      login: (opts?: LoginOptions): Promise<LoginResponse> => {
        return new Promise((resolve) => {
          window.FB.login((response: LoginResponse) => {
            resolve(response);
          }, opts);
        });
      },
      logout: (): Promise<LoginResponse> => {
        return new Promise((resolve) => {
          window.FB.logout((response: LoginResponse) => {
            resolve(response);
          });
        });
      },
    }),
    [window.FB],
  );

  const i18next = useTranslation();
  const { dialogStore } = useDataStore();
  const { register, formState, setValue } = useForm<FbABAudienceForm>({ mode: 'all' });
  const [name, setName] = useState<string>('');
  const [ratio, setRatio] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [campaignIds, setCampaignIds] = useState<string[]>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);

  const { data: mall } = useQuery<Mall>(
    'mallCompany',
    async () => (await MallApi().getMall(StorageService.selectedMall.id, { includes: ['medias', 'company'] })).data,
    { refetchOnMount: 'always' },
  );

  const [selectedFbAdAccount, setSelectedFbAdAccount] = useState<FbAdAccount | undefined>();

  const {
    data: fbAdAccounts,
    isFetching: isFetchingFbAdAccounts,
    refetch: refetchFbAdAccounts,
    remove: removeFbAdAccounts,
  } = useQuery<FbAdAccount[]>(
    'fbAdAccounts',
    async () => (await FacebookApi().getFBAdAccounts(StorageService.selectedMall.id)).data.data,
    {
      enabled: false,
      onSuccess: (res) => {
        const initialFbAdAccount = mall && mall.medias.find((item) => item.type === 'fb-ad-account');
        const findFbAccount = res && res.find((account) => account.id === initialFbAdAccount?.media_id);

        if (findFbAccount) {
          const init = { id: findFbAccount.id, name: findFbAccount.name, user_tasks: findFbAccount?.user_tasks };
          setSelectedFbAdAccount(init);
          setValue('selectedFbAdAccount', init, { shouldValidate: true });
        }
      },
    },
  );

  const {
    data: isLoggedIn,
    refetch: refetchIsLoggedIn,
    isLoading: isLoadingLoggedIn,
    remove: removeIsLoggedIn,
  } = useQuery('fbAuth', async () => (await MediaApi().getMediaAuthByType('facebook')).data.data.is_logged_in, {
    onSuccess: () => {
      refetchFbAdAccounts();
    },
  });

  const getCampaignForAudience = async (id: string) => {
    return (await FacebookApi().getCampaignForAudience(id)).data.data;
  };

  const selectedFbAdAccountId = selectedFbAdAccount?.id as string;

  const { data: campaignForAudience, isLoading: isLoadingCampaignForAudience } = useQuery<CampaignForAudience[]>(
    ['campaignForAudience', selectedFbAdAccountId],
    () => getCampaignForAudience(selectedFbAdAccountId as string),
    { enabled: !!selectedFbAdAccountId },
  );

  const filteredCampaignForAudience = useMemo(() => {
    if (!campaignForAudience) return [];

    let resultCampaigns = campaignForAudience as CampaignForAudience[];

    if (resultCampaigns && search !== '') {
      resultCampaigns = resultCampaigns.filter(
        (campaign) =>
          campaign.name.toLowerCase().includes(search.toLowerCase()) ||
          campaign.id.toLowerCase().includes(search.toLowerCase()),
      );
    }
    return resultCampaigns;
  }, [campaignForAudience, search]);

  const handleCheckCampaigns = (selectedId: string) => {
    const checked = campaignIds.indexOf(selectedId) === -1;
    const news = campaignIds.filter((item) => item !== selectedId);
    if (checked) {
      news.push(selectedId);
    }
    setCampaignIds(news);
  };

  const toggleAllCampaigns = (isAllChecked: boolean) => {
    if (isAllChecked) {
      setCampaignIds([]);
    } else {
      const filteredValue = filteredCampaignForAudience.filter((campaign) => campaign.results >= MIN_RESULTS);
      const ids = filteredValue.reduce((acc, cur) => {
        acc.push(cur.id);
        return acc;
      }, [] as string[]);
      setCampaignIds(ids);
    }
    setAllChecked(!isAllChecked);
  };

  useEffect(() => {
    register('selectedFbAdAccount', {
      value: selectedFbAdAccount,
      validate: (account: FbAdAccount) => {
        if (account && (account.user_tasks === null || !account.user_tasks.includes(REQUIRED_USER_TASK))) {
          return i18next.t('위 광고 계정의 캠페인 관리 권한이 없어서 타겟을 생성할 수 없습니다.') as string;
        }
        return true;
      },
    });
    register('ratio', {
      value: ratio,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        const tempValue = parseInt(value, 10);
        if (tempValue < MIN_RATIO || tempValue > MAX_RATIO) {
          return i18next.t('{{min}}이상 {{max}}이하의 자연수만 입력할 수 있습니다.', {
            min: MIN_RATIO,
            max: MAX_RATIO,
          }) as string;
        }

        return true;
      },
    });
    register('name', {
      value: name,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (value.length > MAX_NAME_LENGTH) {
          return i18next.t('{{length}}자 이하로 입력해주세요', { length: MAX_NAME_LENGTH }) as string;
        }

        return true;
      },
    });
  }, []);

  const requiredScopes = [
    'business_management',
    'ads_management',
    'ads_read',
    'pages_manage_ads',
    'pages_manage_metadata',
    'pages_read_engagement',
    'pages_read_user_content',
    'pages_manage_posts',
    'pages_manage_engagement',
    'catalog_management',
  ];

  const login = async () => {
    if (isLoggedIn) {
      return;
    }
    const validateAndFinish = (loginResponse: LoginResponse): boolean | string => {
      if (loginResponse.status !== 'connected') {
        return i18next.t('로그인이 중간에 취소 되었어요.\n다시 로그인해주세요!') as string;
      }

      const grantedScopes = loginResponse.authResponse.grantedScopes.split(',');
      if (difference(requiredScopes, grantedScopes).length !== 0) {
        return i18next.t('일부 권한이 누락되었어요.\n모든 권한을 승인해주세요!') as string;
      }

      MediaApi()
        .putMediaAuth('facebook', loginResponse.authResponse.accessToken)
        .then(() => {
          return refetchIsLoggedIn();
        })
        .catch((error) => {
          promisifiedFB.logout();

          if (axios.isAxiosError(error)) {
            const response = error?.response;
            if (response && response.status === 422) {
              dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

              return;
            }
          }

          dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
          throw error;
        });
      return false;
    };

    promisifiedFB.init({
      appId: String(process.env.REACT_APP_FACEBOOK_APP_ID),
      status: true,
      cookie: true,
      xfbml: true,
      version: 'v12.0',
    });

    const loginOptions = { scope: requiredScopes.join(','), return_scopes: true };
    const message = validateAndFinish(await promisifiedFB.login(loginOptions));
    if (typeof message === 'string') {
      await dialogStore.showMessage(i18next.t('알림'), message);
      await promisifiedFB.logout();
    }
  };

  const logout = async () => {
    return MediaApi()
      .deleteMediaAuth('facebook')
      .then(() => {
        removeFbAdAccounts();
        removeIsLoggedIn();
        refetchIsLoggedIn();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  const renderSkeleton = () => {
    const skeleton = [];
    for (let i = 0; i < 10; i += 1) {
      skeleton.push(
        <tr className="skeleton-table-row" key={getUniqKey('skeleton-table-row', i)}>
          <td
            key={getUniqKey('skeleton-header-name-cell', i)}
            css={css`
              .skeleton {
                border-radius: 0;
              }
            `}
            style={{ padding: '16px' }}
          >
            <Skeleton className="skeleton" style={{ width: '100%' }} />
          </td>
          <td
            key={getUniqKey('skeleton-header-value-cell', i)}
            css={css`
              .skeleton {
                border-radius: 0;
              }
            `}
            style={{ padding: '16px' }}
          >
            <Skeleton className="skeleton" style={{ width: '100%' }} />
          </td>
        </tr>,
      );
    }

    return skeleton;
  };

  return (
    <div css={[baseCss]}>
      <TopNavBar
        title={i18next.t('Meta 캠페인 전환 유사 타겟 생성')}
        button={[
          {
            id: 1,
            label: i18next.t('타겟 생성'),
            isDisabled: !formState.isValid || campaignIds.length < 1,
            onClick: async () => {
              if (selectedFbAdAccount) {
                const data = {
                  campaign_ids: campaignIds,
                  ratio: parseInt(ratio, 10),
                  name,
                };
                await FacebookApi()
                  .postCampaignAudience(selectedFbAdAccount.id, data)
                  .then(() => {
                    dialogStore.showMessage(
                      i18next.t('유사 타겟 생성 완료'),
                      i18next.t(
                        '생성된 캠페인 전환 유사 타겟은 Meta에서 직접 활용할 수 있습니다. 솔루션에서는 활용할 수 없습니다.',
                      ),
                    );
                  })
                  .catch((e) => {
                    console.error(e);
                  });
              }
            },
          },
        ]}
      />
      <div className="create-target-container">
        <BorderSection style={{ width: '100%' }}>
          <div className="target-setting-wrapper">
            <div className="title-wrapper">
              <div className="title">{i18next.t('타겟 설정')}</div>
              <div className="description">
                {i18next.t('특정 캠페인에서 전환을 일으킨 고객을 기준으로 유사 타겟을 생성합니다.')}
                <br />
                {i18next.t('생성된 타겟은 Meta에서 직접 활용할 수 있습니다. (솔루션 내에서 활용 불가)')}
              </div>
            </div>
            <div className="target-setting-contents">
              <div className="table-wrapper">
                <div className="row">
                  <span className="item-title">{i18next.t('Meta 사용자 계정')}</span>
                  <span className="item-content">
                    {!isLoggedIn && <BDSButton label={i18next.t('로그인')} onClick={login} />}
                    {isLoggedIn && <BDSButton appearance="secondary" label={i18next.t('로그아웃')} onClick={logout} />}
                  </span>
                </div>
                <div className="row">
                  <span className="item-title">{i18next.t('Meta 광고 계정')}</span>
                  <span className="item-content">
                    {isFetchingFbAdAccounts && <Skeleton style={{ height: '36px' }} />}
                    {!isFetchingFbAdAccounts && !isLoggedIn && (
                      <div className="select-wrapper" onClick={login}>
                        <BDSSelectBox
                          disabled
                          appearance="gray"
                          defaultValue={selectedFbAdAccount}
                          displayValue={(item: any) =>
                            item ? `${item.name}(${item.id})` : i18next.t('광고계정 연동 필요')
                          }
                          handleUpdate={() => null}
                          isSelected={() => false}
                          list={[]}
                        />
                      </div>
                    )}
                    {!isFetchingFbAdAccounts && isLoggedIn && (
                      <React.Fragment>
                        <BDSSelectBox
                          error={!!formState.errors.selectedFbAdAccount}
                          appearance="gray"
                          defaultValue={selectedFbAdAccount}
                          displayValue={(item: any) =>
                            item
                              ? `${item.name}(${item.id}) ${
                                  item.user_tasks === null || !item.user_tasks.includes(REQUIRED_USER_TASK)
                                    ? i18next.t('#권한 부족')
                                    : ''
                                }`
                              : i18next.t('광고계정 연동 필요')
                          }
                          handleUpdate={(item: any) => {
                            setValue('selectedFbAdAccount', item, { shouldValidate: true });
                            setSelectedFbAdAccount(item);
                            setAllChecked(false);
                            setCampaignIds([]);
                          }}
                          isSelected={(item: any) => item.id === selectedFbAdAccount?.id}
                          list={fbAdAccounts || []}
                        />
                        {formState.errors.selectedFbAdAccount && (
                          <InputError message={(formState.errors.selectedFbAdAccount as any).message as string} />
                        )}
                      </React.Fragment>
                    )}
                  </span>
                </div>
                <div className="row">
                  <span className="item-title">{i18next.t('타겟 유사율')}</span>
                  <span className="item-content">
                    <BDSInput
                      error={!!formState.errors.ratio}
                      inputProps={{
                        name: 'ratio',
                        placeholder: i18next.t('1-20 (자연수만 입력)'),
                        value: ratio,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                          const target = event.target as HTMLInputElement;
                          target.value = target.value.replace(/[^0-9]/gi, '').replace(/(\..*)\./gi, '$1');
                          setRatio(target.value);
                          setValue('ratio', target.value, { shouldValidate: true });
                        },
                      }}
                      rightLabelComponent={<span className="text-h5 text-right text-color-sub">{i18next.t('%')}</span>}
                    />
                    {formState.errors.ratio && <InputError message={formState.errors.ratio.message as string} />}
                  </span>
                </div>
                <div className="row">
                  <span className="item-title">{i18next.t('타겟 이름')}</span>
                  <span className="item-content">
                    <BDSInput
                      error={!!formState.errors.name}
                      inputProps={{
                        name: 'name',
                        placeholder: i18next.t('이름을 입력해주세요.'),
                        value: name,
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                          setValue('name', event.target.value, { shouldValidate: true });
                          setName(event.target.value);
                        },
                      }}
                      customLength={name.length}
                      customLengthLimit={50}
                      lengthUnit={i18next.t('자')}
                    />
                    {formState.errors.name && <InputError message={formState.errors.name.message as string} />}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </BorderSection>
        <BorderSection style={{ width: '100%' }}>
          <div className="campaign-setting-wrapper">
            <div className="title-wrapper">
              <div className="title">{i18next.t('기준 캠페인 선택')}</div>
              <div className="description">
                {i18next.t('선택한 캠페인의 전환 고객이 유사 타겟의 기준이 됩니다.')} <br />
                {i18next.t('100건 이상의 전환이 발생된 캠페인만 선택할 수 있습니다.')}
              </div>
            </div>
            <div className="campaign-setting-contents">
              <BDSInput
                style={{
                  height: '52px',
                  marginBottom: '16px',
                  backgroundColor: '#fff',
                }}
                leftLabelComponent={<BDSFontIcon name="ic-search" size="20px" color="#7e8696" />}
                inputProps={{
                  name: 'search',
                  value: search,
                  placeholder: i18next.t('캠페인 명 및 캠페인 ID 검색'),
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearch(event.target.value);
                  },
                  style: { backgroundColor: '#fff' },
                }}
              />

              <div className="criterion-campaign-table-wrapper">
                <table className="criterion-campaign-table">
                  <thead>
                    <tr>
                      <th>{i18next.t('캠페인 명')}</th>
                      <th style={{ fontSize: 0 }}>
                        {campaignForAudience && campaignForAudience.length > 0 && (
                          <div
                            className="campaign-checkbox-wrapper"
                            onClick={() => {
                              if (!formState.errors.selectedFbAdAccount && filteredCampaignForAudience.length > 0) {
                                toggleAllCampaigns(allChecked);
                              }
                            }}
                          >
                            <BDSCheckbox checked={allChecked} size={20} />
                            {i18next.t('캠페인 전체 선택')}
                          </div>
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    className={campaignForAudience && filteredCampaignForAudience.length === 0 ? 'empty-body' : ''}
                  >
                    {campaignForAudience && filteredCampaignForAudience.length === 0 && (
                      <div className="empty-container">
                        <StyledDefaultEmptyOverlayComponent>
                          <div style={{ width: '510px' }}>
                            <BGEmpty
                              type="chart"
                              title={i18next.t('활용 가능한 캠페인이 없습니다.')}
                              description={i18next.t(
                                'Meta 사용자 계정 로그인 후, 광고 계정을 선택하면 캠페인 리스트가 노출됩니다.',
                              )}
                            />
                          </div>
                        </StyledDefaultEmptyOverlayComponent>
                      </div>
                    )}
                    {(isLoadingLoggedIn || isLoadingCampaignForAudience || selectedFbAdAccount === undefined) &&
                      renderSkeleton()}
                    {campaignForAudience &&
                      filteredCampaignForAudience.map((campaign, index) => (
                        <tr
                          key={getUniqKey('campaign-row', index)}
                          onClick={() => {
                            if (campaign.results >= MIN_RESULTS && !formState.errors.selectedFbAdAccount) {
                              handleCheckCampaigns(campaign.id);
                            }
                          }}
                        >
                          <td style={{ fontSize: 0 }}>
                            {(campaign.results < MIN_RESULTS || !!formState.errors.selectedFbAdAccount) && (
                              <div className="disabled-cell-overlay" />
                            )}
                            <div className="campaign-checkbox-wrapper">
                              <BDSCheckbox
                                disabled={campaign.results < MIN_RESULTS}
                                checked={campaignIds.includes(campaign.id)}
                                size={20}
                              />
                              {campaign.name}
                              {`(${campaign.id})`}
                            </div>
                          </td>
                          <td>
                            {(campaign.results < MIN_RESULTS || !!formState.errors.selectedFbAdAccount) && (
                              <div className="disabled-cell-overlay" />
                            )}
                            {`${campaign.result_type}  ${campaign.results ?? '-'}`}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </BorderSection>
      </div>
    </div>
  );
};
