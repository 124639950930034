import { css } from '@emotion/react';
import { GS } from '../../styles/size';
// import { TopNavBarHeight } from '../TopNavBar/TopNavBar.style';

const TopNavBarHeight = 76;

export const baseCss = css`
  &.plan {
    .wrapper-container {
      padding: 75px 0px 2.6875rem ${GS.margin}px;
      max-width: 1640px;
      min-width: ${GS.contentMinWidth}px;
    }
  }

  &.wide {
    .wrapper-container {
      max-width: 1600px;
    }
  }
  &.full {
    .wrapper-container {
      max-width: 100%;
    }
  }

  &.side-nav-collapsed {
    .top-nav-bar-panel {
      margin-left: ${GS.sideBarMinWidth}px;

      .top-nav-bar {
        width: calc(100% - ${GS.sideBarMinWidth}px);
      }
    }
  }

  .top-nav-bar-panel {
    transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    margin-left: ${GS.sideBarMaxWidth}px;
    .top-nav-bar {
      top: 0;
      position: fixed;
      z-index: 11;
      width: calc(100% - ${GS.sideBarMaxWidth}px);
    }
  }
  .wrapper-container {
    position: relative;
    &.no-padding {
      padding: 0;
    }

    max-width: ${GS.contentMaxWidth}px;
    min-width: ${GS.contentMinWidth}px;
    padding: ${TopNavBarHeight + GS.margin}px ${GS.margin}px 2.6875rem;
    height: 100%;
    min-height: 100vh;
    background-color: #f9fafb;
  }

  .button-wrapper {
    cursor: pointer;
    .kakao-button {
      cursor: pointer;
      position: fixed;
      display: flex;
      padding: 0;
      right: 20px;
      bottom: 20px;
      z-index: 90;
      box-shadow: 0 3px 21px 0 rgba(79, 86, 97, 0.05);
      width: 48px;
      height: 48px;
      border-radius: 4px;
    }
  }
`;
