import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { BDSSelectBox, BDSButton } from '@bigin/bigin-ui-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import { useForm } from 'react-hook-form';
import { baseCss } from './KkAssetModal.style';
import { BGButtonGroup } from '../BGButtonGroup/BGButtonGroup';
import StorageService from '../../services/StorageService';
import { MallApi } from '../../lib/api/MallApi';
import { BGLoadingSpinner } from '../BGLoadingSpinner/BGLoadingSpinner';
import { KakaoApi } from '../../lib/api/KakaoApi';

type KkAdAccount = {
  id: string;
  name: string;
};

type KkPixel = {
  id: string;
  name: string;
};

export interface KkAssetModalForm {
  selectedKkAdAccount: KkAdAccount;
  selectedKkPixel: KkPixel;
}

export interface KkAssetModalProps {
  types: MediaAssetType[];
  assets: MallMedia[];
  close: () => void;
  onSubmitted?: () => void;
}

export const KkAssetModal = (props: KkAssetModalProps): ReactElement => {
  const i18next = useTranslation();
  const hasKkAdAccountType = !!props.types.find((item) => item === 'kk-ad-account');
  const initialKkAdAccount = props.assets.find((item) => item.type === 'kk-ad-account');
  const [selectedKkAdAccount, setSelectedKkAdAccount] = useState<KkAdAccount | undefined>(
    initialKkAdAccount ? { id: initialKkAdAccount.media_id, name: initialKkAdAccount.name } : undefined,
  );
  const { data: kkAdAccounts, isLoading: isLoadingKkAdAccounts } = useQuery<KkAdAccount[]>(
    'kkAdAccounts',
    async () => (await KakaoApi().getKKAdAccounts(StorageService.selectedMall.id)).data.data,
  );

  const hasKkPixelType = !!props.types.find((item) => item === 'kk-track');
  const initialKkPixel = props.assets.find((item) => item.type === 'kk-track');
  const [selectedKkPixel, setSelectedKkPixel] = useState<KkPixel | undefined>(
    initialKkPixel ? { id: initialKkPixel.media_id, name: initialKkPixel.name } : undefined,
  );
  const { data: kkPixels, isLoading: isLoadingKkPixels } = useQuery<KkPixel[]>(
    'kkPixels',
    async () => (await KakaoApi().getPixels(StorageService.selectedMall.id)).data.data,
  );

  const { register, handleSubmit, setValue, formState } = useForm<KkAssetModalForm>({ mode: 'all' });
  useEffect(() => {
    register('selectedKkAdAccount', { value: selectedKkAdAccount });
    register('selectedKkPixel', { value: selectedKkPixel });
  }, []);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const onSubmit = async (form: KkAssetModalForm) => {
    setIsSubmitting(true);
    try {
      if (form.selectedKkAdAccount && form.selectedKkAdAccount?.id !== initialKkAdAccount?.media_id) {
        await MallApi().updateMallMedia(
          StorageService.selectedMall.id,
          'kk-ad-account',
          form.selectedKkAdAccount.id,
          form.selectedKkAdAccount.name,
        );
      }

      if (form.selectedKkPixel && form.selectedKkPixel?.id !== initialKkPixel?.media_id) {
        await MallApi().updateMallMedia(
          StorageService.selectedMall.id,
          'kk-track',
          form.selectedKkPixel.id,
          form.selectedKkPixel.name,
        );
      }
    } finally {
      setIsSubmitting(false);
    }

    if (props.onSubmitted) {
      props.onSubmitted();
    }
    props.close();
  };

  return (
    <form css={[baseCss]} onSubmit={handleSubmit(onSubmit)}>
      <div className="kk-asset-modal-header">
        <div className="text-h1 text-color-main">{i18next.t('계정 연동 필요')}</div>

        <p className="text-input text-color-main">
          {i18next.t('Kakao 광고를 제작하기 위해 \n최초 1회에 한하여 계정 연동이 필요합니다.')}
        </p>
      </div>
      <div className="kk-asset-modal-body">
        {hasKkAdAccountType && (
          <div className="form-input-group">
            <p className="form-input-label">AdAccount</p>
            {isLoadingKkAdAccounts && <Skeleton style={{ height: '40px' }} />}
            {!isLoadingKkAdAccounts && (
              <BDSSelectBox
                appearance="gray"
                defaultValue={selectedKkAdAccount}
                displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('광고계정 연동 필요'))}
                handleUpdate={(item: any) => {
                  setValue('selectedKkAdAccount', item, { shouldValidate: true });
                  setSelectedKkAdAccount(item);
                }}
                isSelected={(item: any) => item.id === selectedKkAdAccount?.id}
                list={kkAdAccounts || []}
              />
            )}
          </div>
        )}
        {hasKkPixelType && (
          <div className="form-input-group">
            <p className="form-input-label">Pixel</p>
            {isLoadingKkPixels && <Skeleton style={{ height: '40px' }} />}
            {!isLoadingKkPixels && (
              <BDSSelectBox
                appearance="gray"
                defaultValue={selectedKkPixel}
                displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('픽셀 연동 필요'))}
                handleUpdate={(item: any) => {
                  setValue('selectedKkPixel', item, { shouldValidate: true });
                  setSelectedKkPixel(item);
                }}
                isSelected={(item: any) => item.id === selectedKkPixel?.id}
                list={kkPixels || []}
              />
            )}
          </div>
        )}
      </div>
      <div className="kk-asset-modal-footer">
        <BGButtonGroup rightAlign>
          <BDSButton
            appearance="secondary"
            label={i18next.t('취소')}
            onClick={() => {
              props.close();
            }}
          />
          <BDSButton label={i18next.t('연동')} type="submit" isDisabled={!formState.isValid} />
        </BGButtonGroup>
      </div>

      <BGLoadingSpinner isLoading={isSubmitting} />
    </form>
  );
};
