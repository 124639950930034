import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { GS } from '../../styles/size';

export interface BGPageProps {
  direction?: 'row' | 'column';
  gutter?: number;
  className?: string;
  children: React.ReactNode;
}

const PageWrapper = styled.div<{ theme: Theme; direction: string; gutter: number }>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => props.gutter}px;
`;

export const BGPage = (props: BGPageProps): JSX.Element => {
  const { className, direction = 'column', gutter = GS.gutter, children } = props;
  const theme: Theme = useTheme();

  return (
    <PageWrapper className={className} theme={theme} direction={direction} gutter={gutter}>
      {children}
    </PageWrapper>
  );
};
