import React, { useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Backdrop } from '../../../components/Backdrop/Backdrop';
import { VideoManageView } from '../VideoManageView/VideoManageView';
import { baseCss } from './ModalVideoSelect.style';

export interface ModalVideoSelectProps {
  selectType?: SelectType;
  isOpen: string | number | boolean;
  close: () => void;
  setSelectedVideos: (videos: Video[]) => void;
}

export interface UploaderRef {
  uploadFiles: () => void;
}
export const ModalVideoSelect = ({
  selectType = 'single',
  isOpen,
  close,
  setSelectedVideos,
}: ModalVideoSelectProps): JSX.Element => {
  const i18next = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.width = '100%';

      setTimeout(() => {
        setVisible(true);
      }, 100);
    } else {
      setVisible(false);
      document.body.removeAttribute('style');
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.removeAttribute('style');
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <div className={`modal-container ${visible && 'visible'}`} css={[baseCss]}>
      <Backdrop
        handleClick={close}
        style={{
          zIndex: 99,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        }}
      />
      <div className="modal-contents-wrapper">
        <div className="modal-header">
          <div className="modal-title">{i18next.t('파일 등록')}</div>
        </div>
        <div className="modal-contents">
          <VideoManageView
            templateType="dialog"
            selectType={selectType}
            setSelectedVideos={setSelectedVideos}
            modalClose={close}
          />
        </div>
      </div>
    </div>
  );
};
