import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx, Theme, useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FontIcon } from '../FontIcon/FontIcon';
import { StyledHeaderSetUp } from './HeaderSetUp.style';
import { WrapperModal } from '../WrapperModal/WrapperModal';
import { BGLoadingSpinner } from '../BGLoadingSpinner/BGLoadingSpinner';
import { BGInput } from '../BGInput/BGInput';
import { MallApi } from '../../lib/api/MallApi';
import StorageService from '../../services/StorageService';
import { UserApi } from '../../lib/api/UserApi';
import { initGridColumn } from '../../pages/CampaignDashboard/CampaignDashboardDef';
import { multilingual } from '../../utils/multilingual.util';
import i18n from '../../i18n';

/* 프로젝트 생성 모달 상태 */
enum ModalProjectInfoStatus {
  OPENED = 'openedProjectInfo',
  CLOSED = '',
}

export interface HeaderSetUpProps {
  close: () => void;
  style?: object;
  handleClick?: () => void;
}

export const HeaderSetUp = (props: HeaderSetUpProps): ReactElement => {
  const i18next = useTranslation();
  const theme: Theme = useTheme();
  const themeCSS = StyledHeaderSetUp(theme);
  const navigate = useNavigate();
  const [projectInfoModal, setProjectInfoModal] = useState<ModalProjectInfoStatus>(ModalProjectInfoStatus.CLOSED);
  const queryClient = useQueryClient();
  const [projectList, newProjectList] = useState<Mall[]>([]);

  const findProejcts = async () => {
    const res = await MallApi().getMalls();

    return res.data.data;
  };

  const { data: projects = [], isLoading } = useQuery(
    'applicationFind',
    () => {
      return findProejcts();
    },
    { staleTime: 1000 * 30, onSettled: (data: Mall[] | undefined) => newProjectList(data || []) },
  );
  const { data: me } = useQuery<User>('me', async () => (await UserApi().getMe()).data.data, { staleTime: 1000 * 30 });

  const changeProject = async (application: Mall) => {
    const mall = (await MallApi().getMall(application.id, { includes: ['active_subscription'] })).data;
    StorageService.selectedMall = mall;
    StorageService.activePlan = mall.active_subscription;
    queryClient.invalidateQueries('remain');
    queryClient.invalidateQueries('gtmData');
    navigate(`/malls/${StorageService.selectedMall.id}/campaign-dashboard`);
    StorageService.saveGridColumns = initGridColumn();

    // mall 변경 시 해당 mall에 대한 timezone에 따라서 언어 설정 되도록 하기. START
    const mallLang = multilingual.getLanguageByTimezone(mall.timezone);
    i18n.changeLanguage(mallLang);
    StorageService.translationLanguage = mallLang;
    // mall 변경 시 해당 mall에 대한 timezone에 따라서 언어 설정 되도록 하기. END
    props.close();
  };

  const closeProjectInfoModal = () => {
    setProjectInfoModal(ModalProjectInfoStatus.CLOSED);
  };

  const updateProject = (filterList: Mall[]) => {
    newProjectList(filterList);
  };

  const updateProjectList = (search: string) => {
    if (search) {
      return projects.filter((project) => project.name.toLowerCase().includes(search.toLowerCase()));
    }
    return projects;
  };
  useEffect(() => {
    newProjectList(projects);
  }, []);

  return (
    <div className="header-setup-contents" css={themeCSS} style={props.style || {}}>
      <div className="header-project-panel">
        <div className="project-title">
          <div className="title-label">{i18next.t('쇼핑몰')}</div>
          <div
            className="project-add"
            onClick={() => {
              navigate('/new-mall');
            }}
          >
            <FontIcon name="ic-add" color="#7e8696" size="20px" />
          </div>
        </div>
        <div className="project-search" style={isLoading ? { display: 'none' } : {}}>
          <BGInput
            inputProps={{
              placeholder: i18next.t('쇼핑몰 명을 검색해주세요.'),
              onChange: (event) => {
                updateProject(updateProjectList(event.target.value));
              },
            }}
            leftLabelComponent={<FontIcon name="ic-search" size="16px" color="#cad4e7" />}
            style={{
              width: 'auto',
              height: '40px',
              padding: '8px',
              margin: '0 8px 8px 8px',
              border: 'solid 1px #dce0e8',
              borderRadius: '8px',
            }}
          />
        </div>
        <div className="project-list">
          <BGLoadingSpinner isLoading={isLoading} />
          {!isLoading &&
            projectList.map((project) => (
              <div
                key={project.id}
                className={`project-panel ${StorageService.selectedMall.id === project.id ? 'active' : ''}`}
                onClick={() => {
                  changeProject(project);
                }}
              >
                <div className="project-info-panel">
                  <div className="project-info-item">
                    <div className="project-name">{project.name}</div>
                    <div className="projectId-value">{project.id}</div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="header-identifier-panel">
        <div className="identifier-item">
          <div className="identifier-item-title">
            <div className="item-label">{i18next.t('계정')}</div>
            <div
              className="item-logout-label"
              onClick={async () => {
                UserApi()
                  .logout()
                  .then(() => {
                    StorageService.clear();

                    return navigate('/login');
                  });
              }}
            >
              {i18next.t('로그아웃')}
            </div>
          </div>
          <div className="item-value">{me?.email}</div>
        </div>
      </div>
      {/* 프로젝트 생성 모달 */}
      <WrapperModal
        isOpen={projectInfoModal}
        position={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '8px',
          width: '360px',
        }}
        close={closeProjectInfoModal}
      >
        {/* TODO */}
        <div />
      </WrapperModal>
    </div>
  );
};
