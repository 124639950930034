import StorageService from '../services/StorageService';

export interface SlideContent {
  product_image_id?: number;
  product_image?: ProductImage; // 상품 정보가 있는 경우 product image
  mall_image_id?: number;
  mall_image?: MallImage; // 상품 정보가 없는 경우 mall_image
  image_id?: number;
  title: string;
  description: string;
  url: string;
  src: string;
}

export const ImageGroupTypes: CampaignType[] = [
  'fb_single_image',
  'fb_slide_image',
  'aw_responsive_image',
  'kk_image_banner',
  'kk_image_native',
];
export const VideoGroupTypes: CampaignType[] = ['fb_single_video', 'aw_youtube'];
export const CatalogGroupTypes: CampaignType[] = [
  'fb_catalog_slide',
  'fb_collection_image',
  'fb_collection_video',
  'fb_collection_dynamic_video',
  'aw_shopping',
  'aw_smart_shopping',
  'aw_pmax',
];
export const DynamicGroupTypes: CampaignType[] = [
  'fb_dynamic',
  'fb_dynamic_image',
  'fb_dynamic_slide',
  'fb_dynamic_video',
  'fb_dynamic_dynamic_video',
  'aw_dynamic_remarketing',
];

export const SearchGroupTypes: CampaignType[] = ['aw_search'];

export const getGroup = (type: CampaignType) => {
  if (ImageGroupTypes.includes(type)) return 'image';
  if (VideoGroupTypes.includes(type)) return 'video';
  if (CatalogGroupTypes.includes(type)) return 'catalog';
  if (DynamicGroupTypes.includes(type)) return 'dynamic';
  if (SearchGroupTypes.includes(type)) return 'search';
  return 'image';
};

export const dynamicTypes: CampaignType[] = [
  'fb_dynamic',
  'fb_dynamic_image',
  'fb_dynamic_video',
  'fb_dynamic_dynamic_video',
];

export const convertGroups = (campaigns: CampaignAdSettingState[], type: 'create' | 'copy' = 'create') => {
  const groups = campaigns.reduce((acc, cur) => {
    const group = getGroup(cur.type);
    const ads = [] as CampaignAdSettingState[];

    const campaignModel = {
      ...cur,
      url: cur.url === '' ? '' : cur.url,
    };

    if (type === 'create') {
      if (dynamicTypes.includes(campaignModel.type)) {
        const collection = {
          ...campaignModel,
          fb_body: campaignModel.fb_dynamic_collection_body,
          name: campaignModel.name,
          slides: [],
          type: campaignModel.type === 'fb_dynamic' ? 'fb_dynamic_dynamic_video' : campaignModel.type,
        };
        const catalog = {
          ...campaignModel,
          fb_body: campaignModel.fb_dynamic_catalog_body,
          name: campaignModel.name,
          slides: [],
          url: '',
          type: 'fb_dynamic_slide' as CampaignType,
        };
        ads.push(...[collection, catalog]);
      } else if (campaignModel.type === 'fb_catalog_slide') {
        ads.push({
          ...campaignModel,
          slides: [],
        });
      } else if (campaignModel.type.includes('kk')) {
        ads.push({
          ...campaignModel,
          kk_profile_name: campaignModel.type === 'kk_image_native' ? StorageService.selectedMall.name : '',
          kk_alt_text: StorageService.selectedMall.name,
        });
      } else {
        ads.push(campaignModel);
      }
    } else if (type === 'copy') {
      ads.push(campaignModel);
    }

    const buildGroupKey = (groupType: string, groupAds: CampaignAdSettingState[]) => {
      const keyElements = [];
      keyElements.push(groupType);
      if (groupAds.find((adItem) => adItem.type === 'kk_image_banner')) {
        keyElements.push('kk_image_banner');
      } else if (groupAds.find((adItem) => adItem.type === 'kk_image_native')) {
        keyElements.push('kk_image_native');
      }

      return keyElements.join('|');
    };
    const curGroupKey = buildGroupKey(group, ads);

    const findGroup = acc.find((item) => buildGroupKey(item.type, item.ads) === curGroupKey);
    if (findGroup) {
      findGroup.ads.push(...ads);
    } else {
      acc.push({
        type: group,
        ads: [...ads],
        category_id: group === 'dynamic' ? (ads[0].category_id as number) : undefined,
      });
    }
    return acc;
  }, [] as { type: 'image' | 'video' | 'catalog' | 'dynamic' | 'search'; ads: CampaignAdSettingState[]; category_id?: number }[]);
  return groups;
};
