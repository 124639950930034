import { atom } from 'recoil';

const category = atom<Category[]>({
  key: 'category',
  default: [],
});

export default {
  category,
};
