import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { SkeletonTheme } from 'react-loading-skeleton';
import { isEmpty } from 'lodash';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { BDSButton } from '@bigin/bigin-ui-components';
import _ from 'lodash';
import { baseCss, leftWrapper, rightWrapper, successCreateImageModalCss } from './CreateImage.style';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { BGTab, ITab } from '../../components/BGTab/BGTab';
import { Step1TemplateSetting } from '../Creative/Step1TemplateSetting/Step1TemplateSetting';
import { CreateImageSummary } from '../Creative/CreateImageSummary/CreateImageSummary';
import { BGButtonProps } from '../../components/BGButton/BGButton';
import { Step2ImageSetting } from '../Creative/Step2ImageSetting/Step2ImageSetting';
import StorageService from '../../services/StorageService';
import { AssetsApi, Jobs, Remains, TemplateFormList } from '../../lib/api/AssetsApi';
import Template from '../../recoil/Template';
import { WrapperModal } from '../../components/WrapperModal/WrapperModal';
import { DialogConfirm } from '../../components/DialogConfirm/DialogConfirm';
import { MallApi } from '../../lib/api/MallApi';
import { LogoUploadModal } from '../../components/LogoUploadModal/LogoUploadModal';
import { filters } from '../../utils/filter';

enum STEP {
  STEP1 = 1,
  STEP2 = 2,
}
export const CreateImage = (): ReactElement => {
  const i18next = useTranslation();

  const topNavTab = [
    {
      key: 'template-setting',
      label: i18next.t('템플릿 선택'),
    },
    {
      key: 'image-setting',
      label: i18next.t('이미지 설정'),
    },
  ];

  let saveTimerId: number;
  const [step, setStep] = useState<number>(STEP.STEP1);
  const [disabledCreateImage, setDisabledCreateImage] = useState<boolean>(false);
  const [selectedTopNavTab, setSelectedTopNavTab] = useState<ITab>(topNavTab[0]);
  const [topNavButton, setTopNavButton] = useState<BGButtonProps[] | []>([]);
  const [loadTemporaryModal, setLoadTemporaryModal] = useState('');
  const [saveTemporaryModal, setSaveTemporaryModal] = useState('');
  const [successCreateImageModal, setSuccessCreateImageModal] = useState<boolean>(false);
  const [logoUploadModal, setLogoUploadModal] = useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [selectedTemplateState, setSelectedTemplateState] = useRecoilState(Template.selectedTemplate);
  const [templateFormListState, setTemplateFormListState] = useRecoilState(Template.templateFormList);
  const resetSelectedTemplateState = useResetRecoilState(Template.selectedTemplate);
  const resetTemplateFormListState = useResetRecoilState(Template.templateFormList);
  const [imageRemainCount, setImageRemainCount] = useState<number>(0);
  const [disabledNextStep, setDisabledNextStep] = useState<boolean>(true);
  const [isRequestTemplate, setIsRequestTemplate] = useState<boolean>(false);

  const { data: mall, refetch: refetchMall } = useQuery<Mall>(
    'mall',
    async () => (await MallApi().getMall(StorageService.selectedMall.id, { includes: ['medias'] })).data,
  );
  const { data: imageRemains } = useQuery<Remains>(
    'imageRemains',
    async () => (await AssetsApi().getAssetsRemainCount(StorageService.selectedMall.id, 'asset_image')).data.data,
  );
  const { data: templateRemains } = useQuery<Remains>(
    'templateRemains',
    async () => (await AssetsApi().getAssetsRemainCount(StorageService.selectedMall.id, 'asset_template')).data.data,
  );

  const { data: commonTemplates } = useQuery('getCommonTemplates', async () => {
    return (await AssetsApi().getTemplates(StorageService.selectedMall.id, 'common')).data.data;
  });
  const { data: requestedTemplates } = useQuery('getRequestedTemplates', async () => {
    return (await AssetsApi().getTemplates(StorageService.selectedMall.id, 'requested')).data.data;
  });

  useEffect(() => {
    if (requestedTemplates?.includes(selectedTemplateState)) {
      setIsRequestTemplate(true);
    } else {
      setIsRequestTemplate(false);
    }
  }, [selectedTemplateState]);

  const closeModal = () => {
    setSaveTemporaryModal('');
    setLoadTemporaryModal('');
  };

  const navigate = useNavigate();

  const handleTopNavTab = (newStep: STEP) => {
    if (newStep === STEP.STEP1) {
      setSelectedTopNavTab(topNavTab[0]);
    } else if (newStep === STEP.STEP2) {
      setSelectedTopNavTab(topNavTab[1]);
    }
  };

  const savedDataKey = `${StorageService.selectedMall?.id}_saved_image_template`;
  const savedData = localStorage.getItem(savedDataKey);

  const loadTemporaryData = () => {
    if (savedData) {
      const data = JSON.parse(savedData) as {
        template: any;
        templateForm: TemplateFormList[];
      };

      setSelectedTemplateState(data.template);
      setTemplateFormListState(data.templateForm);
    }
  };
  const saveTemporary = () => {
    window.clearInterval(saveTimerId);
    saveTimerId = window.setTimeout(() => {
      const saveData = {
        template: selectedTemplateState,
        templateForm: templateFormListState,
      };
      localStorage.setItem(savedDataKey, JSON.stringify(saveData));
    }, 3000);
    setSaveTemporaryModal('');
  };
  const deleteTemporary = () => {
    localStorage.removeItem(savedDataKey);
  };

  // componentDidMount
  useEffect(() => {
    (function checkSaveData() {
      if (savedData) {
        setLoadTemporaryModal('load');
      }
    })();

    if (!StorageService.selectedMall.landscape_logo_url) {
      setLogoUploadModal(true);
    }

    resetSelectedTemplateState();
    resetTemplateFormListState();
    setIsFirstLoad(false);

    // componentWillUnmount
    return () => {
      resetSelectedTemplateState();
      resetTemplateFormListState();
    };
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      saveTemporary();
    }
  }, [selectedTemplateState, templateFormListState]);

  const handleCreateImage = async () => {
    const tempTemplateFormListState = _.cloneDeep(templateFormListState);

    const tempJobs = tempTemplateFormListState.reduce((acc, cur) => {
      cur.properties.forEach((property) => {
        // eslint-disable-next-line no-param-reassign
        return delete property.maxLength;
      });

      const jobs = {
        product_id: cur.product_id,
        metadata: cur.properties,
      } as Jobs;

      acc.push(jobs);
      return acc;
    }, [] as any);

    const option = {
      template_id: selectedTemplateState.id,
      jobs: tempJobs,
    };

    await AssetsApi()
      .postCreateImage(StorageService.selectedMall.id, option)
      .then(() => {
        AssetsApi()
          .getAssetsRemainCount(StorageService.selectedMall.id, 'asset_image')
          .then((resData) => {
            setImageRemainCount(resData.data.data.remains);
            setSuccessCreateImageModal(true);
          });
      });
  };

  const handleSuccessCreateImate = () => {
    setSuccessCreateImageModal(false);
    navigate(`/malls/${StorageService.selectedMall.id}/creative`);
    setTemplateFormListState([]);
    setSelectedTemplateState({});
    resetSelectedTemplateState();
    resetTemplateFormListState();
    window.clearInterval(saveTimerId);
    deleteTemporary();
  };

  // TODO: 소재제작 레벨3
  // const handleSendEmail = async () => {
  //   await AssetsApi()
  //     .postSendEmailTemplate(StorageService.selectedMall.id)
  //     .then((res) => {
  //       dialogStore.showMessage(
  //         i18next.t('제작 대행 요청'),
  //         i18next.t(
  //           '{{email}}로 \n고급 이미지 제작 대행 요청 양식을 발송했습니다.\n양식을 작성하여 회신하시면 요청이 접수됩니다.',
  //           { email: res.data.data.to },
  //         ),
  //       );
  //     });
  // };

  const setTopNavBtn = () => {
    const btn = [];
    if (step === STEP.STEP2) {
      btn.push({
        id: 0,
        label: i18next.t('이전'),
        appearance: 'secondary',
        onClick: () => {
          setStep(step - 1);
          handleTopNavTab(step - 1);
        },
        style: { marginRight: '8px' },
      });
    }

    btn.push({
      id: 1,
      label: i18next.t('임시 저장'),
      appearance: 'secondary',
      onClick: () => {
        setSaveTemporaryModal('save');
      },
      style: { marginRight: '8px' },
    });

    if (step === STEP.STEP2) {
      btn.push({
        id: 4,
        isDisabled: disabledCreateImage,
        label: i18next.t('이미지 생성'),
        onClick: async () => {
          await handleCreateImage();
        },
      });
    }

    if (step === STEP.STEP1) {
      if (isEmpty(selectedTemplateState) || isEmpty(StorageService.selectedMall.landscape_logo_url)) {
        const errors = [];
        if (isEmpty(selectedTemplateState)) {
          errors.push(i18next.t('템플릿을 선택해주세요.') as string);
        }

        if (isEmpty(StorageService.selectedMall.landscape_logo_url)) {
          errors.push(i18next.t('로고를 등록해주세요.') as string);
        }

        btn.push({
          id: 3,
          label: i18next.t('다음'),
          isDisabled: disabledNextStep,
          isUnclickable: true,
          className: 'isDisabled',
          hoverable: false,
          tooltip: (
            <React.Fragment>
              {errors.map((error) => (
                <div key={error}>
                  <div>{`· ${error}`}</div>
                </div>
              ))}
            </React.Fragment>
          ),
        });
      } else {
        btn.push({
          id: 2,
          label: i18next.t('다음'),
          onClick: () => {
            setTimeout(() => {
              setStep(step + 1);
              handleTopNavTab(step + 1);
            }, 10);
          },
        });
      }
    }

    setTopNavButton(btn);
  };

  useEffect(() => {
    setDisabledNextStep(isEmpty(selectedTemplateState) || isEmpty(StorageService.selectedMall.landscape_logo_url));
    setTopNavBtn();
  }, [step, selectedTemplateState, templateFormListState]);

  useEffect(() => {
    if (isEmpty(selectedTemplateState) && commonTemplates) {
      setSelectedTemplateState(commonTemplates[0]);
    }
  }, []);

  return (
    <SkeletonTheme baseColor="#edf1f6" highlightColor="#ffffff">
      <div css={[baseCss]}>
        <TopNavBar style={{ border: 0 }} title={i18next.t('이미지 생성')} button={topNavButton} />
        <BGTab
          fixed
          showArrow
          tabList={topNavTab}
          selectedTab={selectedTopNavTab}
          handleTab={(tab) => {
            if (tab.key === 'template-setting') {
              setStep(STEP.STEP1);
              setSelectedTopNavTab(tab);
            } else if (tab.key === 'image-setting') {
              if (!disabledNextStep) {
                setStep(STEP.STEP2);
                setSelectedTopNavTab(tab);
              }
            }
          }}
        />

        <div className="create-image-container flex-row" style={{ marginTop: '28px' }}>
          <div css={[leftWrapper]} className="left-wrapper flex-col">
            {/* TODO: 소재제작 레벨3
            {step === STEP.STEP1 && (
              <div className="request-image-banner-container">
                <div className="contents-wrapper">
                  <img src="/assets/img/graphic-banner.svg" alt="" />
                  <div className="typography-wrapper">
                    <div className="text-h3 text-color-main">{i18next.t('내 상품과어울리는 광고 소재를 제작하고 싶으신가요?')}</div>
                    <div className="text-input text-color-main">
                      {i18next.t('템플릿을 활용하여 제작하기 어렵다면, 맞춤 이미지 제작 대행 서비스를 사용해보세요.')}
                    </div>
                  </div>
                </div>
                <BDSButton
                  label={i18next.t('제작 대행 요청하기')}
                  onClick={() => {
                    handleSendEmail();
                  }}
                />
              </div>
            )} */}
            {step === STEP.STEP1 && commonTemplates && requestedTemplates && (
              <Step1TemplateSetting
                key="create-image-step1"
                templateRemains={templateRemains ? templateRemains.remains : 0}
                commonTemplates={commonTemplates}
                requestedTemplates={requestedTemplates}
              />
            )}
            {step === STEP.STEP2 && (
              <Step2ImageSetting
                key="create-image-step2"
                templateId={selectedTemplateState.id}
                updateTemplateFormList={setTemplateFormListState}
                setCurrentValid={(valid: boolean) => setDisabledCreateImage(!valid)}
              />
            )}
          </div>
          <div css={[rightWrapper]} className="right-wrapper flex-col">
            <CreateImageSummary
              step={step}
              isRequestTemplate={isRequestTemplate}
              imageRemains={imageRemains ? imageRemains.remains : 0}
            />
          </div>
        </div>
      </div>

      {/* 쇼핑몰 로고 업로드 모달 */}
      {mall && (
        <WrapperModal
          isOpen={logoUploadModal}
          close={() => {
            setLogoUploadModal(false);
          }}
          position={{ width: '360px' }}
        >
          <LogoUploadModal
            square={StorageService.selectedMall.square_logo_url}
            landscape={StorageService.selectedMall.landscape_logo_url}
            media="image"
            close={() => {
              setLogoUploadModal(false);
            }}
            onSubmitted={() => {
              refetchMall();
            }}
          />
        </WrapperModal>
      )}

      {/* 임시 저장 모달 */}
      <WrapperModal isOpen={saveTemporaryModal} close={closeModal} isDialogConfirm>
        <DialogConfirm
          title={i18next.t('임시 저장')}
          desc={i18next.t('설정을 임시 저장하시겠어요?\n먼저 저장된 설정이 있다면 지금 설정으로 대체되어 저장됩니다.')}
          buttons={[
            { label: i18next.t('취소'), appearance: 'secondary', handleClick: closeModal },
            { label: i18next.t('저장'), handleClick: saveTemporary },
          ]}
        />
      </WrapperModal>

      {/* 임시 저장 불러오기 모달 */}
      <WrapperModal isOpen={loadTemporaryModal} isDialogConfirm>
        <DialogConfirm
          title={i18next.t('이미지 생성')}
          desc={i18next.t('임시 저장한 설정이 있어요.\n해당 설정을 불러올까요?')}
          buttons={[
            {
              label: i18next.t('새롭게 생성'),
              appearance: 'secondary',
              handleClick: () => {
                deleteTemporary();
                closeModal();
              },
            },
            {
              label: i18next.t('불러오기'),
              handleClick: () => {
                loadTemporaryData();
                closeModal();
              },
            },
          ]}
        />
      </WrapperModal>

      {/* 생성 완료 모달 */}
      <WrapperModal
        position={{ width: '320px' }}
        isOpen={successCreateImageModal}
        close={() => {
          setSuccessCreateImageModal(false);
        }}
      >
        <div css={[successCreateImageModalCss]}>
          <div className="text-h1 text-color-main">{i18next.t('이미지 생성 완료')}</div>
          <div className="success-modal-body">
            <div className="text-input text-color-main">
              {imageRemainCount === 0
                ? i18next.t(
                    '무료로 생성 가능한 이미지 잔여 수량이 부족하여 {{credit}} 크레딧이 차감되었습니다. \n{{count}}개의 이미지가 생성됩니다.',
                    {
                      credit: filters.formatNumber(templateFormListState.length * 1000),
                      count: templateFormListState.length,
                    },
                  )
                : i18next.t('{{count}}개의 이미지가 생성됩니다.\n남은 생성 가능 이미지 수는 {{remains}}개 입니다.', {
                    count: templateFormListState.length,
                    remains: imageRemainCount,
                  })}
            </div>
            <div className="text-disclaimer mt-4">
              {i18next.t('*생성된 이미지는 ')}
              <span style={{ textDecoration: 'underline' }}>{i18next.t('5분 내외로')}</span>
              <br />
              {i18next.t('크리에이티브에서 확인할 수 있습니다.')}
            </div>
          </div>
          <div className="success-modal-footer">
            <BDSButton label={i18next.t('확인')} onClick={handleSuccessCreateImate} />
          </div>
        </div>
      </WrapperModal>
    </SkeletonTheme>
  );
};
