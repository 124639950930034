import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { baseCss } from './DoubleArrowBtn.style';

export const DoubleArrowBtn = (): JSX.Element => {
  return (
    <div className="side-nav-bar-button" css={baseCss}>
      <BDSFontIcon name="ic-double-arrow-left" size="24px" color="#7e8696" />
    </div>
  );
};
