import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, Theme, useTheme } from '@emotion/react';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next';
import { BDSFontIcon, BDSToggleButton } from '@bigin/bigin-ui-components';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { AdMoreButton } from './AdMoreButton';
import { getMediaIconsByCampaignType, IMAGE_PROXY_URL } from '../../../utils/utils';
import { campaignTypeText, targetingTypeText } from '../../../utils/text';
import { getMoreButtonObject } from '../CampiagnReportDef';
import StorageService from '../../../services/StorageService';
import CampaignSetting from '../../../recoil/CampaignSetting';
import { BGTooltip } from '../../../components/BGTooltip/BGTooltip';

export interface AdTitleProps {
  type: string;
  item?: UniAdsByType | UniAd | FbAd | AwAd;
  campaignId: number;
  handleStatusToggle: (item: UniAdsByType | UniAd | FbAd | AwAd, media?: string) => void;
  handleShowPreview: (item: UniAd) => void;
  media?: string;
  toggle?: boolean;
  hideToggleIcon?: boolean;
  campaignType: CampaignType;
}

export const AdTitle = ({
  type,
  item,
  campaignId,
  handleStatusToggle,
  handleShowPreview,
  toggle = false,
  hideToggleIcon = false,
  media = 'facebook',
  campaignType,
}: AdTitleProps): JSX.Element => {
  const i18next = useTranslation();
  const theme: Theme = useTheme();
  const moreButtonObject = getMoreButtonObject(i18next);
  const navigate = useNavigate();
  const resetCampaignState = useResetRecoilState(CampaignSetting.campaignSetting);
  const resetCampaignAdState = useResetRecoilState(CampaignSetting.campaignAdSetting);

  const statusColor = () => {
    if (!item) return 'warning';
    const uniAd = item as UniAd;
    if (uniAd.status_tag.code === 'CAMPAIGN_STATUS_LIVE') {
      return 'success';
    }

    if (uniAd.status_tag.code === 'CAMPAIGN_STATUS_REJECTED') {
      return 'error';
    }

    if (uniAd.status_tag.code === 'CAMPAIGN_STATUS_END' || uniAd.status_tag.code === 'CAMPAIGN_STATUS_NON_ACTIVE') {
      return 'icon';
    }

    return 'warning';
  };

  const statusText = () => {
    const uniAd = item as UniAd;

    if (uniAd.status_tag.code === 'CAMPAIGN_STATUS_LIVE') {
      return i18next.t('라이브');
    }

    if (uniAd.status_tag.code === 'CAMPAIGN_STATUS_REJECTED') {
      return i18next.t('심사 거절');
    }

    if (uniAd.status_tag.code === 'CAMPAIGN_STATUS_END' || uniAd.status_tag.code === 'CAMPAIGN_STATUS_NON_ACTIVE') {
      return i18next.t('비활성');
    }

    return i18next.t('심사 중');
  };

  const handleAddCampaign = () => {
    resetCampaignState();
    resetCampaignAdState();
    navigate(`/malls/${StorageService.selectedMall.id}/add-campaign?id=${campaignId}&type=${campaignType}`);
  };

  const handleEditAd = (curAd: UniAd) => {
    resetCampaignState();
    resetCampaignAdState();
    navigate(
      `/malls/${StorageService.selectedMall.id}/edit-ad?id=${curAd.id}&campaignId=${campaignId}&type=${campaignType}`,
    );
  };

  const imageBox = (uniAd: UniAd) => {
    if (!campaignType.includes('shopping') && uniAd.thumbnail_image) {
      return (
        <img
          css={css`
            width: 40px;
            height: 40px;
            border-radius: 3px;
            border: solid 1px #dce0e8;
          `}
          className="cell-icon"
          src={`${IMAGE_PROXY_URL}${(item as UniAd).thumbnail_image}`}
          alt={(item as UniAd).name}
        />
      );
    }
    if (campaignType.includes('shopping')) {
      return (
        <div
          css={css`
            width: 40px;
            height: 40px;
            border-radius: 3px;
            border: solid 1px #dce0e8;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
          `}
        >
          <img src="/assets/img/common/icons-google-shopping.svg" alt="" />
        </div>
      );
    }
    return (
      <div
        css={css`
          width: 40px;
          height: 40px;
          border-radius: 3px;
          border: solid 1px #dce0e8;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #edf1f6;
        `}
      >
        <BDSFontIcon name="ic-img" size="20px" color="#7e8696" />
      </div>
    );
  };

  return (
    <div
      css={css`
        font-size: 14px;
        font-weight: bold;
        color: ${theme.colors.mainFont};
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 20px;
        letter-spacing: normal;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
    >
      {item && type === 'group' && (
        <React.Fragment>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {getMediaIconsByCampaignType((item as UniAdsByType).type).map((icon) => (
              <img
                key={icon}
                css={css`
                  width: 24px;
                  height: 24px;
                `}
                src={`/assets/img/card_icons/${icon}.png`}
                alt=""
              />
            ))}
            <span
              css={css`
                margin-left: 4px;
              `}
            >
              {i18next.t(campaignTypeText[(item as UniAdsByType).type])}
            </span>
          </div>
          {!campaignType.includes('fb_dynamic') &&
            !campaignType.includes('aw_dynamic') &&
            !campaignType.includes('shopping') && (
              <div>
                <BGTooltip title={i18next.t('소재 추가') as string}>
                  <div className="tooltip-icon-wrapper">
                    <button
                      type="button"
                      css={css`
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        background: #edf1f6;
                        &:hover {
                          background: #fff;
                        }
                      `}
                      onClick={() => {
                        handleAddCampaign();
                      }}
                    >
                      <BDSFontIcon name="ic-add" size="20px" color="#7e8696" />
                    </button>
                  </div>
                </BGTooltip>
              </div>
            )}
        </React.Fragment>
      )}
      {item && type === 'default' && (
        <div
          css={css`
            width: 100%;
            display: flex;
            gap: 8px;
          `}
        >
          {imageBox(item as UniAd)}
          <div
            css={css`
              width: 295px;
              display: flex;
              align-items: flex-end;
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                max-width: 90%;
              `}
            >
              <div>
                <span
                  css={css`
                    width: 8px;
                    height: 8px;
                    border-radius: 10px;
                    display: inline-block;
                  `}
                  style={{
                    border: `solid 2px ${theme.colors[statusColor()]}`,
                    backgroundColor: theme.colors[statusColor()],
                  }}
                />
                <span className="text-disclaimer" style={{ marginLeft: 4 }}>
                  {statusText()}
                </span>
              </div>
              <BGTooltip
                title={
                  (item && i18next.t((item as UniAd).name || i18next.t(campaignTypeText[(item as UniAd).types[0]]))) ||
                  ''
                }
              >
                <div
                  css={css`
                    margin-right: 4px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  `}
                >
                  {item && i18next.t((item as UniAd).name || i18next.t(campaignTypeText[(item as UniAd).types[0]]))}
                </div>
              </BGTooltip>
            </div>
            {!hideToggleIcon && (
              <BDSFontIcon name={toggle ? 'ic-arrow-up' : 'ic-arrow-down'} color="#7e8696" size="20px" />
            )}
          </div>
          <div
            css={css`
              display: inline-flex;
              gap: 16px;
              align-items: center;
            `}
          >
            <BDSToggleButton
              active={((item as UniAd).status === 'active') as boolean}
              onChangeToggle={() => handleStatusToggle(item as UniAd)}
            />

            <AdMoreButton
              ad={item as UniAd}
              getMoreButtonList={() => {
                const buttonItems = [];
                buttonItems.push(moreButtonObject.preview);

                if (
                  !campaignType.includes('fb_dynamic') &&
                  !campaignType.includes('aw_dynamic') &&
                  !campaignType.includes('aw_pmax')
                ) {
                  buttonItems.push(moreButtonObject.modify);
                }

                return buttonItems;
              }}
              handleMoreButton={(curAd, value: string) => {
                if (value === 'modify') {
                  handleEditAd(curAd);
                } else if (value === 'preview') {
                  handleShowPreview(curAd);
                }
              }}
            />
          </div>
        </div>
      )}
      {item && type === 'target' && (
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              width: 295px;
              display: flex;
              align-items: center;
            `}
          >
            <BDSFontIcon name="ic-arrow-right" color="#7e8696" size="20px" />
            <div
              css={css`
                margin-right: 4px;
              `}
            >
              {(item && i18next.t(targetingTypeText[(item as FbAd | AwAd).targeting_type])) || ''}
            </div>
          </div>
          <div
            css={css`
              display: inline-flex;
              gap: 16px;
              align-items: center;
            `}
          >
            <BDSToggleButton
              active={(item as FbAd | AwAd).is_use as boolean}
              onChangeToggle={() => handleStatusToggle(item as FbAd | AwAd, media)}
            />
          </div>
        </div>
      )}
    </div>
  );
};
