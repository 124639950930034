import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton, BDSSelectBox, BDSTextArea } from '@bigin/bigin-ui-components';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { AxiosPromise } from 'axios';
import { useMatch } from 'react-router-dom';
import _ from 'lodash';
import { BGUrlSelector } from '../../../../components/BGUrlSelector/BGUrlSelector';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss, categoryModalCss } from './FacebookSingleCollection.style';
import CampaignSetting from '../../../../recoil/CampaignSetting';
import { MaterialCheckboxSelector } from '../../MaterialCheckboxSelector/MaterialCheckboxSelector';
import { PreviewFacebookItem } from '../PreviewFacebookItem/PreviewFacebookItem';
import StorageService from '../../../../services/StorageService';
import { WrapperModal } from '../../../../components/WrapperModal/WrapperModal';
import { CategorySelector } from '../CategorySelector/CategorySelector';
import { ProductApi } from '../../../../lib/api/ProductApi';
import { MallApi } from '../../../../lib/api/MallApi';
import { ModalImageSelect } from '../../../Creative/ModalImageSelect/ModalImageSelect';
import { ModalVideoSelect } from '../../../Creative/ModalVideoSelect/ModalVideoSelect';
import { InputError } from '../../../../components/InputError/InputError';
import { ModalCategoryImageSelector } from '../../../Creative/ModalCategoryImageSelect/ModalCategoryImageSelect';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { facebookCTA } from '../../../../constants/campaigns';
import { BGTagInput } from '../../../../components/BGTagInput/BGTagInput';
import { AssetHeader } from '../../../../components/AssetHeader/AssetHeader';

export interface FacebookSingleCollectionProps {
  setting: CampaignAdSettingState;
  handleUpdate: (setting: CampaignAdSettingState) => void;
  handleValid?: (type: CampaignType, isValid: boolean) => void;
  removeSelectedCampaignTypes: (type: CampaignType) => void;
}

interface FacebookSingleCollectionForm {
  name: string;
  title: string;
  description: string;
  body: string;
  url: string;
  image_id?: number | undefined | null;
  video_id?: number | undefined | null;
  category_id?: number | undefined | null;
}

export type ContentType = 'image' | 'video' | 'dynamic_video';

type Button = {
  label: string;
  handleClick?: () => void;
  appearance?: string;
};

const CategorySelectorModalWrapper: React.FC<{
  isShow: boolean;
  buttons: Array<Button>;
  categories: Category[];
  selectedCategory: Category | undefined;
  selectedParentCategory: Category | undefined;
  setCategory: (category: Category | undefined) => void;
  setParentCategory: (category: Category | undefined) => void;
}> = (props: {
  isShow: boolean;
  buttons: Array<Button>;
  categories: Category[];
  selectedCategory: Category | undefined;
  selectedParentCategory: Category | undefined;
  setCategory: (category: Category | undefined) => void;
  setParentCategory: (category: Category | undefined) => void;
}) => {
  return (
    <React.Fragment>
      <div css={[categoryModalCss]}>
        <WrapperModal isOpen={`${props.isShow ? 'true' : ''}`}>
          <CategorySelector
            buttons={props.buttons}
            categories={props.categories}
            selectedCategory={props.selectedCategory}
            selectedParentCategory={props.selectedParentCategory}
            setCategory={props.setCategory}
            setParentCategory={props.setParentCategory}
          />
        </WrapperModal>
      </div>
    </React.Fragment>
  );
};

export const FacebookSingleCollection = (props: FacebookSingleCollectionProps): ReactElement => {
  const i18next = useTranslation();

  const [showCategorySelector, setShowCategorySelector] = useState<boolean>(false);
  const [showModalImageSelect, setShowModalImageSelect] = useState<boolean>(false);
  const [showModalVideoSelect, setShowModalVideoSelect] = useState<boolean>(false);
  const [showChangeDefaultImageSelector, setShowChangeDefaultImageSelector] = useState<boolean>(false);

  const editMatch = useMatch('/malls/:mallId/edit-ad');
  const addMatch = useMatch('/malls/:mallId/add-campaign');

  const [selectedTitleTemplate] = useState<string>(props.setting?.fb_dynamic_slide_title);
  const [selectedDescriptionTemplate] = useState<string>(props.setting?.fb_dynamic_slide_description);

  const [selectedCTA, setSelectedCTA] = useState<FacebookCTA>(
    props.setting?.fb_call_to_action ? props.setting?.fb_call_to_action : 'shop_now',
  );
  const [name, setName] = useState(props.setting?.name);
  const [title, setTitle] = useState(props.setting?.fb_collection_title);
  const [body, setBody] = useState(props.setting?.fb_body);
  const [contentType, setContentType] = useState<ContentType>(
    props.setting?.type.replace('fb_collection_', '') as ContentType,
  );
  const [url, setUrl] = useState(props.setting?.url);
  const [valueLock, setValueLock] = useState<boolean>(false);
  const [selectedParentCategory, setSelectedParentCategory] = useState<Category | undefined>(undefined);
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>(props.setting?.category_id);
  const [categoriesState, setCategoriesState] = useState<Category[]>([] as Category[]);
  const [categoryProducts, setCategoryProducts] = useState<Product[]>([] as Product[]);
  const [biginKeywords, setBiginKeywords] = useState<string[]>(props.setting?.bigin_keywords);

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);

  const { register, unregister, formState, setValue, trigger } = useForm<FacebookSingleCollectionForm>({
    mode: 'onChange',
  });

  const fetchCategoryProducts = async (categoryId: number) => {
    const query = `((categories ct ${categoryId}))`;
    const res = await ProductApi().getProducts(StorageService.selectedMall?.id ?? null, {
      query,
      page: 1,
      per_page: 3,
      includes: ['images'],
    });
    setCategoryProducts(res.data.data);
  };

  const updateCategoryProduct = () => {
    if (props.setting?.category_id) {
      fetchCategoryProducts(props.setting?.category_id);
    } else {
      setCategoryProducts([]);
    }
  };

  const handleDefaultImages = async (images: ProductImage[]) => {
    const payloads: AxiosPromise<{ data: ProductImage }>[] = [];
    images.forEach((image) => {
      payloads.push(ProductApi().updateDefaultImage(StorageService.selectedMall.id, image.product_id, image.id));
    });
    await Promise.all(payloads);
    setShowChangeDefaultImageSelector(false);
    if (props.setting?.category_id) fetchCategoryProducts(props.setting?.category_id);
  };

  const hasCategoryId = useMemo(() => {
    if (props.setting?.category_id) return true;
    return false;
  }, [props.setting?.category_id]);

  const updateContentType = (curContentType: ContentType) => {
    if (curContentType === 'image') {
      setContentType('image');
      props.handleUpdate({
        ...props?.setting,
        type: `fb_collection_${curContentType}`,
        mall_video: undefined,
        mall_video_id: undefined,
      });
      unregister('video_id');
      register('image_id', {
        value: (props.setting?.mall_image || props.setting?.product_image)?.id,
        required: i18next.t('필수 입력란입니다.') as string,
      });
    } else if (curContentType === 'video') {
      setContentType('video');
      props.handleUpdate({
        ...props?.setting,
        type: `fb_collection_${curContentType}`,
        mall_image: undefined,
        mall_image_id: undefined,
        product_image: undefined,
        product_image_id: undefined,
      });
      unregister('image_id');
      register('video_id', {
        value: props.setting?.mall_video?.id,
        required: i18next.t('필수 입력란입니다.') as string,
      });
    } else if (curContentType === 'dynamic_video') {
      setContentType('dynamic_video');
      unregister('video_id');
      unregister('image_id');
      props.handleUpdate({
        ...props?.setting,
        type: `fb_collection_${curContentType}`,
        mall_video: undefined,
        mall_video_id: undefined,
        mall_image: undefined,
        mall_image_id: undefined,
        product_image: undefined,
        product_image_id: undefined,
      });
    }
    trigger();
  };

  const updateName = (curName: string) => {
    props.handleUpdate({ ...props?.setting, name: curName });
  };

  const updateCollectionTitle = (curCollectionTitle: string) => {
    props.handleUpdate({ ...props?.setting, fb_collection_title: curCollectionTitle });
  };

  const updateBody = (curBody: string) => {
    props.handleUpdate({ ...props?.setting, fb_body: curBody });
  };

  const updateUrl = (curUrl: string) => {
    props.handleUpdate({ ...props?.setting, url: curUrl });
  };

  const updateCTA = (curCTA: FacebookCTA) => {
    props.handleUpdate({ ...props?.setting, fb_call_to_action: curCTA });
  };

  const updateDynamicSlideTitle = (curTemplateTitle: string) => {
    props.handleUpdate({ ...props?.setting, fb_dynamic_slide_title: curTemplateTitle });
  };

  const updateDynamicSlideDescription = (curTemplateDescription: string) => {
    props.handleUpdate({ ...props?.setting, fb_dynamic_slide_description: curTemplateDescription });
  };

  const updateCategoryId = (curCategoryId: number) => {
    props.handleUpdate({ ...props?.setting, category_id: curCategoryId });
    updateCategoryProduct();
    trigger();
  };

  const updateBiginKeywords = (curKeywords: string[]) => {
    props.handleUpdate({ ...props?.setting, bigin_keywords: curKeywords });
  };

  const buttons = [
    {
      label: i18next.t('취소'),
      appearance: 'secondary',
      handleClick: () => {
        setShowCategorySelector(false);
      },
    },
    {
      label: i18next.t('선택'),
      handleClick: () => {
        setShowCategorySelector(false);
        setSelectedCategoryId(selectedCategory?.id);
        setValue('category_id', selectedCategory?.id, { shouldValidate: true });
      },
    },
  ];

  const updateSingleImage = (curImage: MallImage | ProductImage | ImageType) => {
    if ('product_id' in curImage) {
      const newUrl = curImage.product_id ? curImage.detail_url : props.setting?.url || '';

      props.handleUpdate({
        ...props?.setting,
        product_image: curImage,
        product_image_id: curImage.id,
        mall_image: undefined,
        mall_image_id: undefined,
        url: newUrl,
      });
      setUrl(newUrl);
      setValue('url', newUrl);
    } else {
      props.handleUpdate({
        ...props?.setting,
        mall_image: curImage,
        mall_image_id: curImage.id,
        product_image: undefined,
        product_image_id: undefined,
      });
    }
  };
  const updateSingleVideo = (curVideo: Video) => {
    props.handleUpdate({ ...props?.setting, mall_video: curVideo, mall_video_id: curVideo.id });
  };

  const selectedImages = useMemo(() => {
    return (props.setting.mall_image || props.setting.product_image || []) as ImageTypes[];
  }, [props.setting]);

  const handleSelectImages = (images: ImageTypes[]) => {
    updateSingleImage(images[0]);
    setValue('image_id', images[0].id, { shouldValidate: true });
    setShowModalImageSelect(false);
    trigger();
  };

  const handleSelectVideos = (videos: Video[]) => {
    updateSingleVideo(videos[0]);
    setValue('video_id', videos[0].id, { shouldValidate: true });
    setShowModalVideoSelect(false);
    trigger();
  };

  useEffect(() => {
    if (name !== props.setting?.name) {
      updateName(name);
    }
    if (title !== props.setting?.fb_collection_title) {
      updateCollectionTitle(title);
    }
    if (body !== props.setting?.fb_body) {
      updateBody(body);
    }
    if (!valueLock) {
      if (url !== props.setting?.url) {
        updateUrl(url);
      }
    }
    if (selectedCTA && selectedCTA !== props.setting?.fb_call_to_action) {
      updateCTA(selectedCTA);
    }
    if (selectedTitleTemplate && selectedTitleTemplate !== props.setting?.fb_dynamic_slide_title) {
      updateDynamicSlideTitle(selectedTitleTemplate);
    }
    if (selectedDescriptionTemplate && selectedDescriptionTemplate !== props.setting?.fb_dynamic_slide_description) {
      updateDynamicSlideDescription(selectedDescriptionTemplate);
    }
    if (selectedCategoryId && selectedCategoryId !== props.setting?.category_id) {
      updateCategoryId(selectedCategoryId);
    }
    if (!_.isEqual(biginKeywords, props.setting?.bigin_keywords)) {
      updateBiginKeywords(biginKeywords);
    }
    setValueLock(false);
  }, [
    name,
    title,
    body,
    selectedCTA,
    url,
    selectedTitleTemplate,
    selectedDescriptionTemplate,
    contentType,
    selectedCategoryId,
    biginKeywords,
  ]);

  const fetchCategories = async () => {
    const res = await MallApi().getCategories(StorageService.selectedMall?.id ?? null, {
      includes: ['product_summary'],
    });
    setCategoriesState(res.data.data);
    return res.data.data as Category[];
  };

  const findCategory = (categoryId: number) => {
    let tempCategory: Category | undefined;

    // eslint-disable-next-line consistent-return
    const searchCategory = (category: Category, findCategoryId: number) => {
      if (category.id === findCategoryId) {
        tempCategory = category;
        return tempCategory;
      }
      category.children.map((childCategory) => searchCategory(childCategory, categoryId));
    };

    categoriesState.map((category) => searchCategory(category, categoryId));

    return tempCategory as Category | undefined;
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    async function getCategories() {
      if (props.setting?.category_id) {
        setSelectedCategory(findCategory(props.setting?.category_id));
      }
    }
    getCategories();
  }, [categoriesState]);

  useEffect(() => {
    if (selectedCategory) {
      if (selectedCategory.parent_id) {
        setSelectedParentCategory(findCategory(selectedCategory.parent_id));
      } else {
        setSelectedParentCategory(selectedCategory);
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    updateCategoryProduct();
  }, [props.setting?.category_id]);

  useEffect(() => {
    register('url', {
      value: url,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isURL(value)) {
          return i18next.t('잘못된 URL 형식입니다.') as string;
        }

        return true;
      },
    });
    register('body', {
      value: body,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('title', {
      value: title,
      required: i18next.t('필수 입력란입니다.') as string,
    });

    register('category_id', {
      value: selectedCategoryId,
      required: i18next.t('필수 입력란입니다.') as string,
    });

    if (contentType === 'image') {
      register('image_id', {
        value: (props.setting?.mall_image || props.setting?.product_image)?.id,
        required: i18next.t('필수 입력란입니다.') as string,
      });
    } else if (contentType === 'video') {
      register('video_id', {
        value: props.setting?.mall_video?.id,
        required: i18next.t('필수 입력란입니다.') as string,
      });
    }
  }, []);

  useEffect(() => {
    if (props.handleValid) {
      props.handleValid(props.setting?.type, formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <BorderSection>
      <div className="fb-catalog-slide-setting-wrapper" css={[baseCss]}>
        <AssetHeader
          title={i18next.t('컬렉션 광고')}
          icons={[
            { imgSrc: '/assets/img/card_icons/ic-channel-facebook.png', alt: 'Facebook' },
            { imgSrc: '/assets/img/card_icons/ic-channel-instagram.png', alt: 'Instagram' },
          ]}
          handleIconClick={() => {
            props.removeSelectedCampaignTypes(props.setting.type);
          }}
        />
        <div className="create-section-content-wrapper">
          <div className="create-section-content">
            <div className="preview-container">
              <div className="sticky-wrap">
                <div className="title-panel">
                  <div className="title">{i18next.t('미리보기')}</div>
                  <div className="info">{i18next.t('*실제 노출과 미리보기가 다를 수 있습니다.')}</div>
                </div>
                <div className="material-preview-wrapper">
                  <div className="material-preview">
                    <div className="facebook-wrapper">
                      <div className="preview-media-title-wrapper">Facebook</div>
                      <PreviewFacebookItem setting={props.setting} products={categoryProducts} />
                    </div>
                    <div className="instagram-wrapper">
                      <div className="preview-media-title-wrapper">Instagram</div>
                      <PreviewFacebookItem setting={props.setting} products={categoryProducts} instagram />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <h2 className="material-field-header">{i18next.t('광고 소재명')}</h2>
              <div className="material-field-selector-wrapper">
                <BGInput
                  style={{ width: '100%' }}
                  inputProps={{
                    name: 'name',
                    placeholder: i18next.t('광고 소재 이름을 입력해주세요.'),
                    value: name || '',
                    onChange: (event) => {
                      setName(event.target.value);
                    },
                  }}
                />
                <p className="description">{i18next.t('*다른 소재와 구분지을 수 있게 이름을 입력하세요.')}</p>
              </div>

              {!editMatch && !addMatch && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('광고 소재 이미지')}</h2>
                  <div className="material-field-selector-wrapper">
                    <div className="content-upload-container">
                      <div className="content-type-selector">
                        <div
                          className={`content-type-item ${contentType === 'image' || !contentType ? 'active' : ''}`}
                          onClick={() => {
                            updateContentType('image');
                          }}
                        >
                          {i18next.t('이미지')}
                        </div>
                        <div
                          className={`content-type-item ${contentType === 'video' ? 'active' : ''}`}
                          onClick={() => {
                            updateContentType('video');
                          }}
                        >
                          {i18next.t('동영상')}
                        </div>

                        <div
                          className={`content-type-item ${contentType === 'dynamic_video' ? 'active' : ''}`}
                          onClick={() => {
                            updateContentType('dynamic_video');
                          }}
                        >
                          {i18next.t('동적 동영상')}
                        </div>
                      </div>
                      {contentType === 'image' && (
                        <div className="single-image-uploader-wrapper uploader-wrapper">
                          <div className="image-uploader-title-wrapper">
                            <div className="image-uploader-title" style={{ fontWeight: 'normal' }}>
                              {i18next.t('광고에 사용할 대표 이미지를 선택하세요.')}
                            </div>
                            <div className="image-uploader-subtitle">
                              <div>{i18next.t('* 권장 사이즈: ')} 1080px*1080px</div>
                              <div>{i18next.t('* 최소 사이즈: ')} 600px*600px</div>
                              <div>{i18next.t('* 파일 형식: ')} JPG, PNG</div>
                              <div>{i18next.t('* 파일 용량: {{size}}MB 이내', { size: 30 })}</div>
                            </div>
                          </div>
                          <div className="single-image-uploader-wrapper file-wrapper">
                            <BDSButton
                              className="image-updater-btn"
                              onClick={() => {
                                setShowModalImageSelect(true);
                                setValueLock(true);
                              }}
                              label={i18next.t('업로드')}
                            />
                            {props.setting.product_image && props.setting.product_image.name && (
                              <div className="file-name">{props.setting.product_image.name}</div>
                            )}
                            {props.setting.mall_image && <div className="file-name">{i18next.t('상품 정보 없음')}</div>}
                          </div>
                        </div>
                      )}
                      {contentType === 'video' && (
                        <div className="single-image-uploader-wrapper uploader-wrapper">
                          <div className="image-uploader-title-wrapper">
                            <div className="image-uploader-title" style={{ fontWeight: 'normal' }}>
                              {i18next.t('광고에 사용할 대표 동영상을 선택하세요.')}
                            </div>
                            <div className="image-uploader-subtitle">
                              <div>{i18next.t('* 권장 길이: {{second}}초', { second: 15 })} </div>
                              <div>{i18next.t('* 화면 비율: 세로방향(4:5)')}</div>
                              <div>{i18next.t('* 해상도 최소 너비: ')} 600px</div>
                              <div>{i18next.t('* 파일 형식: ')} mp4, mov</div>
                              <div>{i18next.t('* 파일 용량: 최대 {{size}}MB', { size: 200 })}</div>
                            </div>
                          </div>
                          <div className="single-image-uploader-wrapper">
                            <BDSButton
                              className="image-updater-btn"
                              onClick={() => {
                                setShowModalVideoSelect(true);
                              }}
                              label={i18next.t('업로드')}
                            />
                          </div>
                        </div>
                      )}
                      {contentType === 'dynamic_video' && (
                        <div className="single-image-uploader-wrapper uploader-wrapper">
                          <div className="image-uploader-title-wrapper">
                            <div className="image-uploader-title" style={{ fontWeight: 'normal' }}>
                              {i18next.t(
                                '선택한 카테고리의 상품 이미지가 동영상으로 보여집니다. 상품수가 5개 이상인 카테고리만 선택 가능합니다.',
                              )}
                            </div>
                          </div>
                          <div className="single-image-uploader-wrapper category-wrapper">
                            <BDSButton
                              className="image-updater-btn"
                              onClick={() => {
                                setShowCategorySelector(true);
                              }}
                              label={i18next.t('카테고리 선택')}
                            />
                            {typeof selectedCategoryId === 'number' && selectedCategory && (
                              <div className="selected-category-name">{selectedCategory.name}</div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}
              {!editMatch && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('광고 상품 카테고리')}</h2>
                  <div className="material-field-selector-wrapper">
                    <div className="content-upload-container">
                      <div className="single-image-uploader-wrapper uploader-wrapper">
                        <div className="image-uploader-title-wrapper">
                          <div className="image-uploader-subtitle">
                            <div>{i18next.t('카테고리 상품의 대표 이미지가 보여집니다.')}</div>
                            <div>{i18next.t('상품수가 5개 이상인 카테고리만 선택 가능합니다.')}</div>
                          </div>
                        </div>
                        <div className="single-image-uploader-wrapper category-wrapper">
                          <BDSButton
                            className="image-updater-btn"
                            onClick={() => {
                              setShowCategorySelector(true);
                            }}
                            label={i18next.t('카테고리 선택')}
                          />
                          {typeof selectedCategoryId === 'number' && selectedCategory && (
                            <div className="selected-category-name">{selectedCategory.name}</div>
                          )}
                        </div>
                      </div>
                      <div className="horizontal-line" />
                      <div className="single-image-uploader-wrapper uploader-wrapper">
                        <div className="image-uploader-title-wrapper">
                          <div className="image-uploader-title">{i18next.t('대표 이미지 변경')}</div>
                          <div className="image-uploader-subtitle">
                            <div>{i18next.t('선택된 카테고리 상품의 대표 노출 이미지를 변경합니다.')}</div>
                            <div>{i18next.t('변경을 원한다면 아래 버튼을 클릭하여 이미지를 변경해주세요.')}</div>
                          </div>
                        </div>
                        <div className="single-image-uploader-wrapper">
                          <BDSButton
                            className="image-updater-btn"
                            isDisabled={!hasCategoryId}
                            appearance="secondary"
                            onClick={() => {
                              setShowChangeDefaultImageSelector(true);
                              setValueLock(true);
                            }}
                            label={i18next.t('수정')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
              {(editMatch || addMatch) && contentType !== 'dynamic_video' && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('광고 소재 이미지')}</h2>
                  <div className="material-field-selector-wrapper">
                    <div className="content-upload-container">
                      <div className="content-type-selector">
                        {contentType === 'image' && (
                          <div
                            className={`content-type-item ${contentType === 'image' || !contentType ? 'active' : ''}`}
                            onClick={() => {
                              updateContentType('image');
                            }}
                          >
                            {i18next.t('이미지')}
                          </div>
                        )}
                        {contentType === 'video' && (
                          <div
                            className={`content-type-item ${contentType === 'video' ? 'active' : ''}`}
                            onClick={() => {
                              updateContentType('video');
                            }}
                          >
                            {i18next.t('동영상')}
                          </div>
                        )}
                      </div>
                      {contentType === 'image' && (
                        <div className="single-image-uploader-wrapper uploader-wrapper">
                          <div className="image-uploader-title-wrapper">
                            <div className="image-uploader-title" style={{ fontWeight: 'normal' }}>
                              {i18next.t('광고에 사용할 대표 이미지를 선택하세요.')}
                            </div>
                            <div className="image-uploader-subtitle">
                              <div>{i18next.t('* 권장 사이즈: ')} 1080px*1080px</div>
                              <div>{i18next.t('* 최소 사이즈: ')} 600px*600px</div>
                              <div>{i18next.t('* 파일 형식: ')} JPG, PNG</div>
                              <div>{i18next.t('* 파일 용량: {{size}}MB 이내', { size: 30 })}</div>
                            </div>
                          </div>
                          <div className="single-image-uploader-wrapper file-wrapper">
                            <BDSButton
                              className="image-updater-btn"
                              onClick={() => {
                                setShowModalImageSelect(true);
                                setValueLock(true);
                              }}
                              label={i18next.t('업로드')}
                            />
                            <div className="file-name">
                              {props.setting.product_image && props.setting.product_image.name && (
                                <div className="file-name">{props.setting.product_image.name}</div>
                              )}
                              {props.setting.mall_image && (
                                <div className="file-name">{i18next.t('상품 정보 없음')}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      {contentType === 'video' && (
                        <div className="single-image-uploader-wrapper uploader-wrapper">
                          <div className="image-uploader-title-wrapper">
                            <div className="image-uploader-title" style={{ fontWeight: 'normal' }}>
                              {i18next.t('광고에 사용할 대표 동영상을 선택하세요.')}
                            </div>
                            <div className="image-uploader-subtitle">
                              <div>{i18next.t('* 권장 길이: {{second}}초', { second: 15 })} </div>
                              <div>{i18next.t('* 화면 비율: 세로방향(4:5)')}</div>
                              <div>{i18next.t('* 해상도 최소 너비: ')} 600px</div>
                              <div>{i18next.t('* 파일 형식: ')} mp4, mov</div>
                              <div>{i18next.t('* 파일 용량: 최대 {{size}}MB', { size: 200 })}</div>
                            </div>
                          </div>
                          <div className="single-image-uploader-wrapper">
                            <BDSButton
                              className="image-updater-btn"
                              onClick={() => {
                                setShowModalVideoSelect(true);
                              }}
                              label={i18next.t('업로드')}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 텍스트')}</h2>
              <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 문구')} checkboxLabel="" value hiddenCheckbox />
                <div className="material-field-input-wrapper">
                  <BDSTextArea
                    isAutoGrow
                    error={!!formState.errors.body}
                    style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                    textAreaProps={{
                      name: 'fb_body',
                      cols: 30,
                      rows: 3,
                      style: {
                        minHeight: 49,
                      },
                      value: body || '',
                      placeholder: i18next.t('광고 문구를 입력해주세요.'),
                      onChange: (event) => {
                        setBody(event.target.value);
                        setValue('body', event.target.value, { shouldValidate: true });
                      },
                    }}
                    curLength={body.length || 0}
                    limitLength={120}
                    lengthUnit={i18next.t('자')}
                  />
                  {formState.errors.body && <InputError message={formState.errors.body.message as string} />}
                </div>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 제목')} checkboxLabel="" value hiddenCheckbox />
                <BGInput
                  style={{ width: '100%', marginTop: '8px' }}
                  error={!!formState.errors.title}
                  inputProps={{
                    name: 'fb_collection_title',
                    value: title || '',
                    placeholder: i18next.t('광고 제목을 입력해주세요.'),
                    onChange: (event) => {
                      setTitle(event.target.value);
                      setValue('title', event.target.value, { shouldValidate: true });
                    },
                  }}
                  customLength={title.length || 0}
                  customLengthLimit={16}
                  lengthUnit={i18next.t('자')}
                />
                {formState.errors.title && <InputError message={formState.errors.title.message as string} />}
                <p className="description">{i18next.t('*Facebook에서만 보여집니다.')}</p>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('행동 유도 버튼')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <BDSSelectBox
                  appearance="gray"
                  style={{ width: '100%', marginTop: '8px' }}
                  list={facebookCTA}
                  defaultValue={props.setting?.fb_call_to_action}
                  displayValue={(item: any) => {
                    return i18next.t(item.label);
                  }}
                  isSelected={(item: any) => {
                    return selectedCTA === item.value;
                  }}
                  handleUpdate={(item: any) => {
                    setSelectedCTA(item.value);
                  }}
                />
                <p className="description">{i18next.t('*Instagram에서만 보여집니다.')}</p>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('랜딩 URL')} checkboxLabel="" value hiddenCheckbox />
                <BGUrlSelector
                  linkButton
                  validationTrackOn
                  isValid={!formState.errors.url}
                  url={url || ''}
                  handleUpdate={(curUrl: string) => {
                    setUrl(curUrl);
                    setValue('url', curUrl, { shouldValidate: true });
                  }}
                  style={{ marginTop: '8px' }}
                  placeholder="www.bigin.io"
                />
                {formState.errors.url && <InputError message={formState.errors.url.message as string} />}
              </div>
              {campaignState.isBiginConnected && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('연계 캠페인')}</h2>
                  <div className="material-field-selector-wrapper">
                    <MaterialCheckboxSelector
                      contentTitle={i18next.t('핵심 키워드')}
                      checkboxLabel=""
                      value
                      hiddenCheckbox
                    />
                    <BGTagInput
                      style={{ width: '100%', marginTop: '8px' }}
                      inputProps={{
                        name: 'bigin_keywords',
                        placeholder: i18next.t(
                          'Bigin CRM에서 해당 광고 소재를 검색할 때 사용할 키워드를 입력해주세요.',
                        ),
                      }}
                      handleUpdate={(keywords) => {
                        setBiginKeywords(keywords);
                      }}
                      defaultTags={biginKeywords}
                      duplicateText={i18next.t('* 이미 등록된 키워드입니다.')}
                    />
                    <p className="description">
                      {i18next.t('*핵심 키워드는 선택사항이며, 최대 5개까지 입력할 수 있습니다.')}
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      <React.Suspense fallback={null}>
        {showCategorySelector && (
          <CategorySelectorModalWrapper
            isShow={showCategorySelector}
            buttons={buttons}
            categories={categoriesState}
            selectedParentCategory={selectedParentCategory}
            selectedCategory={selectedCategory}
            setCategory={setSelectedCategory}
            setParentCategory={setSelectedParentCategory}
          />
        )}
      </React.Suspense>
      <React.Suspense fallback={null}>
        {showModalImageSelect && (
          <ModalImageSelect
            selectType="single"
            selectedImages={selectedImages}
            campaignType={props.setting.type}
            isOpen={showModalImageSelect}
            close={() => setShowModalImageSelect(false)}
            setSelectedImages={handleSelectImages}
          />
        )}
      </React.Suspense>
      <React.Suspense fallback={null}>
        {showModalVideoSelect && (
          <ModalVideoSelect
            selectType="single"
            isOpen={showModalVideoSelect}
            setSelectedVideos={handleSelectVideos}
            close={() => setShowModalVideoSelect(false)}
          />
        )}
      </React.Suspense>
      <React.Suspense fallback={null}>
        {showChangeDefaultImageSelector && (
          <ModalCategoryImageSelector
            isOpen={showChangeDefaultImageSelector}
            close={() => setShowChangeDefaultImageSelector(false)}
            setDefaultImages={handleDefaultImages}
            selectedCategoryId={selectedCategoryId}
          />
        )}
      </React.Suspense>
    </BorderSection>
  );
};
