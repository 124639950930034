import { css, Theme, useTheme } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .plan-container {
      width: 100%;
      height: 100%;
      background-color: #f9fafb;
      position: relative;
      box-sizing: border-box;

      .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .plan-content {
        width: 100%;
        height: 100%;
        position: relative;

        .plan-panel {
          justify-content: flex-start;
          align-items: flex-start;
          .plan-box {
            width: 100%;
            min-width: ${GS.contentMinWidth}px;
            max-width: ${GS.contentMaxWidth}px;
            align-self: flex-start;
            margin-top: 20px;
            margin-right: 20px;

            .plan-list {
              width: 100%;
              position: relative;

              .plan-group-panel {
                align-items: flex-start;
                justify-content: flex-start;
                position: absolute;
                top: 0;
                width: 100%;
              }

              .plan-info-wrapper {
                justify-content: flex-start;
              }

              .vat-msg-wrapper {
                padding-top: 425px;
                margin-bottom: 20px;
                justify-content: flex-start;
              }
              .tier-upgrade-banner-container {
                background: url(${`/assets/img/campaign/taggers-plan-background.svg`});
                background-repeat: no-repeat;
                background-size: cover;
                background-position: left;
                border: 1px solid ${theme.colors.divider};
                border-radius: 12px;
                height: auto;
                padding: 16px 20px 16px 0;
                min-height: 80px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                margin-bottom: 20px;

                .typography-wrapper {
                  display: flex;
                  flex-direction: column;
                  margin-left: 154px;
                }
              }
            }
          }
        }
        .plan-box-wrapper {
          position: sticky;
          top: 0;
          right: 0;
        }
      }
    }
  `;
};
