export type MaterialVerticalAlign = 'top' | 'middle' | 'bottom';
export type MaterialHorizontalAlign = 'left' | 'center' | 'right';

export enum PeriodValue {
  MONTHLY = 'Monthly', // 월간
  YEARLY = 'Yearly', // 연간
}

export interface Period {
  label: string;
  value: PeriodValue;
}

export interface Metric {
  prop: string; // 데이터 이름 ex. event_purchase_revenue
  event: string; // 이벤트 이름 ex. bg:purchase
  eventName: string;
  eventType: 'Session' | 'View' | 'User' | 'Ecommerce' | 'Campaign' | 'PageTarget' | 'Custom';
  displayName: string;
  dimensions: Dimension[];
  format: string; // Number
  operation?: string;
  index?: number;
  event_segs: string[]; // 이벤트 관련 속성 ex. bg:impression -> productId, productPrice, ... list, position
  alternative?: string; // ??
  pageRegex?: string[];
  resend?: boolean;
}

export interface Dimension {
  category: string; // PropertyType 의 propertyDetailType
  propertyDetailType: 'device' | 'traffic' | 'country' | 'event' | 'user' | 'newtree';
  index?: number;
  name: string; // 데이터 이름 , user_seg, device_seg, session_seg, event_seg 등의 prefix 가 제거된 순 이름
  prop: string; // user prefix 가 추가된 속성명
  propertyType?: string;
  displayName: string; // 한글 속성명
}

export interface BaseQueryParameter {
  projectId?: string;
  startAt?: string;
  endAt?: string;
}
