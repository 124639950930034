import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = css`
  position: relative;
  display: flex;
  justify-content: space-between;

  & * {
    box-sizing: border-box;
  }

  .material-dropdown-wrapper {
    width: calc(100% - 50px);
    height: auto;
  }

  .material-position-click-selector {
    display: flex;
    flex-wrap: wrap;
    width: 36px;
    height: 36px;
    border: solid 1px #d8d8e6;
    border-radius: 2px;
    margin-left: auto;

    .material-position-click-btn {
      padding: 0;
      width: calc(100% / 3);
      height: calc(100% / 3);
      cursor: pointer;
      border-right: solid 1px #d8d8e6;
      border-bottom: solid 1px #d8d8e6;
      background: #ffffff;
      box-sizing: border-box;

      &:nth-of-type(3n) {
        border-right: 0;
      }

      &:nth-of-type(n + 7) {
        border-bottom: 0;
      }

      &.selected {
        border: 1px solid #0086ff !important;
        background-color: #0086ff;
      }

      &.disabled {
        cursor: default;
        background-color: #f5f5fa;
      }
    }
  }
`;

export const materialDropDownStyle = () => {
  const theme: Theme = useTheme();

  return css`
    &,
    & * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      height: 36px;
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    &.white {
    }

    &.gray {
      &.disabled {
        .material-dropdown-selected-item-wrapper {
          border: solid 1px #dce0e8;
          background-color: #ebeef2;
        }

        .material-dropdown-item {
          &.gray {
            background-color: #ebeef2;
            opacity: 0.3;
          }
        }
      }

      &:not(.disabled):hover {
        .material-dropdown-selected-item-wrapper {
          &:not(.box-opened) {
            border-radius: 8px;
            border: solid 1px #b8beca;
          }
        }
      }
    }

    &.error {
      .material-dropdown-selected-item-wrapper {
        border: solid 1px #e65c5c !important;
      }
    }

    &.box-opened {
      .material-dropdown-select-box {
        border-left: solid 1px #3399ff;
        border-right: solid 1px #3399ff;
        border-bottom: solid 1px #3399ff;
        border-radius: 0 0 8px 8px;

        &.top {
          border-left: solid 1px #3399ff;
          border-right: solid 1px #3399ff;
          border-top: solid 1px #3399ff;
          border-radius: 8px 8px 0 0;
        }
      }
    }

    &.toast-opened-custom {
      .material-dropdown-select-box {
        max-height: 288px !important;
      }
    }

    .material-dropdown-select-box {
      width: 100%;
      height: auto;
      max-height: 300px;
      background-color: #f9fafb;
      border: 1px solid #dce0e8;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 2;
      overflow-y: hidden;
      &.top {
        top: 0;
        transform: translate(0, -100%);
      }

      &.scroll {
        overflow-y: scroll;
        &::-webkit-scrollbar {
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #dce0e8;
          border: 8px solid transparent;
          border-radius: 8px;
          background-clip: padding-box;
        }
      }

      &.white {
        background-color: #ffffff;
      }
    }

    .material-dropdown-selected-item-wrapper {
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //border-radius: 4px;
      position: relative;
      border-radius: 8px;
      border: solid 1px #dce0e8;

      &.box-opened {
        border: solid 1px #3399ff;
        border-bottom: solid 1px transparent;
        border-radius: 8px 8px 0 0;

        &.top {
          border-top: solid 1px transparent;
          border-bottom: solid 1px #3399ff;
          border-radius: 0 0 8px 8px;
        }
      }
    }

    .material-dropdown-list-item {
      width: 100%;
      height: 36px;
      transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
      //border-radius: 4px;
      padding: 8px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      ${theme.text.input};
      color: ${theme.colors.subFont};

      &:hover {
        background: #edf1f6;
      }

      &.selected {
        background-color: #edf1f6;
        font-size: 14px;
        font-weight: bold;
        color: ${theme.colors.subFont};
      }

      .material-dropdown-item-text {
        margin: 0 0;
        ${theme.text.input};
        color: ${theme.colors.subFont};
      }
    }

    .material-dropdown-item {
      width: 100%;
      height: 100%;
      //border-radius: 4px;
      padding: 8px;
      cursor: pointer;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff;
      transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);

      ${theme.text.input};
      color: ${theme.colors.subFont};

      &.white {
        background-color: #ffffff;
      }

      &.gray {
        background-color: #f9fafb;
      }

      &:hover {
        //box-shadow: 0 4px 8px -2px #d1d7dd;
      }

      .material-dropdown-item-text {
        margin: 0 0;

        ${theme.text.input};
        color: ${theme.colors.subFont};
      }
    }
  `;
};
