import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { baseCss } from './PlanItem.style';
import { Period } from '../../../object-model/model';
import Plan from '../../../services/plans/Plan';

export interface PlanItemProps {
  planItem: Plan;
  period: Period;
  changePlan: (currPlanItem: Plan) => void;
  selected: boolean;
  disabled: boolean;
}

export const PlanItem = (props: PlanItemProps): ReactElement => {
  const i18next = useTranslation();
  const { planItem, changePlan, selected, disabled } = props;

  return (
    <React.Fragment>
      <div
        className={`plan-item ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
        css={[baseCss]}
        onClick={() => {
          changePlan(planItem);
        }}
      >
        <div className="plan-top-list">
          <img src={planItem.image} className="plan-icon" alt="bigin-icon" />
          {selected && <div className="plan-tag selected">{i18next.t('선택됨')}</div>}
          {disabled && !selected && <div className="plan-tag">{i18next.t('현재 플랜')}</div>}
        </div>
        <div className="text-h2 text-color-main">{planItem.name}</div>
        {/* <div className="plan-item-msg" dangerouslySetInnerHTML={{ __html: getPlanMessage(planItem.name) }} /> */}
        <div className="text-input text-color-main mb-5 fill-width">
          {Plan.isFreeType(planItem.type)
            ? i18next.t('무료로 디지털 광고 자동화 솔루션을 경험할 수 있는 플랜입니다.')
            : i18next.t('Bigin Ads의 모든 기능과 함께 전담 컨설턴트가 배정되는 플랜입니다.')}
        </div>
        <div className="plan-main-list">
          {planItem.features.map((feature: string) => (
            <div key={feature} className="plan-main-list-item" style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="plan-item-desc">
                <span className="text-input text-color-main">{`· ${feature}`}</span>
              </div>
            </div>
          ))}
        </div>

        <div className="text-h2 text-color-main" style={{ position: 'absolute', bottom: '20px' }}>
          {planItem.subscriptionFee === null && (
            <React.Fragment>
              <span className="text-h4">{i18next.t('구독료 79만원부터')}</span>
              <span className="text-input"> {i18next.t('/월')}</span>
            </React.Fragment>
          )}
          <p className="text-h4">
            {planItem.subscriptionFee !== null &&
              i18next.t('구독료 {{number}}원', { number: planItem.subscriptionFee })}
          </p>
          <p className="text-input">
            {planItem.feeRate &&
              i18next.t('환전 수수료 등을 포함한 {{number}}% 종합 수수료 부과', { number: planItem.feeRate })}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
