import { UseTranslationResponse } from 'react-i18next';

export class MonthlyBudget {
  public static UNDER_300 = '~300';

  public static BETWEEN_300_500 = '300~500';

  public static BETWEEN_500_1000 = '500~1000';

  public static BETWEEN_1000_2000 = '1000~2000';

  public static BETWEEN_2000_3500 = '2000~3500';

  public static BETWEEN_3500_5000 = '3500~5000';

  public static OVER_5000 = '5000~';

  public static values() {
    return Object.values(MonthlyBudget);
  }

  public static toSelects(i18next: UseTranslationResponse<'translation'>) {
    return [
      {
        label: i18next.t('월 300만원 이하'),
        value: MonthlyBudget.UNDER_300,
      },
      {
        label: i18next.t('월 300만원-500만원'),
        value: MonthlyBudget.BETWEEN_300_500,
      },
      {
        label: i18next.t('월 500만원-1,000만원'),
        value: MonthlyBudget.BETWEEN_500_1000,
      },
      {
        label: i18next.t('월 1,000만원-2,000만원'),
        value: MonthlyBudget.BETWEEN_1000_2000,
      },
      {
        label: i18next.t('월 2,000만원-3,500만원'),
        value: MonthlyBudget.BETWEEN_2000_3500,
      },
      {
        label: i18next.t('월 3,500만원-5,000만원'),
        value: MonthlyBudget.BETWEEN_3500_5000,
      },
      {
        label: i18next.t('월 5,000만원 이상'),
        value: MonthlyBudget.OVER_5000,
      },
    ];
  }

  public static isUnder500(value: TMonthlyBudget) {
    return (
      [MonthlyBudget.UNDER_300 as TMonthlyBudget, MonthlyBudget.BETWEEN_300_500 as TMonthlyBudget].indexOf(value) > -1
    );
  }
}

export type TMonthlyBudget = typeof MonthlyBudget[keyof typeof MonthlyBudget];
