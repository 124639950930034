import { css, useTheme, Theme } from '@emotion/react';
import { GS } from '../../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: 100%;
    min-width: fit-content;

    * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: ${GS.gutter}px;
    }

    .rc-slider-handle-dragging {
      border: none !important;
      box-shadow: 0 0 0 3px rgba(0, 89, 217, 0.3) !important;
    }

    .plan-update-container {
      background: url(${`/assets/img/campaign/taggers-plan-background.svg`});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: left;
      border: 1px solid ${theme.colors.divider};
      border-radius: 12px;
      width: 100%;
      height: auto;
      padding: 16px 20px 16px 0;
      min-height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .typography-wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 154px;
      }
    }

    .border-section-header-wrapper {
      padding: 0 ${GS.gutter}px;
      padding-top: ${GS.gutter}px;
      width: 100%;

      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .title {
          ${theme.text.h2};
          color: ${theme.colors.mainFont};
          margin-right: 16px;
        }
      }

      .description-wrapper {
        margin-top: 8px;
        .description {
          ${theme.text.input}
          color: ${theme.colors.mainFont};
        }
      }
    }

    .campaign-goal-selector-wrapper {
      margin-top: 20px;
      padding: 0 20px 20px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;

      .campaign-goal-box {
        border-radius: 8px;
        border: solid 1px ${theme.colors.divider};
        background-color: #fff;
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        user-select: none;

        &:hover {
          background-color: #f9fafb;
          border: solid 1px #39f;
          cursor: pointer;
        }

        &.active {
          border: solid 1px #006fff;
          background-color: #fff;

          &:hover {
            background-color: #f9fafb;
            border: solid 1px #39f;
            cursor: pointer;
          }
        }

        &.disabled {
          background-color: #f9fafb;
          transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);

          &:hover {
            .label-wrapper {
              background-color: #7e8696;
              max-width: 300px;
              overflow: hidden;

              .label {
                margin-left: 2px;
                padding: 1px;
                display: block;
                max-width: 300px;
                opacity: 1;
              }
            }
          }
        }

        .infographic-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          position: relative;

          .icon {
            width: 48px;
            height: 48px;
            border-radius: 8px;
            border: solid 1px ${theme.colors.divider};
            padding: 0;
            margin: 0;
            overflow: hidden;
            object-fit: contain;
          }

          .badge {
            ${theme.text.disclaimer}
            background-color: ${theme.colors.bg3};
            padding: 2px 4px;
            border-radius: 4px;

            &.active {
              color: #fff;
              background-color: ${theme.colors.primary};
            }
          }

          .label-wrapper {
            display: inline-flex;
            margin-bottom: 8px;
            border-radius: 10px;
            align-items: center;
            transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
            padding: 2px 5px 3px;
            height: 21px;
            background-color: #b5bcc9;
            position: absolute;
            top: 0;
            right: 0;

            .label {
              ${theme.text.p3}
              color: #fff;
              transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
              overflow: hidden;
              opacity: 0;
              max-width: 0px;
              max-height: 40px;

              span {
                min-width: 105px;
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .campaign-target-selector-wrapper {
      margin-top: 20px;
      padding: 0 20px 20px;
      width: 100%;
      display: flex;
      flex-direction: column;

      .target-detail-wrapper {
        padding: 16px 0;
        display: flex;
        align-items: center;
        .left-wrapper {
          width: 160px;
        }
        .right-wrapper {
          width: calc(100% - 160px);
        }

        .checkbox-group-wrapper {
          display: flex;
          gap: 24px;
        }

        .checkbox-wrapper {
          display: flex;
          cursor: pointer;

          .label {
            margin-left: 4px;
            ${theme.text.input}
            color: ${theme.colors.mainFont};
          }
        }

        .radiobutton-group-wrapper {
          display: flex;
          gap: 25px;
        }
      }

      .target-item-wrapper {
        padding: 16px 0;
        display: flex;
        align-items: center;

        .left-wrapper {
          width: 160px;
        }
        .right-wrapper {
          width: calc(100% - 160px);
        }
      }

      .target-gender-wrapper {
        .checkbox-group-wrapper {
          display: flex;
          gap: 24px;
        }

        .checkbox-wrapper {
          display: flex;
          cursor: pointer;

          .label {
            margin-left: 4px;
            ${theme.text.input}
            color: ${theme.colors.mainFont};
          }
        }
      }
      .target-age-wrapper {
        padding: 16px 0;
        display: flex;
        justify-content: flex-start;

        .slider-wrapper {
          display: block;
          width: 480px;
          margin-left: 7px;
        }
      }

      .target-nation-wrapper {
        .target-nation-text-wrapper {
          display: block;
          font-size: 14px;
          color: ${theme.colors.mainFont};
        }
      }
    }

    .campaign-type-tab-wrapper {
      margin-top: 20px;
    }

    .campaign-type-selector-wrapper {
      margin-top: 20px;
      padding: 0 20px 20px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px 16px;

      .campaign-type-box {
        border-radius: 8px;
        outline: solid 1px ${theme.colors.divider};
        background-color: #fff;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &:hover {
          outline: solid 1px #3399ff;
          cursor: pointer;
        }

        &.active {
          outline: solid 1px #006fff;
          background-color: #fff;

          &:hover {
            outline: solid 1px #006fff;
            cursor: pointer;
          }
        }

        &.warning {
          &:hover {
            .background-layer {
              .label-wrapper.warning {
                .font-icon-panel {
                  color: #7e8696;
                }
              }
            }
          }
        }

        &.lock {
          .background-layer {
            background-color: #edf1f6;
          }
          .description-layer {
            background-color: #f9fafb;
          }

          &:hover {
            .background-layer {
              .label-wrapper {
                background-color: #7e8696;
                max-width: 140px;
                overflow: hidden;

                .label {
                  margin-left: 2px;
                  padding: 1px;
                  display: block;
                  max-width: 120px;
                  opacity: 1;
                  span {
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }

        &.disabled {
          opacity: 0.4;
          pointer-events: none;
          &:hover {
            outline: solid 1px #dce0e8;
            cursor: default;
          }
        }

        .background-layer {
          width: 100%;
          background-color: #f9fafb;
          position: relative;

          .infographic-wrapper {
            display: flex;
            justify-content: flex-end;
            padding: 16px 20px 0;
            width: 100%;
            position: absolute;

            .icon-wrap {
              display: flex;

              .icon {
                width: 20px;
                height: 20px;
              }
            }

            .badge-wrap {
              display: flex;
              flex-direction: column;
              gap: 4px;
              align-items: end;

              .badge {
                ${theme.text.disclaimer}
                background-color: ${theme.colors.bg3};
                padding: 2px 4px;
                border-radius: 4px;

                &.active {
                  color: #fff;
                  background-color: ${theme.colors.primary};
                }
              }
            }

            .label-wrapper {
              display: inline-flex;
              margin-bottom: 8px;
              border-radius: 10px;
              align-items: center;
              transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
              padding: 2px 5px 3px;
              height: 21px;
              background-color: #b5bcc9;
              overflow: hidden;

              &.warning {
                padding: unset;
                background-color: unset;

                .font-icon-panel {
                  color: #b5bcc9;
                }
              }

              .label {
                ${theme.text.p3}
                color: #fff;
                transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
                overflow: hidden;
                opacity: 0;
                max-width: 0px;
                max-height: 40px;

                span {
                  min-width: 105px;
                  display: inline-block;
                }
              }
            }
          }
          .preview-image-wrapper {
            width: 100%;
            height: 100%;

            img {
              object-fit: cover;
              width: 100%;
              user-select: none;
            }
          }
        }

        .description-layer {
          width: 100%;
          min-height: 104px;
          padding: 16px 16px 20px;
          height: 100%;

          .title-wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            &.active {
              .title {
                font-weight: bold;
              }
            }

            .icon-wrap {
              display: flex;
              gap: 4px;

              .icon {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }

    .accordion-container {
      .accordion-header-wrapper {
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .accordion-header-title-container {
          display: flex;
          align-items: center;
          .accordion-header-title-wrapper {
            min-width: 204px;
            display: flex;
            align-items: center;
            gap: 4px;
            ${theme.text.h4};
            color: ${theme.colors.mainFont};
          }

          .accordion-header-sub-title-wrapper {
            ${theme.text.input};
            color: ${theme.colors.mainFont};

            .sub-title {
              font-weight: bold;
              margin-right: 8px;
            }
          }
        }
      }
      .accordion-panel {
        transition: height 0.3s cubic-bezier(0.33, 1, 0.68, 1) 0s;
      }
      .accordion-contents {
        border-top: 1px solid #dce0e8;
        transition: height 0.3s cubic-bezier(0.33, 1, 0.68, 1) 0s;
      }
    }

    .linked-campaign-toggle-wrapper {
      padding: 16px;
      display: flex;
      justify-content: flex-start;
      transition: height 0.3s cubic-bezier(0.33, 1, 0.68, 1) 0s;
    }

    .campaign-setting-selector-wrapper {
      margin-top: 8px;
      padding: 0 20px 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      transition: max-height 0.3s cubic-bezier(0.33, 1, 0.68, 1);

      .campaign-budget-wrapper {
        padding: 8px 0;
        display: flex;
        justify-content: flex-start;
      }
      .campaign-date-wrapper {
        padding: 16px 0;
        display: flex;
        justify-content: flex-start;

        .campaign-period-form-wrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;
          width: 100%;

          .campaign-date-selector-wrapper {
            display: flex;
            flex-direction: column;
            gap: 18px;
          }

          .campaign-period-wrapper {
            width: 95%;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 12px;

            .date-form-wrapper {
              display: flex;
              gap: 16px;
              align-items: center;

              .date-label {
                ${theme.text.input}
                font-weight: bold;
                color: ${theme.colors.mainFont};
              }

              .bg-calendar-panel {
                .position-bottom,
                .position-top {
                  left: 0px;
                  right: unset;
                }

                .position-top {
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }
    }
  `;
};
