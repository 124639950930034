import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    align-self: stretch;

    .payment-info-section.shadow {
      position: sticky;
      top: 76px;
      border-left: 1px solid #dce0e8;
    }

    .flex-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .flex-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .payment-box {
      background-color: transparent;
      border-radius: 0;
      width: 100%;
      height: 100%;
    }

    .payment-info-section {
      width: 280px;
      box-sizing: border-box;
      align-items: flex-start;
      padding: 24px;
      background-color: #ffffff;
      height: calc(100vh - 140px);

      .payment-header {
        justify-content: flex-start !important;
        width: 100%;
        margin: 0 0 1px 0;
        ${theme.text.h1};
        color: ${theme.colors.mainFont};
      }

      .payment-cycle {
        width: 100%;
        margin-top: 16px;
        justify-content: space-between;

        .payment-select-box {
          align-items: flex-start;
          width: 100%;

          .payment-form-input {
            width: 100%;
          }
        }
      }

      .divide-line {
        margin-top: 16px;
        margin-bottom: 16px;
        height: 1px;
        width: 100%;
        border-top: solid 1px #ededf2;
      }

      .payment-info {
        width: 100%;
        margin: 24px 0 0 0;
        ${theme.text.input};
        color: ${theme.colors.mainFont};
        box-sizing: border-box;

        .payment-detail {
          justify-content: space-between;
          margin-bottom: 8px;

          .total-price {
            .total-detail {
              span {
                display: none;
              }
            }
          }

          .plan-price {
            ${theme.text.input};
            color: ${theme.colors.mainFont};
          }
        }

        .final-price-section {
          justify-content: space-between;
          ${theme.text.h4};
          color: ${theme.colors.mainFont};
        }
      }

      .btn-box {
        width: 100%;
        font-size: 14px;
        margin: 24px 0 0 0;
      }
    }

    .info-box {
      margin-top: 20px;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 14px;
      font-weight: normal;
      color: #53585f;
      padding-bottom: 20px;

      span.bold {
        font-weight: bold;
      }
    }
  `;
};
