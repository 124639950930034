import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .form-input-wrapper {
      margin-bottom: 20px;

      .form-label {
        ${theme.text.h4};
        color: ${theme.colors.mainFont};
        margin-bottom: 16px;

        .form-label-help {
          ${theme.text.disclaimer};
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .checkbox-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      cursor: pointer;

      .checkbox-label {
        margin-left: 4px;
        ${theme.text.input};
        color: ${theme.colors.mainFont};
      }
    }
  `;
};
