import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .crm-project-info-modal-header {
      ${theme.text.h1};
      color: ${theme.colors.mainFont};
      display: flex;
      justify-content: space-between;
      .header-close {
        cursor: pointer;
      }
    }
    .crm-project-info-modal-contents {
      margin-top: 24px;

      .info-label {
        ${theme.text.h5};
        color: ${theme.colors.subFont};
      }
      .info-description {
        ${theme.text.h4};
        color: ${theme.colors.mainFont};
        margin-top: 4px;
      }
      .button-text {
        margin-top: 8px;
        padding: 0;
        ${theme.text.disclaimer};
        border: 0;
        background: none;
        cursor: pointer;
      }
    }
  `;
};
