import React, { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import _, { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import { SkeletonTheme } from 'react-loading-skeleton';
import { BDSButton } from '@bigin/bigin-ui-components';
import { useForm } from 'react-hook-form';
import Cookies from 'js-cookie';
import { useRecoilState, useRecoilValue } from 'recoil';
import { MetricItem } from '../../components/MetricItem/MetricItem';
import { baseCss, changeBudgetModalCss } from './CampaignDashboard.style';
import { CampaignListTable, CampaignListTableHeader } from '../../components/CampiagnListTable/CampaignListTable';
import { CampaignStats } from './CampaignItem/CampaignStats';
import { CampaignTitle } from './CampaignItem/CampaignTitle';
import { BorderSection } from '../../components/BorderSection/BorderSection';
import { BGPagination } from '../../components/BGPagination/BGPagination';
// eslint-disable-next-line import/no-cycle
import { BGListFilterV2 } from '../../components/BGListFilterV2/BGListFilterV2';
import {
  getCampaignFilterList,
  metricConfigList,
  Metric,
  initGridColumn,
  CampaignQueryParams,
} from './CampaignDashboardDef';
import { CampaignApi } from '../../lib/api/CampaignApi';
import { AllColumnType } from '../../types/BGEventDef';
import { DateRange } from '../../components/Calendar/Calendar';
import { getInsightType, getUnitText } from '../../utils/insight';
import StorageService from '../../services/StorageService';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { WrapperModal } from '../../components/WrapperModal/WrapperModal';
import { BGInput } from '../../components/BGInput/BGInput';
import { filters } from '../../utils/filter';
import CampaignList from '../../recoil/CampaignList';
import { BGPage } from '../../components/BGPage/BGPage';
import { MallApi } from '../../lib/api/MallApi';
import { DialogConfirm } from '../../components/DialogConfirm/DialogConfirm';
import { InputError } from '../../components/InputError/InputError';
import { BGPageBanner } from '../../components/BGPageBanner/BGPageBanner';
import { needBiginSdkCheck } from '../../utils/utils';
import { useDataStore } from '../../context/Store';
import { PaymentApi } from '../../lib/api/PaymentApi';
import { multilingual } from '../../utils/multilingual.util';
import { CurrencyCodeRecoil, showBudgetValueRecoil } from '../../recoil/Currency';

export interface CampaignSortData {
  key: string;
  direction: 1 | -1;
}

export const CampaignDashboard = (): ReactElement => {
  const { i18n, ...i18next } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { register, formState, setValue } = useForm<{ budget: number }>({
    mode: 'onChange',
  });
  const { toastStore } = useDataStore();

  const [showModalDailyBudget, setShowModalDailyBudget] = useState<boolean>(false);
  const [dailyBudget, setDailyBudget] = useState<number>(0);
  const [selectedCampaign, setSelectedCampaign] = useState<UnifiedCampaign>();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [date, setDate] = useState<DateRange>();
  const [showBudgetValue, setShowBudgetValue] = useRecoilState(showBudgetValueRecoil);
  const [showModalSDKInstall, setShowModalSDKInstall] = useRecoilState<boolean>(CampaignList.sdkModal);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const minBudget = useMemo(() => {
    const generalTypeMinimumBudget = multilingual.getMinimumBudgetByCurrency({
      currency: currencyCode,
    });
    if (selectedCampaign) {
      return selectedCampaign.uni_ads_count * generalTypeMinimumBudget;
    }
    return generalTypeMinimumBudget;
  }, [selectedCampaign]);

  useEffect(() => {
    register('budget', {
      required: (i18next.t('일 예산을 입력해주세요.') as string) || '',
      min: {
        value: minBudget,
        message:
          (i18next.t('최소 일 예산은 {{minBudget}} 입니다.', {
            minBudget: filters.formatCurrency({ value: minBudget, currencyCode }),
          }) as string) || '',
      },
    });
  }, [selectedCampaign]);

  const CampaignFilterList = getCampaignFilterList(i18next);
  const defaultFilterItem = { label: i18next.t('전체'), value: 'CAMPAIGN_FILTER_ALL', isDefault: true } as ListItem;

  const selectableDateRange = useMemo(() => {
    if (date && date.start && date.end && moment.isMoment(date.start) && moment.isMoment(date.end)) {
      return {
        start: date.start.startOf('day'),
        end: date.end.endOf('day'),
      };
    }
    return {
      start: moment().subtract(180, 'day').startOf('day'),
      end: moment().endOf('day'),
    };
  }, []);
  const prevQueryParams = useMemo(() => {
    const parsedQueryParams: CampaignQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    return {
      ...parsedQueryParams,
      activePage: parsedQueryParams?.activePage ? parseInt(parsedQueryParams.activePage as string, 10) : 1,
      countPerPage: parsedQueryParams?.countPerPage ? parseInt(parsedQueryParams.countPerPage as string, 10) : 10,
      sortData: parsedQueryParams?.sortData
        ? ({
            key: parsedQueryParams.sortData.key as string,
            direction: parseInt(parsedQueryParams.sortData.direction as string, 10),
          } as CampaignSortData)
        : null,
      searchText: parsedQueryParams?.searchText || '',
    };
  }, [location.search]);
  const initSelectedDateRange = useMemo(() => {
    const queryParams = qs.stringify({
      ...prevQueryParams,
    });
    const urlSearchParams = new URLSearchParams(queryParams);
    const params = Object.fromEntries(urlSearchParams.entries());
    const savedDate = StorageService.saveDateRange;

    if (savedDate) {
      const diff = moment(savedDate.end).diff(moment(savedDate.start), 'days');

      return {
        start: moment(savedDate.start).startOf('day'),
        end: moment(savedDate.end).endOf('day'),
        compareStart: moment(savedDate.start)
          .subtract(diff + 1, 'day')
          .startOf('day'),
        compareEnd: moment(savedDate.start).subtract(1, 'day').endOf('day'),
      };
    }
    if (params.startAt && params.endAt) {
      const startDate = moment(parseInt(params.startAt, 10));
      const endDate = moment(parseInt(params.endAt, 10));

      const diff = endDate.diff(startDate, 'days');
      const paramsDate = {
        start: startDate,
        end: endDate,
        compareStart: startDate
          .clone()
          .subtract(diff + 1, 'day')
          .startOf('day'),
        compareEnd: startDate.clone().subtract(1, 'day').endOf('day'),
      };
      StorageService.saveDateRange = paramsDate;

      return paramsDate;
    }

    const initDate = {
      start: moment().subtract(7, 'day').startOf('day'),
      end: moment().subtract(1, 'day').endOf('day'),
      compareStart: moment().subtract(14, 'day').startOf('day'),
      compareEnd: moment().subtract(8, 'day').endOf('day'),
    };

    StorageService.saveDateRange = initDate;

    return initDate;
  }, []);

  const getCampaignActivatedFilter = () => {
    if (prevQueryParams?.filter) {
      const resultQueryParamFilter = CampaignFilterList.items.find(
        (list: ListItem) => list.value === prevQueryParams.filter,
      );

      return resultQueryParamFilter as ListItem;
    }

    const resultFilter = defaultFilterItem;

    return resultFilter as ListItem;
  };

  const [metricList, setMetricList] = useState<Metric[]>(metricConfigList());

  // Pagination state
  const [totalCount, setTotalCount] = useState<number>(0);
  const [countPerPage, setCountPerPage] = useState<number>(prevQueryParams.countPerPage);
  const [activePage, setActivePage] = useState<number>(prevQueryParams.activePage);
  const [sortData, setSortData] = useState<CampaignSortData | null>(
    prevQueryParams.sortData ?? { key: '', direction: 1 },
  );

  // filter state
  const [filterData, setFilterData] = useState<{ keyword: string; selectedFilter: ListItem }>({
    keyword: prevQueryParams.searchText,
    selectedFilter: getCampaignActivatedFilter(),
  });

  const [interval, setInterval] = useState<DateRange>({
    ...initSelectedDateRange,
    start: initSelectedDateRange.start.startOf('day'),
    end: initSelectedDateRange.end.endOf('day'),
  });

  const {
    data: campaigns,
    refetch: campaignsRefetch,
    isLoading: isCampaignsLoading,
    isFetching: isCampaignsFetching,
  } = useQuery('getCampaigns', async () => {
    return CampaignApi().getUnifiedCampaigns(
      StorageService.selectedMall.id,
      moment(interval.start).format('YYYY-MM-DD'),
      moment(interval.end).format('YYYY-MM-DD'),
      // countPerPage를 써야하지만 프론트단 filtering이 존재하여 모든 항목을 가져온다.
      // BiginOne에서는 activePage, countPerPage로 사용되어야 함
      1,
      1000,
    );
  });
  const {
    data: campaignsSummary,
    refetch: campaignsSummaryRefetch,
    isFetching: isCampaignsSummaryFetching,
  } = useQuery('getUnifiedCampaignsSummary', async () => {
    return CampaignApi().getUnifiedCampaignsSummary(
      StorageService.selectedMall.id,
      moment(interval.start).format('YYYY-MM-DD'),
      moment(interval.end).format('YYYY-MM-DD'),
      filterData.selectedFilter ? filterData.selectedFilter.value : 'CAMPAIGN_STATUS_ALL',
    );
  });

  const { data: autoCharge } = useQuery<boolean | undefined>(
    'autoCharge',
    async () => (await PaymentApi().getAutoCharge(StorageService.selectedMall.id)).data.data,
  );

  const { data: gtmData } = useQuery<GtmData>(
    'gtmData',
    async () => (await MallApi().getGtmCode(StorageService.selectedMall.id)).data.data,
    { refetchOnMount: 'always' },
  );

  const needBiginSdk = useMemo(() => {
    if (StorageService.activePlan && gtmData) {
      return needBiginSdkCheck(StorageService.activePlan, gtmData);
    }
    return true;
  }, [StorageService.activePlan, gtmData]);

  const filteredCampaigns = useMemo(() => {
    if (!campaigns) return [] as UnifiedCampaign[];

    let result = campaigns.data.data as UnifiedCampaign[];

    if (!isEmpty(filterData.selectedFilter)) {
      result = result.filter((campaign) => {
        if (filterData.selectedFilter.value === 'CAMPAIGN_FILTER_ACTIVE') {
          return campaign.status_tag.code === 'CAMPAIGN_STATUS_LIVE';
        }
        if (filterData.selectedFilter.value === 'CAMPAIGN_FILTER_PARTIAL_INACTIVE') {
          return campaign.status_tag.code === 'CAMPAIGN_STATUS_PENDING';
        }
        if (filterData.selectedFilter.value === 'CAMPAIGN_FILTER_ALL_INACTIVE') {
          return (
            campaign.status_tag.code === 'CAMPAIGN_STATUS_REJECTED' ||
            campaign.status_tag.code === 'CAMPAIGN_STATUS_NON_ACTIVE' ||
            campaign.status_tag.code === 'CAMPAIGN_STATUS_END'
          );
        }
        return true;
      });
    }

    if (filterData.keyword !== '') {
      result = result.filter((campaign) => campaign.name.toLowerCase().includes(filterData.keyword.toLowerCase()));
    }

    if (sortData?.key) {
      result = orderBy(result, [`summary.${sortData?.key}`], sortData?.direction === 1 ? ['asc'] : ['desc']);
    }

    setTotalCount(result.length);

    const startIndex = countPerPage * activePage - countPerPage;
    const endIndex = countPerPage * activePage;

    return result.slice(startIndex, endIndex);
  }, [campaigns, sortData?.key, sortData?.direction]);

  // gridColumn state
  const [gridColumns, setGridColumns] = useState<AllColumnType[]>(StorageService.saveGridColumns ?? initGridColumn());
  const updateQueryParams = (queryParams: object) => {
    const resultQueryParams = qs.stringify(queryParams);
    const url = `/malls/${StorageService.selectedMall.id}/campaign-dashboard?${resultQueryParams}`;

    navigate(url, { replace: true });
  };

  const handleUpdateSort = (key: string) => {
    let resultSortData: CampaignSortData;

    if (sortData?.key === key) {
      resultSortData = {
        ...sortData,
        direction: sortData.direction === 1 ? -1 : 1,
      };
    } else {
      resultSortData = {
        key,
        direction: -1,
      };
    }

    setActivePage(1);

    setSortData((prev) => {
      if (!prev) return prev;
      // eslint-disable-next-line no-param-reassign
      prev.key = resultSortData.key;
      // eslint-disable-next-line no-param-reassign
      prev.direction = resultSortData.direction;
      return prev;
    });

    // set Query Parameter
    updateQueryParams({
      ...prevQueryParams,
      activePage: 1,
      sortData: resultSortData,
    });
  };

  const toggleStatus = useMutation(
    (curCampaign: UnifiedCampaign) => {
      const status = curCampaign.status === 'active' ? 'paused' : 'active';

      return CampaignApi().patchUnifiedCampaignStatus(curCampaign.mall_id, curCampaign.id, status);
    },
    {
      onSuccess: async (mutationData) => {
        const prevValue = queryClient.getQueryData<{ data: { data: UnifiedCampaign[] } }>('getCampaigns');
        if (autoCharge) {
          // 프론트에서 자동충전이 되었는 지 알 수 있는 방법이 없으므로 이전 잔액과 현재 잔액을 비교하는 로직을 추가
          const prevRemain = queryClient.getQueryData<number>('remain');
          const currentRemain = (await PaymentApi().getBalance(StorageService.selectedMall.id)).data.data.remain;

          if (prevRemain !== undefined && currentRemain > prevRemain) {
            toastStore.showToast(
              'basic',
              'success',
              i18next.t('광고 집행을 위해 필요한 크레딧이 자동 충전되었습니다.'),
            );
            queryClient.invalidateQueries('remain'); // 잔액 최신화
          } else {
            queryClient.invalidateQueries('remain'); // 잔액 최신화
          }
        }

        if (prevValue) {
          const cloneValue = _.cloneDeep(prevValue).data.data;

          const findIndex = cloneValue.findIndex((campaign) => campaign.id === mutationData.data.data.id);
          if (findIndex !== -1) {
            cloneValue[findIndex].status = mutationData.data.data.status;
            queryClient.setQueryData('getCampaigns', () => {
              return {
                data: {
                  data: cloneValue,
                },
              };
            });
          }
        }
      },
    },
  );

  const handleCampaignStatusToggle = async (curCampaign: UnifiedCampaign) => {
    toggleStatus.mutate(curCampaign);
  };

  const setHeaderFormat = (column: AllColumnType[]) => {
    const insightTypeText = {
      spend: i18next.t('지출 금액'),
      impressions: i18next.t('노출 수'),
      frequency: i18next.t('노출 빈도'),
      cpm: 'CPM',
      inline_link_clicks: i18next.t('클릭 수'),
      inline_link_click_ctr: 'CTR',
      inline_link_click_cpc: 'CPC',
      actions_1d_view_7d_click: i18next.t('구매 수'),
      actions_7d_click: i18next.t('구매 수'),
      cvr_1d_view_7d_click: i18next.t('구매 전환율'),
      cvr_7d_click: i18next.t('구매 전환율'),
      cpa_1d_view_7d_click: 'CPA',
      cpa_7d_click: 'CPA',
      roas: 'ROAS',
      roas_1d_view_7d_click: 'ROAS',
      roas_7d_click: 'ROAS',
      action_values_1d_view_7d_click: i18next.t('구매 전환 값'),
      action_values_7d_click: i18next.t('구매 전환 값'),
    } as any;

    return column.reduce((acc, cur) => {
      const header = {
        key: cur.prop,
        displayName: insightTypeText[cur.prop],
        dataFormat: 'string',
        handleSort: handleUpdateSort,
        getDataCellElement: function campaignStats(key: string, item: UnifiedCampaign) {
          return (
            <CampaignStats
              isLoaded
              campaign={item as UnifiedCampaign}
              targetStats={(item as UnifiedCampaign).summary[cur.prop as UnifiedCampaignSummaryType]}
              propName={cur.prop}
              formatType={getInsightType(cur.prop)}
              unit={i18next.t(getUnitText(cur.prop))}
              lock={cur.fieldLock}
            />
          );
        },

        headerStyle: {
          justifyContent: 'flex-end',
        },
        cellStyle: { textAlign: 'right' },
      };

      acc.push(header);
      return acc;
    }, [] as CampaignListTableHeader[]);
  };

  const handleBudgetChange = (e: ChangeEvent) => {
    const targetValue = (e.target as HTMLInputElement).value.replace(/[^0-9.]/gi, '').replace(/(\..*)\./gi, '$1');

    switch (currencyCode) {
      case 'SGD': {
        const value = parseFloat(parseFloat(targetValue.replaceAll(',', '')).toFixed(2));
        setDailyBudget(Number.isNaN(value) ? 0 : value);
        setValue('budget', Number.isNaN(value) ? 0 : value, { shouldValidate: true });

        const regex = /^[\d]*\.?[\d]{0,2}$/g; // 소수점 2자리까지만
        if (regex.test(targetValue.replaceAll(',', ''))) {
          setShowBudgetValue(Number.isNaN(value) ? '' : targetValue.replaceAll(',', ''));
        }
        break;
      }
      default:
      case 'KRW': {
        const value = parseInt(targetValue.replaceAll(',', ''), 10);

        setDailyBudget(Number.isNaN(value) ? 0 : value);
        setValue('budget', Number.isNaN(value) ? 0 : value, { shouldValidate: true });
        setShowBudgetValue(Number.isNaN(value) ? '' : value.toString());
        break;
      }
    }
  };
  const changeDailyBudget = useMutation(
    (campaignId: number) => {
      return CampaignApi().patchUnifiedCampaignBudget(StorageService.selectedMall.id, campaignId, dailyBudget * 1e6);
    },
    {
      onSuccess: async (mutationData) => {
        const prevValue = queryClient.getQueryData<{ data: { data: UnifiedCampaign[] } }>('getCampaigns');
        if (autoCharge) {
          // 프론트에서 자동충전이 되었는 지 알 수 있는 방법이 없으므로 이전 잔액과 현재 잔액을 비교하는 로직을 추가
          const prevRemain = queryClient.getQueryData<number>('remain');
          const currentRemain = (await PaymentApi().getBalance(StorageService.selectedMall.id)).data.data.remain;

          if (prevRemain !== undefined && currentRemain > prevRemain) {
            toastStore.showToast(
              'basic',
              'success',
              i18next.t('광고 집행을 위해 필요한 크레딧이 자동 충전되었습니다.'),
            );
            queryClient.invalidateQueries('remain'); // 잔액 최신화
          } else {
            queryClient.invalidateQueries('remain'); // 잔액 최신화
          }
        }

        if (prevValue) {
          const cloneValue = _.cloneDeep(prevValue).data.data;

          const findIndex = cloneValue.findIndex((campaign) => campaign.id === mutationData.data.data.id);
          if (findIndex !== -1) {
            cloneValue[findIndex].daily_budget_e6 = mutationData.data.data.daily_budget_e6;
            queryClient.setQueryData('getCampaigns', () => {
              return {
                data: {
                  data: cloneValue,
                },
              };
            });
          }
        }
        setShowModalDailyBudget(false);
      },
    },
  );

  const handleChangeDailyBudget = async () => {
    if (!selectedCampaign) return;
    changeDailyBudget.mutate(selectedCampaign.id);
  };

  const handleShowChangeDailyBudget = (campaign: UnifiedCampaign) => {
    const decimalPlaceLength = multilingual.getDecimalPlaceLength({ currencyCode });
    setSelectedCampaign(campaign);
    setDailyBudget(campaign.daily_budget_e6 / 1e6);
    setShowBudgetValue((campaign.daily_budget_e6 / 1e6).toFixed(decimalPlaceLength));
    setShowModalDailyBudget(true);
  };

  const [headers, setHeaders] = useState(setHeaderFormat(gridColumns.filter((value) => value.checked)));

  const handleGridColumnsUpdate = (curGridColumns: AllColumnType[]) => {
    const newGrid = curGridColumns.map((item) => {
      const fieldLock = needBiginSdk && item.lock;

      return {
        ...item,
        fieldLock,
      };
    });
    setGridColumns(newGrid);
    StorageService.saveGridColumns = newGrid;
  };

  useEffect(() => {
    setHeaders(setHeaderFormat(gridColumns.filter((value) => value.checked)));
  }, [gridColumns]);

  const onChangeFilter = (searchText: string, activatedFilter: ListItem) => {
    // set State
    setActivePage(1);
    setSortData(null);
    setFilterData({
      ...filterData,
      keyword: searchText,
      selectedFilter: activatedFilter,
    });

    // set Query Parameter
    updateQueryParams({
      ...prevQueryParams,
      activePage: 1,
      sortData: null,
      searchText,
      filter: activatedFilter.value,
    });
  };

  const updateInitialQueryParams = () => {
    let queryParams;

    if (interval && interval.start && interval.end) {
      queryParams = qs.stringify({
        ...prevQueryParams,
        startAt: interval.start.valueOf(),
        endAt: interval.end.valueOf(),
        compareStartAt: interval.compareStart?.valueOf(),
        compareEndAt: interval.compareEnd?.valueOf(),
      });
    } else {
      queryParams = qs.stringify({
        ...prevQueryParams,
      });
    }

    const url = `/malls/${StorageService.selectedMall.id}/campaign-dashboard?${queryParams}`;
    setDate(interval);
    navigate(url, { replace: true });
  };

  useEffect(() => {
    updateInitialQueryParams();
    campaignsRefetch();
    campaignsSummaryRefetch();
  }, [interval, activePage, countPerPage, filterData, StorageService.selectedMall.id]);

  useEffect(() => {
    if (!StorageService.saveGridColumns) {
      StorageService.saveGridColumns = initGridColumn();
    }
    const newGrid = StorageService.saveGridColumns.map((item: AllColumnType) => {
      const fieldLock = needBiginSdk && item.lock;

      return {
        ...item,
        fieldLock,
      };
    });
    setGridColumns(newGrid);
    StorageService.saveGridColumns = newGrid;
  }, [needBiginSdk]);

  useEffect(() => {
    updateInitialQueryParams();
  }, [sortData]);

  useEffect(() => {
    const savedDate = StorageService.saveDateRange;

    if (!document.cookie.includes('term_of_use')) {
      Cookies.set('term_of_use', 'true');
      setShowInfo(true);
    }

    if (savedDate) {
      setDate(savedDate);
    }
  }, []);

  const handleCloseShowInfo = () => {
    Cookies.set('term_of_use', 'false');
    setShowInfo(false);
  };

  useEffect(() => {
    if (!campaignsSummary) return;
    const updateMetricList = metricConfigList().map((metric) => {
      const count = campaignsSummary.data.data.summary.current[metric.prop];
      const compareCount = campaignsSummary.data.data.summary.compare[metric.prop];
      return { ...metric, count, compareCount };
    });
    setMetricList(updateMetricList);
    handleGridColumnsUpdate(StorageService.saveGridColumns);
  }, [campaignsSummary, StorageService.activePlan?.type]);

  const pinnedHeader = [
    {
      key: 'campaignName',
      displayName: i18next.t('캠페인 명'),
      dataFormat: 'string',
      getDataCellElement: function campaignTitle(key: string, item: UnifiedCampaign) {
        return (
          <CampaignTitle
            key={key}
            campaign={item as UnifiedCampaign}
            handleClick={() => {
              navigate(`/malls/${item.mall_id}/campaign-report?id=${item.id}`);
            }}
            handleStatusToggle={handleCampaignStatusToggle}
            handleShowChangeDailyBudget={handleShowChangeDailyBudget}
          />
        );
      },
      headerStyle: {
        justifyContent: 'flex-start',
      },
      cellStyle: {
        width: '484px',
      },
      skeletonComponent: function campaignChannelSkeleton() {
        return <CampaignTitle isSkeleton />;
      },
    },
  ];

  const gtmModalBtns = (): { label: string; handleClick?: () => void; appearance?: string; disabled?: boolean }[] => {
    return [
      {
        label: i18next.t('취소'),
        appearance: 'secondary',
        handleClick: () => {
          setShowModalSDKInstall(false);
        },
      },
      {
        label: i18next.t('스크립트 직접 설치'),
        appearance: 'secondary',
        handleClick: () => {
          setShowModalSDKInstall(false);
          navigate(`/malls/${StorageService.selectedMall.id}/accounts?tab=bigin-sdk`);
        },
      },
      {
        label: i18next.t('플랜 업그레이드'),
        disabled: StorageService?.activePlan?.type === 'smartstore',
        handleClick: () => {
          if (StorageService?.activePlan?.type !== 'smartstore') {
            setShowModalSDKInstall(false);
            navigate(`/malls/${StorageService.selectedMall.id}/plan`);
          }
        },
      },
    ];
  };

  return (
    <SkeletonTheme baseColor="#edf1f6" highlightColor="#ffffff">
      <BGPage>
        <TopNavBar
          title={i18next.t('캠페인 관리')}
          button={[
            {
              id: 1,
              label: i18next.t('통합 캠페인 생성'),
              onClick: () => {
                navigate(`/malls/${StorageService.selectedMall?.id}/create-campaign`);
              },
            },
          ]}
        />
        <div css={[baseCss]}>
          {showInfo && (
            <BGPageBanner
              text={i18next.t('서비스 플랜 변경으로 이용약관이 변경됩니다.')}
              type="link"
              handleMoreView={() => window.open('https://www.notion.so/13672e5ccdc749e6b5d3473bc95b456e', '_blank')}
              handleClose={() => handleCloseShowInfo()}
              language={i18n.language === 'ko' ? 'ko' : 'en'}
            />
          )}

          <div style={{ width: '100%' }}>
            <BGListFilterV2
              defaultSearchText={filterData.keyword}
              filters={CampaignFilterList}
              selectedFilter={filterData.selectedFilter}
              onChangeFilters={onChangeFilter}
              dateConfig={{
                selectableRange: selectableDateRange,
                initDateRange: initSelectedDateRange,
                onChangeDate: (dateRange: DateRange) => {
                  const diff = dateRange.end.diff(dateRange.start, 'days');
                  const compareStart = moment(dateRange.start)
                    .subtract(diff + 1, 'day')
                    .startOf('day');
                  const compareEnd = moment(dateRange.start).subtract(1, 'day').endOf('day');

                  const changeDate = {
                    start: dateRange.start.startOf('day'),
                    end: dateRange.end.endOf('day'),
                    compareStart,
                    compareEnd,
                  };

                  StorageService.saveDateRange = changeDate;
                  setInterval(changeDate);
                },
              }}
            />
          </div>
          <BorderSection style={{ width: '100%', overflow: 'auto', borderRadius: '8px' }}>
            <div className="summary-container flex-col">
              <div className="title-container" style={{ border: 'none' }}>
                <div className="title-wrapper flex-row" style={{ paddingBottom: '0' }}>
                  <div className="title-wrapper-text text-h2 text-color-main">{i18next.t('캠페인 개요')}</div>
                  <div className="title-item-wrapper">
                    <div className="text-disclaimer">
                      <span className="text-color-main text-bold mr-1">{i18next.t('기준 기간')}</span>
                      {`${moment(date?.start).format('YYYY.MM.DD')}~${moment(date?.end).format('YYYY.MM.DD')}`}
                    </div>
                    <div className="text-disclaimer">
                      <span className="text-color-main text-bold mr-1">{i18next.t('비교 기간')}</span>
                      {`${moment(date?.compareStart).format('YYYY.MM.DD')}~${moment(date?.compareEnd).format(
                        'YYYY.MM.DD',
                      )}`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="application-status-wrapper">
                {metricList &&
                  metricList.map((status) => (
                    <MetricItem
                      key={status.prop}
                      status={status}
                      lock={needBiginSdk && status.lock}
                      isLoading={isCampaignsSummaryFetching}
                      onClick={(event) => {
                        event.stopPropagation();
                        if (needBiginSdk && status.lock) {
                          setShowModalSDKInstall(true);
                        }
                      }}
                    />
                  ))}
              </div>
            </div>
          </BorderSection>
          <BorderSection style={{ width: '100%', borderRadius: '8px' }}>
            <div className="table-container flex-col">
              <div className="title-container">
                <div className="title-wrapper flex-row">
                  <div className="title-wrapper-text text-h2 text-color-main">{i18next.t('캠페인 목록')}</div>
                </div>
              </div>
              <div>
                <CampaignListTable
                  isLoaded={!isCampaignsFetching}
                  pinnedHeader={pinnedHeader}
                  headers={headers}
                  lists={filteredCampaigns}
                  containerStyle={{
                    width: '100%',
                  }}
                  defaultHeaderStyle={{
                    justifyContent: 'center',
                  }}
                  gridColumns={gridColumns}
                  handleGridColumnsUpdate={handleGridColumnsUpdate}
                />
              </div>
            </div>
          </BorderSection>
        </div>
        {campaigns && !isCampaignsLoading && (
          <div
            className="table-footer-wrapper"
            style={{ position: 'relative', width: '100%', height: '36px', marginBottom: '80px' }}
          >
            <BGPagination
              isLoaded={!isCampaignsLoading}
              curPageItemLength={10}
              totalCount={totalCount}
              countPerPage={countPerPage}
              activePage={activePage}
              handleCountPerPage={(curCountPerPage) => {
                setActivePage(1);
                setCountPerPage(curCountPerPage);

                // set Query Parameter
                updateQueryParams({
                  ...prevQueryParams,
                  activePage: 1,
                  countPerPage: curCountPerPage,
                });
              }}
              handleActivePage={(curActivePage) => {
                setActivePage(curActivePage);

                // set Query Parameter
                updateQueryParams({
                  ...prevQueryParams,
                  activePage: curActivePage,
                });
              }}
              selectBoxPosition="top"
            />
          </div>
        )}
        {showModalDailyBudget && (
          <WrapperModal
            isOpen={showModalDailyBudget}
            close={() => {
              setShowModalDailyBudget(false);
            }}
            position={{ width: '320px' }}
          >
            <div className="edit-campaign-setting" css={[changeBudgetModalCss]}>
              <div className="title-wrapper">
                <div className="title-wrapper-text text-h2 text-color-main">{i18next.t('예산 수정')}</div>
              </div>
              <div className="contents-wrapper">
                <div className="text-h5 text-color-sub mt-2 mb-2">{i18next.t('일 예산')}</div>
                <BGInput
                  inputProps={{
                    type: 'text',
                    name: 'budget',
                    placeholder: i18next.t('일 예산을 입력하세요.'),
                    value: filters.formatStringComma(showBudgetValue),
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      handleBudgetChange(event);
                    },
                  }}
                  rightLabelComponent={<span className="text-h5 text-right text-color-sub">{currencyCode}</span>}
                  leftLabelComponent={
                    <span className="text-h5 text-right text-color-sub">
                      {multilingual.getCurrencySymbolByCurrencyCode({ currencyCode })}
                    </span>
                  }
                />
                {formState.errors.budget && <InputError message={formState.errors.budget.message as string} />}
                <p className="text-disclaimer mt-2">
                  {i18next.t('*유효한 광고 성과를 위해, 광고 유형 당 {{budget}} 이상의 일 예산이 필요합니다.', {
                    budget: filters.formatCurrency({
                      value: multilingual.getMinimumBudgetByCurrency({
                        currency: currencyCode,
                      }),
                      currencyCode,
                    }),
                  })}
                </p>
              </div>
              <div className="footer-wrapper">
                <BDSButton
                  appearance="secondary"
                  label={i18next.t('취소')}
                  onClick={() => {
                    setShowModalDailyBudget(false);
                    if (selectedCampaign) {
                      setDailyBudget(selectedCampaign.daily_budget_e6 / 1e6);
                      const decimalPlaceLength = multilingual.getDecimalPlaceLength({ currencyCode });
                      setShowBudgetValue((selectedCampaign.daily_budget_e6 / 1e6).toFixed(decimalPlaceLength));
                    }
                  }}
                />
                <BDSButton
                  label={i18next.t('확인')}
                  isDisabled={!formState.isValid || minBudget > dailyBudget}
                  onClick={handleChangeDailyBudget}
                />
              </div>
            </div>
          </WrapperModal>
        )}
        {showModalSDKInstall && (
          <WrapperModal
            isOpen={showModalSDKInstall}
            close={() => {
              setShowModalSDKInstall(false);
            }}
            position={{ width: '370px' }}
          >
            <DialogConfirm
              title={i18next.t('Bigin SDK 설치 필요')}
              desc={i18next.t(
                '해당 지표를 확인하기 위해선 Bigin SDK 설치가 필요합니다.\n플랜을 업그레이드하면 전문가가 대신 Bigin SDK를 설치해드립니다',
              )}
              buttons={[...gtmModalBtns()]}
            />
          </WrapperModal>
        )}
      </BGPage>
    </SkeletonTheme>
  );
};
