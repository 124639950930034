import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, useTheme, Theme } from '@emotion/react';
import styled from '@emotion/styled';

export interface BGCheckboxProps {
  style?: object;
  checked?: boolean;
  fixed?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  size: number;
  isError?: boolean;
}

export const StyledBGCheckbox = styled.div<{
  theme: Theme;
  size: number;
  checked: boolean;
  fixed: boolean;
  disabled: boolean;
  isError?: boolean;
}>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 2px;
  border: solid 1px
    ${(props) => {
      if (props.isError) {
        return '#e65c5c';
      }
      if (props.checked) {
        return '#006FFF';
      }
      if (props.fixed) {
        return '#bbbbcc';
      }
      return '#7e8696';
    }};
  box-sizing: border-box;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  background: ${(props) => {
    if (props.isError) {
      return `url(/assets/img/common/ic-check.png) center / contain no-repeat #e65c5c`;
    }
    if (props.checked) {
      return `url(/assets/img/common/ic-check.png) center / contain no-repeat #006FFF`;
    }
    if (props.fixed) {
      return `url(/assets/img/common/ic-check.png) center / contain no-repeat #bbbbcc`;
    }
    if (props.disabled) {
      return '#f7f7fa';
    }
    return '#ffffff';
  }};
`;

export const BGCheckbox = (props: BGCheckboxProps): ReactElement => {
  const { style, checked = false, fixed = false, disabled = false, size = 20, onChange } = props;

  const theme: Theme = useTheme();

  return (
    <StyledBGCheckbox
      theme={theme}
      onClick={() => {
        if (onChange) onChange(!checked);
      }}
      style={style}
      size={size}
      checked={checked}
      fixed={fixed}
      disabled={disabled}
      isError={props.isError}
    />
  );
};
