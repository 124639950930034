import React from 'react';
import { useTranslation } from 'react-i18next';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import Skeleton from 'react-loading-skeleton';
import { baseCss } from './AccountItem.style';

export interface AccountItemPops {
  children: React.ReactNode;
  title: string;
  iconUrl?: string;
  description?: string;
  direction?: 'row' | 'column';
  className?: string;
  essential?: boolean;
  style?: object;
  isLoading?: boolean;
}

export const AccountItem = ({
  children,
  title,
  iconUrl = '',
  description,
  direction = 'row',
  essential = false,
  className,
  style = {},
  isLoading,
}: AccountItemPops): JSX.Element => {
  const i18next = useTranslation();
  const classNames = className ? ['bg-button'].concat(className.split(' ')) : ['bg-button'];

  return (
    <div className={`item ${direction} ${classNames.join(' ')}`} css={baseCss} style={style || {}}>
      {isLoading && (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
          <Skeleton style={{ width: '200px' }} />
          {direction !== 'column' && <Skeleton style={{ width: '500px' }} />}
        </div>
      )}
      {!isLoading && (
        <React.Fragment>
          <div className="ico_area">
            <img
              className={`icon ${direction}`}
              src={iconUrl}
              alt={i18next.t(title)}
              style={
                title === 'Bigin CRM' ? { width: '26px', height: '33px', marginRight: '16px', marginLeft: '8px' } : {}
              }
            />
          </div>
          <div className="dsc_area">
            <strong className="elss title">
              {i18next.t(title)} {essential ? <div className="badge">{i18next.t('필수사항')}</div> : null}
            </strong>
            {description && <p className="elss dsc">{i18next.t(description)}</p>}
          </div>
          <div className="button-wrap">{children}</div>
        </React.Fragment>
      )}
    </div>
  );
};
