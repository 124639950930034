import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .aw-asset-modal-header {
      margin-bottom: 20px;

      & > :not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .aw-asset-modal-body {
      margin-bottom: 20px;

      .form-input-group {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        & > *:not(:last-child) {
          margin-bottom: 8px;
        }

        .form-input-label {
          ${theme.text.h4};
          color: ${theme.colors.mainFont};
        }
      }

      .no-channel-container {
        height: 56px;
        display: flex;
        align-items: center;
        padding: 0 12px 0 12px;

        border-radius: 8px;
        border: solid 1px #dce0e8;
        background-color: #f9fafb;

        ${theme.text.input};
        color: ${theme.colors.subFont};
      }
      .empty-video-list-wrapper {
        height: 56px;
        display: flex;
        align-items: center;
        padding: 0 12px 0 12px;

        border-radius: 8px;
        background-color: #f9fafb;

        ${theme.text.input};
        color: ${theme.colors.subFont};
      }

      // BGSelectBox가 disabled처리 되어도 UI상 변경 없도록 하는 꼼수
      .select-wrapper {
        .gray.disabled .material-dropdown-selected-item-wrapper {
          background-color: initial !important;
        }
        .gray.disabled .material-dropdown-item.gray {
          background-color: initial !important;
          opacity: initial !important;
        }
      }
    }
    .aw-asset-modal-footer {
      display: flex;
      justify-content: space-between;

      .logout-container {
        ${theme.text.disclaimer};

        .logout {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  `;
};

export const selectYoutubeVideoCss = () => {
  const theme: Theme = useTheme();

  return css`
    .video-container {
      max-height: 252px;
      border-radius: 8px;
      border: solid 1px #dce0e8;

      overflow: scroll;
      -ms-overflow-style: none; // IE and Edge
      scrollbar-width: none; // Firefox
      &::-webkit-scrollbar {
        display: none;
      }

      .video-item {
        height: 56px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 8px 12px 8px 12px;

        &:hover {
          background-color: #f9fafb;
        }

        &:not(:last-child) {
          border-bottom: solid 1px #dce0e8;
        }

        .video-item-image {
          width: 72px;
          height: 40px;
          border-radius: 4px;
          border: solid 1px #dce0e8;
          flex-shrink: 0;
          margin-right: 8px;
        }

        .video-item-title {
          color: ${theme.colors.mainFont};
          ${theme.text.input};
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &.selected {
            font-weight: bold;
          }
        }
      }
    }
  `;
};
