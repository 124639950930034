import React, { ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { useSetRecoilState } from 'recoil';
import { AllColumnType } from '../../types/BGEventDef';
import { PropertyGroupWrapperStyle } from './ColumnPicker.style';
import { BGCheckbox } from '../BGCheckbox/BGCheckbox';
import { BGTooltip } from '../BGTooltip/BGTooltip';
import CampaignList from '../../recoil/CampaignList';

const getUniqKey = (prefix: string, index: number): string => {
  return `${prefix}-${index}`;
};

interface PropertyGroupType {
  groupName: string;
  columns: AllColumnType[];
  // eslint-disable-next-line react/require-default-props
  handleUpdate?: (curColumn: AllColumnType) => void;
}

const PropertyGroupWrapper = (props: PropertyGroupType): ReactElement => {
  const i18next = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const updatePropertyChecked = (curColumn: AllColumnType) => {
    if (props.handleUpdate) props.handleUpdate({ ...curColumn, checked: !curColumn.checked });
  };
  const setShowModalSDKInstall = useSetRecoilState<boolean>(CampaignList.sdkModal);

  const PickerItem = (curColumn: AllColumnType): ReactElement => {
    if (curColumn.fieldLock) {
      return (
        <BGTooltip title={i18next.t('Bigin SDK 설치 필요') || ''}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <BDSFontIcon name="ic-lock" />
            <span>{i18next.t(curColumn.displayName)}</span>
          </div>
        </BGTooltip>
      );
    }

    let icon;
    if (curColumn.fixed) {
      icon = <BGCheckbox size={14} fixed />;
    } else if (curColumn.checked) {
      icon = <BGCheckbox size={14} checked />;
    } else {
      icon = <BGCheckbox size={14} disabled />;
    }

    return (
      <React.Fragment>
        {icon}
        <span>{i18next.t(curColumn.displayName)}</span>
      </React.Fragment>
    );
  };

  return (
    <div css={PropertyGroupWrapperStyle}>
      <div
        className="property-group-name-wrapper"
        onClick={() => {
          setIsOpen((preVal) => !preVal);
        }}
      >
        <div className="property-group-name-text">{i18next.t(props.groupName)}</div>
        <BDSFontIcon
          className={`column-group-icon ${isOpen ? 'open' : ''}`}
          name="ic-arrow-right"
          color="#53585f"
          size="16px"
        />
      </div>

      {isOpen && (
        <React.Fragment>
          {props.columns
            .filter((curColumn) => {
              return !!curColumn.visible;
            })
            .map((curColumn, index) => {
              return (
                <div
                  key={getUniqKey(`${curColumn.prop}`, index)}
                  className={`property-item-wrapper ${curColumn.checked ? 'active' : ''} ${
                    curColumn.fixed ? 'fixed' : ''
                  }`}
                  onClick={() => {
                    if (!curColumn.fieldLock) {
                      if (!curColumn.fixed) updatePropertyChecked(curColumn);
                    } else {
                      setShowModalSDKInstall(true);
                    }
                  }}
                >
                  {PickerItem(curColumn)}
                </div>
              );
            })}
        </React.Fragment>
      )}
    </div>
  );
};

interface PropertyPickerProps {
  propertyColumns: AllColumnType[];
  // eslint-disable-next-line react/require-default-props
  handleUpdate?: (curPropertyColumns: AllColumnType[]) => void;
}

export const PropertyPicker = (props: PropertyPickerProps): ReactElement => {
  const updatePropertyColumn = (curPropertyColumn: AllColumnType) => {
    if (!props.handleUpdate) return;
    const curColumnIndex = _.findIndex(props.propertyColumns, { prop: curPropertyColumn.prop });
    props.handleUpdate([
      ...props.propertyColumns.slice(0, curColumnIndex),
      { ...curPropertyColumn },
      ...props.propertyColumns.slice(curColumnIndex + 1),
    ]);
  };
  return (
    <div>
      {_.entries(_.groupBy(props.propertyColumns, 'groupName'))
        .map(([groupName, columns]) => {
          return {
            groupName,
            columns,
          };
        })
        .map((propertyGroup: PropertyGroupType) => {
          return (
            <PropertyGroupWrapper
              key={`${propertyGroup.groupName}`}
              {...propertyGroup}
              handleUpdate={updatePropertyColumn}
            />
          );
        })}
    </div>
  );
};
