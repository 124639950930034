import moment from 'moment';
import Plan from '../services/plans/Plan';

export const IMAGE_PROXY_URL = `https://cors-proxy.advertiser.bigin.io/`;

export const AW_SHORT_TITLE_MAX_BYTE = 30;

export const AW_MAX_BYTE = 90;
export const AW_SHORT_MAX_BYTE = 60;

export const KK_MAX_LENGTH = 25;
export const getLang = () => {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
};

export const getMediaPlatform = (mediaType: string) => {
  switch (mediaType) {
    case 'fb-business':
    case 'fb-ad-account':
    case 'fb-pixel':
    case 'fb-page':
      return 'facebook';
    case 'fb-instagram-account':
      return 'instagram';
    default:
      return 'taggers';
  }
};

export const googleAdRegexs = () => {
  const specialCharRegex = /[{}[\]/?:|)*~`^\-_+<>@#$\\=('"]/;
  const ampRegex = /(&amp;)/;
  const emojiRegex =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/;
  const doubleCharRegex = /[*]{2,}|[.]{2,}|[&]{2,}|[%]{2,}|[!]{2,}|[,]{2,}|[;]{2,}/;
  const enterRegex = /(Enter)/;
  const doubleSpaceRegex = / {2,}/;

  return [specialCharRegex, ampRegex, emojiRegex, doubleCharRegex, enterRegex, doubleCharRegex, doubleSpaceRegex];
};

// export const getLogo = (platform: string, color: string, type = 'svg') => {
//   return require(`@/assets/images/logos/${platform}/${color}.${type}`);
// };

export const getGtmCode = (containerId: string) => {
  return `<!-- Start Taggers Pixel -->
  <script>!function(t,e,n){t[n]=t[n]||[],t[n]
    .push({"gtm.start":(new Date).getTime(),event:"gtm.js"})}
    (window,document,"dataLayer")
  </script>
  <script async src="https://www.googletagmanager.com/gtm.js?l=dataLayer&id=${containerId}"></script>
  <script async src="https://www.googletagmanager.com/gtag/js"></script>
  <script async src="https://www.google-analytics.com/analytics.js"></script>
  <script async src="https://www.googleadservices.com/pagead/conversion_async.js"></script>
  <script>!function(e,u){
    e.fbq||(u=e.fbq=function(){
      u.callMethod?u.callMethod.apply(u,arguments):u.queue.push(arguments)
    },e._fbq||(e._fbq=u),(u.push=u).loaded=!0,u.version="2.0",u.queue=[])
  }(window)</script><script async src="https://connect.facebook.net/en_US/fbevents.js"></script>
  <!-- End Taggers Pixel -->`;
};

// export const getCardIcon = (brand: string) => {
//   try {
//     return require(`@/assets/images/icons/${brand}.svg`);
//   } catch (e) {
//     return require(`@/assets/images/icons/ic_creditcard.svg`);
//   }
// };

export function absoluteRatio(current: number, compare: number) {
  const isSomeZero = current === 0 || compare === 0;
  const isSame = current === compare;
  if (isSomeZero || isSame) {
    return 0;
  }
  return (current - compare).toFixed(2);
}

export function relativeRatio(current: number, compare: number) {
  const isSomeZero = current === 0 || compare === 0;
  const isSame = current === compare;
  if (isSomeZero || isSame) {
    return 0;
  }
  if (current - compare >= 0) {
    return Math.round((current / compare - 1) * 10000) / 100;
  }
  return -Math.round((1 - current / compare) * 10000) / 100;
}

export function compareInsight(
  value: number,
  compareValue: number,
  compareType: 'absolute' | 'relative',
  reverse = false,
) {
  const comparisonValue =
    compareType === 'absolute' ? absoluteRatio(value, compareValue) : relativeRatio(value, compareValue);

  if (comparisonValue > 0) {
    return {
      icon: 'ic_increase_16',
      color: reverse ? '#3F7BA0' : '#B81F18',
    };
  }
  if (comparisonValue < 0) {
    return {
      icon: 'ic_decrease_16',
      color: reverse ? '#B81F18' : '#3F7BA0',
    };
  }
  if (comparisonValue === 0) {
    return {
      icon: 'ic_level-off_16',
      color: '#999999',
    };
  }
  return {
    icon: null,
    color: 'transparent',
  };
}

export function dateDiff(start: string, end: string) {
  return moment(start).diff(moment(end), 'days');
}

export function strByteLength(s: string) {
  if (!s) return 0;
  return (
    s
      .split('')
      // eslint-disable-next-line @typescript-eslint/no-shadow
      .map((s) => s.charCodeAt(0))
      .reduce((prev, c) => {
        let value;

        if (c === 10) {
          value = 2;
          // eslint-disable-next-line no-bitwise
        } else if (c >> 7) {
          value = 2;
        } else {
          value = 1;
        }
        return prev + value;
      }, 0)
  );
}

export function getMediaIconsByCampaignType(type: CampaignType) {
  const facebookInstagramTypes: CampaignType[] = [
    'fb_single_image',
    'fb_slide_image',
    'fb_single_video',
    'fb_catalog_slide',
    'fb_collection_image',
    'fb_collection_dynamic_video',
    'fb_collection_video',
    'fb_dynamic',
    'fb_dynamic_image',
    'fb_dynamic_dynamic_video',
    'fb_dynamic_slide',
    'fb_dynamic_video',
  ];
  const googleTyeps: CampaignType[] = [
    'aw_shopping',
    'aw_smart_shopping',
    'aw_dynamic_remarketing',
    'aw_responsive_image',
    'aw_pmax',
    'aw_search',
  ];
  const youtubeTypes: CampaignType[] = ['aw_youtube'];
  const kakaoTypes: CampaignType[] = ['kk_image_banner', 'kk_image_native'];

  if (facebookInstagramTypes.includes(type)) {
    return ['ic-channel-facebook', 'ic-channel-instagram'];
  }
  if (googleTyeps.includes(type)) {
    return ['ic-channel-google'];
  }
  if (youtubeTypes.includes(type)) {
    return ['ic-channel-youtube'];
  }
  if (kakaoTypes.includes(type)) {
    return ['ic-channel-kakao'];
  }
  return [];
}

export const getMedia = (type: CampaignType) => {
  if (type.includes('aw_youtube')) return 'youtube';
  if (type.includes('aw')) return 'google';
  if (type.includes('fb')) return 'facebook';
  if (type.includes('kk')) return 'kakao';
  return '';
};

export const makeForm = (obj: any) => {
  const form = new FormData();
  Object.keys(obj).forEach((key) => {
    form.append(key, obj[key]);
  });
  return form;
};

export const needBiginSdkCheck = (activePlan: any, gtmData: GtmData) => {
  return Plan.isFreeType(activePlan?.type ?? 'smartstore') && gtmData.script_code === null;
};

// export const sortByDesc = (a: number, b: number) => {
//   if (a > b) {
//     return -1;
//   } else if (a < b) {
//     return 1;
//   } else {
//     return 0;
//   }
// };
