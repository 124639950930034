import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, useTheme, Theme } from '@emotion/react';
import { LinearProgress } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { BGButton, BGButtonProps } from '../BGButton/BGButton';
import { BGTooltip } from '../BGTooltip/BGTooltip';
import { baseCss, useLinearProgressStyle } from './TopNavBar.style';
import SideNavBarRecoil from '../../recoil/SideNavBar';

export interface TopNavBarButtonProps extends BGButtonProps {
  tooltip?: string;
}

export interface TopNavBarProps {
  title: JSX.Element | JSX.Element[] | string;
  isProgressBar?: boolean;
  infoData?: JSX.Element | JSX.Element[] | string;
  button?: TopNavBarButtonProps[];
  style?: object;
}

export const TopNavBar = (props: TopNavBarProps): ReactElement => {
  const theme: Theme = useTheme();
  const sideNavBarisCollapsed = useRecoilValue(SideNavBarRecoil.isCollapsed);
  const classes = useLinearProgressStyle({ isCollapsed: sideNavBarisCollapsed });

  const themeCss = css`
    border-bottom: 1px solid ${theme.colors.divider};
    color: ${theme.colors.subFont};
    background-color: ${theme.colors.bg2};
    .top-nav-left {
      color: ${theme.colors.subFont};
    }
  `;

  return (
    <div css={[baseCss(sideNavBarisCollapsed), themeCss]} className="top-nav-bar" style={props.style || {}}>
      <div className="top-nav-bar-contents">
        <div className="top-nav-title">{props.title}</div>
        <div className="top-nav-info">{props.infoData}</div>
      </div>
      {props.button && (
        <div className="top-nav-bar-buttons">
          {props.button.map((btn) => {
            if (btn?.tooltip) {
              return (
                <BGTooltip key={btn.id} title={btn.tooltip} placement="left">
                  <div>
                    <BGButton
                      {...btn}
                      key={btn.id}
                      label={btn.label}
                      style={btn.style ? { ...btn.style } : {}}
                      onClick={btn.onClick}
                      isDisabled={btn.isDisabled}
                      appearance={btn.appearance}
                      icon={btn.icon}
                    />
                  </div>
                </BGTooltip>
              );
            }
            return (
              <BGButton
                {...btn}
                key={btn.id}
                label={btn.label}
                style={btn.style ? { ...btn.style } : {}}
                onClick={btn.onClick}
                isDisabled={btn.isDisabled}
                appearance={btn.appearance}
                icon={btn.icon}
              />
            );
          })}
        </div>
      )}
      {props.isProgressBar && <LinearProgress classes={classes} />}
    </div>
  );
};
