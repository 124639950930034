import axios, { AxiosPromise } from 'axios';

export interface TemplateList {
  created_at: string;
  deleted_at?: string;
  updated_at: string;
  id: number;
  mall_id: number;
  properties: Properties[];
  template_id: string;
  name: string;
  thumbnail_url: string;
}

export interface TemplateBatchesForm {
  template_id: number;
  jobs: Jobs[];
}

export interface Jobs {
  product_id: number;
  metadata: Properties[];
}
export interface RequestTemplateForm {
  email: string;
  phone: string;
  media: string;
  reference: string;
  request: string;
}

export interface TemplateFormList {
  product_id: number;
  properties: Properties[];
  id?: any;
}

export interface Properties {
  objectName: string;
  type: 'IMAGE_URL' | 'IMAGE_FIT_TYPE' | 'IMAGE_ANCHOR_X' | 'IMAGE_ANCHOR_Y' | 'TEXT' | 'DYNAMIC_FIGURE_COLOR' | '';
  objectNamePostfix: string;
  headerName: string;
  text?: string;
  imageUrl?: string;
  value?: string;
  maxLength?: number;
}

interface UploadImage {
  created_at: string;
  filesize: number;
  height: number;
  id: number;
  md5: string;
  mime_type: string;
  updated_at: string;
  url: string;
  width: number;
}

export interface Remains {
  remains: number;
}

export const AssetsApi = () => ({
  getTemplates(mallId: number, type: 'common' | 'requested'): AxiosPromise<{ data: TemplateList[] }> {
    return axios.get(`/malls/${mallId}/assets/templates?filter[type]=${type}`);
  },
  getTemplate(mallId: number, templateId: number): AxiosPromise<{ data: TemplateList }> {
    return axios.get(`/malls/${mallId}/assets/templates/${templateId}`);
  },
  getAssetsRemainCount(mallId: number, type: 'asset_image' | 'asset_template'): AxiosPromise<{ data: Remains }> {
    return axios.get(`/malls/${mallId}/tickets/${type}/remains`);
  },
  postCreateImage(mallId: number, templateForm: TemplateBatchesForm): AxiosPromise<{ data: TemplateList }> {
    return axios.post(`/malls/${mallId}/assets/batches`, templateForm);
  },
  postUploadImage(image: File | Blob): AxiosPromise<{ data: UploadImage }> {
    const fromedImage = new FormData();
    fromedImage.append('image', image);

    return axios.post(`/images`, fromedImage);
  },
  postManageTemplates(mallId: number, templateId: number): AxiosPromise<{ data: TemplateList }> {
    return axios.post(`/management/malls/${mallId}/assets/templates`, { template_id: templateId });
  },
  postSendEmailTemplate(mallId: number): AxiosPromise<{ data: { to: string } }> {
    return axios.post(`/malls/${mallId}/email-me/asset`);
  },
});
