import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();
  return css`
    width: 100%;

    .horizontal-line {
      width: 100%;
      height: 1px;
      background-color: #ebeef4;
    }

    .create-section-content-wrapper {
      display: flex;
      width: 100%;
      min-height: 700px;

      .preview-container {
        width: calc(100% - 400px);
        padding: 20px 20px 60px;
        position: relative;
        display: flex;
        flex-direction: column;

        .sticky-wrap {
          position: sticky;
          top: 106px;
          width: 100%;
        }
        .title-panel {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            ${theme.text.h4};
            color: ${theme.colors.mainFont};
          }
          .info {
            ${theme.text.disclaimer};
          }
        }

        .google-preview {
          border-radius: 8px;
          background-color: #f9fafb;
          border: solid 1px #dce0e8;
          min-height: 700px;
          position: relative;

          .preview-title-wrapper {
            padding: 20px 0 0 20px;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #3d4046;
          }
        }
        .material-preview-wrapper {
          width: 100%;
          height: 100%;
          margin-top: 16px;
        }
      }

      .form-container {
        border-left: 1px solid ${theme.colors.divider};
        width: 400px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 20px;

        .material-field-header {
          ${theme.text.h4}
          color: ${theme.colors.mainFont};
          margin: 0;
          padding: 16px 20px 20px;
        }

        .material-field-selector-wrapper {
          width: 100%;
          height: auto;
          padding: 0 20px 20px;

          .description {
            margin-top: 8px;
            ${theme.text.disclaimer}
          }

          .material-field-input-wrapper {
            position: relative;

            textarea {
              height: 49px;
            }
          }

          .material-dropdown.gray {
            border-radius: 8px;

            .material-dropdown-item.gray {
              border-radius: 8px;
            }
          }
        }
      }
    }
  `;
};
