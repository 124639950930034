import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { BDSButton, BDSCheckbox } from '@bigin/bigin-ui-components';

import { baseCss } from './Step2No.style';
import { BGButtonGroup } from '../../../components/BGButtonGroup/BGButtonGroup';
import { BGInput } from '../../../components/BGInput/BGInput';

enum Important {
  IMAGE_CREATIVE = 'image_creative',
  TARGETING = 'targeting',
  VARIOUS_MEDIAS = 'various_medias',
  ANALYSIS = 'analysis',
  ETC = 'etc',
}

export interface Step2NoForm {
  importants: Important[];
  importantEtc: string;
}

export interface Step2NoProps {
  prev: () => void;
  onSubmit: (form: Step2NoForm) => void;
}

export const Step2No = (props: Step2NoProps): ReactElement => {
  const i18next = useTranslation();

  const [importants, setImportants] = useState<Important[]>([]);
  const [importantEtc, setImportantEtc] = useState<string>();
  const { formState, register, setValue, handleSubmit } = useForm<Step2NoForm>({ mode: 'all' });

  useEffect(() => {
    register('importants');
    register('importantEtc');
  });

  const toggleImportants = (important: Important) => {
    const checked = importants.indexOf(important) === -1;
    const news = importants.filter((item) => item !== important);
    if (checked) {
      news.push(important);
    }

    setValue('importants', news, { shouldValidate: true });
    setImportants(news);
  };

  return (
    <div css={[baseCss]}>
      <form onSubmit={handleSubmit(props.onSubmit)}>
        <div className="form-input-wrapper">
          <p className="form-label">{i18next.t('디지털 광고 집행에서 가장 중요하다고 생각하는 것은 무엇인가요?')}</p>
          {[
            Important.IMAGE_CREATIVE,
            Important.TARGETING,
            Important.VARIOUS_MEDIAS,
            Important.ANALYSIS,
            Important.ETC,
          ].map((important) => (
            <div className="checkbox-wrapper">
              <BDSCheckbox
                size={16}
                checked={importants.indexOf(important) > -1}
                onChange={() => {
                  toggleImportants(important);
                }}
              />
              <span
                className="text-input text-color-main ml-1"
                onClick={() => {
                  toggleImportants(important);
                }}
              >
                {important === Important.IMAGE_CREATIVE && i18next.t('매력적인 광고 이미지')}
                {important === Important.TARGETING && i18next.t('정확한 타겟팅')}
                {important === Important.VARIOUS_MEDIAS && i18next.t('페이스북, 구글, 카카오 등 다양한 매체 활용')}
                {important === Important.ANALYSIS && i18next.t('데이터 분석 및 인사이트 반영')}
                {important === Important.ETC && i18next.t('기타')}
              </span>
            </div>
          ))}
          {importants.includes(Important.ETC) && (
            <BGInput
              inputProps={{
                type: 'text',
                placeholder: i18next.t(''),
                value: importantEtc,
                onChange: (event) => {
                  setValue('importantEtc', event.target.value, { shouldValidate: true });
                  setImportantEtc(event.target.value);
                },
              }}
            />
          )}
        </div>
        <BGButtonGroup rightAlign>
          <BDSButton
            key={i18next.t('이전') as string}
            label={i18next.t('이전')}
            onClick={() => {
              props.prev();
            }}
            appearance="secondary"
          />
          <BDSButton
            key={i18next.t('다음') as string}
            label={i18next.t('다음')}
            isDisabled={!formState.isValid}
            type="submit"
          />
        </BGButtonGroup>
      </form>
    </div>
  );
};
