import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { BDSRadioButton, BDSFontIcon } from '@bigin/bigin-ui-components';
import { MaterialCheckboxSelectorStyle } from './MaterialCheckboxSelector.style';

export interface MaterialRadioType {
  displayName: string;
  value: string;
  checked: boolean;
  htmlFor: string;
}

export interface MaterialCheckboxSelectorProps {
  contentTitle: string;
  checkboxLabel: string;
  value: boolean;
  handleUpdate?: (curValue: boolean) => void;
  hiddenCheckbox?: boolean;

  disabledRadio?: boolean;
  hiddenRadio?: boolean;
  handleUpdateRadio?: (curValue: string) => void;
  radioValueList?: MaterialRadioType[];
}

export const MaterialCheckboxSelector = (props: MaterialCheckboxSelectorProps): ReactElement => {
  const updateValue = () => {
    if (props.handleUpdate) props.handleUpdate(!props.value);
  };
  return (
    <div className="material-field-top-wrapper" css={MaterialCheckboxSelectorStyle}>
      <div className="material-field-label">{props.contentTitle}</div>
      {!props.hiddenCheckbox && (
        <div className="material-field-usage-selector" onClick={updateValue}>
          {props.value && <BDSFontIcon name="ic-checkbox-active" color="#0d86ff" size="20px" />}
          {!props.value && <BDSFontIcon name="ic-checkbox" color="#bbc" size="20px" />}
          <span>{props.checkboxLabel}</span>
        </div>
      )}
      {props.handleUpdateRadio && props.radioValueList && props.radioValueList[0] && (
        <div className="material-radio-container">
          {props.handleUpdateRadio &&
            !props.hiddenRadio &&
            props.radioValueList &&
            props.radioValueList[0] &&
            props.radioValueList.map((radio) => {
              return (
                <BDSRadioButton
                  key={radio.htmlFor}
                  inputProps={{
                    id: radio.htmlFor,
                    name: radio.htmlFor,
                    checked: radio.checked,
                    onChange: () => {
                      if (props.handleUpdateRadio) props.handleUpdateRadio(radio.value);
                    },
                  }}
                  labelText={radio.displayName}
                />
              );
            })}
        </div>
      )}
    </div>
  );
};
