import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, useTheme, Theme } from '@emotion/react';

import moment from 'moment';
import _ from 'lodash';
import { List } from 'react-virtualized';
import { ListRowProps } from 'react-virtualized/dist/es/List';
import { useTranslation } from 'react-i18next';
import { Backdrop } from '../Backdrop/Backdrop';
import { baseCss } from './Calendar.style';
import { FontIcon } from '../FontIcon/FontIcon';
import { BorderSection } from '../BorderSection/BorderSection';
import { i18nextScanKey } from '../../i18n';

// viewMonth 월(月)에 해당하는 JSX 를 반환
function monthRender(
  viewMonth?: moment.Moment,
  dateRange?: DateRange,
  disableFuture?: boolean,
  eventHandle?: (event: DateEvent) => void,
  highlightRange?: DateRange | null,
  selectableRange?: DateRange | null,
) {
  const startOfMonth = moment(viewMonth).startOf('month'); // viewMonth 의 첫째 날
  const endOfMonth = moment(viewMonth).endOf('month'); // viewMonth 의 마지막 날
  const startOfLastWeek = moment(endOfMonth).startOf('week'); // viewMonth 의 마지막 주 일요일

  const result: JSX.Element[] = [];
  const cursor = moment(startOfMonth).startOf('week'); // 지난 달의 마지막 일요일이거나 이번 달의 첫번째 일요일
  const today = moment().startOf('day');

  while (startOfLastWeek.isSameOrAfter(cursor)) {
    const week: JSX.Element[] = [];

    // bg-inner-date << 안에 active 넣어야 함
    _.times(7, (i) => {
      // cursor 은 viewMonth의 지난 달의 마지막 일요일 또는
      // viewMonth 의 첫번째 일요일이 될 수 있다.
      const currentDate = moment(cursor).add(i, 'day');

      let isStartBg = '';
      let isEndBg = '';
      let isIgnoreDate = '';
      let isToday = '';
      let isDisabled = '';
      let isActiveDate = '';
      let isActiveBg = '';

      let isHighlightStartBg = '';
      let isHighlightEndBg = '';
      let isHighlightActiveDate = '';
      let isHighlightActiveBg = '';

      if (currentDate.isSame(today, 'day')) isToday = ' today';
      if (disableFuture && currentDate.isAfter(today)) isDisabled = ' disabled';
      if (selectableRange && (currentDate.isAfter(selectableRange.end) || currentDate.isBefore(selectableRange.start)))
        isDisabled = ' disabled';

      if (dateRange) {
        isActiveDate =
          currentDate.isSame(dateRange.start, 'day') || currentDate.isSame(dateRange.end, 'day') ? ' active' : '';
        isActiveBg = currentDate.isBetween(dateRange.start, dateRange.end, 'day') ? ' active' : '';

        if (!moment(dateRange.start).isSame(dateRange.end, 'day')) {
          isStartBg = currentDate.isSame(dateRange.start, 'day') ? ' active' : '';
          isEndBg = currentDate.isSame(dateRange.end, 'day') ? ' active' : '';
        }

        if (currentDate.isBefore(startOfMonth))
          if (
            startOfMonth.isBetween(dateRange.start, dateRange.end, 'day', '(]') &&
            !moment(dateRange.start).isSame(dateRange.end, 'day')
          )
            isActiveBg = ' active';
          else isActiveBg = '';

        if (currentDate.isAfter(endOfMonth))
          if (
            endOfMonth.isBetween(dateRange.start, dateRange.end, 'day', '[)') &&
            !moment(dateRange.start).isSame(dateRange.end, 'day')
          )
            isActiveBg = ' active';
          else isActiveBg = '';

        if (highlightRange) {
          isHighlightActiveDate =
            currentDate.isSame(highlightRange.start, 'day') || currentDate.isSame(highlightRange.end, 'day')
              ? ' highlight'
              : '';
          isHighlightActiveBg = currentDate.isBetween(highlightRange.start, highlightRange.end) ? ' highlight' : '';

          if (
            !moment(highlightRange.start).isSame(highlightRange.end, 'day') &&
            !currentDate.isBetween(dateRange.start, dateRange.end, 'day', '[]')
          ) {
            isHighlightStartBg = currentDate.isSame(highlightRange.start, 'day') ? ' highlight' : '';
            isHighlightEndBg = currentDate.isSame(highlightRange.end, 'day') ? ' highlight' : '';
          }

          if (currentDate.isBefore(startOfMonth))
            if (
              startOfMonth.isBetween(highlightRange.start, highlightRange.end, 'day', '(]') &&
              !moment(highlightRange.start).isSame(highlightRange.end, 'day')
            )
              isHighlightActiveBg = ' highlight';
            else isHighlightActiveBg = '';

          if (currentDate.isAfter(endOfMonth))
            if (
              endOfMonth.isBetween(highlightRange.start, highlightRange.end, 'day', '[)') &&
              !moment(highlightRange.start).isSame(highlightRange.end, 'day')
            )
              isHighlightActiveBg = ' highlight';
            else isHighlightActiveBg = '';
        }
      }

      if (currentDate.isBefore(startOfMonth)) {
        isIgnoreDate = ' hide';
        isStartBg = '';
        isEndBg = '';
        isHighlightStartBg = '';
        isHighlightEndBg = '';
      }

      if (currentDate.isAfter(endOfMonth)) {
        isIgnoreDate = ' hide';
        isStartBg = '';
        isEndBg = '';
        isHighlightStartBg = '';
        isHighlightEndBg = '';
      }

      week.push(
        <div className={`bg-date${isHighlightActiveBg}${isActiveBg}`} key={currentDate.format('l')}>
          <div className={`bg-inner-start ${isStartBg}${isHighlightStartBg}`} />
          <div className={`bg-inner-end ${isEndBg}${isHighlightEndBg}`} />
          <div
            role="button"
            tabIndex={0}
            className={`bg-inner-date ${isHighlightActiveDate}${isActiveDate}${isIgnoreDate}${isToday}${isDisabled}`}
            onClick={() =>
              (!disableFuture || !currentDate.isAfter(today)) &&
              !currentDate.isBefore(startOfMonth) &&
              !currentDate.isAfter(endOfMonth) &&
              (selectableRange
                ? currentDate.isBetween(selectableRange.start, selectableRange.end, 'day', '[]')
                : true) &&
              eventHandle &&
              eventHandle({
                eventType: 'click',
                date: currentDate,
              })
            }
            onMouseEnter={() =>
              (!disableFuture || !currentDate.isAfter(today)) &&
              !currentDate.isBefore(startOfMonth) &&
              !currentDate.isAfter(endOfMonth) &&
              (selectableRange
                ? currentDate.isBetween(selectableRange.start, selectableRange.end, 'day', '()')
                : true) &&
              eventHandle &&
              eventHandle({
                eventType: 'hover',
                date: currentDate,
              })
            }
          >
            <span>{currentDate.date()}</span>
          </div>
        </div>,
      );
    });

    result.push(
      <div className="bg-week" key={cursor.format('l')}>
        {week}
      </div>,
    );
    cursor.add(1, 'week');
  }

  return (
    <div className="bg-calendar-display-month-detail">
      <div className="bg-calendar-display-month">{viewMonth ? viewMonth.format('yyyy.MM') : ''}</div>
      <div className="bg-calendar-display-date">{result}</div>
    </div>
  );
}

export interface DateRange {
  start: moment.Moment;
  end: moment.Moment;
  compareStart?: moment.Moment;
  compareEnd?: moment.Moment;
}

export interface CalendarProps {
  initDateRange?: DateRange | null; // DateRange의 초기값
  viewRange?: DateRange | null; // 화면에 노출되는 Month의 범위, viewRange 를 props 로 주입하지 않는다면 12Month가 초기값이 된다.
  highlightRange?: DateRange | null;
  selectableRange?: DateRange | null; // 선택할 수 있는 date 범위를 제한합니다.
  dateHandle?: (dateRange: DateRange) => void;
  unsetHandle?: () => void; // 선택된 날짜를 삭제 핸들러
  disableFuture?: boolean;
  preLabel?: string;
  hiddenControlPanel?: boolean; // 캘린더 좌측의 ControlPanel 의 가시여부를 결정합니다.
  initSelectedDateRange?: DateRange | null; // selectedDateRange 의 초기값
  position?: 'top' | 'bottom'; // calendar의 노출 위치를 위 또는 아래로 설정합니다.
  selectedDate?: DateRange | null;
  isSingleDateRange?: boolean; // 범위 선택이 아닌 단일 선택 여부
  style?: object;
  hiddenHeader?: boolean; // 캘린더 헤더 노출 여부를 결정합니다.
  monthWidth?: number;
}

interface DateEvent {
  eventType: 'click' | 'hover';
  date: moment.Moment;
}

const usePrevious = <T,>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

/**
 * @constructor
 */

export const Calendar = (props: CalendarProps): ReactElement => {
  const i18next = useTranslation();
  const theme: Theme = useTheme();
  const themeCss = css`
    background-color: ${theme.colors.bg2};
    &.active {
      color: #d2dfff;
      background-color: ${theme.colors.bg2};
    }

    .bg-calendar-current-date {
      color: ${theme.colors.bg1};
    }

    .bg-inner-date {
      &.active {
        color: white;
        background-color: ${theme.colors.primary};
      }
    }

    .bg-calendar-select-apply {
      background-color: ${theme.colors.primary};
    }
  `;

  const listRef = useRef<List | null>(null); // virtual-list 컴포넌트를 제어하기 위한 Ref
  const [isOver, setIsOver] = useState(false); // Calendar 버튼의 hover effect를 위한 State
  const propsObj = props;

  // 화면에 노출되는 Month의 범위, 기본값은 12 Month
  const viewRange: DateRange = propsObj.viewRange
    ? propsObj.viewRange
    : {
        start: moment().subtract(14, 'months').startOf('month'),
        end: propsObj.disableFuture ? moment().startOf('day') : moment().add(12, 'months').startOf('day'),
      };

  // 화면에 노출되는 Month의 Count, 기본값은 12
  const monthCount = Math.abs(viewRange?.start.diff(viewRange.end, 'month'));

  // 적용되는 실제 DateRange
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange | null | undefined>(
    propsObj.initSelectedDateRange || propsObj.initDateRange,
  );

  // 날짜를 click, hover 하여 선택되는 임시 DateRange
  const [dateRange, setDateRange] = useState<DateRange>(
    propsObj.initDateRange
      ? propsObj.initDateRange
      : {
          start: moment().startOf('day'),
          end: moment().startOf('day'),
        },
  );

  useEffect(() => {
    setSelectedDateRange(props.initSelectedDateRange || props.initDateRange);
    if (props.initDateRange) setDateRange(props.initDateRange);
  }, [props.initSelectedDateRange, props.initDateRange]);

  // Left-Control Panel의 기본 선택값
  const [selectLabel, setSelectLabel] = useState('직접 설정 기간');

  const init: JSX.Element[] = [];
  const [items, setItems] = useState(init);

  // Calendar 는 Button 상태와 Calendar 상태로 나뉜다.
  const [showCalendar, setShowCalendar] = useState(false);

  // DateRange는 범위 값이므로 start, end 에 해당하는 최대 2개의 Moment를 저장하는 State
  const [dateSelection, setDateSelection] = useState<moment.Moment[]>([]);

  const dateEventHandle = useCallback(
    async (event: DateEvent) => {
      switch (event.eventType) {
        case 'click':
          setSelectLabel('직접 설정 기간');

          if (props.isSingleDateRange) {
            setDateSelection([]);
            setDateRange({
              start: moment(event.date),
              end: moment(event.date),
            });
            break;
          }

          if (dateSelection.length === 0) {
            // DateRange.start 선택
            dateSelection.push(event.date);
            setDateSelection(dateSelection);
            setDateRange({
              start: moment(event.date),
              end: moment(event.date),
            });
          } else {
            // DateRange.end 선택
            setDateSelection([]);
            setDateRange({
              start: dateSelection[0].isBefore(event.date) ? moment(dateSelection[0]) : moment(event.date),
              end: dateSelection[0].isAfter(event.date) ? moment(dateSelection[0]) : moment(event.date),
            });
          }
          break;
        case 'hover':
          if (dateSelection.length > 0) {
            setDateRange({
              start: dateSelection[0].isBefore(event.date) ? moment(dateSelection[0]) : moment(event.date),
              end: dateSelection[0].isAfter(event.date) ? moment(dateSelection[0]) : moment(event.date),
            });
          }
          break;
        default:
      }
    },
    [dateSelection],
  );

  const updateViewIndex = useCallback(async () => {
    if (!listRef || !listRef.current) return;
    const viewMonthCount = moment(viewRange.start).year() * 12 + moment(viewRange.start).month();
    const startMonthCount = moment(dateRange.start).year() * 12 + moment(dateRange.start).month();
    listRef.current.scrollToRow(startMonthCount - viewMonthCount - 1);
  }, [dateRange.start, viewRange.start]);

  const getMonths = useCallback(async () => {
    const months: JSX.Element[] = [];
    _.times(monthCount, (i) => {
      months.push(
        monthRender(
          moment(viewRange?.start).add(i + 1, 'month'),
          dateRange,
          props.disableFuture,
          dateEventHandle,
          props.highlightRange,
          props.selectableRange,
        ),
      );
    });
    setItems(months);
  }, [dateRange, monthCount, viewRange, props.disableFuture, dateEventHandle]);

  const prevShowCalendar = usePrevious(showCalendar);

  useEffect(() => {
    if (listRef && listRef.current) {
      getMonths().then(() => {
        if (listRef && listRef.current) listRef.current.forceUpdateGrid();
        if (selectLabel !== '직접 설정 기간' || showCalendar !== prevShowCalendar) updateViewIndex().then();
      });
    }
    // TODO: need to fix infinite render problem
  }, [
    dateRange,
    // getMonths,
    listRef,
    showCalendar,
    // updateViewIndex,
    selectLabel,
  ]);

  function selectRange(range: string) {
    let start = moment().startOf('day');
    let end = moment().startOf('day');
    switch (range) {
      case '직접 설정':
        setSelectLabel('직접 설정 기간');
        return;
      case '오늘':
        start = moment().startOf('day');
        end = moment().startOf('day');
        setSelectLabel('오늘');
        break;
      case '어제':
        start = moment().subtract(1, 'day').startOf('day');
        end = moment().subtract(1, 'day').startOf('day');
        setSelectLabel('어제');
        break;
      case '이번 주':
        start = moment().startOf('week');
        end = moment().startOf('day');
        setSelectLabel('이번 주');
        break;
      case '지난 주':
        start = moment().subtract(1, 'week').startOf('week');
        end = moment().subtract(1, 'week').endOf('week').startOf('day');
        setSelectLabel('지난 주');
        break;
      case '지난 7일':
        start = moment().subtract(7, 'day').startOf('day');
        end = moment().subtract(1, 'day').startOf('day');
        setSelectLabel('지난 7일');
        break;
      case '지난 14일':
        start = moment().subtract(14, 'day').startOf('day');
        end = moment().subtract(1, 'day').startOf('day');
        setSelectLabel('지난 14일');
        break;
      case '지난 30일':
        start = moment().subtract(30, 'day').startOf('day');
        end = moment().subtract(1, 'day').startOf('day');
        setSelectLabel('지난 30일');
        break;
      case '지난 60일':
        start = moment().subtract(60, 'day').startOf('day');
        end = moment().subtract(1, 'day').startOf('day');
        setSelectLabel('지난 60일');
        break;
      case '지난 90일':
        start = moment().subtract(90, 'day').startOf('day');
        end = moment().subtract(1, 'day').startOf('day');
        setSelectLabel('지난 90일');
        break;
      default:
    }
    if (range !== '직접 설정') setDateSelection([]); // 직접 설정이 아닌 다른 기간 프리셋을 선택했을 때 데이트 셀렉션 초기화

    if (
      props.selectableRange &&
      props.selectableRange.start &&
      moment.isMoment(props.selectableRange.start) &&
      start &&
      moment.isMoment(start) &&
      props.selectableRange.start.isAfter(start)
    ) {
      setDateRange({
        start: props.selectableRange.start,
        end,
      });
    } else setDateRange({ start, end });
  }

  function rowRenderer({ key, index, style }: ListRowProps) {
    return (
      <div key={key} style={style} className="">
        {items[index]}
      </div>
    );
  }

  function close() {
    setIsOver(false);
    setShowCalendar(false);
    setDateRange(
      selectedDateRange || {
        start: moment().startOf('day'),
        end: moment().startOf('day'),
      },
    );
    setSelectLabel('직접 설정 기간');
  }

  function submit() {
    setIsOver(false);
    setShowCalendar(false);
    setSelectedDateRange(dateRange);
    setSelectLabel('직접 설정 기간');
    if (props.dateHandle) props.dateHandle(dateRange);
  }

  const CalendarControlBtn = ({ index, btnName }: { index: number; btnName: string }): ReactElement => {
    const isSelectedLabel = selectLabel === btnName || (selectLabel === '직접 설정 기간' && btnName === '직접 설정');
    return (
      <div
        role="button"
        tabIndex={index}
        className={`bg-calendar-control-btn${isSelectedLabel ? ' select' : ''}`}
        onClick={() => selectRange(btnName)}
      >
        <span>{i18next.t(btnName)}</span>
      </div>
    );
  };

  const CalendarControlPanel = () => {
    const btnList = [
      i18nextScanKey('직접 설정'),
      i18nextScanKey('오늘'),
      i18nextScanKey('어제'),
      i18nextScanKey('이번 주'),
      i18nextScanKey('지난 주'),
      i18nextScanKey('지난 7일'),
      i18nextScanKey('지난 14일'),
      i18nextScanKey('지난 30일'),
      i18nextScanKey('지난 60일'),
      i18nextScanKey('지난 90일'),
    ];

    return (
      <div className="bg-calendar-control-panel">
        {btnList.map((bname: string, index: number) => (
          <CalendarControlBtn key={bname} index={index} btnName={bname} />
        ))}
      </div>
    );
  };

  return (
    <React.Fragment>
      {showCalendar && <Backdrop handleClick={() => close()} style={{ zIndex: 1, backgroundColor: 'rgba(0,0,0,0)' }} />}

      <div
        style={props.style || {}}
        css={[baseCss, themeCss]}
        onMouseEnter={() => setIsOver(true)}
        onMouseLeave={() => setIsOver(false)}
        className={`bg-calendar-panel ${showCalendar || isOver ? ' active' : ''} ${selectedDateRange ? '' : 'empty'}`}
      >
        <div role="button" tabIndex={0} className="bg-calendar-display" onClick={() => setShowCalendar(true)}>
          {!selectedDateRange && <FontIcon name="ic-add" color="#7e8696" size="20px" />}
          {selectedDateRange ? (
            <div className="bg-calendar-display-inner">
              <div className="bg-calendar-display-label">{i18next.t(propsObj.preLabel || '')}</div>
              <div className="bg-calendar-current-date">
                {props.isSingleDateRange && selectedDateRange?.start.format('yyyy.MM.DD')}
                {!props.isSingleDateRange &&
                  `${selectedDateRange?.start.format('yyyy.MM.DD')} ~ ${selectedDateRange?.end.format('yyyy.MM.DD')}`}
              </div>
            </div>
          ) : (
            <div className="bg-calendar-display-label" style={{ margin: '0 0 0 4px' }}>
              {i18next.t('비교 기간 추가')}
            </div>
          )}
          {selectedDateRange &&
            props.preLabel !== '비교' &&
            (showCalendar ? (
              <FontIcon name="ic-arrow-left" color="#7e8696" size="20px" />
            ) : (
              <FontIcon name="ic-arrow-right" color="#7e8696" size="20px" />
            ))}
          {selectedDateRange && props.preLabel === '비교' && (
            <FontIcon
              name="ic-delete"
              color="#7e8696"
              size="20px"
              handleClick={(e) => {
                e.stopPropagation();
                if (props.unsetHandle) props.unsetHandle();
              }}
            />
          )}
        </div>

        {showCalendar && (
          <div className={` ${props.position === 'top' ? 'position-top' : 'position-bottom'}`}>
            <BorderSection style={{ overflow: 'hidden' }}>
              <div className="bg-calendar-selector-panel">
                {!props.hiddenControlPanel && <CalendarControlPanel />}

                <div className="bg-calendar-display-panel">
                  {!props.hiddenHeader && (
                    <div className="bg-calendar-display-header">
                      <div className="bg-calendar-display-header-label">{i18next.t(selectLabel)}</div>
                      <div className="bg-calendar-display-header-value">
                        {props.isSingleDateRange && dateRange?.start.format('yyyy.MM.DD')}
                        {!props.isSingleDateRange &&
                          `${dateRange?.start.format('yyyy.MM.DD')} - ${dateRange?.end.format('yyyy.MM.DD')}`}
                      </div>
                    </div>
                  )}
                  <div className="bg-calendar-display-content">
                    <div className="bg-calendar-display-content-day-panel">
                      <div className="bg-calendar-display-content-day">
                        <span>{i18next.t('일(요일)')}</span>
                      </div>
                      <div className="bg-calendar-display-content-day">
                        <span>{i18next.t('월(요일)')}</span>
                      </div>
                      <div className="bg-calendar-display-content-day">
                        <span>{i18next.t('화(요일)')}</span>
                      </div>
                      <div className="bg-calendar-display-content-day">
                        <span>{i18next.t('수(요일)')}</span>
                      </div>
                      <div className="bg-calendar-display-content-day">
                        <span>{i18next.t('목(요일)')}</span>
                      </div>
                      <div className="bg-calendar-display-content-day">
                        <span>{i18next.t('금(요일)')}</span>
                      </div>
                      <div className="bg-calendar-display-content-day">
                        <span>{i18next.t('토(요일)')}</span>
                      </div>
                    </div>
                    <div className="bg-calendar-month-panel">
                      <List
                        ref={listRef}
                        width={props.hiddenControlPanel ? props.monthWidth || 450 : 300}
                        height={320}
                        rowCount={items.length}
                        rowHeight={240}
                        rowRenderer={rowRenderer}
                        style={{ marginRight: '-50px' }}
                      />
                    </div>
                    <div className="bg-calendar-select-panel">
                      <div role="button" tabIndex={0} className="bg-calendar-select-cancel" onClick={() => close()}>
                        <span>{i18next.t('취소')}</span>
                      </div>
                      <div role="button" tabIndex={0} className="bg-calendar-select-apply" onClick={() => submit()}>
                        <span>{i18next.t('적용')}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BorderSection>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

// label scan
i18nextScanKey('직접 설정 기간');
// preLabel key scan
i18nextScanKey('기간');
i18nextScanKey('비교');

Calendar.defaultProps = {
  preLabel: '기간',
};
