type PolicyItem = boolean | string;
type Policy = {
  management: {
    budgetLimit: PolicyItem;
    commission: PolicyItem;
    consult: PolicyItem;
    oneOnOneChat: PolicyItem;
    alimtalkPro: PolicyItem;
    solutionEducation: PolicyItem;
  };
  analytics: {
    biginCrm: PolicyItem;
    biginSdk: PolicyItem;
  };
  report: {
    campaign: PolicyItem;
    weeklyPerformance: PolicyItem;
  };
  creative: {
    createCatalog: PolicyItem;
    updateProductImage: PolicyItem;
    editTool: PolicyItem;
    support: PolicyItem;
  };
  media: {
    facebookImage: PolicyItem;
    facebookVideo: PolicyItem;
    facebookCollection: PolicyItem;
    facebookDynamic: PolicyItem;
    googleResponsive: PolicyItem;
    googleDynamic: PolicyItem;
    googleShopping: PolicyItem;
    youtube: PolicyItem;
    kakaoBizboard: PolicyItem;
    kakaoNative: PolicyItem;
  };
};

export default abstract class Plan {
  public static freeTypes: PlanType[] = ['smartstore', 'brandmall'];

  public static isFreeType = (type: PlanType) => this.freeTypes.indexOf(type) > -1;

  public static managedTypes: PlanType[] = ['managed'];

  public static isManagedType = (type: PlanType) => this.managedTypes.indexOf(type) > -1;

  public static cloudTypes: PlanType[] = [
    'cloud_tier_1',
    'cloud_tier_2',
    'cloud_tier_3',
    'cloud_tier_4',
    'cloud_tier_5',
  ];

  public static isCloudType = (type: PlanType) => this.cloudTypes.indexOf(type) > -1;

  public type!: PlanType;

  public image!: string;

  public name!: string;

  public description!: string;

  public features!: string[];

  public subscriptionFee!: number | null; // null: 협의필요

  public feeRate!: number | null; // null: 협의필요

  public policy!: Policy;
}
