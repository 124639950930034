import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();
  return css`
    width: 100%;
    .horizontal-line {
      width: 100%;
      height: 1px;
      background-color: #ebeef4;
    }

    .create-section-content-wrapper {
      display: flex;
      width: 100%;
      min-height: 1000px;

      .preview-container {
        width: calc(100% - 200px);
        padding: 20px 20px 60px;
        position: relative;
        display: flex;
        flex-direction: column;

        .sticky-wrap {
          position: sticky;
          top: 106px;
          width: 100%;
        }
        .title-panel {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            ${theme.text.h4};
            color: ${theme.colors.mainFont};
          }
          .info {
            ${theme.text.disclaimer};
          }
        }

        .google-preview {
          border-radius: 8px;
          background-color: #f9fafb;
          border: solid 1px #dce0e8;
          min-height: 1000px;
          position: relative;
        }
        .material-preview-wrapper {
          width: 100%;
          height: 100%;
          margin-top: 16px;
        }
      }

      .form-container {
        border-left: 1px solid ${theme.colors.divider};
        width: 400px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 20px;

        .material-field-header {
          ${theme.text.h4}
          color: ${theme.colors.mainFont};
          margin: 0;
          padding: 16px 20px 20px;
        }

        .material-field-selector-wrapper {
          width: 100%;
          height: auto;
          padding: 0 20px 20px;

          .description {
            margin-top: 8px;
            ${theme.text.disclaimer}
          }

          .material-field-input-wrapper {
            position: relative;

            textarea {
              height: 49px;
            }
          }

          .material-dropdown.gray {
            border-radius: 8px;

            .material-dropdown-item.gray {
              border-radius: 8px;
            }
          }
        }

        .content-upload-container {
          width: 100%;
          height: auto;
          border-radius: 8px;
          border: solid 1px #dce0e8;
          background-color: #f9fafb;
          overflow: hidden;
          margin-top: 8px;
        }

        .uploader-wrapper {
          padding: 16px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;

          .image-uploader-title-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 12px;
          }

          .image-uploader-subtitle {
            // view
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            & > div {
              //text
              ${theme.text.disclaimer}
            }
          }
        }

        .single-image-uploader-wrapper {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .image-uploader-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          padding: 16px;
          border-radius: 8px;
          background-color: ${theme.colors.bg3};
          position: relative;

          .image-uploader-item-wrapper {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
          }
        }
        .file-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 24px;

          .file-name {
            font-size: 14px;
            line-height: 1.43;
            color: #3d4046;
            font-weight: bold;
          }
        }
      }
    }
  `;
};

export const smallButtonCss = css`
  // view
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dce0e8;
`;
