import axios, { AxiosPromise } from 'axios';

export const KakaoApi = () => ({
  getPixels(mallId: number): AxiosPromise<{ data: { id: string; name: string }[] }> {
    return axios.get(`/malls/${mallId}/kakao-moment/trackers`);
  },
  getKKAdAccounts(mallId: number): AxiosPromise {
    return axios.get(`/malls/${mallId}/kakao-moment/ad-accounts`);
  },
});
