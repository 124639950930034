import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { StyledBorderSection } from './BorderSection.style';

export interface BorderSectionProps {
  children?: React.ReactNode;
  addHoverCss?: boolean;
  style?: Object;
  className?: string;
}
export const BorderSection = ({
  children,
  addHoverCss = false,
  style = {},
  className = '',
}: BorderSectionProps): React.ReactElement => {
  return (
    <StyledBorderSection
      className={`border-section-style-component ${className}`}
      addHoverCss={addHoverCss}
      style={style}
    >
      {children}
    </StyledBorderSection>
  );
};
