import React, { ReactNode } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useQuery } from 'react-query';
import { MallApi } from '../../lib/api/MallApi';
import { Wrapper } from '../Wrapper/Wrapper';

export interface WrapperMallProps {
  children: ReactNode | undefined;
}

export const WrapperMall = (props: WrapperMallProps) => {
  const { data: malls, isLoading } = useQuery<Mall[]>('malls', async () => (await MallApi().getMalls()).data.data);

  if (isLoading) {
    return <div />;
  }

  return !isLoading && malls && malls.length > 1 ? (
    <Wrapper noPadding>{props.children}</Wrapper>
  ) : (
    <div>{props.children}</div>
  );
};
