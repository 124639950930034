import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
// import { BGRadioButton, BGSelectBox } from 'bigin-ui-components';
import { BDSRadioButton, BDSSelectBox, BDSButton } from '@bigin/bigin-ui-components';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useQuery } from 'react-query';

import { baseCss, selectYoutubeVideoCss } from './AwAssetModal.style';
import { BGButtonGroup } from '../BGButtonGroup/BGButtonGroup';
import useScript from '../../hooks/useScript';
import { MediaApi } from '../../lib/api/MediaApi';
import { useDataStore } from '../../context/Store';
import StorageService from '../../services/StorageService';
import { GoogleApi } from '../../lib/api/GoogleApi';
import { MallApi } from '../../lib/api/MallApi';

type YtChannel = {
  id: string;
  name: string;
};

type YtVideo = {
  id: string;
  name: string;
  thumbnail: string;
  url: string;
};

type AwAdAccount = {
  customer_id: string;
  name: string;
};

type GaAccount = {
  id: string;
  name: string;
};

export interface SelectYoutubeVideoProps {
  videos: YtVideo[];
  selectedVideo?: YtVideo;
  onChange: (item: YtVideo) => void;
}

const SelectYoutubeVideo = (props: SelectYoutubeVideoProps): ReactElement => {
  const i18next = useTranslation();
  const [selectedYtVideo, setSelectedYtVideo] = useState<YtVideo | undefined>(
    props.videos.find((item) => item.id === props.selectedVideo?.id),
  );

  return (
    <div css={[selectYoutubeVideoCss]}>
      <div className="video-container">
        {props.videos.length > 0 ? (
          props.videos.map((item) => (
            <div
              key={`video-item-${item.id}`}
              className="video-item"
              onClick={() => {
                setSelectedYtVideo(item);
                props.onChange(item);
              }}
            >
              <BDSRadioButton
                inputProps={{
                  id: item.id,
                  name: item.id,
                  checked: selectedYtVideo?.id === item.id,
                  onChange: () => {
                    setSelectedYtVideo(item);
                    props.onChange(item);
                  },
                }}
                labelText="&nbsp;" // labelText가 없으면 align-items: center; 속성이 먹히지 않음
              />
              <img className="video-item-image" src={item.thumbnail} alt="video-thumbnail" />
              <span className={`video-item-title ${selectedYtVideo?.id === item.id ? 'selected' : ''}`}>
                {item.name}
              </span>
            </div>
          ))
        ) : (
          <div className="empty-video-list-wrapper">
            <p>{i18next.t('유튜브 영상이 존재하지 않습니다.')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export interface AwAssetModalForm {
  selectedYtChannel: YtChannel;
  selectedYtVideo: YtVideo;
  selectedAwAdAccount: AwAdAccount;
  selectedGaAccount: GaAccount;
}

export interface AwAssetModalProps {
  types: (MediaAssetType | 'youtube-videos')[];
  assets: MallMedia[];
  video?: YtVideo;
  close: () => void;
  onSubmitted?: (form: AwAssetModalForm) => void;
}

export const AwAssetModal = (props: AwAssetModalProps): ReactElement => {
  const requiredScopes = [
    'https://www.googleapis.com/auth/youtube.readonly',
    'https://www.googleapis.com/auth/analytics.edit',
    'https://www.googleapis.com/auth/analytics.manage.users',
    'https://www.googleapis.com/auth/adwords',
  ];
  useScript('https://apis.google.com/js/client:platform.js', () => {
    window.gapi.load('auth2', () => {
      window.gapi.auth2.init({
        client_id: String(process.env.REACT_APP_GOOGLE_CLIENT_ID),
        cookie_policy: 'single_host_origin',
        scope: requiredScopes.join(' '),
      });
    });
  });

  const i18next = useTranslation();
  const { dialogStore } = useDataStore();

  const hasYoutubeChannelType = !!props.types.find((item) => item === 'youtube');
  const initialYtChannel = props.assets.find((item) => item.type === 'youtube');
  const [selectedYtChannel, setSelectedYtChannel] = useState<YtChannel | undefined>(
    initialYtChannel ? { id: initialYtChannel.media_id, name: initialYtChannel.name } : undefined,
  );
  const {
    data: ytChannels,
    isLoading: isLoadingYtChannels,
    refetch: refetchYtChannels,
    remove: removeYtChannels,
  } = useQuery<YtChannel[]>(
    'ytChannels',
    async () => {
      const ytChannelList: YtChannel[] = (await GoogleApi().getChannels(StorageService.selectedMall.id)).data.data;
      // 서버에서 mall 객체 내의 medias에 있는 Youtube 채널은 안보내주고 있어서 프론트에서 처리.
      return (initialYtChannel ? [{ id: initialYtChannel.media_id, name: initialYtChannel.name }] : []).concat(
        ytChannelList.filter((item) => item.id !== initialYtChannel?.media_id),
      );
    },
    { enabled: false },
  );

  const hasYoutubeVideoType = !!props.types.find((item) => item === 'youtube-videos');
  const [selectedYtVideo, setSelectedYtVideo] = useState<YtVideo | undefined>(props.video);
  const {
    data: ytVideos,
    isLoading: isLoadingYtVideos,
    refetch: refetchYtVideos,
    remove: removeYtVideos,
  } = useQuery<YtVideo[]>(
    'ytVideos',
    async () =>
      (await GoogleApi().getYoutubeVideos(StorageService.selectedMall.id, { channel_id: selectedYtChannel?.id })).data
        .data,
    { enabled: false },
  );

  const hasAwAdAccountType = !!props.types.find((item) => item === 'aw-client-account');
  const initialAwAdAccount = props.assets.find((item) => item.type === 'aw-client-account');
  const [selectedAwAdAccount, setSelectedAwAdAccount] = useState<AwAdAccount | undefined>(
    initialAwAdAccount ? { customer_id: initialAwAdAccount.media_id, name: initialAwAdAccount.name } : undefined,
  );
  const {
    data: awAdAccounts,
    isLoading: isLoadingAwAdAccounts,
    refetch: refetchAwAdAccounts,
    remove: removeAwAdAccounts,
  } = useQuery<AwAdAccount[]>('awAdAccounts', async () => (await GoogleApi().getClientAccounts()).data.data, {
    enabled: false,
  });

  const hasGaAccountType = !!props.types.find((item) => item === 'ga-account');
  const initialGaAccount = props.assets.find((item) => item.type === 'ga-account');
  const [selectedGaAccount, setSelectedGaAccount] = useState<GaAccount | undefined>(
    initialGaAccount ? { id: initialGaAccount.media_id, name: initialGaAccount.name } : undefined,
  );
  const {
    data: gaAccounts,
    isLoading: isLoadingGaAccounts,
    refetch: refetchGaAccounts,
    remove: removeGaAccounts,
  } = useQuery<GaAccount[]>('gaAccounts', async () => (await GoogleApi().getGAAccountIds()).data.data, {
    enabled: false,
  });

  const {
    data: isLoggedIn,
    refetch: refetchIsLoggedIn,
    remove: removeIsLoggedIn,
  } = useQuery('isLoggedIn', async () => (await MediaApi().getMediaAuthByType('google')).data.data.is_logged_in, {
    onSuccess: () => {
      if (hasYoutubeChannelType) {
        refetchYtChannels();
      }
      if (hasYoutubeVideoType && selectedYtChannel) {
        refetchYtVideos();
      }
      if (hasAwAdAccountType) {
        refetchAwAdAccounts();
      }
      if (hasGaAccountType) {
        refetchGaAccounts();
      }
    },
  });

  useEffect(() => {
    removeYtVideos();

    if (hasYoutubeVideoType && selectedYtChannel) {
      refetchYtVideos();
    }
  }, [selectedYtChannel]);

  const { register, handleSubmit, setValue, formState } = useForm<AwAssetModalForm>({
    mode: 'all',
  });

  useEffect(() => {
    register('selectedYtChannel', { value: selectedYtChannel });
    register('selectedYtVideo', { value: selectedYtVideo });
    register('selectedAwAdAccount', { value: selectedAwAdAccount });
    register('selectedGaAccount', { value: selectedGaAccount });
  }, []);

  const login = () => {
    if (isLoggedIn) {
      return;
    }

    const client = window.gapi?.auth2?.getAuthInstance();
    if (!client) {
      dialogStore.showMessage(i18next.t('알림'), i18next.t('초기화 중입니다.\n잠시 후 다시 시도해주세요.'));
      return;
    }

    client
      .grantOfflineAccess()
      .then(({ code }) => {
        MediaApi()
          .putMediaAuth('google', code)
          .then(() => {
            return refetchIsLoggedIn();
          })
          .catch((error) => {
            client.signOut();

            if (axios.isAxiosError(error)) {
              const response = error?.response;
              if (response && response.status === 422) {
                dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

                return;
              }
            }

            dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
            throw error;
          });
      })
      .catch((error: GrantOfflineAccessError) => {
        switch (error.error) {
          case 'popup_closed_by_user':
            dialogStore.showMessage(
              i18next.t('알림'),
              i18next.t('로그인이 중간에 취소 되었어요.\n다시 로그인해주세요!'),
            );
            break;
          case 'access_denied':
            dialogStore.showMessage(
              i18next.t('알림'),
              i18next.t('일부 권한이 누락되었어요.\n모든 권한을 승인해주세요!'),
            );
            break;
          default:
            dialogStore.showMessage(
              i18next.t('알림'),
              i18next.t('로그인 중간에 문제가 발생하였어요.\n잠시 후 다시 시도해주세요.'),
            );
            break;
        }
      });
  };

  const logout = () => {
    return MediaApi()
      .deleteMediaAuth('google')
      .then(() => {
        removeYtChannels();
        setSelectedYtChannel(undefined);

        removeYtVideos();
        setSelectedYtVideo(undefined);

        removeAwAdAccounts();
        setSelectedAwAdAccount(undefined);

        removeGaAccounts();
        setSelectedGaAccount(undefined);

        removeIsLoggedIn();
        refetchIsLoggedIn();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  const onSubmit = async (form: AwAssetModalForm) => {
    if (form.selectedYtChannel && form.selectedYtChannel?.id !== initialYtChannel?.media_id) {
      await MallApi().updateMallMedia(
        StorageService.selectedMall.id,
        'youtube',
        form.selectedYtChannel.id,
        form.selectedYtChannel.name,
      );
    }

    if (form.selectedAwAdAccount && form.selectedAwAdAccount?.customer_id !== initialAwAdAccount?.media_id) {
      await MallApi().updateMallMedia(
        StorageService.selectedMall.id,
        'aw-client-account',
        form.selectedAwAdAccount.customer_id,
        form.selectedAwAdAccount.name,
      );
    }

    if (form.selectedGaAccount && form.selectedGaAccount?.id !== initialGaAccount?.media_id) {
      await MallApi().updateMallMedia(
        StorageService.selectedMall.id,
        'ga-account',
        form.selectedGaAccount.id,
        form.selectedGaAccount.name,
      );
    }

    if (props.onSubmitted) {
      props.onSubmitted(form);
    }
    props.close();
  };

  return (
    <form css={[baseCss]} onSubmit={handleSubmit(onSubmit)}>
      <div className="aw-asset-modal-header">
        <div className="text-h1 text-color-main">{i18next.t('채널 연동 필요')}</div>
        <p className="text-input text-color-main">
          {i18next.t('Youtube 광고를 제작하기 위해 채널을 연동하고 영상을 선택해주세요.')}
        </p>
      </div>
      <div className="aw-asset-modal-body">
        {hasYoutubeChannelType && (
          <div className="form-input-group">
            <p className="form-input-label">Youtube</p>
            {isLoadingYtChannels ? (
              <Skeleton style={{ height: '40px' }} />
            ) : (
              <div className="select-wrapper" onClick={login}>
                <BDSSelectBox
                  disabled={!isLoggedIn}
                  appearance="gray"
                  defaultValue={selectedYtChannel}
                  displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('채널 연동하기'))}
                  handleUpdate={(item: any) => {
                    setValue('selectedYtChannel', item, { shouldValidate: true });
                    setSelectedYtChannel(item);
                  }}
                  isSelected={(item: any) => item.id === selectedYtChannel?.id}
                  list={ytChannels || []}
                />
              </div>
            )}
          </div>
        )}
        {hasYoutubeVideoType && (
          <div className="form-input-group">
            <p className="form-input-label">{i18next.t('Youtube 영상')}</p>
            {selectedYtChannel &&
              (isLoadingYtVideos ? (
                <Skeleton style={{ height: '60px' }} />
              ) : (
                <SelectYoutubeVideo
                  videos={ytVideos || []}
                  selectedVideo={selectedYtVideo}
                  onChange={(item: YtVideo) => {
                    setValue('selectedYtVideo', item, { shouldValidate: true });
                    setSelectedYtVideo(item);
                  }}
                />
              ))}
            {!selectedYtChannel && (
              <div className="no-channel-container">
                <p>{i18next.t('먼저 채널을 연동해주세요.')}</p>
              </div>
            )}
          </div>
        )}
        {hasAwAdAccountType && (
          <div className="form-input-group">
            <p className="form-input-label">AdAccount</p>
            {isLoadingAwAdAccounts ? (
              <Skeleton style={{ height: '40px' }} />
            ) : (
              <div className="select-wrapper" onClick={login}>
                <BDSSelectBox
                  disabled={!isLoggedIn}
                  appearance="gray"
                  defaultValue={selectedAwAdAccount}
                  displayValue={(item: any) =>
                    item ? `${item.name}(${item.customer_id})` : i18next.t('광고 계정 연동하기')
                  }
                  handleUpdate={(item: any) => {
                    setValue('selectedAwAdAccount', item, { shouldValidate: true });
                    setSelectedAwAdAccount(item);
                  }}
                  isSelected={(item: any) => item.customer_id === selectedAwAdAccount?.customer_id}
                  list={awAdAccounts || []}
                />
              </div>
            )}
          </div>
        )}
        {hasGaAccountType && (
          <div className="form-input-group">
            <p className="form-input-label">GaAccount</p>
            {isLoadingGaAccounts ? (
              <Skeleton style={{ height: '40px' }} />
            ) : (
              <div className="select-wrapper" onClick={login}>
                <BDSSelectBox
                  disabled={!isLoggedIn}
                  appearance="gray"
                  defaultValue={selectedGaAccount}
                  displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('GA 연동하기'))}
                  handleUpdate={(item: any) => {
                    setValue('selectedGaAccount', item, { shouldValidate: true });
                    setSelectedGaAccount(item);
                  }}
                  isSelected={(item: any) => item.id === selectedGaAccount?.id}
                  list={gaAccounts || []}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="aw-asset-modal-footer">
        {isLoggedIn ? (
          <div className="logout-container">
            <p>{i18next.t('다른 계정으로 접속을 원하신다면?')}</p>
            <span className="logout" onClick={logout}>
              {i18next.t('계정 로그아웃')}
            </span>
          </div>
        ) : (
          <div />
        )}
        <BGButtonGroup rightAlign>
          <BDSButton
            appearance="secondary"
            label={i18next.t('취소')}
            onClick={() => {
              props.close();
            }}
          />
          <BDSButton label={i18next.t('등록')} type="submit" isDisabled={!formState.isValid} />
        </BGButtonGroup>
      </div>
    </form>
  );
};
