import React from 'react';
import './App.css';
import './styles/index.scss';
import { ThemeProvider } from '@emotion/react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import { darkTheme, lightTheme } from './Theme';
import Route from './routes/Route';
import { StoreProvider } from './context/Store';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: process.env.REACT_APP_ENV === 'production',
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <ThemeProvider theme={lightTheme || darkTheme}>
      <RecoilRoot>
        <StoreProvider>
          <QueryClientProvider client={queryClient}>
            <Router>
              <Route />
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </StoreProvider>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
