import { UseTranslationResponse } from 'react-i18next';

export class AttributionWindow {
  public static ONE_DAY_VIEW_SEVEN_DAYS_CLICK = '1d_view_7d_click';

  public static SEVEN_DAYS_CLICK = '7d_click';

  public static values() {
    return Object.values(AttributionWindow);
  }

  public static toSelects(i18next: UseTranslationResponse<'translation'>) {
    return [
      {
        label: i18next.t('클릭 7일 + 조회 1일'),
        value: AttributionWindow.ONE_DAY_VIEW_SEVEN_DAYS_CLICK,
      },
      {
        label: i18next.t('클릭 7일'),
        value: AttributionWindow.SEVEN_DAYS_CLICK,
      },
    ];
  }
}

export type TAttributionWindow = typeof AttributionWindow[keyof typeof AttributionWindow];
