import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import validator from 'validator';

import { baseCss } from './FormMallUrl.style';
import { InputError } from '../InputError/InputError';
import { BGUrlSelector } from '../BGUrlSelector/BGUrlSelector';

export interface FormMallUrlForm {
  url: string;
}

export interface FormMallUrlProps {
  url?: string;
  children?: React.ReactNode;
  onChanged?: (form: Partial<FormMallUrlForm>) => void;
  onIsValidChanged?: (isValid: boolean) => void;
  onSubmit: (form: FormMallUrlForm) => void;
}

export const FormMallUrl = (props: FormMallUrlProps): ReactElement => {
  const i18next = useTranslation();

  const [url, setUrl] = useState<string>(props.url || '');

  const { register, formState, setValue, handleSubmit, watch, trigger } = useForm<FormMallUrlForm>({ mode: 'all' });
  useEffect(() => {
    register('url', {
      value: url,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isURL(value)) {
          return i18next.t('잘못된 URL 형식입니다.') as string;
        }

        return true;
      },
    });
  }, []);
  useEffect(() => {
    if (!props.onChanged) {
      return () => undefined;
    }

    const subscription = watch(props.onChanged);
    return () => subscription.unsubscribe();
  }, [watch]);
  useEffect(() => {
    if (props.onIsValidChanged) {
      props.onIsValidChanged(formState.isValid);
    }
  }, [formState.isValid]);

  useEffect(() => {
    if (props.url && props.url !== url) {
      setUrl(props.url);
      setValue('url', props.url);
      trigger('url');
    }
  }, [props]);

  return (
    <form css={[baseCss]} onSubmit={handleSubmit(props.onSubmit)}>
      <div className="mall-url-form-body">
        <div className="form-input">
          <div className="label">{i18next.t('쇼핑몰 URL')}</div>
          <BGUrlSelector
            validationTrackOn={!!formState.errors.url}
            isValid={!formState.errors.url}
            url={url || ''}
            handleUpdate={(s) => {
              setValue('url', s, { shouldValidate: true });
              setUrl(s);
            }}
            placeholder="www.bigin.io"
          />
          {formState.errors.url && <InputError message={formState.errors.url.message as string} />}
        </div>
      </div>
      {props.children}
    </form>
  );
};
