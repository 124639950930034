import React, { useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import axios, { AxiosError } from 'axios';

import { LogoFrame } from '../../components/LogoFrame/LogoFrame';
import { BGButton } from '../../components/BGButton/BGButton';
import { baseCss, stepCss } from './SignUp.style';
import { UserApi } from '../../lib/api/UserApi';
import { WrapperModal } from '../../components/WrapperModal/WrapperModal';
import { useDataStore } from '../../context/Store';
import { MallApi } from '../../lib/api/MallApi';
import { Step1, Step1State } from './Step1/Step1';
import { Step2, Step2State } from './Step2/Step2';
import { Step3, Step3State } from './Step3/Step3';
import { Step4, Step4State } from './Step4/Step4';
import { ModalDuplicated } from './ModalDuplicated/ModalDuplicated';
import { Badge } from '../../components/Badge/Badge';
import { BorderSection } from '../../components/BorderSection/BorderSection';

enum STEP {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
  STEP4 = 4,
}

export const SignUp = () => {
  const i18next = useTranslation();
  const navigate = useNavigate();
  const { dialogStore } = useDataStore();

  const STEPS = 4;

  const [step, setStep] = useState<number>(STEP.STEP1);
  const [url, setUrl] = useState<string>('https://');
  const [name, setName] = useState<string>('');
  const [hosting, setHosting] = useState<Hosting>('etc');
  const [companyName, setCompanyName] = useState<string>('');
  const [companyRegistration, setCompanyRegistration] = useState<string>('');
  const [isStep2Loading, setIsStep2Loading] = useState<boolean>(false);
  const [isStep3Loading, setIsStep3Loading] = useState<boolean>(false);
  const [isOpenModalDuplicated, setIsOpenModalDuplicated] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>();
  const [mallClassifications, setMallClassifications] = useState<MallClassification[]>([]);
  const [mallClassificationId, setMallClassificationId] = useState<number | null>(null);
  const [monthlyBudget, setMonthlyBudget] = useState<string | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [userEmail, setUserEmail] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('');
  const [userPasswordConfirm, setUserPasswordConfirm] = useState<string>('');
  const [userPhone, setUserPhone] = useState<string>('');

  const handleStep1Update = (state: Step1State) => {
    if (url !== state.url) {
      setUrl(state.url);
    }
    if (isValid !== state.isValid) {
      setIsValid(state.isValid);
    }
  };

  const handleStep2Update = (state: Step2State) => {
    if (name !== state.name) {
      setName(state.name);
    }
    if (hosting !== state.hosting) {
      setHosting(state.hosting);
    }
    if (companyName !== state.companyName) {
      setCompanyName(state.companyName);
    }
    if (companyRegistration !== state.companyRegistration) {
      setCompanyRegistration(state.companyRegistration);
    }
    if (isValid !== state.isValid) {
      setIsValid(state.isValid);
    }
  };

  const handleStep3Update = (state: Step3State) => {
    if (mallClassificationId !== state.mallClassificationId) {
      setMallClassificationId(state.mallClassificationId);
    }
    if (monthlyBudget !== state.monthlyBudget) {
      setMonthlyBudget(state.monthlyBudget);
    }
    if (isValid !== state.isValid) {
      setIsValid(state.isValid);
    }
  };

  const handleStep4Update = (state: Step4State) => {
    if (userName !== state.userName) {
      setUserName(state.userName);
    }
    if (userEmail !== state.userEmail) {
      setUserEmail(state.userEmail);
    }
    if (userPassword !== state.userPassword) {
      setUserPassword(state.userPassword);
    }
    if (userPasswordConfirm !== state.userPasswordConfirm) {
      setUserPasswordConfirm(state.userPasswordConfirm);
    }
    if (userPhone !== state.userPhone) {
      setUserPhone(state.userPhone);
    }
    if (isValid !== state.isValid) {
      setIsValid(state.isValid);
    }
  };

  const handlePrev = () => {
    if (step === STEP.STEP1) {
      navigate(-1);
      return;
    }

    setStep(step - 1);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (step === STEP.STEP1) {
      setStep(step + 1);

      setIsStep2Loading(true);
      try {
        let response;
        try {
          response = await UserApi().getSiteUrlInfo(url);
          setName(response.data.data.name || '');
          setHosting((response.data.data.hosting || 'etc') as Hosting);
          setCompanyRegistration(response.data.data.registration || '');
        } catch (err) {
          if (!axios.isAxiosError(err)) {
            throw err;
          }

          throw err;
        }

        const businessNumber = response.data.data.registration;
        if (businessNumber) {
          try {
            response = await UserApi().checkBusinessNumber(businessNumber);
            setCompanyName(response.data.data.bup_nm);
          } catch (err) {
            if (!axios.isAxiosError(err)) {
              throw err;
            }

            throw err;
          }
        }
      } finally {
        setIsStep2Loading(false);
      }
    }
    if (step === STEP.STEP2) {
      try {
        const response = await UserApi().isBusinessNumberDuplicate(companyRegistration);
        if (response?.status === 200) {
          setIsOpenModalDuplicated(true);
          return;
        }
      } catch (err) {
        if (!axios.isAxiosError(err)) {
          throw err;
        }

        switch ((err as AxiosError).response?.status) {
          case 404:
            setStep(step + 1);
            break;
          default:
            dialogStore.showMessage(
              i18next.t('알림'),
              i18next.t('사업자 등록번호 중복 확인 요청이 실패했습니다. 잠시 후, 다시 시도해주세요.'),
            );
            break;
        }
      }

      setStep(step + 1);

      setIsStep3Loading(true);
      try {
        setMallClassifications((await MallApi().getMallClassifications()).data.data);
      } catch (err) {
        if (!axios.isAxiosError(err)) {
          throw err;
        }

        switch ((err as AxiosError).response?.status) {
          default:
            dialogStore.showMessage(
              i18next.t('알림'),
              i18next.t('업종 카테고리를 가져오는 요청이 실패했습니다. 잠시 후, 다시 시도해주세요.'),
            );
            break;
        }
      } finally {
        setIsStep3Loading(false);
      }
    }
    if (step === STEP.STEP3) {
      setStep(step + 1);
    }
    if (step === STEP.STEP4) {
      const params = {
        user: {
          email: userEmail,
          name: userName,
          password: userPassword,
          password_confirmation: userPasswordConfirm,
          phone: userPhone,
          is_promotion_agreed: true,
        },
        name: companyName,
        registration: companyRegistration,
        mall: {
          url,
          name,
          hosting,
          classification_id: mallClassificationId as number,
          extra_json: JSON.stringify({
            monthly_budget: monthlyBudget,
          }),
        },
      };

      try {
        await UserApi().registrationAccount(params);
        dialogStore.showMessage(
          i18next.t('알림'),
          i18next.t('회원가입이 완료되었습니다. 기입하신 정보로 로그인 해주세요.'),
        );

        navigate('/login');
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 409) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      }
    }
  };

  return (
    <div css={[baseCss]}>
      <LogoFrame>
        <BorderSection style={{ padding: '20px', width: '320px', overflow: 'visible' }}>
          <form onSubmit={handleSubmit}>
            <div className="header">
              <div className="title">{i18next.t('회원가입')}</div>
              <Badge>
                <span>{`${step}/${STEPS}`}</span>
              </Badge>
            </div>
            {step === STEP.STEP1 && (
              <div css={[stepCss]}>
                <div className="description">{i18next.t('마케팅을 진행할 쇼핑몰 URL을 입력해주세요.')}</div>
                <Step1 url={url} handleUpdate={handleStep1Update} />
              </div>
            )}
            {step === STEP.STEP2 && (
              <div css={[stepCss]}>
                <div className="description">
                  {isStep2Loading
                    ? i18next.t('{{url}}을 분석중입니다.\n잠시만 기다려주세요.', { url })
                    : i18next.t('{{url}}의 분석을 완료했습니다.\n아래의 정보를 확인해주세요.', { url })}
                </div>
                <Step2
                  isLoading={isStep2Loading}
                  name={name}
                  hosting={hosting}
                  companyName={companyName}
                  companyRegistration={companyRegistration}
                  handleUpdate={handleStep2Update}
                />
              </div>
            )}
            {step === STEP.STEP3 && (
              <div css={[stepCss]}>
                <div className="description">
                  {i18next.t(
                    '{{name}}의 카테고리와 예산을 설정해주세요.\n이 정보들은 더욱 정교한 마케팅에 활용됩니다.',
                    {
                      name,
                    },
                  )}
                </div>
                <Step3
                  isLoading={isStep3Loading}
                  mallClassifications={mallClassifications}
                  handleUpdate={handleStep3Update}
                  mallClassificationId={mallClassificationId}
                  monthlyBudget={monthlyBudget}
                />
              </div>
            )}
            {step === STEP.STEP4 && (
              <div css={[stepCss]}>
                <div className="description">
                  {i18next.t('{{name}}에 대한 정보는 모두 입력되었어요.\n이제 광고주님의 정보를 입력해주세요.', {
                    name,
                  })}
                </div>
                <Step4
                  handleUpdate={handleStep4Update}
                  userName={userName}
                  userEmail={userEmail}
                  userPassword={userPassword}
                  userPasswordConfirm={userPasswordConfirm}
                  userPhone={userPhone}
                />
              </div>
            )}

            <div className="buttons-wrapper">
              <BGButton appearance="secondary" label={i18next.t('이전')} hoverable onClick={handlePrev} />
              <BGButton
                type="submit"
                label={step !== STEPS ? i18next.t('다음') : i18next.t('회원가입')}
                hoverable
                isDisabled={!isValid}
              />
            </div>
          </form>
        </BorderSection>
      </LogoFrame>
      {isOpenModalDuplicated ? (
        <WrapperModal isOpen={isOpenModalDuplicated} close={() => setIsOpenModalDuplicated(false)}>
          <ModalDuplicated
            close={() => {
              setIsOpenModalDuplicated(false);
            }}
          />
        </WrapperModal>
      ) : (
        <div />
      )}
    </div>
  );
};
