import axios, { AxiosPromise } from 'axios';

export const MediaApi = () => ({
  getMediaAuths(): AxiosPromise<{
    data: {
      facebook: {
        type: string;
        is_logged_in: boolean;
      };
      google: {
        type: string;
        is_logged_in: boolean;
      };
    };
  }> {
    return axios.get('/media-auths');
  },
  putMediaAuth(type: string, access_token: string): AxiosPromise<{ data: { type: string; is_logged_in: boolean } }> {
    return axios.put(`/media-auths/${type}`, {
      access_token,
    });
  },
  putBiginCrmAuth(
    access_token: string,
    refresh_token: string,
    service_id: string,
  ): AxiosPromise<{ data: { type: string; is_logged_in: boolean } }> {
    return axios.put(`/media-auths/bigin_crm`, {
      access_token,
      refresh_token,
      service_id,
    });
  },
  getMediaAuthByType(type: string): AxiosPromise {
    return axios.get(`/media-auths/${type}`);
  },
  deleteMediaAuth(type: string): AxiosPromise {
    return axios.delete(`/media-auths/${type}`);
  },
});
