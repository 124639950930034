import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { baseCss } from './PlanRecommendation.style';
import { LogoFrame } from '../../components/LogoFrame/LogoFrame';
import { Step1, Step1Form } from './Step1/Step1';
import { Step2Yes, Step2YesForm } from './Step2Yes/Step2Yes';
import { Step3Yes, Step3YesForm } from './Step3Yes/Step3Yes';
import { Step2No, Step2NoForm } from './Step2No/Step2No';
import { Step3No, Step3NoForm } from './Step3No/Step3No';
import { MallApi } from '../../lib/api/MallApi';
import { BGLoadingSpinner } from '../../components/BGLoadingSpinner/BGLoadingSpinner';
import { MonthlyBudget } from '../../enums/MonthlyBudget';
import { Badge } from '../../components/Badge/Badge';
import { BorderSection } from '../../components/BorderSection/BorderSection';

enum Step {
  STEP1,
  STEP2_YES,
  STEP3_YES,
  STEP2_NO,
  STEP3_NO,
  STEP_SUBMIT,
}

enum StepUI {
  STEP1 = 1,
  STEP2 = 2,
  STEP3 = 3,
}

export const PlanRecommendation = (): ReactElement => {
  const { mallId } = useParams<{ mallId: string }>();
  const i18next = useTranslation();
  const navigate = useNavigate();

  const STEPS = 3;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  useQuery<MallTask[]>('mallTasks', async () => (await MallApi().indexTask(Number(mallId))).data.data, {
    onSuccess: (mallTasks: MallTask[]) => {
      const subscribeTasks = mallTasks
        .filter((item) => item.task?.type === 'subscribe')
        .filter((item) => ['submitted', 'progressing'].indexOf(item.status) > -1);
      if (subscribeTasks.length > 0) {
        navigate(`/malls/${mallId}/plans/recommendation/cloud/complete`);
        return;
      }

      setIsLoading(false);
    },
  });

  const [step, setStep] = useState<Step>(Step.STEP1);
  const [stepUI, setStepUI] = useState<StepUI>(StepUI.STEP1);
  // Step1
  const [hasExperienced, setHasExperienced] = useState<boolean>();
  // Step2Yes
  const [experiencedMedias, setExperiencedMedias] = useState<string[]>([]);
  const [experiencedEtcMedia, setExperiencedEtcMedia] = useState<string>();
  // Step3Yes
  const [difficults, setDifficults] = useState<string[]>([]);
  const [difficultEtc, setDifficultEtc] = useState<string>();
  // Step2No
  const [importants, setImportants] = useState<string[]>([]);
  const [importantEtc, setImportantEtc] = useState<string>();
  // Step3Common(Step3Yes + Step3No)
  const [needHelp, setNeedHelp] = useState<boolean>();
  const [message, setMessage] = useState<string>();

  const recommend = async (): Promise<PlanType | 'cloud'> => {
    const survey = {
      has_experienced: hasExperienced,
      experienced_medias: experiencedMedias,
      experienced_etc_media: experiencedEtcMedia,
      difficults,
      difficult_etc: difficultEtc,
      importants,
      important_etc: importantEtc,
      need_help: needHelp,
      message,
    };

    return MallApi()
      .updateMall(Number(mallId), { extra: { survey } })
      .then((response) => {
        const mall = response.data.data;

        // 호스팅사가 오픈마켓일 경우
        if (mall.hosting === 'smartstore') {
          return 'smartstore';
        }

        // 월 운용 예산 500만원 이하 또는 도움 니즈가 없는 경우
        if (MonthlyBudget.isUnder500(mall.extra?.monthly_budget) || !needHelp) {
          return 'brandmall';
        }

        // 이 외
        return 'cloud';
      });
  };

  useEffect(() => {
    if (step !== Step.STEP_SUBMIT) {
      return;
    }

    recommend().then((plan) => {
      navigate(`/malls/${mallId}/plans/recommendation/${plan}`);
    });
  }, [step]);

  const prev = () => {
    if (step === Step.STEP1) {
      navigate(-1);
    }
    if (step === Step.STEP2_YES) {
      setStep(Step.STEP1);
      setStepUI(StepUI.STEP1);
    }
    if (step === Step.STEP3_YES) {
      setStep(Step.STEP2_YES);
      setStepUI(StepUI.STEP2);
    }
    if (step === Step.STEP2_NO) {
      setStep(Step.STEP1);
      setStepUI(StepUI.STEP1);
    }
    if (step === Step.STEP3_NO) {
      setStep(Step.STEP2_NO);
      setStepUI(StepUI.STEP2);
    }
  };

  const onStep1Submit = (form: Step1Form) => {
    setHasExperienced(form.hasExperienced);

    setStep(form.hasExperienced ? Step.STEP2_YES : Step.STEP2_NO);
    setStepUI(StepUI.STEP2);
  };

  const onStep2YesSubmit = (form: Step2YesForm) => {
    setExperiencedMedias(form.experiencedMedias);
    setExperiencedEtcMedia(form.experiencedEtcMedia);

    setStep(Step.STEP3_YES);
    setStepUI(StepUI.STEP3);
  };

  const onStep3YesSubmit = (form: Step3YesForm) => {
    setDifficults(form.difficults);
    setDifficultEtc(form.difficultEtc);
    setNeedHelp(form.needHelp);
    setMessage(form.message);

    setStep(Step.STEP_SUBMIT);
  };

  const onStep2NoSubmit = (form: Step2NoForm) => {
    setImportants(form.importants);
    setImportantEtc(form.importantEtc);

    setStep(Step.STEP3_NO);
    setStepUI(StepUI.STEP3);
  };

  const onStep3NoSubmit = (form: Step3NoForm) => {
    setNeedHelp(form.needHelp);
    setMessage(form.message);

    setStep(Step.STEP_SUBMIT);
  };

  return (
    <div css={[baseCss]}>
      {isLoading && <BGLoadingSpinner isLoading />}
      {!isLoading && (
        <LogoFrame>
          <BorderSection style={{ padding: '20px', width: '320px' }}>
            <div className="header-wrapper">
              <span className="text-h1 text-color-main">{i18next.t('플랜 추천 받기')}</span>
              <Badge>
                <span>{`${stepUI}/${STEPS}`}</span>
              </Badge>
            </div>
            <div className="body-wrapper">
              {step === Step.STEP1 && <Step1 prev={prev} onSubmit={onStep1Submit} />}
              {step === Step.STEP2_YES && <Step2Yes prev={prev} onSubmit={onStep2YesSubmit} />}
              {step === Step.STEP3_YES && <Step3Yes prev={prev} onSubmit={onStep3YesSubmit} />}
              {step === Step.STEP2_NO && <Step2No prev={prev} onSubmit={onStep2NoSubmit} />}
              {step === Step.STEP3_NO && <Step3No prev={prev} onSubmit={onStep3NoSubmit} />}
              {step === Step.STEP_SUBMIT && <BGLoadingSpinner isLoading />}
            </div>
          </BorderSection>
        </LogoFrame>
      )}
    </div>
  );
};
