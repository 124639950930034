import React, { ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { useSetRecoilState } from 'recoil';
import { baseCss, materialDropDownStyle } from './PositionSelector.style';
import { MaterialHorizontalAlign, MaterialVerticalAlign } from '../../object-model/model';
import { FontIcon } from '../FontIcon/FontIcon';
import { Backdrop } from '../Backdrop/Backdrop';
import { BGTooltip } from '../BGTooltip/BGTooltip';
import CampaignList from '../../recoil/CampaignList';

const getUniqKey = (prefix: string, index: number) => {
  return `${prefix}-${index}`;
};

export interface MaterialDropDownProps<T> {
  disabled?: boolean;
  list: T[];
  defaultValue: T;
  displayValue: (value: T) => string;
  isSelected: (value: T) => boolean;
  handleUpdate: (value: T, index?: number) => void;
  style?: object;
  appearance?: 'white' | 'gray';
  className?: string;
  error?: boolean;
  position?: 'bottom' | 'top';
  lock?: boolean;
}

export const MaterialDropDown = (props: MaterialDropDownProps<unknown>): ReactElement => {
  const [selectBoxOpened, setSelectedBoxOpened] = useState<boolean>(false);
  let selectedValue = props.defaultValue;
  const i18next = useTranslation();
  const setShowModalSDKInstall = useSetRecoilState<boolean>(CampaignList.sdkModal);

  return (
    <React.Fragment>
      {selectBoxOpened && (
        <Backdrop
          handleClick={() => {
            setSelectedBoxOpened(false);
          }}
        />
      )}

      <div
        className={`material-dropdown ${props.appearance || ''} ${props.className || ''} ${
          selectBoxOpened ? 'box-opened' : ''
        } ${props.disabled ? 'disabled' : ''} ${props.error ? 'error' : ''} ${props?.position === 'top' ? 'top' : ''}`}
        css={[materialDropDownStyle]}
        style={props.style}
      >
        <div
          className={`material-dropdown-selected-item-wrapper ${selectBoxOpened ? 'box-opened' : ''} ${
            props?.position === 'top' ? 'top' : ''
          }`}
        >
          <div
            className={`material-dropdown-item ${props.appearance || ''}`}
            onClick={() => {
              if (!props.disabled) setSelectedBoxOpened(!selectBoxOpened);
            }}
          >
            <span className="material-dropdown-item-text">
              {props.list.reduce((acc: string, item) => {
                return acc + (props.isSelected(item) ? props.displayValue(item) : '');
              }, '') || props.displayValue(props.defaultValue)}
            </span>
            {!selectBoxOpened && <FontIcon name="ic-arrow-down" size="20px" color="#7e8696" />}
            {selectBoxOpened && <FontIcon name="ic-arrow-up" size="20px" color="#7e8696" />}
          </div>
        </div>
        {selectBoxOpened && (
          // 드랍다운 Wrapper
          <div
            className={`material-dropdown-select-box ${props.list.length > 7 ? 'scroll' : ''} ${
              props?.position === 'top' ? 'top' : ''
            }`}
          >
            {props.list.map((item: any, index) => {
              const dropdownItem = (
                <div
                  key={getUniqKey('position-dropdown-item', index)}
                  className={`material-dropdown-list-item ${props.isSelected(item) ? 'selected' : ''} ${
                    props.appearance
                  }`}
                  onClick={() => {
                    if (!item.disabled) {
                      setSelectedBoxOpened(false);
                      selectedValue = item;
                      props.handleUpdate(selectedValue, index);
                    } else {
                      setShowModalSDKInstall(true);
                    }
                  }}
                >
                  {item.disabled && <BDSFontIcon name="ic-lock" size="20px" color="#c9cfda" />}
                  <span style={item.disabled ? { marginLeft: 4 } : {}}>{props.displayValue(item)}</span>
                </div>
              );
              return (
                // 드랍다운 아이템
                item.disabled ? (
                  <BGTooltip title={i18next.t('Bigin SDK 설치 필요') || ''}>{dropdownItem}</BGTooltip>
                ) : (
                  dropdownItem
                )
              );
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export type PositionType = {
  name: string;
  value: string;
  verticalAlign: MaterialVerticalAlign;
  horizontalAlign: MaterialHorizontalAlign;
  disabled?: boolean;
};

export interface PositionSelectorProps {
  positionList: PositionType[];
  defaultValue: PositionType;
  handleUpdate: (v: MaterialVerticalAlign, h: MaterialHorizontalAlign) => void;
  verticalAlign: MaterialVerticalAlign;
  horizontalAlign: MaterialHorizontalAlign;
  style?: object;
}

export const PositionSelector = (props: PositionSelectorProps): ReactElement => {
  const i18next = useTranslation();
  const updatePosition = (v: MaterialVerticalAlign, h: MaterialHorizontalAlign) => {
    props.handleUpdate(v, h);
  };

  return (
    <div className="material-position-selector" css={[baseCss]} style={props.style}>
      <div className="material-dropdown-wrapper">
        <MaterialDropDown
          appearance="gray"
          list={props.positionList.filter((position) => {
            return !position.disabled;
          })}
          defaultValue={props.positionList[0]}
          displayValue={(value) => {
            return i18next.t((value as PositionType).name);
          }}
          isSelected={(value) => {
            return (value as PositionType).value === props.defaultValue.value;
          }}
          handleUpdate={(value) => {
            props.handleUpdate((value as PositionType).verticalAlign, (value as PositionType).horizontalAlign);
          }}
        />
      </div>

      <div className="material-position-click-selector">
        {props.positionList.map((position, index) => {
          return (
            <div
              key={getUniqKey('position-button', index)}
              className={`material-position-click-btn ${
                props.verticalAlign === position.verticalAlign && props.horizontalAlign === position.horizontalAlign
                  ? 'selected'
                  : ''
              } ${position.disabled ? 'disabled' : ''}`}
              onClick={() => {
                if (!position.disabled) updatePosition(position.verticalAlign, position.horizontalAlign);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

PositionSelector.defaultProps = {
  style: {},
  className: '',
};
