import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const StyledCommonListMoreButtonWrapper = css`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .category-select-box-menu {
    width: 300px;
    padding: 4px;
    margin-top: 8px;

    .mat-menu-content {
      width: 100% !important;
    }
  }

  .select-box-item {
    width: 100% !important;
    height: unset;
    font-size: 12px;
    line-height: 1.33;
    color: #777788;
    align-items: unset !important;
    border-radius: 2px;
    margin: 0 !important;
    flex-direction: column;
    padding: 0 16px 0 12px;

    &:last-of-type {
      margin-block-end: 4px !important;
    }

    div {
      display: block;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #838294;
      word-break: keep-all;
      white-space: normal;

      &:first-of-type {
        font-size: 14px;
        color: #222233;
        margin-bottom: rem(8);
      }
    }

    &.selected {
      font-weight: bold;
      color: #222233;
      background-color: #f5f5fa;
    }

    &:hover {
      background: #f5f5fa !important;
    }
  }
`;

export const StyledButton = styled.button`
  padding: 2px;
  border-radius: 50%;
  background-color: ${({ theme }): string => theme.colors.bg3};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${({ theme }): string => theme.colors.secondary1};
    cursor: pointer;
  }

  & > .font-icon-panel {
    color: ${({ theme }): string => theme.colors.icon};
    &:hover {
      color: #fff;
    }
  }
`;
