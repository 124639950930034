import { css } from '@emotion/react';

export const baseCss = css`
  .input-error {
    margin-top: 1px;
    width: 100%;

    .az-icon {
      vertical-align: middle;
      width: 16px;
      height: 16px;
      font-size: 16px;
      color: #e65c5c;
      margin-right: 8px;
    }

    span {
      vertical-align: middle;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      color: #e65c5c;
    }
  }
`;
