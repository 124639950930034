import { css } from '@emotion/react';

export const baseCss = css`
  .plan-confirm {
    & > .plans-wrapper {
      display: flex;
      justify-content: space-between;

      & > *:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
`;
