import React, { ReactElement, RefObject, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';

import { baseCss } from './InputExpiryDate.style';

interface InputExpiryDateProps {
  nextRef?: RefObject<HTMLInputElement>;
  valueHandle?: (key: any, value: any) => void;
}

export const InputExpiryDate = React.forwardRef(
  (props: InputExpiryDateProps, ref: React.Ref<HTMLInputElement>): ReactElement => {
    const [number, setNumber] = useState({ mm: '', yy: '' });
    const Date: React.Ref<HTMLInputElement> = useRef(null);
    const Year: React.Ref<HTMLInputElement> = useRef(null);

    const maxLengthCheck = (
      e: React.FormEvent<HTMLInputElement>,
      inputType: RefObject<HTMLInputElement>,
      index: 'mm' | 'yy',
    ) => {
      let { value } = e.currentTarget;
      const { maxLength } = e.currentTarget;
      value = value.replace(/[^0-9]*/g, '');

      if (value.length >= maxLength) {
        value = value.slice(0, maxLength);
        inputType.current?.focus();
      }
      const updateNumber = { ...number };
      updateNumber[index] = value;
      setNumber(updateNumber);
      if (props.valueHandle) props.valueHandle(index, value);
    };

    return (
      <div css={[baseCss]} className="input-card-number">
        <input
          type="text"
          maxLength={2}
          ref={ref || Date}
          key={0}
          placeholder="MM"
          onInput={(e) => maxLengthCheck(e, Year, 'mm')}
          value={number.mm}
        />
        <div className="input-card-divider">/</div>
        <input
          type="text"
          autoComplete="new-password"
          maxLength={2}
          ref={Year}
          key={1}
          placeholder="YY"
          value={number.yy}
          onInput={(e) => maxLengthCheck(e, props.nextRef || Year, 'yy')}
        />
      </div>
    );
  },
);
