import { css, SerializedStyles, Theme } from '@emotion/react';

export const toastCSS = (theme: Theme): SerializedStyles => {
  const {
    colors: { warning, success, error, subFont, primary },
  } = theme;

  return css`
    & * {
      box-sizing: border-box;
    }

    .toast-loading-page {
      position: fixed;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 1px;
      z-index: 9999;
      margin: 0 auto;

      &.bottom {
        bottom: 0;
        left: 0;
        right: 0;

        &.visible {
          .toast-box {
            opacity: 1;
            bottom: 40px;
            transform: translateY(-100%);
          }
        }
      }

      .toast-box {
        width: fit-content;
        opacity: 0;
        bottom: 0;
        transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        -webkit-transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        -moz-transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        -o-transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        min-width: 360px;
        max-width: 600px;
        padding: 10px;
        border-radius: 8px;
        background-color: #bbf3de;
        border: solid 1px ${success};
        z-index: 10;

        .icon-wrapper {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;

          .toast-icon {
            color: ${success};
          }
        }

        .toast-text {
          font-size: 14px;
          font-weight: bold;
          color: ${subFont};
        }

        .button-wrapper {
          margin-left: auto;
        }

        &.fail {
          background-color: #f8dbd4;
          border: solid 1px ${error};

          .icon-wrapper {
            .toast-icon {
              color: ${error};
            }
          }
        }

        &.info {
          background-color: #cae4ff;
          border: solid 1px ${primary};

          .icon-wrapper {
            .toast-icon {
              color: ${primary};
            }
          }
        }

        &.warning {
          background-color: #f2e2c1;
          border: solid 1px ${warning};

          .icon-wrapper {
            .toast-icon {
              color: ${warning};
            }
          }
        }
      }
    }

    .btm-toast-loading-page {
      position: fixed;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      height: 1px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 999;
      margin: 0 auto;

      &.visible {
        .btm-toast-box {
          opacity: 1;
          bottom: 40px;
          transform: translateY(-100%);
        }
      }

      .btm-toast-box {
        display: flex;
        justify-content: space-between;
        width: 644px;
        opacity: 0;
        bottom: 0;
        transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        -webkit-transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        -moz-transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        -o-transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
        align-items: center;
        position: relative;
        min-width: 360px;
        padding: 10px;
        border-radius: 8px;
        background-color: #beecdb;
        border: solid 1px ${success};
        z-index: 10;

        .btm-toast-panel-box {
          display: flex;
          align-items: center;

          az-icon {
            font-size: 18px;
            margin-right: 5px;
          }

          .btm-toast-text {
            font-size: 14px;
          }
        }
        .btm-close {
          cursor: pointer;
          font-size: 16px;
        }
        .btm-btn {
          display: none;
        }

        &.fail {
          background-color: #fadede;
          border: 1px solid ${error};
          color: ${error};
        }

        &.info {
          background-color: #cce7ff;
          border: 1px solid ${primary};
          color: ${primary};
        }

        &.demo {
          background-color: #cce7ff;
          border: 1px solid ${primary};
          color: ${primary};
          padding: 14px 22px;

          .btm-close {
            display: none;
          }

          .btm-btn {
            cursor: pointer;

            width: 117px;
            height: 36px;
            border-radius: 4px;
            background-color: ${primary};

            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            line-height: 1.43;
            text-align: center;
            color: #ffffff;

            &:hover {
              background-color: #0c92ff;
            }

            &:active {
              box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.3);
              background-color: #007ef1;
            }
          }
        }
      }
    }
  `;
};
