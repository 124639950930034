import { css, SerializedStyles, Theme } from '@emotion/react';

export const StyledHeaderSetUp = (theme: Theme): SerializedStyles => {
  return css`
    width: 280px;
    border-radius: 0 0 8px 8px;
    background-color: #edf1f6;

    .header-identifier-panel {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-top: 1px solid #d3d7de;

      .identifier-item {
        width: 100%;

        .identifier-item-title {
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          margin-bottom: 4px;

          .item-label {
            ${theme.text.h5};
            color: ${theme.colors.mainFont};
          }

          .item-logout-label {
            ${theme.text.h5};
            color: ${theme.colors.subFont};
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .item-value {
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 250px;
          overflow: hidden;
          ${theme.text.input};
          color: ${theme.colors.subFont};
        }
      }
    }

    .header-project-panel {
      overflow: scroll;
      max-height: 400px;

      .project-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 16px 20px;

        .title-label {
          ${theme.text.h5};
          color: ${theme.colors.mainFont};
        }

        .project-add {
          cursor: pointer;
        }
      }

      .project-list {
        position: relative;

        .project-panel {
          box-sizing: border-box;
          margin: 4px 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px;
          border-radius: 8px;

          &:hover {
            cursor: pointer;
            background-color: #f9fafb;
            border: solid 1px #dce0e8;
            margin: 3px 7px;

            .project-name {
              white-space: normal !important;
              font-weight: normal;
              color: #3d4046;
            }

            .project-setting-panel {
              display: flex;
            }
          }

          &.active {
            background-color: #ffffff;
            border: solid 1px #dce0e8;
            margin: 3px 7px;

            .project-name {
              font-weight: bold;
            }
          }

          .project-info-panel {
            display: flex;
            justify-content: flex-start;

            .project-info-item {
              margin-left: 10px;

              .project-name {
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 188px;
                overflow: hidden;
                ${theme.text.input};
                color: ${theme.colors.mainFont};
              }

              .projectId-value {
                ${theme.text.disclaimer}
              }
            }
          }

          .project-setting-panel {
            display: none;
          }
        }
      }
    }

    .modal-contents {
      overflow: visible;
    }
  `;
};
