import { css, useTheme, Theme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    margin-bottom: 20px;
    position: relative;

    .facebook-title-box {
      padding: 20px 20px 8px 16px;
      display: flex;
      gap: 8px;

      &.instagram {
        padding-bottom: 16px;
      }

      .logo {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        border: solid 1px #edf1f6;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .media-title-wrapper {
        .title {
          ${theme.text.h5}
          color: ${theme.colors.mainFont};
        }
        .info {
          ${theme.text.disclaimer}
        }
      }
    }

    .preview-title {
      padding: 0 16px 8px;
      ${theme.text.h5}
      color: ${theme.colors.mainFont};
    }

    .preview-body {
      padding: 0 16px 8px;
      ${theme.text.input}
      color: ${theme.colors.mainFont};
      word-break: break-word;

      &.instagram {
        padding: 0px 16px 16px;
      }
    }

    .preview-action-wrapper {
      display: flex;
      justify-content: space-between;
      background-color: #c9d4dc;
      padding: 8px 16px;
      align-items: center;
      max-width: 500px;

      .preview-action-body {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 2px 0;
        max-width: 70%;

        .action-url {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          ${theme.text.p3}
          color: ${theme.colors.mainFont};
        }

        .action-body {
          ${theme.text.h5}
          color: ${theme.colors.mainFont};
        }

        .action-description {
          ${theme.text.p3}
          color: ${theme.colors.mainFont};
        }
      }
      .preview-action-link {
        display: flex;

        .preview-action-button {
          ${theme.text.p3}
          color: ${theme.colors.mainFont};
          padding: 4px 8px;
          border-radius: 4px;
          border: 1px solid #a5adb2;
        }

        .font-icon-panel {
          padding: 2px 0;
        }
      }
    }
    .preview-instagram-button-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px 11px;

      .left-side {
        display: flex;
        gap: 10px;
      }
      .right-side {
        display: flex;
      }
    }

    .preview-img-box {
      width: 100%;
      background-color: ${theme.colors.bg3};
      overflow: hidden;
      position: relative;
      padding-top: 100%;
      box-sizing: border-box;
      height: 0;
      user-select: none;

      &.empty {
        /* padding: 47%; */
        .font-icon-panel {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: top;
        position: absolute;
        top: 0;
        left: 0;
        user-select: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
      }
      .icon-play {
        position: absolute;
        top: 45%;
        left: 45%;
        width: 40px;
        height: 40px;
      }
    }
    .preview-img-box:after {
      padding-bottom: 100%;
    }

    .preview-slider-container {
      position: relative;

      &.facebook {
        padding-bottom: 20px;
      }
    }

    .preview-slider-wrap {
      overflow-x: auto;
      scroll-behavior: smooth;
      width: 400px;

      .flicking-pagination-bullet-active {
        background-color: #39f;
      }

      .flicking-viewport {
        padding: 0 16px;
        white-space: nowrap;
      }

      .margin-left {
        margin-left: 16px;
      }

      .preview-slide-item {
        display: flex;
        flex-direction: column;
        gap: 0;
        position: relative;
        width: 300px;
        margin-right: 16px;

        .preview-action-wrapper {
          padding: 8px;

          .preview-action-body {
            max-width: 69%;
            white-space: pre-wrap;
          }
        }

        .preview-img-box {
          width: 300px;
          height: 300px;

          &.empty {
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      &.instagram {
        .preview-slide-item {
          width: 400px;
          margin-right: 1px;

          .preview-img-box {
            width: 400px;
            height: 400px;
          }
        }
        .flicking-viewport {
          padding: 0;
        }
      }
    }

    .facebook-wrapper {
      padding: 20px;
    }
    .instagram-wrapper {
      border-left: 1px solid ${theme.colors.divider};
      padding: 20px;
    }

    .preview-collection-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 4px;
      margin-top: 4px;

      &.instagram {
        margin-bottom: 4px;
      }

      .collection-img-box {
        width: 100%;
        background-color: ${theme.colors.bg3};
        overflow: hidden;
        position: relative;
        padding-top: 100%;
        box-sizing: border-box;
        height: 0;

        &.empty {
          .font-icon-panel {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        img {
          width: 100%;
          height: 100%;
          vertical-align: top;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
        }
      }
    }
  `;
};
