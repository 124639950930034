import { useTranslation } from 'react-i18next';
import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';

import { BGButton } from '../../../components/BGButton/BGButton';
import { CardButton } from '../CardButton/CardButton';
import { baseCss } from './ModalDuplicated.style';

interface ModalDuplicatedProps {
  close: () => void;
}

export const ModalDuplicated = (props: ModalDuplicatedProps) => {
  const i18next = useTranslation();

  return (
    <div css={[baseCss]}>
      <div className="text-h1 text-color-main mb-6">{i18next.t('이미 등록된 회사입니다.')}</div>
      <CardButton
        id={1}
        icon="add"
        title={i18next.t('같은 사업자등록번호로 새로운 쇼핑몰을 추가하고 싶으신가요?')}
        desc={i18next.t('기존 계정으로 로그인 후 ‘새 쇼핑몰 추가’를 해주세요.')}
      />
      <CardButton
        id={2}
        icon="side-direction"
        title={i18next.t('쇼핑몰의 호스팅사가 바뀌었나요?')}
        desc={i18next.t('기존 계정으로 로그인 후 ‘새 쇼핑몰 추가’를 해주세요.')}
      />
      <CardButton
        id={3}
        icon="enter"
        title={i18next.t('팀원분께서 이미 가입하셨나요?')}
        desc={i18next.t('기존 계정 생성자에게 ‘등록된 팀원 관리’를 통해 초대를 요청해주세요.')}
      />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <BGButton label={i18next.t('확인')} hoverable onClick={() => props.close()} style={{ marginLeft: '1rem' }} />
      </div>
    </div>
  );
};
