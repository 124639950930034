import { css } from '@emotion/react';

export const ColumnPickerStyle = css`
  &,
  & * {
    box-sizing: border-box;
  }
  & {
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    width: 45px;
    height: 45px;
    border: 1px solid #dce0e8;
    border-top: 0;
    border-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .column-picker-button {
    width: 44px;
    height: 44px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  .column-picker-panel {
    position: absolute;
    top: 44px;
    right: 0;
    width: 540px;
    height: 400px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 10%);
  }

  .font-icon-panel {
    cursor: pointer;
    //border: 1px solid #7e8696;
  }

  .left-panel {
    width: 320px;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #ebeef4;

    .search-wrapper {
      width: 100%;
      height: auto;
      padding: 12px;
    }

    .column-picker-content {
      width: 100%;
      height: calc(100% - 100px);
      overflow-y: scroll;
    }
  }

  .right-panel {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    height: 100%;
    background-color: #fff;

    .selected-column-count-text {
      width: 100%;
      height: 24px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #3d4046;
      font-weight: bold;
      padding: 8px 8px 0 8px;
    }

    .selected-column-list {
      width: 100%;
      height: calc(100% - 76px);
      overflow-y: scroll;
      padding: 8px;
      .selected-column-wrapper {
        margin: 4px 0;
        width: 100%;
        height: 32px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #f5f5fa;

        font-size: 14px;
        color: #778;

        &.fixed {
          background: #edf1f6;
        }

        &.draggable {
          cursor: move;

          & > .font-icon-panel {
            display: none;
          }

          &:hover {
            .font-icon-panel {
              display: block;
            }
          }
        }
      }
    }
    .button-container {
      width: 100%;
      height: 52px;
      padding: 8px;
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: flex-end;
      border-top: 1px solid #ebeef4;
    }
  }
`;

export const PropertyGroupWrapperStyle = css`
  &,
  & * {
    box-sizing: border-box;
  }

  width: 100%;
  height: auto;
  padding: 0 16px;
  background-color: #ffffff;
  .property-group-name-wrapper {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .property-group-name-text {
      font-size: 14px;
      font-weight: bold;
      color: #53585f;
    }

    .column-group-icon {
      transform: rotate(270deg);
      &.open {
        transform: rotate(90deg);
      }
    }
  }

  .property-item-wrapper {
    width: 100%;
    height: 32px;
    font-size: 14px;
    color: #53585f;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 4px 0;
    padding: 0 8px;
    border-radius: 4px;

    &.active {
      background: #edf1f6;
    }

    &.fixed {
      cursor: not-allowed;
    }

    span {
      margin-left: 4px;
    }

    &:hover {
      .formula-selector-wrapper {
        visibility: visible;
      }
    }
  }

  .horizontal-line {
    width: 100%;
    height: 1px;
    background-color: #ebeef4;
    margin: 4px 0;
  }

  .formula-selector-wrapper {
    position: relative;
    width: auto;
    height: 100%;
    margin-left: auto;
    visibility: hidden;

    .formula-selected-button {
      height: 100%;
      font-size: 8px;
      font-weight: normal;
      color: #0086ff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .formula-selector-list {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      width: 80px;
      padding: 0 5px;
      background-color: #fff;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      .formula-selector {
        position: relative;
        z-index: 11;
        width: 100%;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8px;
        color: #778;
        &:hover {
          background-color: #f5f5fa;
        }
      }
    }
  }
`;
