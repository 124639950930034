import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { GS } from '../../styles/size';

interface StyledTabProps {
  isSideNavBarCollapsed: boolean;
  theme: Theme;
}

export const StyledTab = styled.div<StyledTabProps>`
  display: flex;
  justify-content: flex-start;
  height: 28px;
  width: 100%;
  background: #fff;
  padding: 0 24px;
  left: ${({ isSideNavBarCollapsed }) =>
    isSideNavBarCollapsed ? `${GS.sideBarMinWidth}px` : `${GS.sideBarMaxWidth}px`};
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);

  &.show-arrow {
    .tab-panel {
      margin-right: 10px;
    }
    .font-icon-panel {
      margin-right: 10px;
    }
  }

  &.fixed {
    top: 76px;
    position: fixed;
    z-index: 11;
    width: calc(
      100% -
        ${({ isSideNavBarCollapsed }) =>
          isSideNavBarCollapsed ? `${GS.sideBarMinWidth}px` : `${GS.sideBarMaxWidth}px`}
    );
  }

  .tab-panel {
    cursor: pointer;
    ${({ theme }) => theme.text.h5};
    color: #53585fb3;
    margin-right: 20px;
    margin-bottom: -1px;
    &.active {
      border-bottom: 2px solid #53585f;
      color: #53585f;
    }
  }
`;
