import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton, BDSSelectBox } from '@bigin/bigin-ui-components';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import _ from 'lodash';
import { BGUrlSelector } from '../../../../components/BGUrlSelector/BGUrlSelector';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss } from './KakaoImageNative.style';
import CampaignSetting from '../../../../recoil/CampaignSetting';
import { MaterialCheckboxSelector } from '../../MaterialCheckboxSelector/MaterialCheckboxSelector';
import { ModalImageSelect } from '../../../Creative/ModalImageSelect/ModalImageSelect';
import { InputError } from '../../../../components/InputError/InputError';
import { PreviewKakaoItem } from '../PreviewKakaoItem/PreviewKakaoItem';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { BGTagInput } from '../../../../components/BGTagInput/BGTagInput';
import { KK_MAX_LENGTH } from '../../../../utils/utils';
import { AssetHeader } from '../../../../components/AssetHeader/AssetHeader';

export interface KakaoImageNativeProps {
  setting: CampaignAdSettingState;
  handleUpdate: (setting: CampaignAdSettingState) => void;
  handleValid?: (type: CampaignType, isValid: boolean) => void;
  removeSelectedCampaignTypes: (type: CampaignType) => void;
}

interface KakaoImageNativeForm {
  title: string;
  url: string;
  kk_image_id: number | undefined | null;
}

export const KakaoImageNative = (props: KakaoImageNativeProps): ReactElement => {
  const i18next = useTranslation();

  const kakaoCTA: { label: string; value: KakaoCTA }[] = [
    { label: i18next.t('바로가기'), value: 'LINK' },
    { label: i18next.t('알아보기'), value: 'MORE' },
    { label: i18next.t('예약하기'), value: 'RESERVATION' },
    { label: i18next.t('구매하기'), value: 'PURCHASE' },
    { label: i18next.t('신청하기'), value: 'REQUEST' },
    { label: i18next.t('문의하기'), value: 'INQUIRE' },
    { label: i18next.t('설치하기'), value: 'INSTALL' },
    { label: i18next.t('실행하기'), value: 'RUN' },
    { label: i18next.t('다운로드'), value: 'DOWNLOAD' },
    { label: i18next.t('사용하기'), value: 'LAUNCH' },
    { label: i18next.t('채널추가'), value: 'ADD_FRIEND' },
    { label: i18next.t('구독하기'), value: 'SUBSCRIBE' },
    { label: i18next.t('영상 더보기'), value: 'MORE_VIDEO' },
    { label: i18next.t('가입하기'), value: 'JOIN' },
    { label: i18next.t('쿠폰받기'), value: 'COUPON' },
  ];

  const [selectedCTA, setSelectedCTA] = useState<KakaoCTA>(
    props.setting?.kk_action_button ? props.setting?.kk_action_button : 'LINK',
  );
  const [name, setName] = useState(props.setting?.name);
  const [title, setTitle] = useState(props.setting?.kk_title);
  const [url, setUrl] = useState(props.setting?.url);
  const [biginKeywords, setBiginKeywords] = useState<string[]>(props.setting?.bigin_keywords);
  const [valueLock, setValueLock] = useState<boolean>(false);

  const [currentImageRatioType, setCurrentImageRatioType] = useState<KakaoNativeImageRatioType>('2:1');
  const [showModalImageSelect, setShowModalImageSelect] = useState<boolean>(false);
  const { register, unregister, formState, setValue, trigger } = useForm<KakaoImageNativeForm>({
    mode: 'onChange',
  });

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);

  const selectedImages = useMemo(() => {
    if (props.setting.kk_native_mall_image) {
      return [props.setting.kk_native_mall_image] as ImageTypes[];
    }
    if (props.setting.kk_native_product_image) {
      return [props.setting.kk_native_product_image] as ImageTypes[];
    }
    return [] as ImageTypes[];
  }, [props.setting]);

  useEffect(() => {
    if (selectedImages) {
      if (selectedImages[0] && selectedImages[0].width === selectedImages[0].height) {
        setCurrentImageRatioType('1:1');
      }
    }
  }, []);

  const updateName = (curName: string) => {
    props.handleUpdate({ ...props?.setting, name: curName });
  };

  const updateTitle = (curTitle: string) => {
    props.handleUpdate({ ...props?.setting, kk_title: curTitle });
  };

  const updateUrl = (curUrl: string) => {
    props.handleUpdate({ ...props?.setting, url: curUrl });
  };

  const updateCTA = (curCTA: KakaoCTA) => {
    props.handleUpdate({ ...props?.setting, kk_action_button: curCTA });
  };

  const updateBiginKeywords = (curKeywords: string[]) => {
    props.handleUpdate({ ...props?.setting, bigin_keywords: curKeywords });
  };

  const updateImage = (curImage: ProductImage) => {
    if ('product_id' in curImage) {
      const newTitle = curImage.product_id ? curImage.name : props.setting?.kk_title || '';
      const newUrl = curImage.product_id ? curImage.detail_url : props.setting?.url || '';
      props.handleUpdate({
        ...props?.setting,
        kk_native_product_image: curImage,
        kk_native_product_image_id: curImage.id,
        kk_native_mall_image: undefined,
        kk_native_mall_image_id: undefined,
        kk_title: newTitle,
        url: newUrl,
      });

      setTitle(newTitle);
      setUrl(newUrl);
      setValue('title', newTitle);
      setValue('url', newUrl);
      trigger();
    } else {
      props.handleUpdate({
        ...props?.setting,
        kk_native_mall_image: curImage,
        kk_native_mall_image_id: curImage.id,
        kk_native_product_image: undefined,
        kk_native_product_image_id: undefined,
      });
    }
    setValue('kk_image_id', curImage.id, { shouldValidate: true });
  };

  const handleSelectImages = (images: ImageTypes[]) => {
    updateImage(images[0] as ProductImage);
    setValue('kk_image_id', images[0].id, { shouldValidate: true });
    setShowModalImageSelect(false);
    trigger();
  };
  const updateCurrentRatioType = (ratio: '2:1' | '1:1') => {
    setCurrentImageRatioType(ratio);
    props.handleUpdate({
      ...props?.setting,
      kk_native_mall_image: undefined,
      kk_native_mall_image_id: undefined,
      kk_native_product_image: undefined,
      kk_native_product_image_id: undefined,
    });

    unregister('kk_image_id');
    register('kk_image_id', {
      value: undefined,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    trigger();
  };

  useEffect(() => {
    if (name !== props.setting?.name) {
      updateName(name);
    }

    if (!valueLock) {
      if (title !== props.setting?.kk_title) {
        updateTitle(title);
      }
      if (url !== props.setting?.url) {
        updateUrl(url);
      }
    }
    if (selectedCTA !== props.setting?.kk_action_button) {
      updateCTA(selectedCTA);
    }
    if (!_.isEqual(biginKeywords, props.setting?.bigin_keywords)) {
      updateBiginKeywords(biginKeywords);
    }
    setValueLock(false);
  }, [name, url, title, selectedCTA, biginKeywords]);

  useEffect(() => {
    register('url', {
      value: url,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isURL(value)) {
          return i18next.t('잘못된 URL 형식입니다.') as string;
        }

        return true;
      },
    });
    register('title', {
      value: title,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (value.length > KK_MAX_LENGTH) {
          return i18next.t('{{length}}자 이하로 입력해주세요', { length: KK_MAX_LENGTH }) as string;
        }

        return true;
      },
    });
    register('kk_image_id', {
      value: (props.setting?.kk_native_mall_image || props.setting?.kk_native_product_image)?.id,
      required: i18next.t('필수 입력란입니다.') as string,
    });
  }, []);

  useEffect(() => {
    if (props.handleValid) {
      props.handleValid(props.setting?.type, formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <BorderSection>
      <div className="fb-single-image-setting-wrapper" css={[baseCss]}>
        <AssetHeader
          title={i18next.t('네이티브 디스플레이')}
          icons={[{ imgSrc: '/assets/img/card_icons/ic-channel-kakao.png', alt: 'Kakao' }]}
          handleIconClick={() => {
            props.removeSelectedCampaignTypes(props.setting.type);
          }}
        />
        <div className="create-section-content-wrapper">
          <div className="create-section-content">
            <div className="preview-container">
              <div className="sticky-wrap">
                <div className="title-panel">
                  <div className="title">{i18next.t('미리보기')}</div>
                  <div className="info">{i18next.t('*실제 노출과 미리보기가 다를 수 있습니다.')}</div>
                </div>
                <div className="material-preview-wrapper">
                  <div className="material-preview">
                    <div className="kakao-wrapper">
                      <div className="preview-title">
                        {i18next.t('{{ratio}} 비율 이미지', { ratio: currentImageRatioType })}
                      </div>
                      <PreviewKakaoItem setting={props.setting} ratio={currentImageRatioType} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <h2 className="material-field-header">{i18next.t('광고 소재명')}</h2>
              <div className="material-field-selector-wrapper">
                <BGInput
                  style={{ width: '100%' }}
                  inputProps={{
                    name: 'name',
                    placeholder: i18next.t('광고 소재 이름을 입력해주세요.'),
                    value: name || '',
                    onChange: (event) => {
                      setName(event.target.value);
                    },
                  }}
                />
                <p className="description">{i18next.t('*다른 소재와 구분지을 수 있게 이름을 입력하세요.')}</p>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 이미지')}</h2>
              <div className="material-field-selector-wrapper">
                <div className="content-upload-container">
                  <div className="content-type-selector">
                    <div
                      className={`content-type-item ${
                        currentImageRatioType === '2:1' || !currentImageRatioType ? 'active' : ''
                      }`}
                      onClick={() => {
                        updateCurrentRatioType('2:1');
                        trigger();
                      }}
                    >
                      {i18next.t('2:1 비율')}
                    </div>
                    <div
                      className={`content-type-item ${currentImageRatioType === '1:1' ? 'active' : ''}`}
                      onClick={() => {
                        updateCurrentRatioType('1:1');
                        trigger();
                      }}
                    >
                      {i18next.t('1:1 비율')}
                    </div>
                  </div>
                  {currentImageRatioType === '2:1' && (
                    <div className="single-image-uploader-wrapper uploader-wrapper">
                      <div className="image-uploader-title-wrapper">
                        <div className="image-uploader-subtitle">
                          <div>{i18next.t('* 권장 사이즈: ')} 1200px*600px</div>
                          <div>{i18next.t('* 파일 형식: ')} JPG, JPEG, PNG</div>
                          <div>{i18next.t('* 파일 용량: {{size}}KB 이내', { size: 500 })}</div>
                        </div>
                      </div>
                      <div className="single-image-uploader-wrapper file-wrapper">
                        <BDSButton
                          className="image-updater-btn"
                          onClick={() => {
                            setShowModalImageSelect(true);
                            setValueLock(true);
                          }}
                          label={i18next.t('업로드')}
                        />
                        {props.setting.kk_native_product_image && props.setting.kk_native_product_image.name && (
                          <div className="file-name">{props.setting.kk_native_product_image.name}</div>
                        )}
                        {props.setting.kk_native_mall_image && (
                          <div className="file-name">{i18next.t('상품 정보 없음')}</div>
                        )}
                      </div>
                    </div>
                  )}
                  {currentImageRatioType === '1:1' && (
                    <div className="single-image-uploader-wrapper uploader-wrapper">
                      <div className="image-uploader-title-wrapper">
                        <div className="image-uploader-subtitle">
                          <div>{i18next.t('* 권장 사이즈: ')} 500px*500px</div>
                          <div>{i18next.t('* 파일 형식: ')} JPG, JPEG, PNG</div>
                          <div>{i18next.t('* 파일 용량: {{size}}KB 이내', { size: 500 })}</div>
                          <div>{i18next.t('* 이미지 내 텍스트 사용 불가')}</div>
                        </div>
                      </div>
                      <div className="single-image-uploader-wrapper file-wrapper">
                        <BDSButton
                          className="image-updater-btn"
                          onClick={() => {
                            setShowModalImageSelect(true);
                            setValueLock(true);
                          }}
                          label={i18next.t('업로드')}
                        />
                        {props.setting.kk_native_product_image && props.setting.kk_native_product_image.name && (
                          <div className="file-name">{props.setting.kk_native_product_image.name}</div>
                        )}
                        {props.setting.kk_native_mall_image && (
                          <div className="file-name">{i18next.t('상품 정보 없음')}</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 텍스트')}</h2>

              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 제목')} checkboxLabel="" value hiddenCheckbox />
                <BGInput
                  style={{ width: '100%', marginTop: '8px' }}
                  error={!!formState.errors.title}
                  inputProps={{
                    name: 'title',
                    value: title || '',
                    placeholder: i18next.t('광고 제목을 입력해주세요.'),
                    onChange: (event) => {
                      setTitle(event.target.value);
                      setValue('title', event.target.value, { shouldValidate: true });
                    },
                  }}
                  customLength={title.length}
                  customLengthLimit={25}
                  lengthUnit={i18next.t('자')}
                />
                {formState.errors.title && <InputError message={formState.errors.title.message as string} />}
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('행동 유도 버튼')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <BDSSelectBox
                  appearance="gray"
                  style={{ width: '100%', marginTop: '8px' }}
                  list={kakaoCTA}
                  defaultValue={props.setting?.kk_action_button}
                  displayValue={(item: any) => {
                    return item.label;
                  }}
                  isSelected={(item: any) => {
                    return selectedCTA === item.value;
                  }}
                  handleUpdate={(item: any) => {
                    setSelectedCTA(item.value);
                  }}
                />
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('랜딩 URL')} checkboxLabel="" value hiddenCheckbox />
                <BGUrlSelector
                  linkButton
                  validationTrackOn
                  isValid={!formState.errors.url}
                  url={url || ''}
                  handleUpdate={(curUrl: string) => {
                    setUrl(curUrl);
                    setValue('url', curUrl, { shouldValidate: true });
                  }}
                  style={{ marginTop: '8px' }}
                  placeholder="www.bigin.io"
                />
                {formState.errors.url && <InputError message={formState.errors.url.message as string} />}
              </div>
              {campaignState.isBiginConnected && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('연계 캠페인')}</h2>
                  <div className="material-field-selector-wrapper">
                    <MaterialCheckboxSelector
                      contentTitle={i18next.t('핵심 키워드')}
                      checkboxLabel=""
                      value
                      hiddenCheckbox
                    />
                    <BGTagInput
                      style={{ width: '100%', marginTop: '8px' }}
                      inputProps={{
                        name: 'bigin_keywords',
                        placeholder: i18next.t(
                          'Bigin CRM에서 해당 광고 소재를 검색할 때 사용할 키워드를 입력해주세요.',
                        ),
                      }}
                      handleUpdate={(keywords) => {
                        setBiginKeywords(keywords);
                      }}
                      defaultTags={biginKeywords}
                      duplicateText={i18next.t('* 이미 등록된 키워드입니다.')}
                    />
                    <p className="description">
                      {i18next.t('*핵심 키워드는 선택사항이며, 최대 5개까지 입력할 수 있습니다.')}
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModalImageSelect && currentImageRatioType && (
        <ModalImageSelect
          selectType="single"
          selectedImages={selectedImages}
          campaignType={props.setting.type}
          isOpen={showModalImageSelect}
          ratio={currentImageRatioType}
          close={() => setShowModalImageSelect(false)}
          setSelectedImages={handleSelectImages}
        />
      )}
    </BorderSection>
  );
};
