import { css } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = () => {
  return css`
    width: 100%;
    min-width: fit-content;

    * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: ${GS.gutter}px;
    }

    .create-campaign-container {
      font-stretch: normal;
      font-style: normal;
      width: 100%;
      display: flex;
      position: relative;

      .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }
  `;
};

export const leftWrapper = css`
  width: calc(100% - 280px);
  min-width: ${GS.contentMinWidth}px;
  max-width: ${GS.contentMaxWidth}px;

  &.step2 {
    min-width: ${GS.contentMaxWidth}px;
  }
`;
export const rightWrapper = css`
  width: 260px;
  margin-left: 20px;
  position: relative;
`;

export const createCampaignModalCss = css`
  .horizontal-divider {
    width: 100%;
    height: 1px;
    margin: 12px 0;
    background-color: #dce0e8;
  }
  .create-campaign-header {
    margin-bottom: 8px;
  }
  .create-campaign-body {
    margin-bottom: 16px;

    .field-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }
  }
  .create-campaign-footer {
    //
  }
`;
