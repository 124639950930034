import React, { ReactElement, useState, useEffect, ChangeEvent, FocusEvent, useMemo, useRef } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Theme, useTheme } from '@emotion/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Range } from 'rc-slider';
import {
  BDSCheckbox,
  BDSRadioButton,
  BDSButton,
  BDSCalendar,
  BDSFontIcon,
  BDSToggleButton,
} from '@bigin/bigin-ui-components';
import moment from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import { useMatch, useNavigate } from 'react-router-dom';
import { BorderSection } from '../../../components/BorderSection/BorderSection';
import { BGTab, ITab } from '../../../components/BGTab/BGTab';
import { baseCss } from './Step1CampaignSetting.style';
import 'rc-slider/assets/index.css';
import { BGTooltip } from '../../../components/BGTooltip/BGTooltip';
import { DialogConfirm } from '../../../components/DialogConfirm/DialogConfirm';
import { WrapperModal } from '../../../components/WrapperModal/WrapperModal';
import CampaignSetting, {
  ageRangeSelector,
  campaignBudgetSelector,
  campaignEndDateSelector,
  campaignGoalSelector,
  campaignInvolvementsSelector,
  campaignIsBiginConnectedSelector,
  campaignRevisitOptimizeSelector,
  campaignStartDateSelector,
  campaignStartOptionSelector,
  campaignTargetExcludeSelector,
  campaignTypeResetSelector,
  campaignTypeSelector,
  targetGenderSelector,
} from '../../../recoil/CampaignSetting';
import { filters } from '../../../utils/filter';
import { getUniqKey } from '../../../utils/array.utils';
import StorageService from '../../../services/StorageService';
import { MallApi } from '../../../lib/api/MallApi';
import { FbAssetModal } from '../../../components/FbAssetModal/FbAssetModal';
import { BGInput } from '../../../components/BGInput/BGInput';
import * as Campaign from '../../../constants/campaigns';
import { LogoUploadModal } from '../../../components/LogoUploadModal/LogoUploadModal';
import Plan from '../../../services/plans/Plan';
import { InputError } from '../../../components/InputError/InputError';
import { LinkedBiginCrmModal } from '../../../components/LinkedBiginCrmModal/LinkedBiginCrmModal';
import { needBiginSdkCheck } from '../../../utils/utils';
import { CurrencyCodeRecoil, showBudgetValueRecoil } from '../../../recoil/Currency';
import { multilingual } from '../../../utils/multilingual.util';

interface CampaignGoalTypes {
  key: number;
  goal: CampaignGoal;
  title: string;
  description: string;
  isDisabled: boolean;
}

export const Step1CampaignSetting = (): ReactElement => {
  const theme: Theme = useTheme();
  const { i18n } = useTranslation();
  const i18next = useTranslation();
  const navigate = useNavigate();
  const copyMatch = useMatch('/malls/:mallId/copy-campaign');

  const isManager = useMemo(() => StorageService.user.type === 'manager', [StorageService.user.type]);
  const queryClient = useQueryClient();
  const [planUpdateModal, setPlanUpdateModal] = useState<string>('');
  const [showModalSDKInstall, setShowModalSDKInstall] = useState<boolean>(false);
  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);
  const [selectedGoal, handleSelectedGoal] = useRecoilState(campaignGoalSelector);
  const [selectedGenders, handleSelectedGenders] = useRecoilState(targetGenderSelector);
  const [selectedAgeRange, handleSelectedAgeRange] = useRecoilState(ageRangeSelector);
  const [selectedCampaignTypes, handleCamapignTypes] = useRecoilState(campaignTypeSelector);
  const [campaignBudget, updateCampaignBudget] = useRecoilState(campaignBudgetSelector);
  const [selectedStartOption, updateStartOption] = useRecoilState(campaignStartOptionSelector);
  const [selectedTargetExclude, updateTargetExclude] = useRecoilState(campaignTargetExcludeSelector);
  const [selectedRevisitOptimize, updateRevisitOptimize] = useRecoilState(campaignRevisitOptimizeSelector);
  const [selectedInvolvements, handleSelectedInvolvements] = useRecoilState(campaignInvolvementsSelector);
  const [campaignStartDate, updateCampaignStartDate] = useRecoilState(campaignStartDateSelector);
  const [campaignEndDate, updateCampaignEndDate] = useRecoilState(campaignEndDateSelector);
  const [showBudgetValue, setShowBudgetValue] = useRecoilState(showBudgetValueRecoil);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);
  const [fbAssetModal, setFbAssetModal] = useState<boolean>(false);
  const [logoUploadModal, setLogoUploadModal] = useState<boolean>(false);
  const [selectedMedia, setSelectedMedia] = useState<string | undefined>();
  const [userClickedType, setUserClickedType] = useState<boolean>(false);
  const [linkedBiginCrmModal, setLinkedBiginCrmModal] = useState<boolean>(false);

  const [showDateSetting, setShowDateSetting] = useState<boolean>(false);
  const [showLinkedCampaign, setShowLinkedCampaign] = useState<boolean>(false);
  const [isBiginConnected, updateIsBiginConnected] = useRecoilState(campaignIsBiginConnectedSelector);
  const resetCampaignTypes = useSetRecoilState(campaignTypeResetSelector);

  const dateDiv: React.Ref<HTMLDivElement> = useRef(null);
  const linkedDiv: React.Ref<HTMLDivElement> = useRef(null);

  const { data: activeSubscription } = useQuery<Subscription | undefined>('activeSubscription', async () => {
    const res = await MallApi().getMall(StorageService.selectedMall.id, { includes: ['active_subscription'] });

    return res.data.active_subscription;
  });

  const { data: mall, refetch: refetchMall } = useQuery<Mall>(
    'mall',
    async () => (await MallApi().getMall(StorageService.selectedMall.id, { includes: ['medias'] })).data,
  );

  const hasMediaType = (medias: MallMedia[], type: MediaAssetType): boolean => {
    return medias.findIndex((mallMedia: MallMedia) => mallMedia.type === type) > -1;
  };

  const isFreePlan = useMemo(
    () => Plan.isFreeType(activeSubscription?.type ?? 'smartstore'),
    [activeSubscription?.type, StorageService.selectedMall.id],
  );

  const { data: gtmData } = useQuery<GtmData>(
    'gtmData',
    async () => (await MallApi().getGtmCode(StorageService.selectedMall.id)).data.data,
    { refetchOnMount: 'always' },
  );

  const needBiginSdk = useMemo(() => {
    if (StorageService.activePlan && gtmData) {
      return needBiginSdkCheck(StorageService.activePlan, gtmData);
    }
    return true;
  }, [StorageService.activePlan, gtmData]);

  const hasBiginProject = useMemo(
    () => hasMediaType(mall?.medias || [], 'bigin-project'),
    [mall?.medias, StorageService.selectedMall.id],
  );

  /**
   * 판매자 센터 연결 여부 true -> 연결, false -> 연결 x
   */
  const hasMerchantCenter = useMemo((): boolean => {
    if (Array.isArray(mall?.medias) && mall?.medias.find((media) => media.type === 'merchant')) {
      return true;
    }
    return false;
  }, [mall?.medias, StorageService.selectedMall.id]);

  useEffect(() => {
    if (hasBiginProject) {
      updateIsBiginConnected(true);
    }
  }, [hasBiginProject]);

  const { register, formState, setValue, trigger, resetField } = useForm<{ budget: number }>({
    mode: 'onChange',
  });

  const closeModal = () => {
    setShowModalSDKInstall(false);
    setPlanUpdateModal('');
  };

  const campaignGoals: CampaignGoalTypes[] = [
    {
      key: 1,
      goal: 'maximize_clicks' as CampaignGoal,
      title: i18next.t('유입수 극대화'),
      description: i18next.t(
        '초기 쇼핑몰 또는 지속적인 브랜딩을 위해 추천드립니다. 적은 비용으로 많은 잠재 고객을 모집할 수 있습니다.',
      ),
      isDisabled: false,
    },
    {
      key: 2,
      goal: 'revisit_leads' as CampaignGoal,
      title: i18next.t('재방문 유도'),
      description: i18next.t(
        '기존 쇼핑몰 방문자에게 신규 상품 출시, 프로모션 기간 등 새롭게 업데이트 된 내용을 홍보하여 재방문을 유도합니다.',
      ),
      isDisabled: needBiginSdk ?? true,
    },
    {
      key: 3,
      goal: 'buying_acquisition' as CampaignGoal,
      title: i18next.t('유효 타겟 확장'),
      description: i18next.t(
        '쇼핑몰 방문자를 대상으로 첫 구매를 유도하거나, 그들과 특성이 유사한 타겟을 목표로 캠페인을 진행합니다.',
      ),
      isDisabled: !!isFreePlan,
    },
    {
      key: 4,
      goal: 'purchase_leads' as CampaignGoal,
      title: i18next.t('구매 활성화'),
      description: i18next.t(
        '제품에 관심을 보인 방문자에게 구매를 유도하거나, 구매자에게 재구매를 유도하는 캠페인을 진행합니다.',
      ),
      isDisabled: !!isFreePlan,
    },
  ];

  const campaignAgeRange = {
    0: { label: i18next.t('18세'), style: { fontSize: 12 } },
    20: { label: i18next.t('25세'), style: { fontSize: 12 } },
    40: { label: i18next.t('35세'), style: { fontSize: 12 } },
    60: { label: i18next.t('45세'), style: { fontSize: 12 } },
    80: { label: i18next.t('55세'), style: { fontSize: 12 } },
    100: { label: i18next.t('65세'), style: { fontSize: 12, width: 30 } },
  };

  const campaignTypeTabs = [
    {
      key: 'all',
      label: i18next.t('전체보기'),
    },
    {
      key: 'facebook',
      label: 'Facebook/Instagram',
    },
    {
      key: 'google',
      label: 'Google/Youtube',
    },
    {
      key: 'kakao',
      label: 'Kakao',
    },
  ];

  interface CampaignTypeItem {
    media: string;
    type: string;
    title: string;
    description: string;
    badges?: string[];
  }

  const remaketingTypes = ['fb_dynamic', 'aw_dynamic_remarketing'];

  const campaignTypes: Array<CampaignTypeItem> = [
    {
      media: 'facebook',
      type: 'fb_single_image',
      title: i18next.t('단일 이미지'),
      description: i18next.t('하나의 고품질 이미지를 활용하여 집중도 있는 캠페인을 진행합니다.'),
    },
    {
      media: 'facebook',
      type: 'fb_slide_image',
      title: i18next.t('슬라이드 이미지'),
      description: i18next.t('여러 장의 이미지와 링크를 통해 최대 10개의 제품을 노출할 수 있습니다.'),
    },
    {
      media: 'facebook',
      type: 'fb_single_video',
      title: i18next.t('단일 동영상'),
      description: i18next.t('동영상을 활용하여 제품의 고유한 기능 또는 브랜드 스토리를 전달할 수 있습니다.'),
    },
    {
      media: 'facebook',
      type: 'fb_collection_image',
      title: i18next.t('컬렉션 광고'),
      description: i18next.t('선택한 카탈로그의 제품을 큰 광고 이미지와 함께 작은 이미지로 보여줄 수 있습니다.'),
    },
    {
      media: 'facebook',
      type: 'fb_catalog_slide',
      title: i18next.t('카탈로그 슬라이드'),
      description: i18next.t('선택한 카탈로그의 제품을 슬라이드 형태로 보여줄 수 있습니다.'),
    },
    {
      media: 'facebook',
      type: 'fb_dynamic',
      title: i18next.t('다이나믹 광고'),
      description: i18next.t('고객이 광고에 반응하는 정도를 기반으로 가장 효과가 좋은 광고를 노출합니다.'),
    },
    {
      media: 'google',
      type: 'aw_responsive_image',
      title: i18next.t('반응형 이미지'),
      description: i18next.t('광고가 노출되는 지면 형식에 따라 가장 적합한 형태로 자동 조합되어 노출됩니다.'),
    },
    {
      media: 'google',
      type: 'aw_dynamic_remarketing',
      title: i18next.t('동적 리마케팅'),
      description: i18next.t('자사몰에 방문한 고객을 대상으로 고객이 조회한 제품이 포함된 광고가 노출됩니다.'),
    },
    // {
    //   media: 'google',
    //   type: 'aw_shopping',
    //   title: i18next.t('쇼핑 광고'),
    //   description: i18next.t('쇼핑몰 제품 정보 활용하여 구글 검색 또는 디스플레이 네트워크에 광고를 노출합니다.'),
    // },
    {
      media: 'google',
      type: 'aw_pmax',
      title: i18next.t('실적 최대화'),
      description: i18next.t('성과를 극대화하기 위해 구글 검색, 유튜브 등 모든 지면에 다이나믹 광고 등을 노출합니다.'),
    },
    {
      media: 'google',
      type: 'aw_search',
      title: i18next.t('구글 검색'),
      description: i18next.t('검색 키워드 기반으로 전환율 높은 구글 검색광고와 디스플레이 광고지면에 노출합니다.'),
    },
    {
      media: 'google',
      type: 'aw_youtube',
      title: i18next.t('Youtube 광고'),
      description: i18next.t('Youtube 영상 시청 전, 집중도 높은 시점에 광고 영상을 노출합니다.'),
    },
    {
      media: 'kakao',
      type: 'kk_image_banner',
      title: i18next.t('비즈보드'),
      description: i18next.t('카카오톡 채팅리스트 최상단에 고정된 배너로 브랜딩을 위한 캠페인을 진행합니다.'),
    },
    {
      media: 'kakao',
      type: 'kk_image_native',
      title: i18next.t('네이티브 디스플레이'),
      description: i18next.t('카카오 핵심 서비스와 주요 파트너 서비스를 중심으로 다양한 지면에 광고를 보여줍니다.'),
    },
  ];

  const [selectedTypeTab, setSelectedTypeTab] = useState<ITab>(campaignTypeTabs[0]);

  /**
   * 일 예산 최소값
   * 한국의 경우 카카오 30000, 다른 일반적인 것은 10000원으로 측정되어진다.
   * 싱가포르는 10SGD로 최소 값을 잡는다.
   */
  const minBudget = useMemo(() => {
    const generalTypeMinimumBudget = multilingual.getMinimumBudgetByCurrency({
      currency: currencyCode,
    });
    return selectedCampaignTypes.length * generalTypeMinimumBudget;
  }, [selectedCampaignTypes]);

  const handleBudgetChange = (e: ChangeEvent) => {
    const targetValue = (e.target as HTMLInputElement).value.replace(/[^0-9.]/gi, '').replace(/(\..*)\./gi, '$1');

    switch (currencyCode) {
      case 'SGD': {
        const decimalPlaceLength = multilingual.getDecimalPlaceLength({ currencyCode });
        const value = parseFloat(parseFloat(targetValue.replaceAll(',', '')).toFixed(decimalPlaceLength));
        updateCampaignBudget(Number.isNaN(value) ? 0 : value);
        setValue('budget', Number.isNaN(value) ? 0 : value, { shouldValidate: true });

        const regex = /^[\d]*\.?[\d]{0,2}$/g; // 소수점 2자리까지만
        if (regex.test(targetValue.replaceAll(',', ''))) {
          setShowBudgetValue(Number.isNaN(value) ? '' : targetValue.replaceAll(',', ''));
        }
        break;
      }
      default:
      case 'KRW': {
        const value = parseInt(targetValue.replaceAll(',', ''), 10);

        updateCampaignBudget(Number.isNaN(value) ? 0 : value);
        setValue('budget', Number.isNaN(value) ? 0 : value, { shouldValidate: true });
        setShowBudgetValue(Number.isNaN(value) ? '' : value.toString());
        break;
      }
    }
  };

  const handleMinimumBudget = (e: FocusEvent) => {
    const target = e.target as HTMLInputElement;
    const value = parseFloat(
      target.value
        .replace(/[^0-9.]/gi, '')
        .replace(/(\..*)\./gi, '$1')
        .replaceAll(',', ''),
    );

    if (value < minBudget) {
      updateCampaignBudget(minBudget);
      setValue('budget', minBudget);
      setShowBudgetValue(minBudget.toString());
      trigger();
    }
  };

  const formattedBudget = () => {
    return filters.formatStringComma(showBudgetValue);
  };

  useEffect(() => {
    if (campaignState.budget > 0) {
      resetField('budget');
      register('budget', {
        required: (i18next.t('일 예산을 입력해주세요.') as string) || '',
        min: {
          value: campaignState.budget,
          message:
            (i18next.t('캠페인 유형 선택에 따른 최소 일 예산은 {{minBudget}} 입니다.', {
              minBudget: filters.formatCurrency({ value: minBudget, currencyCode }),
            }) as string) || '',
        },
      });
    }
  }, []);

  useEffect(() => {
    if (userClickedType || copyMatch) {
      updateCampaignBudget(minBudget);
      setShowBudgetValue(minBudget.toString());

      resetField('budget');
      register('budget', {
        required: (i18next.t('일 예산을 입력해주세요.') as string) || '',
        min: {
          value: minBudget,
          message:
            (i18next.t('캠페인 유형 선택에 따른 최소 일 예산은 {{minBudget}} 입니다.', {
              minBudget: filters.formatCurrency({ value: minBudget, currencyCode }),
            }) as string) || '',
        },
      });
    }
  }, [selectedCampaignTypes]);

  const playVideo = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    try {
      const playPromise = event.currentTarget.getElementsByTagName('video')[0].play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            return null;
          })
          .catch(() => {
            return null;
          });
      }
    } catch (e) {
      return false;
    }
    return false;
  };

  const pauseVideo = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    try {
      const targetVideo = event.currentTarget.getElementsByTagName('video')[0];
      const playPromise = targetVideo.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            targetVideo.pause();
            targetVideo.currentTime = 0;
          })
          .catch(() => {
            return null;
          });
      }
    } catch (e) {
      return false;
    }
    return false;
  };

  const putLineFeedForEn = (text: string): string => {
    if (i18n.language === 'en') {
      return text.replace(/\(/g, '\n(');
    }
    return text;
  };

  const detailTargetRenderer = (goal: CampaignGoal) => {
    let detailTarget;

    if (goal === 'buying_acquisition') {
      detailTarget = (
        <div className="target-detail-wrapper">
          <div className="text-h5 text-color-main left-wrapper">{i18next.t('상세 타겟')}</div>
          <div className="radiobutton-group-wrapper right-wrapper">
            <BDSRadioButton
              style={{ color: '#3d4046' }}
              labelText={i18next.t('최근 1개월 구매자 제외')}
              inputProps={{
                id: 'exclude_purchase_30days',
                name: 'targetExclude',
                checked: selectedTargetExclude === Campaign.TargetExclude.PURCHASE_30DAYS,
                onChange: () => {
                  updateTargetExclude(Campaign.TargetExclude.PURCHASE_30DAYS);
                },
              }}
            />
            <BDSRadioButton
              style={{ color: '#3d4046' }}
              labelText={i18next.t('최근 6개월 구매자 제외')}
              inputProps={{
                id: 'exclude_purchase_180days',
                name: 'targetExclude',
                checked: selectedTargetExclude === Campaign.TargetExclude.PURCHASE_180DAYS,
                onChange: () => {
                  updateTargetExclude(Campaign.TargetExclude.PURCHASE_180DAYS);
                },
              }}
            />
            <BDSRadioButton
              style={{ color: '#3d4046' }}
              labelText={i18next.t('최근 6개월 방문자 제외')}
              inputProps={{
                id: 'exclude_page_view_180days',
                name: 'targetExclude',
                checked: selectedTargetExclude === Campaign.TargetExclude.PAGE_VIEW_180DAYS,
                onChange: () => {
                  updateTargetExclude(Campaign.TargetExclude.PAGE_VIEW_180DAYS);
                },
              }}
            />

            <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <BDSRadioButton
                style={{ color: '#3d4046' }}
                labelText={i18next.t('제외 없음')}
                inputProps={{
                  id: 'exclude_none',
                  name: 'targetExclude',
                  checked: selectedTargetExclude === null,
                  onChange: () => {
                    updateTargetExclude(null);
                  },
                }}
              />
              <BGTooltip
                key="detail_tooltip_1"
                title={
                  (i18next.t(
                    '예산이 부족하거나 소재 도달력이 약한 경우, 기존 방문 고객에게 광고 노출이 제한될 수 있습니다.',
                  ) as string) || ''
                }
                placement="top"
              >
                <div style={{ height: 20 }}>
                  <BDSFontIcon name="ic-info" color="#c9cfda" size="20px" />
                </div>
              </BGTooltip>
            </div>
          </div>
        </div>
      );
    } else if (goal === 'revisit_leads') {
      detailTarget = (
        <div className="target-detail-wrapper">
          <div className="text-h5 text-color-main left-wrapper">{i18next.t('상세 타겟')}</div>
          <div className="radiobutton-group-wrapper right-wrapper">
            <BDSRadioButton
              style={{ color: '#3d4046' }}
              labelText={i18next.t('상품 조회 등 관심 유도 목적')}
              inputProps={{
                id: 'revisit_view_content_or_interest',
                name: 'revisitOptimize',
                checked: selectedRevisitOptimize === Campaign.RevisitOptimize.VIEW_CONTENT_OR_INTEREST,
                onChange: () => {
                  updateRevisitOptimize(Campaign.RevisitOptimize.VIEW_CONTENT_OR_INTEREST);
                },
              }}
            />
            <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
              <BDSRadioButton
                style={{ color: '#3d4046' }}
                labelText={i18next.t('구매 전환 목적')}
                inputProps={{
                  id: 'revisit_purchase',
                  name: 'revisitOptimize',
                  checked: selectedRevisitOptimize === Campaign.RevisitOptimize.PURCHASE,
                  onChange: () => {
                    updateRevisitOptimize(Campaign.RevisitOptimize.PURCHASE);
                  },
                }}
              />
              <BGTooltip
                key="detail_tooltip_1"
                title={(i18next.t('구매 건 수가 충분하지 않은 경우 광고 노출이 제한될 수 있습니다.') as string) || ''}
                placement="top"
              >
                <div style={{ height: 20 }}>
                  <BDSFontIcon name="ic-info" color="#c9cfda" size="20px" />
                </div>
              </BGTooltip>
            </div>
          </div>
        </div>
      );
    } else if (goal === 'purchase_leads') {
      detailTarget = (
        <div className="target-detail-wrapper">
          <div className="text-h5 text-color-main left-wrapper">{i18next.t('상세 타겟')}</div>
          <div className="checkbox-group-wrapper right-wrapper">
            <div
              className="checkbox-wrapper"
              onClick={() => {
                const involvement = 'low' as InvolvementType;
                handleSelectedInvolvements(involvement);
              }}
            >
              <BDSCheckbox checked={selectedInvolvements.includes('low' as InvolvementType)} size={20} />
              <span className="label">{putLineFeedForEn(i18next.t('저관여 고객(상세페이지 조회)'))}</span>
            </div>
            <div
              className="checkbox-wrapper"
              onClick={() => {
                const involvement = 'high' as InvolvementType;
                handleSelectedInvolvements(involvement);
              }}
            >
              <BDSCheckbox checked={selectedInvolvements.includes('high' as InvolvementType)} size={20} />
              <span className="label">{putLineFeedForEn(i18next.t('고관여 고객(장바구니 담기, 구매 시도)'))}</span>
            </div>
            <div
              className="checkbox-wrapper"
              onClick={() => {
                const involvement = 'purchased' as InvolvementType;
                handleSelectedInvolvements(involvement);
              }}
            >
              <BDSCheckbox checked={selectedInvolvements.includes('purchased' as InvolvementType)} size={20} />
              <span className="label">{i18next.t('구매 고객')}</span>
            </div>
          </div>
        </div>
      );
    }
    return detailTarget;
  };

  // const isPossibleCampaign = (media: string) => {
  //   if (mall) {
  //     if (media.includes('fb_') && hasMediaType(mall.medias, 'fb-page')) return true;
  //     if (media.includes('aw_') && hasMediaType(mall.medias, 'aw-client-account')) return true;
  //   }
  //   return false;
  // };

  const hasBadgeType = (media: string) => {
    const badgeType = [];
    if (mall) {
      if (media.includes('fb_') && !hasMediaType(mall.medias, 'fb-page')) badgeType.push('needAccountFb');
      if (media.includes('kk_') && mall && !mall.square_logo_url) badgeType.push('needLogo');
      if (media.includes('aw_') && mall && (!mall.square_logo_url || !mall.landscape_logo_url))
        badgeType.push('needLogo');
      /* 실적 최대화 캠페인의 경우 Google Ads에 연결된 Merchant center 연결 여부 확인 되어야 생성이 가능하다.  */
      if (media.includes('aw_pmax') && mall && hasMerchantCenter === false) badgeType.push('needLinkGMC');
      if (
        isFreePlan &&
        [
          'fb_collection_image',
          'fb_catalog_slide',
          'fb_dynamic',
          'aw_dynamic_remarketing',
          'aw_shopping',
          'aw_smart_shopping',
          'aw_youtube',
          'aw_pmax',
          'aw_search',
        ].includes(media)
      ) {
        badgeType.push('needPlanUpdate');
      }
      /* 타겟팅 국가에 따라 지원하는 매체 Enable, 지원안하는건 Disabled 처리 */
      switch (currencyCode) {
        case 'SGD': {
          // 싱가폴 -> 카카오 Disabled
          if (media.includes('kk_')) badgeType.push('disabled');
          break;
        }
        case 'KRW':
        default:
      }
    }
    return badgeType;
  };

  const filteredCampaignTypes = (media: string) => {
    let filteredTypes = campaignTypes;
    filteredTypes = filteredTypes.map((item) => {
      return {
        ...item,
        badges: hasBadgeType(item.type),
      };
    });

    const upgradeTypes = filteredTypes
      .map((item) => {
        if (item.badges?.includes('needPlanUpdate')) return item;
        return null;
      })
      .filter((item) => item !== null);
    const warningTypes = filteredTypes
      .map((item) => {
        if (
          item.badges &&
          item.badges?.length > 0 &&
          !item.badges?.includes('needPlanUpdate') &&
          !item.badges?.includes('disabled')
        )
          return item;
        return null;
      })
      .filter((item) => item !== null);
    const activeTypes = filteredTypes
      .map((item) => {
        if (item.badges && item.badges.length === 0) return item;
        return null;
      })
      .filter((item) => item !== null);
    const disabledTypes = filteredTypes
      .map((item) => {
        if (
          item.badges &&
          item.badges?.length > 0 &&
          !item.badges?.includes('needPlanUpdate') &&
          item.badges?.includes('disabled')
        )
          return item;
        return null;
      })
      .filter((item) => item !== null);

    filteredTypes = [...activeTypes, ...warningTypes, ...upgradeTypes, ...disabledTypes].flatMap((f) => (f ? [f] : []));

    if (media !== 'all') {
      // filteredTypes = campaignTypes.filter((type) => type.media === media);
      filteredTypes = filteredTypes.filter((type) => type.media === media);
    }
    return filteredTypes;
  };

  // eslint-disable-next-line react/require-default-props
  const CampaignTypeLabel = (props: { lock: boolean; needLinkGMC: boolean }): ReactElement => {
    const { lock, needLinkGMC } = props;

    if (lock) {
      return (
        <div className={`label-wrapper `}>
          <img className="label-icon" src="/assets/img/common/icons-ic-lock-reverse.svg" alt="lock" />
          <span className={`label `}>
            <span>{i18next.t('플랜 업그레이드 필요')}</span>
          </span>
        </div>
      );
    }
    if (needLinkGMC) {
      return (
        <div className={`label-wrapper `}>
          <img className="label-icon" src="/assets/img/common/icons-ic-lock-reverse.svg" alt="lock" />
          <span className={`label `}>
            <span>{i18next.t('판매자 센터 연결 필요')}</span>
          </span>
        </div>
      );
    }
    return (
      <div className={`label-wrapper warning `}>
        <BDSFontIcon name="ic-warning" size="20px" />
      </div>
    );
  };

  const campaignTargetNationLabel = useMemo(() => {
    return multilingual.getTargetNationLabel({ currencyCode });
  }, [currencyCode]);

  const gtmModalBtns = (): { label: string; handleClick?: () => void; appearance?: string; disabled?: boolean }[] => {
    return [
      { label: i18next.t('취소'), appearance: 'secondary', handleClick: closeModal },
      {
        label: i18next.t('스크립트 직접 설치'),
        appearance: 'secondary',
        handleClick: () => {
          closeModal();
          navigate(`/malls/${StorageService.selectedMall.id}/accounts?tab=bigin-sdk`);
        },
      },
      {
        label: i18next.t('플랜 업그레이드'),
        handleClick: () => {
          closeModal();
          navigate(`/malls/${StorageService.selectedMall.id}/plan`);
        },
      },
    ];
  };

  return (
    <React.Fragment>
      <div css={[baseCss]} className="create-campaign-container" style={{ marginTop: copyMatch ? 0 : 28 }}>
        {activeSubscription && activeSubscription?.type !== 'smartstore' && (
          <div className="campaign-goal-container">
            <BorderSection>
              <div className="border-section-header-wrapper">
                <div className="title-wrapper">
                  <div className="title">{i18next.t('캠페인 목표 설정')}</div>
                </div>
                <div className="description-wrapper">
                  <div className="description">{i18next.t('캠페인을 통해 이루고 싶은 것이 무엇인가요?')}</div>
                </div>
              </div>
              <div className="campaign-goal-selector-wrapper">
                {campaignGoals.map((goal) => (
                  <div
                    key={getUniqKey('campaign-goal', `icon-${goal.goal}`)}
                    className={`campaign-goal-box ${goal.goal === selectedGoal ? 'active' : ''} ${
                      goal.isDisabled ? 'disabled' : ''
                    }`}
                    onClick={() => {
                      if (goal.isDisabled) {
                        if (['buying_acquisition', 'purchase_leads'].includes(goal.goal)) {
                          setPlanUpdateModal('goal');
                        } else {
                          setShowModalSDKInstall(true);
                        }
                        return;
                      }
                      handleSelectedGoal(goal.goal);
                      if (goal.goal !== selectedGoal && !copyMatch) {
                        resetCampaignTypes([]);
                      }
                    }}
                  >
                    <div className="infographic-wrapper">
                      <img
                        src={`/assets/img/campaign/taggers-campaign-icon-0${goal.key}.svg`}
                        className="icon"
                        alt={goal.title}
                      />
                      {goal.goal === selectedGoal && <span className="badge active">{i18next.t('선택됨')}</span>}
                      {!(goal.goal === selectedGoal) && goal.isDisabled && (
                        <div className="label-wrapper">
                          <img className="label-icon" src="/assets/img/common/icons-ic-lock-reverse.svg" alt="lock" />
                          <span className="label">
                            {['buying_acquisition', 'purchase_leads'].includes(goal.goal) ? (
                              <span>{i18next.t('플랜 업그레이드 필요')}</span>
                            ) : (
                              <span>{i18next.t('Bigin SDK 설치 필요')}</span>
                            )}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="text-h4 text-color-main">{goal.title}</div>
                    <div className="text-input text-color-main">{goal.description}</div>
                  </div>
                ))}
              </div>
            </BorderSection>
          </div>
        )}
        {activeSubscription && ['brandmall'].includes(activeSubscription?.type as string) && (
          <div className="plan-update-container">
            <div className="typography-wrapper">
              <div className="text-h3 text-color-main">
                {i18next.t('플랜 업그레이드를 통해 더욱 다양한 기능을 활용해보세요.')}
              </div>
              <div className="text-input text-color-main">
                {i18next.t('플랜을 업그레이드하시면 다양한 캠페인 목표와 더 많은 캠페인 유형을 활용할 수 있습니다.')}
              </div>
            </div>
            <BDSButton
              label={i18next.t('플랜 업그레이드 하러가기')}
              onClick={() => {
                navigate(`/malls/${StorageService.selectedMall.id}/plan`);
              }}
            />
          </div>
        )}
        <div className="campaign-target-container">
          <BorderSection>
            <div className="border-section-header-wrapper">
              <div className="title-wrapper">
                <div className="title">{i18next.t('캠페인 타겟 설정')}</div>
              </div>
              <div className="description-wrapper">
                <div className="description">{i18next.t('누구에게 캠페인을 진행하고 싶으신가요?')}</div>
              </div>
            </div>
            <div className="campaign-target-selector-wrapper">
              {detailTargetRenderer(selectedGoal)}
              <div className="target-gender-wrapper target-item-wrapper">
                <div className="text-h5 text-color-main left-wrapper">{i18next.t('성별')}</div>
                <div className="checkbox-group-wrapper right-wrapper">
                  <div
                    className="checkbox-wrapper"
                    onClick={() => {
                      const gender = 'women' as GenderType;
                      handleSelectedGenders(gender);
                    }}
                  >
                    <BDSCheckbox checked={selectedGenders.includes('women' as GenderType)} size={20} />
                    <span className="label">{i18next.t('여성')}</span>
                  </div>
                  <div
                    className="checkbox-wrapper"
                    onClick={() => {
                      const gender = 'men' as GenderType;
                      handleSelectedGenders(gender);
                    }}
                  >
                    <BDSCheckbox checked={selectedGenders.includes('men' as GenderType)} size={20} />
                    <span className="label">{i18next.t('남성')}</span>
                  </div>
                </div>
              </div>
              <div className="target-age-wrapper target-item-wrapper">
                <div className="text-h5 text-color-main left-wrapper">{i18next.t('연령대')}</div>
                <div className="slider-wrapper right-wrapper">
                  <Range
                    marks={campaignAgeRange}
                    step={20}
                    allowCross={false}
                    pushable={20}
                    defaultValue={selectedAgeRange}
                    onChange={(value) => {
                      handleSelectedAgeRange(value);
                    }}
                    handleStyle={[
                      {
                        backgroundColor: `${theme.colors.primary}`,
                        width: 12,
                        height: 12,
                        marginTop: -4,
                        border: 'none',
                        borderRadius: 8,
                        boxShadow: '0 3px 13px -3px rgba(0, 111, 255, 0.4)',
                      },
                      {
                        backgroundColor: `${theme.colors.primary}`,
                        width: 12,
                        height: 12,
                        marginTop: -4,
                        border: 'none',
                        borderRadius: 8,
                        boxShadow: '0 3px 13px -3px rgba(0, 111, 255, 0.4)',
                      },
                    ]}
                    dotStyle={{ border: 'none', backgroundColor: '#cacfd9', width: 4, height: 4, bottom: 0 }}
                    activeDotStyle={{
                      border: 'none',
                      backgroundColor: `${theme.colors.primaryPressed}`,
                      width: 4,
                      height: 4,
                      bottom: 0,
                    }}
                    railStyle={{ backgroundColor: `${theme.colors.bg3}` }}
                    trackStyle={[{ backgroundColor: `${theme.colors.primary}` }]}
                  />
                </div>
              </div>
              {/* 국가 타겟팅 셀렉트 박스 필요하지만 국가: 싱가폴 정도만 표현하는 것으로 임시조치 */}
              <div className="target-nation-wrapper target-item-wrapper">
                <div className="text-h5 text-color-main left-wrapper">{i18next.t('국가')}</div>
                <div className="target-nation-text-wrapper right-wrapper">{campaignTargetNationLabel}</div>
              </div>
            </div>
          </BorderSection>
        </div>
        {!copyMatch && (
          <React.Fragment>
            <div className="campaign-type-container">
              <BorderSection>
                <div className="border-section-header-wrapper">
                  <div className="title-wrapper">
                    <div className="title">{i18next.t('광고 유형 설정')}</div>
                  </div>
                  <div className="description-wrapper">
                    <div className="description">
                      {i18next.t('집행하고자 하는 광고의 적절한 채널과 형태를 고려하여 광고 유형을 모두 선택해주세요.')}
                    </div>
                  </div>
                </div>
                <div className="campaign-type-tab-wrapper">
                  <BGTab
                    tabList={campaignTypeTabs}
                    selectedTab={selectedTypeTab}
                    handleTab={(tab) => {
                      setSelectedTypeTab(tab);
                    }}
                  />
                </div>
                <div className="campaign-type-selector-wrapper">
                  {filteredCampaignTypes(selectedTypeTab.key).map((type, index) => {
                    const badges = hasBadgeType(type.type);
                    let imgObj;
                    let imgUrl;
                    if (type.media === 'facebook') {
                      imgUrl = '/assets/img/card_icons/ic-facebook.png';
                      imgObj = (
                        <React.Fragment key={getUniqKey('campaign-icon', `icon-${type.media}-${index}`)}>
                          <img className="icon" src="/assets/img/card_icons/ic-channel-facebook.svg" alt={type.media} />
                          <img
                            className="icon"
                            src="/assets/img/card_icons/ic-channel-instagram.svg"
                            alt={type.media}
                          />
                        </React.Fragment>
                      );
                    } else if (type.media === 'google') {
                      if (type.type === 'aw_youtube') {
                        imgUrl = '/assets/img/card_icons/ic-channel-youtube.svg';
                      } else {
                        imgUrl = '/assets/img/card_icons/ic-channel-gg.svg';
                      }
                    } else if (type.media === 'kakao') {
                      imgUrl = '/assets/img/card_icons/ic-channel-kakao.png';
                    }
                    if (type.media !== 'facebook') {
                      imgObj = <img className="icon" src={imgUrl} alt={type.media} />;
                    }

                    if (selectedGoal === 'maximize_clicks' && remaketingTypes.includes(type.type)) return '';
                    if (
                      selectedGoal === 'maximize_clicks' &&
                      activeSubscription?.type === 'smartstore' &&
                      ['aw_shopping', 'fb_collection_image', 'fb_catalog_slide', 'aw_youtube'].includes(type.type)
                    )
                      return '';

                    return (
                      <div
                        key={getUniqKey('create-campaign-type', `icon-${type.media}-${type.type}`)}
                        className={`campaign-type-box ${selectedCampaignTypes.includes(type.type) ? 'active' : ''} ${
                          badges.includes('needPlanUpdate') ? 'lock' : ''
                        } ${badges.length > 0 && !badges.includes('needPlanUpdate') ? 'warning' : ''} ${
                          badges.includes('disabled') ? 'disabled' : ''
                        } ${badges.includes('needLinkGMC') ? 'lock' : ''}`}
                        onClick={() => {
                          if (badges.includes('disabled') || badges.includes('needLinkGMC')) {
                            return;
                          }

                          if (badges.includes('needPlanUpdate')) {
                            setPlanUpdateModal('type');
                          } else if (badges.includes('needAccountFb')) {
                            setFbAssetModal(true);
                          } else if (badges.length === 0) {
                            setUserClickedType(true);
                            handleCamapignTypes(type.type);
                          }

                          if (!badges.includes('needPlanUpdate')) {
                            if (badges.includes('needLogo')) {
                              setSelectedMedia(type.media);
                              setLogoUploadModal(true);
                            }
                          }
                        }}
                        onMouseOver={(event) => {
                          if (
                            badges.filter(
                              (badge) => badge === 'needPlanUpdate' || badge === 'disabled' || badge === 'needLinkGMC',
                            ).length > 0
                          ) {
                            return false;
                          }
                          return playVideo(event);
                        }}
                        onMouseLeave={pauseVideo}
                        onFocus={() => false}
                      >
                        <div className="background-layer">
                          <div className="infographic-wrapper">
                            {selectedCampaignTypes.includes(type.type) && (
                              <div className="icon-wrap">
                                <BDSFontIcon name="ic-check-alt" color="#006fff" size="20px" />
                              </div>
                            )}
                            {badges.length > 0 && (
                              <CampaignTypeLabel
                                lock={badges.includes('needPlanUpdate')}
                                needLinkGMC={badges.includes('needLinkGMC')}
                              />
                            )}
                          </div>
                          <div className="preview-image-wrapper">
                            <video
                              loop
                              muted
                              preload="auto"
                              key={getUniqKey('campaign-video', `video-${type.media}-${index}`)}
                              style={{ width: '100%', minHeight: '100%' }}
                            >
                              <source
                                src={`/assets/video/campaign/bigin-ads-campaign-${type.type}.webm`}
                                type="video/webm"
                              />
                              <track default kind="captions" srcLang="en" />
                              Sorry, your browser doesnt support embedded videos.
                            </video>
                          </div>
                        </div>
                        <div className="description-layer">
                          <div className={`title-wrap ${selectedCampaignTypes.includes(type.type) ? 'active' : ''}`}>
                            <span className="text-h4 text-color-main">{type.title}</span>
                            <div className="icon-wrap">{imgObj}</div>
                          </div>
                          <p className="text-input text-color-main mt-2">{type.description}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </BorderSection>
            </div>
          </React.Fragment>
        )}
        <div className="campaign-setting-container">
          <BorderSection>
            <div className="accordion-container">
              <div className="accordion-header-wrapper" onClick={() => setShowDateSetting(!showDateSetting)}>
                <div className="accordion-header-title-container">
                  <div className="accordion-header-title-wrapper">
                    <img src="/assets/img/campaign/icons-graphic-img-date.svg" className="icon" alt="" />
                    {i18next.t('일 예산 및 기간설정')}
                  </div>
                  {showDateSetting ? (
                    <div className="accordion-header-sub-title-wrapper">
                      {i18next.t('캠페인의 하루 예산과 노출할 기간을 설정해주세요.')}
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="accordion-header-sub-title-wrapper">
                        <span className="sub-title">{i18next.t('일 예산')}</span>
                        <span className="sub-content">
                          {filters.formatCurrency({ value: campaignBudget, currencyCode })}
                        </span>
                      </div>
                      <div className="accordion-header-sub-title-wrapper" style={{ marginLeft: '40px' }}>
                        <span className="sub-title">{i18next.t('기간')}</span>
                        <span className="sub-content">
                          {selectedStartOption === 'endless'
                            ? i18next.t('오늘부터 계속 게재')
                            : `${campaignState.date.start} ~ ${campaignState.date.end}`}
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <BDSFontIcon name={showDateSetting ? 'ic-arrow-up' : 'ic-arrow-down'} size="20px" color="#7e8696" />
              </div>
              <div
                className="accordion-panel"
                style={
                  showDateSetting
                    ? { height: `${dateDiv.current?.offsetHeight}px`, overflow: 'visible' }
                    : { height: 0, overflow: 'hidden', borderTop: 'unset' }
                }
              >
                <div className="accordion-contents" ref={dateDiv}>
                  <div className="campaign-setting-selector-wrapper">
                    <div className="campaign-budget-wrapper">
                      <div className="text-input text-bold text-color-main mt-2" style={{ minWidth: '204px' }}>
                        {i18next.t('일 예산')}
                      </div>
                      <div className="form-wrapper">
                        <BGInput
                          inputProps={{
                            type: 'text',
                            name: 'budget',
                            value: formattedBudget(),
                            placeholder: '0',
                            onChange: (event) => handleBudgetChange(event),
                            onBlur: (event) => handleMinimumBudget(event),
                            autoComplete: 'none',
                          }}
                          style={{
                            width: 220,
                          }}
                          error={formState.errors.budget && true}
                          leftLabelComponent={
                            <span className="text-input text-bold text-color-main text-right">
                              {multilingual.getCurrencySymbolByCurrencyCode({ currencyCode })}
                            </span>
                          }
                          rightLabelComponent={
                            <span className="text-input text-bold text-color-main text-right">{currencyCode}</span>
                          }
                        />
                        {formState.errors.budget && <InputError message={formState.errors.budget.message as string} />}
                        <p className="text-disclaimer mt-2">
                          {i18next.t('*유효한 광고 성과를 위해, 광고 유형 당 {{budget}} 이상의 일 예산이 필요합니다.', {
                            budget: filters.formatCurrency({
                              value: multilingual.getMinimumBudgetByCurrency({
                                currency: currencyCode,
                              }),
                              currencyCode,
                            }),
                          })}
                        </p>
                      </div>
                    </div>
                    <div className="campaign-date-wrapper">
                      <div className="text-input text-bold text-color-main mt-2" style={{ minWidth: '204px' }}>
                        {i18next.t('캠페인 노출 기간')}
                      </div>
                      <div className="campaign-period-form-wrapper">
                        <div className="campaign-date-selector-wrapper">
                          <BDSRadioButton
                            style={{ color: '#3d4046' }}
                            labelText={i18next.t('오늘부터 계속 게재')}
                            inputProps={{
                              id: 'period_1',
                              name: 'period',
                              className: 'text-color-main',
                              onChange: () => {
                                updateCampaignStartDate(moment().format('YYYY-MM-DD'));
                                updateCampaignEndDate(null);
                                updateStartOption(Campaign.StartOption.ENDLESS);
                              },
                              checked: selectedStartOption === 'endless',
                            }}
                          />
                          <BDSRadioButton
                            style={{ color: '#3d4046' }}
                            labelText={i18next.t('시작 및 종료 날짜 지정')}
                            inputProps={{
                              id: 'period_2',
                              name: 'period',
                              className: 'text-color-main',
                              onChange: () => {
                                updateCampaignStartDate(moment().format('YYYY-MM-DD'));
                                updateCampaignEndDate(moment().add(7, 'days').format('YYYY-MM-DD'));
                                updateStartOption(Campaign.StartOption.PERIOD);
                              },
                              checked: selectedStartOption === 'period',
                            }}
                          />
                        </div>
                        {selectedStartOption === Campaign.StartOption.PERIOD && campaignEndDate != null && (
                          <div className="campaign-period-wrapper">
                            <div className="date-form-wrapper">
                              <div className="date-label">{i18next.t('시작일')}</div>
                              <BDSCalendar
                                position="top"
                                hiddenControlPanel
                                isSingleDateRange
                                preLabel=""
                                style={{ width: 220, height: 40 }}
                                initDateRange={{ start: moment(campaignStartDate), end: moment(campaignStartDate) }}
                                selectableRange={{
                                  start: moment().startOf('days'),
                                  end: moment().startOf('days').add(12, 'months'),
                                }}
                                dateHandle={(date) => {
                                  updateCampaignStartDate(date.start.format('YYYY-MM-DD'));
                                }}
                                language={i18n.language === 'ko' ? 'ko' : 'en'}
                              />
                            </div>
                            <div className="date-form-wrapper">
                              <div className="date-label">{i18next.t('종료일')}</div>
                              <BDSCalendar
                                position="top"
                                hiddenControlPanel
                                isSingleDateRange
                                preLabel=""
                                style={{ width: 220, height: 40 }}
                                initDateRange={{
                                  start: moment(campaignEndDate),
                                  end: moment(campaignEndDate),
                                }}
                                selectableRange={{
                                  start: moment().startOf('days'),
                                  end: moment().startOf('days').add(12, 'months'),
                                }}
                                dateHandle={(date) => {
                                  updateCampaignEndDate(date.start.format('YYYY-MM-DD'));
                                }}
                                language={i18n.language === 'ko' ? 'ko' : 'en'}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BorderSection>
        </div>
        {activeSubscription?.type !== 'smartstore' && (
          <div className="linked-campaign-container">
            <BorderSection>
              <div className="accordion-container">
                <div className="accordion-header-wrapper" onClick={() => setShowLinkedCampaign(!showLinkedCampaign)}>
                  <div className="accordion-header-title-container">
                    <div className="accordion-header-title-wrapper">
                      <img src="/assets/img/campaign/icons-graphic-img-linked-campaign.svg" className="icon" alt="" />
                      {i18next.t('연계 캠페인')}
                    </div>
                    {showLinkedCampaign ? (
                      <div className="accordion-header-sub-title-wrapper">
                        {i18next.t(
                          'Bigin Ads의 캠페인을 통해 웹사이트로 유입한 사용자에게 Bigin CRM의 인-웹 팝업을 띄울 수 있습니다.',
                        )}
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="accordion-header-sub-title-wrapper">
                          <span className="sub-title">{i18next.t('사용 여부')}</span>
                          <span className="sub-content">
                            {isBiginConnected ? i18next.t('사용') : i18next.t('사용 안함')}
                          </span>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <BDSFontIcon
                    name={showLinkedCampaign ? 'ic-arrow-up' : 'ic-arrow-down'}
                    size="20px"
                    color="#7e8696"
                  />
                </div>
                <div
                  className="accordion-panel"
                  style={
                    showLinkedCampaign
                      ? { height: `${linkedDiv.current?.offsetHeight}px`, overflow: 'visible' }
                      : { height: 0, overflow: 'hidden' }
                  }
                >
                  <div className="accordion-contents" ref={linkedDiv}>
                    <div className="linked-campaign-toggle-wrapper">
                      <div className="text-input text-bold text-color-main" style={{ minWidth: '204px' }}>
                        {i18next.t('사용 여부')}
                      </div>
                      <div className="form-wrapper">
                        <BDSToggleButton
                          active={isBiginConnected}
                          onChangeToggle={() => {
                            if (mall && !hasBiginProject) {
                              setLinkedBiginCrmModal(true);
                            } else {
                              updateIsBiginConnected(!isBiginConnected);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BorderSection>
          </div>
        )}
      </div>
      <WrapperModal isOpen={showModalSDKInstall} close={closeModal} position={{ width: '370px' }}>
        <DialogConfirm
          title={i18next.t('Bigin SDK 설치 필요')}
          desc={i18next.t(
            '선택하신 목표를 사용하기 위해선 Bigin SDK 설치가 필요합니다.\n플랜을 업그레이드하면 전문가가 대신 Bigin SDK를 설치해드립니다',
          )}
          buttons={[...gtmModalBtns()]}
        />
      </WrapperModal>

      <WrapperModal isOpen={planUpdateModal} close={closeModal} isDialogConfirm>
        <DialogConfirm
          title={i18next.t('플랜 업그레이드 필요')}
          desc={i18next.t(
            '선택하신 {{type}} 사용하기 위해선 플랜을 업그레이드 해야 합니다. 아래 버튼을 클릭하여 사용중인 플랜을 업그레이드 해주세요.',
            { type: planUpdateModal === 'goal' ? i18next.t('목표를') : i18next.t('유형을') },
          )}
          buttons={[
            { label: i18next.t('취소'), appearance: 'secondary', handleClick: closeModal },
            {
              label: i18next.t('플랜 업그레이드'),
              handleClick: () => {
                closeModal();
                navigate(`/malls/${StorageService.selectedMall.id}/plan`);
              },
            },
          ]}
        />
      </WrapperModal>

      {/* 쇼핑몰 로고 업로드 모달 */}
      {mall && (
        <WrapperModal
          isOpen={logoUploadModal}
          close={() => {
            setLogoUploadModal(false);
          }}
          position={{ width: '360px' }}
        >
          <LogoUploadModal
            square={mall.square_logo_url}
            landscape={mall.landscape_logo_url}
            media={selectedMedia}
            close={() => {
              setLogoUploadModal(false);
            }}
            onSubmitted={() => {
              refetchMall();
            }}
          />
        </WrapperModal>
      )}

      {/* 페이스북 자산 연동 모달 */}
      {mall && (
        <WrapperModal
          isOpen={fbAssetModal}
          close={() => {
            setFbAssetModal(false);
          }}
          position={{ width: '360px', maxHeight: '853px' }}
        >
          <FbAssetModal
            types={[
              'fb-page',
              'fb-instagram-account',
              ...(isManager ? (['fb-business', 'fb-ad-account', 'fb-pixel'] as MediaAssetType[]) : []),
            ]}
            assets={mall.medias}
            close={() => {
              setFbAssetModal(false);
            }}
            onSubmitted={() => {
              refetchMall();
            }}
          />
        </WrapperModal>
      )}
      {/* 빅인 연동 모달 */}
      {mall && (
        <WrapperModal
          isOpen={linkedBiginCrmModal}
          close={() => {
            setLinkedBiginCrmModal(false);
          }}
          position={{ width: '320px' }}
        >
          <LinkedBiginCrmModal
            assets={mall.medias}
            isCreateCampaign
            close={() => {
              setLinkedBiginCrmModal(false);
            }}
            onSubmitted={() => {
              queryClient.invalidateQueries('mall');
              updateIsBiginConnected(true);
            }}
          />
        </WrapperModal>
      )}
    </React.Fragment>
  );
};
