import axios, { AxiosPromise } from 'axios';

export type CompanyParam = 'users';

export const UserApi = () => ({
  login(
    email: string,
    password: string,
  ): AxiosPromise<{
    data: {
      access_token: string;
      expires_in: number;
      refresh_token: string;
      token_type: string;
    };
  }> {
    return axios.post('/login', {
      username: email,
      password,
    });
  },
  loginViaKakao(access_token: string): AxiosPromise<{
    data: {
      access_token: string;
      expires_in: number;
      refresh_token: string;
      token_type: string;
    };
  }> {
    return axios.post('/loginViaKakao', { access_token });
  },

  getMe(): AxiosPromise<{ data: User }> {
    return axios.get('/me');
  },
  updateMe(user: Partial<User>): AxiosPromise<{ data: User }> {
    return axios.patch('/me', { ...user });
  },
  logout(): AxiosPromise<void> {
    return axios.get('/logout');
  },
  getCompany(params?: ApiParam<CompanyParam>): AxiosPromise<{ data: Company }> {
    return axios.get('/me/company', { params });
  },
  getPendingUsers(companyId: number): AxiosPromise<{ data: User[] }> {
    return axios.get(`/company/${companyId}/pending-users`);
  },
  getPendingUser(token: string): AxiosPromise<{ data: PendingUser }> {
    return axios.get(`/pending-users-by-token/${token}`);
  },
  invitePendingUser(companyId: number, email: string): AxiosPromise {
    return axios.post(`/company/${companyId}/pending-users`, { email });
  },
  deletePendingUser(userId: number): AxiosPromise {
    return axios.delete(`/company/pending-users/${userId}`);
  },
  deleteCompanyUser(userId: number): AxiosPromise<{ data: User }> {
    return axios.delete(`/me/company/users/${userId}`);
  },
  postCompanyUser(form: {
    email: string;
    password: string;
    password_confirmation: string;
    name: string;
    phone: string;
    token: string;
  }): AxiosPromise {
    return axios.post('/company/users', form);
  },
  getSiteUrlInfo(url: string): AxiosPromise<{
    data: { hosting: string; name: string; registration: string };
  }> {
    return axios.get(`analysis/site?url=${url}`, {
      validateStatus: (status) => status === 200 || status === 422,
    });
  },
  checkBusinessNumber(registration: string): AxiosPromise<{
    data: { bup_nm: string };
  }> {
    return axios.get(`analysis/company?registration=${registration}`, {
      validateStatus: (status) => status < 500,
    });
  },
  sendEmailVerification(email: string): AxiosPromise {
    return axios.post(`email-verification`, { email });
  },
  checkEmailVerification(email: string, token: string): AxiosPromise {
    return axios.get(`email-verification`, { params: { email, token } });
  },
  registrationAccount(params: {
    user: {
      email: string;
      name: string;
      password: string;
      password_confirmation: string;
      phone: string;
      is_promotion_agreed: boolean;
    };
    name: string;
    registration: string;
    mall: {
      url: string;
      name: string;
      hosting: string;
      classification_id: number;
      extra_json: string;
    };
    kakao?: {
      access_token: string;
    };
  }): AxiosPromise {
    return axios.post(`company`, { ...params });
  },
  isBusinessNumberDuplicate(registration: string): AxiosPromise {
    return axios.head(`/companies-by-registration/${registration}`);
  },
  initPassword(email: string): AxiosPromise {
    return axios.patch(`/users-by-email/${email}/password`, {
      validateStatus: (status: number) => status === 200 || status === 404,
    });
  },
});
