import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .header {
      margin-bottom: 20px;

      & > :not(:last-child) {
        margin-bottom: 8px;
      }
    }
    .body {
      margin-bottom: 8px;

      .form-input-wrapper {
        ${theme.text.input};
        color: ${theme.colors.subFont};

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .form-input-label {
          ${theme.text.h4};
          color: ${theme.colors.mainFont};
          margin-bottom: 8px;
        }
      }
    }
    .footer {
      //
    }
  `;
};

export const downgradeBodyCss = () => {
  const theme: Theme = useTheme();

  return css`
    .body {
      ${theme.text.h4};
      color: ${theme.colors.mainFont};
      margin-bottom: 24px;

      & > :not(:last-child) {
        margin-bottom: 12px;
      }

      .description {
        ${theme.text.input};
      }

      .warning-item-wrapper {
        .warning-item {
          padding: 10px 12px 10px 8px;
          border-radius: 8px;
          background-color: #f9fafb;

          &:not(:last-child) {
            margin-bottom: 8px;
          }
          .warning-item-index {
            ${theme.text.input};
            color: ${theme.colors.subFont};
            padding: 2px 8px 2px 7px;
            border-radius: 4px;
            background-color: #edf1f6;
            margin-right: 8px;
          }
          .warning-item-message {
            ${theme.text.input};
            color: ${theme.colors.subFont};
          }
        }
      }
    }
  `;
};
