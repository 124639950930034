import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import Skeleton from 'react-loading-skeleton';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { dataFormatting } from '../../../utils/dataFormatting.util';
import { CurrencyCodeRecoil } from '../../../recoil/Currency';
import { filters } from '../../../utils/filter';

export interface AdStatsProps {
  ad?: UniAd;
  propName?: string;
  isLoaded?: boolean;
  targetStats?: number;
  isPercent?: boolean;
  unit?: string;
  lock?: boolean;
  formatType?: string;
}

export const AdStats = (props: AdStatsProps): JSX.Element => {
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);
  const getResultStats = () => {
    let resultStats;

    if (!_.isNil(props.targetStats)) {
      if (props.formatType === 'Percent') {
        resultStats = dataFormatting('Percent', props.targetStats);
      }
      if (props.formatType === 'Number') {
        resultStats = dataFormatting('Number', props.targetStats);
      }

      if (props.unit) {
        resultStats = `${resultStats}${props.unit}`;
      }

      if (props.formatType === 'Currency') {
        resultStats = filters.formatCurrency({ value: props.targetStats ?? 0, currencyCode });
      }
    } else {
      resultStats = '-';
    }

    return resultStats;
  };

  return (
    <div
      className="text-h5 text-color-sub"
      css={css`
        white-space: nowrap;

        .skeleton {
          border-radius: 0;
        }
      `}
    >
      {!props.isLoaded && <Skeleton className="skeleton fill-width" />}
      {props.lock && (
        <Skeleton className="skeleton" style={{ width: '70%', height: 8, marginTop: 8 }} enableAnimation={false} />
      )}
      {!props.lock && props.isLoaded && getResultStats()}
    </div>
  );
};
