import { css } from '@emotion/react';

export const BgChartTooltipStyle = css`
  position: fixed;
  z-index: 9999;
  top: 1000px;
  left: 1000px;
  min-width: 240px;
  max-width: 400px;

  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09);
  border: solid 1px #ededf2;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .vs-tooltip-wrapper {
    width: 100%;
    border-bottom: 1px solid #dce0e8;
    box-sizing: border-box;
    padding: 16px;
  }
  .open-user-list-btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    padding: 16px;
  }

  //.open-user-list-btn {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  font-size: 14px;
  //  line-height: 1.43;
  //  color: #777788;
  //  height: 39px;
  //  width: 100%;
  //  cursor: pointer;
  //  background-color: #ffffff;
  //}
  //
  //.open-user-list-btn:hover {
  //  color: #0086ff;
  //}

  .wrapper {
    .tooltip-data-wrapper {
      margin-bottom: 8px;
    }
  }

  .wrapper:last-child {
    margin-bottom: 0;

    .tooltip-data-wrapper {
      margin-bottom: 0px;
    }
  }

  .negative {
    color: #e65c5c !important;

    .positive {
      display: none !important;
    }
  }

  .tooltip-header-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;

    .key-wrapper {
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      color: #53585f;
    }

    .compare-rate-wrapper {
      font-size: 12px;
      font-weight: bold;
      line-height: 1.33;
      text-align: right;
      color: #39b88a;
    }
  }

  .tooltip-header-wrapper :first-of-type {
    margin-bottom: 14px;
  }

  .tooltip-data-wrapper {
    justify-content: space-between;
    display: flex;
    align-items: center;
  }

  .left-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }

  .legend-color-icon {
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    white-space: nowrap;
  }

  .datakey-wrapper {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: normal;
    color: #53585f;
    margin-right: 24px;
  }

  .date-wrapper {
    font-size: 12px;
    line-height: 1.33;
    color: #3d4046;
  }

  .value-wrapper {
    font-size: 14px;
    line-height: 1.43;
    color: #53585f;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .rate-wrapper {
    font-size: 14px;
    font-weight: bold;
    line-height: 1.43;
    color: #53585f;
  }

  .vs-line-tooltip-wrapper {
    .tooltip-header-wrapper {
      max-width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.25;
      color: #53585f;
      margin-bottom: 14px;
    }

    .metric-name-rapper {
      font-size: 14px;
      line-height: 1.43;
      letter-spacing: normal;
      color: #53585f;
      margin-right: 24px;
    }
    .link-btn {
      font-size: 14px;
      font-weight: bold;
      background: transparent;
      white-space: nowrap;
    }
    .link-btn-active {
      color: #0060ad;
      cursor: pointer;
    }
  }
`;
