import { css } from '@emotion/react';

export const baseCss = () => {
  return css`
    width: 88px;
    height: 88px;
    border-radius: 4px;
    display: inline-block;
    border: solid 1px #dce0e8;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    font-size: 0;
    position: relative;
    .checkbox-button {
      position: absolute;
      top: 8px;
      left: 8px;
      padding: 0;
      background: transparent;
    }
    .dark-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      z-index: 1;
    }

    .image {
      width: 88px;
      height: 88px;
      object-fit: cover;
    }
  `;
};
