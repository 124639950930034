import React, { ReactElement, useEffect } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import sysend from 'sysend';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { baseCss } from './BiginLoginCallback.style';

export const BiginLoginCallback = (): ReactElement => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      sysend.broadcast('bigin-login-callback', _.fromPairs(Array.from(new URLSearchParams(location.search).entries())));
      window.close();
    }, 1000);
  }, []);

  return <div css={[baseCss]} />;
};
