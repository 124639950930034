import { css, useTheme, Theme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: 260px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: sticky;
    top: 124px;

    .create-image-summary-area {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding: 16px;
      box-sizing: border-box;

      .name-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        ${theme.text.h4};
        color: ${theme.colors.mainFont};
        margin-bottom: 8px;
      }

      .name {
        min-height: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .tooltip-icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 4px;
        cursor: default;
      }

      .button-wrapper {
        display: block;
        position: absolute;
        right: 20px;
      }

      .table-wrapper {
        width: 100%;

        .row {
          display: flex;
          justify-content: space-between;
          padding-bottom: 16px;

          &.short {
            padding-bottom: 8px;
          }

          &.border-bottom {
            border-bottom: 1px solid ${theme.colors.divider};
          }

          .example-image-wrapper {
            width: 228px;
            border-radius: 4px;
            border: solid 1px #dce0e8;
            display: flex;
            align-items: center;
            position: relative;
            cursor: pointer;

            .example-image {
              width: 100%;
              border-radius: 3px;
            }
          }

          &:last-child {
            padding-bottom: 4px;

            &.border-none {
              padding-bottom: 8px;
            }
          }
        }

        .row + .row {
          padding-top: 16px;
          border-top: 1px solid ${theme.colors.divider};

          &.border-none {
            border-top: none;
          }

          &.padding-top-unset {
            padding-top: unset !important;
          }

          &.padding-bottom-unset {
            padding-bottom: unset !important;
          }

          &.short {
            padding-top: 8px;
          }
        }
      }
    }
  `;
};
