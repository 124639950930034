import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, Theme, useTheme } from '@emotion/react';
import { BGButton } from '../BGButton/BGButton';
import { baseCss } from './DialogConfirm.style';
import { BGButtonGroup } from '../BGButtonGroup/BGButtonGroup';

export type Button = {
  label: string;
  handleClick?: () => void;
  appearance?: string;
  disabled?: boolean;
};

export interface DialogConfirmProps {
  style?: object;
  title: string;
  desc: string | JSX.Element;
  buttons: Array<Button>;
}
export const DialogConfirm = (props: DialogConfirmProps): ReactElement => {
  const theme: Theme = useTheme();
  const themeCss = css`
    background-color: ${theme.colors.bg1};
  `;
  return (
    <div className="dialog-confirm-container" css={[baseCss, themeCss]} style={props.style || {}}>
      <div className="dialog-confirm-content">
        <div className="dialog-confirm-title">{props.title}</div>
        <div className="dialog-confirm-desc">{props.desc}</div>
      </div>
      <BGButtonGroup rightAlign>
        {props.buttons.map((button) => (
          <BGButton
            key={button.label}
            label={button.label}
            isDisabled={button.disabled}
            onClick={button.handleClick}
            appearance={button.appearance || ''}
          />
        ))}
      </BGButtonGroup>
    </div>
  );
};
