import React from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, useTheme, Theme } from '@emotion/react';
import { Link, useMatch } from 'react-router-dom';
import { baseCss } from './MenuSubButton.style';

export interface MenuButtonSubProps {
  id: number;
  link: string;
  label?: string;
  style?: Object;
  handleOnClick?: () => void;
}

export const MenuSubButton: React.FC<MenuButtonSubProps> = (props: MenuButtonSubProps) => {
  const match = useMatch({
    path: `${props.link}/*`,
  });
  const theme: Theme = useTheme();
  const themeCss = css`
    background-color: ${theme.colors.bg1};
  `;

  return (
    <div className={`sub-button-panel ${match ? 'active' : ''}`} style={props.style || {}} css={[baseCss, themeCss]}>
      <Link to={props.link} key={props.id} style={{ textDecoration: 'none', width: '100%' }}>
        <button
          type="button"
          onClick={() => {
            if (props.handleOnClick) {
              props.handleOnClick();
            }
          }}
        >
          {props.label}
        </button>
      </Link>
    </div>
  );
};
