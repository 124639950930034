import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { useTranslation } from 'react-i18next';
import { baseCss } from './BGPageBanner.style';

export interface BDSPageBannerProps {
  text: string;
  type: BannerType;
  handleMoreView?: () => void;
  handleClose?: () => void;
  language: 'ko' | 'en';
}

export type BannerType = 'normal' | 'link' | 'update';

export const BGPageBanner = (props: BDSPageBannerProps): JSX.Element => {
  const { text, type, handleMoreView, handleClose, language = 'ko' } = props;
  const i18next = useTranslation();
  return (
    <div css={baseCss}>
      <div className="flex">
        <div className={`img-wrapper ${type === 'update' ? 'success-back-ground-color' : 'primary-back-ground-color'}`}>
          {type === 'update' ? (
            <img src="/assets/img/common/ic-notice-white.png" alt={`${type} img`} />
          ) : (
            <img src="/assets/img/ic-info.svg" alt={`${type} img`} />
          )}
          {type === 'update' && <div className="oval">&nbsp;</div>}
        </div>
        <div className="banner-text">{text}</div>
        {type !== 'normal' && handleMoreView && (
          <div className="link-comment primary-color" onClick={handleMoreView}>
            {language === 'ko' ? i18next.t('자세히 보기') : 'Learn more'}
          </div>
        )}
      </div>
      {handleClose && (
        <div className="delete-icon-wrapper" onClick={handleClose}>
          <BDSFontIcon name="ic-close" size="20px" color="#fff" />
        </div>
      )}
      <img className="banner-graphic" src="/assets/img/banner-graphic.png" alt="" />
    </div>
  );
};
