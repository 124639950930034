import React, { useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { useTranslation } from 'react-i18next';
import { BGTooltip } from '../../../components/BGTooltip/BGTooltip';
import { baseCss } from './ImageBox.style';

export interface ImageBoxProps {
  editMode?: boolean;
  selectMode?: boolean;
  deleteMode?: boolean;
  isRatioSelectionMode?: boolean;
  type?: { type: 'creative' | 'create-campaign'; value: String; ratio: '1:1' | '1.91:1' | '2:1' };
  label?: string | undefined;
  hover?: boolean;
  onEdit?: () => void;
  imageUrl: string;
  disabled?: boolean;
  isSelected?: boolean;
  isClicked?: boolean;
  clickImage?: (ratio: ImageRatioType) => void;
  deleteImage?: () => void;
}

export const ImageBox = ({
  editMode = false,
  selectMode = false,
  deleteMode = false,
  // isRatioSelectionMode = false,
  type = { type: 'creative', value: '', ratio: '1:1' },
  label,
  // hover,
  onEdit,
  imageUrl,
  disabled = false,
  isSelected,
  // isClicked,
  clickImage,
  deleteImage,
}: ImageBoxProps): JSX.Element => {
  const i18next = useTranslation();
  const [isHover, setIsHover] = useState(false);
  const [isShowRatioSelectionDialog] = useState(false);
  const handleMouseOver = () => {
    if (deleteMode) return;
    setIsHover(true);
  };
  const handleMouseOut = () => {
    setIsHover(false);
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e) e.stopPropagation();
    if (!disabled) {
      if (clickImage) {
        clickImage('');
      }
    }
  };

  const disabledTooltipContent = () => {
    let size = '';
    if (type.type === 'create-campaign') {
      if (type.value.includes('aw')) {
        if (type.ratio === '1.91:1') {
          size = '600x314';
        } else {
          size = '300x300';
        }
      } else if (type.value.includes('kk')) {
        if (type.value === 'kk_image_banner') {
          size = '1029x258';
        }
        if (type.value === 'kk_image_native') {
          if (type.ratio === '2:1') {
            size = '1200x600';
          } else {
            size = '500x500';
          }
        }
      } else {
        size = '600x600';
      }
    }

    return (
      <div>
        {i18next.t('사이즈가 규격에 맞지 않아 사용할 수 없습니다.')}
        <br />
        {type.value === 'kk_image_banner'
          ? i18next.t('권장 이미지 크기 {{size}}', { size })
          : i18next.t('최소 이미지 크기 {{size}}', { size })}
      </div>
    );
  };

  return (
    <div
      className="image-box"
      css={[baseCss]}
      onClick={handleClick}
      onMouseLeave={handleMouseOut}
      onMouseEnter={!isShowRatioSelectionDialog ? handleMouseOver : () => ''}
    >
      {isHover && disabled === false && (
        <div data-key="image-box" className="dark-layer">
          {selectMode && disabled === false && (
            <button type="button" className="checkbox-button">
              {isSelected ? (
                <BDSFontIcon name="ic-checkbox-active" color="#006fff" size="20px" />
              ) : (
                <BDSFontIcon name="ic-checkbox" color="#dce0e8" size="20px" />
              )}
            </button>
          )}
          {editMode && (
            <button
              type="button"
              className="edit-button"
              onClick={(event) => {
                event.stopPropagation();
                if (onEdit) {
                  onEdit();
                }
              }}
            >
              <BDSFontIcon name="ic-edit" color="#ffffff" size="20px" />
            </button>
          )}
        </div>
      )}
      {deleteMode && (
        <button
          type="button"
          onClick={() => {
            if (deleteImage) {
              deleteImage();
            }
          }}
          className="delete-button"
        >
          <BDSFontIcon name="ic-close" color="#7e8696" size="16px" />
        </button>
      )}
      {selectMode && disabled === false && (
        <button type="button" className="checkbox-button">
          {isSelected && <BDSFontIcon name="ic-checkbox-active" color="#006fff" size="20px" />}
        </button>
      )}
      {label && (
        <div className="badge" style={{ left: isHover || (selectMode && isSelected && disabled === false) ? 32 : 8 }}>
          {label}
        </div>
      )}
      <img className="image" src={imageUrl} alt="" />
      {disabled && (
        <BGTooltip title={disabledTooltipContent()} placement="right">
          <div className="checkbox-button">
            <BDSFontIcon name="ic-warning" color="#FFAB00" size="20px" />
          </div>
        </BGTooltip>
      )}
    </div>
  );
};
