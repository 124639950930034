import React, { ReactElement } from 'react';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import parse from 'html-react-parser';

export type BGEmptyImageType = 'default' | 'list' | 'chart' | 'user';

export interface BGEmptyProps {
  title: string;
  type?: BGEmptyImageType;
  description?: string;
  children?: JSX.Element | JSX.Element[];
}

const StyledCustomEmptyComponent = styled.div<{ theme: Theme }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.text.h4};
  text-align: center;
  color: ${({ theme }) => theme.colors.mainFont};

  img {
    margin-bottom: 16px;
    width: 110px;
  }

  .description {
    ${({ theme }) => theme.text.input};
    margin-top: 8px;
    color: ${({ theme }) => theme.colors.subFont};
  }
`;

export const BGEmpty = (props: BGEmptyProps): ReactElement => {
  const { type, title, description, children } = props;
  const theme: Theme = useTheme();

  const getImagePath = (imageType: BGEmptyImageType) => {
    switch (imageType) {
      case 'list':
        return '/assets/img/common/ic-cant-find-list-data.png';
      case 'chart':
        return '/assets/img/common/ic-cant-find-chart-data.png';
      case 'user':
        return '/assets/img/common/ic-cant-find-user-data.png';
      default:
        return '/assets/img/common/ic-cant-find-data.png';
    }
  };

  return (
    <StyledCustomEmptyComponent theme={theme}>
      {type && <img className="image" src={getImagePath(type)} alt="list-data-empty" />}
      {title}
      {description && <div className="description">{parse(description)}</div>}
      {children}
    </StyledCustomEmptyComponent>
  );
};
