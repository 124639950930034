import React, { useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { IMAGE_PROXY_URL } from '../../../utils/utils';
import { baseCss } from './VideoBox.style';

export interface VideoBoxProps {
  video: Video;
  selectMode: boolean;
  isSelected: boolean;
  clickVideo?: () => void;
}

export const VideoBox = ({ video, selectMode = false, isSelected = false, clickVideo }: VideoBoxProps): JSX.Element => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseOver = () => {
    setIsHover(true);
  };
  const handleMouseOut = () => {
    setIsHover(false);
  };

  const handleClick = () => {
    if (clickVideo) {
      clickVideo();
    }
  };

  return (
    <div
      className="video-box"
      css={[baseCss]}
      onMouseLeave={handleMouseOut}
      onMouseEnter={handleMouseOver}
      onClick={handleClick}
    >
      {isHover && (
        <div data-key="image-box" className="dark-layer">
          {selectMode && (
            <button type="button" className="checkbox-button">
              {isSelected ? (
                <BDSFontIcon name="ic-checkbox-active" color="#006fff" size="20px" />
              ) : (
                <BDSFontIcon name="ic-checkbox" color="#dce0e8" size="20px" />
              )}
            </button>
          )}
        </div>
      )}
      {selectMode && (
        <button type="button" className="checkbox-button">
          {isSelected && <BDSFontIcon name="ic-checkbox-active" color="#006fff" size="20px" />}
        </button>
      )}
      <img className="image" src={`${IMAGE_PROXY_URL}${video.video.thumbnails[0].url}`} alt="" />
    </div>
  );
};
