import React, { InputHTMLAttributes, ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, SerializedStyles, Theme, useTheme } from '@emotion/react';
import { BGRadioButtonWrapperCSS } from './BGRadioButton.style';

export interface BGRadioButtonInputProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string;
  name: string;
  className?: string;
  style?: object;
}

export interface BGRadioButtonProps {
  inputProps: BGRadioButtonInputProps;
  labelText?: string;
  className?: string;
  style?: object;
  css?: SerializedStyles;
}

export const BGRadioButton = React.forwardRef(
  (props: BGRadioButtonProps, ref: React.Ref<HTMLInputElement>): ReactElement => {
    const { inputProps, labelText = '', className = '', css: wrapperCSS, style = {} } = props;
    const { id, name, className: inputClassName = '', style: inputStyle = {}, disabled = false } = inputProps;

    const theme: Theme = useTheme();

    return (
      <div
        style={style}
        className={`${className} radio-button-wrapper ${id}-radio-wrapper ${disabled ? 'isDisabled' : ''}`}
        css={[BGRadioButtonWrapperCSS(theme), wrapperCSS]}
      >
        <input
          type="radio"
          {...inputProps}
          ref={ref}
          id={id}
          name={name}
          className={`radio-button-input ${inputClassName}`}
          style={inputStyle}
          onChange={(event) => {
            if (inputProps.onChange) {
              inputProps.onChange(event);
            }
          }}
        />
        <label htmlFor={id} className={`radio-button-label ${id}-radio-label`}>
          {labelText}
        </label>
      </div>
    );
  },
);
