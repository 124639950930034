import { css } from '@emotion/react';

export const baseCss = css`
  .form-input-wrapper {
    margin-bottom: 20px;

    .form-label {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #3d4046;

      margin-bottom: 16px;
    }
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    cursor: pointer;
  }
`;
