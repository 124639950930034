import { css } from '@emotion/react';

export const baseCss = css`
  gap: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;

  .info-banner {
    width: 100%;
    height: 48px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }

    .info-content {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 12px;
      box-sizing: border-box;

      .info-item {
        display: flex;
        align-items: center;
        gap: 8px;
        .info-icon {
          width: 24px;
          height: 24px;
        }

        .more {
          color: #0661e4;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }

  .table-container {
    width: 100%;
  }
  .application-status-wrapper {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 20px;
  }
  .title-container {
    width: 100%;
    max-height: 88px;
    border-bottom: solid 1px #ededf2;

    .title-wrapper {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      background-color: #ffffff;
      padding: 20px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .title-wrapper-text {
        width: fit-content;
        white-space: nowrap;
      }
      .title-item-wrapper {
        display: flex;
        gap: 16px;
      }
    }
  }
`;

export const changeBudgetModalCss = css`
  .title-wrapper {
    .title-wrapper-text {
      width: fit-content;
      white-space: nowrap;
    }
  }
  .contents-wrapper {
    margin-top: 20px;
  }
  .footer-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 20px;
  }
`;
