import { atom } from 'recoil';

const isCollapsed = atom({
  key: 'sideNavBarIsCollapsed',
  default: false,
});

const isCollapsedHover = atom({
  key: 'sideNavBarIsCollapsedHover',
  default: false,
});

const isBlockCollapsedHover = atom({
  key: 'sideNavBarIsBlockCollapsedHover',
  default: false,
});

export default {
  isCollapsed,
  isCollapsedHover,
  isBlockCollapsedHover,
};
