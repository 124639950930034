import React, { ReactElement, useState, useEffect } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton, BDSSelectBox, BDSTextArea } from '@bigin/bigin-ui-components';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import _ from 'lodash';
import { BGUrlSelector } from '../../../../components/BGUrlSelector/BGUrlSelector';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss } from './YoutubeVideo.style';
import CampaignSetting from '../../../../recoil/CampaignSetting';
import { MaterialCheckboxSelector } from '../../MaterialCheckboxSelector/MaterialCheckboxSelector';
import { PreviewYoutubeVideo } from '../PreviewYoutubeVideo/PreviewYoutubeVideo';
import { InputError } from '../../../../components/InputError/InputError';
import { filters } from '../../../../utils/filter';
import { AwAssetModal, AwAssetModalForm } from '../../../../components/AwAssetModal/AwAssetModal';
import { WrapperModal } from '../../../../components/WrapperModal/WrapperModal';
import { MallApi } from '../../../../lib/api/MallApi';
import StorageService from '../../../../services/StorageService';
import { AW_MAX_BYTE, AW_SHORT_TITLE_MAX_BYTE, googleAdRegexs } from '../../../../utils/utils';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { GoogleField, googleCTA } from '../../../../constants/campaigns';
import { BGTagInput } from '../../../../components/BGTagInput/BGTagInput';
import { AssetHeader } from '../../../../components/AssetHeader/AssetHeader';

export interface YoutubeVideoProps {
  setting: CampaignAdSettingState;
  handleUpdate: (setting: CampaignAdSettingState) => void;
  handleValid?: (type: CampaignType, isValid: boolean) => void;
  removeSelectedCampaignTypes: (type: CampaignType) => void;
}

interface YoutubeVideoForm {
  aw_long_title: string;
  aw_short_title: string;
  aw_description: string;
  url: string;
  aw_youtube_url: string;
}

export const YoutubeVideo = (props: YoutubeVideoProps): ReactElement => {
  const i18next = useTranslation();

  const [awAssetModal, setAwAssetModal] = useState<boolean>(false);

  const [selectedCTA, setSelectedCTA] = useState<GoogleCTA>(
    props.setting?.aw_call_to_action ? props.setting?.aw_call_to_action : 'shop_now',
  );
  const [name, setName] = useState(props.setting?.name);
  const [url, setUrl] = useState(props.setting?.url);

  const [awYoutubeUrl, setAwYoutubeUrl] = useState(props.setting?.aw_youtube_url);
  const [awDescription, setAwDescription] = useState(props.setting?.aw_description);
  const [awLongTitle, setAwLongTitle] = useState(props.setting?.aw_long_title);
  const [awShortTitle, setAwShortTitle] = useState(props.setting?.aw_short_title);
  const [biginKeywords, setBiginKeywords] = useState<string[]>(props.setting?.bigin_keywords);

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);

  const { register, formState, setValue } = useForm<YoutubeVideoForm>({ mode: 'onChange' });

  const { data: mall, refetch: refetchMall } = useQuery<Mall>(
    'mall',
    async () => (await MallApi().getMall(StorageService.selectedMall.id, { includes: ['medias'] })).data,
  );

  const [currentErrorField, setCurrentErrorField] = useState<GoogleField | null>(null);
  const googleRegexCheck = (field: GoogleField, value: string) => {
    let googleRegex = false;
    googleAdRegexs().map((regex) => {
      if (new RegExp(regex, 'gi').test(value)) googleRegex = true;
      return true;
    });
    if (googleRegex) setCurrentErrorField(field);
    else setCurrentErrorField(null);

    const regexFreeValue = filters.googleRegexRemove(value);
    switch (field) {
      case GoogleField.AW_LONG_TITLE:
        setAwLongTitle(regexFreeValue);
        break;
      case GoogleField.AW_SHORT_TITLE:
        setAwShortTitle(regexFreeValue);
        break;
      case GoogleField.AW_DESCRIPTION:
        setAwDescription(regexFreeValue);
        break;
      default:
        break;
    }
    setValue(field as 'aw_long_title' | 'aw_short_title' | 'aw_description', regexFreeValue, {
      shouldValidate: true,
    });
  };

  const updateName = (curName: string) => {
    props.handleUpdate({ ...props?.setting, name: curName });
  };

  const updateAwLongTitle = (curAwLongTitle: string) => {
    props.handleUpdate({ ...props?.setting, aw_long_title: curAwLongTitle });
  };

  const updateAwShortTitle = (curAwShortTitle: string) => {
    props.handleUpdate({ ...props?.setting, aw_short_title: curAwShortTitle });
  };

  const updateAwDescription = (curAwDescription: string) => {
    props.handleUpdate({ ...props?.setting, aw_description: curAwDescription });
  };

  const updateAwYoutubeUrl = (curAwYoutubeUrl: string) => {
    props.handleUpdate({ ...props?.setting, aw_youtube_url: curAwYoutubeUrl });
  };

  const updateUrl = (curUrl: string) => {
    props.handleUpdate({ ...props?.setting, url: curUrl });
  };

  const updateCTA = (curCTA: GoogleCTA) => {
    props.handleUpdate({ ...props?.setting, aw_call_to_action: curCTA });
  };

  const updateBiginKeywords = (curKeywords: string[]) => {
    props.handleUpdate({ ...props?.setting, bigin_keywords: curKeywords });
  };

  useEffect(() => {
    if (name !== props.setting?.name) {
      updateName(name);
    }
    if (awLongTitle !== props.setting?.aw_long_title) {
      updateAwLongTitle(awLongTitle);
    }
    if (awShortTitle !== props.setting?.aw_short_title) {
      updateAwShortTitle(awShortTitle);
    }
    if (awDescription !== props.setting?.aw_description) {
      updateAwDescription(awDescription);
    }
    if (awYoutubeUrl !== props.setting?.aw_youtube_url) {
      updateAwYoutubeUrl(awYoutubeUrl);
    }
    if (url !== props.setting?.url) {
      updateUrl(url);
    }
    if (selectedCTA !== props.setting?.aw_call_to_action) {
      updateCTA(selectedCTA);
    }
    if (!_.isEqual(biginKeywords, props.setting?.bigin_keywords)) {
      updateBiginKeywords(biginKeywords);
    }
  }, [name, awLongTitle, awShortTitle, awDescription, awYoutubeUrl, url, selectedCTA, biginKeywords]);

  useEffect(() => {
    register('url', {
      value: url,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isURL(value)) {
          return i18next.t('잘못된 URL 형식입니다.') as string;
        }

        return true;
      },
    });
    register('aw_youtube_url', {
      value: awYoutubeUrl,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('aw_long_title', {
      value: awLongTitle,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_MAX_BYTE) {
          return i18next.t('긴 광고 제목은 {{byte}} byte를 넘을 수 없습니다.', { byte: AW_MAX_BYTE }) as string;
        }

        return true;
      },
    });
    register('aw_short_title', {
      value: awShortTitle,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_SHORT_TITLE_MAX_BYTE) {
          return i18next.t('광고 제목은 {{byte}}byte를 넘을 수 없습니다.', { byte: AW_SHORT_TITLE_MAX_BYTE }) as string;
        }

        return true;
      },
    });
    register('aw_description', {
      value: awDescription,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_MAX_BYTE) {
          return i18next.t('광고 문구는 {{byte}}byte를 넘을 수 없습니다.', { byte: AW_MAX_BYTE }) as string;
        }

        return true;
      },
    });
  }, []);

  useEffect(() => {
    if (props.handleValid) {
      props.handleValid(props.setting?.type, formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <BorderSection>
      <div className="fb-single-image-setting-wrapper" css={[baseCss]}>
        <AssetHeader
          title={i18next.t('Youtube 광고')}
          icons={[{ imgSrc: '/assets/img/card_icons/ic-channel-youtube.png', alt: 'Google' }]}
          handleIconClick={() => {
            props.removeSelectedCampaignTypes(props.setting.type);
          }}
        />
        <div className="create-section-content-wrapper">
          <div className="create-section-content">
            <div className="preview-container">
              <div className="sticky-wrap">
                <div className="title-panel">
                  <div className="title">{i18next.t('미리보기')}</div>
                  <div className="info">{i18next.t('*실제 노출과 미리보기가 다를 수 있습니다.')}</div>
                </div>
                <div className="material-preview-wrapper">
                  <div className="material-preview">
                    <div className="left-wrapper">
                      <PreviewYoutubeVideo setting={props.setting} type={1} />
                    </div>
                    <div className="right-wrapper">
                      <PreviewYoutubeVideo setting={props.setting} type={2} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <h2 className="material-field-header">{i18next.t('광고 소재명')}</h2>
              <div className="material-field-selector-wrapper">
                <BGInput
                  style={{ width: '100%' }}
                  inputProps={{
                    name: 'name',
                    placeholder: i18next.t('광고 소재 이름을 입력해주세요.'),
                    value: name || '',
                    onChange: (event) => {
                      setName(event.target.value);
                    },
                  }}
                />
                <p className="description">{i18next.t('*다른 소재와 구분지을 수 있게 이름을 입력하세요.')}</p>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 이미지')}</h2>
              <div className="material-field-selector-wrapper">
                <div className="content-upload-container">
                  <div className="single-image-uploader-wrapper uploader-wrapper">
                    <div className="image-uploader-title-wrapper">
                      <div className="image-uploader-subtitle">
                        <div>{i18next.t('* 권장 길이: {{second}}초', { second: 30 })} </div>
                        <div>{i18next.t('* 화면 비율: 640×360(19:9) 또는 480×360(4:3)')}</div>
                        <div>{i18next.t('* 오디오 코덱: AAC, MP3')}</div>
                        <div>{i18next.t('* 비디오 코덱: H.264, MPEG-2, MPEG-4')}</div>
                        <div>{i18next.t('* 파일 용량: 최대 {{size}}GB', { size: 1 })}</div>
                      </div>
                    </div>
                    <div className="single-image-uploader-wrapper">
                      <BDSButton
                        className="image-updater-btn"
                        onClick={() => {
                          setAwAssetModal(true);
                        }}
                        label={i18next.t('영상 선택')}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 텍스트')}</h2>
              <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 문구')} checkboxLabel="" value hiddenCheckbox />
                <div className="material-field-input-wrapper">
                  <BDSTextArea
                    isAutoGrow
                    error={!!formState.errors.aw_description}
                    style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                    textAreaProps={{
                      name: 'aw_description',
                      cols: 30,
                      rows: 3,
                      style: {
                        minHeight: 49,
                      },
                      value: awDescription || '',
                      placeholder: i18next.t('광고 문구를 입력하세요.'),
                      onChange: (event) => {
                        googleRegexCheck(GoogleField.AW_DESCRIPTION, event.target.value);
                      },
                    }}
                    curLength={filters.formatByte(awDescription) || 0}
                    limitLength={90}
                    lengthUnit="byte"
                  />
                  {formState.errors.aw_description && (
                    <InputError message={formState.errors.aw_description.message as string} />
                  )}
                  {currentErrorField === GoogleField.AW_DESCRIPTION && (
                    <p className="description">
                      {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                    </p>
                  )}
                </div>
              </div>
              <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('긴 광고 제목')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <div className="material-field-input-wrapper">
                  <BDSTextArea
                    isAutoGrow
                    error={!!formState.errors.aw_long_title}
                    style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                    textAreaProps={{
                      name: 'aw_long_title',
                      cols: 30,
                      rows: 3,
                      style: {
                        minHeight: 49,
                      },
                      value: awLongTitle || '',
                      placeholder: i18next.t('긴 광고 제목을 입력하세요.'),
                      onChange: (event) => {
                        googleRegexCheck(GoogleField.AW_LONG_TITLE, event.target.value);
                      },
                    }}
                    curLength={filters.formatByte(awLongTitle) || 0}
                    limitLength={90}
                    lengthUnit="byte"
                  />
                  {formState.errors.aw_long_title && (
                    <InputError message={formState.errors.aw_long_title.message as string} />
                  )}
                  {currentErrorField === GoogleField.AW_LONG_TITLE && (
                    <p className="description">
                      {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                    </p>
                  )}
                </div>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 제목')} checkboxLabel="" value hiddenCheckbox />
                <BGInput
                  style={{ width: '100%', marginTop: '8px' }}
                  error={!!formState.errors.aw_short_title}
                  inputProps={{
                    name: 'aw_short_title',
                    value: awShortTitle || '',
                    placeholder: i18next.t('광고 제목을 입력하세요.'),
                    onChange: (event) => {
                      googleRegexCheck(GoogleField.AW_SHORT_TITLE, event.target.value);
                    },
                  }}
                  customLength={filters.formatByte(awShortTitle) || 0}
                  customLengthLimit={30}
                  lengthUnit="byte"
                />
                {formState.errors.aw_short_title && (
                  <InputError message={formState.errors.aw_short_title.message as string} />
                )}
                {currentErrorField === GoogleField.AW_SHORT_TITLE && (
                  <p className="description">
                    {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                  </p>
                )}
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('행동 유도 버튼')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <BDSSelectBox
                  appearance="gray"
                  style={{ width: '100%', marginTop: '8px' }}
                  list={googleCTA}
                  defaultValue={props.setting?.aw_call_to_action}
                  displayValue={(item: any) => {
                    return i18next.t(item.label);
                  }}
                  isSelected={(item: any) => {
                    return selectedCTA === item.value;
                  }}
                  handleUpdate={(item: any) => {
                    setSelectedCTA(item.value);
                  }}
                />
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('랜딩 URL')} checkboxLabel="" value hiddenCheckbox />
                <BGUrlSelector
                  linkButton
                  validationTrackOn
                  isValid={!formState.errors.url}
                  url={url || ''}
                  handleUpdate={(curUrl: string) => {
                    setUrl(curUrl);
                    setValue('url', curUrl, { shouldValidate: true });
                  }}
                  style={{ marginTop: '8px' }}
                  placeholder="www.bigin.io"
                />
                {formState.errors.url && <InputError message={formState.errors.url.message as string} />}
              </div>
              {campaignState.isBiginConnected && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('연계 캠페인')}</h2>
                  <div className="material-field-selector-wrapper">
                    <MaterialCheckboxSelector
                      contentTitle={i18next.t('핵심 키워드')}
                      checkboxLabel=""
                      value
                      hiddenCheckbox
                    />
                    <BGTagInput
                      style={{ width: '100%', marginTop: '8px' }}
                      inputProps={{
                        name: 'bigin_keywords',
                        placeholder: i18next.t(
                          'Bigin CRM에서 해당 광고 소재를 검색할 때 사용할 키워드를 입력해주세요.',
                        ),
                      }}
                      handleUpdate={(keywords) => {
                        setBiginKeywords(keywords);
                      }}
                      defaultTags={biginKeywords}
                      duplicateText={i18next.t('* 이미 등록된 키워드입니다.')}
                    />
                    <p className="description">
                      {i18next.t('*핵심 키워드는 선택사항이며, 최대 5개까지 입력할 수 있습니다.')}
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 구글애즈 자산 연동 모달 */}
      {mall && (
        <WrapperModal
          isOpen={awAssetModal}
          close={() => {
            setAwAssetModal(false);
          }}
          position={{ width: '640px', overflow: 'visible' }}
        >
          <AwAssetModal
            types={['youtube', 'youtube-videos']}
            assets={mall.medias}
            close={() => {
              setAwAssetModal(false);
            }}
            onSubmitted={(form: AwAssetModalForm) => {
              refetchMall();
              setValue('aw_youtube_url', form.selectedYtVideo.url, { shouldValidate: true });
              setAwYoutubeUrl(form.selectedYtVideo.url);
            }}
          />
        </WrapperModal>
      )}
    </BorderSection>
  );
};
