// import BiginStorage from '../services/BiginStorage';

/**
 * @description
 * 설정된 표시 통화에 해당하는 통화 기호 반환
 * 표시 통화가 설정되어 있지 않으면 ₩ 반환
 * */
export const getCurrency = () => {
  const currencyDict: { [prop: string]: string } = {
    krw: '₩',
    jpy: '¥',
    usd: '$',
  };
  // const currency = (BiginStorage.selectedProject?.currency || 'krw') as string;
  const currency = 'krw' as string;
  return currencyDict[currency] || '₩';
};

/**
 *
 * @param formatType format 타입
 * @param value 변환할 변수
 * @returns
 */
export const dataFormatting = (formatType: string, value: number | string | string[] | number[]): string => {
  let res;
  let day;
  let hour;
  let minute;
  let seconds;

  switch (formatType) {
    case 'Date':
      if (typeof value !== 'number') return value.toString();
      day = Math.floor(value / 1000 / 60 / 60 / 24);
      hour = Math.floor((value / 1000 / 60 / 60) % 24);
      minute = Math.floor((value / 1000 / 60) % 60);
      seconds = Math.floor((value / 1000) % 60);

      hour = hour.toString().padStart(2, '0');
      minute = minute.toString().padStart(2, '0');
      seconds = seconds.toString().padStart(2, '0');

      return `${
        (day > 0 ? `${day.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}일 ` : '') + hour
      }:${minute}:${seconds}`;
    case 'Currency':
      res = typeof value === 'number' && value % 1 !== 0 ? value.toFixed(2) : value;
      res = `${res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      return res;
    case 'Number':
      res = typeof value === 'number' && value % 1 !== 0 ? value.toFixed(2) : value;
      res = res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return res;
    case 'Integer':
      res = typeof value === 'number' && value % 1 !== 0 ? Math.round(value) : value;
      res = res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return res;
    case 'Percent':
      res = typeof value === 'number' && value % 1 !== 0 ? value.toFixed(2) : value;
      res = res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return res;
    case 'Array':
      if (Array.isArray(value)) {
        return value.join(' | ');
      }
      return value.toString();
    default:
      return value.toString();
  }
};

export const displayCard = (cardInfo: string): string => {
  if (cardInfo) {
    return `(${cardInfo.substring(0, 4)}-${cardInfo.substring(4, 6)}**-****-****)`;
  }
  return '';
};
