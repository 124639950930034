import React, { ReactElement, useState, useEffect, useMemo, useRef } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { useQuery } from 'react-query';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import Flicking, { ViewportSlot, ChangedEvent, PanelChangeEvent } from '@egjs/react-flicking';
import { Pagination } from '@egjs/flicking-plugins';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss } from './PreviewFacebookItem.style';
import { getUniqKey } from '../../../../utils/array.utils';
import { FacebookApi } from '../../../../lib/api/FacebookApi';
import StorageService from '../../../../services/StorageService';
import '@egjs/flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/pagination.css';
import { filters } from '../../../../utils/filter';
import { i18nextScanKey } from '../../../../i18n';
import { IMAGE_PROXY_URL } from '../../../../utils/utils';
import { CurrencyCodeRecoil } from '../../../../recoil/Currency';

export interface PreviewFacebookItemProps {
  setting: CampaignAdSettingState;
  instagram?: boolean;
  dynamic?: 'collection' | 'slide';
  products?: Product[];
}

export interface PreviewSliderContainerProps extends PreviewFacebookItemProps {
  handleSlideIndex?: (index: number) => void;
}

export interface PreviewInstagramSingleFooterItemProps extends PreviewFacebookItemProps {
  slideIndex?: number;
}

export interface SlideImageBoxItemProps {
  slide: SlideContent | ProductSlideContent;
  cta: FacebookCTA;
  instagram?: boolean;
  type?: CampaignType;
  product?: Product;
  currentCampaign?: CampaignAdSettingState;
}
export interface SingleImageBoxProps {
  image: MallImage | ProductImage | null | undefined;
  last?: boolean;
}
export interface SingleVideoBoxProps {
  video: Video | null | undefined;
}

const facebookCTALabel = {
  shop_now: i18nextScanKey('지금 쇼핑하기'),
  apply_now: i18nextScanKey('지금 신청하기'),
  book_travel: i18nextScanKey('지금 예약하기'),
  learn_more: i18nextScanKey('더 알아보기'),
  sign_up: i18nextScanKey('가입하기'),
};

const HeaderItem: React.FC = () => {
  const i18next = useTranslation();
  const { data: facebookPage } = useQuery<{ id: string; name: string; picture: string } | undefined>(
    'facebookPage',
    async () => {
      const res = await FacebookApi().getPage(StorageService.selectedMall?.id);

      return res.data.data as { id: string; name: string; picture: string };
    },
  );

  return (
    <div className="facebook-title-box">
      <div className="logo">
        {facebookPage?.picture ? <img src={facebookPage?.picture} alt={facebookPage?.name} /> : ''}
      </div>
      <div className="media-title-wrapper">
        <div className="title">{facebookPage?.name ? facebookPage?.name : i18next.t('Facebook 페이지명')}</div>
        <div className="info">sponsered</div>
      </div>
    </div>
  );
};

const PreviewBodyItem = ({ setting }: PreviewFacebookItemProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <div className="preview-body">
      {setting?.fb_body
        ? setting?.fb_body.split('\n').map((bodyTxt, index) => {
            return (
              <span key={getUniqKey(bodyTxt, index)}>
                {bodyTxt}
                <br />
              </span>
            );
          })
        : i18next.t('입력하신 광고 문구가 여기에 보여집니다.')}
    </div>
  );
};

const PreviewCollectionBodyItem = ({ body, type }: { body: string; type: 'collection' | 'catalog' }): ReactElement => {
  const i18next = useTranslation();

  return (
    <div className="preview-body">
      {body
        ? body.split('\n').map((bodyTxt, index) => {
            return (
              <span key={getUniqKey(bodyTxt, index)}>
                {bodyTxt}
                <br />
              </span>
            );
          })
        : i18next.t('입력하신 {{type}} 광고 문구가 여기에 보여집니다.', {
            type: type === 'collection' ? i18next.t('컬렉션') : i18next.t('슬라이드'),
          })}
    </div>
  );
};

const PreviewInstagramSingleFooterItem = ({
  setting,
  dynamic,
  products,
  slideIndex,
}: PreviewInstagramSingleFooterItemProps): ReactElement => {
  const i18next = useTranslation();
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);
  const convertText = (value: string, targetProduct?: Product) => {
    let tempText = '';
    if (value === '{{product.name}}') {
      tempText = targetProduct ? targetProduct.name : i18next.t('{상품이름}');
    }

    if (value === '{{product.current_price}}') {
      tempText = targetProduct
        ? filters.formatCurrency({
            value: targetProduct.sale_price_e6 / 1e6,
            currencyCode,
            withoutUnit: currencyCode === 'KRW', // 다른 나라일 때는 $10.00 이런 식으로 나타나게 수정.
          })
        : i18next.t('{판매가격}');
    }
    if (value === '{{product.brand}} BEST 상품') {
      tempText = i18next.t('{{mallName}} BEST 상품', { mallName: StorageService.selectedMall?.name });
    }
    if (value === '{{product.price}} > {{product.current_price}}') {
      tempText = targetProduct
        ? `${filters.formatCurrency({
            value: targetProduct.base_price_e6 / 1e6,
            currencyCode,
            withoutUnit: currencyCode === 'KRW',
          })} > ${filters.formatCurrency({
            value: targetProduct.sale_price_e6 / 1e6,
            currencyCode,
            withoutUnit: currencyCode === 'KRW',
          })}`
        : i18next.t('{판매가격} > {할인가격}');
    }
    return tempText;
  };

  return (
    <React.Fragment>
      <div className="preview-action-wrapper">
        <div className="preview-action-body">
          <p className="action-description">
            {setting?.fb_call_to_action && i18next.t(facebookCTALabel[setting?.fb_call_to_action])}
          </p>
        </div>
        <div className="preview-action-link">
          <BDSFontIcon name="ic-arrow-right" color="#738696" size="20px" />
        </div>
      </div>

      <div className="preview-instagram-button-wrapper">
        <div className="left-side">
          <img src="/assets/img/card_icons/ic-instagram-heart.png" alt={i18next.t('좋아요')} />
          <img src="/assets/img/card_icons/ic-instagram-reply.png" alt={i18next.t('댓글')} />
          <img src="/assets/img/card_icons/ic-instagram-share.png" alt={i18next.t('공유하기')} />
        </div>
        <div className="right-side">
          <img src="/assets/img/card_icons/ic-instagram-save.png" alt={i18next.t('저장하기')} />
        </div>
      </div>
      {(setting?.type === 'fb_catalog_slide' || (setting?.type.includes('fb_dynamic_') && dynamic === 'slide')) && (
        <React.Fragment>
          <div className="preview-title">{`${
            setting?.fb_dynamic_slide_title
              ? convertText(setting?.fb_dynamic_slide_title, products && products[slideIndex!])
              : i18next.t('제목 템플릿이 여기에 보여집니다')
          }`}</div>
        </React.Fragment>
      )}

      {(setting?.type.includes('fb_single') ||
        setting?.type.includes('fb_slide') ||
        setting?.type.includes('fb_catalog_slide')) && <PreviewBodyItem setting={setting} />}
    </React.Fragment>
  );
};

const PreviewFacebookSingleFooterItem = ({ setting }: PreviewFacebookItemProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <div className="preview-action-wrapper">
      <div className="preview-action-body">
        <p className="action-url">{setting?.url ? setting?.url : i18next.t('입력하신 URL이 여기에 보여집니다.')}</p>
        <p className="action-body">
          {setting?.fb_single_title ? setting?.fb_single_title : i18next.t('입력하신 광고 제목이 여기에 보여집니다.')}
        </p>
        <p className="action-description">
          {setting?.fb_single_description
            ? setting?.fb_single_description
            : i18next.t('입력하신 광고 설명이 여기에 보여집니다.')}
        </p>
      </div>
      <div className="preview-action-link">
        <div className="preview-action-button">
          {setting?.fb_call_to_action && i18next.t(facebookCTALabel[setting?.fb_call_to_action])}
        </div>
      </div>
    </div>
  );
};

const PreviewSingleImageBox = ({ image }: SingleImageBoxProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <div className={`preview-img-box ${image === null || image === undefined ? 'empty' : ''}`}>
      {image && <img src={`${IMAGE_PROXY_URL}${image.url}`} alt={i18next.t('싱글 이미지')} />}
      {(image === null || image === undefined) && <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />}
    </div>
  );
};

const PreviewSingleVideoBox = ({ video }: SingleVideoBoxProps): ReactElement => {
  const i18next = useTranslation();
  const [imageIndex, setImageIndex] = useState<number>(0);
  const [isHover, setIsHover] = useState<boolean>(true);
  const savedCallback = useRef<() => void>();
  let hoverInterval: number;

  const callback = () => {
    const images = video?.video.thumbnails.map((thumbnail) => thumbnail.url);
    if (images) {
      const maxIndex = images.length - 1;
      if (imageIndex < maxIndex) {
        setImageIndex(imageIndex + 1);
      } else {
        setImageIndex(0);
      }
    }
  };

  const handleMouseOver = () => {
    setIsHover(true);
  };
  const handleMouseOut = () => {
    setIsHover(false);
    if (hoverInterval) {
      window.clearInterval(hoverInterval);
    }
  };

  useEffect(() => {
    setIsHover(false);
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (!isHover) {
      setImageIndex(0);
      return () => {
        //
      };
    }

    const tick = () => {
      if (video) {
        if (savedCallback.current) {
          savedCallback.current();
        }
      }
    };

    const timer = setInterval(tick, 600);
    return () => clearInterval(timer);
  }, [isHover]);

  return (
    <div
      className={`preview-img-box ${video === null || video === undefined ? 'empty' : ''}`}
      onMouseLeave={handleMouseOut}
      onMouseEnter={handleMouseOver}
    >
      {video && (
        <img
          style={{ objectFit: 'contain' }}
          src={video?.video.thumbnails[imageIndex].url}
          alt={i18next.t('단일 동영상 미리보기')}
        />
      )}
      {(video === null || video === undefined) && <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />}
      {video && !isHover && (
        <img className="icon-play" src="/assets/img/card_icons/ic-play-wh.svg" alt={i18next.t('아이콘')} />
      )}
    </div>
  );
};

const PreviewCollectionImageBox = ({ image, last }: SingleImageBoxProps): ReactElement => {
  const i18next = useTranslation();
  return (
    <div
      className={`collection-img-box ${image === null || image === undefined ? 'empty' : ''}`}
      style={{ position: 'relative' }}
    >
      {image && last && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            textAlign: 'center',
            padding: '45% 0',
            color: '#fff',
            fontWeight: 'bold',
            zIndex: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          }}
        >
          {i18next.t('더 보기')}
        </div>
      )}
      {image && <img src={`${IMAGE_PROXY_URL}${image.url}`} alt={i18next.t('싱글 이미지')} />}
      {(image === null || image === undefined) && <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />}
    </div>
  );
};

const PreviewSlideImageBoxItem = ({
  slide,
  cta,
  instagram = false,
  type,
  product,
  currentCampaign,
}: SlideImageBoxItemProps): ReactElement => {
  const i18next = useTranslation();
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const convertText = (value: string, targetProduct?: Product) => {
    let tempText = '';
    if (value === '{{product.name}}') {
      tempText = targetProduct ? targetProduct.name : i18next.t('{상품이름}');
    }
    if (value === '{{product.current_price}}') {
      tempText = targetProduct
        ? filters.formatCurrency({
            value: targetProduct.sale_price_e6 / 1e6,
            currencyCode,
            withoutUnit: currencyCode === 'KRW',
          })
        : i18next.t('{판매가격}');
    }
    if (value === '{{product.brand}} BEST 상품') {
      tempText = i18next.t('{{mallName}} BEST 상품', { mallName: StorageService.selectedMall?.name });
    }
    if (value === '{{product.price}} > {{product.current_price}}') {
      tempText = targetProduct
        ? `${filters.formatCurrency({
            value: targetProduct.base_price_e6 / 1e6,
            currencyCode,
            withoutUnit: currencyCode === 'KRW',
          })} > ${filters.formatCurrency({
            value: targetProduct.sale_price_e6 / 1e6,
            currencyCode,
            withoutUnit: currencyCode === 'KRW',
          })}`
        : i18next.t('{판매가격} > {할인가격}');
    }
    return tempText;
  };

  return (
    <div className="preview-slide-item">
      <div className={`preview-img-box ${!slide?.src ? 'empty' : ''}`}>
        {slide?.src && <img src={`${IMAGE_PROXY_URL}${slide?.src}`} alt="테스트 이미지" />}
        {!slide?.src && <BDSFontIcon name="ic-img" color="#7e8696" size="32px" />}
      </div>
      {instagram === false && (
        <div className="preview-action-wrapper">
          <div className="preview-action-body">
            {type !== 'fb_catalog_slide' && (
              <p className="action-url">{slide?.url ? slide?.url : i18next.t('URL이 여기에 보여집니다.')}</p>
            )}
            <p className="action-body">
              {type &&
                (type === 'fb_catalog_slide' || type.includes('fb_dynamic_')) &&
                (currentCampaign?.fb_dynamic_slide_title
                  ? convertText(currentCampaign?.fb_dynamic_slide_title, product)
                  : i18next.t('제목 템플릿이 여기에 보여집니다.'))}
              {type === 'fb_slide_image' && (slide?.title ? slide?.title : i18next.t('광고 제목이 여기에 보여집니다.'))}
            </p>
            <p className="action-description">
              {type &&
                (type === 'fb_catalog_slide' || type.includes('fb_dynamic_')) &&
                (currentCampaign?.fb_dynamic_slide_description
                  ? convertText(currentCampaign?.fb_dynamic_slide_description, product)
                  : i18next.t('내용 템플릿이 여기에 보여집니다.'))}
              {type === 'fb_slide_image' &&
                (slide?.description ? slide?.description : i18next.t('광고 제목이 여기에 보여집니다.'))}
            </p>
          </div>
          <div className="preview-action-link">
            <div className="preview-action-button">{cta && i18next.t(facebookCTALabel[cta])}</div>
          </div>
        </div>
      )}
    </div>
  );
};

const PreviewSliderContainer: React.FC<PreviewSliderContainerProps> = ({
  setting,
  instagram = false,
  products,
  handleSlideIndex,
}) => {
  const flickRef = useRef<Flicking>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const slideWidth = '349px';

  const slides = useMemo(() => {
    return products && products.length > 0
      ? products.map((product) => {
          const defaultImages = product.images.filter((image) => image.is_default);
          return {
            ...defaultImages[0],
            src: defaultImages[0]?.url || undefined,
            url: StorageService.selectedMall?.url,
          };
        })
      : setting?.slides;
  }, [setting, setting?.category_id, products]);

  const changedFlick = (event: ChangedEvent<Flicking>) => {
    setCurrentSlideIndex(event.index);

    if (handleSlideIndex) handleSlideIndex(event.index);
  };

  const panelChangeFlick = async (event: PanelChangeEvent<Flicking>) => {
    if (event.currentTarget.index === -1) {
      try {
        setCurrentSlideIndex(0);
        if (handleSlideIndex) handleSlideIndex(0);
        await flickRef.current?.moveTo(0);
      } catch (e) {
        //
      }
    } else {
      setCurrentSlideIndex(event.currentTarget.index);
      if (handleSlideIndex) handleSlideIndex(event.currentTarget.index);
    }
  };

  return (
    <div className={`preview-slider-container ${instagram ? 'instagram' : 'facebook'}`}>
      <div className={`preview-slider-wrap ${instagram ? 'instagram' : ''}`}>
        {slides.length > 0 && (
          <Flicking
            onReady={(e) => {
              if (instagram) e.currentTarget.addPlugins(new Pagination({ type: 'bullet' }));
            }}
            onPanelChange={panelChangeFlick}
            onChanged={changedFlick}
            onMoveEnd={(e) => setCurrentSlideIndex(e.currentTarget.index)}
            ref={flickRef}
            viewportTag="div"
            cameraTag="div"
            align="prev"
            useFindDOMNode
            bound
            inputType={['touch', 'mouse']}
          >
            {slides.map((slide, index) => {
              const keyIndex = `slide-${setting?.type}-${index}`;
              return (
                <PreviewSlideImageBoxItem
                  slide={slide}
                  cta={setting?.fb_call_to_action}
                  key={`slide-${keyIndex}`}
                  instagram={instagram}
                  type={setting?.type}
                  product={products && products[index]}
                  currentCampaign={setting}
                />
              );
            })}
            {instagram && (
              <ViewportSlot>
                <div className="flicking-pagination" ref={paginationRef} />
              </ViewportSlot>
            )}
          </Flicking>
        )}
      </div>
      {currentSlideIndex !== 0 && (
        <div
          style={{
            position: 'absolute',
            top: `${parseFloat(slideWidth) / 2}px`,
            left: '14px',
            zIndex: 10,
          }}
        >
          <button
            onClick={() => flickRef.current?.prev()}
            type="button"
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              width: 32,
              height: 32,
              padding: 6,
              borderRadius: '50%',
            }}
          >
            <BDSFontIcon name="ic-arrow-left" size="20px" color="#fff" />
          </button>
        </div>
      )}
      {slides && currentSlideIndex !== slides.length - 1 && (
        <div
          style={{
            position: 'absolute',
            top: `${parseFloat(slideWidth) / 2}px`,
            right: '14px',
            zIndex: 10,
          }}
        >
          <button
            onClick={() => flickRef.current?.next()}
            type="button"
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
              width: 32,
              height: 32,
              padding: 6,
              borderRadius: '50%',
            }}
          >
            <BDSFontIcon name="ic-arrow-right" size="20px" color="#fff" />
          </button>
        </div>
      )}
    </div>
  );
};

const getDefaultImage = (product: Product) => {
  const defaultImages = product.images.filter((image) => image.is_default);
  return defaultImages[0];
};

export const PreviewFacebookItem = ({
  setting,
  instagram = false,
  dynamic,
  products,
}: PreviewFacebookItemProps): ReactElement => {
  const i18next = useTranslation();

  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const handleSlideIndex = (index: number) => {
    setCurrentSlideIndex(index);
  };

  return (
    <div css={[baseCss]} className="preview-media-box-wrapper">
      <BorderSection style={{ overflow: 'hidden' }}>
        <HeaderItem />
        {setting?.type === 'fb_single_image' && (
          <React.Fragment>
            {!instagram && <PreviewBodyItem setting={setting} />}
            <PreviewSingleImageBox image={setting?.mall_image || setting?.product_image} />
            {instagram && <PreviewInstagramSingleFooterItem setting={setting} />}
            {!instagram && <PreviewFacebookSingleFooterItem setting={setting} />}
          </React.Fragment>
        )}
        {setting?.type === 'fb_single_video' && (
          <React.Fragment>
            {!instagram && <PreviewBodyItem setting={setting} />}
            <PreviewSingleVideoBox video={setting?.mall_video} />
            {instagram && <PreviewInstagramSingleFooterItem setting={setting} />}
            {!instagram && <PreviewFacebookSingleFooterItem setting={setting} />}
          </React.Fragment>
        )}
        {['fb_slide_image', 'fb_catalog_slide'].includes(setting?.type) && (
          <React.Fragment>
            {!instagram && <PreviewBodyItem setting={setting} />}
            <PreviewSliderContainer
              setting={setting}
              instagram={instagram}
              products={products}
              handleSlideIndex={handleSlideIndex}
            />
            {instagram && (
              <PreviewInstagramSingleFooterItem setting={setting} products={products} slideIndex={currentSlideIndex} />
            )}
          </React.Fragment>
        )}
        {setting?.type.includes('fb_collection') && (
          <React.Fragment>
            {!instagram && <PreviewBodyItem setting={setting} />}
            {setting?.type === 'fb_collection_image' && (
              <PreviewSingleImageBox image={setting?.mall_image || setting?.product_image} />
            )}
            {setting?.type === 'fb_collection_video' && <PreviewSingleVideoBox video={setting?.mall_video} />}
            {setting?.type === 'fb_collection_dynamic_video' && (
              <PreviewSingleImageBox image={products && products[0] ? getDefaultImage(products[0]) : null} />
            )}

            {!instagram && (
              <div className="preview-title" style={{ paddingTop: 8 }}>
                {setting?.fb_collection_title
                  ? setting?.fb_collection_title
                  : i18next.t('입력하신 광고 제목이 여기에 표시됩니다.')}
              </div>
            )}
            <div className={`preview-collection-wrapper ${instagram ? 'instagram' : ''}`}>
              {products && products.length !== 0 ? (
                <React.Fragment>
                  {products &&
                    products.map((product, index) => (
                      <PreviewCollectionImageBox
                        image={getDefaultImage(product)}
                        key={getUniqKey('collection-product-image', index)}
                        last={index + 1 === 3 && instagram === false}
                      />
                    ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="collection-img-box empty">
                    <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />
                  </div>
                  <div className="collection-img-box empty">
                    <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />
                  </div>
                  <div className="collection-img-box empty">
                    <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />
                  </div>
                </React.Fragment>
              )}
            </div>
            {instagram && <PreviewInstagramSingleFooterItem setting={setting} />}
            {instagram && <PreviewBodyItem setting={setting} />}
          </React.Fragment>
        )}
        {setting?.type.includes('fb_dynamic_') && dynamic && dynamic === 'collection' && (
          <React.Fragment>
            {!instagram && <PreviewCollectionBodyItem body={setting?.fb_dynamic_collection_body} type="collection" />}
            {setting?.type === 'fb_dynamic_image' && (
              <PreviewSingleImageBox image={setting?.mall_image || setting?.product_image} />
            )}
            {setting?.type === 'fb_dynamic_video' && <PreviewSingleVideoBox video={setting?.mall_video} />}
            {setting?.type === 'fb_dynamic_dynamic_video' && (
              <PreviewSingleImageBox image={products && products[0] ? getDefaultImage(products[0]) : null} />
            )}

            {!instagram && (
              <div className="preview-title" style={{ paddingTop: 8 }}>
                {setting?.fb_collection_title
                  ? setting?.fb_collection_title
                  : i18next.t('입력하신 컬렉션 제목이 여기에 표시됩니다.')}
              </div>
            )}
            <div className={`preview-collection-wrapper ${instagram ? 'instagram' : ''}`}>
              {products && products.length !== 0 ? (
                <React.Fragment>
                  {products &&
                    products.map((product, index) => (
                      <PreviewCollectionImageBox
                        image={getDefaultImage(product)}
                        key={getUniqKey('collection-product-image', index)}
                        last={index + 1 === 3 && instagram === false}
                      />
                    ))}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="collection-img-box empty">
                    <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />
                  </div>
                  <div className="collection-img-box empty">
                    <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />
                  </div>
                  <div className="collection-img-box empty">
                    <BDSFontIcon name="ic-img" color="#7e8696" size="40px" />
                  </div>
                </React.Fragment>
              )}
            </div>
            {instagram && <PreviewInstagramSingleFooterItem setting={setting} />}
            {instagram && <PreviewCollectionBodyItem body={setting?.fb_dynamic_collection_body} type="collection" />}
          </React.Fragment>
        )}
        {setting?.type.includes('fb_dynamic_') && dynamic && dynamic === 'slide' && (
          <React.Fragment>
            {!instagram && <PreviewCollectionBodyItem body={setting?.fb_dynamic_catalog_body} type="catalog" />}
            <PreviewSliderContainer
              key={getUniqKey('fb_dynamic_slide', !instagram ? 0 : 1)}
              setting={setting}
              instagram={instagram}
              products={products}
              handleSlideIndex={handleSlideIndex}
            />
            {instagram && (
              <PreviewInstagramSingleFooterItem
                setting={setting}
                dynamic={dynamic}
                products={products}
                slideIndex={currentSlideIndex}
              />
            )}
            {instagram && <PreviewCollectionBodyItem body={setting?.fb_dynamic_catalog_body} type="catalog" />}
          </React.Fragment>
        )}
      </BorderSection>
    </div>
  );
};
