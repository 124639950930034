import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { BDSButton } from '@bigin/bigin-ui-components';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import moment from 'moment';
import axios from 'axios';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { BGButtonProps } from '../../components/BGButton/BGButton';
import { BGTab, ITab } from '../../components/BGTab/BGTab';
import { baseCss, createCampaignModalCss, leftWrapper, rightWrapper } from './CreateCampaign.style';
import { Step1CampaignSetting } from './Step1CampaignSetting/Step1CampaignSetting';
import { Step2AssetSetting } from './Step2AssetSetting/Step2AssetSetting';
import CampaignSetting, { addCampaignAdSettingSelector, campaignAdSettingSelector } from '../../recoil/CampaignSetting';
import { CampaignSummary } from './CampaignSummary/CampaignSummary';
import StorageService from '../../services/StorageService';
import { MallApi } from '../../lib/api/MallApi';
import { CampaignApi } from '../../lib/api/CampaignApi';
import { convertGroups } from '../../model/CampaignModel';
import { useDataStore } from '../../context/Store';
import { WrapperModal } from '../../components/WrapperModal/WrapperModal';
import { filters } from '../../utils/filter';
import { PaymentApi } from '../../lib/api/PaymentApi';
import { ModalNewCard } from '../../components/ModalNewCard/ModalNewCard';
import { Button, DialogConfirm } from '../../components/DialogConfirm/DialogConfirm';
import { adFieldText, campaignNamingGenderRule, campaignNamingMediaRule, campaignTypeText } from '../../utils/text';
import { AW_MAX_BYTE, AW_SHORT_TITLE_MAX_BYTE, getMedia, KK_MAX_LENGTH } from '../../utils/utils';
import { BGButtonGroup } from '../../components/BGButtonGroup/BGButtonGroup';
import { BGInput } from '../../components/BGInput/BGInput';
import { getUniqKey } from '../../utils/array.utils';
import { useDeepCompareEffect } from '../../utils/use-deep-compare';
import { CurrencyCodeRecoil } from '../../recoil/Currency';
import { multilingual } from '../../utils/multilingual.util';
import SideNavBarRecoil from '../../recoil/SideNavBar';
import Plan from '../../services/plans/Plan';

enum STEP {
  STEP1 = 1,
  STEP2 = 2,
}

interface CreateCampaignForm {
  name: string;
}

interface CreateCampaignModalProps {
  name: string;
  activeSubscription: Subscription | undefined;
  handleCreditFailModal: () => void;
  close: () => void;
}

let saveTimerId: number;
const deleteTemporary = () => {
  localStorage.removeItem(`${StorageService.selectedMall?.id}_saved_campaign`);
};

const CreateCampaignModal = (props: CreateCampaignModalProps): ReactElement => {
  const i18next = useTranslation();
  const { dialogStore, toastStore } = useDataStore();

  const [name, setName] = useState<string>(props.name);
  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);
  const campaignAdState = useRecoilValue(CampaignSetting.campaignAdSetting);
  const navigate = useNavigate();
  const resetCampaignState = useResetRecoilState(CampaignSetting.campaignSetting);
  const resetCampaignAdState = useResetRecoilState(CampaignSetting.campaignAdSetting);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const { formState, register, setValue, handleSubmit } = useForm<CreateCampaignForm>({ mode: 'all' });
  useEffect(() => {
    register('name', {
      value: name,
    });
  });

  const { data: autoCharge } = useQuery<boolean | undefined>(
    'autoCharge',
    async () => (await PaymentApi().getAutoCharge(StorageService.selectedMall.id)).data.data,
  );

  const dayCount = useMemo(() => {
    if (campaignState.startOption === 'endless' || campaignState.startOption === ('' as CampaignStartOption)) {
      return 7;
    }

    const start = moment(campaignState.date.start, 'YYYY-MM-DD');
    const end = moment(campaignState.date.end, 'YYYY-MM-DD');

    return moment.duration(end.diff(start)).asDays() + 1;
  }, [campaignState.startOption, campaignState.date.start, campaignState.date.end]);

  const handleCampaignActivation = async ({
    createCampaignId,
    isAutoCharge,
  }: {
    createCampaignId: number;
    isAutoCharge?: boolean;
  }) => {
    try {
      await CampaignApi().patchUnifiedCampaignStatus(StorageService.selectedMall.id, createCampaignId, 'active');
      setIsClicked(false);
      navigate(`/malls/${StorageService.selectedMall?.id}/campaign-dashboard`);
      toastStore.showToast('basic', 'success', i18next.t('캠페인 생성이 완료되었습니다.'));

      if (isAutoCharge) {
        toastStore.showToast('basic', 'success', i18next.t('광고 집행을 위해 필요한 크레딧이 자동 충전되었습니다.'));
      }
      useQueryClient().invalidateQueries('remain'); // 캠페인 생성 후 잔액 최신화
      resetCampaignState();
      resetCampaignAdState();
      window.clearInterval(saveTimerId);
      deleteTemporary();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const response = error?.response;
        if (response && response.status === 404) {
          dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);
        }
      }
    } finally {
      props.close();
    }
  };

  const onSubmit = async () => {
    if (!props.activeSubscription) return;
    setIsClicked(true);
    let createCampaignId;
    const groups = await convertGroups(campaignAdState, 'create');
    const config = {
      onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
      },
    };

    try {
      const createCampaignRes = await CampaignApi().postUnifiedCampaign({
        mallId: StorageService.selectedMall?.id,
        plan: props.activeSubscription.type,
        settings: { ...campaignState, name },
        campaigns: campaignAdState.filter((campaign) => campaign),
        groups,
        config,
        currency: currencyCode,
        country: multilingual.getCountryByCurrencyCode({ currency: currencyCode }),
      });
      createCampaignId = createCampaignRes.data.data.id;
    } catch (e) {
      console.error(e);
      return;
    }

    if (Plan.isManagedType(props.activeSubscription.type)) {
      //  managed 플랜의 경우에는 자동 크레딧 충전 옵션이 없고, 따로 해외 송금 처리가 된다.
      handleCampaignActivation({ createCampaignId });
    } else {
      let successCharge = true;
      let successAutoCharge = false;
      if (campaignState.chargeCredit > 0 && campaignState.chargeAgree) {
        try {
          const res = await PaymentApi().chargeCredits(
            StorageService.selectedMall.id,
            campaignState.chargeCredit * 1e6,
          );
          if (res) {
            successCharge = true;
            successAutoCharge = true;
          }
        } catch (e) {
          successCharge = false;
        }
      } else if (campaignState.chargeCredit > 0 && campaignState.chargeAgree === false) {
        successCharge = false;
      }

      if (!successCharge) {
        setIsClicked(false);
        props.close();
        props.handleCreditFailModal();
      } else {
        handleCampaignActivation({
          createCampaignId,
          isAutoCharge: (autoCharge && campaignState.chargeCredit > 0) || successAutoCharge,
        });
      }
    }
  };

  return (
    <div css={[createCampaignModalCss]}>
      <div className="create-campaign-header">
        <div className="text-h1 text-color-main">{i18next.t('캠페인 생성')}</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="create-campaign-body">
          <p className="text-input text-color-main">
            {i18next.t('앞서 설정한 내용으로 캠페인을 생성할게요.')}
            <br />
            {i18next.t('캠페인 명을 입력하고 설정한 캠페인의 일 예산과 노출 기간을 확인해 주세요.')}
          </p>
          <p className="text-h5 text-color-sub" style={{ marginTop: 20 }}>
            {i18next.t('캠페인 명')}
          </p>
          <BGInput
            style={{ marginTop: 8 }}
            error={!!formState.errors.name}
            inputProps={{
              value: name,
              placeholder: i18next.t('캠페인 명을 입력해주세요.'),
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('name', event.target.value, { shouldValidate: true });
                setName(event.target.value);
              },
            }}
          />
          <div className="horizontal-divider" />
          <div className="field-wrapper">
            <p className="text-h5 text-color-sub" style={{ width: '52px' }}>
              {i18next.t('일 예산')}
            </p>
            <p className="text-h5 text-color-sub">
              {filters.formatCurrency({ value: campaignState.budget, currencyCode })}
            </p>
          </div>
          <div className="horizontal-divider" />
          <div className="field-wrapper">
            <p className="text-h5 text-color-sub" style={{ width: '52px' }}>
              {i18next.t('노출기간')}
            </p>
            <p className="text-h5 text-color-sub">
              {campaignState.startOption === 'endless' && <div>{i18next.t('오늘부터 계속 게재')}</div>}
              {campaignState.startOption && campaignState.date?.end && (
                <div>{`${campaignState.date.start} ~ ${campaignState.date.end}`}</div>
              )}
              {campaignState.startOption && campaignState.date?.end && (
                <div style={{ fontWeight: 'normal', marginTop: '4px' }}>
                  {i18next.t('(총 {{days}}일)', {
                    days: dayCount,
                  })}
                </div>
              )}
            </p>
          </div>
          <div className="horizontal-divider" />
          <p className="text-disclaimer">{i18next.t('*캠페인 생성 후, 광고 유형별 검토 과정이 이루어집니다.')}</p>
        </div>
        <div className="horizontal-divider" style={{ width: '320px', margin: '16px -20px' }} />
        <div className="create-campaign-footer">
          <BGButtonGroup rightAlign>
            <BDSButton
              key={i18next.t('취소') as string}
              label={i18next.t('취소')}
              onClick={() => {
                props.close();
              }}
              appearance="secondary"
            />
            <BDSButton label={i18next.t('생성')} type="submit" isDisabled={!formState.isValid || isClicked} />
          </BGButtonGroup>
        </div>
      </form>
    </div>
  );
};

export const CreateCampaign = (): ReactElement => {
  const i18next = useTranslation();
  const [topNavButton, setTopNavButton] = useState<BGButtonProps[] | []>([]);
  const [step, setStep] = useState<number>(STEP.STEP1);
  // const campaignState = useRecoilValue(CampaignSetting.campaignSetting);
  const [campaignState, setCampaignState] = useRecoilState(CampaignSetting.campaignSetting);
  const campaignAdState = useRecoilValue(CampaignSetting.campaignAdSetting);
  const syncCampaignAdState = useSetRecoilState(campaignAdSettingSelector);
  const addCampaignAdState = useSetRecoilState(addCampaignAdSettingSelector);
  const setSideBarCollpased = useSetRecoilState(SideNavBarRecoil.isCollapsed);
  const navigate = useNavigate();
  const queryParams = useParams();
  const resetCampaignState = useResetRecoilState(CampaignSetting.campaignSetting);
  const resetCampaignAdState = useResetRecoilState(CampaignSetting.campaignAdSetting);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const [createCampaignModal, setCreateCampaignModal] = useState<boolean>(false);
  const [creditChargeFailModal, setCreditChargeFailModal] = useState<boolean>(false);
  const [campaignValidateState, setCampaignValidateState] = useState<CampaignValidateState[]>([]);
  const [saveTemporaryModal, setSaveTemporaryModal] = useState('');
  const [loadTemporaryModal, setLoadTemporaryModal] = useState('');
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [totalErrors, setTotalErrors] = useState<any[]>([]);
  const [step1Errors, setStep1Errors] = useState<string[]>([]);

  const topNavTab = [
    {
      key: 'campaign-setting',
      label: i18next.t('캠페인 설정'),
    },
    {
      key: 'asset-setting',
      label: i18next.t('광고 소재 설정'),
    },
  ];

  const [selectedTopNavTab, setSelectedTopNavTab] = useState<ITab>(topNavTab[0]);
  const [disabledNextStep, setDisabledNextStep] = useState<boolean>(true);
  const [disabledCreateCampaign, setDisabledCreateCampaign] = useState<boolean>(true);

  const validateNextStep = (currentStep: STEP) => {
    if (currentStep === STEP.STEP1) {
      if (campaignState.gender.length === 0) return true;
      if (campaignState.selectedTypes.length === 0) return true;
      if (campaignState.startOption === ('' as CampaignStartOption)) return true;
      if (campaignState.selectedGoal === ('' as CampaignGoal)) return true;
      if (campaignState.selectedGoal === 'purchase_leads' && campaignState.involvements.length === 0) return true;
      if (
        campaignState.selectedTypes.length * multilingual.getMinimumBudgetByCurrency({ currency: currencyCode }) >
        campaignState.budget
      )
        return true;
      return false;
    }
    return true;
  };

  const defaultCampaignName = useMemo(() => {
    const medias = campaignAdState.map((campaign) => getMedia(campaign.type));
    const mediaNames = medias.map((media) => campaignNamingMediaRule[media]);
    const uniqueMedia = mediaNames.filter((item, index) => mediaNames.indexOf(item) === index).join('&');
    const gender = campaignState.gender.map((g) => campaignNamingGenderRule[g]).join('&');
    const convertAge = {
      '0': 18,
      '20': 25,
      '40': 35,
      '60': 45,
      '80': 55,
      '100': 65,
    } as any;

    return `${uniqueMedia}_${multilingual.getCountryByCurrencyCode({ currency: currencyCode })}_${gender}_${
      convertAge[campaignState.ageRange[0]]
    }_${convertAge[campaignState.ageRange[1]]}`;
  }, [campaignAdState]);

  const closeModal = () => {
    setSaveTemporaryModal('');
    setLoadTemporaryModal('');
    setCreditChargeFailModal(false);
  };

  /** 결제 실패 모달 버튼 */
  const paymentFailedBtns = useMemo((): Button[] => {
    const defaultBtns = [
      {
        label: i18next.t('확인'),
        appearance: 'secondary',
        handleClick: () => {
          navigate(`/malls/${StorageService.selectedMall?.id}/campaign-dashboard`);
          closeModal();
          deleteTemporary();

          resetCampaignState();
          resetCampaignAdState();
          window.clearInterval(saveTimerId);
        },
      },
      {
        label: i18next.t('결제 업데이트'),
        handleClick: () => {
          navigate(`/malls/${StorageService.selectedMall?.id}/payment`);
          closeModal();
          deleteTemporary();

          resetCampaignState();
          resetCampaignAdState();
          window.clearInterval(saveTimerId);
        },
      },
    ];

    // 싱가포르 프로젝트에서 현재 결제는 제공하지 않으므로 결제 업데이트 버튼 안보이게 처리.
    switch (currencyCode) {
      case 'SGD':
        return [defaultBtns[0]];
      case 'KRW':
      default:
        return defaultBtns;
    }
  }, [
    currencyCode,
    navigate,
    closeModal,
    deleteTemporary,
    resetCampaignAdState,
    resetCampaignAdState,
    window.clearInterval,
  ]);

  const handleTopNavTab = (newStep: STEP) => {
    if (newStep === STEP.STEP1) {
      setSelectedTopNavTab(topNavTab[0]);
    } else if (newStep === STEP.STEP2) {
      setSelectedTopNavTab(topNavTab[1]);
    }
  };

  const [newCardModal, setNewCardModal] = useState<boolean>(false);
  const { data: card, refetch: refetchCard } = useQuery<Card>(
    'card',
    async () => (await PaymentApi().getRegisteredCard(StorageService.selectedMall.id)).data.data,
  );

  const { data: activeSubscription } = useQuery<Subscription | undefined>('activeSubscription', async () => {
    const res = await MallApi().getMall(StorageService.selectedMall.id, { includes: ['active_subscription'] });

    return res.data.active_subscription;
  });

  const requireVariables = (type: CampaignType): CampaignContent[] => {
    // 단일 슬라이드
    if (type === 'fb_single_image') return ['fb_body', 'fb_single_title', 'fb_single_description', 'src', 'url'];
    if (type === 'fb_slide_image') return ['fb_body', 'slides'];
    if (type === 'aw_responsive_image') return ['src', 'aw_description', 'aw_long_title', 'aw_short_title', 'url'];
    if (type === 'kk_image_banner') return ['src', 'url'];
    if (type === 'kk_image_native') return ['kk_title', 'src', 'url'];

    // 영상
    if (type === 'fb_single_video') return ['fb_body', 'fb_single_description', 'mall_video_id', 'url'];
    if (type === 'aw_youtube') return ['aw_description', 'aw_short_title', 'url', 'aw_youtube_url', 'aw_long_title'];

    // 컬렉션
    if (type === 'fb_catalog_slide')
      return ['fb_body', 'fb_dynamic_slide_description', 'fb_dynamic_slide_title', 'category_id'];
    if (type === 'fb_collection_image') return ['fb_body', 'fb_collection_title', 'url', 'src', 'category_id'];
    if (type === 'fb_collection_video')
      return ['fb_body', 'fb_collection_title', 'url', 'mall_video_id', 'category_id'];
    if (type === 'fb_collection_dynamic_video') return ['fb_body', 'fb_collection_title', 'url', 'category_id'];

    // 구글 쇼핑
    if (type === 'aw_shopping') return [];
    if (type === 'aw_smart_shopping') return ['aw_description', 'aw_long_title', 'aw_short_title', 'url', 'src'];

    const requireDynamic: CampaignContent[] = [
      'url',
      'fb_dynamic_collection_body',
      'fb_dynamic_catalog_body',
      'fb_collection_title',
      'category_id',
      'fb_dynamic_slide_description',
      'fb_dynamic_slide_title',
    ];

    // 다이나믹
    if (type === 'fb_dynamic_image') return [...requireDynamic, 'src'];
    if (type === 'fb_dynamic_video') return [...requireDynamic, 'mall_video_id'];
    if (type === 'fb_dynamic_dynamic_video') return requireDynamic;
    if (type === 'aw_dynamic_remarketing')
      return ['category_id', 'aw_description', 'aw_long_title', 'aw_short_title', 'url', 'src'];

    // 구글 검색
    if (type === 'aw_search') return [];
    // 구글 실적 최대화
    if (type === 'aw_pmax')
      return ['aw_pmax_long_headlines', 'aw_pmax_headlines', 'url', 'aw_pmax_descriptions', 'aw_pmax_src'];

    return [];
  };

  const errors = () => {
    const totalErrorArray = [] as any;
    campaignAdState.forEach((campaign) => {
      const variables = requireVariables(campaign.type);
      const errorArray = [] as {
        variable: CampaignContent;
        message: string;
      }[];

      variables.forEach((variable) => {
        if (variable === 'src') {
          if (
            !campaign.mall_image_id &&
            !campaign.product_image_id &&
            !campaign.kk_banner_mall_image_id &&
            !campaign.kk_banner_product_image_id &&
            !campaign.kk_native_mall_image_id &&
            !campaign.kk_native_product_image_id
          ) {
            errorArray.push({
              variable,
              message: `no ${variable}`,
            });
          }
        } else if (variable === 'aw_pmax_src') {
          // 구글 실적최대화 캠페인은 1:1 비율, 1.91:1 비율 이미지 id 둘다 있어야함. (상품 정보가 있다면 product_id 없다면 mall_id)
          if (!campaign.mall_image_id && !campaign.product_image_id) {
            errorArray.push({
              variable,
              message: `no ${variable}`,
            });
          }

          if (!campaign.aw_landscape_product_image_id && !campaign.aw_landscape_mall_image_id) {
            errorArray.push({
              variable,
              message: `no ${variable}`,
            });
          }
        } else if (variable === 'slides') {
          const validLength = campaign.slides.length > 1;
          const validVariable = campaign.slides.every((slide) => {
            const urlCheck = validator.isURL(slide.url);
            return (
              urlCheck === true &&
              (slide.product_image_id || slide.mall_image_id) &&
              slide.description.length > 0 &&
              slide.title.length > 0 &&
              slide.url.length > 0
            );
          });
          if (!validLength || !validVariable)
            errorArray.push({
              variable,
              message: `no ${variable}`,
            });
        } else if (['aw_short_title', 'aw_long_title', 'aw_description', 'aw_short_description'].includes(variable)) {
          const awValue = campaign[variable] as string;
          let awResult = !!campaign[variable] || (i18next.t('필수 입력란 형식입니다.') as string);

          if (variable === 'aw_short_title') {
            if (filters.formatByte(campaign[variable]) > AW_SHORT_TITLE_MAX_BYTE)
              awResult = i18next.t('최대 입력 byte를 초과하였습니다.') as string;
          } else if (filters.formatByte(awValue) > AW_MAX_BYTE)
            awResult = i18next.t('최대 입력 byte를 초과하였습니다.') as string;

          if (awResult !== true) errorArray.push({ variable, message: awResult });
        } else if (variable === 'kk_title') {
          let kkResult = !!campaign[variable] || (i18next.t('필수 입력란 형식입니다.') as string);

          if (campaign[variable].length > KK_MAX_LENGTH)
            kkResult = i18next.t('최대 입력 글자 수를 초과하였습니다.') as string;
          if (kkResult !== true) errorArray.push({ variable, message: kkResult });
        } else if (variable === 'url') {
          let urlResult = !!campaign[variable] || (i18next.t('필수 입력란 형식입니다.') as string);

          if (!validator.isURL(campaign[variable])) urlResult = i18next.t('잘못된 URL 형식입니다.') as string;
          if (urlResult !== true) errorArray.push({ variable, message: urlResult });
        } else if (['aw_pmax_long_headlines', 'aw_pmax_headlines', 'aw_pmax_descriptions'].includes(variable)) {
          // 구글 실적 최대화 유효 검사.
          if (variable === 'aw_pmax_long_headlines') {
            const result = campaign[variable].length === 1 || (i18next.t('필수 입력란 입니다.') as string);
            if (result !== true) errorArray.push({ variable, message: result });
          } else if (variable === 'aw_pmax_descriptions') {
            const result =
              (!!campaign[variable][0] && !!campaign[variable][1]) || (i18next.t('필수 입력란 입니다.') as string);
            if (result !== true) errorArray.push({ variable, message: result });
          } else if (variable === 'aw_pmax_headlines') {
            const result =
              (!!campaign[variable][0] && !!campaign[variable][1] && !!campaign[variable][2]) ||
              (i18next.t('필수 입력란 입니다.') as string);
            if (result !== true) errorArray.push({ variable, message: result });
          }
        } else {
          const result = !!campaign[variable] || (i18next.t('필수 입력란 입니다.') as string);
          if (result !== true) errorArray.push({ variable, message: result });
        }
      });
      if (errorArray.length !== 0) {
        totalErrorArray.push({ type: campaign.type, errors: errorArray });
      }
    });

    setTotalErrors(totalErrorArray);

    return totalErrorArray.length !== 0;
  };

  const setTopNavBtn = () => {
    const btn = [];
    if (step === STEP.STEP2) {
      btn.push({
        id: 0,
        label: i18next.t('이전'),
        appearance: 'secondary',
        onClick: () => {
          setStep(step - 1);
          handleTopNavTab(step - 1);
        },
        style: { marginRight: '8px' },
      });
    }

    btn.push({
      id: 1,
      label: i18next.t('임시 저장'),
      appearance: 'secondary',
      onClick: () => {
        setSaveTemporaryModal('save');
      },
      style: { marginRight: '8px' },
    });

    if (step === STEP.STEP2) {
      if (totalErrors.length !== 0) {
        btn.push({
          id: 5,
          isUnclickable: true,
          className: 'isDisabled',
          label: i18next.t('캠페인 생성'),
          hoverable: false,
          tooltip: (
            <React.Fragment>
              <div>
                <div>{i18next.t('입력 값을 확인해주세요.')}</div>
                {totalErrors.map((error) => {
                  const errorItems = error.errors.map(
                    (item: { variable: string | number }, itemIndex: string | number) => (
                      <div key={getUniqKey('create-validation-error-step2', itemIndex)}>{`· ${i18next.t(
                        adFieldText[item.variable],
                      )}`}</div>
                    ),
                  );

                  return (
                    <React.Fragment key={error}>
                      <div>&nbsp;</div>
                      <div>{i18next.t(campaignTypeText[error.type])}</div>
                      {errorItems}
                    </React.Fragment>
                  );
                })}
              </div>
            </React.Fragment>
          ),
        });
      } else {
        btn.push({
          id: 4,
          isDisabled: disabledCreateCampaign,
          label: i18next.t('캠페인 생성'),
          onClick: async () => {
            setCreateCampaignModal(true);
            return null;
          },
        });
      }
    }

    if (step === STEP.STEP1) {
      if (step1Errors.length !== 0) {
        btn.push({
          id: 3,
          label: i18next.t('다음'),
          isUnclickable: true,
          className: 'isDisabled',
          hoverable: false,
          tooltip: (
            <React.Fragment>
              <div>
                <div>{i18next.t('입력 값을 확인해주세요.')}</div>
                {step1Errors.map((item, index) => (
                  <div key={getUniqKey('create-validation-error-step1', index)}>{`· ${item}`}</div>
                ))}
              </div>
            </React.Fragment>
          ),
        });
      } else {
        btn.push({
          id: 2,
          label: i18next.t('다음'),
          isDisabled: disabledNextStep,
          onClick: () => {
            syncCampaignAdState({ types: campaignState.selectedTypes, goal: campaignState.selectedGoal });
            setTimeout(() => {
              setStep(step + 1);
              handleTopNavTab(step + 1);
            }, 10);
          },
        });
      }
    }

    setTopNavButton(btn);
  };

  const setCurrentValid = (status: CampaignValidateState[]) => {
    setCampaignValidateState(status);
  };

  const saveTemporary = () => {
    window.clearInterval(saveTimerId);
    saveTimerId = window.setTimeout(() => {
      const saveData = {
        setting: campaignState,
        campaigns: campaignAdState,
      };
      localStorage.setItem(`${StorageService.selectedMall?.id}_saved_campaign`, JSON.stringify(saveData));
    }, 3000);
    setSaveTemporaryModal('');
  };

  useDeepCompareEffect(() => {
    setDisabledNextStep(validateNextStep(step));
    const formValid = campaignValidateState.every((item) => item.isValid === true);
    setDisabledCreateCampaign((errors() && !formValid) || campaignState.selectedTypes.length <= 0);
    setTopNavBtn();
  }, [
    step,
    disabledNextStep,
    disabledCreateCampaign,
    campaignState,
    campaignAdState,
    campaignValidateState,
    currencyCode,
  ]);

  useDeepCompareEffect(() => {
    setTopNavBtn();
  }, [totalErrors]);

  const savedDataKey = `${StorageService.selectedMall?.id}_saved_campaign`;
  const savedData = localStorage.getItem(savedDataKey);
  const isLoadSaveDataImmediate = queryParams.load === 'true';

  const loadTemporaryData = () => {
    if (savedData) {
      const data = JSON.parse(savedData) as {
        setting: CampaignSettingState;
        campaigns: CampaignAdSettingState[];
      };
      setCampaignState(data.setting);
      data.campaigns.forEach((campaign) => {
        addCampaignAdState(campaign);
      });
    }
  };

  // componentDidMount
  useEffect(() => {
    (function checkSaveData() {
      if (savedData) {
        if (isLoadSaveDataImmediate) {
          loadTemporaryData();
          navigate(`/malls/${StorageService.selectedMall.id}/create-campaign`, { replace: true });
        } else {
          setLoadTemporaryModal('load');
        }
      }
    })();

    resetCampaignState();
    resetCampaignAdState();
    setIsFirstLoad(false);

    // componentWillUnmount
    return () => {
      resetCampaignState();
      resetCampaignAdState();
    };
  }, []);

  // componentDidUpdate
  useEffect(() => {
    if (!isFirstLoad) {
      saveTemporary();
    }

    const tempValid = [];
    if (campaignState.gender.length === 0) tempValid.push(i18next.t('성별'));
    if (campaignState.selectedTypes.length === 0) tempValid.push(i18next.t('광고 유형'));
    if (campaignState.startOption === ('' as CampaignStartOption)) tempValid.push(i18next.t('캠페인 노출 기간'));
    if (campaignState.selectedGoal === ('' as CampaignGoal)) tempValid.push(i18next.t('캠페인 목표'));
    if (campaignState.selectedGoal === 'purchase_leads' && campaignState.involvements.length === 0)
      tempValid.push(i18next.t('구매 활성화 타겟'));
    if (
      campaignState.selectedTypes.length * multilingual.getMinimumBudgetByCurrency({ currency: currencyCode }) >
      campaignState.budget
    )
      tempValid.push(i18next.t('일 예산'));
    setStep1Errors(tempValid);
  }, [campaignState, campaignAdState]);

  useEffect(() => {
    // 1320 이하에서는 사이드바 접히도록 설정
    const handleResize = () => {
      if (window.innerWidth <= 1920) {
        setSideBarCollpased(true);
      } else {
        setSideBarCollpased(false);
      }
    };
    handleResize();

    // Create 들어왔을 때 1320px 이하의 브라우저라면 사이드바 접기.
    window.addEventListener('resize', handleResize);
    return () => {
      setSideBarCollpased(false);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <div css={[baseCss]}>
        <TopNavBar
          style={{ border: 0 }}
          title={
            <div
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {i18next.t('캠페인 생성')}
            </div>
          }
          button={topNavButton}
        />
        <BGTab
          fixed
          showArrow
          tabList={topNavTab}
          selectedTab={selectedTopNavTab}
          handleTab={(tab) => {
            if (tab.key === 'campaign-setting') {
              setStep(STEP.STEP1);
              setSelectedTopNavTab(tab);
            } else if (tab.key === 'asset-setting') {
              if (!disabledNextStep) {
                setStep(STEP.STEP2);
                syncCampaignAdState({ types: campaignState.selectedTypes, goal: campaignState.selectedGoal });
                setSelectedTopNavTab(tab);
              }
            }
          }}
        />
        <div className="create-campaign-container flex-row">
          <div css={[leftWrapper]} className={`left-wrapper flex-col ${step === STEP.STEP2 && 'step2'}`}>
            {step === STEP.STEP1 && <Step1CampaignSetting key="create-campaign-step1" />}
            {step === STEP.STEP2 && <Step2AssetSetting key="create-campaign-step2" setCurrentValid={setCurrentValid} />}
          </div>
          <div css={[rightWrapper]} className="right-wrapper flex-col">
            <CampaignSummary handleCardModal={setNewCardModal} card={card} step={step} />
          </div>
        </div>
      </div>

      {/* 캠페인 생성 모달 */}
      <WrapperModal
        position={{ width: '320px' }}
        isOpen={createCampaignModal}
        close={() => {
          setCreateCampaignModal(false);
        }}
      >
        <CreateCampaignModal
          name={defaultCampaignName}
          activeSubscription={activeSubscription}
          close={() => {
            setCreateCampaignModal(false);
          }}
          handleCreditFailModal={() => {
            setCreditChargeFailModal(true);
          }}
        />
      </WrapperModal>

      {/* 카드 등록/수정 모달 */}
      <WrapperModal
        position={{ width: '360px' }}
        isOpen={newCardModal}
        close={() => {
          setNewCardModal(false);
        }}
      >
        <ModalNewCard
          card={card}
          refetch={refetchCard}
          close={() => {
            setNewCardModal(false);
          }}
        />
      </WrapperModal>

      {/* 임시 저장 모달 */}
      <WrapperModal isOpen={saveTemporaryModal} close={closeModal} isDialogConfirm>
        <DialogConfirm
          title={i18next.t('캠페인 임시 저장')}
          desc={i18next.t(
            '설정한 캠페인을 임시 저장하시겠어요?\n먼저 저장된 캠페인이 있다면 지금 생성한 캠페인으로 대체되어 저장됩니다.',
          )}
          buttons={[
            { label: i18next.t('취소'), appearance: 'secondary', handleClick: closeModal },
            { label: i18next.t('저장'), handleClick: saveTemporary },
          ]}
        />
      </WrapperModal>

      {/* 임시 저장 불러오기 모달 */}
      <WrapperModal isOpen={loadTemporaryModal} isDialogConfirm>
        <DialogConfirm
          title={i18next.t('캠페인 생성')}
          desc={i18next.t('임시 저장한 캠페인이 있어요.\n해당 캠페인을 불러올까요?')}
          buttons={[
            {
              label: i18next.t('새롭게 생성'),
              appearance: 'secondary',
              handleClick: () => {
                deleteTemporary();
                closeModal();
              },
            },
            {
              label: i18next.t('불러오기'),
              handleClick: () => {
                loadTemporaryData();
                closeModal();
              },
            },
          ]}
        />
      </WrapperModal>

      {/* 결제 실패 모달 */}
      <WrapperModal isOpen={creditChargeFailModal} isDialogConfirm>
        <DialogConfirm
          title={i18next.t('결제 실패')}
          desc={i18next.t('캠페인이 생성되었으나 결제에 실패하였습니다.\n결제 정보를 업데이트 해주세요.')}
          buttons={paymentFailedBtns}
        />
      </WrapperModal>
    </React.Fragment>
  );
};
