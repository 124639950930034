import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';

import { baseCss } from './InputError.style';

export interface InputErrorProps {
  key?: string;
  message: string;
}

export const InputError = (props: InputErrorProps) => {
  return (
    <div css={[baseCss]}>
      <div className="input-error" key={props.key || props.message}>
        <span>{props.message}</span>
      </div>
    </div>
  );
};
