import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    height: 36px;
    padding: 0 12px;
    border-radius: 8px;
    background-color: #f9fafb;
    border: solid 1px #dce0e8;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      cursor: pointer;
      border: solid 1px #b8beca;
      color: ${theme.colors.mainFont};
      input {
        cursor: pointer;
        color: ${theme.colors.mainFont};
      }
    }

    &:focus-within {
      cursor: text;
      border: solid 1px #3399ff;
      color: ${theme.colors.mainFont};
      input {
        color: ${theme.colors.mainFont};
      }
    }
    input {
      border: 0;
      width: 20%;
      background-color: #f9fafb;
      text-align: center;
      font-size: 14px;
      color: ${theme.colors.mainFont};
      ${theme.text.input};

      &:focus {
        cursor: text;
        color: ${theme.colors.mainFont};
      }
    }
    .input-card-divider {
      ${theme.text.input};
      color: #777788;
      padding: 0 15px;
    }
  `;
};
