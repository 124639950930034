import React, { ChangeEvent, ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { css, jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSCalendar, DateRange, BDSButton, BDSFontIcon, BDSToggleButton } from '@bigin/bigin-ui-components';
import moment from 'moment';
import { useQuery, useQueryClient } from 'react-query';
import axios, { AxiosPromise } from 'axios';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BGPage } from '../../components/BGPage/BGPage';

import {
  baseCss,
  leftWrapper,
  rightWrapper,
  creditChargeModalCss,
  creditHistoryCss,
  creditHistoryDetailModalCss,
  creditRefundModalCss,
  paymentCardCss,
  tableCss,
} from './ManagePayment.style';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { BorderSection } from '../../components/BorderSection/BorderSection';
// import { Toggle } from '../../components/Toggle/Toggle';
import StorageService from '../../services/StorageService';
import { PaymentApi } from '../../lib/api/PaymentApi';
import { MallApi } from '../../lib/api/MallApi';
import { filters } from '../../utils/filter';
import { BGPagination } from '../../components/BGPagination/BGPagination';
import { WrapperModal } from '../../components/WrapperModal/WrapperModal';
import { BGTab, ITab } from '../../components/BGTab/BGTab';
import { InputError } from '../../components/InputError/InputError';
import { BGInput } from '../../components/BGInput/BGInput';
import { BGButtonGroup } from '../../components/BGButtonGroup/BGButtonGroup';
import { useDataStore } from '../../context/Store';
import { ModalNewCard } from '../../components/ModalNewCard/ModalNewCard';
import { PlanModal } from './PlanModal/PlanModal';
import PlanBuilder from '../../services/plans/PlanBuilder';
import { CurrencyCodeRecoil } from '../../recoil/Currency';
import { getUniqKey } from '../../utils/array.utils';

interface CreditHistoryDetailModalProps {
  historyRange: DateRange;
  close: () => void;
}

const CreditHistoryDetailModal = (props: CreditHistoryDetailModalProps): ReactElement => {
  const { i18n, ...i18next } = useTranslation();
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  enum Tab {
    CHARGE = 'charge',
    SPEND = 'spend',
  }
  const tabs: ITab[] = [
    {
      key: Tab.CHARGE,
      label: i18next.t('충전 내역'),
    },
    {
      key: Tab.SPEND,
      label: i18next.t('차감 내역'),
    },
  ];
  const [selectedTab, setSelectedTab] = useState<ITab>(tabs[0]);

  const { data: credits } = useQuery<CreditUsage[]>('credits', async () => {
    const res = await PaymentApi().getCreditList(
      StorageService.selectedMall.id,
      [props.historyRange.start.format('YYYY-MM-DD'), props.historyRange.end.format('YYYY-MM-DD 23:59:59')].join(','),
    );

    return res.data.data;
  });

  return (
    <div css={[creditHistoryDetailModalCss]} style={{ height: '640px' }}>
      <div className="header">
        <div className="text-h1 text-color-main">{i18next.t('광고비 내역 상세보기')}</div>
        <BDSFontIcon
          className="delete-icon"
          name="ic-close"
          size="20px"
          color="#7e8696"
          handleClick={() => {
            if (props.close) {
              props.close();
            }
          }}
        />
      </div>
      <div className="body">
        <BGTab style={{ marginBottom: '20px' }} tabList={tabs} selectedTab={selectedTab} handleTab={setSelectedTab} />

        {/* 충전 내역 */}
        <div className="table-wrapper">
          {selectedTab.key === Tab.CHARGE && (
            <div css={[tableCss]}>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '180px' }}>{i18next.t('날짜')}</th>
                    <th style={{ width: '300px' }}>{i18next.t('구분')}</th>
                    <th style={{ width: '160px' }}>{i18next.t('충전 금액')}</th>
                  </tr>
                </thead>
                <tbody>
                  {credits &&
                    credits
                      .filter((item) => item.amount_e6 > 0)
                      .map((item) => (
                        <tr key={item.id}>
                          <td>{moment(item.created_at).format('YYYY.MM.DD')}</td>
                          <td>
                            <p>{i18next.t(item.type_name)}</p>
                            {/* TODO: 임시로 영문 버전일 때 note 숨기기 */}
                            {i18n.language === 'ko' && item.note && (
                              <p style={{ fontSize: '12px', color: '#999' }}>{item.note}</p>
                            )}
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            {filters.formatCurrency({ value: item.amount_e6 / 1e6, currencyCode })}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          )}
          {/* 차감 내역 */}
          {selectedTab.key === Tab.SPEND && (
            <div css={[tableCss]}>
              <table>
                <thead>
                  <tr>
                    <th style={{ width: '180px' }}>{i18next.t('날짜')}</th>
                    <th style={{ width: '300px' }}>{i18next.t('광고명')}</th>
                    <th style={{ width: '160px' }}>{i18next.t('지출 금액')}</th>
                  </tr>
                </thead>
                <tbody>
                  {credits &&
                    credits
                      .filter((item) => item.amount_e6 <= 0)
                      .map((item) => (
                        <tr>
                          <td>{moment(item.created_at).format('YYYY.MM.DD')}</td>
                          <td>
                            <p>{item.uni_campaign?.name || i18next.t(item.type_name)}</p>
                            {/* TODO: 임시로 영문 버전일 때 note 숨기기 */}
                            {i18n.language === 'ko' && item.note && (
                              <p style={{ fontSize: '12px', color: '#999' }}>{item.note}</p>
                            )}
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            {filters.formatCurrency({ value: item.amount_e6 / 1e6, currencyCode })}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface CreditHistoryTableProps {
  items: (DateCreditUsage | null)[];
}

const CreditHistoryTable = (props: CreditHistoryTableProps): ReactElement => {
  const i18next = useTranslation();
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  return (
    <div css={[tableCss]}>
      <table>
        <thead>
          <tr>
            <th>{i18next.t('날짜')}</th>
            <th>{i18next.t('충전 금액')}</th>
            <th>{i18next.t('지출 금액')}</th>
            <th>{i18next.t('보유 크레딧')}</th>
          </tr>
        </thead>
        <tbody>
          {props.items.map((dateCredit, index) => {
            if (!dateCredit) {
              return (
                <tr key={getUniqKey('empty', index)}>
                  <td style={{ height: '40px' }} />
                  <td style={{ height: '40px' }} />
                  <td style={{ height: '40px' }} />
                  <td style={{ height: '40px' }} />
                </tr>
              );
            }

            return (
              <tr key={getUniqKey(dateCredit.date, index)}>
                <td>{moment(dateCredit.date).format('YYYY.MM.DD')}</td>
                <td style={{ textAlign: 'right' }}>
                  <span>{dateCredit.charged_amount_e6 !== 0 ? '+' : ''}&nbsp;</span>
                  {filters.formatCurrency({ value: dateCredit.charged_amount_e6 / 1e6, currencyCode })}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {filters.formatCurrency({ value: dateCredit.spent_amount_e6 / 1e6, currencyCode })}
                </td>
                <td style={{ textAlign: 'right' }}>
                  {filters.formatCurrency({ value: dateCredit.remain_e6_after_this / 1e6, currencyCode })}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

interface CreditHistoryProps {
  historyRange: DateRange;
  items: DateCreditUsage[];
  setHistoryRange: (range: DateRange) => void;
}

const CreditHistory = (props: CreditHistoryProps): ReactElement => {
  const { i18n } = useTranslation();
  const i18next = useTranslation();

  const COUNT_PER_PAGE = 10;
  const [activePage, setActivePage] = useState<number>(1);
  const [curItems, setCurItems] = useState<(DateCreditUsage | null)[]>([]);
  const [creditHistoryDetailModal, setCreditHistoryDetailModal] = useState<boolean>(false);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  useEffect(() => {
    const start = (activePage - 1) * COUNT_PER_PAGE;
    const end = start + COUNT_PER_PAGE;
    let newItems = props.items.slice(start, end);
    newItems = newItems.concat(new Array(COUNT_PER_PAGE - newItems.length).fill(null));

    setCurItems(newItems);
  }, [activePage, props.items]);
  useEffect(() => {
    setActivePage(1);
  }, [props.items]);

  return (
    <div css={[creditHistoryCss]}>
      <BorderSection style={{ padding: '20px', marginBottom: '20px', overflow: 'hidden' }}>
        <div className="credit-history-header">
          <div className="text-h2 text-color-main">{i18next.t('광고비 내역')}</div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 12 }}>
            <BDSButton
              appearance="secondary"
              fontIcon="ic-file"
              label={i18next.t('상세 보기')}
              onClick={() => {
                setCreditHistoryDetailModal(true);
              }}
            />
            <div className="calendar">
              <BDSCalendar
                style={{
                  minWidth: '220px',
                  height: '36px',
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                }}
                preLabel=""
                disableFuture
                initDateRange={props.historyRange}
                dateHandle={(range) => {
                  props.setHistoryRange(range);
                }}
                language={i18n.language === 'ko' ? 'ko' : 'en'}
              />
            </div>
          </div>
        </div>
        <BorderSection
          style={{
            border: 'unset',
            outline: '1px solid #DCE0E8',
            padding: '16px',
            marginBottom: '16px',
            boxShadow: 'none',
            width: '50%',
            float: 'right',
          }}
        >
          <div className="credit-history-summary">
            <div className="summary-item">
              <span className="summary-item-title">{i18next.t('기간 내 총 충전 금액')}</span>
              <span>&nbsp;</span>
              <span className="summary-item-price">
                {filters.formatCurrency({
                  value: props.items.reduce((acc, cur) => acc + cur.charged_amount_e6, 0) / 1e6,
                  currencyCode,
                })}
              </span>
            </div>
            <div className="summary-item">
              <span className="summary-item-title">{i18next.t('기간 내 총 지출 금액')}</span>
              <span>&nbsp;</span>
              <span className="summary-item-price">
                {filters.formatCurrency({
                  value: Math.abs(props.items.reduce((acc, cur) => acc + cur.spent_amount_e6, 0) / 1e6),
                  currencyCode,
                })}
              </span>
            </div>
          </div>
        </BorderSection>
        <div className="credit-history-body">
          <CreditHistoryTable items={curItems} />
        </div>
      </BorderSection>
      <div className="credit-history-footer">
        <BGPagination
          isHideCountSelector
          curPageItemLength={10}
          totalCount={props.items.length}
          countPerPage={COUNT_PER_PAGE}
          activePage={activePage}
          isLoaded
          isHideOnLess={false}
          handleActivePage={(curActivePage) => {
            setActivePage(curActivePage);
          }}
        />
      </div>

      {/* 광고비 내역 상세보기 모달 */}
      <WrapperModal
        isOpen={creditHistoryDetailModal}
        close={() => {
          setCreditHistoryDetailModal(false);
        }}
      >
        <CreditHistoryDetailModal
          close={() => {
            setCreditHistoryDetailModal(false);
          }}
          historyRange={props.historyRange}
        />
      </WrapperModal>
    </div>
  );
};

interface CreditRefundForm {
  credit: number;
}

interface CreditRefundModalProps {
  remain: number;
  close: () => void;
}

const CreditRefundModal = (props: CreditRefundModalProps): ReactElement => {
  const i18next = useTranslation();
  const { dialogStore, toastStore } = useDataStore();

  const [credit, setCredit] = useState<number>(Math.floor(props.remain));

  const maxCredit = useMemo(() => Math.floor(props.remain), [props.remain]);

  const { formState, register, setValue, handleSubmit } = useForm<CreditRefundForm>({ mode: 'all' });
  useEffect(() => {
    register('credit', {
      value: credit,
      required: i18next.t('필수 입력란입니다.') as string,
      min: {
        value: 0,
        message: i18next.t('{{price}}크레딧 이상이어야 합니다.', {
          price: filters.formatNumber(0),
        }),
      },
      max: {
        value: maxCredit,
        message: i18next.t('{{price}}크레딧 이하이어야 합니다.', {
          price: filters.formatNumber(maxCredit),
        }),
      },
    });
  });

  const onSubmit = () => {
    return MallApi()
      .createTask(StorageService.selectedMall.id, { task_type: 'refund_credit', data: { amount_e6: credit * 1e6 } })
      .then(() => {
        toastStore.showToast('basic', 'success', i18next.t('크레딧 환불 신청이 정상적으로 접수되었습니다.'));
        props.close();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <div css={[creditRefundModalCss]}>
      <div className="credit-refund-header">
        <div className="text-h1 text-color-main">{i18next.t('크레딧 환불')}</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="credit-refund-body">
          <BGInput
            style={{ display: 'none' }}
            error={!!formState.errors.credit}
            inputProps={{
              type: 'number',
              max: maxCredit,
              value: credit,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('credit', Number(event.target.value), { shouldValidate: true });
                setCredit(Number(event.target.value));
              },
            }}
            rightLabelComponent={<span className="credit-input-right-label">{i18next.t('크레딧')}</span>}
          />
          {formState.errors.credit && <InputError message={formState.errors.credit.message as string} />}
          <p className="text-input text-color-main mb-4">
            {i18next.t(
              '남아있는 크레딧 {{price}}원을 환불하시겠어요?\n크레딧 환불이 접수되면 3일 이내에 연락을 드리겠습니다.',
              { price: filters.formatNumber(credit) },
            )}
          </p>
          <p className="text-disclaimer">*{i18next.t('기재된 기간은 영업일 기준입니다.')}</p>
        </div>
        <div className="credit-refund-footer">
          <BGButtonGroup rightAlign>
            <BDSButton
              key={i18next.t('취소') as string}
              label={i18next.t('취소')}
              onClick={() => {
                props.close();
              }}
              appearance="secondary"
            />
            <BDSButton label={i18next.t('환불')} type="submit" isDisabled={!formState.isValid} />
          </BGButtonGroup>
        </div>
      </form>
    </div>
  );
};

interface CreditChargeForm {
  credit: number;
}

interface CreditChargeModalProps {
  remain: number;
  close: () => void;
}

const CreditChargeModal = (props: CreditChargeModalProps): ReactElement => {
  const i18next = useTranslation();
  const { dialogStore, toastStore } = useDataStore();
  const queryClient = useQueryClient();

  const TAX_RATE = 10;

  const [credit, setCredit] = useState<number>(0);
  const [creditRefundModal, setCreditRefundModal] = useState<boolean>(false);
  const { formState, register, setValue, handleSubmit } = useForm<CreditChargeForm>({ mode: 'all' });

  useEffect(() => {
    setCredit(50000);
    setValue('credit', 50000, { shouldValidate: true });
  }, []);

  useEffect(() => {
    register('credit', {
      required: i18next.t('필수 입력란입니다.') as string,
      min: {
        value: 1000,
        message: i18next.t('{{price}}크레딧 이상이어야 합니다.', {
          price: filters.formatNumber(1000),
        }),
      },
    });
  });
  const handleCreditChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    target.value = target.value.replace(/[^0-9.]/gi, '').replace(/(\..*)\./gi, '$1');
    const value = parseInt(target.value.replaceAll(',', ''), 10);

    setValue('credit', value, { shouldValidate: true });
    setCredit(value || 0);
  };

  const creditTax = useMemo(() => Math.floor(credit * (TAX_RATE / 100)), [credit]);
  const creditTotal = useMemo(() => credit + creditTax, [credit, creditTax]);

  const onSubmit = () => {
    return PaymentApi()
      .chargeCredits(StorageService.selectedMall.id, credit * 1e6)
      .then(() => {
        toastStore.showToast('basic', 'success', i18next.t('크레딧이 정상적으로 충전 되었습니다.'));
        queryClient.invalidateQueries(); // 모든 query key를 날려서 refetch 되도록 했습니다.(toast를 띄워야 해서 새로고침은 제거)
        props.close();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <div css={[creditChargeModalCss]}>
      <div className="header">
        <div className="text-h1 text-color-main">{i18next.t('크레딧 충전')}</div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="body">
          <div className="form-input-wrapper">
            <p className="text-h4 text-color-main mb-2">{i18next.t('희망 충전 크레딧')}</p>
            <BGInput
              error={!!formState.errors.credit}
              inputProps={{
                type: 'text',
                name: 'credit',
                value: filters.formatNumber(credit),
                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                  handleCreditChange(event);
                },
              }}
              rightLabelComponent={<span className="credit-input-right-label">{i18next.t('크레딧')}</span>}
            />
            {formState.errors.credit && <InputError message={formState.errors.credit.message as string} />}

            <div className="info">
              <p>*{i18next.t('1크레딧 = 1원입니다.')}</p>
              <p>*{i18next.t('최종 결제 금액에는 10% 부가세가 추가됩니다.')}</p>
            </div>
          </div>

          <div className="summary">
            <BorderSection style={{ padding: '16px' }}>
              <div className="summary-item">
                <span>{i18next.t('결제 금액')}</span>
                <span>{filters.formatCurrency({ value: credit, currencyCode: 'KRW' })}</span>
              </div>
              <div className="summary-item">
                <span>{i18next.t('부가세 ({{rate}}%)', { rate: TAX_RATE })}</span>
                <span>{filters.formatCurrency({ value: creditTax, currencyCode: 'KRW' })}</span>
              </div>
              <div className="divider" />
              <div className="summary-item bold">
                <span>{i18next.t('최종 결제 금액')}</span>
                <span>{filters.formatCurrency({ value: creditTotal, currencyCode: 'KRW' })}</span>
              </div>
            </BorderSection>
          </div>
        </div>

        <div className="footer">
          <span
            className="credit-refund-button"
            onClick={() => {
              setCreditRefundModal(true);
            }}
          >
            {i18next.t('크레딧 환불')}
          </span>
          <BGButtonGroup rightAlign>
            <BDSButton
              key={i18next.t('취소') as string}
              label={i18next.t('취소')}
              onClick={() => {
                props.close();
              }}
              appearance="secondary"
            />
            <BDSButton label={i18next.t('충전')} type="submit" isDisabled={credit < 1000} />
          </BGButtonGroup>
        </div>
      </form>

      {/* 크레딧 환불 모달 */}
      <WrapperModal
        position={{ width: '320px' }}
        isOpen={creditRefundModal}
        close={() => {
          setCreditRefundModal(false);
        }}
      >
        <CreditRefundModal
          remain={props.remain}
          close={() => {
            setCreditRefundModal(false);
          }}
        />
      </WrapperModal>
    </div>
  );
};

export const ManagePayment = (): ReactElement => {
  const i18next = useTranslation();
  const navigate = useNavigate();

  const [historyRange, setHistoryRange] = useState<DateRange>({ start: moment().add(-1, 'months'), end: moment() });
  const [subscriptionPage, setSubscriptionPage] = useState<number>(1);
  const [planModal, setPlanModal] = useState<boolean>(false);
  const [creditChargeModal, setCreditChargeModal] = useState<boolean>(false);
  const [newCardModal, setNewCardModal] = useState<boolean>(false);

  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const { data: bill } = useQuery<Bill>(
    'bill',
    async () =>
      (await PaymentApi().findBill(StorageService.selectedMall.id, moment().year(), moment().month() + 1)).data.data,
  );
  const { data: card, refetch: refetchCard } = useQuery<Card>(
    'card',
    async () => (await PaymentApi().getRegisteredCard(StorageService.selectedMall.id)).data.data,
  );
  const { data: remain } = useQuery<number>(
    'remain',
    async () => (await PaymentApi().getBalance(StorageService.selectedMall.id)).data.data.remain,
  );
  const { data: autoCharge, refetch: refetchAutoCharge } = useQuery<boolean | undefined>(
    'autoCharge',
    async () => (await PaymentApi().getAutoCharge(StorageService.selectedMall.id)).data.data,
  );
  const { data: activeSubscription } = useQuery<Subscription | undefined>('activeSubscription', async () => {
    const res = await MallApi().getMall(StorageService.selectedMall.id, { includes: ['active_subscription'] });

    return res.data.active_subscription;
  });
  const { data: dateCredits, refetch: refetchDateCredits } = useQuery<DateCreditUsage[]>('dateCredits', async () => {
    const res = await PaymentApi().getDateCreditList(
      StorageService.selectedMall.id,
      [historyRange.start.format('YYYY-MM-DD'), historyRange.end.format('YYYY-MM-DD 23:59:59')].join(','),
    );

    return res.data.data;
  });
  const { refetch: refetchSubscriptions } = useQuery<{
    data: SubscribeUsage[];
    meta: SubscribeUsabeMeta;
  }>('subscriptions', async () => {
    const res = await PaymentApi().getSubscriptions(
      StorageService.selectedMall.id,
      [historyRange.start.format('YYYY-MM-DD'), historyRange.end.format('YYYY-MM-DD 23:59:59')].join(','),
      subscriptionPage,
    );

    return res.data;
  });

  const plan = useMemo(() => {
    if (!activeSubscription) {
      return undefined;
    }

    return PlanBuilder.build(activeSubscription.type, i18next);
  }, [activeSubscription?.type]);

  const updateAutoCharge = (isSelect: boolean) => {
    let promise: AxiosPromise;
    if (!card) {
      setNewCardModal(true);
      return null;
    }

    if (isSelect) {
      promise = PaymentApi().setAutoCharge(StorageService.selectedMall.id);
    } else {
      promise = PaymentApi().offAutoCharge(StorageService.selectedMall.id);
    }

    return promise.then(() => refetchAutoCharge());
  };

  useEffect(() => {
    setSubscriptionPage(1);
    refetchDateCredits();
    refetchSubscriptions();
  }, [historyRange]);

  useEffect(() => {
    refetchSubscriptions();
  }, [subscriptionPage]);

  return (
    <BGPage>
      <TopNavBar title={i18next.t('결제 관리')} />
      <div className="payment-container" css={baseCss}>
        <div css={[leftWrapper]} className="left-wrapper flex-col">
          <div className="credit-history-wrapper">
            {dateCredits && (
              <CreditHistory items={dateCredits} historyRange={historyRange} setHistoryRange={setHistoryRange} />
            )}
          </div>
        </div>

        {/** 한국어일 때 지원. 현재는 한국어가 아니면 안보이게 처리. */}
        {currencyCode === 'KRW' && (
          <div css={[rightWrapper]} className="right-wrapper flex-col">
            <div className="payment-info-container">
              <div
                className="flex-col"
                css={[
                  css`
                    width: 100%;
                  `,
                  paymentCardCss,
                ]}
              >
                <div className="payment-card-wrapper" style={{ position: 'relative' }}>
                  <div className="name-wrapper">
                    <div className="name">{i18next.t('사용 중인 플랜')}</div>
                    {plan?.type !== 'smartstore' && (
                      <div className="button-wrapper">
                        <BDSButton
                          label={i18next.t('플랜 변경')}
                          appearance="secondary"
                          onClick={() => {
                            navigate(`/malls/${StorageService.selectedMall.id}/plan`);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="plan-title-panel"
                    onClick={() => {
                      setPlanModal(true);
                    }}
                    onMouseOver={() => false}
                    onMouseLeave={() => false}
                    onFocus={() => false}
                  >
                    <React.Fragment>
                      <div className="plan-title-left">
                        <img src={plan?.image} alt="plan-icon" className="plan-icon" />
                        <div className="plan-title-item">
                          <div className="plan-name-wrapper">
                            <div className="plan-name">
                              {plan && plan?.name}{' '}
                              {plan?.type === 'smartstore' && <div className="badge">{i18next.t('오픈마켓')}</div>}
                              {!plan && '-'}
                            </div>
                          </div>
                          <div className="title-cycle">
                            {bill &&
                              i18next.t('월 최대 예산 {{price}}원', {
                                price: filters.formatNumber(bill.budget_limit_e6 / 1e6),
                              })}
                            {!bill &&
                              i18next.t('월 광고 예산 {{price}}원 미만', {
                                price: filters.formatNumber(5000000),
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="plan-title-right">
                        <BDSFontIcon name="ic-arrow-right" color="#7e8696" size="20px" />
                      </div>
                    </React.Fragment>
                  </div>
                  <div className="plan-info-wrapper">
                    <div className="plan-info-panel">
                      {activeSubscription &&
                        activeSubscription.type !== 'brandmall' &&
                        activeSubscription.type !== 'smartstore' && (
                          <div className="plan-info-list-panel">
                            <React.Fragment>
                              <div className="plan-info-list">
                                <div className="info-label">{i18next.t('이용 기간')}</div>
                                {activeSubscription ? (
                                  <div className="info-value">
                                    <p>{moment(activeSubscription.subscribed_at).format('YYYY.MM.DD')}</p>

                                    <p>&nbsp;~&nbsp;</p>
                                    <p>{moment(activeSubscription.expired_at).format('YYYY.MM.DD')}</p>
                                  </div>
                                ) : (
                                  <div className="info-value">
                                    <p>-</p>
                                  </div>
                                )}
                              </div>
                              <div className="divide-vertical" />
                            </React.Fragment>
                            <div className="plan-info-list">
                              <div className="info-label">{i18next.t('결제 예정')}</div>
                              <div className="info-value">
                                {activeSubscription?.next_scheduled_at
                                  ? moment(activeSubscription.next_scheduled_at).format('YYYY.MM.DD')
                                  : '-'}
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="payment-card-wrapper" style={{ position: 'relative' }}>
                  <div className="name-wrapper">
                    <div className="name">{i18next.t('예산 관리')}</div>
                    <div className="button-wrapper">
                      <BDSButton
                        label={i18next.t('크레딧 충전')}
                        onClick={() => {
                          setCreditChargeModal(true);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="plan-title-panel"
                    onClick={() => {
                      setNewCardModal(true);
                    }}
                    onMouseOver={() => false}
                    onMouseLeave={() => false}
                    onFocus={() => false}
                  >
                    <React.Fragment>
                      <div className="plan-title-left">
                        <div className="plan-title-item">
                          <div className="card-no-exist">
                            <BDSFontIcon name="ic-card" color="#7e8696" size="20px" />
                            {card ? (
                              <p>
                                {i18next.t('{{issuer}}카드', { issuer: card.issuer_name })}
                                {`(****-****-****-${card.last_4digit})`}
                              </p>
                            ) : (
                              <p>{i18next.t('프로젝트에 등록된 카드가 없습니다.')}</p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="plan-title-right">
                        <BDSFontIcon name="ic-arrow-right" color="#7e8696" size="20px" />
                      </div>
                    </React.Fragment>
                  </div>
                  <div className="plan-info-wrapper">
                    <div className="plan-info-panel">
                      <div className="plan-info-list-panel">
                        <div className="plan-info-list">
                          <div className="info-label">{i18next.t('크레딧')}</div>
                          <div className="info-value">
                            <BDSFontIcon name="ic-credit" color="#7e8696" size="20px" />
                            <p>&nbsp;</p>
                            {filters.formatCurrency({ value: Number(remain), currencyCode })}
                          </div>
                        </div>
                        <div className="divide-vertical" />
                        <div className="plan-info-list">
                          <div className="info-label">{i18next.t('자동 충전')}</div>
                          <div className="info-value">
                            <BDSToggleButton active={!!autoCharge} onChangeToggle={updateAutoCharge} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* 사용 중인 플랜 모달 */}
      {plan && (
        <WrapperModal
          position={{ width: '320px', overflow: 'unset' }}
          isOpen={planModal}
          close={() => {
            setPlanModal(false);
          }}
        >
          <PlanModal
            plan={plan}
            close={() => {
              setPlanModal(false);
            }}
          />
        </WrapperModal>
      )}

      {/* 크레딧 충전 모달 */}
      {(remain || remain === 0) && (
        <WrapperModal
          position={{ width: '360px' }}
          isOpen={creditChargeModal}
          close={() => {
            setCreditChargeModal(false);
          }}
        >
          <CreditChargeModal
            remain={remain}
            close={() => {
              setCreditChargeModal(false);
            }}
          />
        </WrapperModal>
      )}

      {/* 카드 등록/수정 모달 */}
      <WrapperModal
        position={{ width: '360px' }}
        isOpen={newCardModal}
        close={() => {
          setNewCardModal(false);
        }}
      >
        <ModalNewCard
          card={card}
          refetch={refetchCard}
          close={() => {
            setNewCardModal(false);
          }}
        />
      </WrapperModal>
    </BGPage>
  );
};
