import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

export interface BGNewAlarmProps {
  isActiveAlarm: boolean;
  wrapperStyle?: object;
  iconWrapperStyle?: object;
  iconStyle?: object;
  children?: JSX.Element | JSX.Element[];
}

const NewAlarmWrapper = styled.div<{ theme: Theme }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NewAlarmIconWrapper = styled.div<{ theme: Theme }>`
  position: absolute;
  top: -3px;
  right: -2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  padding: 2px;
  background-color: ${(props) => props.theme.colors.bg1};
  border-radius: 50%;
  box-sizing: border-box;
`;

const NewAlarmIcon = styled.div<{ theme: Theme }>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.error};
`;

export const BGNewAlarm = (props: BGNewAlarmProps): ReactElement => {
  const { children, isActiveAlarm, wrapperStyle, iconWrapperStyle, iconStyle } = props;
  const theme: Theme = useTheme();

  return (
    <NewAlarmWrapper className="bg-new-alarm-wrapper" theme={theme} style={wrapperStyle}>
      {isActiveAlarm && (
        <NewAlarmIconWrapper className="bg-new-alarm-icon-wrapper" theme={theme} style={iconWrapperStyle}>
          <NewAlarmIcon className="bg-new-alarm-icon" theme={theme} style={iconStyle} />
        </NewAlarmIconWrapper>
      )}
      {children}
    </NewAlarmWrapper>
  );
};
