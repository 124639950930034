import { UseTranslationResponse } from 'react-i18next';

import Plan from './Plan';

export default class ManagedPlan extends Plan {
  constructor(i18next: UseTranslationResponse<'translation'>) {
    super();

    this.type = 'managed';
    this.image = '/assets/img/plan/ic-plan-cloud.svg';
    this.name = `${i18next.t('매니지드')} ${i18next.t('플랜')}`;
    this.description = '비즈니스 스케일업을 위한 기업 플랜';
    this.features = [
      i18next.t('맞춤 리포트를 통한 마케팅 인사이트 도출'),
      i18next.t('전담 매니저의 운영으로 빠르고 효과적인 이슈 해결'),
      i18next.t('월 최대 예산 : 무제한'),
    ];
    this.subscriptionFee = null;
    this.feeRate = null;
    this.policy = {
      management: {
        budgetLimit: true,
        commission: false,
        consult: true,
        oneOnOneChat: true,
        alimtalkPro: false,
        solutionEducation: false,
      },
      analytics: {
        biginCrm: true,
        biginSdk: true,
      },
      report: {
        campaign: true,
        weeklyPerformance: true,
      },
      creative: {
        createCatalog: true,
        updateProductImage: true,
        editTool: true,
        support: true,
      },
      media: {
        facebookImage: true,
        facebookVideo: true,
        facebookCollection: true,
        facebookDynamic: true,
        googleResponsive: true,
        googleDynamic: true,
        googleShopping: true,
        youtube: true,
        kakaoBizboard: true,
        kakaoNative: true,
      },
    };
  }
}
