import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .user-form-body {
      margin-bottom: 20px;

      .form-input {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .label {
          margin: 0 0 8px 0;
          ${theme.text.h4};
          color: ${theme.colors.mainFont};
        }

        .value {
          ${theme.text.input};
          color: ${theme.colors.mainFont};
        }
      }

      .footer {
        ${theme.text.disclaimer}
      }
    }
  `;
};
