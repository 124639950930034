import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, SerializedStyles } from '@emotion/react';
import { Container as DraggableContainer, Draggable } from 'react-smooth-dnd';
import { useTranslation } from 'react-i18next';
import { ColumnPickerStyle } from './ColumnPicker.style';
import { FontIcon } from '../FontIcon/FontIcon';
import { AllColumnType } from '../../types/BGEventDef';
import { PropertyPicker } from './PropertyPicker';
import { BGInput } from '../BGInput/BGInput';
import { BGButton } from '../BGButton/BGButton';

export interface ColumnPickerProps {
  columns: AllColumnType[];
  handleUpdate: (curColumns: AllColumnType[]) => void;
  customCss?: SerializedStyles;
}
export const ColumnPicker = (props: ColumnPickerProps): ReactElement => {
  const i18next = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [columns, setColumns] = useState<AllColumnType[]>(props.columns);
  const [selectedColumns, setSelectedColumns] = useState<AllColumnType[]>(
    props.columns.filter((curColumn) => {
      return curColumn.checked && !curColumn.fixed;
    }),
  );
  const originColumns = useRef<AllColumnType[]>(props.columns);

  // 사용자 속성 칼럼 리스트
  const propertyColumns = useMemo(() => {
    return columns.map((curColumn) => {
      if (search) {
        if (
          curColumn.displayName &&
          i18next.t(curColumn.displayName).toLowerCase().indexOf(i18next.t(search).toLowerCase()) > -1
        )
          return {
            ...curColumn,
            visible: true,
          };
        return {
          ...curColumn,
          visible: false,
        };
      }
      return { ...curColumn, visible: true };
    });
  }, [props.columns, columns, search, isOpen]);

  const fixedColumns = useMemo(() => {
    return columns.filter((curColumn) => {
      return !!curColumn.fixed;
    });
  }, [props.columns, columns]);

  const notSelectedColumns = useMemo(() => {
    return columns.filter((curColumn) => {
      return !curColumn.checked && !curColumn.fixed;
    });
  }, [props.columns, columns]);

  const updateColumns = (curColumns: AllColumnType[]) => {
    setColumns([...curColumns]);
  };

  const close = () => {
    setIsOpen(false);
    setSearch('');
    props.handleUpdate(originColumns.current);
  };

  const confirm = () => {
    setIsOpen(false);
    setSearch('');
    props.handleUpdate([...fixedColumns, ...selectedColumns, ...notSelectedColumns]);
  };

  const onDrop = (payload: any, removeIndex: any, addIndex: any) => {
    if (removeIndex !== null && addIndex !== null) {
      // swap
      const removeItem = selectedColumns[removeIndex];
      selectedColumns.splice(removeIndex, 1);
      selectedColumns.splice(addIndex, 0, removeItem);
      // swap

      // updateColumn
      setSelectedColumns([...selectedColumns]);
      // updateColumn
    }
  };

  useEffect(() => {
    setColumns(props.columns);
  }, [props.columns]);

  useEffect(() => {
    setSelectedColumns(
      columns.filter((curColumn) => {
        return curColumn.checked && !curColumn.fixed;
      }),
    );
  }, [columns]);

  useEffect(() => {
    if (isOpen) originColumns.current = props.columns;
  }, [isOpen]);

  return (
    <div css={[ColumnPickerStyle, props?.customCss]}>
      <div className="column-picker-button">
        <FontIcon
          name="ic-filter"
          size="20px"
          color="#7e8696"
          handleClick={() => {
            setIsOpen((prevValue) => !prevValue);
          }}
        />
      </div>

      {isOpen && (
        <div className="column-picker-panel">
          <div className="left-panel">
            <div className="search-wrapper">
              <BGInput
                inputProps={{
                  value: search,
                  placeholder: i18next.t('검색'),
                  onChange: (event) => {
                    setSearch(event.target.value);
                  },
                }}
              />
            </div>

            <div className="column-picker-content">
              <PropertyPicker
                propertyColumns={propertyColumns}
                handleUpdate={(curPropertyColumns) => {
                  updateColumns(curPropertyColumns);
                }}
              />
            </div>
          </div>
          <div className="right-panel">
            <div className="selected-column-count-text">
              {i18next.t('{{count}}개의 열이 선택됨.', {
                count: columns.filter((curColumn) => {
                  return curColumn.checked;
                }).length,
              })}
            </div>
            <div className="selected-column-list">
              {fixedColumns.map((curColumn) => {
                return (
                  <div className="selected-column-wrapper fixed" key={curColumn.prop}>
                    {i18next.t(curColumn.displayName.trim())}
                  </div>
                );
              })}
              <DraggableContainer
                onDrop={({ payload, removedIndex, addedIndex }) => {
                  const removeIndex = removedIndex === null ? undefined : removedIndex;
                  const addIndex = addedIndex === null ? undefined : addedIndex;
                  onDrop(payload, removeIndex, addIndex);
                }}
              >
                {selectedColumns.map((curColumn) => {
                  return (
                    <Draggable>
                      <div className="selected-column-wrapper draggable" key={curColumn.prop}>
                        <FontIcon name="ic-drag" color="#bbc" size="16px" />
                        {i18next.t(curColumn.displayName.trim())}
                      </div>
                    </Draggable>
                  );
                })}
              </DraggableContainer>
            </div>
            <div className="button-container">
              <BGButton
                onClick={() => {
                  close();
                }}
                appearance="secondary"
                label={i18next.t('취소')}
              />
              <BGButton
                onClick={() => {
                  confirm();
                }}
                appearance="default"
                label={i18next.t('확인')}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// @ts-ignore
// eslint-disable-next-line spaced-comment
/***
GridColumns

 {
   field: "session_seg.medium" // druid 시절 테이블 칼럼 명
   format: "String" // format
   groupName: "유입 및 트래픽" //
   name: "매체" // 한글 이름
   prop: "medium" // 실제 이름
   propertyType: "Session"
   tab: "PROPERTY"
   tooltip: "방문 세션의 매체 또는 utm_medium으로 수집된 값으로 분류합니다. (자연, cpc 등)"
   type: "typecast"
   visible: true //
 }






 * */
