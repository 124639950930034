import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { BDSCheckbox, BDSFontIcon } from '@bigin/bigin-ui-components';
import moment from 'moment';
import { BorderSection } from '../../../components/BorderSection/BorderSection';
import { BGTooltip } from '../../../components/BGTooltip/BGTooltip';
import { baseCss } from './CampaignSummary.style';
import StorageService from '../../../services/StorageService';
import CampaignSetting, { campaignChargeAgreeSelector, chargeCreditSelector } from '../../../recoil/CampaignSetting';
import { filters } from '../../../utils/filter';
import { getUniqKey } from '../../../utils/array.utils';
import { MallApi } from '../../../lib/api/MallApi';
import { PaymentApi } from '../../../lib/api/PaymentApi';
import { goalTypeText, targetExcludeTypeText, revisitOptimizeTypeText } from '../../../utils/text';
import { CurrencyCodeRecoil } from '../../../recoil/Currency';
import { multilingual } from '../../../utils/multilingual.util';
import Plan from '../../../services/plans/Plan';
import { DoubleArrowBtn } from './DoubleArrowBtn/DoubleArrowBtn';

export interface CampaignSummaryProps {
  handleCardModal: (status: boolean) => void;
  card?: Card;
  step: number;
}

export const CampaignSummary = (props: CampaignSummaryProps): ReactElement => {
  const i18next = useTranslation();
  const queryClient = useQueryClient();

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);
  const [, updateChargeCredit] = useRecoilState(chargeCreditSelector);
  const [chargeAgree, updateChargeAgree] = useRecoilState(campaignChargeAgreeSelector);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);
  const { data: autoCharge } = useQuery<boolean | undefined>(
    'autoCharge',
    async () => (await PaymentApi().getAutoCharge(StorageService.selectedMall.id)).data.data,
  );
  const [showSpendCredit, setShowSpendCredit] = useState<boolean>(true);
  const [showChargeCredit, setShowChargeCredit] = useState<boolean>(true);

  const [isFold, setIsFold] = useState<boolean>(false); // 캠페인 생성 시 해상도가 낮을 때 요약본이 안보이는 현상 때문에 fold 형태로 하기 위한 state

  const { data: activeSubscription } = useQuery<Subscription | undefined>('activeSubscription', async () => {
    const res = await MallApi().getMall(StorageService.selectedMall.id, { includes: ['active_subscription'] });

    return res.data.active_subscription;
  });

  const { data: remain } = useQuery<number>(
    'remain',
    async () => {
      if (!StorageService.selectedMall) {
        return 0;
      }

      return (await PaymentApi().getBalance(StorageService.selectedMall.id)).data.data.remain;
    },
    { staleTime: 1000 * 30 },
  );

  const { data: currentDailyBudget } = useQuery<number>('currentDailyBudget', async () => {
    if (!StorageService.selectedMall) {
      return 0;
    }
    const res = await PaymentApi().getDailyBudget(StorageService.selectedMall.id);

    if (res.data.data) {
      return res.data.data.daily_budget_e6;
    }
    return 0;
  });

  const purchaseLeadsText = {
    low: i18next.t('저관여 고객(상세페이지 조회)'),
    high: i18next.t('고관여 고객(장바구니 담기, 구매 시도)'),
    purchased: i18next.t('구매 고객'),
  };

  const targetExclude = () => {
    if (campaignState.targetExclude === null) {
      return i18next.t('제외 없음');
    }
    return i18next.t(targetExcludeTypeText[campaignState.targetExclude]);
  };

  const revisitOptimize = () => {
    return i18next.t(revisitOptimizeTypeText[campaignState.revisitOptimize]);
  };

  const convertGender = () => {
    if (campaignState.gender.length >= 2) {
      return i18next.t('모든 성별');
    }
    if (campaignState.gender.includes('women')) {
      return i18next.t('여성');
    }
    if (campaignState.gender.includes('men')) {
      return i18next.t('남성');
    }
    return null;
  };

  const campaignAgeRange: any = {
    '0': i18next.t('18세'),
    '20': i18next.t('25세'),
    '40': i18next.t('35세'),
    '60': i18next.t('45세'),
    '80': i18next.t('55세'),
    '100': i18next.t('65세'),
  };

  const convertAge = () => {
    if (campaignState.ageRange[0] === 0 && campaignState.ageRange[1] === 100) {
      return i18next.t('모든 연령대');
    }
    return campaignState.ageRange
      .map((age) => {
        return campaignAgeRange[age];
      })
      .join('~');
  };

  const convertOption = () => {
    if (campaignState.startOption === 'endless') {
      return i18next.t('오늘부터 계속 게재');
    }
    if (campaignState.startOption === 'period') {
      return i18next.t('시작 및 종료 날짜 지정');
    }
    return null;
  };

  const dayCount = useMemo(() => {
    if (campaignState.startOption === 'endless' || campaignState.startOption === ('' as CampaignStartOption)) {
      return 7;
    }

    const start = moment(campaignState.date.start, 'YYYY-MM-DD');
    const end = moment(campaignState.date.end, 'YYYY-MM-DD');

    return moment.duration(end.diff(start)).asDays() + 1;
  }, [campaignState.startOption, campaignState.date.start, campaignState.date.end]);

  /* 미디어 그룹별 최소예산 */
  // const mediaBasedMinBudget = () => {
  //   const mediaGroups = [
  //     ['fb_single_image', 'fb_slide_image'],
  //     ['fb_single_video'],
  //     ['fb_collection_image', 'fb_catalog_slide'],
  //     ['fb_dynamic'],
  //     ['aw_responsive_image'],
  //     ['aw_youtube'],
  //     ['aw_shopping'],
  //     ['aw_dynamic_remarketing'],
  //   ];

  //   let minBudget = 0;
  //   mediaGroups.map((group) => {
  //     if (group.some((el) => campaignState.selectedTypes.includes(el))) minBudget += 10000;
  //     return true;
  //   });

  //   return minBudget;
  // };

  const dailyBudget = campaignState.budget;
  const dailyFee = dailyBudget * (Plan.isFreeType(activeSubscription?.type ?? 'smartstore') ? 0.055 : 0);
  const dailyCredit = StorageService.activePlan.type.includes('cloud') ? dailyBudget : dailyBudget + dailyFee;
  const requiredCredit = dailyCredit * dayCount;
  const creditTax = multilingual.getTaxByCurrencyCode({ currencyCode, requiredCredit });
  const totalCredit = requiredCredit + creditTax;

  const minimumRequiredBudget = useMemo(() => {
    if (currentDailyBudget !== undefined && remain !== undefined) {
      return totalCredit + currentDailyBudget / 1e6 - remain;
    }
    return 0;
  }, [dailyBudget, requiredCredit, creditTax, currentDailyBudget]);

  const minimumRequiredBudgetWithoutTax = useMemo(() => {
    if (currentDailyBudget !== undefined && remain !== undefined) {
      return requiredCredit + currentDailyBudget / 1e6 - remain;
    }
    return 0;
  }, [dailyBudget, requiredCredit, creditTax, currentDailyBudget]);

  const isLackCredit = useMemo(() => {
    if (currentDailyBudget !== undefined && remain !== undefined) {
      return currentDailyBudget / 1e6 + requiredCredit > remain;
    }
    return false;
  }, [dailyBudget]);

  /** 통화 코드 별로 충전 필요 크레딧, 부가세 보야줘야 하는 지 안하는지 boolean */
  const showSpendCreditInfoByCurrencyCode = useMemo((): boolean => {
    switch (currencyCode) {
      case 'SGD':
        return false;
      case 'KRW':
      default:
        return true;
    }
  }, [currencyCode]);

  /**
   * 판매자 센터 연결 여부 true -> 연결, false -> 연결 x
   */
  const hasMerchantCenter = useMemo((): boolean => {
    const mall: Mall | undefined = queryClient.getQueryData(['mall'])
      ? queryClient.getQueryData(['mall'])
      : StorageService.selectedMall;
    if (Array.isArray(mall?.medias) && mall?.medias.find((media) => media.type === 'merchant')) {
      return true;
    }
    return false;
  }, [queryClient.getQueryData(['mall']), StorageService.selectedMall.id]);

  /**
   * 판매자 센터 연결 여부 UI 보여주기 || 숨기기 상태
   * 실적 최대화 캠페인 선택 시만 보여주기
   */
  const showMerchantCenter = useMemo((): boolean => {
    return campaignState.selectedTypes.includes('aw_pmax');
  }, [campaignState.selectedTypes]);

  useEffect(() => {
    updateChargeAgree(false);
    if (!autoCharge) {
      if (isLackCredit) {
        updateChargeCredit(minimumRequiredBudgetWithoutTax);
      } else {
        updateChargeAgree(true);
        updateChargeCredit(0);
      }
    } else {
      updateChargeAgree(true);
      updateChargeCredit(0);
    }
  }, [isLackCredit, minimumRequiredBudgetWithoutTax]);

  const campaignTypes: any = {
    fb_single_image: { media: 'facebook', label: i18next.t('단일 이미지') },
    fb_slide_image: { media: 'facebook', label: i18next.t('슬라이드 이미지') },
    fb_single_video: { media: 'facebook', label: i18next.t('단일 동영상') },
    fb_collection_image: { media: 'facebook', label: i18next.t('컬렉션 광고') },
    fb_catalog_slide: { media: 'facebook', label: i18next.t('카탈로그 슬라이드') },
    fb_dynamic: { media: 'facebook', label: i18next.t('다이나믹 광고') },
    aw_responsive_image: { media: 'google', label: i18next.t('반응형 이미지') },
    aw_dynamic_remarketing: { media: 'google', label: i18next.t('동적 리마케팅') },
    aw_pmax: { media: 'google', label: i18next.t('실적 최대화') },
    aw_search: { media: 'google', label: i18next.t('구글 검색') },
    aw_shopping: { media: 'google', label: i18next.t('쇼핑 광고') },
    aw_youtube: { media: 'youtube', label: i18next.t('Youtube 광고') },
    kk_image_banner: { media: 'kakao', label: i18next.t('비즈보드') },
    kk_image_native: { media: 'kakao', label: i18next.t('네이티브 디스플레이') },
  };

  const buildMediaImage = (type: string) => {
    const { media } = campaignTypes[type];
    if (media === 'facebook') {
      return (
        <React.Fragment>
          <img className="icon" src="/assets/img/card_icons/ic-channel-facebook.png" alt={media} />
          <img className="icon" src="/assets/img/card_icons/ic-channel-instagram.png" alt={media} />
        </React.Fragment>
      );
    }
    if (media === 'google') {
      return <img className="icon" src="/assets/img/card_icons/icons-ic-google.png" alt={media} />;
    }
    if (media === 'youtube') {
      return <img className="icon" src="/assets/img/card_icons/ic-channel-youtube.png" alt={media} />;
    }
    if (media === 'kakao') {
      return <img className="icon" src="/assets/img/card_icons/ic-channel-kakao.png" alt={media} />;
    }
    return null;
  };

  useEffect(() => {
    // 1320 이하에서는 사이드바 접히도록 설정
    const handleResize = () => {
      if (window.innerWidth <= 1440) {
        setIsFold(true);
      } else {
        setIsFold(false);
      }
    };

    if (props.step === 1) {
      return () => {
        setIsFold(false);
        window.removeEventListener('resize', handleResize);
      };
    }

    handleResize();

    // Create 들어왔을 때 1320px 이하의 브라우저라면 사이드바 접기.
    window.addEventListener('resize', handleResize);
    return () => {
      setIsFold(false);
      window.removeEventListener('resize', handleResize);
    };
  }, [props.step]);
  return (
    <div css={[baseCss]}>
      <section className={`campaign-summary-floating-section ${isFold ? 'fold' : ''}`}>
        {isFold && (
          <div className="double-arrow-btn-wrapper">
            <DoubleArrowBtn />
          </div>
        )}
        <BorderSection className="campaign-summary-border-section">
          <div className="campaign-summary-area detail">
            <div className="name-wrapper">
              <div className="name">{i18next.t('캠페인 명세')}</div>
            </div>
            <div className="table-wrapper">
              {activeSubscription && activeSubscription?.type !== 'smartstore' && (
                <div className="row short">
                  <span className="item-title">{i18next.t('캠페인 목표')}</span>
                  <span className="item-content">
                    {i18next.t(goalTypeText[campaignState.selectedGoal])
                      ? i18next.t(goalTypeText[campaignState.selectedGoal])
                      : ''}
                  </span>
                </div>
              )}
              <div className="row short">
                <span className="item-title">{i18next.t('타겟팅')}</span>
                <span className="item-content">
                  {campaignState.selectedGoal === 'buying_acquisition' && <div>{targetExclude()}</div>}
                  {campaignState.selectedGoal === 'revisit_leads' && <div>{revisitOptimize()}</div>}
                  {campaignState.selectedGoal === 'purchase_leads' &&
                    campaignState.involvements.map((item, index) => (
                      <div key={getUniqKey('leads-item', index)}>{purchaseLeadsText[item]}</div>
                    ))}
                  {campaignState.gender && <div>{convertGender()}</div>}
                  {campaignState.ageRange && <div>{convertAge()}</div>}
                  {currencyCode && <div>{multilingual.getTargetNationLabel({ currencyCode })}</div>}
                </span>
              </div>
              <div className="row short">
                <span className="item-title">{i18next.t('기간')}</span>
                <span className="item-content">
                  {campaignState.startOption && <div>{convertOption()}</div>}
                  {campaignState.startOption && campaignState.date?.end && (
                    <div>{`${campaignState.date.start} ~ ${campaignState.date.end}`}</div>
                  )}
                </span>
              </div>
              <div className="row short" style={{ paddingBottom: 0 }}>
                <span className="item-title">{i18next.t('캠페인 유형')}</span>
                {campaignState.selectedTypes.length !== 0 && (
                  <span className="item-content">
                    <div>{i18next.t('총 {{number}}개', { number: campaignState.selectedTypes.length })}</div>
                  </span>
                )}
              </div>
            </div>
            <div className="campaign-type-wrapper">
              {campaignState.selectedTypes.length === 0 && <div>{i18next.t('선택된 캠페인 유형이 없습니다.')}</div>}
              {campaignState.selectedTypes &&
                campaignState.selectedTypes.length !== 0 &&
                campaignState.selectedTypes.map((item) => (
                  <div
                    className="campaign-type-item"
                    key={getUniqKey('campaign-summary-type', `type-${campaignTypes[item].label}`)}
                  >
                    <span className="icon-wrapper">{buildMediaImage(item)}</span>{' '}
                    <span>{campaignTypes[item].label}</span>
                  </div>
                ))}
            </div>
          </div>
        </BorderSection>
        {showMerchantCenter && (
          <BorderSection
            className="campaign-summary-border-section"
            style={{ borderColor: hasMerchantCenter ? '#DCE0E8' : '#E65C5C' }}
          >
            <div className="campaign-summary-area merchant-center">
              <div className="table-wrapper">
                <div className="row short">
                  <span className="item-title">
                    {i18next.t('판매자 센터')}: {hasMerchantCenter ? i18next.t('연결됨') : i18next.t('연결 필요')}
                  </span>
                  {hasMerchantCenter === false && <BDSFontIcon name="ic-warning" color="#7e8696" size="20px" />}
                </div>
              </div>
            </div>
          </BorderSection>
        )}
        <BorderSection className="campaign-summary-border-section">
          <div className="campaign-summary-area payment">
            <div className="name-wrapper">
              <div className="name">{i18next.t('예산')}</div>
            </div>
            <div className="table-wrapper">
              <div className="row short border-none">
                <span className="item-title">{i18next.t('지출 예정 크레딧')}</span>
                <span className="item-content emphasis flex" onClick={() => setShowSpendCredit(!showSpendCredit)}>
                  {dailyBudget !== 0 && (
                    <div>{filters.formatCurrency({ value: dailyCredit * dayCount, currencyCode })}</div>
                  )}
                  {showSpendCreditInfoByCurrencyCode && (
                    <BDSFontIcon
                      name={showSpendCredit ? 'ic-arrow-up' : 'ic-arrow-down'}
                      size="20px"
                      color="#7e8696"
                      className="arrow"
                    />
                  )}
                </span>
              </div>
              {showSpendCredit && (
                <React.Fragment>
                  <div className="row short padding-top-unset border-none">
                    <span className="item-title">{i18next.t('일 예산')}</span>
                    <span className="item-content">
                      {dailyBudget !== 0 && <div>{filters.formatCurrency({ value: dailyBudget, currencyCode })}</div>}
                    </span>
                  </div>
                  {Plan.isFreeType(activeSubscription?.type ?? 'smartstore') && (
                    <div className="row short padding-top-unset border-none">
                      <span className="item-title">{i18next.t('종합 수수료')}</span>
                      <span className="item-content flex">
                        {dailyBudget !== 0 && <div>{filters.formatCurrency({ value: dailyFee, currencyCode })}</div>}
                        <BGTooltip
                          title={`${i18next.t(
                            '환전 수수료 등 광고 집행 과정에서 발생하는 외부 수수료 지불을 위한 비용입니다.',
                          )}`}
                        >
                          <div className="tooltip-icon-wrapper">
                            <BDSFontIcon name="ic-info" size="16px" color="#b5bcc9" />
                          </div>
                        </BGTooltip>
                      </span>
                    </div>
                  )}
                  <div
                    className={`row short padding-top-unset border-none ${
                      !autoCharge && isLackCredit ? 'border-bottom' : 'padding-bottom-unset'
                    }`}
                  >
                    <span className="item-title">{i18next.t('예산 필요 기간')}</span>
                    <span className="item-content flex">
                      {dayCount !== 0 && <div>{i18next.t('{{dayCount}}일', { dayCount })}</div>}
                      {['', 'endless'].includes(campaignState.startOption) && (
                        <BGTooltip title={`${i18next.t('종료일이 없을 경우, 최소 7일간 예산이 필요합니다.')}`}>
                          <div className="tooltip-icon-wrapper">
                            <BDSFontIcon name="ic-info" size="16px" color="#b5bcc9" />
                          </div>
                        </BGTooltip>
                      )}
                    </span>
                  </div>
                </React.Fragment>
              )}

              {!autoCharge && isLackCredit && (
                <React.Fragment>
                  <div className="row short border-none">
                    <span className="item-title">{i18next.t('충전 결제 금액')}</span>
                    <span className="item-content emphasis flex" onClick={() => setShowChargeCredit(!showChargeCredit)}>
                      {remain === 0 && minimumRequiredBudget > 0 && (
                        <div>{filters.formatCurrency({ value: minimumRequiredBudget, currencyCode })}</div>
                      )}
                      {remain !== 0 && minimumRequiredBudget > 0 && (
                        <div>
                          {filters.formatCurrency({
                            value:
                              minimumRequiredBudgetWithoutTax +
                              multilingual.getTaxByCurrencyCode({
                                currencyCode,
                                requiredCredit: minimumRequiredBudgetWithoutTax,
                              }),
                            currencyCode,
                          })}
                        </div>
                      )}
                      {showSpendCreditInfoByCurrencyCode && (
                        <BDSFontIcon
                          name={showChargeCredit ? 'ic-arrow-up' : 'ic-arrow-down'}
                          size="20px"
                          color="#7e8696"
                          className="arrow"
                        />
                      )}
                    </span>
                  </div>
                  {showChargeCredit && showSpendCreditInfoByCurrencyCode && (
                    <React.Fragment>
                      <div className="row short padding-top-unset border-none">
                        <span className="item-title">{i18next.t('충전 필요 크레딧')}</span>
                        <span className="item-content">
                          {minimumRequiredBudget !== 0 && (
                            <div>
                              {filters.formatCurrency({ value: minimumRequiredBudgetWithoutTax, currencyCode })}
                            </div>
                          )}
                        </span>
                      </div>
                      <div className="row short padding-top-unset border-none border-bottom">
                        <span className="item-title">{i18next.t('부가세 (10%)')}</span>
                        <span className="item-content">
                          {minimumRequiredBudget !== 0 && (
                            <div>
                              {filters.formatCurrency({
                                value: multilingual.getTaxByCurrencyCode({
                                  currencyCode,
                                  requiredCredit: minimumRequiredBudgetWithoutTax,
                                }),
                                currencyCode,
                              })}
                            </div>
                          )}
                        </span>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
            {!autoCharge && isLackCredit && !Plan.isManagedType(activeSubscription?.type ?? 'smartstore') && (
              <React.Fragment>
                <div
                  className="plan-title-panel"
                  onClick={() => {
                    props.handleCardModal(true);
                  }}
                  onMouseOver={() => false}
                  onMouseLeave={() => false}
                  onFocus={() => false}
                >
                  <React.Fragment>
                    <div className="plan-title-left">
                      <div className="plan-title-item">
                        <div className="card-no-exist">
                          <div className="title-wrapper">
                            <BDSFontIcon name="ic-card" color="#7e8696" size="20px" />
                            {props.card ? (
                              <p>
                                {i18next.t('{{issuer}}카드', { issuer: props.card.issuer_name })}
                                {`(****-****-****-${props.card.last_4digit})`}
                              </p>
                            ) : (
                              <p style={{ fontSize: 14 }}>{i18next.t('결제카드 등록')}</p>
                            )}
                          </div>
                          {props.card ? (
                            <div className="secondary-button">
                              <u>{i18next.t('결제카드 변경')}</u>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                </div>
                <div className="checkbox-wrapper">
                  <BDSCheckbox
                    size={16}
                    style={{ display: 'inline-block', minWidth: 16, marginTop: 4 }}
                    checked={chargeAgree}
                    onChange={() => {
                      updateChargeAgree(!chargeAgree);
                    }}
                  />
                  <span
                    className="checkbox-label"
                    onClick={() => {
                      updateChargeAgree(!chargeAgree);
                    }}
                  >
                    {i18next.t('지출 예정 크레딧보다 부족한 크레딧은 캠페인 생성시, 자동 충전 됩니다.')}
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
        </BorderSection>
      </section>
    </div>
  );
};
