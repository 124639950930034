import { selector } from 'recoil';
import atom from './atom';

const setCategory = selector<Category[]>({
  key: 'setCategory',
  get: ({ get }) => get(atom.category),
  set: ({ set }, category) => {
    return set(atom.category, category);
  },
});

export { setCategory };
