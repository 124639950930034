import { atom } from 'recoil';

const sdkModal = atom<boolean>({
  key: 'sdkInstallModal',
  default: false,
});

export default {
  sdkModal,
};
