/** @jsxRuntime classic */
/** @jsx jsx */
import { css } from '@emotion/react';

export const MenuButtonStyle = css`
  &.active {
    .icon-button-item {
      background-color: #edf1f6;
    }
    .sub-button-panel {
      display: flex;
    }
  }

  a {
    text-decoration: none;
  }
  .icon-button-item {
    width: 100%;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    background-color: unset;
    transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    &:hover {
      background-color: #f9fafb;
      color: #3d4046;
      border-radius: 4px;
    }
    img {
      width: 20px;
    }
    .icon-button-content {
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: space-between;
      span {
        font-size: 15px;
        font-weight: bold;
        color: #53585f;
      }
    }
  }
`;
