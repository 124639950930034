/*
 * bigin Grid System
 * https://zpl.io/agxPNYR
 * */
export const GS = {
  contentMaxWidth: 1320,
  contentMinWidth: 960,
  sideBarMaxWidth: 280,
  sideBarMinWidth: 60,
  margin: 20,
  gutter: 20,
};

export const modalContentsMaxWidth = 640;
