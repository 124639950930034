import React, { useEffect, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import _ from 'lodash';
import { BDSButton } from '@bigin/bigin-ui-components';
import { ImageBox } from '../../ImageBox/ImageBox';
import { ProductApi } from '../../../../lib/api/ProductApi';
import StorageService from '../../../../services/StorageService';
import Products, { setProducts } from '../../../../recoil/Products';
import { baseCss } from './AssetUploader.style';

export interface AssetUploaderProps {
  selectedProduct?: Product;
  acceptableFileTypes?: string;
  fileType?: string;
  close: () => void;
  refetch?: () => void;
}

export const AssetUploader = ({
  selectedProduct,
  acceptableFileTypes = 'image/jpeg, image/pjpeg, image/png',
  fileType = 'image',
  close,
  refetch,
}: AssetUploaderProps): JSX.Element => {
  const inputRef = useRef<null | HTMLInputElement>(null);
  const [files, setFiles] = useState<(File & { src: string; url: string })[]>([]);
  const [buttonDisabled, setButtonDisables] = useState<boolean>(false);

  const i18next = useTranslation();

  const [productsState, setProductsState] = useRecoilState(setProducts);
  const [defaultProductState, setDefaultProductState] = useRecoilState(Products.defaultProduct);

  const handleUploadFiles = async () => {
    if (buttonDisabled) return;

    const payloads = [] as any;

    if (fileType === 'video') {
      files.forEach((file) => {
        payloads.push(
          ProductApi()
            .postVideo(file, StorageService.selectedMall.id)
            .then(() => {
              if (refetch) {
                refetch();
              }
            }),
        );
      });
    }
    if (fileType === 'image') {
      if (!selectedProduct) return;
      const { id } = selectedProduct;
      if (id === -1) {
        files.forEach((file) => {
          payloads.push(
            ProductApi()
              .updateMallUploadImage(StorageService.selectedMall.id, file)
              .then((res) => {
                setDefaultProductState({
                  id: -1,
                  name: i18next.t('상품 정보 없는 이미지'),
                  images: [res.data.data, ...defaultProductState.images] as ProductImage[],
                } as Product);
              }),
          );
        });
      } else if (id) {
        files.forEach((file) => {
          payloads.push(
            ProductApi()
              .postProductImage(StorageService.selectedMall.id, id, 'thumbnail', file)
              .then((res) => {
                setProductsState(() => {
                  const clone = _.cloneDeep(productsState);
                  const findProduct = clone.find((product) => {
                    return product.id === id;
                  });
                  if (findProduct) {
                    findProduct.images.unshift(res.data.data);
                  }
                  return clone;
                });
              }),
          );
        });
      }
    }
    setButtonDisables(true);
    await Promise.all(payloads)
      .then(() => {
        close();
      })
      .finally(() => {
        setButtonDisables(false);
      });
  };

  const previewFile = (file: File) => {
    const reader = new FileReader();
    const url = URL.createObjectURL(file);
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      setFiles((prev) => [...prev, Object.assign(file, { src: reader.result as string, url })]);
    };
  };

  useEffect(() => {
    if (files.length <= 0) {
      setButtonDisables(true);
    } else {
      setButtonDisables(false);
    }
  }, [files]);

  const inputHandler = (e: any) => {
    const fileList = (e.target as HTMLInputElement).files;
    if (!fileList) return;
    Array.from(fileList).forEach((file) => previewFile(file));
  };

  const dropHandler = (e: DragEvent) => {
    e.preventDefault();
    const uploadFiles = [] as File[];
    if (!e.dataTransfer) return;
    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const file = e.dataTransfer.items[i].getAsFile();
          if (file) {
            uploadFiles.push(file);
          }
        }
      }
    } else {
      uploadFiles.push(...Array.from(e.dataTransfer.files));
    }
    uploadFiles.forEach((file) => {
      const reg = new RegExp(acceptableFileTypes);
      const regCheck = reg.test(file.type);
      const typeCheck = acceptableFileTypes.includes(file.type);
      if (regCheck || typeCheck) previewFile(file);
    });
  };
  const dragOverHandler = (e: DragEvent) => {
    e.preventDefault();
  };

  const handelDeleteFile = (deleteFile: File) => {
    setFiles(files.filter((file) => file !== deleteFile));
    if (fileType === 'video') {
      const videos = document.getElementsByTagName('video');
      Array.from(videos).forEach((video) => video.load());
    }
  };
  const ImageWrapper = () => (
    <div style={{ height: 'calc(100% - 76px)', display: 'flex', gap: '8px', padding: '20px', overflow: 'auto' }}>
      {files.map((file) => (
        <ImageBox key={file.name} deleteMode deleteImage={() => handelDeleteFile(file)} imageUrl={file.src} />
      ))}
    </div>
  );

  const VideoWrapper = () => (
    <div style={{ height: 'calc(100% - 76px)', display: 'flex', gap: '8px', padding: '20px', overflow: 'auto' }}>
      {files.map((file) => (
        <div
          key={file.name}
          style={{
            width: 'fit-content',
            height: '88px',
            display: 'inline-block',
          }}
        >
          <video
            style={{
              height: '88px',
              display: 'inline-block',
              borderRadius: '3px',
              border: 'solid 1px #dce0e8',
            }}
            preload="auto"
          >
            <source src={file.url} type="video/mp4" />
            <track kind="captions" />
          </video>
        </div>
      ))}
    </div>
  );

  return (
    <div css={[baseCss]}>
      <input
        style={{
          display: 'none',
        }}
        type="file"
        multiple
        ref={inputRef}
        onChange={(e) => inputHandler(e)}
        accept={acceptableFileTypes}
      />
      <div className="upload-file-container">
        <div
          onDrop={(e) => dropHandler(e as unknown as DragEvent)}
          onDragOver={(e) => dragOverHandler(e as unknown as DragEvent)}
          className="upload-file-wrapper"
        >
          {files.length === 0 ? (
            <div className="empty-file-wrapper">
              <div className="text-h4 text-color-main text-center">{i18next.t('파일을 이곳으로 드래그 하세요.')}</div>
              <div className="text-input text-color-sub mt-1 mb-5">{i18next.t('또는')}</div>
              <BDSButton onClick={() => inputRef.current?.click()}>{i18next.t('파일 직접 등록')}</BDSButton>
            </div>
          ) : (
            <React.Fragment>
              {fileType === 'image' && ImageWrapper()}
              {fileType === 'video' && VideoWrapper()}
            </React.Fragment>
          )}
          <div className="footer-button-wrapper">
            <BDSButton appearance="secondary" style={{ marginRight: '12px' }} onClick={close}>
              {i18next.t('취소')}
            </BDSButton>
            <BDSButton isDisabled={buttonDisabled} onClick={handleUploadFiles}>
              {i18next.t('추가')}
            </BDSButton>
          </div>
        </div>
      </div>
    </div>
  );
};
