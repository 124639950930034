import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import _ from 'lodash';
import { useMatch } from 'react-router-dom';
import { baseCss } from './Step2AssetSetting.style';
import { FacebookSingleImage } from './FacebookSingleImage/FacebookSingleImage';
import { FacebookSingleVideo } from './FacebookSingleVideo/FacebookSingleVideo';
import CampaignSetting, {
  campaignAdSettingSelector,
  campaignAdSettingUpdateSelector,
  campaignBudgetSelector,
  campaignTypeSelector,
} from '../../../recoil/CampaignSetting';
import { FacebookSlideImage } from './FacebookSlideImage/FacebookSlideImage';
import { FacebookCatalogSlide } from './FacebookCatalogSlide/FacebookCatalogSlide';
import { FacebookSingleCollection } from './FacebookSingleCollection/FacebookSingleCollection';
import { FacebookDynamic } from './FacebookDynamic/FacebookDynamic';
import { GoogleResponsiveImage } from './GoogleResponsiveImage/GoogleResponsiveImage';
import { GoogleShopping } from './GoogleShopping/GoogleShopping';
import { GoogleDynamicRemarketing } from './GoogleDynamicRemarketing/GoogleDynamicRemarketing';
import { YoutubeVideo } from './YoutubeVideo/YoutubeVideo';
import { getUniqKey } from '../../../utils/array.utils';
import { KakaoImageBanner } from './KakaoImageBanner/KakaoImageBanner';
import { KakaoImageNative } from './KakaoImageNative/KakaoImageNative';
import { GooglePerformanceMax } from './GooglePerformaceMax/GooglePerformanceMax';
import { GoogleSearch } from './GoogleSearch/GoogleSearch';
import { multilingual } from '../../../utils/multilingual.util';
import { CurrencyCodeRecoil, showBudgetValueRecoil } from '../../../recoil/Currency';

export interface Step2AssetSettingProps {
  setCurrentValid?: (status: CampaignValidateState[]) => void;
}

export const Step2AssetSetting = (props: Step2AssetSettingProps): ReactElement => {
  const editMatch = useMatch('/malls/:mallId/edit-ad');
  const addMatch = useMatch('/malls/:mallId/add-campaign');
  const campaignAds = useRecoilValue(CampaignSetting.campaignAdSetting);

  const setCampaign = useSetRecoilState(campaignAdSettingUpdateSelector);
  const handleCamapignTypes = useSetRecoilState(campaignTypeSelector);
  const syncCampaignAdState = useSetRecoilState(campaignAdSettingSelector);
  const setShowBudgetValue = useSetRecoilState(showBudgetValueRecoil);
  const updateCampaignBudget = useSetRecoilState(campaignBudgetSelector);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);
  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);

  const [campaignValidateState, setCampaignValidateState] = useState<CampaignValidateState[]>([]);

  const handleValidState = (type: CampaignType, isValid: boolean) => {
    let tempValids = _.cloneDeep(campaignValidateState);
    const findValidIndex = tempValids.findIndex((valid) => valid.type === type);
    if (findValidIndex !== -1) {
      tempValids[findValidIndex].isValid = isValid;
    } else {
      tempValids = [...tempValids, { type, isValid }];
    }

    setCampaignValidateState(tempValids);
  };

  const getMinBudget = (selectedTypes: string[]) => {
    const generalTypeMinimumBudget = multilingual.getMinimumBudgetByCurrency({
      currency: currencyCode,
    });
    return selectedTypes.length * generalTypeMinimumBudget;
  };

  const handleBudget = (selectedTypes: string[]) => {
    const minBudget = getMinBudget(selectedTypes);

    updateCampaignBudget(minBudget);
    switch (currencyCode) {
      case 'SGD': {
        const decimalPlaceLength = multilingual.getDecimalPlaceLength({ currencyCode });
        const value = parseFloat(parseFloat(minBudget.toString().replaceAll(',', '')).toFixed(decimalPlaceLength));
        const regex = /^[\d]*\.?[\d]{0,2}$/g; // 소수점 2자리까지만
        if (regex.test(minBudget.toString().replaceAll(',', ''))) {
          setShowBudgetValue(Number.isNaN(value) ? '' : minBudget.toString().replaceAll(',', ''));
        }
        break;
      }
      default:
      case 'KRW': {
        const value = parseInt(minBudget.toString().replaceAll(',', ''), 10);
        setShowBudgetValue(Number.isNaN(value) ? '' : value.toString());
        break;
      }
    }
  };
  const getSelectedType = (type: CampaignType): CampaignType => {
    // adsSetting에 들어있는 campaign type과 campaignState에 담겨있는 type이 다르기 때문에 converting 필요.
    // ex) 캠페인 목표가 maximize_clicks이라면 aw_shopping 아니라면 aw_smart_shopping 이런식으로 들어가 있음
    if (type.includes('fb_collection')) {
      return (campaignState.selectedTypes.find((selectedType) => selectedType.includes('fb_collection')) ??
        type) as CampaignType;
    }
    if (type.includes('fb_dynamic')) {
      return (campaignState.selectedTypes.find((selectedType) => selectedType.includes('fb_dynamic')) ??
        type) as CampaignType;
    }
    if (type.includes('shopping')) {
      return (campaignState.selectedTypes.find((selectedType) => selectedType.includes('shopping')) ??
        type) as CampaignType;
    }

    return type;
  };

  /**
   * Step2 에서 캠페인 선택된 것 삭제하는 함수
   */
  const removeSelectedCampaignTypes = (type: CampaignType) => {
    // 1개만 남아있을 때는 삭제 되면 안됌.
    if (campaignState.selectedTypes.length === 1) return;
    const selectedType = getSelectedType(type);
    handleCamapignTypes(() => selectedType);
    // 선택된 캠페인 타입 바뀌면 ads 관련 recoil도 업데이트
    const filteredSelectedTypes = campaignState.selectedTypes.filter((item) => item !== selectedType);
    syncCampaignAdState({ types: filteredSelectedTypes, goal: campaignState.selectedGoal });
    // 선택된 캠페인 타입 바뀌면 budget 관련 recoil도 업데이트
    handleBudget(filteredSelectedTypes);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (props.setCurrentValid) {
      props.setCurrentValid(campaignValidateState);
    }
  }, [campaignValidateState]);

  return (
    <React.Fragment>
      <div css={[baseCss]} className="asset-setting-container" style={{ marginTop: editMatch || addMatch ? 0 : 28 }}>
        {campaignAds.map((item, index) => {
          switch (item.type) {
            case 'fb_single_image':
              return (
                <FacebookSingleImage
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'fb_single_video':
              return (
                <FacebookSingleVideo
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'fb_slide_image':
              return (
                <FacebookSlideImage
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'fb_catalog_slide':
              return (
                <FacebookCatalogSlide
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'fb_collection':
            case 'fb_collection_image':
            case 'fb_collection_video':
            case 'fb_collection_dynamic_video':
              return (
                <FacebookSingleCollection
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'fb_dynamic':
            case 'fb_dynamic_image':
            case 'fb_dynamic_video':
            case 'fb_dynamic_dynamic_video':
              return (
                <FacebookDynamic
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'aw_responsive_image':
              return (
                <GoogleResponsiveImage
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'aw_shopping':
            case 'aw_smart_shopping':
              return (
                <GoogleShopping
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'aw_pmax':
              return (
                <GooglePerformanceMax
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'aw_search':
              return (
                <GoogleSearch
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'aw_dynamic_remarketing':
              return (
                <GoogleDynamicRemarketing
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'aw_youtube':
              return (
                <YoutubeVideo
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );

            case 'kk_image_banner':
              return (
                <KakaoImageBanner
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            case 'kk_image_native':
              return (
                <KakaoImageNative
                  key={getUniqKey(item.type, index)}
                  setting={item}
                  handleUpdate={(campaign) => {
                    setCampaign(campaign);
                  }}
                  handleValid={handleValidState}
                  removeSelectedCampaignTypes={removeSelectedCampaignTypes}
                />
              );
            default:
              return <React.Fragment>{}</React.Fragment>;
          }
        })}
      </div>
    </React.Fragment>
  );
};
