import React from 'react';
import { StyledLoadingSpinnerWrapper } from './BGLoadingSpinner.style';

export interface BGLoadingSpinnerProps {
  isLoading: boolean;
  isInfiniteListSpinner?: boolean;
  style?: Object;
}

export const BGLoadingSpinner = ({
  isLoading,
  isInfiniteListSpinner = false,
  style = {},
}: BGLoadingSpinnerProps): JSX.Element => {
  return (
    <StyledLoadingSpinnerWrapper isLoading={isLoading} isInfiniteListSpinner={isInfiniteListSpinner} style={style}>
      <div className="unit-loading">
        <div className="spinner-wrap">
          <div className="circle" />
          <div className="circle" />
          <div className="circle" />
        </div>
      </div>
    </StyledLoadingSpinnerWrapper>
  );
};
