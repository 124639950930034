import { css, SerializedStyles, Theme } from '@emotion/react';

export const BGTagInputWrapperCSS = (theme: Theme): SerializedStyles => {
  const {
    colors: { bg3, inputPlaceholderText, mainFont, smallFont, inputDisabledText, error },
  } = theme;

  return css`
    label: BGTagInputWrapperCSS;

    position: relative;

    transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    width: 100%;
    height: 72px;
    padding: 12px;
    overflow-y: auto;
    line-height: 2;

    border: solid 1px #dce0e8;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #f9fafb;

    font-size: 14px;
    color: ${mainFont};

    .badge {
      padding: 2px 4px;
      background-color: ${bg3};
      border-radius: 4px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      color: ${smallFont};
      display: inline-block;

      &:not(:first-of-type) {
        margin-left: 4px;
      }
    }

    .tag-input-wrap {
      display: inline-block;
      margin-left: 2px;
    }

    .max-length-wrapper {
      display: none;
    }

    &:hover {
      cursor: pointer;
      border: solid 1px #b8beca;
      color: ${mainFont};
    }

    &:focus-within {
      cursor: text;
      border: solid 1px #3399ff;
      color: ${mainFont};

      .max-length-wrapper {
        display: flex;
      }
    }

    &.isDisabled {
      pointer-events: none;
      border: solid 1px #dce0e8;
      background-color: #ebeef2;
      color: ${inputDisabledText};

      input {
        background-color: #ebeef2;
      }
    }

    &.isError {
      border: solid 1px ${error} !important;
    }

    .placeholder {
      position: absolute;
      pointer-events: none;
      color: ${inputPlaceholderText};
      line-height: 1.43;
      top: 12px;
      left: 12px;
      padding: 0 12px 12px 0;
      box-sizing: border-box;
    }

    input {
      width: 100%;
      background-color: #f9fafb;
      color: ${mainFont};
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 12px;

      &::placeholder {
        color: ${inputPlaceholderText};
      }

      &:hover {
        cursor: pointer;
        color: ${mainFont};
      }

      &:focus {
        cursor: text;
        color: ${mainFont};
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-transition: background-color 9999s ease-out;
        -webkit-box-shadow: 0 0 0px 1000px #f9fafb inset !important;
        -webkit-text-fill-color: #3d4046 !important;
      }
    }
  `;
};

export const descriptionCss = (theme: Theme): SerializedStyles => {
  return css`
    margin-top: 8px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: ${theme.colors.smallFont};
  `;
};
