import { css } from '@emotion/react';

export const BGUrlSelectorStyle = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .url-selector {
    // view
    width: 100%;
    height: 36px;
    border: solid 1px #dce0e8;
    border-radius: 8px;
    background-color: #f9fafb;
    transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);

    & * {
      box-sizing: border-box;
    }

    &:hover {
      cursor: pointer;
      border: solid 1px #b8beca;

      input.url-input {
        color: #3d4046;
      }
    }

    &:focus-within {
      cursor: text;
      border: solid 1px #3399ff;

      input.url-input {
        color: #3d4046;
      }
    }

    &.isError {
      border: solid 1px #e65c5c;
    }

    .url-selector-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      &.relative {
        position: relative;
      }
      &.disabled {
        background-color: #ebeef2;
        .protocol-select-item {
          cursor: default !important;
          background-color: #ebeef2;
        }
        input {
          cursor: default !important;
          background-color: #ebeef2;
        }
      }
    }

    .protocol-select-box {
      //view
      width: 105px;
      // height: 92px;
      // padding: 2px;
      background-color: #ffffff;
      box-shadow: 0 0 10px 0 rgb(0 0 0 / 5%);

      overflow-y: auto;
      border: 1px solid #eaeaea;
      border-radius: 8px;

      position: absolute;
      top: 43px;
      left: 0;
      z-index: 1;

      & > .protocol-select-item {
        height: auto;

        &:hover {
          background: #f5f5fa;
          border-radius: 0;
        }
      }
    }

    .protocol-selected-item-wrapper {
      height: 100%;

      & > .protocol-select-item {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #f9fafb;
        margin-right: 8px;
      }
    }

    .protocol-select-item {
      // view
      width: 100%;
      flex-direction: column;
      padding: 10px 8px 10px 12px;
      border-radius: 8px 0 0 8px;
      font-size: 14px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #53585f;
      cursor: pointer;
      background-color: #ffffff;
    }

    .removable-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .delete-icon-wrapper {
        height: 100%;
        display: flex;

        .delete-icon {
          margin: auto 10px auto 10px;
          cursor: pointer;
        }
      }
    }

    input.url-input {
      // view
      width: 100%;
      border-radius: 0 8px 8px 0;
      border-left: 1px solid #dce0e8;
      height: 100%;
      padding: 12px;
      margin-left: auto;
      background-color: #f9fafb;

      // text
      color: #737b86;
      font-size: 14px;

      &:hover {
        cursor: pointer;
        color: #3d4046;
      }

      &:focus {
        cursor: text;
        color: #3d4046;
      }
    }

    .link-button {
      padding: 0 9px 0 5px;
      height: 100%;
      display: flex;
      align-items: center;
      border-left: solid 1px #dce0e8;
      cursor: pointer;
    }
  }
`;
