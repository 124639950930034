import { I18Next, i18nextScanKey } from '../../i18n';
import { Metric } from '../../object-model/model';

export interface CampaignMetricType extends Metric {
  count: number;
  active: boolean;
  isLoading: boolean;
  color?: string;
  disabled: boolean;
  tooltip?: object;
}
export const CampaignMetrics: CampaignMetricType[] = [
  {
    displayName: i18nextScanKey('전체'),
    event: '',
    eventName: 'totals',
    prop: 'totals',
    format: 'Currency',
    operation: 'TOTAL',
    count: 0,
    active: true,
    event_segs: [],
    dimensions: [],
    eventType: 'Campaign',
    isLoading: true,
    color: '#000a29',
    disabled: false,
  },
  {
    displayName: 'Facebook/Instagram',
    event: '',
    eventName: 'facebook',
    prop: 'facebook',
    format: 'Currency',
    operation: 'TOTAL',
    count: 0,
    active: true,
    event_segs: [],
    dimensions: [],
    eventType: 'Campaign',
    isLoading: true,
    color: '#0054f7',
    disabled: false,
  },
  {
    displayName: 'Google',
    event: '',
    eventName: 'google',
    prop: 'google',
    format: 'Currency',
    operation: 'TOTAL',
    count: 0,
    active: true,
    event_segs: [],
    dimensions: [],
    eventType: 'Campaign',
    isLoading: true,
    color: '#ea4335',
    disabled: false,
  },
  {
    displayName: 'Kakao',
    event: '',
    eventName: 'kakao',
    prop: 'kakao',
    format: 'Currency',
    operation: 'TOTAL',
    count: 0,
    active: true,
    event_segs: [],
    dimensions: [],
    eventType: 'Campaign',
    isLoading: true,
    color: '#ffbf00',
    disabled: false,
  },
];

export interface FetchQueryFieldFilter {
  type: string;
  dimension: string;
  value: string | string[];
}

export const getMoreButtonObject = (i18next: I18Next) => ({
  modify: {
    label: i18next.t('광고 소재 수정'),
    value: 'modify',
  },
  preview: {
    label: i18next.t('소재 미리보기'),
    value: 'preview',
  },
});
