import { makeAutoObservable } from 'mobx';

class ToastStore {
  type: 'basic' | 'button' = 'basic';

  status: 'fail' | 'info' | 'warning' | 'success' = 'info';

  message = '';

  time = 4000;

  isShow = false;

  button: JSX.Element | undefined = undefined;

  position: 'top' | 'bottom' = 'bottom';

  showToast = (
    type: 'basic' | 'button' = 'basic',
    status: 'fail' | 'info' | 'warning' | 'success' = 'info',
    message = '',
    time = 4000,
    button?: JSX.Element,
    position?: 'top' | 'bottom',
  ): void => {
    this.type = type;
    this.status = status;
    this.message = message;
    this.time = time;
    this.isShow = true;

    if (position) {
      this.position = position;
    }

    this.button = button;
  };

  hideToast = (): void => {
    this.isShow = false;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default ToastStore;
