import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { DateRange } from '../components/Calendar/Calendar';

class ProjectStore {
  pickerDuration: any = {
    startAt: null,
    endAt: null,
    compareStartAt: null,
    compareEndAt: null,
  };

  selectableDateRange: DateRange = {
    start: moment().subtract(90, 'day').startOf('day'),
    end: moment().startOf('day'),
  };

  setDefaultDuration = (url: string) => {
    const params = new URLSearchParams(url);

    if (params.get('startAt')) {
      this.pickerDuration.startAt = moment(parseInt(<string>params.get('startAt'), 10));
    }
    if (params.get('endAt')) this.pickerDuration.endAt = moment(parseInt(<string>params.get('endAt'), 10));
    if (params.get('compareStartAt'))
      this.pickerDuration.compareStartAt = moment(parseInt(<string>params.get('compareStartAt'), 10));
    if (params.get('compareEndAt'))
      this.pickerDuration.compareEndAt = moment(parseInt(<string>params.get('compareEndAt'), 10));

    if (
      !this.pickerDuration.startAt ||
      !this.pickerDuration.endAt ||
      !this.pickerDuration.compareStartAt ||
      !this.pickerDuration.compareEndAt
    ) {
      this.pickerDuration.startAt = moment().startOf('day').subtract(7, 'day');
      this.pickerDuration.endAt = moment().endOf('day');
      this.pickerDuration.compareStartAt = moment().startOf('day').subtract(14, 'day');
      this.pickerDuration.compareEndAt = moment().endOf('day').subtract(8, 'day');
    }
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default ProjectStore;
