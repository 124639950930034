import React, { InputHTMLAttributes, ReactElement, useState } from 'react';
import _ from 'lodash';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, SerializedStyles, Theme, useTheme } from '@emotion/react';
import { BGInputWrapperCSS } from './BGInput.style';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  style?: object;
}

export interface BGInputProps {
  inputProps: InputProps;
  className?: string;
  style?: object;
  css?: SerializedStyles;
  error?: boolean;
  customLength?: number;
  customLengthLimit?: number;
  lengthUnit?: string;
  leftLabelComponent?: JSX.Element | string;
  rightLabelComponent?: JSX.Element | string;
  noShowLength?: boolean;
}

export const BGInput = React.forwardRef((props: BGInputProps, ref: React.Ref<HTMLInputElement>): ReactElement => {
  const {
    inputProps,
    className = '',
    css: wrapperCSS,
    style = {},
    error,
    customLength,
    customLengthLimit,
    lengthUnit,
    leftLabelComponent,
    rightLabelComponent,
  } = props;
  const {
    className: inputClassName = '',
    style: inputStyle = {},
    placeholder = '',
    disabled = false,
    type = 'text',
    defaultValue,
  } = inputProps;

  const theme: Theme = useTheme();

  const [valueLength, setValueLength] = useState<number>(defaultValue?.toString()?.length || 0);

  return (
    <div
      style={style}
      className={`${className} ${disabled ? 'isDisabled' : ''} ${error ? 'isError' : ''}`}
      css={[BGInputWrapperCSS(theme), wrapperCSS]}
    >
      {leftLabelComponent && <React.Fragment>{leftLabelComponent}</React.Fragment>}
      <input
        {...inputProps}
        ref={ref}
        className={inputClassName}
        style={inputStyle}
        type={type}
        placeholder={placeholder}
        onChange={(event) => {
          if (inputProps.onChange) {
            inputProps.onChange(event);
          }
          setValueLength(event.target.value.length);
        }}
      />
      {rightLabelComponent && <React.Fragment>{rightLabelComponent}</React.Fragment>}
      {!props?.noShowLength && !_.isNil(inputProps.maxLength) && (
        <div
          className="max-length-wrapper"
          css={css`
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #626871;
          `}
        >
          <React.Fragment>
            {valueLength}/{inputProps.maxLength}
          </React.Fragment>
          {lengthUnit && <React.Fragment>{lengthUnit}</React.Fragment>}
        </div>
      )}

      {!props?.noShowLength && _.isNil(inputProps.maxLength) && !_.isNil(customLength) && !_.isNil(customLengthLimit) && (
        <div
          className="max-length-wrapper"
          css={css`
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            color: #626871;
            white-space: nowrap;
          `}
        >
          <React.Fragment>
            {customLength}/{customLengthLimit}
          </React.Fragment>
          {lengthUnit && <React.Fragment>{lengthUnit}</React.Fragment>}
        </div>
      )}
    </div>
  );
});
