import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import axios, { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import validator from 'validator';

import { baseCss, templateCss } from './FindPassword.style';
import { BGButton } from '../../components/BGButton/BGButton';
import { BGInput } from '../../components/BGInput/BGInput';
import { LogoFrame } from '../../components/LogoFrame/LogoFrame';
import { UserApi } from '../../lib/api/UserApi';
import { useDataStore } from '../../context/Store';
import { Step2 } from './Step2/Step2';
import { BorderSection } from '../../components/BorderSection/BorderSection';

enum STEP {
  EMAIL_FILL = 1,
  LINK_SEND = 2,
}

// Template
export interface FindPasswordTemplateProps {
  step: STEP.EMAIL_FILL | STEP.LINK_SEND;
  titleMsg: string;
  descMsg: string;
  labelMsg: string;
  setEmailHandler: (text: string) => void;
  btn1Name: string;
  btn2Name: string;
  setLinkSendAtHandler: () => void;
  setNextStepHandler?: () => void;
}
interface FindPasswordForm {
  email: string;
}
export const FindPasswordTemplate = ({
  step,
  titleMsg,
  descMsg,
  labelMsg,
  setEmailHandler,
  btn1Name,
  btn2Name,
  setLinkSendAtHandler,
  setNextStepHandler,
}: FindPasswordTemplateProps): ReactElement => {
  const i18next = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');

  const { dialogStore } = useDataStore();

  const { register, formState, setValue, handleSubmit } = useForm<FindPasswordForm>({
    mode: 'all',
  });
  useEffect(() => {
    register('email', {
      value: email,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isEmail(value)) {
          return i18next.t('이메일이 올바르지않습니다.') as string;
        }

        return true;
      },
    });
  }, []);

  const onSubmit = (data: FindPasswordForm) => {
    return UserApi()
      .initPassword(data.email)
      .then(() => {
        setEmailHandler(data.email);
        setLinkSendAtHandler();
        if (setNextStepHandler) {
          setNextStepHandler();
        } else {
          dialogStore.showMessage(i18next.t('알림'), i18next.t('비밀번호 변경 링크를 전송 하였습니다.'));
        }
      })
      .catch((error: AxiosResponse) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 404) {
            dialogStore.showMessage(i18next.t('알림'), i18next.t('등록되지 않은 이메일입니다.'));

            return;
          }

          dialogStore.showMessage(
            i18next.t('알림'),
            i18next.t('비밀번호 찾기 요청이 실패했습니다. 잠시 후, 다시 시도해주세요.'),
          );
        }
      });
  };

  return (
    <section className="find-password-section step-1" css={[templateCss]}>
      <div className="find-password-container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`find-password-title-wrapper ${step === STEP.LINK_SEND ? 'add-space' : ''}`}>{titleMsg}</div>
          <div className="find-email-description">{descMsg}</div>
          <div className={`label-email${titleMsg === i18next.t('이메일 전송 완료') ? ' red' : ''}`}>{labelMsg}</div>
          <BGInput
            style={{ marginBottom: '24px' }}
            inputProps={{
              type: 'email',
              placeholder: i18next.t('이메일을 입력하세요.'),
              value: email,
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                setValue('email', event.target.value, { shouldValidate: true });
                setEmail(event.target.value);
              },
            }}
          />
          <div className="buttons-wrapper">
            <BGButton appearance="secondary" label={btn1Name} hoverable onClick={() => navigate('/login')} />
            <BGButton type="submit" label={btn2Name} hoverable isDisabled={!formState.isValid} />
          </div>
        </form>
      </div>
    </section>
  );
};

// Main Component
// export interface FindPasswordProps {}
export const FindPassword = (): ReactElement => {
  const i18next = useTranslation();
  const [currentStep, setStep] = useState<number>(STEP.EMAIL_FILL);
  const [email, setEmail] = useState<string>();
  const [linkSendAt, setLinkSendAt] = useState<moment.Moment>();

  const setNextStepHandler = () => {
    setStep(currentStep + 1);
  };

  const setEmailHandler = (text: string) => {
    setEmail(text);
  };

  const setLinkSendAtHandler = () => {
    setLinkSendAt(moment());
  };

  return (
    <div css={[baseCss]}>
      <LogoFrame useViewPort>
        <BorderSection style={{ padding: '20px', width: '320px' }}>
          {/* step 1 */}
          {currentStep === STEP.EMAIL_FILL && (
            <FindPasswordTemplate
              step={STEP.EMAIL_FILL}
              titleMsg={i18next.t('비밀번호 찾기')}
              descMsg={i18next.t('가입 시 등록한 이메일 주소로 임시 비밀번호가 전송됩니다.')}
              labelMsg={i18next.t('이메일 주소')}
              setEmailHandler={setEmailHandler}
              btn1Name={i18next.t('취소')}
              btn2Name={i18next.t('메일 발송')}
              setLinkSendAtHandler={setLinkSendAtHandler}
              setNextStepHandler={setNextStepHandler}
            />
          )}
          {/* step2 */}
          {currentStep === STEP.LINK_SEND && email && linkSendAt && (
            <Step2
              email={email}
              onAnotherClicked={() => {
                setStep(STEP.EMAIL_FILL);
              }}
            />
          )}
        </BorderSection>
      </LogoFrame>
    </div>
  );
};
