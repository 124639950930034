import { atom } from 'recoil';

const videos = atom<Video[]>({
  key: 'videos',
  default: [],
});

export default {
  videos,
};
