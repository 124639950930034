import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { baseCss } from './PlanDetail.style';
import Plan from '../../../services/plans/Plan';

const PlanDetailItem = (props: { item: boolean | string }): ReactElement => {
  if (typeof props.item === 'boolean') {
    return props.item ? <BDSFontIcon name="ic-check" color="#7e8696" size="20px" /> : <div className="default-icon" />;
  }

  return <span>{props.item}</span>;
};

export interface PlanDetailProps {
  planList: Array<Plan>;
}

export const PlanDetail = (props: PlanDetailProps): ReactElement => {
  const i18next = useTranslation();
  const { planList } = props;

  return (
    <div css={[baseCss]}>
      {planList && (
        <React.Fragment>
          <div className="plan-detail-table flex-row">
            <div className="table-detail-item title">
              <div className="table-title-category sticky" style={{ borderTopLeftRadius: '8px' }} />
              <div className="table-title-category property">{i18next.t('캠페인 운영')}</div>
              <div className="table-detail-list">
                <div className="table-detail-title first-column bold">{i18next.t('월 광고 예산 한도')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('종합 수수료 면제')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('광고 컨설팅')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('개인 채팅 상담 지원')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('알림톡 Pro (지표 관리 등)')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('솔루션 사용 교육')}</div>
              </div>
              <div className="table-title-category property">{i18next.t('데이터 분석 환경')}</div>
              <div className="table-detail-list">
                <div className="table-detail-title first-column bold">{i18next.t('CRM 데이터 연계')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('Bigin SDK 자동 설치')}</div>
              </div>
              <div className="table-title-category property">{i18next.t('분석 리포트')}</div>
              <div className="table-detail-list">
                <div className="table-detail-title first-column bold">{i18next.t('캠페인 리포트')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('주간 성과 리포트 메일링')}</div>
              </div>
              <div className="table-title-category property">{i18next.t('소재 생성')}</div>
              <div className="table-detail-list">
                <div className="table-detail-title first-column bold">{i18next.t('카탈로그 자동 제작 및 최적화')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('상품 이미지 자동 등록')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('소재 편집 도구')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('소재 제작 지원 및 대행')}</div>
              </div>
              <div className="table-title-category property">{i18next.t('지원 매체 및 유형')}</div>
              <div className="table-detail-list">
                <div className="table-detail-title first-column bold">{i18next.t('Facebook/Instagram 이미지')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('Facebook/Instagram 동영상')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('Facebook/Instagram 컬렉션')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('Facebook/Instagram 다이나믹')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('Google Ads 반응형 디스플레이')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('Google Ads 동적 리마케팅')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('Google Ads 쇼핑')}</div>
                <div className="table-detail-title first-column bold">Youtube</div>
                <div className="table-detail-title first-column bold">{i18next.t('Kakao 비즈보드')}</div>
                <div className="table-detail-title first-column bold">{i18next.t('Kakao 네이티브')}</div>
              </div>
            </div>

            <React.Fragment>
              {planList.map((plan: Plan, index: number) => (
                <div
                  key={`PlanDetail_${plan.name}`}
                  className={`table-detail-item value ${index === planList.length - 1 ? 'last-column' : ''}`}
                >
                  <div
                    className="table-title-category flex-row sticky"
                    style={{ borderTopRightRadius: index === planList.length - 1 ? '8px' : '0' }}
                  >
                    {plan.name}
                  </div>
                  <div className="table-title-category property" />
                  {/* 캠페인 운영 */}
                  <div className="table-detail-list">
                    {/* 월 광고 예산 한도 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.management.budgetLimit} />
                    </div>
                    {/* 종합 수수료 면제 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.management.commission} />
                    </div>
                    {/* 광고 컨설팅 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.management.consult} />
                    </div>
                    {/* 개인 채팅 상담 지원 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.management.oneOnOneChat} />
                    </div>
                    {/* 알림톡 Pro (지표 관리 등) */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.management.alimtalkPro} />
                    </div>
                    {/* 솔루션 사용 교육 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.management.solutionEducation} />
                    </div>
                  </div>

                  <div className="table-title-category property" />
                  {/* 데이터 분석 환경 */}
                  <div className="table-detail-list">
                    {/* CRM 데이터 연계 (bigin) */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.analytics.biginCrm} />
                    </div>
                    {/* Bigin SDK 자동 설치 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.analytics.biginSdk} />
                    </div>
                  </div>

                  <div className="table-title-category property" />
                  {/* 분석 리포트 */}
                  <div className="table-detail-list">
                    {/* 캠페인 리포트 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.report.campaign} />
                    </div>
                    {/* 주간 성과 리포트 메일링 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.report.weeklyPerformance} />
                    </div>
                  </div>

                  <div className="table-title-category property" />
                  {/* 소재 생성 */}
                  <div className="table-detail-list">
                    {/* 카탈로그 자동 제작 및 최적화 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.creative.createCatalog} />
                    </div>
                    {/* 상품 이미지 자동 등록 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.creative.updateProductImage} />
                    </div>
                    {/* 소재 편집 도구 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.creative.editTool} />
                    </div>
                    {/* 소재 제작 지원 및 대행 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.creative.support} />
                    </div>
                  </div>

                  <div className="table-title-category property" />
                  {/* 지원 매체 및 유형 */}
                  <div className="table-detail-list">
                    {/* Facebook/Instagram 이미지 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.media.facebookImage} />
                    </div>
                    {/* Facebook/Instagram 동영상 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.media.facebookVideo} />
                    </div>
                    {/* Facebook/Instagram 컬렉션 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.media.facebookCollection} />
                    </div>
                    {/* Facebook/Instagram 다이나믹 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.media.facebookDynamic} />
                    </div>
                    {/* Google Ads 반응형 디스플레이 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.media.googleResponsive} />
                    </div>
                    {/* Google Ads 동적 리마케팅 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.media.googleDynamic} />
                    </div>
                    {/* Google Ads 쇼핑 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.media.googleShopping} />
                    </div>
                    {/* Youtube */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.media.youtube} />
                    </div>
                    {/* Kakao 비즈보드 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.media.kakaoBizboard} />
                    </div>
                    {/* Kakao 네이티브 */}
                    <div
                      className={`table-detail-title flex-row ${index === planList.length - 1 ? 'last-column' : ''}`}
                    >
                      <PlanDetailItem item={plan.policy.media.kakaoNative} />
                    </div>
                  </div>
                </div>
              ))}
            </React.Fragment>
          </div>
        </React.Fragment>
      )}
      <div className="plan-detail-info flex-col" />
    </div>
  );
};
