import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .category-selector-wrapper {
      width: 100%;
      .title-wrapper {
        padding: 20px 20px 0;
      }

      .category-group-wrapper {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-top: 1px solid #dce0e8;
        border-bottom: 1px solid #dce0e8;
        gap: 0;
        min-height: 480px;
        margin-top: 27px;

        .main-category {
          display: flex;
          flex-direction: column;
          gap: 0;
        }
        .sub-category {
          border-left: 1px solid #dce0e8;
        }

        .title-wrapper {
          height: 44px;
          ${theme.text.h4};
          color: ${theme.colors.mainFont};
          padding: 10px 24px;
          border-bottom: 1px solid #dce0e8;
        }

        .category-menu-wrapper {
          margin-top: 8px;
          padding: 0px 20px;
          .category-menu-button {
            &.active {
              background-color: #edf1f6;
            }
            width: 100%;
            background-color: #edf1f6;
            white-space: nowrap;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 8px;
            padding: 8px;
            cursor: pointer;
            background-color: unset;
            transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
            &:hover {
              color: #3d4046;
              background-color: #f9fafb;
              border-radius: 4px;
            }
            span {
              font-size: 15px;
              font-weight: bold;
              color: #53585f;
            }
            .count {
              margin-left: 4px;
              ${theme.text.input};
              color: ${theme.colors.mainFont};
            }

            &.disabled {
              span {
                font-size: 15px;
                font-weight: bold;
                color: #99a0a9;
              }
              .count {
                margin-left: 4px;
                ${theme.text.input};
                color: #99a0a9;
              }
            }
          }
        }
      }
    }

    .category-main-wrapper {
    }
    .invite-pannel-wrapper {
      width: 100%;

      .invite-title-wrapper {
        h2 {
          margin-top: 0;
          margin-bottom: 12px;
        }

        p {
          font-size: 14px;
          font-weight: normal;
          line-height: 1.43;
          color: #3e355c;
        }
      }

      .info-title {
        ${theme.text.h5};
        color: ${theme.colors.subFont};
        margin-top: 20px;
        margin-bottom: 8px;
      }
    }

    .category-buttons-wrapper {
      margin-top: 24px;
      padding: 0 20px 20px;
    }
  `;
};
