import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .logo-upload-modal-header {
      margin-bottom: 20px;

      .title {
        margin-bottom: 8px;
      }
    }
    .logo-upload-modal-body {
      margin-bottom: 20px;

      .form-input-group {
        margin-bottom: 20px;

        .form-input-label {
          ${theme.text.h5};
          color: ${theme.colors.mainFont};

          margin-bottom: 8px;
        }
        .upload-box-wrapper {
          ${theme.text.disclaimer}
          padding: 16px;
          border-radius: 8px;
          border: solid 1px #dce0e8;
          background-color: #f9fafb;

          .upload-box-description {
            margin-bottom: 16px;
          }
          .upload-box-uploader {
            display: flex;
            align-items: center;

            .upload-box-thumbnail {
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;

              width: 40px;
              height: 40px;
              margin-right: 8px;
              border-radius: 8px;
              border: solid 1px #dce0e8;
              background-color: #f9fafb;

              &.landscape {
                width: 160px;
              }
            }
          }
        }
      }
    }
    .logo-upload-modal-footer {
    }
  `;
};
