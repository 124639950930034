import React, { useState, useMemo, useEffect } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton, BDSFontIcon, BDSInput, BDSSelectBox, BDSTextArea } from '@bigin/bigin-ui-components';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { useRecoilValue } from 'recoil';
import _ from 'lodash';
import CampaignSetting from '../../../../recoil/CampaignSetting';
import { PreviewGoogleItem } from '../PreviewGoogleItem/PreviewGoogleItem';
import { MaterialCheckboxSelector } from '../../MaterialCheckboxSelector/MaterialCheckboxSelector';
import { googleCTA, GoogleField } from '../../../../constants/campaigns';
import { AW_MAX_BYTE, AW_SHORT_MAX_BYTE, AW_SHORT_TITLE_MAX_BYTE, googleAdRegexs } from '../../../../utils/utils';
import { filters } from '../../../../utils/filter';
import { ModalImageSelect } from '../../../Creative/ModalImageSelect/ModalImageSelect';
import { InputError } from '../../../../components/InputError/InputError';
import { BGUrlSelector } from '../../../../components/BGUrlSelector/BGUrlSelector';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss, smallButtonCss } from './GooglePerformanceMax.style';
import { BGTagInput } from '../../../../components/BGTagInput/BGTagInput';
import { GogglePerformanceFormData, GooglePerformanceMaxForm } from './GogglePerformanceMax.d';
import { AssetHeader } from '../../../../components/AssetHeader/AssetHeader';

interface GooglePerformanceMaxProps {
  setting: CampaignAdSettingState;
  handleUpdate: (setting: CampaignAdSettingState) => void;
  handleValid: (type: CampaignType, isValid: boolean) => void;
  removeSelectedCampaignTypes: (type: CampaignType) => void;
}

export const GooglePerformanceMax = (props: GooglePerformanceMaxProps): JSX.Element => {
  const { setting, handleUpdate, handleValid, removeSelectedCampaignTypes } = props;
  const i18next = useTranslation();

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);

  const [formDataState, setFormDataState] = useState<GogglePerformanceFormData>({
    selectedCTA: setting.aw_call_to_action ? setting.aw_call_to_action : 'shop_now',
    name: setting.name,
    url: setting.url,
    awShortDescription: setting.aw_pmax_descriptions[0] ?? '',
    awDescription: setting.aw_pmax_descriptions[1] ?? '',
    awLongTitle: setting.aw_pmax_long_headlines[0] ?? '',
    awShortTitle1: setting.aw_pmax_headlines[0] ?? '',
    awShortTitle2: setting.aw_pmax_headlines[1] ?? '',
    awShortTitle3: setting.aw_pmax_headlines[2] ?? '',
    currentImageRatioType: '1:1',
    biginKeywords: setting.bigin_keywords,
  });
  const [valueLock, setValueLock] = useState<boolean>(false);

  const [showModalImageSelect, setShowModalImageSelect] = useState<boolean>(false);

  const { register, formState, setValue, trigger, getValues } = useForm<GooglePerformanceMaxForm>({
    mode: 'onChange',
  });

  const [currentErrorField, setCurrentErrorField] = useState<GoogleField | null>(null);
  const googleRegexCheck = (field: GoogleField, value: string) => {
    let googleRegex = false;
    googleAdRegexs().map((regex) => {
      if (new RegExp(regex, 'gi').test(value)) googleRegex = true;
      return true;
    });
    if (googleRegex) setCurrentErrorField(field);
    else setCurrentErrorField(null);

    const regexFreeValue = filters.googleRegexRemove(value);
    switch (field) {
      case GoogleField.AW_LONG_TITLE:
        setFormDataState((prev) => ({ ...prev, awLongTitle: regexFreeValue }));
        break;
      case GoogleField.AW_SHORT_TITLE1:
        setFormDataState((prev) => ({ ...prev, awShortTitle1: regexFreeValue }));
        break;
      case GoogleField.AW_SHORT_TITLE2:
        setFormDataState((prev) => ({ ...prev, awShortTitle2: regexFreeValue }));
        break;
      case GoogleField.AW_SHORT_TITLE3:
        setFormDataState((prev) => ({ ...prev, awShortTitle3: regexFreeValue }));
        break;
      case GoogleField.AW_DESCRIPTION:
        setFormDataState((prev) => ({ ...prev, awDescription: regexFreeValue }));
        break;
      case GoogleField.AW_SHORT_DESCRIPTION:
        setFormDataState((prev) => ({ ...prev, awShortDescription: regexFreeValue }));
        break;
      default:
        break;
    }
    setValue(
      field as
        | 'aw_long_title'
        | 'aw_short_title1'
        | 'aw_short_title2'
        | 'aw_short_title3'
        | 'aw_description'
        | 'aw_short_description',
      regexFreeValue,
      {
        shouldValidate: true,
      },
    );

    if (field === 'aw_short_title1' || field === 'aw_short_title2' || field === 'aw_short_title3') {
      trigger(['aw_short_title1', 'aw_short_title2', 'aw_short_title3']);
    }
  };

  const getCurrentImage = (type: ImageRatioType) => {
    if (type === '1:1') {
      return (setting.mall_image || setting.product_image || []) as ImageTypes[];
    }
    return (setting.aw_landscape_mall_image || setting.aw_landscape_product_image || []) as ImageTypes[];
  };
  const selectedImages = useMemo(
    () => getCurrentImage(formDataState.currentImageRatioType),
    [formDataState.currentImageRatioType],
  );
  const updateImage = (curImage: ProductImage) => {
    const newUrl = curImage.product_id ? curImage.detail_url : setting.url || '';

    if (formDataState.currentImageRatioType === '1:1') {
      if ('product_id' in curImage) {
        handleUpdate({
          ...setting,
          product_image: curImage,
          product_image_id: curImage.id,
          mall_image: undefined,
          mall_image_id: undefined,
          url: newUrl,
        });
      } else {
        handleUpdate({
          ...setting,
          mall_image: curImage,
          mall_image_id: curImage.id,
          product_image: undefined,
          product_image_id: undefined,
          url: newUrl,
        });
      }
      setValue('aw_image_id', curImage.id, { shouldValidate: true });
    } else if (formDataState.currentImageRatioType === '1.91:1') {
      if ('product_id' in curImage) {
        handleUpdate({
          ...props?.setting,
          aw_landscape_product_image: curImage,
          aw_landscape_product_image_id: curImage.id,
          aw_landscape_mall_image: undefined,
          aw_landscape_mall_image_id: undefined,
          url: newUrl,
        });
      } else {
        handleUpdate({
          ...props?.setting,
          aw_landscape_mall_image: curImage,
          aw_landscape_mall_image_id: curImage.id,
          aw_landscape_product_image: undefined,
          aw_landscape_product_image_id: undefined,
          url: newUrl,
        });
      }
      setValue('aw_landscape_image_id', curImage.id, { shouldValidate: true });
    }
    setValueLock(true);
    setFormDataState((prev) => ({ ...prev, url: newUrl }));
    setValue('url', newUrl);
  };

  const handleSelectImages = (images: ImageTypes[]) => {
    updateImage(images[0] as ProductImage);

    setShowModalImageSelect(false);
  };

  useEffect(() => {
    if (formDataState.name !== setting.name) {
      handleUpdate({ ...setting, name: formDataState.name });
      return;
    }
    if (formDataState.awLongTitle !== (setting.aw_pmax_long_headlines[0] ?? '')) {
      handleUpdate({ ...setting, aw_pmax_long_headlines: [formDataState.awLongTitle] });
      return;
    }
    if (
      formDataState.awShortTitle1 !== (setting.aw_pmax_headlines[0] ?? '') ||
      formDataState.awShortTitle2 !== (setting.aw_pmax_headlines[1] ?? '') ||
      formDataState.awShortTitle3 !== (setting.aw_pmax_headlines[2] ?? '')
    ) {
      handleUpdate({
        ...setting,
        aw_pmax_headlines: [formDataState.awShortTitle1, formDataState.awShortTitle2, formDataState.awShortTitle3],
      });
      return;
    }
    if (
      formDataState.awShortDescription !== (setting.aw_pmax_descriptions[0] ?? '') ||
      formDataState.awDescription !== (setting.aw_pmax_descriptions[1] ?? '')
    ) {
      handleUpdate({
        ...setting,
        aw_pmax_descriptions: [formDataState.awShortDescription, formDataState.awDescription],
      });
      return;
    }
    if (!valueLock) {
      if (formDataState.url !== setting.url) {
        handleUpdate({ ...setting, url: formDataState.url });
        return;
      }
    }

    if (formDataState.selectedCTA !== setting.aw_pmax_call_to_action_selection) {
      handleUpdate({ ...setting, aw_pmax_call_to_action_selection: formDataState.selectedCTA });
      return;
    }
    if (!_.isEqual(formDataState.biginKeywords, setting.bigin_keywords)) {
      handleUpdate({ ...setting, bigin_keywords: formDataState.biginKeywords });
      return;
    }

    setValueLock(false);
  }, [formDataState]);

  useEffect(() => {
    register('url', {
      value: formDataState.url,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isURL(value)) {
          return i18next.t('잘못된 URL 형식입니다.') as string;
        }

        return true;
      },
    });
    register('aw_long_title', {
      value: formDataState.awLongTitle,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_MAX_BYTE) {
          return i18next.t('긴 광고 제목은 {{byte}} byte를 넘을 수 없습니다.', { byte: AW_MAX_BYTE }) as string;
        }

        return true;
      },
    });
    register('aw_short_title1', {
      value: formDataState.awShortTitle1,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_SHORT_TITLE_MAX_BYTE) {
          return i18next.t('광고 제목은 {{byte}} byte를 넘을 수 없습니다.', {
            byte: AW_SHORT_TITLE_MAX_BYTE,
          }) as string;
        }

        if (value === getValues('aw_short_title2') || value === getValues('aw_short_title3')) {
          return i18next.t('광고 제목이 중복되었습니다. 다른 광고 제목을 입력해주세요.') as string;
        }
        return true;
      },
    });
    register('aw_short_title2', {
      value: formDataState.awShortTitle2,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_SHORT_TITLE_MAX_BYTE) {
          return i18next.t('광고 제목은 {{byte}} byte를 넘을 수 없습니다.', {
            byte: AW_SHORT_TITLE_MAX_BYTE,
          }) as string;
        }

        if (value === getValues('aw_short_title1') || value === getValues('aw_short_title3')) {
          return i18next.t('광고 제목이 중복되었습니다. 다른 광고 제목을 입력해주세요.') as string;
        }

        return true;
      },
    });
    register('aw_short_title3', {
      value: formDataState.awShortTitle3,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_SHORT_TITLE_MAX_BYTE) {
          return i18next.t('광고 제목은 {{byte}} byte를 넘을 수 없습니다.', {
            byte: AW_SHORT_TITLE_MAX_BYTE,
          }) as string;
        }

        if (value === getValues('aw_short_title1') || value === getValues('aw_short_title2')) {
          return i18next.t('광고 제목이 중복되었습니다. 다른 광고 제목을 입력해주세요.') as string;
        }

        return true;
      },
    });
    register('aw_description', {
      value: formDataState.awDescription,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_MAX_BYTE) {
          return i18next.t('긴 광고 문구는 {{byte}} byte를 넘을 수 없습니다.', { byte: AW_MAX_BYTE }) as string;
        }

        return true;
      },
    });

    register('aw_short_description', {
      value: formDataState.awShortDescription,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_SHORT_MAX_BYTE) {
          return i18next.t('광고 문구는 {{byte}} byte를 넘을 수 없습니다.', { byte: AW_SHORT_MAX_BYTE }) as string;
        }

        return true;
      },
    });

    register('aw_image_id', {
      value: (setting?.mall_image || setting?.product_image)?.id,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('aw_landscape_image_id', {
      value: (setting?.aw_landscape_mall_image || setting?.aw_landscape_product_image)?.id,
      required: i18next.t('필수 입력란입니다.') as string,
    });
  }, []);

  useEffect(() => {
    if (handleValid) {
      handleValid(setting?.type, formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <BorderSection>
      <div className="goggle-performance-asset-setting-wrapper" css={baseCss}>
        <AssetHeader
          title={i18next.t('실적 최대화')}
          icons={[{ imgSrc: '/assets/img/card_icons/icons-ic-google.png', alt: 'Google' }]}
          handleIconClick={() => {
            removeSelectedCampaignTypes(setting.type);
          }}
        />
        <div className="create-section-content-wrapper">
          <div className="preview-container">
            <div className="sticky-wrap">
              <div className="title-panel">
                <div className="title">{i18next.t('미리보기')}</div>
                <div className="info">{i18next.t('*실제 노출과 미리보기가 다를 수 있습니다.')}</div>
              </div>
              <div className="material-preview-wrapper">
                <div className="material-preview">
                  <div className="google-shopping-wrapper">
                    <div className="google-preview">
                      <PreviewGoogleItem setting={setting} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-container">
            <h2 className="material-field-header">{i18next.t('광고 소재명')}</h2>
            <div className="material-field-selector-wrapper">
              <BDSInput
                style={{ width: '100%' }}
                inputProps={{
                  name: 'name',
                  placeholder: i18next.t('광고 소재 이름을 입력해주세요.'),
                  value: formDataState.name || '',
                  onChange: (event) => {
                    setFormDataState((prev) => ({ ...prev, name: event.target.value }));
                  },
                }}
              />
              <p className="description">{i18next.t('*다른 소재와 구분지을 수 있게 이름을 입력하세요.')}</p>
            </div>
            <div className="horizontal-line" />
            <h2 className="material-field-header">{i18next.t('광고 소재 이미지')}</h2>
            <div className="material-field-selector-wrapper">
              <MaterialCheckboxSelector
                contentTitle={i18next.t('1:1 비율의 광고 이미지')}
                checkboxLabel=""
                value
                hiddenCheckbox
              />
              <div className="content-upload-container">
                <div className="single-image-uploader-wrapper uploader-wrapper">
                  <div className="image-uploader-title-wrapper">
                    <div className="image-uploader-subtitle">
                      <div>{i18next.t('* 최소 사이즈: ')} 300px*300px</div>
                      <div>{i18next.t('* 최대 사이즈: ')} 1200px*1200px</div>
                      <div>{i18next.t('* 파일 형식: ')} JPG, PNG</div>
                      <div>{i18next.t('* 파일 용량: {{size}}MB 이내', { size: 1 })}</div>
                    </div>
                  </div>
                  <div className="image-uploader-wrapper">
                    <div className="image-uploader-item-wrapper" style={{ flexDirection: 'row', gap: 8 }}>
                      <div css={smallButtonCss} className="image-uploader-btn">
                        {setting?.mall_image || setting?.product_image ? (
                          <img
                            style={{ width: '100%', height: '100%', position: 'relative' }}
                            src={(setting?.mall_image || setting?.product_image)?.url}
                            alt="1:1 이미지"
                          />
                        ) : (
                          <BDSFontIcon name="ic-img" color="#7e8696" size="20px" />
                        )}
                      </div>
                      <div className="file-wrapper">
                        <BDSButton
                          className="image-updater-btn"
                          onClick={() => {
                            setFormDataState((prev) => ({ ...prev, currentImageRatioType: '1:1' }));
                            setShowModalImageSelect(true);
                          }}
                          label={i18next.t('업로드')}
                        />
                        {props.setting.product_image && props.setting.product_image.name && (
                          <div className="file-name">{props.setting.product_image.name}</div>
                        )}
                        {props.setting.mall_image && <div className="file-name">{i18next.t('상품 정보 없음')}</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="material-field-selector-wrapper">
              <MaterialCheckboxSelector
                contentTitle={i18next.t('1.91:1 비율의 광고 이미지')}
                checkboxLabel=""
                value
                hiddenCheckbox
              />
              <div className="content-upload-container">
                <div className="single-image-uploader-wrapper uploader-wrapper">
                  <div className="image-uploader-title-wrapper">
                    <div className="image-uploader-subtitle">
                      <div>{i18next.t('* 최소 사이즈: ')} 600px*314px</div>
                      <div>{i18next.t('* 최대 사이즈: ')} 1200px*628px</div>
                      <div>{i18next.t('* 파일 형식: ')} JPG, PNG</div>
                      <div>{i18next.t('* 파일 용량: {{size}}MB 이내', { size: 1 })}</div>
                    </div>
                  </div>
                  <div className="image-uploader-wrapper">
                    <div className="image-uploader-item-wrapper" style={{ flexDirection: 'row', gap: 8 }}>
                      <div css={smallButtonCss} className="image-uploader-btn">
                        {setting?.aw_landscape_mall_image || setting?.aw_landscape_product_image ? (
                          <img
                            style={{ width: '100%', height: '100%', position: 'relative' }}
                            src={(setting?.aw_landscape_mall_image || setting?.aw_landscape_product_image)?.url}
                            alt="1.91:1 이미지"
                          />
                        ) : (
                          <BDSFontIcon name="ic-img" color="#7e8696" size="20px" />
                        )}
                      </div>
                      <div className="file-wrapper">
                        <BDSButton
                          className="image-updater-btn"
                          onClick={() => {
                            setFormDataState((prev) => ({ ...prev, currentImageRatioType: '1.91:1' }));
                            setShowModalImageSelect(true);
                          }}
                          label={i18next.t('업로드')}
                        />
                        {props.setting.aw_landscape_product_image && props.setting.aw_landscape_product_image.name && (
                          <div className="file-name">{props.setting.aw_landscape_product_image.name}</div>
                        )}
                        {props.setting.aw_landscape_mall_image && (
                          <div className="file-name">{i18next.t('상품 정보 없음')}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="horizontal-line" />
            <h2 className="material-field-header">{i18next.t('광고 소재 텍스트')}</h2>
            <div className="material-field-selector-wrapper">
              <MaterialCheckboxSelector contentTitle={i18next.t('광고 제목')} checkboxLabel="" value hiddenCheckbox />
              <BDSInput
                style={{ width: '100%', marginTop: '8px' }}
                error={!!formState.errors.aw_short_title1}
                inputProps={{
                  name: 'aw_short_title1',
                  value: formDataState.awShortTitle1 || '',
                  placeholder: i18next.t('광고 제목을 입력하세요.'),
                  onChange: (event) => {
                    googleRegexCheck(GoogleField.AW_SHORT_TITLE1, event.target.value);
                  },
                }}
                customLength={filters.formatByte(formDataState.awShortTitle1) || 0}
                customLengthLimit={30}
                lengthUnit="byte"
                rightLabelComponent={
                  !formState.errors.aw_short_title1 && formDataState.awShortTitle1.length > 0 ? (
                    <React.Fragment>
                      <BDSFontIcon name="ic-check-alt" color="#3399FF" size="20px" />
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )
                }
              />
              {formState.errors.aw_short_title1 && (
                <InputError message={formState.errors.aw_short_title1.message as string} />
              )}
              {currentErrorField === GoogleField.AW_SHORT_TITLE1 && (
                <p className="description">
                  {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                </p>
              )}
              <BDSInput
                style={{ width: '100%', marginTop: '8px' }}
                error={!!formState.errors.aw_short_title2}
                inputProps={{
                  name: 'aw_short_title',
                  value: formDataState.awShortTitle2 || '',
                  placeholder: i18next.t('광고 제목을 입력하세요.'),
                  onChange: (event) => {
                    googleRegexCheck(GoogleField.AW_SHORT_TITLE2, event.target.value);
                  },
                }}
                customLength={filters.formatByte(formDataState.awShortTitle2) || 0}
                customLengthLimit={30}
                lengthUnit="byte"
                rightLabelComponent={
                  !formState.errors.aw_short_title2 && formDataState.awShortTitle2.length > 0 ? (
                    <React.Fragment>
                      <BDSFontIcon name="ic-check-alt" color="#3399FF" size="20px" />
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )
                }
              />
              {formState.errors.aw_short_title2 && (
                <InputError message={formState.errors.aw_short_title2.message as string} />
              )}
              {currentErrorField === GoogleField.AW_SHORT_TITLE2 && (
                <p className="description">
                  {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                </p>
              )}
              <BDSInput
                style={{ width: '100%', marginTop: '8px' }}
                error={!!formState.errors.aw_short_title3}
                inputProps={{
                  name: 'aw_short_title',
                  value: formDataState.awShortTitle3 || '',
                  placeholder: i18next.t('광고 제목을 입력하세요.'),
                  onChange: (event) => {
                    googleRegexCheck(GoogleField.AW_SHORT_TITLE3, event.target.value);
                  },
                }}
                customLength={filters.formatByte(formDataState.awShortTitle3) || 0}
                customLengthLimit={30}
                lengthUnit="byte"
                rightLabelComponent={
                  !formState.errors.aw_short_title3 && formDataState.awShortTitle3.length > 0 ? (
                    <React.Fragment>
                      <BDSFontIcon name="ic-check-alt" color="#3399FF" size="20px" />
                    </React.Fragment>
                  ) : (
                    <React.Fragment />
                  )
                }
              />
              {formState.errors.aw_short_title3 && (
                <InputError message={formState.errors.aw_short_title3.message as string} />
              )}
              {currentErrorField === GoogleField.AW_SHORT_TITLE3 && (
                <p className="description">
                  {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                </p>
              )}
            </div>
            <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
              <MaterialCheckboxSelector
                contentTitle={i18next.t('긴 광고 제목')}
                checkboxLabel=""
                value
                hiddenCheckbox
              />
              <div className="material-field-input-wrapper">
                <BDSTextArea
                  isAutoGrow
                  error={!!formState.errors.aw_long_title}
                  style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                  textAreaProps={{
                    name: 'aw_long_title',
                    cols: 30,
                    rows: 3,
                    style: {
                      minHeight: 49,
                    },
                    value: formDataState.awLongTitle || '',
                    placeholder: i18next.t('긴 광고 제목을 입력하세요.'),
                    onChange: (event) => {
                      googleRegexCheck(GoogleField.AW_LONG_TITLE, event.target.value);
                    },
                  }}
                  curLength={filters.formatByte(formDataState.awLongTitle) || 0}
                  limitLength={90}
                  lengthUnit="byte"
                />
                {formState.errors.aw_long_title && (
                  <InputError message={formState.errors.aw_long_title.message as string} />
                )}
                {currentErrorField === GoogleField.AW_LONG_TITLE && (
                  <p className="description">
                    {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                  </p>
                )}
              </div>
            </div>
            <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
              <MaterialCheckboxSelector contentTitle={i18next.t('광고 문구')} checkboxLabel="" value hiddenCheckbox />
              <div className="material-field-input-wrapper">
                <BDSTextArea
                  isAutoGrow
                  error={!!formState.errors.aw_short_description}
                  style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                  textAreaProps={{
                    name: 'aw_short_description',
                    cols: 30,
                    rows: 3,
                    style: {
                      minHeight: 49,
                    },
                    value: formDataState.awShortDescription || '',
                    placeholder: i18next.t('광고 문구를 입력하세요.'),
                    onChange: (event) => {
                      googleRegexCheck(GoogleField.AW_SHORT_DESCRIPTION, event.target.value);
                    },
                  }}
                  curLength={filters.formatByte(formDataState.awShortDescription) || 0}
                  limitLength={60}
                  lengthUnit="byte"
                />
                {formState.errors.aw_short_description && (
                  <InputError message={formState.errors.aw_short_description.message as string} />
                )}
                {currentErrorField === GoogleField.AW_SHORT_DESCRIPTION && (
                  <p className="description">
                    {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                  </p>
                )}
              </div>
            </div>
            <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
              <MaterialCheckboxSelector
                contentTitle={i18next.t('긴 광고 문구')}
                checkboxLabel=""
                value
                hiddenCheckbox
              />
              <div className="material-field-input-wrapper">
                <BDSTextArea
                  isAutoGrow
                  error={!!formState.errors.aw_description}
                  style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                  textAreaProps={{
                    name: 'aw_description',
                    cols: 30,
                    rows: 3,
                    style: {
                      minHeight: 49,
                    },
                    value: formDataState.awDescription || '',
                    placeholder: i18next.t('광고 문구를 입력하세요.'),
                    onChange: (event) => {
                      googleRegexCheck(GoogleField.AW_DESCRIPTION, event.target.value);
                    },
                  }}
                  curLength={filters.formatByte(formDataState.awDescription) || 0}
                  limitLength={90}
                  lengthUnit="byte"
                />
                {formState.errors.aw_description && (
                  <InputError message={formState.errors.aw_description.message as string} />
                )}
                {currentErrorField === GoogleField.AW_DESCRIPTION && (
                  <p className="description">
                    {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                  </p>
                )}
              </div>
            </div>

            <div className="material-field-selector-wrapper">
              <MaterialCheckboxSelector
                contentTitle={i18next.t('행동 유도 버튼')}
                checkboxLabel=""
                value
                hiddenCheckbox
              />
              <BDSSelectBox
                appearance="gray"
                style={{ width: '100%', marginTop: '8px' }}
                list={googleCTA}
                defaultValue={setting?.aw_call_to_action}
                displayValue={(item: any) => {
                  return i18next.t(item.label);
                }}
                isSelected={(item: any) => {
                  return formDataState.selectedCTA === item.value;
                }}
                handleUpdate={(item: any) => {
                  setFormDataState((prev) => ({ ...prev, selectedCTA: item.value }));
                }}
              />
            </div>
            <div className="material-field-selector-wrapper">
              <MaterialCheckboxSelector contentTitle={i18next.t('랜딩 URL')} checkboxLabel="" value hiddenCheckbox />
              <BGUrlSelector
                linkButton
                validationTrackOn
                isValid={!formState.errors.url}
                url={formDataState.url || ''}
                handleUpdate={(curUrl: string) => {
                  setFormDataState((prev) => ({ ...prev, url: curUrl }));
                  setValue('url', curUrl, { shouldValidate: true });
                }}
                style={{ marginTop: '8px' }}
                placeholder="www.bigin.io"
              />
              {formState.errors.url && <InputError message={formState.errors.url.message as string} />}
            </div>
            {campaignState.isBiginConnected && (
              <React.Fragment>
                <div className="horizontal-line" />
                <h2 className="material-field-header">{i18next.t('연계 캠페인')}</h2>
                <div className="material-field-selector-wrapper">
                  <MaterialCheckboxSelector
                    contentTitle={i18next.t('핵심 키워드')}
                    checkboxLabel=""
                    value
                    hiddenCheckbox
                  />
                  <BGTagInput
                    style={{ width: '100%', marginTop: '8px' }}
                    inputProps={{
                      name: 'bigin_keywords',
                      placeholder: i18next.t('Bigin CRM에서 해당 광고 소재를 검색할 때 사용할 키워드를 입력해주세요.'),
                    }}
                    handleUpdate={(keywords) => {
                      setFormDataState((prev) => ({ ...prev, biginKeywords: keywords }));
                    }}
                    defaultTags={formDataState.biginKeywords}
                    duplicateText={i18next.t('* 이미 등록된 키워드입니다.')}
                  />
                  <p className="description">
                    {i18next.t('*핵심 키워드는 선택사항이며, 최대 5개까지 입력할 수 있습니다.')}
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      {showModalImageSelect && formDataState.currentImageRatioType && (
        <ModalImageSelect
          selectType="single"
          selectedImages={selectedImages}
          campaignType={setting.type}
          isOpen={showModalImageSelect}
          ratio={formDataState.currentImageRatioType}
          close={() => setShowModalImageSelect(false)}
          setSelectedImages={handleSelectImages}
        />
      )}
    </BorderSection>
  );
};
