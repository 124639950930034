import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Theme, useTheme } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { useRecoilValue } from 'recoil';
import { StyledTab } from './BGTab.style';
import SideNavBarRecoil from '../../recoil/SideNavBar';
import { FontIcon } from '../FontIcon/FontIcon';

export interface ITab {
  key: string;
  label: string;
}

export interface BGTabProps {
  tabList: ITab[];
  selectedTab: ITab;
  handleTab: (tab: ITab) => void;
  style?: object;
  fixed?: boolean;
  showArrow?: boolean;
}
export const BGTab = observer(
  ({ tabList, selectedTab, handleTab, style, fixed, showArrow }: BGTabProps): ReactElement => {
    const sideNavBarisCollapsed = useRecoilValue(SideNavBarRecoil.isCollapsed);
    const tabLength = tabList.length;
    const theme: Theme = useTheme();

    return (
      <StyledTab
        theme={theme}
        isSideNavBarCollapsed={sideNavBarisCollapsed}
        className={`${fixed ? 'fixed' : ''} ${showArrow ? 'show-arrow' : ''}`}
        style={style || {}}
      >
        {tabList.map((tab, i) => {
          return (
            <React.Fragment key={tab.key}>
              <div
                className={`tab-panel ${tab.key === selectedTab.key ? 'active' : ''}`}
                data-testid="bg-tab-panel"
                onClick={() => handleTab(tab)}
              >
                {tab.label}
              </div>
              {showArrow && i !== tabLength - 1 && <FontIcon name="ic-arrow-right" size="20px" color="#7e8696" />}
            </React.Fragment>
          );
        })}
      </StyledTab>
    );
  },
);
