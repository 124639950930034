import { css } from '@emotion/react';

export const baseCss = css`
  .header {
    position: relative;
    margin: -20px -20px 20px -20px;
    padding: 20px 20px 0 20px;

    width: 100%;
    height: 159px;
  }
`;
