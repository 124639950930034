import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

export interface BGTooltipProps extends TooltipProps {
  arrow?: boolean;
}

export const BGTooltip: React.FC<BGTooltipProps> = (props: BGTooltipProps) => {
  const { placement = 'top' } = props;

  const StyledTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: '#2a2e37',
      borderRadius: '4px',
      color: '#fff',
      padding: '8px',
      fontSize: 12,
      margin: '8px',
    },
  }))(Tooltip);

  return <StyledTooltip {...props} placement={placement} />;
};
