import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { containerCss } from './ManageAccount.style';
import { UserApi } from '../../lib/api/UserApi';
import StorageService from '../../services/StorageService';
import { MallApi } from '../../lib/api/MallApi';
import { BGPage } from '../../components/BGPage/BGPage';
import { BGTab, ITab } from '../../components/BGTab/BGTab';
import { AccountSetting } from './AccountSetting/AccountSetting';
import { BiginSdkSetting } from './BiginSdkSetting/BiginSdkSetting';

enum STEP {
  STEP1 = 1,
  STEP2 = 2,
}

export const ManageAccount = (): ReactElement => {
  const { ...i18next } = useTranslation();

  const navigate = useNavigate();
  const queryParams: any = useMemo(
    () => qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    [window.location.search],
  );

  const topNavTab = useMemo(() => {
    if (StorageService.activePlan?.type === 'smartstore') {
      return [
        {
          key: 'account',
          label: i18next.t('계정 정보'),
        },
      ];
    }
    return [
      {
        key: 'account',
        label: i18next.t('계정 정보'),
      },
      {
        key: 'bigin-sdk',
        label: 'Bigin SDK',
      },
    ];
  }, [StorageService.activePlan]);
  const [step, setStep] = useState<number>(STEP.STEP1);
  const [selectedTopNavTab, setSelectedTopNavTab] = useState<ITab>(topNavTab[0]);

  const { data: me, isFetching: isMeFetching } = useQuery<User>('me', async () => (await UserApi().getMe()).data.data);
  const { data: mall, isFetching: isMallFetching } = useQuery<Mall>(
    'mallCompany',
    async () =>
      (
        await MallApi().getMall(StorageService.selectedMall.id, {
          includes: ['medias', 'company', 'classification.parent'],
        })
      ).data,
    { refetchOnMount: 'always' },
  );

  const { data: isOrphan, isFetching: isOphanFetching } = useQuery<boolean>(
    'isOrphan',
    async () => (await MallApi().getIsOrphanProject(StorageService.selectedMall.id)).data.data.is_orphan,
    { enabled: mall && mall.medias.findIndex((mallMedia: MallMedia) => mallMedia.type === 'bigin-project') > -1 },
  );

  useEffect(() => {
    if (StorageService.activePlan?.type === 'smartstore' && queryParams.tab === 'bigin-sdk') {
      navigate(`/malls/${StorageService.selectedMall.id}/accounts?tab=account`, { replace: true });
    } else if (queryParams.tab === 'account') {
      setStep(STEP.STEP1);
      setSelectedTopNavTab(topNavTab[0]);
    } else if (queryParams.tab === 'bigin-sdk') {
      setStep(STEP.STEP2);
      setSelectedTopNavTab(topNavTab[1]);
    }
  }, [queryParams]);

  return (
    <BGPage>
      <TopNavBar
        style={{ border: 0 }}
        title={
          <div
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {i18next.t('설정')}
          </div>
        }
      />
      <BGTab
        fixed
        tabList={topNavTab}
        selectedTab={selectedTopNavTab}
        handleTab={(tab) => {
          if (tab.key === 'account') {
            setStep(STEP.STEP1);
            setSelectedTopNavTab(tab);
          } else if (tab.key === 'bigin-sdk') {
            setStep(STEP.STEP2);
            setSelectedTopNavTab(tab);
          }

          const url = `/malls/${StorageService.selectedMall.id}/accounts?tab=${tab.key}`;
          navigate(url, { replace: true });
        }}
      />
      <div className="account-setting-container" css={containerCss}>
        {/* TODO: 매체 연동 관련 Description 내용 텍스트 수정하기 */}
        {/* TODO: 매체 연동 버튼 동작 관련 반영하기 */}
        {step === STEP.STEP1 && (
          <AccountSetting
            me={me ?? null}
            mall={mall ?? null}
            isOrphan={isOrphan ?? false}
            isLoading={isMeFetching || isMallFetching || isOphanFetching}
          />
        )}
        {step === STEP.STEP2 && mall && <BiginSdkSetting mall={mall} />}
      </div>
    </BGPage>
  );
};
