import { css } from '@emotion/react';

export const baseCss = css`
  .new-mall-header {
    margin-bottom: 20px;

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
`;
