import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { baseCss } from './BGPagination.style';
import { FontIcon } from '../FontIcon/FontIcon';
import { MaterialDropDown } from '../PositionSelector/PositionSelector';

export type BGPaginationCountPerPageType = 10 | 20 | 50 | 100;

export interface BGPaginationProps {
  isLoaded: boolean;

  curPageItemLength: number; // 현재 페이지 아이템 수
  totalCount: number; // 리스트 아이템 전체 수
  countPerPage: number; // 페이지 당 아이템 수
  activePage: number; // 현재 페이지 수
  countPerSection?: number; // 섹션 별 페이지 수

  handleCountPerPage?: (curCountPerPage: number) => void;
  handleActivePage: (curActivePage: number) => void;

  selectBoxPosition?: 'top' | 'bottom';

  isHideOnLess?: boolean;
  isHideCountSelector?: boolean;
}

const DefaultCountPerPageList: BGPaginationCountPerPageType[] = [10, 20, 50, 100];

export const BGPagination = (props: BGPaginationProps): ReactElement => {
  const i18next = useTranslation();
  const { countPerSection = 10 } = props;

  const [sectionIndex, setSectionIndex] = useState<number>(0);

  const pages = useMemo(() => {
    const pageArray: Array<Array<number>> = [];
    const pageSize = props.totalCount && props.totalCount > 0 ? Math.ceil(props.totalCount / props.countPerPage) : 0;
    const sectionSize = pageSize > countPerSection ? Math.ceil(pageSize / countPerSection) : 1;
    for (let section = 0; section < sectionSize; section += 1) {
      pageArray.push([]);
      for (let page = 0; page < countPerSection; page += 1) {
        const curPage = section * countPerSection + page + 1;
        if (curPage <= pageSize) {
          pageArray[section].push(section * countPerSection + page + 1);
        }
      }
    }

    return pageArray;
  }, [props.totalCount, props.countPerPage]);

  const [currentPageStartNumber, setCurrentPageStartNumber] = useState<number>();
  const [currentPageEndNumber, setCurrentPageEndNumber] = useState<number>();

  useEffect(() => {
    if (props.isLoaded) {
      const curStart = (props.activePage - 1) * props.countPerPage + 1;
      setCurrentPageStartNumber(curStart);
      setCurrentPageEndNumber(curStart + (props.curPageItemLength - 1));
    }
  }, [props.isLoaded]);

  return (
    <div className="bg-pagination" css={baseCss}>
      {(!props.isHideOnLess || props.totalCount > props.countPerPage) && (
        <div className="pagination-wrapper">
          {sectionIndex - 1 >= 0 && (
            <button
              className="pre-button"
              type="button"
              onClick={() => {
                setSectionIndex(sectionIndex - 1);
                props.handleActivePage(pages[sectionIndex - 1][0]);
              }}
            >
              <FontIcon name="ic-arrow-left" color="#53585f" size="16px" />
            </button>
          )}
          {pages[sectionIndex].map((page: number) => {
            return (
              <button
                key={page}
                type="button"
                className={`page-button ${props.activePage === page ? 'active' : ''}`}
                onClick={() => {
                  props.handleActivePage(page);
                }}
              >
                {page}
              </button>
            );
          })}
          {pages?.[sectionIndex + 1] && (
            <button
              className="next-button"
              type="button"
              onClick={() => {
                setSectionIndex(sectionIndex + 1);
                props.handleActivePage(pages[sectionIndex + 1][0]);
              }}
            >
              <FontIcon name="ic-arrow-right" color="#53585f" size="16px" />
            </button>
          )}
        </div>
      )}
      {!props.isHideCountSelector && props.totalCount > DefaultCountPerPageList[0] && (
        <div className="list-count-select-wrapper">
          <div className="total-wrapper">
            {props.totalCount > 0 &&
              `${i18next.t('전체 {{count}}개 중', {
                count: props.totalCount,
              })} ${currentPageStartNumber}~${currentPageEndNumber}`}
          </div>
          <div className="list-count-selector">
            {i18next.t('목록 개수')}
            <MaterialDropDown
              appearance="gray"
              style={{ height: '36px', width: '80px', marginLeft: '16px' }}
              list={DefaultCountPerPageList.map((count) => count.toString())}
              defaultValue={props.countPerPage}
              displayValue={(curListCount) => {
                return curListCount as string;
              }}
              isSelected={(curListCount) => curListCount === props.countPerPage.toString()}
              handleUpdate={(curListCount) => {
                if (props.handleCountPerPage) {
                  props.handleCountPerPage(parseInt(curListCount as string, 10));
                }
                setSectionIndex(0);
              }}
              position={props?.selectBoxPosition}
            />
          </div>
        </div>
      )}
    </div>
  );
};
