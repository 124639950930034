import { css, useTheme, Theme } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = () => {
  return css`
    width: 100%;
    min-width: fit-content;

    * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: ${GS.gutter}px;
    }

    .create-image-container {
      font-stretch: normal;
      font-style: normal;
      width: 100%;
      display: flex;
      position: relative;

      .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }
  `;
};

export const leftWrapper = () => {
  const theme: Theme = useTheme();

  return css`
    width: calc(100% - 280px);
    min-width: ${GS.contentMinWidth}px;
    max-width: ${GS.contentMaxWidth}px;

    .request-image-banner-container {
      background-image: linear-gradient(91deg, #d6e8ff 6%, #fff 101%);
      border: 1px solid ${theme.colors.divider};
      border-radius: 8px;
      width: 100%;
      padding: 0 20px 0 9px;
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .contents-wrapper {
        display: flex;
        height: 80px;
        img {
          height: 76px;
        }
        .typography-wrapper {
          display: flex;
          flex-direction: column;
          margin-left: 16px;
          justify-content: center;
        }
      }
    }
  `;
};
export const rightWrapper = css`
  width: 260px;
  margin-left: 20px;
  position: relative;
`;

export const successCreateImageModalCss = css`
  .success-modal-body {
    margin-top: 8px;
  }
  .success-modal-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
`;
