import { atom } from 'recoil';
import { CurrenyCode } from '../../types/currency.d';

/**
 * Mall 객체 내의 currency로 구분해서 통화 표시 (default: 'KRW')
 */
export const CurrencyCodeRecoil = atom<CurrenyCode>({
  key: 'currencyCode',
  default: 'KRW',
});

/**
 * 캠페인 관련 Budget 소수점 대응 관련해서 보여주는 recoil (string)
 */
export const showBudgetValueRecoil = atom<string>({
  key: 'showBudgetValue',
  default: '',
});
