import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: 100%;
    min-height: 417px;
    padding: 20px;
    border-radius: 8px;
    border: solid 1px #dce0e8;
    background-color: #ffffff;
    position: relative;
    margin-right: 20px;
    transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
    cursor: pointer;
    box-sizing: border-box;
    box-shadow: 0 4px 24px 0 rgba(79, 86, 97, 0.05);

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      margin-top: -6px;
    }

    &.selected {
      border-radius: 8px;
      border: 2px solid #006fff;
    }

    .plan-top-list {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: flex-start;
      .plan-icon {
        width: 64px;
        margin-bottom: 8px;
        border-radius: 8px;
        background-color: #edf1f6;
      }
      .plan-tag {
        padding: 6px 10px;
        border-radius: 4px;
        background-color: #edf1f6;
        ${theme.text.h5};
        color: ${theme.colors.subFont};
        &.selected {
          color: #ffffff;
          background-color: #006fff;
        }
      }
    }

    .plan-main-list {
      .plan-main-list-item {
        margin: 0 0 0 2px;

        .plan-item-desc {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 8px;
          white-space: nowrap;
          font-size: 16px;
        }
      }
    }
  `;
};
