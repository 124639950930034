import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton, BDSFontIcon, BDSSelectBox, BDSTextArea } from '@bigin/bigin-ui-components';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import _ from 'lodash';
import { BGUrlSelector } from '../../../../components/BGUrlSelector/BGUrlSelector';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss, smallButtonCss } from './FacebookSlideImage.style';
import CampaignSetting from '../../../../recoil/CampaignSetting';
import { MaterialCheckboxSelector } from '../../MaterialCheckboxSelector/MaterialCheckboxSelector';
import { getUniqKey } from '../../../../utils/array.utils';
import { FacebookApi } from '../../../../lib/api/FacebookApi';
import StorageService from '../../../../services/StorageService';
import { PreviewFacebookItem } from '../PreviewFacebookItem/PreviewFacebookItem';
import { ModalImageSelect } from '../../../Creative/ModalImageSelect/ModalImageSelect';
import { InputError } from '../../../../components/InputError/InputError';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { facebookCTA } from '../../../../constants/campaigns';
import { filters } from '../../../../utils/filter';
import { BGTagInput } from '../../../../components/BGTagInput/BGTagInput';
import { CurrencyCodeRecoil } from '../../../../recoil/Currency';
import { AssetHeader } from '../../../../components/AssetHeader/AssetHeader';

export interface FacebookSlideImageProps {
  setting: CampaignAdSettingState;
  handleUpdate: (setting: CampaignAdSettingState) => void;
  handleValid?: (type: CampaignType, isValid: boolean) => void;
  removeSelectedCampaignTypes: (type: CampaignType) => void;
}

interface FacebookSlideImageForm {
  name: string;
  title: string;
  description: string;
  body: string;
  url: string;
  image_id: number | undefined | null;
  slides: SlideContent[];
}

export const FacebookSlideImage = (props: FacebookSlideImageProps): ReactElement => {
  const i18next = useTranslation();

  useQuery<{ id: string; name: string; picture: string } | undefined>('facebookPage', async () => {
    const res = await FacebookApi().getPage(StorageService.selectedMall?.id);

    return res.data.data as { id: string; name: string; picture: string };
  });

  const [selectedCTA, setSelectedCTA] = useState<FacebookCTA>(
    props.setting?.fb_call_to_action ? props.setting?.fb_call_to_action : 'shop_now',
  );
  const [adName, setAdName] = useState(props.setting?.name);
  const [body, setBody] = useState(props.setting?.fb_body);
  const [slides, setSlides] = useState(props.setting?.slides);
  const [showModalImageSelect, setShowModalImageSelect] = useState<boolean>(false);
  const [selectSlideIndex, setSelectSlideIndex] = useState<number>(0);
  const [biginKeywords, setBiginKeywords] = useState<string[]>(props.setting?.bigin_keywords);
  const { register, unregister, formState, setValue, trigger } = useForm<FacebookSlideImageForm>({ mode: 'all' });

  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  useEffect(() => {
    setSlides(props.setting?.slides);
  }, [props.setting?.slides]);

  const changeLocalSlide = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newSlides = _.cloneDeep(slides);
    newSlides[index][event.target.name.replace(`slides.${index}.`, '')] = event.target.value;
    setSlides(newSlides);
  };

  const changeLocalSlideImage = (index: number, curImage: ProductImage) => {
    const newSlides = _.cloneDeep(slides);
    newSlides[index].product_image = curImage.product_id ? curImage : undefined;
    newSlides[index].product_image_id = curImage.product_id ? curImage.id : undefined;
    newSlides[index].mall_image = curImage.product_id ? undefined : (curImage as MallImage);
    newSlides[index].mall_image_id = curImage.product_id ? undefined : curImage.id;
    newSlides[index].src = curImage.url;
    newSlides[index].image_id = curImage.image_id;
    newSlides[index].url = curImage.product_id ? curImage.detail_url : slides[index]?.url || '';
    newSlides[index].title = curImage.product_id ? curImage.name : slides[index]?.title || '';
    newSlides[index].description = curImage.product_id
      ? filters.formatCurrency({ value: curImage.sale_price, currencyCode, withoutUnit: currencyCode === 'KRW' })
      : slides[index]?.description || '';
    setSlides(newSlides);
  };

  const registerSlideFromValidation = (index: number) => {
    register(`slides.${index}.title` as const, {
      value: slides[index].title,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register(`slides.${index}.description` as const, {
      value: slides[index].description,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register(`slides.${index}.url` as const, {
      value: slides[index].url,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isURL(value)) {
          return i18next.t('잘못된 URL 형식입니다.') as string;
        }

        return true;
      },
    });
    register(`slides.${index}.image_id` as const, {
      value: undefined,
      required: i18next.t('필수 입력란입니다.') as string,
    });
  };

  const unregisterSlideFromValidation = (index: number) => {
    unregister(`slides.${index}.title` as const, { keepDirty: false, keepError: false });
    unregister(`slides.${index}.description` as const, { keepDirty: false, keepError: false });
    unregister(`slides.${index}.url` as const, { keepDirty: false, keepError: false });
    unregister(`slides.${index}.image_id` as const, { keepDirty: false, keepError: false });
  };

  const appendSlide = () => {
    if (slides.length < 10) {
      const tempSlides = _.cloneDeep(slides);
      const newSlide: SlideContent = {
        src: '',
        url: '',
        title: '',
        description: '',
      };
      setSlides([...tempSlides, newSlide]);
    }
  };

  const removeSlideImageContent = (index: number) => {
    const tempSlides = _.cloneDeep(slides);
    tempSlides.splice(index, 1);
    setSlides([...tempSlides]);
  };

  const currentSlideImage = (slideIndex: number) => {
    return (slides[slideIndex]?.mall_image || slides[slideIndex]?.product_image || []) as ImageTypes[];
  };

  const selectedImages = useMemo(() => currentSlideImage(selectSlideIndex), [selectSlideIndex]);

  const handleSelectImages = (images: ImageTypes[]) => {
    changeLocalSlideImage(selectSlideIndex, images[0] as ProductImage);
    setValue(`slides.${selectSlideIndex}.image_id`, images[0].id);

    setShowModalImageSelect(false);
  };

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);

  const updateName = (curName: string) => {
    props.handleUpdate({ ...props?.setting, name: curName });
  };

  const updateBody = (curBody: string) => {
    props.handleUpdate({ ...props?.setting, fb_body: curBody });
  };

  const updateCTA = (curCTA: FacebookCTA) => {
    props.handleUpdate({ ...props?.setting, fb_call_to_action: curCTA });
  };

  const updateSlides = (curSlides: SlideContent[]) => {
    props.handleUpdate({ ...props?.setting, slides: curSlides });
  };

  const updateBiginKeywords = (curKeywords: string[]) => {
    props.handleUpdate({ ...props?.setting, bigin_keywords: curKeywords });
  };

  useEffect(() => {
    if (adName !== props.setting?.name) {
      updateName(adName);
    }
    if (body !== props.setting?.fb_body) {
      updateBody(body);
    }
    if (selectedCTA !== props.setting?.fb_call_to_action) {
      updateCTA(selectedCTA);
    }
    if (slides !== props.setting?.slides) {
      updateSlides(slides);
      slides.map((slide, index) => {
        unregisterSlideFromValidation(index);
        registerSlideFromValidation(index);
        return slide;
      });
      trigger();
    }
    if (!_.isEqual(biginKeywords, props.setting?.bigin_keywords)) {
      updateBiginKeywords(biginKeywords);
    }
  }, [adName, body, selectedCTA, slides, biginKeywords]);

  useEffect(() => {
    register('body', {
      value: body,
      required: i18next.t('필수 입력란입니다.') as string,
    });

    slides.map((slide, index) => {
      registerSlideFromValidation(index);
      return slide;
    });

    trigger();
  }, []);

  useEffect(() => {
    if (props.handleValid) {
      props.handleValid(props.setting?.type, formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <BorderSection>
      <div className="fb-slide-image-setting-wrapper" css={[baseCss]}>
        <AssetHeader
          title={i18next.t('슬라이드 이미지')}
          icons={[
            { imgSrc: '/assets/img/card_icons/ic-channel-facebook.png', alt: 'Facebook' },
            { imgSrc: '/assets/img/card_icons/ic-channel-instagram.png', alt: 'Instagram' },
          ]}
          handleIconClick={() => {
            props.removeSelectedCampaignTypes(props.setting.type);
          }}
        />
        <div className="create-section-content-wrapper">
          <div className="create-section-content">
            <div className="preview-container" style={{ position: 'relative' }}>
              <div style={{ position: 'sticky', top: 117, paddingBottom: 87 }}>
                <div className="title-panel">
                  <div className="title">{i18next.t('미리보기')}</div>
                  <div className="info">{i18next.t('*실제 노출과 미리보기가 다를 수 있습니다.')}</div>
                </div>
                <div className="material-preview-wrapper">
                  <div className="material-preview">
                    <div className="facebook-wrapper">
                      <div className="preview-media-title-wrapper">Facebook</div>
                      <PreviewFacebookItem setting={props.setting} />
                    </div>
                    <div className="instagram-wrapper">
                      <div className="preview-media-title-wrapper">Instagram</div>
                      <PreviewFacebookItem setting={props.setting} instagram />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <h2 className="material-field-header">{i18next.t('광고 소재명')}</h2>
              <div className="material-field-selector-wrapper">
                <BGInput
                  style={{ width: '100%' }}
                  inputProps={{
                    name: 'name',
                    placeholder: i18next.t('광고 소재 이름을 입력해주세요.'),
                    value: adName || '',
                    onChange: (event) => {
                      setAdName(event.target.value);
                    },
                  }}
                />
                <p className="description">{i18next.t('*다른 소재와 구분지을 수 있게 이름을 입력하세요.')}</p>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 텍스트')}</h2>
              <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 문구')} checkboxLabel="" value hiddenCheckbox />
                <div className="material-field-input-wrapper">
                  <BDSTextArea
                    isAutoGrow
                    error={!!formState.errors.body}
                    style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                    textAreaProps={{
                      name: 'fb_body',
                      cols: 30,
                      rows: 3,
                      style: {
                        minHeight: 49,
                      },
                      value: body || '',
                      placeholder: i18next.t('광고 문구를 입력해주세요.'),
                      onChange: (event) => {
                        setBody(event.target.value);
                        setValue('body', event.target.value, { shouldValidate: true });
                      },
                    }}
                    curLength={body.length}
                    limitLength={120}
                    lengthUnit={i18next.t('자')}
                  />
                  {formState.errors.body && <InputError message={formState.errors.body.message as string} />}
                </div>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('행동 유도 버튼')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <BDSSelectBox
                  appearance="gray"
                  style={{ width: '100%', marginTop: '8px' }}
                  list={facebookCTA}
                  defaultValue={props.setting?.fb_call_to_action}
                  displayValue={(item: any) => {
                    return i18next.t(item.label);
                  }}
                  isSelected={(item: any) => {
                    return selectedCTA === item.value;
                  }}
                  handleUpdate={(item: any) => {
                    setSelectedCTA(item.value);
                  }}
                />
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 이미지')}</h2>
              <div className="material-field-selector-wrapper">
                <div className="content-upload-container">
                  <div className="single-image-uploader-wrapper uploader-wrapper">
                    <div className="image-uploader-title-wrapper">
                      <div className="image-uploader-subtitle">
                        <div>{i18next.t('* 권장 사이즈: ')} 1080px*1080px</div>
                        <div>{i18next.t('* 최소 사이즈: ')} 600px*600px</div>
                        <div>{i18next.t('* 파일 형식: ')} JPG, PNG</div>
                        <div>{i18next.t('* 파일 용량: {{size}}MB 이내', { size: 30 })}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {slides &&
                slides.map((slide, index) => {
                  return (
                    <div className="material-field-selector-wrapper" key={getUniqKey('slide-content-wrapper', index)}>
                      <div className="multiple-image-uploader-wrapper">
                        {index > 1 && (
                          <BDSFontIcon
                            className="delete-icon"
                            name="ic-close"
                            color="#7e8696"
                            size="20px"
                            handleClick={() => {
                              removeSlideImageContent(index);
                            }}
                          />
                        )}
                        <h2 className="multiple-image-heading">{`${i18next.t('이미지')} ${index + 1}`}</h2>
                        <div className="multiple-image-uploader-item-wrapper" style={{ flexDirection: 'row', gap: 8 }}>
                          <div css={smallButtonCss} className="image-uploader-btn">
                            {props.setting?.slides[index]?.mall_image || props.setting?.slides[index]?.product_image ? (
                              <img
                                style={{ width: '100%', height: '100%', position: 'relative' }}
                                src={
                                  (
                                    props.setting?.slides[index]?.mall_image ||
                                    props.setting?.slides[index]?.product_image
                                  )?.url
                                }
                                alt="이미지"
                              />
                            ) : (
                              <BDSFontIcon name="ic-img" color="#7e8696" size="20px" />
                            )}
                          </div>
                          <div className="file-wrapper">
                            <BDSButton
                              className="image-updater-btn"
                              onClick={() => {
                                setSelectSlideIndex(index);
                                setShowModalImageSelect(true);
                              }}
                              label={i18next.t('업로드')}
                            />
                            {props.setting.product_image && props.setting.product_image.name && (
                              <div className="file-name">{props.setting.product_image.name}</div>
                            )}
                            {props.setting.mall_image && <div className="file-name">{i18next.t('상품 정보 없음')}</div>}
                          </div>
                        </div>
                        <div className="multiple-image-uploader-item-wrapper">
                          <MaterialCheckboxSelector
                            contentTitle={i18next.t('광고 제목')}
                            checkboxLabel=""
                            value
                            hiddenCheckbox
                          />
                          <BGInput
                            style={{ width: '100%', marginTop: '8px' }}
                            error={formState.errors.slides && !!formState.errors.slides[index]?.title}
                            inputProps={{
                              name: `slides.${index}.title`,
                              value: slide.title || '',
                              placeholder: i18next.t('광고 제목을 입력해주세요.'),
                              onChange: (event) => {
                                changeLocalSlide(event, index);
                                setValue(`slides.${index}.title`, event.target.value, { shouldValidate: true });
                              },
                            }}
                            customLength={slide.title?.length || 0}
                            customLengthLimit={16}
                            lengthUnit={i18next.t('자')}
                          />
                          {formState.errors.slides && (
                            <InputError message={formState.errors.slides[index]?.title?.message as string} />
                          )}
                        </div>
                        <div className="multiple-image-uploader-item-wrapper">
                          <MaterialCheckboxSelector
                            contentTitle={i18next.t('광고 설명')}
                            checkboxLabel=""
                            value
                            hiddenCheckbox
                          />
                          <BGInput
                            style={{ width: '100%', marginTop: '8px' }}
                            error={formState.errors.slides && !!formState.errors.slides[index]?.description}
                            inputProps={{
                              name: 'description',
                              // ...register(`slides.${index}.description` as const),
                              value: slide.description || '',
                              placeholder: i18next.t('광고 설명을 입력해주세요.'),
                              onChange: (event) => {
                                changeLocalSlide(event, index);
                                setValue(`slides.${index}.description`, event.target.value, { shouldValidate: true });
                              },
                            }}
                            customLength={slide.description?.length || 0}
                            customLengthLimit={19}
                            lengthUnit={i18next.t('자')}
                          />
                          {formState.errors.slides && (
                            <InputError message={formState.errors.slides[index]?.description?.message as string} />
                          )}
                          <p className="description" style={{ marginTop: '4px' }}>
                            {i18next.t('*Facebook에서만 보여집니다.')}
                          </p>
                        </div>
                        <div className="multiple-image-uploader-item-wrapper">
                          <MaterialCheckboxSelector
                            contentTitle={i18next.t('이미지 URL')}
                            checkboxLabel=""
                            value
                            hiddenCheckbox
                          />
                          <BGUrlSelector
                            linkButton
                            validationTrackOn
                            isValid={formState.errors.slides && !formState.errors.slides[index]?.url}
                            url={slide.url || ''}
                            handleUpdate={(curUrl: string) => {
                              const newSlides = _.cloneDeep(slides);
                              newSlides[index].url = curUrl;
                              setSlides(newSlides);
                              setValue(`slides.${index}.url`, curUrl, { shouldValidate: true });
                            }}
                            style={{ marginTop: '8px' }}
                            placeholder="www.bigin.io"
                          />
                          {formState.errors.slides && (
                            <InputError message={formState.errors.slides[index]?.url?.message as string} />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div className="material-field-selector-wrapper">
                <BDSButton
                  label={i18next.t('슬라이드 추가')}
                  appearance="secondary"
                  fontIcon="ic-add"
                  onClick={() => appendSlide()}
                />
              </div>
              {campaignState.isBiginConnected && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('연계 캠페인')}</h2>
                  <div className="material-field-selector-wrapper">
                    <MaterialCheckboxSelector
                      contentTitle={i18next.t('핵심 키워드')}
                      checkboxLabel=""
                      value
                      hiddenCheckbox
                    />
                    <BGTagInput
                      style={{ width: '100%', marginTop: '8px' }}
                      inputProps={{
                        name: 'bigin_keywords',
                        placeholder: i18next.t(
                          'Bigin CRM에서 해당 광고 소재를 검색할 때 사용할 키워드를 입력해주세요.',
                        ),
                      }}
                      handleUpdate={(keywords) => {
                        setBiginKeywords(keywords);
                      }}
                      defaultTags={biginKeywords}
                      duplicateText={i18next.t('* 이미 등록된 키워드입니다.')}
                    />
                    <p className="description">
                      {i18next.t('*핵심 키워드는 선택사항이며, 최대 5개까지 입력할 수 있습니다.')}
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModalImageSelect && (
        <ModalImageSelect
          selectType="single"
          selectedImages={selectedImages}
          campaignType={props.setting.type}
          isOpen={showModalImageSelect}
          close={() => setShowModalImageSelect(false)}
          setSelectedImages={handleSelectImages}
        />
      )}
    </BorderSection>
  );
};
