import React, { ReactElement, useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useTranslation } from 'react-i18next';
import { BGLegendStyle } from './BGLegend.style';
import { FontIcon } from '../FontIcon/FontIcon';
import { BGLoadingSpinner } from '../BGLoadingSpinner/BGLoadingSpinner';
import { dataFormatting } from '../../utils/dataFormatting.util';
import { BGInput } from '../BGInput/BGInput';

const styleCss = BGLegendStyle;

export interface BGLegendProps {
  legendLists: LegendList[];
  toggleLegend: (event: any) => void;
  searchLegend: (searchText: string) => void;
  loadMore: () => void;
  hasMore: boolean;
  isSimpleLegend?: boolean;
}

export interface Legend {
  name: string;
  active: boolean;
  level?: number;
  value?: number;
  color: string;
  disabled?: boolean;

  [key: string]: any;
}

export interface LegendList {
  title: string;
  key: string;
  dimensions: any[];
  active: boolean;
  legends: Legend[];
  total: number;
  more: boolean;
  level?: number;
  color?: string;
}

export const BGLegend = (props: BGLegendProps): ReactElement => {
  const i18next = useTranslation();
  const [legendLists, setLegendLists] = useState(props.legendLists);
  const [isLegendBoxOpen, setIsLegendBoxOpen] = useState(
    props.legendLists.map(() => {
      return true;
    }),
  );

  const activeLegend = (legends: Legend[]) => {
    const result = [];
    _.forEach(legends, (legend) => {
      if (legend.active === true) result.push(legend);
    });
    return result.length;
  };

  const searchLegend = _.debounce((event) => {
    props.searchLegend(event.target.value);
  }, 500);

  const toggleLegendBox = (index: number) => {
    const newLegendboxToggle = [...isLegendBoxOpen];
    newLegendboxToggle[index] = !isLegendBoxOpen[index];
    setIsLegendBoxOpen(newLegendboxToggle);
  };

  const toggleLegend = (legendIndex: number, legendListIndex: number) => {
    const newLegendLists = [...legendLists]; // 얕은 복사

    newLegendLists[legendListIndex].legends[legendIndex].active =
      !newLegendLists[legendListIndex].legends[legendIndex].active;

    props.toggleLegend(newLegendLists);
  };

  const userList = () => {
    console.log('userList');
  };

  useEffect(() => {
    setLegendLists(props.legendLists);
  }, [props.legendLists]);

  return (
    <div className="legend-container" css={[styleCss]}>
      {!props.isSimpleLegend && (
        <div className="legend-wrapper">
          <BGInput
            style={{
              height: '32px',
              marginBottom: '8px',
            }}
            leftLabelComponent={<FontIcon name="ic-search" size="20px" color="#7e8696" />}
            inputProps={{
              placeholder: i18next.t('검색'),
              onChange: searchLegend,
            }}
          />
          {legendLists && legendLists.length > 0 && (
            <div className="legend-box">
              {legendLists.map((legendList: LegendList, legendListIndex: number) => {
                return (
                  <div className="legend-box-wrapper" key={legendList.key}>
                    {legendList.title && (
                      <div
                        className="legend-title-wrapper"
                        style={{
                          padding: isLegendBoxOpen[legendListIndex] ? '8px 8px 2px' : '8px',
                        }}
                        onClick={() => {
                          toggleLegendBox(legendListIndex);
                        }}
                      >
                        <div className="left-wrapper">
                          <div className="name-wrapper">{legendList.title}</div>
                          <div className="count-wrapper">
                            {activeLegend(legendList.legends)} / {legendList.total}
                          </div>
                        </div>
                        {!isLegendBoxOpen[legendListIndex] && (
                          <FontIcon className="group-open-icon" name="ic-arrow-down" size="20px" />
                        )}
                        {isLegendBoxOpen[legendListIndex] && (
                          <FontIcon className="group-open-icon" name="ic-arrow-up" size="20px" />
                        )}
                      </div>
                    )}
                    {isLegendBoxOpen[legendListIndex] && (
                      <InfiniteScroll
                        next={() => {
                          props.loadMore();
                        }}
                        hasMore={legendList.more}
                        dataLength={legendList.legends.length}
                        loader={<BGLoadingSpinner isLoading={false} />}
                        height={270}
                      >
                        <React.Fragment>
                          {legendList.legends.map((legend: Legend, legendIndex: number) => {
                            return (
                              <div
                                key={legend.name}
                                className={`legend-item-wrapper noselect ${!legend.active ? 'disabled' : ''} ${
                                  legend.disabled ? 'blocekd' : ''
                                }`}
                                onClick={() => {
                                  if (legend.disabled) return;
                                  toggleLegend(legendIndex, legendListIndex);
                                }}
                              >
                                <div className="legend-item">
                                  {legend.color && legend.active && (
                                    <FontIcon
                                      className="check-icon"
                                      name="ic-checkbox-active"
                                      size="20px"
                                      color="#7e8696"
                                    />
                                  )}
                                  {!legend.color && legend.active && (
                                    <FontIcon className="check-icon" name="ic-checkbox-active" size="20px" />
                                  )}
                                  {!legend.active && (
                                    <FontIcon className="check-icon" name="ic-checkbox-default" size="20px" />
                                  )}
                                  <div>
                                    {legend.name && legend.name !== 'null' && legend.name !== 'undefined'
                                      ? legend.name
                                      : '(none)'}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </React.Fragment>
                      </InfiniteScroll>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
      {props.isSimpleLegend && legendLists && legendLists.length > 0 && (
        <div className="simple-legend-wrapper">
          {legendLists.map((legendList: LegendList, legendListIndex: number) => {
            return (
              <div className="legend-box-wrapper" key={legendList.key}>
                {isLegendBoxOpen[legendListIndex] && (
                  <InfiniteScroll
                    next={() => {
                      props.loadMore();
                    }}
                    hasMore={legendList.more}
                    dataLength={legendList.legends.length}
                    loader={<BGLoadingSpinner isLoading={false} />}
                    height={180}
                  >
                    <React.Fragment>
                      {legendList.legends.map((legend: Legend) => {
                        return (
                          <div
                            key={legend.name}
                            className={`legend-item-wrapper noselect ${!legend.active ? 'disabled' : ''} ${
                              legend.disabled ? 'blocekd' : ''
                            }`}
                            onClick={() => {
                              if (legend.disabled) return;
                              userList();
                            }}
                          >
                            <div className="legend-item">
                              <div className="left-wrapper">
                                {legend.color && legend.active && (
                                  <div className="color-icon" style={{ backgroundColor: legend.color }} />
                                )}
                                <div className="legend-name-wrapper">
                                  {legend.name && legend.name !== 'null' && legend.name !== 'undefined'
                                    ? legend.name
                                    : '(none)'}
                                </div>
                              </div>
                              <div className="legend-value-wrapper">
                                {dataFormatting('Number', legend.value && legend.value >= 0 ? legend.value : 0)}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </React.Fragment>
                  </InfiniteScroll>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
