import React, { ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { BDSButton } from '@bigin/bigin-ui-components';
import { baseCss } from './PlanPayment.style';
import { Period, PeriodValue } from '../../../object-model/model';
import { MaterialDropDown } from '../../../components/PositionSelector/PositionSelector';
import Plan from '../../../services/plans/Plan';
import { filters } from '../../../utils/filter';
import { CurrencyCodeRecoil } from '../../../recoil/Currency';

export interface PlanPaymentProps {
  isDisabled: boolean;
  period: Period;
  selectedPlan: Plan;
  dateToChange: moment.Moment;
  changePeriod: (clickedPeriod: Period) => void;
  onPlanChangeClicked: () => void;
}

export const PlanPayment = (props: PlanPaymentProps): ReactElement => {
  const i18next = useTranslation();
  const { selectedPlan, changePeriod } = props;

  const periodTypes: Array<Period> = [
    { label: i18next.t('월간 결제'), value: PeriodValue.MONTHLY },
    { label: i18next.t('연간 결제'), value: PeriodValue.YEARLY },
  ];

  const [selectedPeriod, setSelectedPeriod] = useState<Period>(periodTypes[0]);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const handleDropdown = (clickedPeriod: Period) => {
    changePeriod(clickedPeriod);
    setSelectedPeriod(clickedPeriod);
  };

  return (
    <div css={[baseCss]}>
      <div className="payment-box">
        <div className="payment-info-section shadow">
          <div className="payment-header flex-row">{i18next.t('결제')}</div>

          {/* 결제 주기 */}
          {selectedPlan && Plan.cloudTypes.indexOf(selectedPlan.type) > -1 && (
            <div className="payment-cycle flex-row">
              <div className="payment-select-box flex-col">
                <div className="payment-form-input flex-row">
                  <MaterialDropDown
                    appearance="gray"
                    list={periodTypes.map((periodItem) => periodItem.label)}
                    defaultValue={selectedPeriod.label}
                    displayValue={(periodLabel) => {
                      return periodLabel as string;
                    }}
                    isSelected={(periodLabel) => periodLabel === selectedPeriod.label}
                    handleUpdate={(periodLabel) => {
                      const clickedPeriod: Period = periodTypes.filter((selected) => selected.label === periodLabel)[0];
                      handleDropdown(clickedPeriod);
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          {selectedPlan && (
            <React.Fragment>
              <div className="payment-info">
                {/* 플랜 가격 */}
                <div className="payment-detail flex-row">
                  <div className="payment-selected-plan-name">{selectedPlan.name}</div>
                  {selectedPlan.subscriptionFee === null ? (
                    <div>
                      <div className="plan-price">{i18next.t('협의 필요')}</div>
                    </div>
                  ) : (
                    <div className="total-price">
                      <div className="total-detail flex-row">
                        <div>{filters.formatCurrency({ value: selectedPlan.subscriptionFee, currencyCode })}</div>
                      </div>
                    </div>
                  )}
                </div>

                {/* 부가세 */}
                <div className="payment-detail flex-row">
                  <div className="payment-selected-plan-name">{i18next.t('부가세 (VAT)')}</div>
                  {selectedPlan.subscriptionFee === null ? (
                    <div>
                      <div className="plan-price">{i18next.t('협의 필요')}</div>
                    </div>
                  ) : (
                    <div className="total-price">
                      <div className="total-detail flex-row">
                        <div>
                          {filters.formatCurrency({
                            value: Math.floor(selectedPlan.subscriptionFee / 10),
                            currencyCode,
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="divide-line" />

                {/* 합계 금액 */}
                <div className="final-price-section flex-row">
                  <div className="final-price-name">{i18next.t('합계')}</div>
                  <div className="final-price">
                    <div className="final-detail flex-row">
                      {selectedPlan.subscriptionFee === null ? (
                        <div>{i18next.t('협의 필요')}</div>
                      ) : (
                        <div>
                          {filters.formatCurrency({
                            value: Math.floor(selectedPlan.subscriptionFee * 1.1),
                            currencyCode,
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* 버튼 */}
              <div className="btn-box">
                <div>
                  <BDSButton
                    isDisabled={props.isDisabled}
                    label={i18next.t('플랜 변경 신청')}
                    style={{ width: '100%', justifyContent: 'center' }}
                    onClick={props.onPlanChangeClicked}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
