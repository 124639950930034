import { css, useTheme, Theme } from '@emotion/react';
import { GS } from '../../../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .horizontal-line {
      width: 100%;
      height: 1px;
      background-color: #ebeef4;
    }

    .create-section-content {
      display: flex;
      justify-content: flex-start;
      position: relative;
      min-height: 500px;
      padding: 0;
      width: 100%;
      /* align-items: flex-start; // Sticky 옵션을 사용할 거라면 활성화 */

      .preview-container {
        width: calc(100% - 400px);
        padding: 0 ${GS.gutter}px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .sticky-wrap {
          position: sticky;
          top: 106px;
          padding-bottom: 87px;
          width: 100%;
        }

        .title-panel {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 20px;
          padding-bottom: 16px;

          .title {
            ${theme.text.h4};
            color: ${theme.colors.mainFont};
          }
          .info {
            ${theme.text.disclaimer}
          }
        }

        .material-preview-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          border: solid 1px #dce0e8;
          background-color: #f9fafb;
          margin-bottom: 60px;

          .material-preview {
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 700px;
            height: 100%;

            .kakao-wrapper {
              .preview-title {
                padding: 20px 20px 0;
                ${theme.text.h4}
                color: ${theme.colors.mainFont};
              }
            }
          }
        }
      }

      .form-container {
        border-left: 1px solid ${theme.colors.divider};
        width: 400px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 20px;

        .material-field-header {
          font-size: 16px;
          font-weight: bold;
          color: ${theme.colors.mainFont};
          margin: 0;
          padding: 16px 20px 20px;
        }

        .material-field-selector-wrapper {
          width: 100%;
          height: auto;
          padding: 0 20px 20px;

          .description {
            margin-top: 8px;
            ${theme.text.disclaimer}
          }

          .material-field-input-wrapper {
            position: relative;

            textarea {
              height: 49px;
            }
          }

          .material-dropdown.gray {
            border-radius: 8px;

            .material-dropdown-item.gray {
              border-radius: 8px;
            }
          }
        }

        .material-trigger-action-information-wrapper {
          margin-top: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          span {
            margin-left: 4px;
            ${theme.text.disclaimer};
          }
        }
      }
    }

    .content-upload-container {
      width: 100%;
      height: auto;
      border-radius: 8px;
      border: solid 1px #dce0e8;
      background-color: #f9fafb;
      overflow: hidden;
      margin-top: 8px;
      .horizontal-line {
        background-color: #ebeef4;
      }

      .content-type-selector {
        display: flex;
        width: 100%;
        height: 36px;
        .content-type-item {
          flex: 1 1 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          ${theme.text.input}
          color: ${theme.colors.subFont};
          cursor: pointer;
          background-color: #ffffff;

          border-bottom: solid 1px #dce0e8;

          &:not(:last-of-type) {
            border-right: solid 1px #dce0e8;
          }

          &.active {
            border-bottom: solid 1px transparent;
            border-radius: 4px;
            background-color: #f9fafb;
            color: ${theme.colors.subFont};
            font-weight: bold;
          }
        }
      }
    }

    .uploader-wrapper {
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      .image-uploader-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }

      .image-uploader-subtitle {
        // view
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & > div {
          //text
          ${theme.text.disclaimer}
        }
      }
    }

    .single-image-uploader-wrapper {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .image-uploader-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding: 16px;
      border-radius: 8px;
      background-color: ${theme.colors.bg3};
      position: relative;

      .image-uploader-item-wrapper {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
      }
    }

    .file-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 24px;

      .file-name {
        font-size: 14px;
        line-height: 1.43;
        color: #3d4046;
        font-weight: bold;
      }
    }
  `;
};
