import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    .modal-header {
      margin-bottom: 20px;
      .header-top {
        display: flex;
        justify-content: space-between;
      }
    }

    .new-card-list {
      width: 100%;
      .card-item {
        margin-bottom: 20px;
        &.card-update {
          margin-bottom: 0;
        }
        &.custom {
          display: flex;
          justify-content: space-between;
          .card-item-sub {
            width: 47%;
            display: flex;
            flex-direction: column;
          }
        }
        .card-header {
          ${theme.text.h5};
          color: ${theme.colors.subFont};
          margin-bottom: 8px;
        }
        .item-description {
          ${theme.text.disclaimer}
          margin-top: 8px;
        }
      }

      .bg-radio-wrapper {
        text-align: center;
        display: flex;

        div.radio-type {
          display: flex;
          align-items: center;
          max-height: 24px;
          font-size: 16px;
          color: #3d4046;
          background-color: #ffffff;
          padding: 8px 0 8px 0;
          text-align: center;

          &:first-of-type {
            margin-right: 32px;
          }
        }
      }
    }

    .horizontal-line {
      margin-top: 8px;
      margin-bottom: 16px;
      margin-left: -20px;
      background-color: #ebeef4;
      width: 360px;
      height: 1px;
      border: 0px;
    }
    .register-card-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .checkbox-wrapper {
      display: flex;
      align-items: center;

      cursor: pointer;

      .checkbox-label {
        margin-left: 4px;
        ${theme.text.input};
        color: ${theme.colors.mainFont};
      }
    }
    .policy-link {
      ${theme.text.input};
      color: #3f7ba0;
    }
  `;
};
