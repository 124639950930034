import { css, useTheme, Theme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();
  return css`
    .elss {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    display: block;
    position: relative;
    height: 76px;
    margin: 0 16px;

    .badge {
      padding: 0 4px;
      height: 20px;
      display: inline-block;
      background-color: ${theme.colors.bg3};
      border-radius: 4px;
      ${theme.text.disclaimer};
      position: relative;
      bottom: 1.5px;
    }
    .button-wrap {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.column {
      height: 44px;
      margin: 16px 0;
      padding: 0 16px 0 20px;

      .button-wrap {
        right: 16px;
      }
    }

    .ico_area {
      float: left;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      font-size: 0;

      .icon {
        width: 37px;
        height: 37px;
        margin-left: 4px;
        margin-right: 11px;

        &.column {
          width: 24px;
          height: 24px;
          margin-right: 2px;
        }
      }
    }

    .dsc_area {
      overflow: hidden;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      .title {
        ${theme.text.h4};
        color: ${theme.colors.mainFont};
      }

      .dsc {
        margin-top: 2px;
        ${theme.text.disclaimer};
      }
    }

    & + & {
      border-top: 1px solid ${theme.colors.divider};
    }

    &.row + &.row {
      border-top: 1px solid ${theme.colors.divider};
    }

    &.column + &.column {
      border-left: 1px solid ${theme.colors.divider};
      border-top: unset;
    }

    &.column:first-of-type:nth-last-of-type(1) {
      float: left;
      width: 100%;
    }
    &.column:first-of-type:nth-last-of-type(2),
    &.column:first-of-type:nth-last-of-type(2) ~ & {
      float: left;
      width: 50%;
    }
    &.column:first-of-type:nth-last-of-type(3),
    &.column:first-of-type:nth-last-of-type(3) ~ & {
      float: left;
      width: 33.33%;
    }
  `;
};
