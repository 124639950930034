import axios, { AxiosPromise } from 'axios';

export type SelfServePaymentParam = 'card' | 'subscription';

export const PaymentApi = () => ({
  getBalance(mallId: number, withDept = false): AxiosPromise<{ data: { remain: number } }> {
    return axios.get(`/malls/${mallId}/credits/remain`, {
      params: {
        with_dept: withDept ? 1 : 0,
      },
    });
  },
  getRegisteredCard(mallId: number): AxiosPromise<{
    data: Card;
  }> {
    return axios.get(`/malls/${mallId}/card?include=lastPayment`, {
      validateStatus: (status) => status < 500,
    });
  },
  registrationCard(mallId: number, data: StoreCardRequestBody): AxiosPromise<{ data: Card }> {
    return axios.put(`/malls/${mallId}/card`, data);
  },
  deleteCard(mallId: number): AxiosPromise {
    return axios.delete(`/malls/${mallId}/card`);
  },
  chargeCredits(
    mallId: number,
    amount_e6: number,
  ): AxiosPromise<{ data: { amount: number; remain_after_this: number } }> {
    return axios.post(`malls/${mallId}/credits/charge`, { amount_e6 });
  },
  getAutoCharge(mallId: number) {
    return axios.get(`malls/${mallId}/auto-charge`, {
      validateStatus: (status) => status < 500,
    });
  },
  setAutoCharge(mallId: number) {
    return axios.post(`malls/${mallId}/auto-charge`);
  },
  offAutoCharge(mallId: number) {
    return axios.delete(`malls/${mallId}/auto-charge`);
  },
  getDailyBudget(mallId: number): AxiosPromise<{ data: { mall_id: number; daily_budget_e6: number } }> {
    return axios.get(`malls/${mallId}/unified-campaigns/aggregate?filter[isLive]=true&filter[trashed]=true`, {
      validateStatus: (status) => status < 500,
    });
  },
  checkInitial(mallId: number): AxiosPromise<{ data: { mall_id: number; daily_budget_e6: number } }> {
    return axios.get(`malls/${mallId}/unified-campaigns/aggregate?filter[trashed]=true`, {
      validateStatus: (status) => status < 500,
    });
  },
  getDateCreditList(
    mallId: number,
    dates: string,
  ): AxiosPromise<{
    data: DateCreditUsage[];
  }> {
    return axios.get(`malls/${mallId}/date-credits?filter[created_at_between]=${dates}&field_remain_e6_after_this=1`);
  },
  getCreditList(
    mallId: number,
    dates: string,
  ): AxiosPromise<{
    data: CreditUsage[];
  }> {
    return axios.get(`malls/${mallId}/credits?filter[created_at_between]=${dates}`);
  },
  getSubscriptions(
    mallId: number,
    dates: string,
    page: number,
  ): AxiosPromise<{ data: SubscribeUsage[]; meta: SubscribeUsabeMeta }> {
    return axios.get(
      `malls/${mallId}/subscriptions?filter[created_at_between]=${dates}&page=${page}&include=payment&aggregate=amount_e6_sum&per_page=10`,
    );
  },
  findBill(mallId: number, year: number, month: number): AxiosPromise<{ data: Bill }> {
    return axios.get(`malls/${mallId}/bills/${year}/${month}`);
  },
});
