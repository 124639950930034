import React, { ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { BDSButton } from '@bigin/bigin-ui-components';
import { welcomeCss, formCss } from './Invitation.style';
import { FormUser, FormUserForm } from '../../components/FormUser/FormUser';
import { BGButtonGroup } from '../../components/BGButtonGroup/BGButtonGroup';
import { LogoFrame } from '../../components/LogoFrame/LogoFrame';
import { useDataStore } from '../../context/Store';
import { UserApi } from '../../lib/api/UserApi';
import { BorderSection } from '../../components/BorderSection/BorderSection';

enum STEP {
  WELCOME,
  FORM,
}

export const Invitation = (): ReactElement => {
  const i18next = useTranslation();
  const { dialogStore } = useDataStore();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { data: pendingUser } = useQuery<PendingUser>(
    'pendingUser',
    async () => (await UserApi().getPendingUser(searchParams.get('token') || '')).data.data,
    {
      onError: () => {
        dialogStore
          .showMessage(
            i18next.t('알림'),
            i18next.t('초대 링크에 문제가 발생했어요.\n팀원에게 팀원 추가를 다시 요청해주세요.'),
          )
          .then(() => {
            navigate(-1);
          });
      },
    },
  );

  const [step, setStep] = useState<STEP>(STEP.WELCOME);
  const [isFormUserValid, setIsFormUserValid] = useState<boolean>(true);

  const next = () => {
    if (step === STEP.WELCOME) {
      setStep(STEP.FORM);
    }
  };

  const invite = (form: FormUserForm) => {
    UserApi()
      .postCompanyUser({
        email: form.email,
        password: form.password,
        password_confirmation: form.passwordConfirm,
        name: form.name,
        phone: form.phone,
        token: searchParams.get('token') || '',
      })
      .then(() => {
        dialogStore
          .showMessage(i18next.t('회원가입 완료'), i18next.t('확인 버튼을 누르시면 \n로그인 페이지로 이동합니다.'))
          .then(() => {
            navigate('/login');
          });
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 409) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <LogoFrame useViewPort>
      <BorderSection style={{ padding: '20px', width: '320px', overflow: 'hidden' }}>
        {step === STEP.WELCOME && (
          <div css={[welcomeCss]}>
            <div
              className="invitation-welcome-header"
              style={{ backgroundImage: 'url(/assets/img/plan/graphic.png)' }}
            />
            <div className="invitation-welcome-body">
              <p className="text-h4 text-color-main mb-6">
                {i18next.t('마케터를 위한 디지털 마케팅 자동화 솔루션\nBigin Ads에 오신 것을 환영합니다.')}
              </p>
              <p className="text-input text-color-main">
                {i18next.t('마케팅 통합 관리와 자동화를 통한 효율적인 마케팅,\nBigin Ads와 함께 시작해보세요.')}
              </p>
            </div>
            <div className="invitation-welcome-footer">
              <BGButtonGroup rightAlign>
                <BDSButton label={i18next.t('시작하기')} onClick={next} isDisabled={!pendingUser} />
              </BGButtonGroup>
            </div>
          </div>
        )}
        {step === STEP.FORM && (
          <div css={[formCss]}>
            <div className="invitation-form-header">
              <div className="text-h1 text-color-main">{i18next.t('회원가입')}</div>
              <p className="text-input text-color-main">{i18next.t('광고주님의 정보를 입력해주세요.')}</p>
            </div>
            <FormUser
              mode="invitation"
              onIsValidChanged={setIsFormUserValid}
              onSubmit={invite}
              email={pendingUser?.email}
            >
              <BGButtonGroup rightAlign>
                <BDSButton label={i18next.t('회원가입')} type="submit" isDisabled={!isFormUserValid} />
              </BGButtonGroup>
            </FormUser>
          </div>
        )}
      </BorderSection>
    </LogoFrame>
  );
};
