import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { BgChartTooltipStyle } from './BgChartTooltip.style';
import { DateRange } from '../Calendar/Calendar';
import { Metric } from '../../object-model/model';
import { dataFormatting } from '../../utils/dataFormatting.util';

export interface ChartTooltipConfig {
  data?: ChartTooltipData[];
  duration?: DateRange;
  compareDuration?: DateRange;
  type?: string;
  style: {};
  metric: Metric;
  metrics?: Metric[];
  chartType: string;
  campaign?: string;
  tooltipType?: string;
}

export interface ChartTooltipData {
  key?: string;
  type?: string;
  color?: string;
  dateKey?: string;
  value?: number;
  rate?: number;
  comparerate?: number;
  metric?: string;
  format?: string;
  defaultValue?: number;
  compareValue?: number;
  fields?: any[];
}

export interface BgChartTooltipProps {
  config: ChartTooltipConfig;
}

const styleCss = BgChartTooltipStyle;

export const BgChartTooltip = (props: BgChartTooltipProps): ReactElement => {
  const datas = props.config.data;

  return (
    <div className="bg-chart-tooltip-container" css={[styleCss]} style={props.config.style}>
      {props.config.tooltipType && props.config.tooltipType === 'Line' && (
        <div className="vs-tooltip-wrapper vs-line-tooltip-wrapper">
          <div className="tooltip-header-wrapper">{datas && datas[0].dateKey}</div>
          {datas &&
            datas.map((data: ChartTooltipData) => {
              return (
                <div className="wrapper" key={`${data.key}_${data.type}`}>
                  <div className="tooltip-data-wrapper">
                    <div className="left-wrapper">
                      <div
                        className="legend-color-icon"
                        style={{
                          background: data.type && data.type === 'compare' ? 'white' : data.color,
                          border:
                            data.type && data.type === 'compare'
                              ? `1px dotted ${data.color}`
                              : `1px solid ${data.color}`,
                        }}
                      />
                      <div className="metric-name-rapper">{data.key}</div>
                    </div>
                    <div className="value-wrapper">
                      <div className="link-btn">
                        {dataFormatting(data.format || 'Number', data.value || 0)}
                        {data.format === 'Percent' ? '%' : ''}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
