import React, { useEffect, useState, ReactElement, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { BDSButton, BDSFontIcon } from '@bigin/bigin-ui-components';
import { baseCss } from './EditMallInfo.style';
import { BGButtonGroup } from '../../../../components/BGButtonGroup/BGButtonGroup';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { InputError } from '../../../../components/InputError/InputError';
import { MallApi } from '../../../../lib/api/MallApi';
import StorageService from '../../../../services/StorageService';

interface IEditMallInfoForm {
  mallName: string;
  mallClassificationId: number | null;
}

export interface EditMallInfoProps {
  mall: Mall;
  close: () => void;
}

export const ModalEditMallInfo = (props: EditMallInfoProps): ReactElement => {
  const { ...i18next } = useTranslation();
  const [mallName, setMallName] = useState<string>(props.mall.name);
  const [mallClassificationId, setMallClassificationId] = useState<number | null>(props.mall.classification_id || null);
  const [parentMallClassificationId, setParentMallClassificationId] = useState<number | null>();
  const [listOpen, setListOpen] = useState<boolean>(false);
  const { data: mallClassifications } = useQuery<MallClassification[]>('mallClassifications', async () => {
    const res = await MallApi().getMallClassifications();

    setParentMallClassificationId(
      res.data.data.find((item) => item.children.find((child) => child.id === props.mall.classification_id))?.id ||
        null,
    );

    return res.data.data;
  });

  const childMallClassifications = useMemo(() => {
    const parent = mallClassifications && mallClassifications.find((item) => item.id === parentMallClassificationId);

    return parent ? parent.children : [];
  }, [mallClassifications, parentMallClassificationId]);

  const { register, formState, setValue, handleSubmit } = useForm<IEditMallInfoForm>({
    mode: 'all',
  });

  const selectedClassificationLabel = useMemo(() => {
    const parentLabel =
      (mallClassifications && mallClassifications.find((parent) => parent.id === parentMallClassificationId)?.name) ??
      '';
    const childLabel = childMallClassifications.find((child) => child.id === mallClassificationId)?.name ?? '';

    return parentLabel && childLabel
      ? `${i18next.t(parentLabel)} > ${i18next.t(childLabel)}`
      : i18next.t('업종 카테고리 선택');
  }, [mallClassifications, childMallClassifications, mallClassificationId]);

  useEffect(() => {
    setListOpen(false);
  }, [mallClassificationId]);

  useEffect(() => {
    register('mallName', { value: mallName, required: i18next.t('필수 입력란입니다.') as string });
  }, []);

  const onSubmit = async () => {
    await MallApi()
      .patchMall(StorageService.selectedMall?.id, {
        name: mallName,
        classification_id: mallClassificationId,
      })
      .then((res) => {
        StorageService.selectedMall = {
          ...StorageService.selectedMall,
          name: res.data.data.name,
        };
        window.location.reload();

        props.close();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <div css={[baseCss]}>
      <div className="edit-pannel-wrapper">
        <div className="edit-title-wrapper">
          <div className="text-h1 text-color-main">{i18next.t('쇼핑몰 정보 수정')}</div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="edit-pannel-name">
            <div className="edit-name-box flex-col">
              <div className="info-title">{i18next.t('쇼핑몰명')}</div>
              <BGInput
                error={!!formState.errors.mallName}
                inputProps={{
                  placeholder: i18next.t('쇼핑몰명을 입력해주세요.'),
                  value: mallName,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('mallName', event.target.value, { shouldValidate: true });
                    setMallName(event.target.value);
                  },
                }}
              />
              {formState.errors.mallName && <InputError message={formState.errors.mallName.message as string} />}
            </div>
          </div>
          {mallClassifications && childMallClassifications && (
            <div className="edit-pannel-phone">
              <div className="edit-phone-box flex-col">
                <div className="info-title">{i18next.t('업종 카테고리')}</div>

                <div className="classification-list-container">
                  <div className="classification-button" onClick={() => setListOpen(!listOpen)}>
                    <span className="text-input text-color-sub">{selectedClassificationLabel}</span>
                    <BDSFontIcon name="ic-arrow-down" size="20px" color="#7e8696" />
                  </div>
                  {listOpen && (
                    <div className="classification-list-wrapper">
                      <div className="list-header">
                        <div className="header-item">{i18next.t('대분류')}</div>
                        <div className="header-item">{i18next.t('소분류')}</div>
                      </div>

                      <div className="list-contents">
                        <div className="child-list">
                          <ul>
                            {[...mallClassifications.map((item) => ({ value: item.id, label: item.name }))].map(
                              (item) => (
                                <li
                                  className={parentMallClassificationId === item.value ? 'active' : ''}
                                  role="presentation"
                                  key={item.value}
                                  onClick={() => setParentMallClassificationId(item.value)}
                                >
                                  {i18next.t(item.label)}
                                  <BDSFontIcon name="ic-arrow-right" size="20px" color="#7e8696" />
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                        <div className="child-list">
                          <ul>
                            {[...childMallClassifications.map((item) => ({ value: item.id, label: item.name }))].map(
                              (item) => (
                                <li
                                  className={mallClassificationId === item.value ? 'active' : ''}
                                  role="presentation"
                                  key={item.value}
                                  onClick={() => {
                                    setMallClassificationId(item.value);
                                  }}
                                >
                                  {i18next.t(item.label)}
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <BGButtonGroup rightAlign style={{ marginTop: '24px' }}>
            <BDSButton
              key={i18next.t('취소') as string}
              label={i18next.t('취소')}
              onClick={() => {
                props.close();
              }}
              appearance="secondary"
            />
            <BDSButton
              isDisabled={!formState.isValid}
              key={i18next.t('수정') as string}
              label={i18next.t('수정')}
              type="submit"
            />
          </BGButtonGroup>
        </form>
      </div>
    </div>
  );
};
