import React from 'react';

import { Navigate, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useQueryClient } from 'react-query';

import StorageService from '../services/StorageService';
import { MallApi } from '../lib/api/MallApi';
import { BGLoadingSpinner } from '../components/BGLoadingSpinner/BGLoadingSpinner';
import { UserApi } from '../lib/api/UserApi';
import { initGridColumn } from '../pages/CampaignDashboard/CampaignDashboardDef';

interface PrivateRouteProps {
  children: JSX.Element;
  path: string;
  key: string;
  roles: string[];
  guards: string[];
}

export const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
  const queryClient = useQueryClient();
  const pathParams = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dashboardMatch = useMatch('/malls/:mallId/campaign-dashboard') || useMatch('/malls/:mallId/campaign-report');

  const refetch = async (mallId?: number) => {
    const selectedMallId = mallId || StorageService.selectedMall?.id;

    return MallApi()
      .getMalls({ includes: ['active_subscription'] })
      .then((response) => {
        const malls = response.data.data;

        const index = _.findIndex(malls, { id: selectedMallId });
        const hasMall = index > -1;
        if (hasMall) {
          StorageService.selectedMall = malls[index];
          StorageService.activePlan = malls[index].active_subscription;
        } else if (malls.length > 0) {
          [StorageService.selectedMall] = malls; // Same as `StorageService.selectedMall = malls[0];`
          StorageService.activePlan = malls[0].active_subscription;
        } else {
          StorageService.selectedMall = null;
          StorageService.activePlan = null;
        }

        if (dashboardMatch) {
          StorageService.saveGridColumns = initGridColumn();
        }
      });
  };
  const refetchMe = async () => {
    return UserApi()
      .getMe()
      .then((response) => {
        StorageService.user = response.data.data;
      });
  };

  const hasAuthGuard = props.guards.findIndex((guard) => guard === 'auth') > -1;
  if (hasAuthGuard) {
    const hasAuth = !!StorageService.token;
    if (!hasAuth) {
      return <Navigate to={{ pathname: '/login' }} />;
    }

    const hasUser = !!StorageService.user;
    if (!hasUser) {
      refetchMe().then(() => {
        if (StorageService.user) {
          navigate(`${location.pathname}${location.search}${location.hash}`, { replace: true });
        } else {
          navigate('/login');
        }
      });

      return <BGLoadingSpinner isLoading />;
    }
  }

  const mallId = Number(pathParams.mallId);
  const hasMallId = !!mallId;
  if (hasMallId && mallId !== StorageService.selectedMall?.id) {
    refetch(mallId).then(() =>
      queryClient.invalidateQueries('remain').then(() => {
        if (StorageService.selectedMall) {
          if (StorageService.selectedMall.id === mallId) {
            navigate(`${location.pathname}${location.search}${location.hash}`, { replace: true });
          } else {
            navigate(`/malls/${StorageService.selectedMall.id}/campaign-dashboard`, { replace: true });
          }
        } else {
          navigate('/malls/create');
        }
      }),
    );

    return <BGLoadingSpinner isLoading />;
  }

  const hasMallGuard = props.guards.findIndex((guard) => guard === 'mall') > -1;
  const hasSelectedMall = !!StorageService.selectedMall;
  if (hasMallGuard && !hasSelectedMall) {
    refetch().then(() =>
      queryClient.invalidateQueries('remain').then(() => {
        if (StorageService.selectedMall) {
          navigate(`/malls/${StorageService.selectedMall.id}/campaign-dashboard`, { replace: true });
        } else {
          navigate('/malls/create');
        }
      }),
    );

    return <BGLoadingSpinner isLoading />;
  }

  const hasManagerGuard = props.guards.findIndex((guard) => guard === 'manager') > -1;
  const hasUser = !!StorageService.user;
  if (hasManagerGuard && !hasUser) {
    refetchMe().then(() => {
      if (StorageService.user) {
        navigate(`${location.pathname}${location.search}${location.hash}`, { replace: true });
      } else {
        navigate('/login');
      }
    });

    return <BGLoadingSpinner isLoading />;
  }
  const isManager = StorageService.user?.type === 'manager';
  if (hasManagerGuard && !isManager) {
    Promise.all([]).then(() => navigate(-1));

    return <BGLoadingSpinner isLoading />;
  }

  const hasPlanGuard = props.guards.findIndex((guard) => guard === 'plan') > -1;
  const hasPlan = !!StorageService.activePlan;
  if (hasPlanGuard && !hasPlan) {
    refetch().then(() =>
      queryClient.invalidateQueries('remain').then(() => {
        if (StorageService.activePlan) {
          navigate(`${location.pathname}${location.search}${location.hash}`, { replace: true });
        } else {
          navigate(`/malls/${StorageService.selectedMall.id}/plans/recommendation`);
        }
      }),
    );

    return <BGLoadingSpinner isLoading />;
  }

  return props.children;
};
