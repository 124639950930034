import React, { useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Backdrop } from '../../../components/Backdrop/Backdrop';
import { ImageManageView } from '../ImageMangeView/ImageManageView';
import { baseCss } from './ModalImageSelect.style';

export interface ModalImageSelectProps {
  selectType?: SelectType;
  campaignType?: CampaignType;
  selectedImages: ImageTypes[];
  isRatioSelectionMode?: boolean;
  isOpen: string | number | boolean;
  close: () => void;
  setSelectedImages: (images: ImageTypes[]) => void;
  ratio?: '1:1' | '1.91:1' | '2:1';
}

export interface UploaderRef {
  uploadFiles: () => void;
}
export const ModalImageSelect = ({
  selectType = 'single',
  selectedImages = [],
  campaignType,
  // isRatioSelectionMode = false,
  isOpen,
  close,
  setSelectedImages,
  ratio = '1:1',
}: ModalImageSelectProps): JSX.Element => {
  const i18next = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.width = '100%';

      setTimeout(() => {
        setVisible(true);
      }, 100);
    } else {
      setVisible(false);
      document.body.removeAttribute('style');
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.removeAttribute('style');
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <div className={`modal-container ${visible && 'visible'}`} css={[baseCss]}>
      <Backdrop
        handleClick={close}
        style={{
          zIndex: 99,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        }}
      />
      <div className="modal-contents-wrapper">
        <div className="modal-header">
          <div className="modal-title">{i18next.t('파일 등록')}</div>
        </div>
        <div className="modal-contents">
          <ImageManageView
            templateType="dialog"
            type={{ type: 'create-campaign', value: campaignType ?? '', ratio }}
            selectType={selectType}
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            modalClose={close}
          />
        </div>
      </div>
    </div>
  );
};
