import React, { ReactElement, useEffect, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, useTheme, Theme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useQuery } from 'react-query';

import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { MenuButton } from '../MenuButton/MenuButton';
import { BGTooltip } from '../BGTooltip/BGTooltip';
import SideNavBarRecoil from '../../recoil/SideNavBar';
import { baseCss } from './SideNavBar.style';
import useHover from './useHover';
import StorageService from '../../services/StorageService';
import { PaymentApi } from '../../lib/api/PaymentApi';
import { WrapperModal } from '../WrapperModal/WrapperModal';
import { HeaderSetUp } from '../HeaderSetUp/HeaderSetUp';
import { filters } from '../../utils/filter';
import { CurrencyCodeRecoil } from '../../recoil/Currency';

export interface SideNavBarProps {
  style?: object;
}

const CheckStores = (items: any) => {
  const isCollapsed = useRecoilValue(SideNavBarRecoil.isCollapsed);
  const isCollapsedHover = useRecoilValue(SideNavBarRecoil.isCollapsedHover);

  return (
    <div
      style={{
        width: '100%',
        padding: '0 12px',
        boxSizing: 'border-box',
        flex: 'auto',
      }}
    >
      <div className="side-nav-menu">
        {items.items
          .filter((item: any) => item.hidden !== true)
          .map((item: any) => {
            return (
              <MenuButton
                key={item.link}
                to={item.link}
                label={item.label}
                image={item.icon}
                activeImage={item.activeIcon}
                menu={item.menu || []}
                externalLink={item.external_link}
                rootMenu={item}
                childMenu={item?.childMenu}
                isActiveAlarm={item?.isActiveAlarm}
                isSmall={isCollapsed && !isCollapsedHover}
                handleOnClick={() => {
                  // queryClient.invalidateQueries('findActiveOffSiteCampaignCount');
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export const SideNavBar = (props: SideNavBarProps): ReactElement => {
  const i18next = useTranslation();
  const hoverRef = useRef<HTMLDivElement>(null);
  const isSideNavHover = useHover(hoverRef);
  const [isModal, setIsModal] = useState('');
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const navigate = useNavigate();

  const items = [
    {
      id: 1,
      icon: 'ic-notice',
      link: `/malls/${StorageService.selectedMall?.id ?? ''}/campaign-dashboard`,
      label: i18next.t('캠페인 관리'),
      showDemo: true,
      hidden: false,
      external_link: false,
    },
    {
      id: 2,
      icon: 'ic-img',
      link: `/malls/${StorageService.selectedMall?.id ?? ''}/creative`,
      label: i18next.t('크리에이티브'),
      showDemo: true,
      hidden: false,
      external_link: false,
    },
    {
      id: 4,
      icon: 'ic-start',
      link: `/admin`,
      label: i18next.t('임시 어드민'),
      showDemo: false,
      hidden: StorageService.user?.type !== 'manager',
      external_link: false,
      menu: [
        { label: i18next.t('Meta 캠페인 전환 유사 타겟 생성'), link: '/admin/create-target', id: 1, showDemo: true },
      ],
    },
  ];
  const bottomItem = [
    {
      id: 1,
      icon: 'ic-setting',
      link: `/malls/${StorageService.selectedMall?.id ?? ''}/accounts?tab=account`,
      label: i18next.t('설정'),
      showDemo: true,
      hidden: false,
      external_link: false,
    },
    {
      id: 2,
      icon: 'ic-card',
      label: i18next.t('결제 관리'),
      link: `/malls/${StorageService.selectedMall?.id ?? ''}/payment`,
      showDemo: false,
      hidden: false,
      external_link: false,
    },
    {
      id: 3,
      icon: 'ic-help-center',
      label: i18next.t('헬프 센터'),
      link: 'https://bigin.notion.site/Bigin-Ads-help-center-7b11e0bf7f13426aa8940c1d7e5c109c',
      showDemo: false,
      hidden: false,
      external_link: true,
    },
    {
      id: 4,
      icon: 'ic-contact',
      label: i18next.t('문의 요청'),
      link: 'http://pf.kakao.com/_xnwPeb/chat',
      showDemo: false,
      hidden: !StorageService.activePlan?.type.includes('cloud'),
      external_link: true,
    },
  ];

  const theme: Theme = useTheme();
  const themeCss = css`
    border-right: 1px solid ${theme.colors.divider};
    background-color: ${theme.colors.bg1};

    .side-nav-wallet-list {
      border-top: 1px solid ${theme.colors.divider};
      border-bottom: 1px solid ${theme.colors.divider};
    }

    .side-nav-bottom-list {
      border-top: 1px solid ${theme.colors.divider};
    }

    .demo {
      border-top: 1px solid ${theme.colors.divider};
    }
  `;

  const propsObj = props;

  const [isCollapsed, setIsCollapsed] = useRecoilState(SideNavBarRecoil.isCollapsed);
  const [isCollapsedHover, setIsCollapsedHover] = useRecoilState(SideNavBarRecoil.isCollapsedHover);
  const [isBlockCollapsedHover, setIsBlockCollapsedHover] = useRecoilState(SideNavBarRecoil.isBlockCollapsedHover);

  const closeModal = () => {
    setIsModal('');
  };
  const openModal = (type: string) => {
    setIsModal(type);
  };

  useEffect(() => {
    if (isSideNavHover && isCollapsed && !isBlockCollapsedHover) {
      setIsCollapsedHover(true);
    } else {
      setIsCollapsedHover(false);
    }
    if (isModal) {
      setIsCollapsedHover(true);
    }
  });

  const { data: remain } = useQuery<number>(
    'remain',
    async () => {
      if (!StorageService.selectedMall) {
        return 0;
      }

      return (await PaymentApi().getBalance(StorageService.selectedMall.id)).data.data.remain;
    },
    { staleTime: 1000 * 30 },
  );

  return (
    <React.Fragment>
      <div
        ref={hoverRef}
        css={[baseCss, themeCss]}
        style={propsObj.style || {}}
        className={`side-nav-bar ${!isCollapsedHover && isCollapsed ? 'mini' : ''} ${
          isCollapsedHover ? 'mini-hover' : ''
        } `}
      >
        <div className="side-nav-logo-panel">
          {(isCollapsedHover || !isCollapsed) && (
            <div onClick={() => navigate(`/malls/${StorageService.selectedMall?.id}/campaign-dashboard`)}>
              <img src="/assets/img/sideNavBar/logo-bigin-ads.svg" alt="logo" className="side-nav-logo" />
            </div>
          )}
          {!isCollapsedHover && isCollapsed && (
            <div onClick={() => navigate(`/malls/${StorageService.selectedMall?.id}/campaign-dashboard`)}>
              <img src="/assets/img/sideNavBar/logo-mini-ads.svg" alt="logo-mini" className="side-nav-logo-mini" />
            </div>
          )}
          <BGTooltip
            title={(isCollapsed ? i18next.t('사이드바 펼치기') : i18next.t('사이드바 접기')) || ''}
            placement="right"
          >
            <div
              className="side-nav-bar-button"
              onClick={(event) => {
                event.stopPropagation();

                setIsBlockCollapsedHover(true);
                setTimeout(() => {
                  setIsBlockCollapsedHover(false);
                }, 500);

                setIsCollapsed(!isCollapsed);
              }}
            >
              <BDSFontIcon
                name={isCollapsed ? 'ic-double-arrow-right' : 'ic-double-arrow-left'}
                size="20px"
                color="#7e8696"
              />
            </div>
          </BGTooltip>
        </div>
        <div
          className="side-nav-project-id"
          onClick={() => {
            // sideNavBarStore.setSideNavBarIsBlockCollapsedHover(true);
            openModal('setup');
          }}
        >
          <div
            css={css`
              white-space: nowrap;
              width: fit-content;
              text-overflow: ellipsis;
              overflow: hidden;
            `}
          >
            {StorageService.selectedMall?.name || ''}
          </div>
          <BDSFontIcon name="ic-arrow-down" size="20px" color="#7e8696" />
        </div>
        {(!isCollapsed || isCollapsedHover) && (
          <div css={{ marginBottom: '12px', width: '100%' }}>
            <div
              className="side-nav-wallet-list"
              onClick={() => {
                navigate(`/malls/${StorageService.selectedMall?.id ?? ''}/payment`);
              }}
            >
              <div className="side-nav-wallet-item">
                <BGTooltip title={i18next.t('크레딧:캠페인에 사용') || ''} placement="right">
                  <div
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <BDSFontIcon name="ic-credit" size="18px" color="#7e8696" />
                  </div>
                </BGTooltip>
                <span>{typeof remain === 'number' && filters.formatCurrency({ value: remain, currencyCode })}</span>
              </div>
            </div>
          </div>
        )}
        <div style={{ marginTop: isCollapsed && !isCollapsedHover ? '92px' : '0' }} />
        <CheckStores items={items} />
        <div className="side-nav-bottom-list">
          <CheckStores items={bottomItem} />
        </div>
      </div>
      {isModal && (
        <WrapperModal isFitContentWidth isOpen={isModal} close={closeModal} type="header" backgroundColor="unset">
          <HeaderSetUp close={closeModal} />
        </WrapperModal>
      )}
    </React.Fragment>
  );
};
