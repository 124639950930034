import { css, SerializedStyles, Theme } from '@emotion/react';

export const baseCss = (theme: Theme): SerializedStyles => {
  const {
    colors: { secondary1, subFont, bg1, bg3, divider },
  } = theme;

  return css`
    position: relative;
    width: 100%;
    height: 36px;

    .pagination-wrapper {
      padding: 8px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        margin-right: 4px;
      }

      .page-button {
        font-size: 14px;
        text-align: center;
        color: ${subFont};

        &.active {
          background-color: ${secondary1};
          font-weight: bold;
          color: ${bg1};
        }

        &:not(.active):hover {
          background-color: ${bg3};
        }
      }
      .pre-button {
        &:hover {
          background-color: ${bg3};
        }
      }
      .next-button {
        &:hover {
          background-color: ${bg3};
        }
      }
    }
    .list-count-select-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 36px;
      .total-wrapper {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: ${subFont};
        border-right: 1px solid ${divider};
        padding-right: 16px;
        margin-right: 16px;
        height: 24px;
        display: flex;
        align-items: center;
      }
      .list-count-selector {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: right;
        color: ${subFont};
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  `;
};
