import { css } from '@emotion/react';

export const baseCss = () => {
  return css`
    width: 88px;
    height: 88px;
    border-radius: 4px;
    display: inline-block;
    border: solid 1px #dce0e8;
    box-sizing: border-box;
    overflow: hidden;
    margin: 0;
    font-size: 0;
    position: relative;

    .checkbox-button {
      position: absolute;
      top: 8px;
      left: 8px;
      padding: 0;
      background: transparent;
      cursor: default;
    }

    .dark-layer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.6);
      z-index: 1;

      .edit-button {
        position: absolute;
        bottom: 8px;
        right: 8px;
        padding: 0;
        background: transparent;
        cursor: pointer;
      }
    }

    .delete-button {
      width: 20px;
      height: 20px;
      position: absolute;
      border: solid 1px #dce0e8;
      top: 8px;
      right: 8px;
      padding: 0;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
    }

    .image {
      width: 88px;
      height: 88px;
      object-fit: cover;
    }

    .badge {
      position: absolute;
      background-color: #006fff;
      top: 8px;
      left: 8px;
      padding: 2px 4px;
      min-width: 12px;
      border-radius: 4px;
      font-size: 12px;
      color: #fff;
      line-height: 1.67;
      z-index: 3;
    }
  `;
};
