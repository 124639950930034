import React, { ReactElement, ReactNode, useEffect } from 'react';

import { Route, Routes, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import axios, { AxiosError } from 'axios';

import { useTranslation } from 'react-i18next';
import StorageService from '../services/StorageService';

import { PrivateRoute } from './PrivateRoute';
import { Wrapper } from '../components/Wrapper/Wrapper';
import { Toast } from '../components/Toast/Toast';
import { WrapperModal } from '../components/WrapperModal/WrapperModal';
import { DialogConfirm } from '../components/DialogConfirm/DialogConfirm';
import { useDataStore } from '../context/Store';
import { ManageAccount } from '../pages/ManageAccount/ManageAccount';
import { FindPassword } from '../pages/FindPassword/FindPassword';
import { Login } from '../pages/Login/Login';
import { SignUp } from '../pages/SignUp/SignUp';
import { CampaignDashboard } from '../pages/CampaignDashboard/CampaignDashboard';
import { AddCampaign } from '../pages/AddCampaign/AddCampaign';
import { EditAd } from '../pages/EditAd/EditAd';
import { CampaignReport } from '../pages/CampaignReport/CampaignReport';
import { ManagePayment } from '../pages/ManagePayment/ManagePayment';
import { CreateCampaign } from '../pages/CreateCampaign/CreateCampaign';
import { Creative } from '../pages/Creative/Creative';
import { PlanRecommendation } from '../pages/PlanRecommendation/PlanRecommendation';
import { PlanConfirm } from '../pages/PlanConfirm/PlanConfirm';
import { PlanComplete } from '../pages/PlanComplete/PlanComplete';
import { PlanChange } from '../pages/PlanChange/PlanChange';
import { WrapperMall } from '../components/WrapperMall/WrapperMall';
import { Invitation } from '../pages/Invitation/Invitation';
import { NewMall } from '../pages/NewMall/NewMall';
import { CopyCampaign } from '../pages/CopyCampaign/CopyCampaign';
import { CreateImage } from '../pages/CreateImage/CreateImage';
import { CreateTarget } from '../pages/CreateTarget/CreateTarget';
import { BiginLoginCallback } from '../pages/BiginLoginCallback/BiginLoginCallback';
import { BiginCrmCallback } from '../pages/BiginCrmCallback/BiginCrmCallback';
import { TitleChange } from '../components/TitleChange/TitleChange';
import { multilingual } from '../utils/multilingual.util';

// @ts-ignore
const GlobalToast = observer(() => {
  const { toastStore } = useDataStore();
  return (
    toastStore.isShow && (
      <Toast
        type={toastStore.type}
        status={toastStore.status}
        message={toastStore.message}
        time={toastStore.time}
        button={toastStore.button}
        position={toastStore.position}
        closeCallBack={() => toastStore.hideToast()}
      />
    )
  );
});

// @ts-ignore
const GlobalDialog = observer(() => {
  const i18next = useTranslation();
  const { dialogStore } = useDataStore();

  const confirmButtons = [
    {
      label: i18next.t('취소'),
      appearance: 'secondary',
      handleClick: () => {
        // dialogStore.cancelReject('cancel');
        dialogStore.hideDialog();
      },
    },
    {
      label: i18next.t('확인'),
      handleClick: () => {
        dialogStore.confirmResolve('confirm');
        dialogStore.hideDialog();
      },
    },
  ];
  const messageButtons = [
    {
      label: i18next.t('확인'),
      handleClick: () => {
        dialogStore.confirmResolve('ok');
        dialogStore.hideDialog();
      },
    },
  ];

  const handleClickHide = () => {
    if (!dialogStore.option?.disableClose) {
      dialogStore.hideDialog();
    }
  };

  return (
    <React.Fragment>
      {dialogStore.type === 'confirm' && (
        <WrapperModal isOpen={`${dialogStore.isShow ? 'true' : ''}`} close={handleClickHide} isDialogConfirm>
          <DialogConfirm title={dialogStore.title} desc={dialogStore.message} buttons={confirmButtons} />
        </WrapperModal>
      )}
      {dialogStore.type === 'message' && (
        <WrapperModal isOpen={`${dialogStore.isShow ? 'true' : ''}`} close={handleClickHide} isDialogConfirm>
          <DialogConfirm title={dialogStore.title} desc={dialogStore.message} buttons={messageButtons} />
        </WrapperModal>
      )}
      {dialogStore.type === 'dialog' && (
        <WrapperModal isOpen={`${dialogStore.isShow ? 'true' : ''}`} close={handleClickHide} isDialogConfirm>
          <DialogConfirm title={dialogStore.title} desc={dialogStore.message} buttons={dialogStore.buttons} />
        </WrapperModal>
      )}
      {dialogStore.type === 'component' && (
        <WrapperModal
          isOpen={`${dialogStore.isShow ? 'true' : ''}`}
          close={handleClickHide}
          isDialogConfirm={!!dialogStore.option?.isDialogConfirm}
        >
          {dialogStore.children}
        </WrapperModal>
      )}
    </React.Fragment>
  );
});

const RouteComponent = (): JSX.Element => {
  const i18next = useTranslation();
  const navigate = useNavigate();

  const { dialogStore } = useDataStore();

  const logout = () => {
    StorageService.clear();
    dialogStore.showMessage(i18next.t('알림'), i18next.t('로그인이 만료되었습니다.'));
    navigate('/login');
  };

  axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL;

  // axios intercepter
  axios.interceptors.request.use(function (config) {
    if (StorageService.token) {
      return {
        ...config,
        headers: {
          Authorization: `Bearer ${StorageService.token}`,
        },
      };
    }
    return config;
  });

  axios.interceptors.response.use(
    function (response) {
      return response;
    },

    function (error: AxiosError) {
      const response = error?.response;
      if (response && response.status === 401) {
        logout();
        return;
        // TODO expired token일 경우 refreshToken()
      }
      if (response && response.status === 403 && response.data.message === 'MallPolicyViolated') {
        dialogStore.showMessage(
          i18next.t('알림'),
          i18next.t('해당 기능의 접근 권한이 없습니다. 쇼핑몰 소유자에게 권한을 요청해주세요.'),
        );
      } else if (response && response.status === 422) {
        dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message || response.data.message);
      } else if (response && response.status >= 500 && response.status < 600) {
        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
      }

      throw error;
    },
  );

  // const defaultPrivateRole = ['Starter', 'Growth', 'Professional', 'Enterprise', 'Free-trial'];
  const routes: Array<{
    path: string;
    component: () => ReactElement;
    roles: string[];
    guards: string[];
    layout?: (props: { children: ReactNode | undefined }) => ReactElement;
  }> = [
    {
      path: '/malls/:mallId/campaign-report',
      component: CampaignReport,
      roles: [],
      guards: ['auth', 'mall', 'plan'],
      layout: Wrapper,
    },
    {
      path: '/malls/:mallId/campaign-dashboard',
      component: CampaignDashboard,
      roles: [],
      guards: ['auth', 'mall', 'plan'],
      layout: Wrapper,
    },
    {
      path: '/malls/:mallId/add-campaign',
      component: AddCampaign,
      roles: [],
      guards: ['auth', 'mall', 'plan'],
      layout: Wrapper,
    },
    {
      path: '/malls/:mallId/copy-campaign',
      component: CopyCampaign,
      roles: [],
      guards: ['auth', 'mall', 'plan'],
      layout: Wrapper,
    },
    {
      path: '/malls/:mallId/edit-ad',
      component: EditAd,
      roles: [],
      guards: ['auth', 'mall', 'plan'],
      layout: Wrapper,
    },
    {
      path: '/malls/:mallId/accounts',
      component: ManageAccount,
      roles: [],
      guards: ['auth', 'mall'],
      layout: Wrapper,
    },
    {
      path: '/malls/:mallId/payment',
      component: ManagePayment,
      roles: [],
      guards: ['auth', 'mall', 'plan'],
      layout: Wrapper,
    },
    {
      path: '/malls/:mallId/create-campaign',
      component: CreateCampaign,
      roles: [],
      guards: ['auth', 'mall', 'plan'],
      layout: Wrapper,
    },
    {
      path: '/admin/create-target',
      component: CreateTarget,
      roles: [],
      guards: ['auth', 'manager'],
      layout: Wrapper,
    },
    {
      path: '/malls/:mallId/creative',
      component: Creative,
      roles: [],
      guards: ['auth', 'mall', 'plan'],
      layout: Wrapper,
    },
    {
      path: '/malls/:mallId/create-image',
      component: CreateImage,
      roles: [],
      guards: ['auth', 'mall', 'plan'],
      layout: Wrapper,
    },
    {
      path: '/malls/:mallId/plans/recommendation',
      component: PlanRecommendation,
      roles: [],
      guards: ['auth', 'mall'],
      layout: WrapperMall,
    },
    {
      path: '/malls/:mallId/plans/recommendation/:type',
      component: PlanConfirm,
      roles: [],
      guards: ['auth', 'mall'],
      layout: WrapperMall,
    },
    {
      path: '/malls/:mallId/plans/recommendation/:type/complete',
      component: PlanComplete,
      roles: [],
      guards: ['auth', 'mall'],
      layout: WrapperMall,
    },
    {
      path: '/malls/:mallId/plan',
      component: PlanChange,
      roles: [],
      guards: ['auth', 'mall'],
      layout: Wrapper,
    },
    {
      path: '/new-mall',
      component: NewMall,
      roles: [],
      guards: ['auth'],
      layout: Wrapper,
    },
    {
      path: '/callbacks/bigin-login',
      component: BiginLoginCallback,
      roles: [],
      guards: [],
    },
    {
      path: '/callbacks/bigin-crm',
      component: BiginCrmCallback,
      roles: [],
      guards: [],
    },
  ];

  useEffect(() => {
    // 1.처음 로그인 화면에서는 선택된 mall 객체가 없기 때문에 browser language 따라가도록 설정.
    if (StorageService.translationLanguage === null) {
      const browserLang = window.navigator.language.split('-')[0];
      StorageService.translationLanguage = browserLang;
    }
  }, [StorageService.translationLanguage]);

  useEffect(() => {
    // 프로젝트의 타임존에 따라 언어 설정.
    if (StorageService.selectedMall?.timezone) {
      const lang = multilingual.getLanguageByTimezone(StorageService.selectedMall.timezone);

      if (lang !== StorageService.translationLanguage) {
        StorageService.translationLanguage = lang;
      }
    }
  }, []);

  return (
    <React.Fragment>
      <TitleChange />
      <Routes>
        {routes.map((route) => {
          let renderRoute = (
            <Route
              key={route.path}
              path={route.path}
              element={
                route.layout ? (
                  <route.layout>
                    <route.component />
                  </route.layout>
                ) : (
                  <route.component />
                )
              }
            />
          );
          if (route.guards && route.guards.length > 0) {
            renderRoute = (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <PrivateRoute key={route.path} path={route.path} roles={route.roles} guards={route.guards}>
                    {route.layout ? (
                      <route.layout>
                        <route.component />
                      </route.layout>
                    ) : (
                      <route.component />
                    )}
                  </PrivateRoute>
                }
              />
            );
          }
          return renderRoute;
        })}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/find-password" element={<FindPassword />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/auth/invitation" element={<Invitation />} />
      </Routes>
      <GlobalToast />
      <GlobalDialog />
    </React.Fragment>
  );
};

export default RouteComponent;
