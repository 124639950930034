import React, { useEffect, useState, ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import { useQueryClient } from 'react-query';
import axios from 'axios';

import { BDSButton, BDSFontIcon } from '@bigin/bigin-ui-components';
import { baseCss } from './EditMyInfo.style';
import { BGButtonGroup } from '../../../../components/BGButtonGroup/BGButtonGroup';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { InputError } from '../../../../components/InputError/InputError';
import { UserApi } from '../../../../lib/api/UserApi';
import { useDataStore } from '../../../../context/Store';

interface IEditMyInfoForm {
  name: string;
  phone: string;
  currentPassword: string;
}

export interface EditMyInfoProps {
  name: string;
  phone: string;
  close: () => void;
}

export const ModalEditMyInfo = (props: EditMyInfoProps): ReactElement => {
  const { ...i18next } = useTranslation();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.name);
  const [phone, setPhone] = useState<string>(props.phone);
  const [currentPassword, setCurrentPassword] = useState<string>();
  const queryClient = useQueryClient();
  const { dialogStore } = useDataStore();

  const { register, formState, setValue, handleSubmit } = useForm<IEditMyInfoForm>({
    mode: 'all',
  });

  const MIN_PASSWORD = 6;
  const MAX_PHONE = 11;
  useEffect(() => {
    register('name', { value: name, required: i18next.t('필수 입력란입니다.') as string });
    register('phone', {
      value: phone,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isNumeric(value)) {
          return i18next.t('숫자만 입력해주세요.') as string;
        }
        if (!validator.isLength(value, { max: MAX_PHONE })) {
          return i18next.t('{{length}}자 이하로 입력해주세요', { length: MAX_PHONE }) as string;
        }

        return true;
      },
    });
    register('currentPassword', {
      value: currentPassword,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isLength(value, { min: MIN_PASSWORD })) {
          return i18next.t('{{length}}자 이상 입력해주세요', { length: MIN_PASSWORD }) as string;
        }

        return true;
      },
    });
  }, []);

  const onSubmit = (data: IEditMyInfoForm) => {
    return UserApi()
      .updateMe({
        name: data.name,
        phone: data.phone,
        current_password: data.currentPassword,
      })
      .then(() => {
        return queryClient.invalidateQueries('me').then(() => {
          props.close();
        });
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <div css={[baseCss]}>
      <div className="edit-pannel-wrapper">
        <div className="edit-title-wrapper">
          <div className="text-h1 text-color-main">{i18next.t('내 정보 수정하기')}</div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="edit-pannel-name">
            <div className="edit-name-box flex-col">
              <div className="info-title">{i18next.t('이름')}</div>
              <BGInput
                error={!!formState.errors.name}
                inputProps={{
                  placeholder: i18next.t('이름을 입력해주세요.'),
                  value: name,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('name', event.target.value, { shouldValidate: true });
                    setName(event.target.value);
                  },
                }}
              />
              {formState.errors.name && <InputError message={formState.errors.name.message as string} />}
            </div>
          </div>
          <div className="edit-pannel-phone">
            <div className="edit-phone-box flex-col">
              <div className="info-title">{i18next.t('전화번호')}</div>
              <BGInput
                error={!!formState.errors.phone}
                inputProps={{
                  placeholder: i18next.t('전화번호를 - 없이 입력하세요.'),
                  value: phone,
                  onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                    setValue('phone', event.target.value, { shouldValidate: true });
                    setPhone(event.target.value);
                  },
                }}
              />
              {formState.errors.phone && <InputError message={formState.errors.phone.message as string} />}
            </div>
          </div>
          <div className="edit-pannel-password">
            <div className="edit-password-box flex-col">
              <div className="info-title">{i18next.t('비밀번호')}</div>
              <div className="input-wrapper">
                <BGInput
                  error={!!formState.errors.currentPassword}
                  inputProps={{
                    type: isShowPassword ? 'text' : 'password',
                    placeholder: i18next.t('비밀번호를 입력해주세요.'),
                    value: currentPassword,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                      setValue('currentPassword', event.target.value, { shouldValidate: true });
                      setCurrentPassword(event.target.value);
                    },
                  }}
                  rightLabelComponent={
                    <BDSFontIcon
                      name={isShowPassword ? 'ic-eye' : 'ic-eye-off'}
                      className="icon-preview-wrapper"
                      size="16px"
                      handleClick={() => setIsShowPassword(!isShowPassword)}
                    />
                  }
                />
                {formState.errors.currentPassword && (
                  <InputError message={formState.errors.currentPassword.message as string} />
                )}
              </div>
            </div>
          </div>
          <BGButtonGroup rightAlign style={{ marginTop: '24px' }}>
            <BDSButton
              key={i18next.t('취소') as string}
              label={i18next.t('취소')}
              onClick={() => {
                props.close();
              }}
              appearance="secondary"
            />
            <BDSButton
              key={i18next.t('수정') as string}
              label={i18next.t('수정')}
              type="submit"
              isDisabled={!formState.isValid}
            />
          </BGButtonGroup>
        </form>
      </div>
    </div>
  );
};
