import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .google-login-button-wrapper {
      box-sizing: border-box;
      width: 100%;
      height: 36px;
      border-radius: 8px;
      border: solid 1px #dce0e8;
      background-color: #ffffff;
      margin-top: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .kakao-login-image {
        background: transparent url('assets/img/common/category/kakao.png') no-repeat center center;
        background-size: contain;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
      }

      &:hover {
        background-color: #f7f7fa;
      }
    }

    form {
      .info-title {
        ${theme.text.h4};
        color: ${theme.colors.mainFont};
        margin-bottom: 8px;

        &::placeholder {
          color: #737b86;
        }
      }

      .login-pannel-email {
        width: 100%;

        .login-email-box {
          align-items: flex-start;
          //margin-bottom: 8px;
        }
      }

      .login-pannel-password {
        width: 100%;
        margin-top: 16px;

        .login-password-box {
          position: relative;

          .password-box {
            width: 100%;
            margin-bottom: 8px;
          }
        }
      }

      .login-fail-alert {
        margin-top: 8px;
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        align-items: center;

        .az-icon {
          vertical-align: middle;
          width: 16px;
          height: 16px;
          font-size: 16px;
          color: #e65c5c;
          margin-right: 8px;
        }

        span {
          vertical-align: middle;
          ${theme.text.input};

          color: ${theme.colors.error};
        }
      }

      .login-button {
        position: relative;
        width: 100%;
        height: 48px;
        font-size: 14px;
        margin-top: 16px;
        cursor: pointer;
      }
    }

    .password-reset-button-wrapper {
      width: 100%;
      text-align: center;
      margin-top: 8px;
      display: flex;
      justify-content: flex-start;

      .password-reset-button {
        cursor: pointer;
        ${theme.text.p3};
        color: ${theme.colors.mainFont};
        text-decoration: underline;
      }
    }

    .horizontal-line {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: -20px;
      background-color: #ebeef4;
      width: 360px;
      height: 1px;
      border: 0px;
    }
  `;
};
