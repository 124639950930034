import { css, Theme } from '@emotion/react';

declare module '@emotion/react' {
  // eslint-disable-next-line no-shadow
  export interface Theme {
    colors: {
      primary: string;
      primaryHover: string;
      primaryPressed: string;

      secondary1: string;
      secondary2: string;
      secondary3: string;

      bg1: string;
      bg2: string;
      bg3: string;

      mainFont: string;
      subFont: string;
      smallFont: string;
      inputPlaceholderText: string;
      inputDisabledText: string;

      warning: string;
      success: string;
      error: string;

      icon: string;
      iconMiddle: string;
      divider: string;
      dim: string;
    };

    text: {
      h1: any;
      h2: any;
      h3: any;
      h4: any;
      h5: any;
      h6: any;
      p1: any;
      p2: any;
      p3: any;
      input: any;
      disclaimer: any;
    };
  }
}

export const lightTheme: Theme = {
  colors: {
    primary: '#006FFF',
    primaryHover: '#0059d9',
    primaryPressed: '#004ec0',

    secondary1: '#3399FF',
    secondary2: '#0A0F32',
    secondary3: '#CC9966',

    bg1: '#FFFFFF',
    bg2: '#F9FAFB',
    bg3: '#EDF1F6',

    mainFont: '#3D4046',
    subFont: '#53585F',
    smallFont: '#626871',
    inputPlaceholderText: '#737B86',
    inputDisabledText: '#99A0A9',

    warning: '#FFAB00',
    success: '#27C28A',
    error: '#E65C5C',

    icon: '#7E8696',
    iconMiddle: '#CAD4E7',
    divider: '#DCE0E8',
    dim: 'rgba(0, 0, 0, .6)',
  },
  text: {
    h1: css`
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    h2: css`
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    h3: css`
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    `,
    h4: css`
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    h5: css`
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
    `,
    h6: css`
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    p1: css`
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    p2: css`
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
    `,
    p3: css`
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
    `,
    input: css`
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
    `,
    disclaimer: css`
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #626871;
    `,
  },
};

export const darkTheme: Theme = {
  colors: {
    primary: '#006FFF',
    primaryHover: '#0059d9',
    primaryPressed: '#004ec0',

    secondary1: '#3399FF',
    secondary2: '#0A0F32',
    secondary3: '#CC9966',

    bg1: '#17181C',
    bg2: '#212127',
    bg3: '#2A2E37',

    mainFont: '#FFFFFF',
    subFont: '#B2C0CB',
    smallFont: '#626871',
    inputPlaceholderText: '#737B86',
    inputDisabledText: '#99A0A9',

    warning: '#FFAB00',
    success: '#27C28A',
    error: '#E65C5C',

    icon: '#FFFFFF',
    iconMiddle: '#DCE0E8',
    divider: '#424448',
    dim: 'rgba(0, 0, 0, .6)',
  },
  text: {
    h1: css`
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    h2: css`
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    h3: css`
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    `,
    h4: css`
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    h5: css`
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
    `,
    h6: css`
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    p1: css`
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
    `,
    p2: css`
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
    `,
    p3: css`
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
    `,
    input: css`
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
    `,
    disclaimer: css`
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: #626871;
    `,
  },
};

export const chartColors: string[] = [
  '#385dff',
  '#00abba',
  '#ffbf00',
  '#8f33ff',
  '#ff6e26',
  '#d6337f',
  '#a6a621',
  '#804646',

  '#5d7afc',
  '#30b9c5',
  '#fcc930',
  '#a259fc',
  '#fc884f',
  '#db5996',
  '#b5b54b',
  '#966868',

  '#8399fa',
  '#61c7d1',
  '#fad461',
  '#b780fa',
  '#faa378',
  '#e280ad',
  '#c4c475',
  '#ae8b8b',

  '#a7b6f7',
  '#91d5db',
  '#f7de91',
  '#caa5f7',
  '#f7bda0',
  '#e7a6c4',
  '#d3d39e',
  '#c4adad',

  '#cdd5f5',
  '#c2e4e7',
  '#f5e8c2',
  '#dfccf5',
  '#f5d8ca',
  '#edccdb',
  '#e3e3c9',
  '#dcd0d0',
];
