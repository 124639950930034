import { css } from '@emotion/react';
import { GS } from '../../../styles/size';

export const baseCss = () => {
  return css`
    width: 100%;
    min-width: fit-content;

    * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: ${GS.gutter}px;
    }

    .error {
      margin: 0 !important;
    }
  `;
};
