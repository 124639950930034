import React, { ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { dataFormatting } from '../../utils/dataFormatting.util';
import { FontIcon } from '../FontIcon/FontIcon';
import { BGTooltip } from '../BGTooltip/BGTooltip';
import { Metric } from '../../pages/CampaignDashboard/CampaignDashboardDef';
import { baseCss } from './MetricItem.style';
import { getInsightType, getUnitText } from '../../utils/insight';
import { filters } from '../../utils/filter';
import { CurrencyCodeRecoil } from '../../recoil/Currency';

// const getIconUrl = (prop: string): string => {
//   switch (prop) {
//     case 'purchase_conversion_rate':
//       return '/assets/img/eventType/img-visit.svg';
//     case 'avg_view_products':
//       return '/assets/img/eventType/img-views.svg';
//     case 'revenue_by_device':
//       return '/assets/img/eventType/img-campaign.svg';
//     case 'event_purchase_revenue':
//       return '/assets/img/eventType/img-budget.svg';
//     default:
//       return '/assets/img/eventType/img-visit.svg';
//   }
// };

export interface MetricItemProps {
  status: Metric;
  isLoading: boolean;
  lock?: boolean;
  onClick: (event: any) => void;
}
export const MetricItem = ({ status, isLoading, lock, onClick }: MetricItemProps): ReactElement => {
  const i18next = useTranslation();
  const { displayName, tooltip, format, count, compareCount } = status;

  const [isHover, setIsHover] = useState<boolean>(false);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);

  const showUpDown = (basicValue: number, compareValue: number, valueFormat: string) => {
    const diff = basicValue - compareValue;
    return (
      !!diff && (
        <div className={`compare-rate-wrapper text-p1 ${diff > 0 ? 'increase' : 'decrease'}`}>
          <div className="value-wrapper">
            {valueFormat === 'Percent' && `${dataFormatting('Percent', basicValue - compareValue)}%p`}
            {valueFormat === 'Currency' &&
              `${dataFormatting(
                'Percent',
                compareValue === 0 ? 100 : ((basicValue - compareValue) / compareValue) * 100,
              )}%`}
          </div>
          <div className="image-wrapper">
            {diff > 0 && <img src="/assets/img/automation/icons-ic-arrow-up.png" alt="arrow-up" />}
            {diff < 0 && <img src="/assets/img/automation/icons-ic-arrow-down.png" alt="arrow-down" />}
          </div>
        </div>
      )
    );
  };
  return (
    <React.Fragment>
      <div
        className={`status-wrapper ${lock ? 'lock' : ''} ${lock && isHover ? 'hover' : ''}`}
        css={[baseCss]}
        onFocus={(event) => {
          event.stopPropagation();
          setIsHover(true);
        }}
        onBlur={(event) => {
          event.stopPropagation();
          setIsHover(false);
        }}
        onMouseOver={(event) => {
          event.stopPropagation();
          setIsHover(true);
        }}
        onMouseOut={(event) => {
          event.stopPropagation();
          setIsHover(false);
        }}
        onClick={(event) => {
          onClick(event);
        }}
      >
        <div className="header-wrapper">
          <div className="name-wrapper">
            <div className="text-h5 text-color-main">{`${i18next.t(displayName)}`}</div>
            {tooltip && (
              <BGTooltip title={`${i18next.t(tooltip)}`}>
                <div className="tooltip-icon-wrapper">
                  <FontIcon name="ic-info" size="16px" color="#b5bcc9" />
                </div>
              </BGTooltip>
            )}
          </div>
          {lock && (
            <div className={`label-wrapper text-p3 text-color-bg1 ${isHover ? 'hover' : ''}`}>
              <img className="icon" src="/assets/img/common/icons-ic-lock-reverse.svg" alt="lock" />
              <span className={`label ${isHover ? 'hover' : ''}`}>
                <span>{i18next.t('Bigin SDK 설치 필요')}</span>
              </span>
            </div>
          )}
        </div>
        <SkeletonTheme baseColor="#edf1f6" highlightColor="#ffffff">
          <div className="count-wrapper">
            <div className="text-h3 text-color-main d-flex align-center">
              {isLoading || lock ? (
                <React.Fragment>
                  <Skeleton className="skeleton" width={77} enableAnimation={!lock} />
                  <Skeleton className="skeleton" width={64} style={{ marginLeft: '12px' }} enableAnimation={!lock} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {format === 'Currency' &&
                    (getInsightType(status.prop) === 'Currency'
                      ? filters.formatCurrency({ value: count, currencyCode })
                      : `${dataFormatting(format, count).split('.')[0]}${i18next.t(getUnitText(status.prop))}`)}
                  {format === 'Percent' && `${dataFormatting(format, count)}%`}
                  {showUpDown(count, compareCount, format)}
                </React.Fragment>
              )}
            </div>
          </div>
        </SkeletonTheme>
      </div>
    </React.Fragment>
  );
};
