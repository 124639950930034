import { css, useTheme, Theme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    position: sticky;
    top: 124px;
    z-index: 2;

    .campaign-summary-floating-section {
      width: 260px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: sticky;
      top: 124px;
      background: #f9fafb;

      .campaign-summary-area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding: 16px;
        box-sizing: border-box;

        .name-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          ${theme.text.h4};
          color: ${theme.colors.mainFont};
          margin-bottom: 8px;
        }

        .name {
          min-height: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .tooltip-icon-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 4px;
          cursor: default;
        }

        .button-wrapper {
          display: block;
          position: absolute;
          right: 20px;
        }

        .table-wrapper {
          width: 100%;

          .row {
            display: flex;
            justify-content: space-between;
            padding-bottom: 16px;

            &.short {
              padding-bottom: 8px;
            }

            &.border-bottom {
              border-bottom: 1px solid ${theme.colors.divider};
            }

            .item-title {
              ${theme.text.p3}
              font-weight: bold;
              color: ${theme.colors.mainFont};
            }
            .item-content {
              ${theme.text.disclaimer};
              text-align: right;
              color: ${theme.colors.mainFont};

              &.flex {
                display: flex;
                gap: 4px;
                align-items: center;
              }

              .arrow {
                cursor: pointer;
                display: inline-block;
              }
            }

            .emphasis {
              ${theme.text.h5};
            }

            &:last-child {
              padding-bottom: 4px;

              &.border-none {
                padding-bottom: 8px;
              }
            }
          }

          .row + .row {
            padding-top: 16px;
            border-top: 1px solid ${theme.colors.divider};

            &.border-none {
              border-top: none;
            }

            &.padding-top-unset {
              padding-top: unset !important;
            }

            &.padding-bottom-unset {
              padding-bottom: unset !important;
            }

            &.short {
              padding-top: 8px;
            }
          }
        }

        &.merchant-center {
          padding: 14px 16px;
          .table-wrapper {
            .row {
              align-items: center;
              padding-bottom: 0;
              .item-title {
                line-height: 24px;
              }
            }
          }
        }

        .campaign-type-wrapper {
          ${theme.text.disclaimer};
          color: ${theme.colors.mainFont};

          .campaign-type-item {
            display: flex;
            align-items: center;
            gap: 4px;
          }

          .icon-wrapper {
            display: flex;
            gap: 4px;
            .icon {
              width: 16px;
              height: 16px;
            }
          }
        }
      }

      .plan-title-panel {
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        border: solid 1px #dce0e8;
        width: 100%;
        background-color: #f9fafb;
        margin-top: 16px;

        &:hover {
          background-color: #f9fafb;
          border: solid 1px #39f;
          cursor: pointer;
        }

        .plan-title-left {
          display: flex;
          .plan-icon {
            width: 64px;
            height: 64px;
            margin-right: 8px;
            border-radius: 8px;
            background-color: #f9fafb;
          }
          .plan-title-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .card-no-exist {
              display: flex;
              align-self: center;
              font-size: 12px;
              color: #3d4046;
              padding: 8px;
              white-space: nowrap;
              flex-direction: column;

              .title-wrapper {
                display: flex;
                align-self: center;
                flex-direction: row;
                align-items: center;
              }

              .font-icon-panel {
                margin-right: 4px;
              }

              .secondary-button {
                width: 100%;
                margin-top: 4px;
                padding: 0;
                border: 0;
                background: none;
                cursor: pointer;
                ${theme.text.disclaimer};
              }
            }
          }
        }
        /* .plan-title-right {
        display: flex;
        .font-icon-panel {
          margin: auto 16px;
        }
      } */
      }

      .checkbox-wrapper {
        align-items: center;
        margin-top: 12px;
        gap: 4px;
        display: flex;
        align-items: flex-start;

        cursor: pointer;

        .checkbox-label {
          margin-left: 4px;
          ${theme.text.input};
          color: ${theme.colors.mainFont};
        }
      }
    }

    .campaign-summary-floating-section {
      &.fold {
        width: 0px;
        position: fixed;
        right: -4px;
        bottom: 0;
        background-color: transparent;

        .campaign-summary-border-section {
          opacity: 0;
        }

        .campaign-summary-area {
          display: none;
          position: relative;
          z-index: 100;
          white-space: nowrap;
        }
        .double-arrow-btn-wrapper {
          width: fit-content;
          display: flex;
          justify-content: flex-end;
          position: absolute;
          bottom: 20px;
          right: 20px;
        }

        &:hover {
          width: 260px;
          transition: width 0.3s cubic-bezier(0.33, 1, 0.68, 1);
          right: 0px;

          .campaign-summary-border-section {
            opacity: 1;
          }

          .campaign-summary-area {
            display: flex;
          }
        }
      }
    }
  `;
};
