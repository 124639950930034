export type CurrenyCode =
  | 'AFN'
  | 'EUR'
  | 'ALL'
  | 'DZD'
  | 'USD'
  | 'EUR'
  | 'AOA'
  | 'XCD'
  | 'XCD'
  | 'ARS'
  | 'AMD'
  | 'AWG'
  | 'AUD'
  | 'EUR'
  | 'AZN'
  | 'BSD'
  | 'BHD'
  | 'BDT'
  | 'BBD'
  | 'BYR'
  | 'EUR'
  | 'BZD'
  | 'XOF'
  | 'BMD'
  | 'BTN'
  | 'INR'
  | 'BOB'
  | 'BOV'
  | 'USD'
  | 'BAM'
  | 'BWP'
  | 'NOK'
  | 'BRL'
  | 'USD'
  | 'BND'
  | 'BGN'
  | 'XOF'
  | 'BIF'
  | 'KHR'
  | 'XAF'
  | 'CAD'
  | 'CVE'
  | 'KYD'
  | 'XAF'
  | 'XAF'
  | 'CLF'
  | 'CLP'
  | 'CNY'
  | 'AUD'
  | 'AUD'
  | 'COP'
  | 'COU'
  | 'KMF'
  | 'XAF'
  | 'CDF'
  | 'NZD'
  | 'CRC'
  | 'XOF'
  | 'HRK'
  | 'CUC'
  | 'CUP'
  | 'ANG'
  | 'EUR'
  | 'CZK'
  | 'DKK'
  | 'DJF'
  | 'XCD'
  | 'DOP'
  | 'USD'
  | 'EGP'
  | 'SVC'
  | 'USD'
  | 'XAF'
  | 'ERN'
  | 'EUR'
  | 'ETB'
  | 'EUR'
  | 'FKP'
  | 'DKK'
  | 'FJD'
  | 'EUR'
  | 'EUR'
  | 'EUR'
  | 'XPF'
  | 'EUR'
  | 'XAF'
  | 'GMD'
  | 'GEL'
  | 'EUR'
  | 'GHS'
  | 'GIP'
  | 'EUR'
  | 'DKK'
  | 'XCD'
  | 'EUR'
  | 'USD'
  | 'GTQ'
  | 'GBP'
  | 'GNF'
  | 'XOF'
  | 'GYD'
  | 'HTG'
  | 'USD'
  | 'AUD'
  | 'EUR'
  | 'HNL'
  | 'HKD'
  | 'HUF'
  | 'ISK'
  | 'INR'
  | 'IDR'
  | 'XDR'
  | 'IRR'
  | 'IQD'
  | 'EUR'
  | 'GBP'
  | 'ILS'
  | 'EUR'
  | 'JMD'
  | 'JPY'
  | 'GBP'
  | 'JOD'
  | 'KZT'
  | 'KES'
  | 'AUD'
  | 'KPW'
  | 'KRW'
  | 'KWD'
  | 'KGS'
  | 'LAK'
  | 'EUR'
  | 'LBP'
  | 'LSL'
  | 'ZAR'
  | 'LRD'
  | 'LYD'
  | 'CHF'
  | 'EUR'
  | 'EUR'
  | 'MOP'
  | 'MKD'
  | 'MGA'
  | 'MWK'
  | 'MYR'
  | 'MVR'
  | 'XOF'
  | 'EUR'
  | 'USD'
  | 'EUR'
  | 'MRO'
  | 'MUR'
  | 'EUR'
  | 'XUA'
  | 'MXN'
  | 'MXV'
  | 'USD'
  | 'MDL'
  | 'EUR'
  | 'MNT'
  | 'EUR'
  | 'XCD'
  | 'MAD'
  | 'MZN'
  | 'MMK'
  | 'NAD'
  | 'ZAR'
  | 'AUD'
  | 'NPR'
  | 'EUR'
  | 'XPF'
  | 'NZD'
  | 'NIO'
  | 'XOF'
  | 'NGN'
  | 'NZD'
  | 'AUD'
  | 'USD'
  | 'NOK'
  | 'OMR'
  | 'PKR'
  | 'USD'
  | 'PAB'
  | 'USD'
  | 'PGK'
  | 'PYG'
  | 'PEN'
  | 'PHP'
  | 'NZD'
  | 'PLN'
  | 'EUR'
  | 'USD'
  | 'QAR'
  | 'EUR'
  | 'RON'
  | 'RUB'
  | 'RWF'
  | 'EUR'
  | 'SHP'
  | 'XCD'
  | 'XCD'
  | 'EUR'
  | 'EUR'
  | 'XCD'
  | 'WST'
  | 'EUR'
  | 'STD'
  | 'SAR'
  | 'XOF'
  | 'RSD'
  | 'SCR'
  | 'SLL'
  | 'SGD'
  | 'ANG'
  | 'XSU'
  | 'EUR'
  | 'EUR'
  | 'SBD'
  | 'SOS'
  | 'ZAR'
  | 'SSP'
  | 'EUR'
  | 'LKR'
  | 'SDG'
  | 'SRD'
  | 'NOK'
  | 'SZL'
  | 'SEK'
  | 'CHE'
  | 'CHF'
  | 'CHW'
  | 'SYP'
  | 'TWD'
  | 'TJS'
  | 'TZS'
  | 'THB'
  | 'USD'
  | 'XOF'
  | 'NZD'
  | 'TOP'
  | 'TTD'
  | 'TND'
  | 'TRY'
  | 'TMT'
  | 'USD'
  | 'AUD'
  | 'UGX'
  | 'UAH'
  | 'AED'
  | 'GBP'
  | 'USD'
  | 'USN'
  | 'USD'
  | 'UYI'
  | 'UYU'
  | 'UZS'
  | 'VUV'
  | 'VEF'
  | 'VND'
  | 'USD'
  | 'USD'
  | 'XPF'
  | 'MAD'
  | 'YER'
  | 'ZMW'
  | 'ZWL'
  | 'XBA'
  | 'XBB'
  | 'XBC'
  | 'XBD'
  | 'XTS'
  | 'XXX'
  | 'XAU'
  | 'XPD'
  | 'XPT'
  | 'XAG';

export type LangugaeCode =
  | 'af'
  | 'af-ZA'
  | 'ar'
  | 'ar-AE'
  | 'ar-BH'
  | 'ar-DZ'
  | 'ar-EG'
  | 'ar-IQ'
  | 'ar-JO'
  | 'ar-KW'
  | 'ar-LB'
  | 'ar-LY'
  | 'ar-MA'
  | 'ar-OM'
  | 'ar-QA'
  | 'ar-SA'
  | 'ar-SY'
  | 'ar-TN'
  | 'ar-YE'
  | 'az'
  | 'az-AZ'
  | 'az-AZ'
  | 'be'
  | 'be-BY'
  | 'bg'
  | 'bg-BG'
  | 'bs-BA'
  | 'ca'
  | 'ca-ES'
  | 'cs'
  | 'cs-CZ'
  | 'cy'
  | 'cy-GB'
  | 'da'
  | 'da-DK'
  | 'de'
  | 'de-AT'
  | 'de-CH'
  | 'de-DE'
  | 'de-LI'
  | 'de-LU'
  | 'dv'
  | 'dv-MV'
  | 'el'
  | 'el-GR'
  | 'en'
  | 'en-AU'
  | 'en-BZ'
  | 'en-CA'
  | 'en-CB'
  | 'en-GB'
  | 'en-IE'
  | 'en-JM'
  | 'en-NZ'
  | 'en-PH'
  | 'en-TT'
  | 'en-US'
  | 'en-ZA'
  | 'en-ZW'
  | 'eo'
  | 'es'
  | 'es-AR'
  | 'es-BO'
  | 'es-CL'
  | 'es-CO'
  | 'es-CR'
  | 'es-DO'
  | 'es-EC'
  | 'es-ES'
  | 'es-ES'
  | 'es-GT'
  | 'es-HN'
  | 'es-MX'
  | 'es-NI'
  | 'es-PA'
  | 'es-PE'
  | 'es-PR'
  | 'es-PY'
  | 'es-SV'
  | 'es-UY'
  | 'es-VE'
  | 'et'
  | 'et-EE'
  | 'eu'
  | 'eu-ES'
  | 'fa'
  | 'fa-IR'
  | 'fi'
  | 'fi-FI'
  | 'fo'
  | 'fo-FO'
  | 'fr'
  | 'fr-BE'
  | 'fr-CA'
  | 'fr-CH'
  | 'fr-FR'
  | 'fr-LU'
  | 'fr-MC'
  | 'gl'
  | 'gl-ES'
  | 'gu'
  | 'gu-IN'
  | 'he'
  | 'he-IL'
  | 'hi'
  | 'hi-IN'
  | 'hr'
  | 'hr-BA'
  | 'hr-HR'
  | 'hu'
  | 'hu-HU'
  | 'hy'
  | 'hy-AM'
  | 'id'
  | 'id-ID'
  | 'is'
  | 'is-IS'
  | 'it'
  | 'it-CH'
  | 'it-IT'
  | 'ja'
  | 'ja-JP'
  | 'ka'
  | 'ka-GE'
  | 'kk'
  | 'kk-KZ'
  | 'kn'
  | 'kn-IN'
  | 'ko'
  | 'ko-KR'
  | 'kok'
  | 'kok-IN'
  | 'ky'
  | 'ky-KG'
  | 'lt'
  | 'lt-LT'
  | 'lv'
  | 'lv-LV'
  | 'mi'
  | 'mi-NZ'
  | 'mk'
  | 'mk-MK'
  | 'mn'
  | 'mn-MN'
  | 'mr'
  | 'mr-IN'
  | 'ms'
  | 'ms-BN'
  | 'ms-MY'
  | 'mt'
  | 'mt-MT'
  | 'nb'
  | 'nb-NO'
  | 'nl'
  | 'nl-BE'
  | 'nl-NL'
  | 'nn-NO'
  | 'ns'
  | 'ns-ZA'
  | 'pa'
  | 'pa-IN'
  | 'pl'
  | 'pl-PL'
  | 'ps'
  | 'ps-AR'
  | 'pt'
  | 'pt-BR'
  | 'pt-PT'
  | 'qu'
  | 'qu-BO'
  | 'qu-EC'
  | 'qu-PE'
  | 'ro'
  | 'ro-RO'
  | 'ru'
  | 'ru-RU'
  | 'sa'
  | 'sa-IN'
  | 'se'
  | 'se-FI'
  | 'se-FI'
  | 'se-FI'
  | 'se-NO'
  | 'se-NO'
  | 'se-NO'
  | 'se-SE'
  | 'se-SE'
  | 'se-SE'
  | 'sk'
  | 'sk-SK'
  | 'sl'
  | 'sl-SI'
  | 'sq'
  | 'sq-AL'
  | 'sr-BA'
  | 'sr-BA'
  | 'sr-SP'
  | 'sr-SP'
  | 'sv'
  | 'sv-FI'
  | 'sv-SE'
  | 'sw'
  | 'sw-KE'
  | 'syr'
  | 'syr-SY'
  | 'ta'
  | 'ta-IN'
  | 'te'
  | 'te-IN'
  | 'th'
  | 'th-TH'
  | 'tl'
  | 'tl-PH'
  | 'tn'
  | 'tn-ZA'
  | 'tr'
  | 'tr-TR'
  | 'tt'
  | 'tt-RU'
  | 'ts'
  | 'uk'
  | 'uk-UA'
  | 'ur'
  | 'ur-PK'
  | 'uz'
  | 'uz-UZ'
  | 'uz-UZ'
  | 'vi'
  | 'vi-VN'
  | 'xh'
  | 'xh-ZA'
  | 'zh'
  | 'zh-CN'
  | 'zh-HK'
  | 'zh-MO'
  | 'zh-SG'
  | 'zh-TW'
  | 'zu'
  | 'zu-ZA';

export interface ICurrency {
  value: number;
  currencyCode: CurrenyCode;
  withoutUnit?: boolean;
}

export const getCurrencyCodeToLanguageCode = (currencyCode: CurrenyCode): LangugaeCode => {
  const currencylanguageCodeObj = {
    KRW: 'ko-KR',
    USD: 'en-USD',
    SGD: 'en-SG',
  };

  return currencylanguageCodeObj[currencyCode];
};
