import { css } from '@emotion/react';

export const BGLegendStyle = css`
  .legend-wrapper {
    height: 100%;
    width: 200px;
    min-width: 200px;

    .search-input-wrapper {
      width: 100%;
    }

    .legend-box-wrapper {
      margin: 8px 0;
      border-radius: 8px;
      border: solid 1px #dce0e8;
      font-size: 14px;
      line-height: 1.43;
      color: #53585f;
      overflow: hidden;

      .legend-title-wrapper {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        padding: 8px;
        cursor: pointer;
        flex-wrap: wrap;

        .left-wrapper {
          flex-wrap: wrap;
          display: flex;
          align-items: center;

          .name-wrapper {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 108px;
            overflow: hidden;
            margin: 4px;
          }

          .count-wrapper {
            font-size: 10px;
            color: #2196f3;
          }
        }

        .group-open-icon {
          display: flex;
          align-items: center;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .legend-item:hover {
        background-color: #ededf2;
      }

      .legend-item {
        padding: 8px;
        display: flex;
        background-color: #fff;
        align-items: center;
        justify-content: flex-start;

        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }

        .check-icon {
          cursor: pointer;
          margin-right: 8px;
          font-size: 16px;
          flex-shrink: 0;
        }

        &.disabled {
          color: #bbbbcc;

          .check-icon {
            color: #bbbbcc;
          }
        }

        &.blocekd {
          .check-icon {
            display: none;
          }

          cursor: default;
        }
      }
    }
  }

  .simple-legend-wrapper {
    width: 100%;
    height: 12.125rem;

    .legend-item-wrapper {
      .legend-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 2rem;
        margin-bottom: 0.25rem;

        .left-wrapper {
          display: flex;
          align-items: center;

          .legend-name-wrapper {
            font-size: 14px;
            line-height: 1.43;
            color: #445;
          }
        }

        .legend-value-wrapper {
          font-size: 14px;
          line-height: 1.43;
          text-align: right;
          color: #445;
        }

        .color-icon {
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
          margin-right: 0.5rem;
        }
      }
    }
  }
`;
