import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
// import { BDSSelectBox } from 'bigin-ui-components';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { useForm } from 'react-hook-form';

import { BDSSelectBox } from '@bigin/bigin-ui-components';
import { baseCss } from './Step3.style';
import { InputError } from '../../../components/InputError/InputError';
import { MonthlyBudget } from '../../../enums/MonthlyBudget';

export interface Step3State {
  mallClassificationId: number | null;
  monthlyBudget: string | null;
  isValid: boolean;
}

export interface Step3Props {
  isLoading: boolean;
  mallClassifications: MallClassification[];
  handleUpdate: (state: Step3State) => void;
  mallClassificationId: number | null;
  monthlyBudget: string | null;
}

interface Step3Form {
  mallClassificationId: number | null;
  monthlyBudget: string | null;
}

export const Step3 = (props: Step3Props) => {
  const i18next = useTranslation();

  const [parentMallClassificationId, setParentMallClassificationId] = useState<number | null>(
    props.mallClassifications.find((item) => item.children.find((child) => child.id === props.mallClassificationId))
      ?.id || null,
  );
  const [mallClassificationId, setMallClassificationId] = useState<number | null>(props.mallClassificationId);
  const [monthlyBudget, setMonthlyBudget] = useState<string | null>(props.monthlyBudget);

  const childMallClassifications = useMemo(() => {
    const parent = props.mallClassifications.find((item) => item.id === parentMallClassificationId);

    return parent ? parent.children : [];
  }, [parentMallClassificationId]);

  const { formState, register, setValue } = useForm<Step3Form>({ mode: 'onChange' });
  useEffect(() => {
    register('mallClassificationId', {
      value: mallClassificationId,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('monthlyBudget', { value: monthlyBudget, required: i18next.t('필수 입력란입니다.') as string });
  }, []);
  useEffect(() => {
    if (props.handleUpdate) {
      props.handleUpdate({ mallClassificationId, monthlyBudget, isValid: formState.isValid });
    }
  }, [formState, formState.isValid]);

  return (
    <div css={[baseCss]}>
      <div className="form-input">
        <div className="label">{i18next.t('업종 대카테고리')}</div>
        {props.isLoading ? (
          <Skeleton style={{ height: '36px' }} />
        ) : (
          <BDSSelectBox
            appearance="gray"
            defaultValue={parentMallClassificationId}
            displayValue={(item: any) => i18next.t(item.label)}
            handleUpdate={(item: any) => {
              setParentMallClassificationId(item.value);

              setValue('mallClassificationId', null, { shouldValidate: true });
              setMallClassificationId(null);
            }}
            isSelected={(item: any) => item.value === parentMallClassificationId}
            list={[
              { value: null, label: i18next.t('업종 대카테고리 선택') },
              ...props.mallClassifications.map((item) => ({ value: item.id, label: item.name })),
            ]}
          />
        )}
      </div>

      <div className="form-input">
        <div className="label">{i18next.t('업종 소카테고리')}</div>
        {props.isLoading ? (
          <Skeleton style={{ height: '36px' }} />
        ) : (
          <BDSSelectBox
            error={!!formState.errors.mallClassificationId}
            appearance="gray"
            defaultValue={mallClassificationId}
            displayValue={(item: any) => i18next.t(item.label)}
            handleUpdate={(item: any) => {
              setValue('mallClassificationId', item.value, { shouldValidate: true });
              setMallClassificationId(item.value);
            }}
            isSelected={(item: any) => item.value === mallClassificationId}
            list={[
              { value: null, label: i18next.t('업종 소카테고리 선택') },
              ...childMallClassifications.map((item) => ({ value: item.id, label: item.name })),
            ]}
          />
        )}
        {formState.errors.mallClassificationId && (
          <InputError message={formState.errors.mallClassificationId.message as string} />
        )}
      </div>

      <div className="form-input">
        <div className="label">{i18next.t('월 광고 예산')}</div>
        {props.isLoading ? (
          <Skeleton style={{ height: '36px' }} />
        ) : (
          <BDSSelectBox
            error={!!formState.errors.monthlyBudget}
            appearance="gray"
            defaultValue={monthlyBudget}
            displayValue={(item: any) => item.label}
            handleUpdate={(item: any) => {
              setValue('monthlyBudget', item.value, { shouldValidate: true });
              setMonthlyBudget(item.value);
            }}
            isSelected={(item: any) => item.value === monthlyBudget}
            list={[{ value: null, label: i18next.t('월 광고 예산 선택') }, ...MonthlyBudget.toSelects(i18next)]}
          />
        )}
        {formState.errors.monthlyBudget && <InputError message={formState.errors.monthlyBudget.message as string} />}
      </div>
    </div>
  );
};
