import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .link-management-container-area {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 12px 12px 12px;
      box-sizing: border-box;

      .left-wrapper {
        display: flex;
        align-items: center;

        .image-wrapper {
          width: 48px;
          height: 48px;
          margin-right: 12px;
          border-radius: 4px;
          background-color: #edf1f6;

          img {
            width: 24px;
            height: 24px;
            padding: 12px;
          }
        }
        .info-wrapper {
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .title-wrapper {
          display: flex;
          align-items: center;
          gap: 4px;
          ${theme.text.h4};
          color: ${theme.colors.mainFont};
        }
        .desc-wrapper {
          ${theme.text.p3};
          color: ${theme.colors.mainFont};
        }
      }
    }
  `;
};
