import React, { ReactElement, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { SkeletonTheme } from 'react-loading-skeleton';
import { baseCss } from './Creative.style';
import { TopNavBar } from '../../components/TopNavBar/TopNavBar';
import { BorderSection } from '../../components/BorderSection/BorderSection';
import { ImageManageView } from './ImageMangeView/ImageManageView';
import { VideoManageView } from './VideoManageView/VideoManageView';
import { BGTab } from '../../components/BGTab/BGTab';
import { BGPage } from '../../components/BGPage/BGPage';

export const Creative = (): ReactElement => {
  const i18next = useTranslation();

  const [selectedTab, setSelectedTab] = useState<{ key: string; label: string }>({
    key: 'images',
    label: i18next.t('이미지'),
  });

  return (
    <SkeletonTheme baseColor="#edf1f6" highlightColor="#ffffff">
      <BGPage>
        <TopNavBar style={{ border: 0 }} title={i18next.t('크리에이티브')} />
        <BGTab
          fixed
          tabList={[
            {
              key: 'images',
              label: i18next.t('이미지'),
            },
            {
              key: 'videos',
              label: i18next.t('동영상'),
            },
          ]}
          selectedTab={selectedTab}
          handleTab={(tab) => {
            setSelectedTab(tab);
          }}
        />
        <div className="creative-container" css={baseCss}>
          <BorderSection style={{ width: '100%', overflow: 'auto', borderRadius: '8px' }}>
            {selectedTab.key === 'images' && (
              <ImageManageView type={{ type: 'creative', value: '', ratio: '1:1' }} selectType="detail" />
            )}
            {selectedTab.key === 'videos' && <VideoManageView selectType="detail" />}
          </BorderSection>
        </div>
      </BGPage>
    </SkeletonTheme>
  );
};
