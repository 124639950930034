import { css, Theme, useTheme } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();
  return css`
    width: 100%;
    padding: ${GS.gutter}px;
    border-bottom: 1px solid ${theme.colors.divider};
    display: flex;
    .title-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;

      .icon-wrap {
        display: flex;
        gap: 8px;

        .icon {
          width: 20px;
          height: 20px;
        }
      }
      .title {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.56;
        color: ${theme.colors.mainFont};
        width: 100%;
      }
    }
  `;
};
