import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { baseCss } from './ImageGuide.style';

interface ImageGuideProps {
  id: string;
}

export const ImageGuide = (props: ImageGuideProps): ReactElement => {
  const i18next = useTranslation();
  const guide = () => {
    switch (props.id) {
      case '624a996dc52cee0d03001df7':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '66px',
                height: '17px',
                top: '5px',
                left: '3px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('로고')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '224px',
                height: '224px',
                top: '0px',
                left: '0px',
              }}
            >
              <div className="guide-title" style={{ height: '20px', right: '-1px', left: 'unset' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '140px',
                height: '17px',
                top: '175px',
                left: '35px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '113px',
                height: '20px',
                top: '193px',
                left: '53px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '186px',
                height: '20px',
                top: '155px',
                left: '19px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('광고 문구')}
              </div>
            </div>
          </React.Fragment>
        );
      case '624a99b449cbdf765724b351':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '67px',
                height: '17px',
                top: '23px',
                left: '26px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('로고')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '104px',
                height: '224px',
                top: '0px',
                left: '120px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '40px',
                top: '57px',
                left: '12px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '20px',
                top: '104px',
                left: '12px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '97px',
                height: '48px',
                top: '141px',
                left: '12px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('광고 문구')}
              </div>
            </div>
          </React.Fragment>
        );
      case '624a9a5c49cbdf765724b353':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '60px',
                height: '17px',
                top: '2px',
                left: '80px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('로고')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '142px',
                height: '133px',
                top: '20px',
                left: '41px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '144px',
                height: '17px',
                top: '155px',
                left: '39px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '144px',
                height: '17px',
                top: '172px',
                left: '39px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '144px',
                height: '17px',
                top: '195px',
                left: '39px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('광고 문구')}
              </div>
            </div>
          </React.Fragment>
        );
      case '624a9abd49cbdf765724b355':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '58px',
                height: '17px',
                top: '4px',
                left: '5px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('로고')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '50px',
                height: '20px',
                top: '9px',
                left: '75px',
                border: 'none',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '130px',
                height: '17px',
                top: '155px',
                left: '16px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '130px',
                height: '20px',
                top: '172px',
                left: '16px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '130px',
                height: '17px',
                top: '194px',
                left: '16px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('광고 문구')}
              </div>
            </div>
          </React.Fragment>
        );
      case '624a9b40c52cee0d03001df9':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '60px',
                height: '17px',
                top: '4px',
                left: '3px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('로고')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '224px',
                height: '224px',
                top: '1px',
                right: '0px',
                border: 'none',
              }}
            >
              <div className="guide-title" style={{ height: '20px', right: '0px', left: 'unset' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '118px',
                height: '17px',
                top: '177px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '118px',
                height: '20px',
                top: '195px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '52px',
                height: '50px',
                top: '146px',
                right: '15px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px', width: '104%' }}>
                {i18next.t('광고 문구')}
              </div>
            </div>
          </React.Fragment>
        );
      case '624a9b8dc52cee0d03001dfb':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '70px',
                height: '17px',
                top: '25px',
                left: '118px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('로고')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '108px',
                height: '224px',
                top: '0px',
                left: '0px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '90px',
                height: '40px',
                top: '113px',
                left: '118px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '90px',
                height: '25px',
                top: '157px',
                left: '118px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '90px',
                height: '53px',
                top: '50px',
                left: '118px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('광고 문구')}
              </div>
            </div>
          </React.Fragment>
        );
      case '624a9ca3c52cee0d03001dfd':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '50px',
                height: '17px',
                top: '3px',
                left: '22px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('로고')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '113px',
                height: '113px',
                top: '0px',
                left: '112px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '34px',
                top: '21px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '20px',
                top: '57px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '17px',
                top: '79px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('광고 문구')}
              </div>
            </div>
          </React.Fragment>
        );
      case '624a9ce0c52cee0d03001dfe':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '50px',
                height: '17px',
                top: '7px',
                left: '115px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('로고')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '95px',
                height: '95px',
                top: '8px',
                left: '12px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '30px',
                top: '40px',
                left: '115px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '17px',
                top: '70px',
                left: '115px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '17px',
                top: '23px',
                left: '115px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('광고 문구')}
              </div>
            </div>
          </React.Fragment>
        );

      case '624a9d5a49cbdf765724b359':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '50px',
                height: '17px',
                top: '10px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('로고')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '100px',
                height: '100px',
                top: '8px',
                left: '109px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '34px',
                top: '29px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '20px',
                top: '66px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '17px',
                top: '87px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('광고 문구')}
              </div>
            </div>
          </React.Fragment>
        );
      case '624a9d89c52cee0d03001e01':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '50px',
                height: '17px',
                top: '2px',
                right: '3px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('로고')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '117px',
                height: '117px',
                top: '0px',
                left: '0px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '96px',
                height: '31px',
                top: '25px',
                right: '3px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '90px',
                height: '20px',
                top: '58px',
                right: '3px',
              }}
            >
              <div className="guide-title" style={{ height: '20px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '97px',
                height: '17px',
                top: '80px',
                right: '3px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('광고 문구')}
              </div>
            </div>
          </React.Fragment>
        );
      case '624a9e0149cbdf765724b35a':
        return (
          <React.Fragment>
            <div
              className="guide"
              style={{
                width: '54px',
                height: '33px',
                top: '8px',
                right: '10px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('이미지')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '122px',
                height: '17px',
                top: '8px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('상품명')}
              </div>
            </div>
            <div
              className="guide"
              style={{
                width: '122px',
                height: '17px',
                top: '24px',
                left: '7px',
              }}
            >
              <div className="guide-title" style={{ height: '17px', fontSize: '11px' }}>
                {i18next.t('할인가')}
              </div>
            </div>
          </React.Fragment>
        );
      default:
        return <React.Fragment />;
    }
  };
  return <div css={[baseCss]}>{guide()}</div>;
};
