import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSButton, BDSFontIcon, BDSSelectBox, BDSTextArea } from '@bigin/bigin-ui-components';
import { useRecoilValue } from 'recoil';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import _ from 'lodash';
import { BGUrlSelector } from '../../../../components/BGUrlSelector/BGUrlSelector';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss, smallButtonCss } from './GoogleResponsiveImage.style';
import CampaignSetting from '../../../../recoil/CampaignSetting';
import { MaterialCheckboxSelector } from '../../MaterialCheckboxSelector/MaterialCheckboxSelector';
import { PreviewGoogleItem } from '../PreviewGoogleItem/PreviewGoogleItem';
import { ModalImageSelect } from '../../../Creative/ModalImageSelect/ModalImageSelect';
import { InputError } from '../../../../components/InputError/InputError';
import { filters } from '../../../../utils/filter';
import { AW_MAX_BYTE, AW_SHORT_TITLE_MAX_BYTE, googleAdRegexs } from '../../../../utils/utils';
import { BGInput } from '../../../../components/BGInput/BGInput';
import { GoogleField, googleCTA } from '../../../../constants/campaigns';
import { BGTagInput } from '../../../../components/BGTagInput/BGTagInput';
import { AssetHeader } from '../../../../components/AssetHeader/AssetHeader';

export interface GoogleResponsiveImageProps {
  setting: CampaignAdSettingState;
  handleUpdate: (setting: CampaignAdSettingState) => void;
  handleValid?: (type: CampaignType, isValid: boolean) => void;
  removeSelectedCampaignTypes: (type: CampaignType) => void;
}

interface GoogleResponsiveImageForm {
  aw_long_title: string;
  aw_short_title: string;
  aw_description: string;
  url: string;
  aw_image_id: number | undefined | null;
  aw_landscape_image_id: number | undefined | null;
}

export const GoogleResponsiveImage = (props: GoogleResponsiveImageProps): ReactElement => {
  const i18next = useTranslation();

  const [selectedCTA, setSelectedCTA] = useState<GoogleCTA>(
    props.setting?.aw_call_to_action ? props.setting?.aw_call_to_action : 'shop_now',
  );
  const [name, setName] = useState(props.setting?.name);
  const [url, setUrl] = useState(props.setting?.url);
  const [valueLock, setValueLock] = useState<boolean>(false);

  const [awDescription, setAwDescription] = useState(props.setting?.aw_description);
  const [awLongTitle, setAwLongTitle] = useState(props.setting?.aw_long_title);
  const [awShortTitle, setAwShortTitle] = useState(props.setting?.aw_short_title);
  const [currentImageRatioType, setCurrentImageRatioType] = useState<ImageRatioType>('1:1');
  const [showModalImageSelect, setShowModalImageSelect] = useState<boolean>(false);
  const [biginKeywords, setBiginKeywords] = useState<string[]>(props.setting?.bigin_keywords);

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);

  const { register, formState, setValue } = useForm<GoogleResponsiveImageForm>({
    mode: 'onChange',
  });

  const [currentErrorField, setCurrentErrorField] = useState<GoogleField | null>(null);
  const googleRegexCheck = (field: GoogleField, value: string) => {
    let googleRegex = false;
    googleAdRegexs().map((regex) => {
      if (new RegExp(regex, 'gi').test(value)) googleRegex = true;
      return true;
    });
    if (googleRegex) setCurrentErrorField(field);
    else setCurrentErrorField(null);

    const regexFreeValue = filters.googleRegexRemove(value);
    switch (field) {
      case GoogleField.AW_LONG_TITLE:
        setAwLongTitle(regexFreeValue);
        break;
      case GoogleField.AW_SHORT_TITLE:
        setAwShortTitle(regexFreeValue);
        break;
      case GoogleField.AW_DESCRIPTION:
        setAwDescription(regexFreeValue);
        break;
      default:
        break;
    }
    setValue(field as 'aw_long_title' | 'aw_short_title' | 'aw_description', regexFreeValue, {
      shouldValidate: true,
    });
  };

  const getCurrentImage = (type: ImageRatioType) => {
    if (type === '1:1') {
      return (props.setting.mall_image || props.setting.product_image || []) as ImageTypes[];
    }
    return (props.setting.aw_landscape_mall_image || props.setting.aw_landscape_product_image || []) as ImageTypes[];
  };
  const selectedImages = useMemo(() => getCurrentImage(currentImageRatioType), [currentImageRatioType]);

  const updateName = (curName: string) => {
    props.handleUpdate({ ...props?.setting, name: curName });
  };

  const updateAwLongTitle = (curAwLongTitle: string) => {
    props.handleUpdate({ ...props?.setting, aw_long_title: curAwLongTitle });
  };

  const updateAwShortTitle = (curAwShortTitle: string) => {
    props.handleUpdate({ ...props?.setting, aw_short_title: curAwShortTitle });
  };

  const updateAwDescription = (curAwDescription: string) => {
    props.handleUpdate({ ...props?.setting, aw_description: curAwDescription });
  };

  const updateUrl = (curUrl: string) => {
    props.handleUpdate({ ...props?.setting, url: curUrl });
  };

  const updateCTA = (curCTA: GoogleCTA) => {
    props.handleUpdate({ ...props?.setting, aw_call_to_action: curCTA });
  };

  const updateBiginKeywords = (curKeywords: string[]) => {
    props.handleUpdate({ ...props?.setting, bigin_keywords: curKeywords });
  };

  const updateImage = (curImage: ProductImage) => {
    const newUrl = curImage.product_id ? curImage.detail_url : props.setting?.url || '';

    if (currentImageRatioType === '1:1') {
      if ('product_id' in curImage) {
        props.handleUpdate({
          ...props?.setting,
          product_image: curImage,
          product_image_id: curImage.id,
          mall_image: undefined,
          mall_image_id: undefined,
          url: newUrl,
        });
      } else {
        props.handleUpdate({
          ...props?.setting,
          mall_image: curImage,
          mall_image_id: curImage.id,
          product_image: undefined,
          product_image_id: undefined,
          url: newUrl,
        });
      }
      setValue('aw_image_id', curImage.id, { shouldValidate: true });
    } else if (currentImageRatioType === '1.91:1') {
      if ('product_id' in curImage) {
        props.handleUpdate({
          ...props?.setting,
          aw_landscape_product_image: curImage,
          aw_landscape_product_image_id: curImage.id,
          aw_landscape_mall_image: undefined,
          aw_landscape_mall_image_id: undefined,
          url: newUrl,
        });
      } else {
        props.handleUpdate({
          ...props?.setting,
          aw_landscape_mall_image: curImage,
          aw_landscape_mall_image_id: curImage.id,
          aw_landscape_product_image: undefined,
          aw_landscape_product_image_id: undefined,
          url: newUrl,
        });
      }
      setValue('aw_landscape_image_id', curImage.id, { shouldValidate: true });
    }
    setUrl(newUrl);
    setValue('url', newUrl);
  };

  const handleSelectImages = (images: ImageTypes[]) => {
    updateImage(images[0] as ProductImage);

    setShowModalImageSelect(false);
  };

  useEffect(() => {
    if (name !== props.setting?.name) {
      updateName(name);
    }
    if (awLongTitle !== props.setting?.aw_long_title) {
      updateAwLongTitle(awLongTitle);
    }
    if (awShortTitle !== props.setting?.aw_short_title) {
      updateAwShortTitle(awShortTitle);
    }
    if (awDescription !== props.setting?.aw_description) {
      updateAwDescription(awDescription);
    }
    if (!valueLock) {
      if (url !== props.setting?.url) {
        updateUrl(url);
      }
    }
    if (selectedCTA !== props.setting?.aw_call_to_action) {
      updateCTA(selectedCTA);
    }
    if (!_.isEqual(biginKeywords, props.setting?.bigin_keywords)) {
      updateBiginKeywords(biginKeywords);
    }
    setValueLock(false);
  }, [name, awLongTitle, awShortTitle, awDescription, url, selectedCTA, biginKeywords]);

  useEffect(() => {
    register('url', {
      value: url,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (!validator.isURL(value)) {
          return i18next.t('잘못된 URL 형식입니다.') as string;
        }

        return true;
      },
    });
    register('aw_long_title', {
      value: awLongTitle,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_MAX_BYTE) {
          return i18next.t('긴 광고 제목은 {{byte}} byte를 넘을 수 없습니다.', { byte: AW_MAX_BYTE }) as string;
        }

        return true;
      },
    });
    register('aw_short_title', {
      value: awShortTitle,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_SHORT_TITLE_MAX_BYTE) {
          return i18next.t('광고 제목은 {{byte}} byte를 넘을 수 없습니다.', {
            byte: AW_SHORT_TITLE_MAX_BYTE,
          }) as string;
        }

        return true;
      },
    });
    register('aw_description', {
      value: awDescription,
      required: i18next.t('필수 입력란입니다.') as string,
      validate: (value: string) => {
        if (filters.formatByte(value) > AW_MAX_BYTE) {
          return i18next.t('광고 문구는 {{byte}} byte를 넘을 수 없습니다.', { byte: AW_MAX_BYTE }) as string;
        }

        return true;
      },
    });
    register('aw_image_id', {
      value: (props.setting?.mall_image || props.setting?.product_image)?.id,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('aw_landscape_image_id', {
      value: (props.setting?.aw_landscape_mall_image || props.setting?.aw_landscape_product_image)?.id,
      required: i18next.t('필수 입력란입니다.') as string,
    });
  }, []);

  useEffect(() => {
    if (props.handleValid) {
      props.handleValid(props.setting?.type, formState.isValid);
    }
  }, [formState.isValid]);

  return (
    <BorderSection>
      <div className="fb-single-image-setting-wrapper" css={[baseCss]}>
        <AssetHeader
          title={i18next.t('반응형 이미지')}
          icons={[{ imgSrc: '/assets/img/card_icons/icons-ic-google.png', alt: 'Google' }]}
          handleIconClick={() => {
            props.removeSelectedCampaignTypes(props.setting.type);
          }}
        />
        <div className="create-section-content-wrapper">
          <div className="create-section-content">
            <div className="preview-container">
              <div className="sticky-wrap">
                <div className="title-panel">
                  <div className="title">{i18next.t('미리보기')}</div>
                  <div className="info">{i18next.t('*실제 노출과 미리보기가 다를 수 있습니다.')}</div>
                </div>
                <div className="material-preview-wrapper">
                  <div className="material-preview">
                    <div className="google-wrapper">
                      <div className="preview-media-title-wrapper">
                        <div className="title">{i18next.t('주요 노출 소재')}</div>
                      </div>
                      <div className="google-preview">
                        <PreviewGoogleItem setting={props.setting} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-container">
              <h2 className="material-field-header">{i18next.t('광고 소재명')}</h2>
              <div className="material-field-selector-wrapper">
                <BGInput
                  style={{ width: '100%' }}
                  inputProps={{
                    name: 'name',
                    placeholder: i18next.t('광고 소재 이름을 입력해주세요.'),
                    value: name || '',
                    onChange: (event) => {
                      setName(event.target.value);
                    },
                  }}
                />
                <p className="description">{i18next.t('*다른 소재와 구분지을 수 있게 이름을 입력하세요.')}</p>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 이미지')}</h2>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('1:1 비율의 광고 이미지')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <div className="content-upload-container">
                  <div className="single-image-uploader-wrapper uploader-wrapper">
                    <div className="image-uploader-title-wrapper">
                      <div className="image-uploader-subtitle">
                        <div>{i18next.t('* 최소 사이즈: ')} 300px*300px</div>
                        <div>{i18next.t('* 최대 사이즈: ')} 1200px*1200px</div>
                        <div>{i18next.t('* 파일 형식: ')} JPG, PNG</div>
                        <div>{i18next.t('* 파일 용량: {{size}}MB 이내', { size: 1 })}</div>
                      </div>
                    </div>
                    <div className="image-uploader-wrapper">
                      <div className="image-uploader-item-wrapper" style={{ flexDirection: 'row', gap: 8 }}>
                        <div css={smallButtonCss} className="image-uploader-btn">
                          {props.setting?.mall_image || props.setting?.product_image ? (
                            <img
                              style={{ width: '100%', height: '100%', position: 'relative' }}
                              src={(props.setting?.mall_image || props.setting?.product_image)?.url}
                              alt="1:1 이미지"
                            />
                          ) : (
                            <BDSFontIcon name="ic-img" color="#7e8696" size="20px" />
                          )}
                        </div>
                        <div className="file-wrapper">
                          <BDSButton
                            className="image-updater-btn"
                            onClick={() => {
                              setValueLock(true);
                              setCurrentImageRatioType('1:1');
                              setShowModalImageSelect(true);
                            }}
                            label={i18next.t('업로드')}
                          />
                          {props.setting.product_image && props.setting.product_image.name && (
                            <div className="file-name">{props.setting.product_image.name}</div>
                          )}
                          {props.setting.mall_image && <div className="file-name">{i18next.t('상품 정보 없음')}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('1.91:1 비율의 광고 이미지')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <div className="content-upload-container">
                  <div className="single-image-uploader-wrapper uploader-wrapper">
                    <div className="image-uploader-title-wrapper">
                      <div className="image-uploader-subtitle">
                        <div>{i18next.t('* 최소 사이즈: ')} 600px*314px</div>
                        <div>{i18next.t('* 최대 사이즈: ')} 1200px*628px</div>
                        <div>{i18next.t('* 파일 형식: ')} JPG, PNG</div>
                        <div>{i18next.t('* 파일 용량: {{size}}MB 이내', { size: 1 })}</div>
                      </div>
                    </div>
                    <div className="image-uploader-wrapper">
                      <div className="image-uploader-item-wrapper" style={{ flexDirection: 'row', gap: 8 }}>
                        <div css={smallButtonCss} className="image-uploader-btn">
                          {props.setting?.aw_landscape_mall_image || props.setting?.aw_landscape_product_image ? (
                            <img
                              style={{ width: '100%', height: '100%', position: 'relative' }}
                              src={
                                (props.setting?.aw_landscape_mall_image || props.setting?.aw_landscape_product_image)
                                  ?.url
                              }
                              alt="1.91:1 이미지"
                            />
                          ) : (
                            <BDSFontIcon name="ic-img" color="#7e8696" size="20px" />
                          )}
                        </div>
                        <div className="file-wrapper">
                          <BDSButton
                            className="image-updater-btn"
                            onClick={() => {
                              setValueLock(true);
                              setCurrentImageRatioType('1.91:1');
                              setShowModalImageSelect(true);
                            }}
                            label={i18next.t('업로드')}
                          />
                          {props.setting.aw_landscape_product_image &&
                            props.setting.aw_landscape_product_image.name && (
                              <div className="file-name">{props.setting.aw_landscape_product_image.name}</div>
                            )}
                          {props.setting.aw_landscape_mall_image && (
                            <div className="file-name">{i18next.t('상품 정보 없음')}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="horizontal-line" />
              <h2 className="material-field-header">{i18next.t('광고 소재 텍스트')}</h2>
              <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 문구')} checkboxLabel="" value hiddenCheckbox />
                <div className="material-field-input-wrapper">
                  <BDSTextArea
                    isAutoGrow
                    error={!!formState.errors.aw_description}
                    style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                    textAreaProps={{
                      name: 'aw_description',
                      cols: 30,
                      rows: 3,
                      style: {
                        minHeight: 49,
                      },
                      value: awDescription || '',
                      placeholder: i18next.t('광고 문구를 입력하세요.'),
                      onChange: (event) => {
                        googleRegexCheck(GoogleField.AW_DESCRIPTION, event.target.value);
                      },
                    }}
                    curLength={filters.formatByte(awDescription) || 0}
                    limitLength={90}
                    lengthUnit="byte"
                  />
                  {formState.errors.aw_description && (
                    <InputError message={formState.errors.aw_description.message as string} />
                  )}
                  {currentErrorField === GoogleField.AW_DESCRIPTION && (
                    <p className="description">
                      {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                    </p>
                  )}
                </div>
              </div>
              <div className="material-field-selector-wrapper" style={{ position: 'relative' }}>
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('긴 광고 제목')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <div className="material-field-input-wrapper">
                  <BDSTextArea
                    isAutoGrow
                    error={!!formState.errors.aw_long_title}
                    style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 72 }}
                    textAreaProps={{
                      name: 'aw_long_title',
                      cols: 30,
                      rows: 3,
                      style: {
                        minHeight: 49,
                      },
                      value: awLongTitle || '',
                      placeholder: i18next.t('긴 광고 제목을 입력하세요.'),
                      onChange: (event) => {
                        googleRegexCheck(GoogleField.AW_LONG_TITLE, event.target.value);
                      },
                    }}
                    curLength={filters.formatByte(awLongTitle) || 0}
                    limitLength={90}
                    lengthUnit="byte"
                  />
                  {formState.errors.aw_long_title && (
                    <InputError message={formState.errors.aw_long_title.message as string} />
                  )}
                  {currentErrorField === GoogleField.AW_LONG_TITLE && (
                    <p className="description">
                      {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                    </p>
                  )}
                </div>
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('광고 제목')} checkboxLabel="" value hiddenCheckbox />
                <BGInput
                  style={{ width: '100%', marginTop: '8px' }}
                  error={!!formState.errors.aw_short_title}
                  inputProps={{
                    name: 'aw_short_title',
                    value: awShortTitle || '',
                    placeholder: i18next.t('광고 제목을 입력하세요.'),
                    onChange: (event) => {
                      googleRegexCheck(GoogleField.AW_SHORT_TITLE, event.target.value);
                    },
                  }}
                  customLength={filters.formatByte(awShortTitle) || 0}
                  customLengthLimit={30}
                  lengthUnit="byte"
                />
                {formState.errors.aw_short_title && (
                  <InputError message={formState.errors.aw_short_title.message as string} />
                )}
                {currentErrorField === GoogleField.AW_SHORT_TITLE && (
                  <p className="description">
                    {i18next.t('* 구글 애즈 제작 시 구두점, 이모지, 특수문자 등을 남용할 경우 비승인될 수 있습니다.')}
                  </p>
                )}
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector
                  contentTitle={i18next.t('행동 유도 버튼')}
                  checkboxLabel=""
                  value
                  hiddenCheckbox
                />
                <BDSSelectBox
                  appearance="gray"
                  style={{ width: '100%', marginTop: '8px' }}
                  list={googleCTA}
                  defaultValue={props.setting?.aw_call_to_action}
                  displayValue={(item: any) => {
                    return i18next.t(item.label);
                  }}
                  isSelected={(item: any) => {
                    return selectedCTA === item.value;
                  }}
                  handleUpdate={(item: any) => {
                    setSelectedCTA(item.value);
                  }}
                />
              </div>
              <div className="material-field-selector-wrapper">
                <MaterialCheckboxSelector contentTitle={i18next.t('랜딩 URL')} checkboxLabel="" value hiddenCheckbox />
                <BGUrlSelector
                  linkButton
                  validationTrackOn
                  isValid={!formState.errors.url}
                  url={url || ''}
                  handleUpdate={(curUrl: string) => {
                    setUrl(curUrl);
                    setValue('url', curUrl, { shouldValidate: true });
                  }}
                  style={{ marginTop: '8px' }}
                  placeholder="www.bigin.io"
                />
                {formState.errors.url && <InputError message={formState.errors.url.message as string} />}
              </div>
              {campaignState.isBiginConnected && (
                <React.Fragment>
                  <div className="horizontal-line" />
                  <h2 className="material-field-header">{i18next.t('연계 캠페인')}</h2>
                  <div className="material-field-selector-wrapper">
                    <MaterialCheckboxSelector
                      contentTitle={i18next.t('핵심 키워드')}
                      checkboxLabel=""
                      value
                      hiddenCheckbox
                    />
                    <BGTagInput
                      style={{ width: '100%', marginTop: '8px' }}
                      inputProps={{
                        name: 'bigin_keywords',
                        placeholder: i18next.t(
                          'Bigin CRM에서 해당 광고 소재를 검색할 때 사용할 키워드를 입력해주세요.',
                        ),
                      }}
                      handleUpdate={(keywords) => {
                        setBiginKeywords(keywords);
                      }}
                      defaultTags={biginKeywords}
                      duplicateText={i18next.t('* 이미 등록된 키워드입니다.')}
                    />
                    <p className="description">
                      {i18next.t('*핵심 키워드는 선택사항이며, 최대 5개까지 입력할 수 있습니다.')}
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModalImageSelect && currentImageRatioType && (
        <ModalImageSelect
          selectType="single"
          selectedImages={selectedImages}
          campaignType={props.setting.type}
          isOpen={showModalImageSelect}
          ratio={currentImageRatioType}
          close={() => setShowModalImageSelect(false)}
          setSelectedImages={handleSelectImages}
        />
      )}
    </BorderSection>
  );
};
