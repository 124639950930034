import { css } from '@emotion/react';

export const baseCss = css`
  &.status-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding: 16px;
    border-radius: 8px;
    border: solid 1px #dce0e8;
    box-sizing: border-box;

    &.lock {
      background-color: #f9fafb;
    }

    &.hover {
      cursor: pointer;
    }

    .skeleton {
      border-radius: 0;
    }

    .header-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: relative;
    }

    .name-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 8px;

      .tooltip-icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 4px;
        cursor: default;
      }
    }

    .label-wrapper {
      display: inline-flex;
      border-radius: 10px;
      align-items: center;
      transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
      /* min-width: 20px; */
      padding: 2px 5px 2px 4px;
      height: 16px;
      background-color: #c9cfda;
      position: absolute;
      top: 0;
      right: 0;

      &.hover {
        background-color: #7e8696;
        overflow: hidden;
      }

      .label {
        transition: all 0.2s cubic-bezier(0.33, 1, 0.68, 1);
        max-width: 0px;
        overflow: hidden;
        opacity: 0;
        max-height: 40px;
        span {
          display: inline-block;
          white-space: nowrap;
        }

        &.hover {
          margin-left: 2px;
          padding: 1px;
          display: block;
          max-width: 190px;
          opacity: 1;
        }
      }
    }

    .count-wrapper {
      color: #3d4046;
      position: relative;
    }

    .compare-rate-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #e65c5c;
      margin-left: 12px;
      white-space: nowrap;

      &.increase {
        color: #0f9e6a;
      }

      &.decrease {
        color: #e65c5c;
      }

      .value-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        margin-left: 4px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;
