import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .icon-wrapper {
      margin-bottom: 8px;
      width: 64px;
      height: 64px;
      background-color: #f9fafb;
      border-radius: 8px;
      border: solid 1px #dce0e8;

      .icon {
        width: 100%;
        height: 100%;
      }
    }

    .title-wrapper {
      margin-bottom: 4px;
    }

    .footer {
      width: 100%;
      position: absolute;
      bottom: 20px;
    }

    .price-wrapper {
      ${theme.text.input};
      color: ${theme.colors.mainFont};

      .bold {
        ${theme.text.h3};
        color: ${theme.colors.mainFont};
      }
    }
  `;
};
