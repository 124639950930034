import { css, SerializedStyles, Theme } from '@emotion/react';

export const BGListFilterV2CSS = (theme: Theme): SerializedStyles => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
  height: 52px;

  .left-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    padding-left: 4px;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 4px;
    }

    .activated-filter-wrapper {
      display: flex;
      align-items: center;
      max-width: 250px;
      height: 36px;
      padding: 0 12px;
      border-radius: 8px;
      background-color: #edf1f6;
      box-sizing: border-box;

      .text-wrapper {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
        font-size: 14px;
        color: #53585f;
      }

      .delete-icon {
        margin-left: 8px;
      }
    }

    .search-input-wrapper {
      position: relative;
      transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 36px;
      border-radius: 8px;
      background-color: ${theme.colors.bg1};
      box-sizing: border-box;

      &.isExistActivated {
        border: solid 1px #dce0e8;
      }

      &:hover {
        input {
          box-shadow: none;
          background-color: transparent;
          color: ${theme.colors.mainFont};
        }
      }

      &:focus-within {
        input {
          border: none;
          color: ${theme.colors.mainFont};
        }
      }

      input {
        padding: 8px;
        font-size: 14px;
        border-radius: 8px;
        background-color: ${theme.colors.bg1};
        color: ${theme.colors.mainFont};
        box-shadow: none;
        box-sizing: border-box;

        &::placeholder {
          color: ${theme.colors.inputPlaceholderText};
        }
      }
    }
  }

  .right-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-left: 1px solid #ebeef4;

    .custom-button-wrapper {
      width: 100%;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-right: 1px solid #ebeef4;

      .custom-btn-group {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: #ffffff;
      }

      button {
        background: 0 0;
        min-width: 144px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:focus {
          outline: 0;
          box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        }

        img {
          width: 24px;
          height: 24px;
        }

        span {
          font-size: 14px;
          font-weight: bold;
          color: #53585f;
          margin-left: 8px;
        }
      }
    }
  }
`;
