import React, { ReactElement, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { BDSButton, BDSRadioButton } from '@bigin/bigin-ui-components';
import { useTranslation } from 'react-i18next';
import sysend from 'sysend';
import axios from 'axios';
import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { baseCss } from './LinkedBiginCrmModal.style';
import { useDataStore } from '../../context/Store';
import { BGTooltip } from '../BGTooltip/BGTooltip';
import { MediaApi } from '../../lib/api/MediaApi';
import StorageService from '../../services/StorageService';
import { CrmProject, MallApi } from '../../lib/api/MallApi';

export interface LinkedBiginCrmModalForm {
  biginProjectId: string;
}

interface CrmLogin {
  token: string;
  refreshToken: string;
  userId: string;
}

export interface LinkedBiginCrmModalProps {
  assets: MallMedia[];
  isCreateCampaign: boolean;
  close: () => void;
  onSubmitted?: () => void;
}

export const LinkedBiginCrmModal = (props: LinkedBiginCrmModalProps): ReactElement => {
  const i18next = useTranslation();
  const { dialogStore, toastStore } = useDataStore();
  const [selectedProject, setSelectedProject] = useState<CrmProject>();
  const [crmProjectList, setCrmProjectList] = useState<CrmProject[]>([]);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();

  const queryParams: any = useMemo(
    () => qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    [window.location.search],
  );

  const handleCrmLogin = () => {
    const options = 'top=10, left=10, width=600, height=800, status=no, menubar=no, toolbar=no, resizable=no';
    window.open(
      `${process.env.REACT_APP_BIGIN_CRM_URL}/login?adsRedirect=/auth/bigin-ads?redirect=${window.location.origin}/callbacks/bigin-login`,
      '_blank',
      options,
    );

    const handler = async (data: CrmLogin) => {
      await MediaApi()
        .putBiginCrmAuth(data.token, data.refreshToken, data.userId)
        .then(async () => {
          setIsLoggedIn(true);
          await MallApi()
            .getBiginCrmProjectList(StorageService.selectedMall.id)
            .then((res) => {
              setCrmProjectList(res.data.data);
            });
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            const response = error?.response;
            if (response && response.status === 422) {
              dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

              return;
            }
          }
          dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
          throw error;
        });
    };
    sysend.off('bigin-login-callback');
    sysend.on('bigin-login-callback', async (data: CrmLogin) => handler(data));
  };

  const handleCallbackError = async (errorCode: string) => {
    let message = i18next.t('잠시 후 다시 시도해주세요.');

    switch (errorCode) {
      case 'C003':
      case 'C004':
      case 'C999':
        break;
      case 'I005':
        message = i18next.t('Bigin CRM 연동이 되어있지 않아요.');
        break;
      case 'P001':
        message = i18next.t('플러그인 연동이 되어있지 않아요. Bigin CRM에서 플러그인을 연동한 후 다시 시도해 주세요!');
        break;
      case 'P002':
      case 'P003':
        break;
      case 'P004':
        message = i18next.t('브라우저 쿠키가 허용되어있지 않아요. 쿠키를 허용해주세요.');
        break;
      default:
        break;
    }

    return dialogStore.showMessage(i18next.t('알림'), message);
  };

  const handleLinkedCrmProject = async () => {
    if (selectedProject) {
      const handler = async () => {
        try {
          await MallApi().updateMallMedia(
            StorageService.selectedMall.id,
            'bigin-project',
            selectedProject.id,
            selectedProject.name,
          );
          if (queryParams.redirect) {
            setTimeout(() => {
              navigate(queryParams.redirect, { replace: true });
            }, 1000);
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            const response = error?.response;
            if (response && response.status === 409) {
              await handler();

              return;
            }
          }

          throw error;
        }

        toastStore.showToast(
          'basic',
          'success',
          i18next.t('Bigin CRM이 성공적으로 연동되었습니다. 이제 연계 캠페인 기능을 이용할 수 있습니다.'),
        );
        if (props.onSubmitted) {
          props.onSubmitted();
        }
        props.close();
      };

      const hosting = StorageService.selectedMall.hosting as Hosting;
      if (hosting === 'cafe24' && selectedProject.is_cafe24_plugin_installed) {
        sysend.off('bigin-crm-callback');
        sysend.on('bigin-crm-callback', async (data) => {
          if (data.result === 'false') {
            return handleCallbackError(data.errCode);
          }

          return handler();
        });

        try {
          const response = await MallApi().createCafe24Integration(StorageService.selectedMall.id, {
            project_id: selectedProject.id,
          });

          window.open(response.data.data.url);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            const response = error?.response;
            if (response && response.status === 409) {
              await handler();

              return;
            }
          }

          throw error;
        }
      } else {
        await handler();
      }
    }
  };

  const tag = (project: CrmProject) => {
    let label = '';

    if (project.is_equal_url) {
      label = i18next.t('동일 URL');
    }

    if (project.is_bigin_crm_connected) {
      label = i18next.t('연동중');
    }

    return <span className={`tag ${project.is_bigin_crm_connected ? 'disabled' : ''}`}>{label}</span>;
  };

  return (
    <div className="linked-bigin-modal-container" css={[baseCss]}>
      <div className="linked-bigin-modal-header">
        {props.isCreateCampaign ? i18next.t('Bigin CRM 연동 필요') : i18next.t('Bigin CRM 연동')}
      </div>
      <div className="linked-bigin-modal-contents">
        <div className="text-input text-color-main">
          {props.isCreateCampaign
            ? i18next.t(
                '연계 캠페인 기능은 Bigin CRM 사용자만 이용할 수 있습니다. Bigin CRM에 로그인하고 연동할 프로젝트를 선택해주세요.',
              )
            : i18next.t('Bigin CRM 연동을 위해 Bigin CRM에 로그인 후 프로젝트를 선택해주세요.')}
        </div>

        {isLoggedIn && crmProjectList.length > 0 ? (
          <div className="crm-project-wrapper">
            {crmProjectList.map((project) => (
              <React.Fragment key={project.id}>
                {project.is_equal_url || project.is_bigin_crm_connected ? (
                  <BGTooltip
                    title={
                      project.is_equal_url
                        ? (i18next.t('현재 쇼핑몰과 동일한 웹사이트 URL로 연결된 프로젝트입니다.') as string)
                        : (i18next.t('다른 쇼핑몰과 연동중인 프로젝트입니다.') as string)
                    }
                    placement="right"
                  >
                    <div
                      className={`project-item ${project.is_bigin_crm_connected ? 'disabled' : ''}`}
                      onClick={() => {
                        if (project.is_bigin_crm_connected) {
                          return;
                        }
                        setSelectedProject(project);
                      }}
                    >
                      {tag(project)}
                      <BDSRadioButton
                        inputProps={{
                          id: project.id,
                          name: project.id,
                          checked: selectedProject?.id === project.id,
                        }}
                        labelText={project.name}
                      />
                      <div className="description">{project.id}</div>
                    </div>
                  </BGTooltip>
                ) : (
                  <div className="project-item" onClick={() => setSelectedProject(project)}>
                    <BDSRadioButton
                      inputProps={{
                        id: project.id,
                        name: project.id,
                        checked: selectedProject?.id === project.id,
                      }}
                      labelText={project.name}
                    />
                    <div className="description">{project.id}</div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <button
            type="button"
            className="button-text"
            onClick={() => window.open('https://bigin.io/solution/bigin-crm', '_blank')}
          >
            <u>{i18next.t('Bigin CRM은 어떤 솔루션인가요?')}</u>
          </button>
        )}
      </div>
      <div className="horizontal-divider" style={{ marginLeft: '-20px', width: '320px' }} />
      <div className="linked-bigin-modal-footer">
        <BDSButton appearance="secondary" label={i18next.t('취소')} onClick={() => props.close()} />

        {isLoggedIn && crmProjectList.length > 0 ? (
          <BDSButton isDisabled={!selectedProject} label={i18next.t('연동')} onClick={() => handleLinkedCrmProject()} />
        ) : (
          <BDSButton
            label={props.isCreateCampaign ? i18next.t('Bigin CRM 연동') : i18next.t('Bigin CRM 로그인')}
            onClick={() => handleCrmLogin()}
          />
        )}
      </div>
    </div>
  );
};
