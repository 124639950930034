import React from 'react';
import { css, Theme, useTheme } from '@emotion/react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';

import { BorderSection } from '../../../components/BorderSection/BorderSection';
import { BGButton, BGButtonProps } from '../../../components/BGButton/BGButton';
import { baseCss } from './CardButton.style';

interface CardButtonProps {
  id?: number;
  title: string;
  desc: JSX.Element | string;
  icon?: 'kakaotalk' | 'sms' | 'facebook' | 'stibee' | 'sendgrid' | 'jandi' | 'cafe24' | 'slack' | string;
  button?: BGButtonProps;
  style?: Object;
}
export const CardButton: React.FC<CardButtonProps> = (props: CardButtonProps) => {
  const theme: Theme = useTheme();
  const themeCss = css`
    background-color: ${theme.colors.bg1};
  `;

  return (
    <div css={[baseCss, themeCss]}>
      <BorderSection key={props.id} style={{ marginBottom: '12px', overflow: 'hidden', backgroundColor: '#f9fafb' }}>
        <div className="link-management-container-area">
          <div className="left-wrapper">
            {props.icon && (
              <div className="image-wrapper">
                <img
                  className="card-button-image"
                  src={`/assets/img/card_icons/ic-${props.icon}.png`}
                  alt={`${props.icon}`}
                />
              </div>
            )}
            <div className="info-wrapper">
              <div className="title-wrapper">{props.title}</div>
              <div className="desc-wrapper">{props.desc}</div>
            </div>
          </div>
          {props.button && (
            <BGButton
              label={props.button.label}
              onClick={props.button.onClick}
              style={props.button.style || {}}
              appearance={props.button.appearance}
            />
          )}
        </div>
      </BorderSection>
    </div>
  );
};
CardButton.defaultProps = {
  id: undefined,
  icon: undefined,
  button: undefined,
  style: undefined,
};
