import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .badge {
      padding: 0 4px;
      border-radius: 4px;
      background-color: #edf1f6;
      ${theme.text.disclaimer};
    }
  `;
};
