import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  return css`
    padding: 20px;
    .product-image-container-item {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
      align-items: center;

      .image-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        width: calc(100% - 28px);
      }
      .more-image-button {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        padding: 0;
        background-color: #ffffff;
        cursor: pointer;
      }
    }
  `;
};

export const imageDetailCss = () => {
  const theme: Theme = useTheme();

  return css`
    .detail-modal-contents {
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      gap: 20px;

      .ratio-image-wrapper {
        .square-image {
          width: 209px;
          height: 209px;
          margin: 8px 0 0;
          border-radius: 4px;
          border: solid 1px #dce0e8;
          object-fit: cover;
        }
        .landscape-image {
          width: 371px;
          height: 209px;
          margin: 8px 0 0;
          border-radius: 4px;
          border: solid 1px #dce0e8;
          object-fit: cover;
        }
      }
    }
    .detail-modal-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      .delete-text {
        ${theme.text.disclaimer}
        display: inline-block;
        cursor: pointer;
      }
    }
  `;
};
export const imageEditorCss = css`
  .editor-modal-contents {
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 20px;
  }
  .editor-modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 24px;
    border-top: 1px solid #dce0e8;
    gap: 12px;
    padding: 20px;
  }
`;
