import React, { ReactElement, useState, useEffect, useMemo } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { BDSButton, BDSFontIcon } from '@bigin/bigin-ui-components';
import _, { isEmpty } from 'lodash';
import { baseCss } from './Step2ImageSetting.style';
import { BorderSection } from '../../../components/BorderSection/BorderSection';
import { BGInput } from '../../../components/BGInput/BGInput';
import { ModalImageSelect } from '../ModalImageSelect/ModalImageSelect';
import { BGEmpty } from '../../../components/BGEmpty/BGEmpty';
import StorageService from '../../../services/StorageService';
import { AssetsApi, TemplateFormList, TemplateList } from '../../../lib/api/AssetsApi';
import { getUniqKey } from '../../../utils/array.utils';
import Template from '../../../recoil/Template';
import { templateTableHeaderText } from '../../../utils/text';
import { filters } from '../../../utils/filter';
import { CurrencyCodeRecoil } from '../../../recoil/Currency';

export interface TemplateTableHeader {
  key: string;
  displayName: string;
  dataFormat?: string;
  headerStyle?: object;
  cellStyle?: object;
  skeletonComponent?: () => JSX.Element;
}

interface Step2ImageSettingProps {
  templateId: number;
  updateTemplateFormList: (list: TemplateFormList[]) => void;
  setCurrentValid: (valid: boolean) => void;
}

export const Step2ImageSetting = (props: Step2ImageSettingProps): ReactElement => {
  const i18next = useTranslation();
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateList>();
  const [showModalImageSelect, setShowModalImageSelect] = useState<boolean>(false);
  const [selectedImages, setSelectedImages] = useState<ImageTypes[]>([]);
  const templateFormListState = useRecoilValue(Template.templateFormList);
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);
  const [inputs, setInputs] = useState<any>({});

  const fetchTemplate = async () => {
    const res = await AssetsApi().getTemplate(StorageService.selectedMall.id, props.templateId);
    setSelectedTemplate(res.data.data);
    return res.data.data;
  };

  const randomString = () => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz';
    const stringLength = 4;
    let randomstring = '';
    for (let i = 0; i < stringLength; i += 1) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring += chars.substring(rnum, rnum + 1);
    }
    return randomstring;
  };

  const getPlaceholder = (name: string) => {
    if (name === 'price') {
      return i18next.t('금액을 입력해주세요.');
    }
    if (name === 'copy') {
      return i18next.t('광고 문구를 입력해주세요.');
    }
    if (name === 'product_name') {
      return i18next.t('상품명을 입력해주세요.');
    }
    return i18next.t('내용을 입력해주세요.');
  };

  const setObjectNameFormat = (name: string) => {
    const pattern = /\s/g;
    if (name.match(pattern)) {
      return name.replace(/ /gi, '_');
    }
    return name;
  };

  const sliceByByte = (str: string, maxByte: number) => {
    let i = 0;
    let c;
    // eslint-disable-next-line no-cond-assign
    for (let b = 0; (c = str.charCodeAt(i)); i += 1) {
      // eslint-disable-next-line no-bitwise
      b += c >> 7 ? 2 : 1;

      if (b > maxByte) break;
    }

    return str.substring(0, i);
  };
  useEffect(() => {
    const properties = selectedTemplate && selectedTemplate.properties;

    const templateListFormat = selectedImages.reduce((acc, cur) => {
      const randomId = randomString();

      const propertiesFormat =
        properties &&
        properties.reduce((dataAcc, dataCur) => {
          const data = {
            ...dataCur,
          } as any;
          const pattern = /\s/g;

          if (data.objectName.match(pattern)) {
            data.objectName = data.objectName.replace(/ /gi, '_');
          }

          if (data.type === 'TEXT') {
            if ('product_id' in cur) {
              if (data.objectName === 'price') {
                data.text = filters.formatCurrency({ value: cur.sale_price, currencyCode });
              } else if (data.objectName === 'product_name') {
                data.text = sliceByByte(cur.name, data.maxLength);
              } else {
                data.text = '';
              }
            } else {
              data.text = '';
            }
          } else if (data.type === 'IMAGE_URL') {
            if (data.objectName === 'product_image') {
              data.imageUrl = cur.url;
            } else if (data.objectName === 'logo_image') {
              data.imageUrl = StorageService.selectedMall.landscape_logo_url;
            }
          } else {
            data.value = '';
          }
          dataAcc.push(data);
          return dataAcc;
        }, [] as any[]);

      let item = {} as TemplateFormList;

      if ((cur as ProductImage).product_id) {
        item = {
          product_id: (cur as ProductImage).product_id,
          properties: propertiesFormat ? [...propertiesFormat] : [],
          id: randomId,
        } as TemplateFormList;
      } else {
        item = {
          properties: propertiesFormat ? [...propertiesFormat] : [],
          id: randomId,
        } as TemplateFormList;
      }

      acc.push(item);
      return acc;
    }, [] as TemplateFormList[]);
    if (templateListFormat) {
      const templateFormList = [...templateFormListState, ...templateListFormat];
      props.updateTemplateFormList(templateFormList);

      // inputs
      templateFormList.forEach((format) => {
        format.properties.forEach((data) => {
          if (data.type === 'TEXT') {
            const inputKey = getUniqKey(data.objectName, format.id);
            const input = {
              [inputKey]: data.text,
            };

            setInputs((prevState: any) => {
              return { ...prevState, ...input };
            });
          }
        });
      });
    }
  }, [selectedImages, selectedTemplate]);

  useEffect(() => {
    const keys = Object.keys(inputs);
    const isValid = !isEmpty(keys) && keys.every((key) => inputs[key] !== '');
    props.setCurrentValid(isValid);
  }, [inputs]);

  const handleChange = (value: string, objectName: string, id: string) => {
    const inputKey = getUniqKey(objectName, id);
    setInputs({
      ...inputs,
      [inputKey]: value,
    });

    const cloneFormList = _.cloneDeep(templateFormListState);
    const findIndex = cloneFormList.findIndex((item) => item.id === id);
    const findItem = cloneFormList[findIndex].properties.find((item) => item.objectName === objectName);

    if (findItem) {
      findItem.text = value;
    }

    props.updateTemplateFormList(cloneFormList);
  };

  const headers = useMemo(() => {
    if (selectedTemplate) {
      return selectedTemplate.properties.filter((data) => data.type === 'TEXT');
    }
    return [];
  }, [selectedTemplate]);
  useEffect(() => {
    fetchTemplate();
  }, []);

  const handleSelectImages = (images: ImageTypes[]) => {
    setSelectedImages([...(images as ProductImage[])]);
    setShowModalImageSelect(false);
  };

  const deleteTemplateFormList = (id: string) => {
    const tempInputs = _.cloneDeep(inputs);
    const Inputskeys = Object.keys(tempInputs);
    props.updateTemplateFormList(templateFormListState.filter((data) => data.id !== id));

    Inputskeys.forEach((key) => {
      if (key.includes(id)) {
        delete tempInputs[key];
      }
    });

    setInputs(tempInputs);
  };

  return (
    <React.Fragment>
      <div css={[baseCss]} className="image-setting-container">
        <BorderSection>
          <div className="border-section-header-wrapper">
            <div className="title-wrapper">
              <div className="text-h2 text-color-main mr-4">{i18next.t('템플릿 상세 정보 입력')}</div>
              <BDSButton
                appearance="secondary"
                fontIcon="ic-add"
                fontIconColor="#7e8696"
                label={i18next.t('상품 추가')}
                onClick={() => {
                  setSelectedImages([]);
                  setShowModalImageSelect(true);
                }}
              />
            </div>
          </div>
          <div className="border-section-contents-wrapper">
            <div className="template-table-form-wrapper">
              <table className="template-table">
                <thead>
                  <tr>
                    <th>{i18next.t('이미지')}</th>
                    {headers.map((header) => (
                      <th className={setObjectNameFormat(header.objectName)}>
                        {i18next.t(templateTableHeaderText[setObjectNameFormat(header.objectName)])}
                      </th>
                    ))}
                    <th />
                  </tr>
                </thead>
                <tbody className={templateFormListState.length === 0 ? 'empty-body' : ''}>
                  {templateFormListState.length === 0 && (
                    <div className="empty-container">
                      <div style={{ width: '310px' }}>
                        <BGEmpty
                          type="default"
                          title={i18next.t('불러온 상품이 없습니다.')}
                          description={i18next.t('상품 불러오기 버튼으로 템플릿을 완성해보세요')}
                        />
                      </div>
                    </div>
                  )}
                  {templateFormListState &&
                    templateFormListState.map((formList, index) => (
                      <tr>
                        {formList.properties
                          .filter(
                            (imageForm) => imageForm.type === 'IMAGE_URL' && imageForm.objectName === 'product_image',
                          )
                          .map((form) => (
                            <td>
                              <div className="template-table-td-title">
                                <div className="title-number">{index + 1}</div>
                                <img src={form.imageUrl} alt="" />
                              </div>
                            </td>
                          ))}
                        {formList.properties
                          .filter((textForm) => textForm.type === 'TEXT')
                          .map((form) => {
                            return (
                              <td>
                                <BGInput
                                  customLength={
                                    filters.formatByte(inputs[getUniqKey(form.objectName, formList.id)]) || 0
                                  }
                                  customLengthLimit={form.maxLength}
                                  lengthUnit="byte"
                                  inputProps={{
                                    type: 'text',
                                    placeholder: getPlaceholder(form.objectName),
                                    value: inputs[getUniqKey(form.objectName, formList.id)],
                                    onChange: (event) => {
                                      let result = '';
                                      const { value } = event.target;
                                      const currentByteLength = filters.formatByte(value);
                                      const lengthLimit = form.maxLength;
                                      if (lengthLimit && currentByteLength > lengthLimit) {
                                        result = sliceByByte(value, lengthLimit);
                                      } else {
                                        result = value;
                                      }
                                      handleChange(result, form.objectName, formList.id);
                                    },
                                  }}
                                />
                              </td>
                            );
                          })}

                        <td>
                          <span style={{ cursor: 'pointer' }} onClick={() => deleteTemplateFormList(formList.id)}>
                            <BDSFontIcon name="ic-delete-alt" color=" #7e8696" size="20px" />
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </BorderSection>
      </div>
      <React.Suspense fallback={null}>
        {showModalImageSelect && (
          <ModalImageSelect
            selectType="multiple"
            selectedImages={selectedImages}
            isOpen={showModalImageSelect}
            close={() => setShowModalImageSelect(false)}
            setSelectedImages={handleSelectImages}
          />
        )}
      </React.Suspense>
    </React.Fragment>
  );
};
