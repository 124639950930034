import { css } from '@emotion/react';

export const baseCss = css`
  transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);
  padding: 8px 14px;
  border-radius: 8px;
  background-color: #f9fafb;
  border: solid 1px #dce0e8;
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
    border: solid 1px #b8beca;
    color: #3d4046;
    input {
      cursor: pointer;
      color: #3d4046;
    }
  }

  &:focus-within {
    cursor: text;
    border: solid 1px #3399ff;
    color: #3d4046;
    input {
      color: #3d4046;
    }
  }
  input {
    font-size: 14px;
    border: 0;
    width: 40%;
    background-color: #f9fafb;
    color: #737b86;
    text-align: center;

    &:focus {
      cursor: text;
      color: #3d4046;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-transition: background-color 9999s ease-out;
      -webkit-box-shadow: 0 0 0px 1000px #f9fafb inset !important;
      -webkit-text-fill-color: #3d4046 !important;
    }
  }
  .input-card-divider {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: normal;
    color: #777788;
    padding: 0 15px;
  }
`;
