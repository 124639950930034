import React, { useEffect, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-cycle
import { AssetUploader } from './AssetUploader/AssetUploader';
import { Backdrop } from '../../../components/Backdrop/Backdrop';
import { baseCss } from './ModalAssetUpload.style';

export interface ModalAssetUploadProps {
  product?: Product;
  isOpen: string | number | boolean;
  type?: string;
  close: () => void;
  refetch?: () => void;
}

export interface UploaderRef {
  uploadFiles: () => void;
}
export const ModalAssetUpload = ({
  product,
  isOpen,
  type = 'image',
  close,
  refetch,
}: ModalAssetUploadProps): JSX.Element => {
  const i18next = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'scroll';
      document.body.style.width = '100%';
      document.body.style.position = 'fixed';

      setTimeout(() => {
        setVisible(true);
      }, 100);
    } else {
      setVisible(false);
      document.body.removeAttribute('style');
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.removeAttribute('style');
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <div className={`modal-container ${visible && 'visible'}`} css={[baseCss]}>
      <Backdrop
        handleClick={close}
        style={{
          zIndex: 99,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        }}
      />
      <div className="modal-contents-wrapper">
        <div className="modal-header">
          <div className="modal-title">{i18next.t('파일 추가')}</div>
        </div>
        <div className="modal-contents">
          {type === 'image' && product && (
            <AssetUploader
              fileType={type}
              acceptableFileTypes="image/jpeg, image/pjpeg, image/png"
              selectedProduct={product}
              close={close}
            />
          )}
          {type === 'video' && (
            <AssetUploader fileType={type} acceptableFileTypes="video/*" close={close} refetch={refetch} />
          )}
        </div>
      </div>
    </div>
  );
};
