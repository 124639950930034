import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';

import { baseCss } from './Badge.style';

interface BadgeProps {
  children: ReactElement;
}

export const Badge = (props: BadgeProps): ReactElement => {
  return (
    <div css={[baseCss]}>
      <div className="badge">{props.children}</div>
    </div>
  );
};
