import React, { ReactElement, RefObject, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';

import { baseCss } from './InputCardNumber.style';

interface InputCardNumberProps {
  nextRef?: RefObject<HTMLInputElement>;
  valueHandle?: (key: any, value: any) => void;
}

export const InputCardNumber = (props: InputCardNumberProps): ReactElement => {
  const [number, setNumber] = useState({ first: '', second: '', third: '', fourth: '' });
  const cardOneInput: React.Ref<HTMLInputElement> = useRef(null);
  const cardTwoInput: React.Ref<HTMLInputElement> = useRef(null);
  const cardThreeInput: React.Ref<HTMLInputElement> = useRef(null);
  const cardFourInput: React.Ref<HTMLInputElement> = useRef(null);

  const maxLengthCheck = (
    e: React.FormEvent<HTMLInputElement>,
    inputType: RefObject<HTMLInputElement>,
    index: 'first' | 'second' | 'third' | 'fourth',
  ) => {
    let { value } = e.currentTarget;
    const { maxLength } = e.currentTarget;
    value = value.replace(/[^0-9]*/g, '');

    if (value.length >= maxLength) {
      value = value.slice(0, maxLength);
      inputType.current?.focus();
    }
    const updateNumber = { ...number };
    updateNumber[index] = value;
    setNumber(updateNumber);
    if (props.valueHandle) props.valueHandle(index, value);
  };

  return (
    <div css={[baseCss]} className="input-card-number">
      <input
        type="text"
        maxLength={4}
        ref={cardOneInput}
        key={0}
        onInput={(e) => maxLengthCheck(e, cardTwoInput, 'first')}
        value={number.first}
      />
      <div className="input-card-divider">-</div>
      <input
        type="text"
        maxLength={4}
        ref={cardTwoInput}
        key={1}
        value={number.second}
        onInput={(e) => maxLengthCheck(e, cardThreeInput, 'second')}
      />
      <div className="input-card-divider">-</div>
      <input
        type="text"
        maxLength={4}
        ref={cardThreeInput}
        key={2}
        value={number.third}
        onInput={(e) => maxLengthCheck(e, cardFourInput, 'third')}
      />
      <div className="input-card-divider">-</div>
      <input
        type="text"
        maxLength={4}
        ref={cardFourInput}
        key={3}
        value={number.fourth}
        onInput={(e) => maxLengthCheck(e, props.nextRef ? props.nextRef : cardFourInput, 'fourth')}
      />
    </div>
  );
};

InputCardNumber.defaultProps = {
  nextRef: [],
  valueHandle: (string: string[]) => {
    console.log(string);
  },
};
