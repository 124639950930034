import React, { ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { BDSFontIcon } from '@bigin/bigin-ui-components';
import { useTranslation } from 'react-i18next';
import { baseCss } from './PlanModal.style';
import Plan from '../../../services/plans/Plan';

export interface PlanModalProps {
  plan: Plan;
  close: () => void;
}

export const PlanModal = (props: PlanModalProps): ReactElement => {
  const i18next = useTranslation();

  return (
    <div css={[baseCss]}>
      <div className="plan-modal-header">
        <div className="text-h1 text-color-main">{i18next.t('사용 중인 플랜')}</div>
        <BDSFontIcon name="ic-close" size="20px" color="#7e8696" handleClick={props.close} />
      </div>
      <div className="plan-modal-body">
        <div className="icon-wrapper">
          <img src={props.plan.image} className="icon" alt="plan-icon" />
        </div>
        <div className="title-wrapper">
          <p className="title">{props.plan.name}</p>
        </div>
        <div className="feature-items">
          {props.plan.features.map((feature: string) => (
            <div className="feature-item" style={{ display: 'flex', flexDirection: 'row' }} key={feature}>
              <div className="feature-item-icon" />
              <div className="feature-item-desc">
                <span>· {feature}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="plan-modal-footer">
        <div className="text-input text-color-main">
          <p className="text-h3 text-color-main">
            {i18next.t('구독료 {{number}}원', { number: props.plan.subscriptionFee })}
          </p>
          <p className="description" style={{ marginTop: 4 }}>
            {props.plan.feeRate &&
              i18next.t('환전 수수료 등을 포함한 {{number}}% 종합 수수료 부과', { number: props.plan.feeRate })}
          </p>
        </div>
      </div>
    </div>
  );
};
