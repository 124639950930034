import React, { ReactElement, useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
// noinspection ES6UnusedImports
import { jsx } from '@emotion/react';
import { BDSSelectBox, BDSButton } from '@bigin/bigin-ui-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { difference } from 'lodash';

import { baseCss } from './FbAssetModal.style';
import { BGButtonGroup } from '../BGButtonGroup/BGButtonGroup';
import { FacebookApi } from '../../lib/api/FacebookApi';
import StorageService from '../../services/StorageService';
import { MallApi } from '../../lib/api/MallApi';
import { useDataStore } from '../../context/Store';
import { MediaApi } from '../../lib/api/MediaApi';
import useScript from '../../hooks/useScript';
import { BGLoadingSpinner } from '../BGLoadingSpinner/BGLoadingSpinner';

type FbBusiness = {
  id: string;
  name: string;
};

type FbAdAccount = {
  id: string;
  name: string;
  business?: {
    id: string;
  };
};

type FbPage = {
  id: string;
  name: string;
};

type FbInstagram = {
  id: string;
  username: string;
};

type FbPixel = {
  id: string;
  name: string;
};

export interface FbAssetModalForm {
  selectedFbBusiness: FbBusiness;
  selectedFbAdAccount: FbAdAccount;
  selectedFbPage: FbPage;
  selectedFbInstagram: FbInstagram;
  selectedFbPixel: FbPixel;
}

export interface FbAssetModalProps {
  types: MediaAssetType[];
  assets: MallMedia[];
  close: () => void;
  onSubmitted?: () => void;
}

export const FbAssetModal = (props: FbAssetModalProps): ReactElement => {
  useScript('https://connect.facebook.net/en_US/sdk.js');
  const promisifiedFB = useMemo(
    () => ({
      init: (params: InitParams): void => {
        return window.FB.init(params);
      },
      getLoginStatus: (force?: boolean): Promise<LoginResponse> => {
        return new Promise((resolve) => {
          window.FB.getLoginStatus((response: LoginResponse) => {
            resolve(response);
          }, force);
        });
      },
      login: (opts?: LoginOptions): Promise<LoginResponse> => {
        return new Promise((resolve) => {
          window.FB.login((response: LoginResponse) => {
            resolve(response);
          }, opts);
        });
      },
      logout: (): Promise<LoginResponse> => {
        return new Promise((resolve) => {
          window.FB.logout((response: LoginResponse) => {
            resolve(response);
          });
        });
      },
    }),
    [window.FB],
  );

  const i18next = useTranslation();
  const { dialogStore } = useDataStore();

  const hasFbBusinessType = !!props.types.find((item) => item === 'fb-business');
  const initialFbBusiness = props.assets.find((item) => item.type === 'fb-business');
  const [selectedFbBusiness, setSelectedFbBusiness] = useState<FbBusiness | undefined>(
    initialFbBusiness ? { id: initialFbBusiness.media_id, name: initialFbBusiness.name } : undefined,
  );
  const {
    data: fbBusinesses,
    isLoading: isLoadingFbBusinesses,
    refetch: refetchFbBusinesses,
    remove: removeFbBusinesses,
  } = useQuery<FbBusiness[]>(
    'fbBusinesses',
    async () => (await FacebookApi().getBusinesses(StorageService.selectedMall.id)).data.data,
    { enabled: false },
  );

  const hasFbAdAccountType = !!props.types.find((item) => item === 'fb-ad-account');
  const initialFbAdAccount = props.assets.find((item) => item.type === 'fb-ad-account');
  const [selectedFbAdAccount, setSelectedFbAdAccount] = useState<FbAdAccount | undefined>(
    initialFbAdAccount ? { id: initialFbAdAccount.media_id, name: initialFbAdAccount.name } : undefined,
  );
  const {
    data: fbAdAccounts,
    isLoading: isLoadingFbAdAccounts,
    refetch: refetchFbAdAccounts,
    remove: removeFbAdAccounts,
  } = useQuery<FbAdAccount[]>(
    'fbAdAccounts',
    async () => (await FacebookApi().getFBAdAccounts(StorageService.selectedMall.id)).data.data,
    { enabled: false },
  );

  const hasFbPageType = !!props.types.find((item) => item === 'fb-page');
  const initialFbPage = props.assets.find((item) => item.type === 'fb-page');
  const [selectedFbPage, setSelectedFbPage] = useState<FbPage | undefined>(
    initialFbPage ? { id: initialFbPage.media_id, name: initialFbPage.name } : undefined,
  );
  const {
    data: fbPages,
    isLoading: isLoadingFbPages,
    refetch: refetchFbPages,
    remove: removeFbPages,
  } = useQuery<FbPage[]>(
    'fbPages',
    async () => (await FacebookApi().getPages(StorageService.selectedMall.id)).data.data,
    {
      enabled: false,
    },
  );

  const hasFbInstagramType = !!props.types.find((item) => item === 'fb-instagram-account');
  const initialFbInstagram = props.assets.find((item) => item.type === 'fb-instagram-account');
  const [selectedFbInstagram, setSelectedFbInstagram] = useState<FbInstagram | undefined>(
    initialFbInstagram ? { id: initialFbInstagram.media_id, username: initialFbInstagram.name } : undefined,
  );
  const {
    data: fbInstagrams,
    isLoading: isLoadingFbInstagrams,
    refetch: refetchFbInstagrams,
    remove: removeFbInstagrams,
  } = useQuery<FbInstagram[]>(
    'fbInstagrams',
    async () => (await FacebookApi().getInstagramAccounts(StorageService.selectedMall.id)).data.data,
    { enabled: false },
  );

  const hasFbPixelType = !!props.types.find((item) => item === 'fb-pixel');
  const initialFbPixel = props.assets.find((item) => item.type === 'fb-pixel');
  const [selectedFbPixel, setSelectedFbPixel] = useState<FbPixel | undefined>(
    initialFbPixel ? { id: initialFbPixel.media_id, name: initialFbPixel.name } : undefined,
  );
  const {
    data: fbPixels,
    isLoading: isLoadingFbPixels,
    refetch: refetchFbPixels,
    remove: removeFbPixels,
  } = useQuery<FbPixel[]>(
    'fbPixels',
    async () => (await FacebookApi().getPixels(StorageService.selectedMall.id)).data.data,
    { enabled: false },
  );

  const {
    data: isLoggedIn,
    refetch: refetchIsLoggedIn,
    remove: removeIsLoggedIn,
  } = useQuery('fbAuth', async () => (await MediaApi().getMediaAuthByType('facebook')).data.data.is_logged_in, {
    onSuccess: () => {
      if (hasFbBusinessType) {
        refetchFbBusinesses();
      }
      if (hasFbAdAccountType) {
        refetchFbAdAccounts();
      }
      if (hasFbPageType) {
        refetchFbPages();
      }
      if (hasFbInstagramType) {
        refetchFbInstagrams();
      }
      if (hasFbPixelType) {
        refetchFbPixels();
      }
    },
  });

  const { register, handleSubmit, setValue, formState } = useForm<FbAssetModalForm>({ mode: 'all' });
  useEffect(() => {
    register('selectedFbBusiness', { value: selectedFbBusiness });
    register('selectedFbAdAccount', { value: selectedFbAdAccount });
    register('selectedFbPage', { value: selectedFbPage });
    register('selectedFbInstagram', { value: selectedFbInstagram });
    register('selectedFbPixel', { value: selectedFbPixel });
  }, []);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const onSubmit = async (form: FbAssetModalForm) => {
    setIsSubmitting(true);
    try {
      if (form.selectedFbBusiness && form.selectedFbBusiness?.id !== initialFbBusiness?.media_id) {
        await MallApi().updateMallMedia(
          StorageService.selectedMall.id,
          'fb-business',
          form.selectedFbBusiness.id,
          form.selectedFbBusiness.name,
        );
      }
      if (form.selectedFbAdAccount && form.selectedFbAdAccount?.id !== initialFbAdAccount?.media_id) {
        await MallApi().updateMallMedia(
          StorageService.selectedMall.id,
          'fb-ad-account',
          form.selectedFbAdAccount.id,
          form.selectedFbAdAccount.name,
        );
      }
      if (form.selectedFbPage && form.selectedFbPage?.id !== initialFbPage?.media_id) {
        await MallApi().updateMallMedia(
          StorageService.selectedMall.id,
          'fb-page',
          form.selectedFbPage.id,
          form.selectedFbPage.name,
        );
      }
      if (form.selectedFbInstagram && form.selectedFbInstagram?.id !== initialFbInstagram?.media_id) {
        await MallApi().updateMallMedia(
          StorageService.selectedMall.id,
          'fb-instagram-account',
          form.selectedFbInstagram.id,
          form.selectedFbInstagram.username,
        );
      }
      if (form.selectedFbPixel && form.selectedFbPixel?.id !== initialFbPixel?.media_id) {
        await MallApi().updateMallMedia(
          StorageService.selectedMall.id,
          'fb-pixel',
          form.selectedFbPixel.id,
          form.selectedFbPixel.name,
        );
      }
    } finally {
      setIsSubmitting(false);
    }

    if (props.onSubmitted) {
      props.onSubmitted();
    }
    props.close();
  };

  const requiredScopes = [
    'business_management',
    'ads_management',
    'ads_read',
    'pages_manage_ads',
    'pages_manage_metadata',
    'pages_read_engagement',
    'pages_read_user_content',
    'pages_manage_posts',
    'pages_manage_engagement',
    'catalog_management',
  ];
  const login = async () => {
    if (isLoggedIn) {
      return;
    }
    const validateAndFinish = (loginResponse: LoginResponse): boolean | string => {
      if (loginResponse.status !== 'connected') {
        return i18next.t('로그인이 중간에 취소 되었어요.\n다시 로그인해주세요!') as string;
      }

      const grantedScopes = loginResponse.authResponse.grantedScopes.split(',');
      if (difference(requiredScopes, grantedScopes).length !== 0) {
        return i18next.t('일부 권한이 누락되었어요.\n모든 권한을 승인해주세요!') as string;
      }

      MediaApi()
        .putMediaAuth('facebook', loginResponse.authResponse.accessToken)
        .then(() => {
          return refetchIsLoggedIn();
        })
        .catch((error) => {
          promisifiedFB.logout();

          if (axios.isAxiosError(error)) {
            const response = error?.response;
            if (response && response.status === 422) {
              dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

              return;
            }
          }

          dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
          throw error;
        });
      return false;
    };

    promisifiedFB.init({
      appId: String(process.env.REACT_APP_FACEBOOK_APP_ID),
      status: true,
      cookie: true,
      xfbml: true,
      version: 'v12.0',
    });

    const loginOptions = { scope: requiredScopes.join(','), return_scopes: true };
    const message = validateAndFinish(await promisifiedFB.login(loginOptions));
    if (typeof message === 'string') {
      await dialogStore.showMessage(i18next.t('알림'), message);
      await promisifiedFB.logout();
    }
  };

  const logout = async () => {
    return MediaApi()
      .deleteMediaAuth('facebook')
      .then(() => {
        removeFbBusinesses();
        removeFbAdAccounts();
        removeFbPages();
        removeFbInstagrams();
        removeFbPixels();

        removeIsLoggedIn();
        refetchIsLoggedIn();
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          const response = error?.response;
          if (response && response.status === 422) {
            dialogStore.showMessage(i18next.t('알림'), response.data.messages[0].message);

            return;
          }
        }

        dialogStore.showMessage(i18next.t('알림'), i18next.t('잠시 후 다시 시도해주세요.'));
        throw error;
      });
  };

  return (
    <form css={[baseCss]} onSubmit={handleSubmit(onSubmit)}>
      <div className="fb-asset-modal-header">
        <div className="text-h1 text-color-main">{i18next.t('계정 연동 필요')}</div>
        <p className="desc">
          {i18next.t(
            'Facebook/Instagram 광고를 제작하기 위해 \n최초 1회에 한하여 계정 연동이 필요합니다.\n광고에 사용할 페이지를 선택해주세요.',
          )}
        </p>
      </div>
      <div className="fb-asset-modal-body">
        {hasFbBusinessType && (
          <div className="form-input-group">
            <p className="form-input-label">Business</p>
            {isLoadingFbBusinesses && <Skeleton style={{ height: '40px' }} />}
            {!isLoadingFbBusinesses && !isLoggedIn && (
              <div className="select-wrapper" onClick={login}>
                <BDSSelectBox
                  disabled
                  appearance="gray"
                  defaultValue={selectedFbBusiness}
                  displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('비즈니스 연동 필요'))}
                  handleUpdate={() => null}
                  isSelected={() => false}
                  list={[]}
                />
              </div>
            )}
            {!isLoadingFbBusinesses && isLoggedIn && (
              <BDSSelectBox
                appearance="gray"
                defaultValue={selectedFbBusiness}
                displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('비즈니스 연동 필요'))}
                handleUpdate={(item: any) => {
                  setValue('selectedFbBusiness', item, { shouldValidate: true });
                  setSelectedFbBusiness(item);
                }}
                isSelected={(item: any) => item.id === selectedFbBusiness?.id}
                list={fbBusinesses || []}
              />
            )}
          </div>
        )}
        {hasFbAdAccountType && (
          <div className="form-input-group">
            <p className="form-input-label">AdAccount</p>
            {isLoadingFbAdAccounts && <Skeleton style={{ height: '40px' }} />}
            {!isLoadingFbAdAccounts && !isLoggedIn && (
              <div className="select-wrapper" onClick={login}>
                <BDSSelectBox
                  disabled
                  appearance="gray"
                  defaultValue={selectedFbAdAccount}
                  displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('광고계정 연동 필요'))}
                  handleUpdate={() => null}
                  isSelected={() => false}
                  list={[]}
                />
              </div>
            )}
            {!isLoadingFbAdAccounts && isLoggedIn && (
              <BDSSelectBox
                disabled={!selectedFbBusiness}
                appearance="gray"
                defaultValue={selectedFbAdAccount}
                displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('광고계정 연동 필요'))}
                handleUpdate={(item: any) => {
                  setValue('selectedFbAdAccount', item, { shouldValidate: true });
                  setSelectedFbAdAccount(item);
                }}
                isSelected={(item: any) => item.id === selectedFbAdAccount?.id}
                list={(fbAdAccounts || []).filter((item: FbAdAccount) => item.business?.id === selectedFbBusiness?.id)}
              />
            )}
            <p className="desc">* {i18next.t('비즈니스 연동 후 광고계정 연동이 가능합니다.')}</p>
          </div>
        )}
        {hasFbPageType && (
          <div className="form-input-group">
            <p className="form-input-label">Facebook</p>
            {isLoadingFbPages && <Skeleton style={{ height: '40px' }} />}
            {!isLoadingFbAdAccounts && !isLoggedIn && (
              <div className="select-wrapper" onClick={login}>
                <BDSSelectBox
                  disabled
                  appearance="gray"
                  defaultValue={selectedFbPage}
                  displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('페이지 연동 필요'))}
                  handleUpdate={() => null}
                  isSelected={() => false}
                  list={[]}
                />
              </div>
            )}
            {!isLoadingFbPages && isLoggedIn && (
              <BDSSelectBox
                appearance="gray"
                defaultValue={selectedFbPage}
                displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('페이지 연동 필요'))}
                handleUpdate={(item: any) => {
                  setValue('selectedFbPage', item, { shouldValidate: true });
                  setSelectedFbPage(item);
                }}
                isSelected={(item: any) => item.id === selectedFbPage?.id}
                list={fbPages || []}
              />
            )}
          </div>
        )}
        {hasFbInstagramType && (
          <div className="form-input-group">
            <p className="form-input-label">Instagram</p>
            {isLoadingFbInstagrams && <Skeleton style={{ height: '40px' }} />}
            {!isLoadingFbAdAccounts && !isLoggedIn && (
              <div className="select-wrapper" onClick={login}>
                <BDSSelectBox
                  disabled
                  appearance="gray"
                  defaultValue={selectedFbInstagram}
                  displayValue={(item: any) => (item ? `${item.username}(${item.id})` : i18next.t('프로필 연동 필요'))}
                  handleUpdate={() => null}
                  isSelected={() => false}
                  list={[]}
                />
              </div>
            )}
            {!isLoadingFbInstagrams && isLoggedIn && (
              <BDSSelectBox
                disabled={!selectedFbPage}
                appearance="gray"
                defaultValue={selectedFbInstagram}
                displayValue={(item: any) => (item ? `${item.username}(${item.id})` : i18next.t('프로필 연동 필요'))}
                handleUpdate={(item: any) => {
                  setValue('selectedFbInstagram', item, { shouldValidate: true });
                  setSelectedFbInstagram(item);
                }}
                isSelected={(item: any) => item.id === selectedFbInstagram?.id}
                list={fbInstagrams || []}
              />
            )}
            <div className="desc">
              <p>* {i18next.t('페이스북 페이지 연동 후 인스타그램 연동이 가능합니다.')}</p>
              <p>* {i18next.t('프로필을 연동하지 않으면 페이스북 페이지로 연결됩니다.')}</p>
            </div>
          </div>
        )}
        {hasFbPixelType && (
          <div className="form-input-group">
            <p className="form-input-label">Pixel</p>
            {isLoadingFbPixels && <Skeleton style={{ height: '40px' }} />}
            {!isLoadingFbAdAccounts && !isLoggedIn && (
              <div className="select-wrapper" onClick={login}>
                <BDSSelectBox
                  disabled
                  appearance="gray"
                  defaultValue={selectedFbPixel}
                  displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('픽셀 연동 필요'))}
                  handleUpdate={() => null}
                  isSelected={() => false}
                  list={[]}
                />
              </div>
            )}
            {!isLoadingFbPixels && isLoggedIn && (
              <BDSSelectBox
                disabled={!selectedFbBusiness || !selectedFbAdAccount}
                appearance="gray"
                defaultValue={selectedFbPixel}
                displayValue={(item: any) => (item ? `${item.name}(${item.id})` : i18next.t('픽셀 연동 필요'))}
                handleUpdate={(item: any) => {
                  setValue('selectedFbPixel', item, { shouldValidate: true });
                  setSelectedFbPixel(item);
                }}
                isSelected={(item: any) => item.id === selectedFbPixel?.id}
                list={fbPixels || []}
              />
            )}
            <p className="desc">* {i18next.t('비즈니스 및 광고계정 연동 후 픽셀 연동이 가능합니다.')}</p>
          </div>
        )}
      </div>
      <div className="fb-asset-modal-footer">
        {isLoggedIn ? (
          <div className="logout-container">
            <p>{i18next.t('다른 계정으로 접속을 원하신다면?')}</p>
            <span className="logout" onClick={logout}>
              {i18next.t('계정 로그아웃')}
            </span>
          </div>
        ) : (
          <div />
        )}
        <BGButtonGroup rightAlign>
          <BDSButton
            appearance="secondary"
            label={i18next.t('취소')}
            onClick={() => {
              props.close();
            }}
          />
          <BDSButton label={i18next.t('연동')} type="submit" isDisabled={!formState.isValid} />
        </BGButtonGroup>
      </div>

      <BGLoadingSpinner isLoading={isSubmitting} />
    </form>
  );
};
