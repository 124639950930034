import React, { useEffect, useMemo, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { BDSButton, BDSFontIcon } from '@bigin/bigin-ui-components';
import { useRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import _, { range } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { cloneDeep } from 'lodash';
import { Backdrop } from '../../../components/Backdrop/Backdrop';
import { BGPagination } from '../../../components/BGPagination/BGPagination';
import { ProductImageContainer } from '../ProductImageContainer/ProductImageContainer';
import { setProducts } from '../../../recoil/Products';
import { ProductApi } from '../../../lib/api/ProductApi';
import StorageService from '../../../services/StorageService';
import { getUniqKey } from '../../../utils/array.utils';
import { BGInput } from '../../../components/BGInput/BGInput';
import { baseCss, imageManageCss, searchPaginationWrapperCss } from './ModalCategoryImageSelect.style';

export interface ModalImageSelectProps {
  // selectedImages: ProductImage[];
  isRatioSelectionMode?: boolean;
  isOpen: string | number | boolean;
  close: () => void;
  setDefaultImages: (images: ProductImage[]) => void;
  // setSelectedImages: (images: ImageTypes[]) => void;
  selectedCategoryId: number | undefined;
}

export interface UploaderRef {
  uploadFiles: () => void;
}
export const ModalCategoryImageSelector = ({
  // selectedImages = [],
  // isRatioSelectionMode = false,
  isOpen,
  close,
  setDefaultImages,
  // setSelectedImages,
  selectedCategoryId,
}: ModalImageSelectProps): JSX.Element => {
  const i18next = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);

  const [selectedImageList, setSelectedImageList] = useState<ProductImage[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  // Pagination state
  const [totalCount, setTotalCount] = useState<number>(1);
  const [countPerPage, setCountPerPage] = useState<number>(10);
  const [activePage, setActivePage] = useState<number>(1);

  const [productsState, setProductsState] = useRecoilState(setProducts);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.width = '100%';

      setTimeout(() => {
        setVisible(true);
      }, 100);
    } else {
      setVisible(false);
      document.body.removeAttribute('style');
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.removeAttribute('style');
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const renderSkeleton = () => (
    <div style={{ width: '100%', display: 'flex' }}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        {range(3).map((index) => (
          <div
            key={getUniqKey('skeleton-wrapper', index)}
            style={{
              width: '100%',
              height: '100%',
              display: 'inline-block',
              borderLeft: 'solid 1px #dce0e8',
            }}
          >
            <div style={{ width: '100%', height: '44px' }} />
            <div style={{ display: 'flex', gap: '8px', padding: '20px', borderBottom: 'solid 1px #dce0e8' }}>
              {range(7).map((boxIndex) => (
                <Skeleton
                  key={getUniqKey('skeleton-image-box', boxIndex)}
                  className="skeleton"
                  style={{ width: '88px', height: '88px' }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const { refetch: productsRefetch, isFetching: isProductsFetching } = useQuery('fetchProducts', async () => {
    const queryArray = [] as string[];
    let resultQuery = '';

    if (searchValue.length > 0) {
      queryArray.push(`(name ct "${searchValue}")`);
    }

    if (selectedCategoryId !== -1) {
      queryArray.push(`((categories ct ${selectedCategoryId}))`);
    }
    resultQuery = queryArray.length > 0 ? queryArray.join(' and ') : '';

    const res = await ProductApi().getProducts(StorageService.selectedMall?.id ?? null, {
      query: resultQuery,
      page: activePage,
      per_page: countPerPage,
      includes: ['images', 'categories'],
    });
    setProductsState([...res.data.data]);
    setTotalCount(res.data.total);
    return res.data.data;
  });

  const prevQueryParams = useMemo(() => {
    const parsedQueryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    return {
      ...parsedQueryParams,
      activePage: parsedQueryParams?.activePage ? parseInt(parsedQueryParams.activePage as string, 10) : 1,
      countPerPage: parsedQueryParams?.countPerPage ? parseInt(parsedQueryParams.countPerPage as string, 10) : 10,
      searchText: parsedQueryParams?.searchText || '',
    };
  }, [location.search]);

  const updateQueryParams = (queryParams: object) => {
    const resultQueryParams = qs.stringify(queryParams);

    const url = `${location.pathname}?${resultQueryParams}`;
    navigate(url, { replace: true });
  };

  // const deleteSelectImage = (image: ImageTypes) => {
  //   setSelectedImageList(selectedImageList.filter((selectedImage) => selectedImage.image_id !== image.image_id));
  // };

  const handleClickImage = (image: ProductImage) => {
    let tempImages: ProductImage[] = cloneDeep(selectedImageList);
    const selectProductIds = tempImages.map((selectedimage) => selectedimage.product_id);
    if (selectedImageList.includes(image)) {
      tempImages = tempImages.filter((selectedimage) => selectedimage.product_id !== image.product_id);
      return;
    }
    if (selectProductIds.includes(image.product_id)) {
      tempImages = tempImages.filter((selectedimage) => selectedimage.product_id !== image.product_id);
    }
    tempImages.push(image);
    setSelectedImageList(tempImages);
  };

  const debouncedFetchData = _.debounce(() => {
    productsRefetch();
  }, 500);
  useEffect(() => {
    debouncedFetchData();
  }, [selectedCategoryId, searchValue, activePage]);

  return (
    <div className={`modal-container ${visible && 'visible'}`} css={[baseCss, imageManageCss]}>
      <Backdrop
        handleClick={close}
        style={{
          zIndex: 99,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        }}
      />
      <div className="modal-contents-wrapper">
        <div className="modal-header">
          <div className="modal-title">{i18next.t('대표 이미지 변경')}</div>
        </div>
        <div className="modal-contents">
          <div css={[searchPaginationWrapperCss]}>
            <div className="search-wrapper">
              <BGInput
                inputProps={{
                  value: searchValue,
                  placeholder: i18next.t('상품명으로 검색해보세요.'),
                  onChange: (event) => {
                    setSearchValue(event.target.value);
                  },
                }}
                leftLabelComponent={<BDSFontIcon name="ic-search" color="#7e8696" size="20px" />}
              />
            </div>
            <div className="pagination-item-wrapper">
              <BGPagination
                isLoaded
                curPageItemLength={10}
                totalCount={totalCount}
                countPerPage={countPerPage}
                activePage={activePage}
                isHideCountSelector
                handleCountPerPage={(curCountPerPage) => {
                  setActivePage(1);
                  setCountPerPage(curCountPerPage);

                  // set Query Parameter
                  updateQueryParams({
                    ...prevQueryParams,
                    activePage: 1,
                    countPerPage: curCountPerPage,
                  });
                }}
                handleActivePage={(curActivePage) => {
                  setActivePage(curActivePage);

                  // set Query Parameter
                  updateQueryParams({
                    ...prevQueryParams,
                    activePage: curActivePage,
                  });
                }}
                selectBoxPosition="top"
              />
            </div>
          </div>
          <div className="image-container dialog">
            {isProductsFetching && renderSkeleton()}
            {productsState &&
              productsState.map((product, index) => (
                <ProductImageContainer
                  showDefaultLabel
                  key={product.id}
                  product={product}
                  selectMode
                  isRatioSelectionMode={false}
                  isLast={productsState.length === index + 1}
                  selectedImageIds={selectedImageList.map((image) => image.image_id)}
                  clickImage={handleClickImage}
                />
              ))}
          </div>
          <div className="footer-button-wrapper">
            <BDSButton appearance="secondary" style={{ marginRight: '12px' }} onClick={close}>
              {i18next.t('취소')}
            </BDSButton>
            <BDSButton isDisabled={selectedImageList.length <= 0} onClick={() => setDefaultImages(selectedImageList)}>
              {i18next.t('변경')}
            </BDSButton>
          </div>
        </div>
      </div>
    </div>
  );
};
