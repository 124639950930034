import React, { useEffect, ReactElement } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { BDSButton } from '@bigin/bigin-ui-components';
import { baseCss } from './CategorySelector.style';
import { BGButtonGroup } from '../../../../components/BGButtonGroup/BGButtonGroup';
import { BGTooltip } from '../../../../components/BGTooltip/BGTooltip';
import { getUniqKey } from '../../../../utils/array.utils';
import { CurrencyCodeRecoil } from '../../../../recoil/Currency';

type Button = {
  label: string;
  handleClick?: () => void;
  appearance?: string;
};

export interface CategorySelectorProps {
  buttons: Array<Button>;
  isDialogConfirm?: boolean | false;
  categories: Category[];
  selectedParentCategory: Category | undefined;
  selectedCategory: Category | undefined;
  setCategory: (category: Category | undefined) => void;
  setParentCategory: (category: Category | undefined) => void;
}

export const CategorySelector = (props: CategorySelectorProps): ReactElement => {
  const { ...i18next } = useTranslation();
  const currencyCode = useRecoilValue(CurrencyCodeRecoil);
  // const [, setCategoriesState] = useRecoilState(setCategory);

  // const fetchCategories = async () => {
  //   const res = await MallApi().getCategories(StorageService.selectedMall?.id ?? null, {
  //     includes: ['product_summary'],
  //   });
  //   setCategoriesState(res.data.data);
  //   return res.data.data as Category[];
  // };

  const { reset } = useForm({
    mode: 'all',
  });

  const CategoryItem = (category: Category, parentProps: CategorySelectorProps) => {
    const noCount = !category.product_summary || !category.product_summary.count;
    const moreThan4 = category.product_summary && category.product_summary.count >= 4;
    const isDisabled = noCount || !moreThan4;
    const isSelected = parentProps.selectedParentCategory?.id === category.id;

    const CategoryButton = () => (
      <button
        key={getUniqKey('category-menu', category.id)}
        type="button"
        className={`category-menu-button ${isSelected ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
        onClick={() => moreThan4 && parentProps.setParentCategory(category)}
      >
        <span>{category.name}</span>
        <span className="count">
          ({category.product_summary?.count || 0}
          {currencyCode === 'KRW' ? '개' : ''})
        </span>
      </button>
    );

    if (isDisabled) {
      return (
        <BGTooltip
          key={getUniqKey('parent-category-disabled', category.id)}
          title={
            (noCount
              ? i18next.t('상품 수 알 수 없음')
              : i18next.t('상품 수가 4개 이상인 카테고리만 선택할 수 있어요.')) as string
          }
          placement="right"
        >
          <div>
            <CategoryButton key={getUniqKey('category-menu-wrap', category.id)} />
          </div>
        </BGTooltip>
      );
    }

    return <CategoryButton key={getUniqKey('category-menu-wrap', category.id)} />;
  };

  const CategoryChildItem = (category: Category, parentProps: CategorySelectorProps) => {
    const noCount = !category.product_summary || !category.product_summary.count;
    const moreThan4 = category.product_summary && category.product_summary.count >= 4;
    const isDisabled = noCount || !moreThan4;
    const isSelected = parentProps.selectedCategory?.id === category.id;

    const CategoryButton = () => (
      <button
        key={getUniqKey('category-child-menu', category.id)}
        type="button"
        className={`category-menu-button ${isSelected ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
        onClick={() => moreThan4 && parentProps.setCategory(category)}
      >
        <span>{category.name}</span>
        <span className="count">
          ({category.product_summary?.count || 0}
          {currencyCode === 'KRW' ? '개' : ''})
        </span>
      </button>
    );

    if (isDisabled) {
      return (
        <BGTooltip
          key={getUniqKey('category-menu-disabled', category.id)}
          title={
            (noCount
              ? i18next.t('상품 수 알 수 없음')
              : i18next.t('상품 수가 4개 이상인 카테고리만 선택할 수 있어요.')) as string
          }
          placement="right"
        >
          <div>
            <CategoryButton key={getUniqKey('category-child-menu-wrap', category.id)} />
          </div>
        </BGTooltip>
      );
    }

    return <CategoryButton key={getUniqKey('category-child-menu-wrap', category.id)} />;
  };

  useEffect(() => {
    reset();
  }, []);

  return (
    <div css={[baseCss]}>
      <div className="category-selector-wrapper">
        <div className="title-wrapper">
          <div className="text-h1 text-color-main">{i18next.t('광고 카테고리 선택')}</div>
        </div>
        <div className="category-group-wrapper">
          <div className="main-category">
            <div className="title-wrapper">{i18next.t('대분류')}</div>
            <div className="category-menu-wrapper">
              {props.categories && props.categories.map((category) => CategoryItem(category, props))}
            </div>
          </div>
          <div className="sub-category">
            <div className="title-wrapper">{i18next.t('소분류')}</div>
            <div className="category-menu-wrapper">
              {props.selectedParentCategory && (
                <React.Fragment>
                  {CategoryChildItem(props.selectedParentCategory, props)}
                  {props.selectedParentCategory.children &&
                    props.selectedParentCategory.children.map((category) => CategoryChildItem(category, props))}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <BGButtonGroup className="category-buttons-wrapper" rightAlign>
          {props.buttons.map((button) => (
            <BDSButton
              key={button.label}
              label={button.label}
              onClick={button.handleClick}
              appearance={button.appearance || ''}
            />
          ))}
        </BGButtonGroup>
      </div>
    </div>
  );
};
