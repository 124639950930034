import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    * {
      box-sizing: border-box;
    }
    position: relative;

    .ad-list-table-wrapper {
      overflow: auto;
      max-height: 100%;
      position: relative;
    }

    .ad-list-table {
      width: 100%;
      background-color: #ffffff;
      border-radius: 8px;
      border-spacing: 0;
      border-collapse: separate;

      thead {
        position: sticky;
        top: 0;
        z-index: 2;

        th {
          ${theme.text.h5};
          color: #53585f;
          text-align: right;
          background-color: #ffffff;
          border-bottom: 1px solid #dce0e8;
          min-width: 96px;
          padding: 12px;
          .ad-list-table-header-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            white-space: nowrap;
          }
        }
        th:first-of-type {
          width: 456px;
          border-right: 1px solid #dce0e8;
          padding: 0 20px;
          height: 44.5px;
          position: sticky;
          left: 0;
          background-color: #ffffff;
        }
        th:last-of-type {
          min-width: 139px;
          padding: 12px 55px 12px 12px;
        }
      }

      .empty-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 416px;
        position: absolute;
        top: 0;
        left: 0;
      }
      .empty-body {
        height: 416px;
        position: relative;
      }
      tbody {
        tr {
          border-bottom: 1px solid #dce0e8;
          td {
            ${theme.text.h5};
            color: #53585f;
            padding: 8px 12px;
            text-align: left;
            border-bottom: 1px solid #dce0e8;
          }
          td:first-of-type {
            border-right: 1px solid #dce0e8;
            background-color: #ffffff;
            position: sticky;
            left: 0;
            z-index: 2;
          }
          td:last-of-type {
            padding: 8px 55px 8px 12px;
          }
        }

        tr:last-of-type {
          border-bottom: 1px solid transparent;
          td {
          }
        }

        .ad-group-row {
          background-color: #edf1f6;
          td {
            background-color: #edf1f6 !important;
            border-bottom: 1px solid #b8beca;
            padding: 8px 12px;
            height: 56px;
          }
          td:first-of-type {
            padding: 0 20px;
          }
        }

        .ad-list-table-row {
          td {
            height: 48px;
          }
          &:hover {
            background-color: #f9fafb;
            transition: 0.3s cubic-bezier(0.33, 1, 0.68, 1);

            td {
              background-color: #f9fafb;
            }
          }
        }
        .fb-target-table-row,
        .aw-target-table-row {
          td {
            height: 40px;
          }
        }
      }
    }
  `;
};
