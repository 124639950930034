import React, { ReactElement, useEffect, useRef, useState } from 'react';
import _ from 'lodash';

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import moment from 'moment';
import * as d3 from 'd3';
import { select, Selection } from 'd3';
import { chartColors } from '../../Theme';
import { DateRange } from '../Calendar/Calendar';
import { LineChartStyle } from './LineChart.style';
import { ChartTooltipData } from '../BgChartTooltip/BgChartTooltip';

const baseCss = LineChartStyle;

export interface LineChartConfig {
  dataset?: any[];
  comparisonDataset?: [];
  width?: number;
  height?: number;
  duration?: DateRange;
  compareDuration?: DateRange;
  intervalType?: string;
  tooltipType?: string;
}

export interface LineChartProps {
  config: LineChartConfig;
  hoverChart: ($event: any) => void;
  selectedChartDuration?: string;
  setSelectedChartDuration?: any;
  newtree?: boolean;
}

export interface Bundle {
  type: string;
  data?: any;
  line?: any;
  area?: any;
  x?: any;
  g?: Selection<any, any, any, any>;
  axisX?: any;
  point?: any;
}

export interface LineData extends Bundle {
  data: any;
  type: string;
  g?: Selection<any, any, any, any>;
}

export interface LineDataset {
  [key: string]: unknown;
}

// utils func start ======================================
const getMin = (yV: any[], vh: number) =>
  d3.max(yV, (d) => {
    return d.value ? parseFloat(d.value) : d.value;
  }) * 1.1 || vh;

const getMax = (yV: any[]) =>
  d3.min(yV, (d) => {
    return d.value ? parseFloat(d.value) : d.value;
  }) * 0.5;

const getTickValuesRange = (values: any[], ticksCount: number, vh: number) => {
  const yMax = getMin(values, vh);
  const yMin = getMax(values);

  const getD3Range = (ticksAmount: number, max: number, min: number): d3.NumberValue[] => {
    const tickStep = (max - min) / ticksAmount;
    const step = (tickStep / 5) * 5;
    return d3.range(min, max + step, step);
  };

  const valueRange = getD3Range(ticksCount, yMax, yMin);
  return valueRange;
};
// utils func end ======================================

export const LineChart = (props: LineChartProps): ReactElement => {
  const svgRef = useRef<SVGSVGElement>(null);
  let svg: Selection<SVGSVGElement, number, null, undefined>;
  let g: Selection<SVGGElement, number, null, undefined>;
  const orig: LineData = { data: {}, type: 'origin' };
  const comp: LineData = { data: {}, type: 'compare' };
  const colors = d3.scaleOrdinal(chartColors);
  let vw: number;
  let vh: number;
  let allValue;
  let y: any;
  let y2: any;
  const { duration } = props.config;
  const { compareDuration } = props.config;
  let dayArray: string[];
  const [selectedShapeType] = useState('line');
  const { width } = props.config || 1250;
  const height = 310;
  const margin = { top: 5, right: 0, bottom: 10, left: 0 };
  const emitMouseOut = (event: any) => {
    props.hoverChart({ name: 'mouseout', event, data: null });
  };

  const emitMouseOver = (event: any, data: ChartTooltipData[]) => {
    props.hoverChart({
      name: 'mouseover',
      event,
      data,
    });
  };

  function defineY(values: any[], originY?: any | undefined) {
    const yValues = _.filter(values, { isSeperatedY: false });
    const y2Values = _.filter(values, { isSeperatedY: true });

    const yMax = getMin(yValues, vh);
    const yMin = getMax(yValues);

    y = (originY || d3.scaleLinear()).rangeRound([vh, 0]).domain([yMin, yMax]);

    const y2Max = getMin(y2Values, vh);
    const y2Min = getMax(y2Values);

    y2 = (originY || d3.scaleLinear()).rangeRound([vh, 0]).domain([y2Min, y2Max]);
  }

  function nFormatter(num: any, digits: number) {
    if (!num) return num;
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'G' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const dataItem = lookup
      .slice()
      .reverse()
      .find((item) => {
        return num >= item.value;
      });
    if (dataItem?.value) return (num / dataItem!.value).toFixed(digits).replace(rx, '$1') + dataItem!.symbol;
    return num;
  }

  function addYAxis(values: any[]): void {
    const ticksCount = 5; // ticks 개수 강제 고정

    const yValues = _.filter(values, { isSeperatedY: false });
    const y2Values = _.filter(values, { isSeperatedY: true });

    const range1 = getTickValuesRange(yValues, ticksCount, vh);
    const range2 = getTickValuesRange(y2Values, ticksCount, vh);

    if (props.newtree) {
      g.append('g')
        .attr('class', 'axis_y')
        .call(
          d3
            .axisRight(y)
            .tickSize(0)
            .ticks(ticksCount)
            .tickValues(range1)
            .tickFormat((d: any) => {
              return nFormatter(d, 2);
            }),
        );
      g.append('g')
        .attr('class', 'axis_y2')
        .attr('transform', `translate(${width || 0 - margin.right}, 0)`)
        .call(
          d3
            .axisLeft(y2)
            .tickSize(0)
            .ticks(ticksCount)
            .tickValues(range2)
            .tickFormat((d: any) => {
              return nFormatter(d, 2);
            }),
        );
    } else {
      g.append('g')
        .attr('class', 'axis_y')
        .call(
          d3
            .axisRight(y)
            .tickSize(0)
            .ticks(ticksCount)
            .tickFormat((d: any) => {
              return nFormatter(d, 2);
            }),
        );
      g.append('g')
        .attr('class', 'axis_y2')
        .attr('transform', `translate(${width || 0 - margin.right}, 0)`)
        .call(
          d3
            .axisLeft(y2)
            .tickSize(0)
            .ticks(ticksCount)
            .tickFormat((d: any) => {
              return nFormatter(d, 2);
            }),
        );
    }
  }

  function defineBundle(bd: Bundle) {
    g.append('g').remove();
    const definedBd = bd;
    definedBd.g = bd.g || g.append('g');
    definedBd.g.attr('class', 'line-box');

    let startAt = duration!.start;
    let endAt = duration!.end;

    startAt = moment(startAt).startOf('day');
    endAt = moment(endAt).endOf('day');

    // @ts-ignore
    const defaultDays = Math.ceil(moment(endAt).diff(startAt, props.selectedChartDuration, true));
    let compareDays = 0;
    if (compareDuration) {
      let compareStart = compareDuration!.start;
      let compareEnd = compareDuration!.end;

      compareStart = moment(compareStart).startOf('day');
      compareEnd = moment(compareEnd).endOf('day');
      // @ts-ignore
      compareDays = Math.ceil(moment(compareEnd).diff(compareStart, props.selectedChartDuration, true));
    }

    const days = defaultDays > compareDays ? defaultDays : compareDays;

    dayArray = [];
    for (let i = 0; i < days; i += 1) {
      let day = moment(startAt).startOf('day').add(i, 'days').format('YYYY.MM.DD');
      if (props.selectedChartDuration === 'weeks')
        day = moment(startAt).startOf('week').add(i, 'weeks').format('YYYY.MM.DD');
      else if (props.selectedChartDuration === 'months')
        day = moment(startAt).startOf('month').add(i, 'months').format('YYYY.MM.DD');
      dayArray.push(day);
    }

    definedBd.x = d3
      .scaleLinear()
      .domain([0, dayArray.length - 1])
      .range([0, vw - 88]);

    definedBd.line = d3
      .line()
      .x((d: any) => {
        return bd.x(d.index);
      })
      .y((d: any) => {
        if (d.isSeperatedY) return y2(d.value);
        return y(d.value);
      });

    definedBd.area = d3
      .area()
      .x((d: any) => bd.x(d.index))
      .y0(vh)
      .y1((d: any) => {
        if (d.isSeperatedY) return y2(d.value);
        return y(d.value);
      });

    return { ...bd, ...definedBd };
  }

  function initPointData(data: any): Selection<any, any, any, any> {
    const pipe =
      (...fns: any[]) =>
      (x: any) =>
        fns.reduce((v, f) => f(v), x);
    const map =
      (...args: any[]) =>
      (arr: any) =>
        arr.map(...args);
    const prop = (p: string) => (o: any) => (o && o[p]) || null;
    const concat = (arr: any[]) => [].concat(...arr);

    return pipe(
      map((d: any) => {
        const values = d.values.map((v: any) => {
          return { ...{ parent: d }, ...v };
        });
        return { ...d, ...{ values } };
      }),
      map(prop('values')),
      concat,
    )(data);
  }

  function valueRate(value: number, compareValue: number) {
    if ((value === 0 || !value) && compareValue > 0) return -100;

    if ((compareValue === 0 || !compareValue) && value > 0) return 100;

    return (value > 0 ? ((value - compareValue) / compareValue) * 100 : 0.0).toFixed(2);
  }

  function emitPointMove($event: any, data: any, bd: any) {
    let compareData: any;
    let originalData: any;
    const datas = [];
    let origin;
    let compare;

    if (data.type === 'default') {
      origin = data;
      const index = _.findIndex(comp.data, { key: data.parent.key });
      if (index > -1) compare = comp.data[index].values[data.index];
    } else {
      compare = data;
      const index = _.findIndex(orig.data, { key: data.parent.key });
      if (index > -1) origin = orig.data[index].values[data.index];
    }

    if (props.config.tooltipType === 'Line') {
      const pointsDatas = g
        .selectAll('.point')
        .style('transition', 'opacity ease 0.2s')
        .style('opacity', '0')
        .filter((d: any) => d.index === data.index)
        .data();

      _.forEach(pointsDatas, (pointData: any) => {
        originalData = {
          color: pointData.parent.color ? pointData.parent.color : colors(pointData.parent.key),
          key: pointData.parent.key,
          value: pointData.value,
          dateKey: pointData.displayName,
          format: pointData.parent.format,
          fields: pointData.fields,
          type: pointData.type,
          isCompare: bd.type !== 'origin',
        };
        datas.push(originalData);
      });
    } else {
      if (origin) {
        originalData = {
          color: data.parent.color ? data.parent.color : colors(data.parent.key),
          key: data.parent.key,
          value: origin.value,
          dateKey: origin.displayName,
          format: data.parent.format,
          fields: origin.fields,
          type: origin.type,
          isCompare: bd.type !== 'origin',
        };
        datas.push(originalData);
      }
      if (compare) {
        compareData = {
          color: data.parent.color ? data.parent.color : colors(data.parent.key),
          key: data.parent.key,
          value: compare.value,
          dateKey: compare.displayName,
          format: data.parent.format,
          fields: compare.fields,
          type: compare.type,
          isCompare: bd.type !== 'origin',
        };
        datas.push(compareData);
      }

      if (origin && compare) {
        const comparerate = valueRate(origin.value, compare.value);
        originalData.comparerate = comparerate;
        compareData.comparerate = comparerate;
      }
    }

    if ($event.type === 'mouseover') emitMouseOver($event, datas);
    if ($event.type === 'mouseout') emitMouseOut($event);

    g.selectAll('.point')
      .style('transition', 'opacity ease 0.2s')
      .style('opacity', '0')
      .filter((d: any) => d.index === data.index)
      .style('opacity', '1');

    g.selectAll('.grid')
      .append('line')
      .lower()
      .attr('class', 'selectedLine')
      .attr('x1', () => {
        return bd.x(data.index) + 50;
      })
      .attr('y1', 0)
      .attr('x2', () => {
        return bd.x(data.index) + 50;
      })
      .attr('y2', vh)
      .attr('stroke', '#777799')
      .attr('stroke-width', 1);

    if ($event.type === 'mouseout') g.selectAll('.selectedLine').remove();
  }

  function addShape(colorScale: any, bd: Bundle) {
    if (!bd.g) return;
    // lines
    bd.g
      .selectAll('.line')
      .data(bd.data)
      .enter()
      .append('path')
      .attr('class', 'line')
      .classed('line_dashed', bd.type !== 'origin')
      .attr('d', (d: any) => {
        const lineDatas = _.clone(d.values);
        if (d && d.values && d.values.length === 1)
          lineDatas.push({ index: d.values[0].index, value: d.values[0].value * 0.5 });
        return bd.line(lineDatas);
      })
      .style('stroke', (d: any) => (d.color ? d.color : colorScale(d.key)))
      .on('mouseout', () => {
        g.selectAll('.point').style('transition', 'opacity ease 0.2s').style('opacity', '0');
      });
    // area
    bd.g
      .selectAll('.area')
      .data(bd.data)
      .enter()
      .append('path')
      .attr('class', 'area')
      .classed('area_hidden', selectedShapeType === 'line')
      .attr('d', (d: any) => bd.area(d.values))
      .style('fill', (d: any) => (d.color ? d.color : colorScale(d.key)));

    // points
    bd.g
      .selectAll('.point')
      .data(initPointData(bd.data))
      .enter()
      .append('circle')
      .attr('class', 'point')
      .attr('r', 4)
      .attr('cx', (d: any) => bd.x(d.index))
      .attr('cy', (d: any) => {
        if (d.isSeperatedY) return y2(d.value);
        return y(d.value);
      })
      .attr('color', (d: any) => (d.parent.color ? d.parent.color : colorScale(d.parent.key)))
      .attr('stroke', (d) => {
        return d.parent.color ? d.parent.color : colorScale(d.parent.key);
      })
      .attr('stroke-width', 1)
      .style('fill', (d: any) => {
        if (d.type === 'compare') return '#ffffff';
        return d.parent.color ? d.parent.color : colorScale(d.parent.key);
      })
      .style('opacity', '0')
      .on('mouseover', ($event: any, data: any) => {
        emitPointMove($event, data, bd);
      })
      .on('mouseout', ($event: any, data: any) => {
        emitPointMove($event, data, bd);
      });
  }

  function addXAxis(bd: any) {
    const xAxisBd = bd;
    const xAxis = xAxisBd.g
      .append('g')
      .attr('class', 'axis axis_x')
      .attr('transform', `translate(-18, ${vh + 16})`)
      .call(
        d3.axisBottom(bd.x).tickFormat((d: any) => {
          let tickText;
          if (d % 1 !== 0) return '';
          tickText = dayArray[d];
          tickText = moment(tickText).format('MM.DD');
          return tickText;
        }),
      );

    const ticks = xAxis.selectAll('.tick');
    ticks.attr('font-family', 'Spoqa Han Sans Neo');

    if (xAxisBd.type !== 'origin') {
      xAxis.selectAll('.tick').remove();
    }

    return { ...bd, ...xAxisBd };
  }

  function addGrid(values: any[]) {
    const yValues = _.filter(values, { isSeperatedY: false });

    const ticksCount = 5; // ticks 개수 강제 고정
    const range1 = getTickValuesRange(yValues, ticksCount, vh);

    if (props.newtree) {
      g.append('g')
        .attr('class', 'grid')
        .attr('width', width || 0 - margin.left - margin.right)
        .attr('height', height - margin.top - margin.bottom)
        .call(d3.axisLeft(y).tickSize(-vw).ticks(ticksCount).tickValues(range1).tickFormat(null));
    } else {
      g.append('g')
        .attr('class', 'grid')
        .attr('width', width || 0 - margin.left - margin.right)
        .attr('height', height - margin.top - margin.bottom)
        .call(d3.axisLeft(y).tickSize(-vw).ticks(ticksCount).tickFormat(null));
    }
  }

  function enableArea(flag: boolean) {
    svg.selectAll('.area').classed('area_hidden', !flag);
  }

  const addNewtreeYAxisLabel = () => {
    g.append('text')
      .attr('class', 'y label left')
      .attr('text-anchor', 'start')
      .attr('y', height - margin.bottom)
      .attr('x', 0)
      .attr('dy', '-0.25em')
      .style('font-size', '12px')
      .style('fill', '#53585f')
      .text('매출액');

    g.append('text')
      .attr('class', 'y label right')
      .attr('text-anchor', 'end')
      .attr('y', height - margin.bottom)
      .attr('x', width || 0 - margin.right)
      .attr('dy', '-0.25em')
      .style('font-size', '12px')
      .style('fill', '#53585f')
      .text('수량/건수');
  };

  function drawChart() {
    allValue = [];
    allValue = [].concat(
      ...orig.data.map((d: any) =>
        d.values.sort((a: any, b: any) => {
          return moment(a.key).valueOf() - moment(b.key).valueOf();
        }),
      ),
      ...comp.data.map((d: any) =>
        d.values.sort((a: any, b: any) => {
          return moment(a.key).valueOf() - moment(b.key).valueOf();
        }),
      ),
    );

    defineY(allValue);
    addYAxis(allValue);
    addGrid(allValue);
    if (props.newtree) addNewtreeYAxisLabel();

    if (orig) {
      defineBundle(orig);
      addXAxis(orig);
      addShape(colors, orig);
    }

    if (comp && comp.data && comp.data.length > 0) {
      defineBundle(comp);
      addXAxis(comp);
      addShape(colors, comp);
    }

    svg.selectAll('.line-box').attr('transform', 'translate(70 ,0)');
    svg.selectAll('.grid').attr('transform', 'translate(20 ,0)');

    enableArea(selectedShapeType === 'area');
  }

  function initData(data: any, isCompare?: any) {
    if (!data) return [];
    const setActiveData = (list: any) => {
      const result = list;
      let i = result && result[0] ? result.length : 0;
      while (i) {
        i -= 1;
        if (result[i].active) {
          result[i].index = i;
          if (result[i].values) {
            setActiveData(result[i].values);
          }
        } else {
          result.splice(i, 1);
        }
      }
      return result;
    };
    const sanitize = (d: any) => {
      return {
        key: moment(d.key).format('YYYY.M.DD'),
        value: d.value,
        fields: d.fields,
        index: d.index,
        type: isCompare ? 'compare' : 'default',
        displayName: d.displayName,
        metric: d.metric ? d.metric : null,
        isSeperatedY: d.isSeperatedY,
      };
    };

    const refineData = setActiveData(data)
      ? data.map((_data: any) => {
          const d = { ..._data };
          if (d.values) d.values = d.values.map(sanitize);
          return d;
        })
      : [];

    return refineData;
  }

  useEffect(() => {
    if (!svgRef || !svgRef.current) return;
    svg = select(svgRef.current);
    svg.selectAll('g').remove();
    g = svg
      .append('g')
      .attr('class', 'line-group')
      .attr('width', width! - margin.left - margin.right)
      .attr('height', height - margin.top - margin.bottom)
      .attr('transform', `translate(${margin.left},${margin.top})`);
    vw = parseInt(svg.attr('width'), 10) - margin.left - margin.right - 40;
    vh = parseInt(svg.attr('height'), 10) - margin.top - margin.bottom - 30;

    orig.data = initData(props.config!.dataset!);
    comp.data = initData(props.config!.comparisonDataset!, true);
    drawChart();
  }, [props.config.dataset, selectedShapeType, props.config.width]);

  return (
    <div css={[baseCss]} className="line-chart-container">
      <div className="chart-wrapper">
        <svg ref={svgRef} width={width} height={height} />
      </div>
    </div>
  );
};
