import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();
  return css`
    .header {
      margin-bottom: 8px;

      .image-wrapper {
        margin-bottom: 8px;
      }
    }

    .back-btn-container {
      margin-top: 8px;
      .back-btn {
        text-decoration: underline;
        cursor: pointer;
        ${theme.text.disclaimer};
      }
    }
    .footer {
      ${theme.text.disclaimer};
      margin: 0 -20px -20px -20px;
      padding: 20px;

      border-top: solid 1px #dce0e8;
      background-color: #f9fafb;

      .button {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  `;
};
