import { css, Theme, useTheme } from '@emotion/react';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .image-wrapper {
      position: relative;
      margin: 20px -20px 20px -20px;
      padding: 0 20px 0 20px;

      width: 100%;
      height: 160px;
    }

    .contents {
      .content-item {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .content-title {
          ${theme.text.h4};
          color: ${theme.colors.mainFont};
          margin-bottom: 8px;
        }
        .content-desc {
          &:not(:last-child) {
            margin-bottom: 4px;
          }
          ${theme.text.input};
          color: ${theme.colors.mainFont};
        }
      }
    }
  `;
};
