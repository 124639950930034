import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx, Theme, useTheme } from '@emotion/react';
import 'react-loading-skeleton/dist/skeleton.css';
import { BDSToggleButton } from '@bigin/bigin-ui-components';
import { useTranslation } from 'react-i18next';
import { targetingTypeText } from '../../../utils/text';

export interface AdTargetTitleProps {
  item?: AdTargetType;
  handleStatusToggle: (item: AdTargetType, uniAd: UniAd, media: string) => void;
  media: string;
  uniAd: UniAd;
}

export const AdTargetTitle = ({
  item,
  handleStatusToggle,
  media = 'facebook',
  uniAd,
}: AdTargetTitleProps): JSX.Element => {
  const theme: Theme = useTheme();
  const i18next = useTranslation();

  return (
    <div
      css={css`
        font-size: 14px;
        font-weight: normal !important;
        color: ${theme.colors.mainFont};
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 20px;
        letter-spacing: normal;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: flex;
        align-items: center;
      `}
    >
      <div
        css={css`
          width: 100%;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            width: 295px;
            display: flex;
            align-items: center;
          `}
        >
          <img src="/assets/img/common/icons-ic-corner-down.svg" alt="icon" />
          <div
            css={css`
              margin-left: 8px;
            `}
          >
            {(item && i18next.t(targetingTypeText[(item as AdTargetType).targeting_type])) || ''}
          </div>
        </div>
        <div
          css={css`
            display: inline-flex;
            gap: 16px;
            align-items: center;
          `}
        >
          <BDSToggleButton
            active={(item as AdTargetType).is_use as boolean}
            onChangeToggle={() => handleStatusToggle(item as AdTargetType, uniAd, media)}
          />
        </div>
      </div>
    </div>
  );
};
