import React, { useState, useEffect } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { BDSInput } from '@bigin/bigin-ui-components';
import _ from 'lodash';
import { useRecoilValue } from 'recoil';
import { BorderSection } from '../../../../components/BorderSection/BorderSection';
import { baseCss } from './GoogleSearch.style';
import { PreviewGoogleItem } from '../PreviewGoogleItem/PreviewGoogleItem';
import { AssetHeader } from '../../../../components/AssetHeader/AssetHeader';
import CampaignSetting from '../../../../recoil/CampaignSetting';
import { MaterialCheckboxSelector } from '../../MaterialCheckboxSelector/MaterialCheckboxSelector';
import { BGTagInput } from '../../../../components/BGTagInput/BGTagInput';

interface GoogleSearchProps {
  setting: CampaignAdSettingState;
  handleUpdate: (setting: CampaignAdSettingState) => void;
  handleValid: (type: CampaignType, isValid: boolean) => void;
  removeSelectedCampaignTypes: (type: CampaignType) => void;
}

export const GoogleSearch = (props: GoogleSearchProps): JSX.Element => {
  const { setting, handleUpdate, removeSelectedCampaignTypes } = props;
  const i18next = useTranslation();

  const campaignState = useRecoilValue(CampaignSetting.campaignSetting);
  const [biginKeywords, setBiginKeywords] = useState<string[]>(setting?.bigin_keywords);

  const updateBiginKeywords = (curKeywords: string[]) => {
    handleUpdate({ ...setting, bigin_keywords: curKeywords });
  };

  useEffect(() => {
    if (!_.isEqual(biginKeywords, props.setting?.bigin_keywords)) {
      updateBiginKeywords(biginKeywords);
    }
  }, [biginKeywords]);

  const [adName, setAdName] = useState<string>(setting.name);
  return (
    <BorderSection>
      <div className="goggle-search-asset-setting-wrapper" css={baseCss}>
        <AssetHeader
          title={i18next.t('구글 검색')}
          icons={[{ imgSrc: '/assets/img/card_icons/icons-ic-google.png', alt: 'Google' }]}
          handleIconClick={() => {
            removeSelectedCampaignTypes(setting.type);
          }}
        />
        <div className="create-section-content-wrapper">
          <div className="preview-container">
            <div className="sticky-wrap">
              <div className="title-panel">
                <div className="title">{i18next.t('미리보기')}</div>
                <div className="info">{i18next.t('*실제 노출과 미리보기가 다를 수 있습니다.')}</div>
              </div>
              <div className="material-preview-wrapper">
                <div className="material-preview">
                  <div className="google-shopping-wrapper">
                    <div className="google-preview">
                      <div className="preview-title-wrapper">{i18next.t('검색 키워드 맞춤형')}</div>
                      <PreviewGoogleItem setting={setting} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-container">
            <h2 className="material-field-header">{i18next.t('광고 소재명')}</h2>
            <div className="material-field-selector-wrapper">
              <BDSInput
                style={{ width: '100%' }}
                inputProps={{
                  name: 'name',
                  placeholder: i18next.t('광고 소재 이름을 입력해주세요.'),
                  value: adName || '',
                  onChange: (event) => {
                    setAdName(event.target.value);
                  },
                }}
              />
              <p className="description">{i18next.t('*다른 소재와 구분지을 수 있게 이름을 입력하세요.')}</p>
            </div>
            <div className="horizontal-line" />
            <h2 className="material-field-header">{i18next.t('광고 안내')}</h2>
            <div className="material-field-selector-wrapper">
              <p className="description">
                {i18next.t(
                  '* 구글 사이트에서 유저가 입력한 검색 키워드에 따라, 브랜드 및 경쟁사, 상품 및 산업 트렌드, 유저의 관심사에 맞춰 키워드 기반의 광고가 노출됩니다.',
                )}
              </p>
            </div>
            <div className="horizontal-line" />
            {campaignState.isBiginConnected && (
              <React.Fragment>
                <div className="horizontal-line" />
                <h2 className="material-field-header">{i18next.t('연계 캠페인')}</h2>
                <div className="material-field-selector-wrapper">
                  <MaterialCheckboxSelector
                    contentTitle={i18next.t('핵심 키워드')}
                    checkboxLabel=""
                    value
                    hiddenCheckbox
                  />
                  <BGTagInput
                    style={{ width: '100%', marginTop: '8px' }}
                    inputProps={{
                      name: 'bigin_keywords',
                      placeholder: i18next.t('Bigin CRM에서 해당 광고 소재를 검색할 때 사용할 키워드를 입력해주세요.'),
                    }}
                    handleUpdate={(keywords) => {
                      setBiginKeywords(keywords);
                    }}
                    defaultTags={biginKeywords}
                    duplicateText={i18next.t('* 이미 등록된 키워드입니다.')}
                  />
                  <p className="description">
                    {i18next.t('*핵심 키워드는 선택사항이며, 최대 5개까지 입력할 수 있습니다.')}
                  </p>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </BorderSection>
  );
};
