import {
  locale_en_gb,
  markup_editor_locale_en_gb,
  plugin_crop_locale_en_gb,
  plugin_filter_locale_en_gb,
  plugin_finetune_locale_en_gb,
  plugin_resize_locale_en_gb,
  plugin_decorate_locale_en_gb,
  plugin_annotate_locale_en_gb,
  plugin_sticker_locale_en_gb,
} from './pintura';

export default {
  ...locale_en_gb,
  ...markup_editor_locale_en_gb,
  ...plugin_crop_locale_en_gb,
  ...plugin_filter_locale_en_gb,
  ...plugin_finetune_locale_en_gb,
  ...plugin_resize_locale_en_gb,
  ...plugin_decorate_locale_en_gb,
  ...plugin_annotate_locale_en_gb,
  ...plugin_sticker_locale_en_gb,

  // label
  annotateLabel: '오브젝트',
  cropLabel: '자르기',
  filterLabel: '필터',
  finetuneLabel: '조절',
  stickerLabel: '로고 삽입',

  // finetune
  finetuneLabelBrightness: '밝기',
  finetuneLabelContrast: '대비',
  finetuneLabelSaturation: '채도',
  finetuneLabelExposure: '노출',
  finetuneLabelTemperature: '온도',
  finetuneLabelGamma: '감마',
  finetuneLabelClarity: '선명함',
  finetuneLabelVignette: '비네팅',

  // filter
  filterLabelChrome: '선명한',
  filterLabelFade: '부드러운',
  filterLabelCold: '차가운',
  filterLabelWarm: '따뜻한',
  filterLabelPastel: '아련한',
  filterLabelMonoDefault: '모노',
  filterLabelMonoNoir: '느와르',
  filterLabelMonoWash: '뚜렷한',
  filterLabelMonoStark: '회상',
  filterLabelSepiaDefault: '세피아',
  filterLabelSepiaBlues: '블루스',
  filterLabelSepiaRust: '빛바랜',
  filterLabelSepiaColor: '가을',
};
