import React, { ChangeEvent, ChangeEventHandler, ReactElement, useEffect, useRef, useState } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { BDSButton, BDSFontIcon, BDSSelectBox, BDSTextArea } from '@bigin/bigin-ui-components';
import { useForm } from 'react-hook-form';
import { baseCss, requestModalCss } from './Step1TemplateSetting.style';
import { BorderSection } from '../../../components/BorderSection/BorderSection';
import { BGTab } from '../../../components/BGTab/BGTab';
import { getUniqKey } from '../../../utils/array.utils';
import { BGEmpty } from '../../../components/BGEmpty/BGEmpty';
import { AssetsApi, RequestTemplateForm, TemplateList } from '../../../lib/api/AssetsApi';
import StorageService from '../../../services/StorageService';
import Template from '../../../recoil/Template';
import { WrapperModal } from '../../../components/WrapperModal/WrapperModal';
import { BGInput } from '../../../components/BGInput/BGInput';
import { FontIcon } from '../../../components/FontIcon/FontIcon';
import { MallApi } from '../../../lib/api/MallApi';
import { useDataStore } from '../../../context/Store';
import { InputError } from '../../../components/InputError/InputError';

interface InputFileProps {
  accept: string;
  label: string;
  onChange: ChangeEventHandler;
}

const InputFile = (props: InputFileProps): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <React.Fragment>
      <input type="file" accept={props.accept} ref={inputRef} onChange={props.onChange} style={{ display: 'none' }} />
      <BDSButton
        label={props.label}
        onClick={() => {
          inputRef?.current?.click();
        }}
      />
    </React.Fragment>
  );
};

export interface Step1TemplateSettingProps {
  templateRemains: number;
  commonTemplates: TemplateList[];
  requestedTemplates: TemplateList[];
}

export const Step1TemplateSetting = ({
  templateRemains,
  commonTemplates,
  requestedTemplates,
}: Step1TemplateSettingProps): ReactElement => {
  const i18next = useTranslation();
  const [selectedTab, setSelectedTab] = useState<{ key: string; label: string }>({
    key: 'default',
    label: i18next.t('기본 템플릿'),
  });
  const [showRequestModal, setShowRequestModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [request, setRequest] = useState<string>('');
  const [reference, setReference] = useState<string | undefined>('');
  const [selectedMedia, setSelectedMedia] = useState<string>(i18next.t('선택하기'));
  const resetTemplateFormListState = useResetRecoilState(Template.templateFormList);
  const [selectedTemplateState, setSelectedTemplateState] = useRecoilState(Template.selectedTemplate);
  const { register, formState, handleSubmit, setValue, reset } = useForm<RequestTemplateForm>({
    mode: 'all',
  });
  const { dialogStore } = useDataStore();

  const clearState = () => {
    setEmail('');
    setPhone('');
    setReference('');
    setRequest('');
    setSelectedMedia(i18next.t('선택하기'));
  };

  const mediaList = [
    i18next.t('1:1비율 (Facebook, Instagram, Google)'),
    i18next.t('1.91:1비율 (Google)'),
    i18next.t('2:1비율 (Kakao)'),
    i18next.t('Kakao 비즈보드'),
  ];

  const mediaIcons = (id: string) => {
    const squreType = [
      '624a996dc52cee0d03001df7',
      '624a99b449cbdf765724b351',
      '624a9a5c49cbdf765724b353',
      '624a9abd49cbdf765724b355',
      '624a9b40c52cee0d03001df9',
      '624a9b8dc52cee0d03001dfb',
    ];
    const googleType = ['624a9d5a49cbdf765724b359', '624a9d89c52cee0d03001e01'];
    const kakaoType = ['624a9ca3c52cee0d03001dfd', '624a9ce0c52cee0d03001dfe', '624a9e0149cbdf765724b35a'];
    if (squreType.includes(id)) {
      return ['facebook', 'instagram', 'gg'];
    }
    if (googleType.includes(id)) {
      return ['gg'];
    }
    if (kakaoType.includes(id)) {
      return ['kakao'];
    }

    return [];
  };

  useEffect(() => {
    register('email', {
      value: email,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('phone', {
      value: phone,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('media', {
      value: selectedMedia,
      required: i18next.t('필수 입력란입니다.') as string,
    });
    register('reference', {
      value: reference,
      required: i18next.t('파일을 선택해 주세요.') as string,
    });
    register('request', {
      value: request,
      required: i18next.t('필수 입력란입니다.') as string,
    });
  });

  const loadFile = (file: File, onLoad: (this: FileReader, ev: ProgressEvent<FileReader>) => void) => {
    const fileReader = new FileReader();
    fileReader.onload = onLoad;
    fileReader.readAsDataURL(file);
  };

  const requestTemplate = async (taskData: { task_type: TaskType; data: object; status?: TaskStatus }) => {
    return MallApi()
      .createTask(StorageService.selectedMall.id, taskData)
      .then(() => {
        setShowRequestModal(false);
        dialogStore.showMessage(
          i18next.t('템플릿 요청 완료'),
          i18next.t(
            '이미지 템플릿 제작을 요청했습니다.\n영업일 기준 최대 3일이 소요되며 제작 완료 시, 이메일 및 알림톡을 통해 알려드립니다.',
          ),
        );
        clearState();
        reset({
          email: '',
          phone: '',
          media: '',
          reference: '',
          request: '',
        });
      });
  };

  const handleRequestTemplate = async (form: RequestTemplateForm) => {
    const taskData = {
      task_type: 'create_databrush_template',
      data: form,
    } as { task_type: TaskType; data: object; status?: TaskStatus };

    if (templateRemains === 0) {
      const buttons = [
        {
          label: '취소',
          handleClick: () => {
            dialogStore.hideDialog();
          },
          appearance: 'secondary',
        },
        {
          label: '차감 후 생성',
          handleClick: () => {
            requestTemplate(taskData);
            dialogStore.hideDialog();
          },
        },
      ];

      dialogStore.showDialog(
        i18next.t('크레딧 차감'),
        i18next.t('요청 가능 템플릿 수가 부족합니다.\n5,000 크레딧이 차감됩니다.'),
        buttons,
      );
    } else {
      requestTemplate(taskData);
    }
  };

  const handleUploadImage = async (image: File) => {
    AssetsApi()
      .postUploadImage(image)
      .then((res) => {
        setValue('reference', res.data.data.url, { shouldValidate: true });
        setReference(res.data.data.url);
      });
  };

  const templateCard = (template: TemplateList) => {
    return (
      <React.Fragment>
        <div className="template-thumbnail-wrapper">
          <img className="thumbnail" src={template.thumbnail_url} alt={template.name} />
          {template.id === selectedTemplateState.id && (
            <div className="icon-wrap">
              <BDSFontIcon name="ic-check-alt" color="#006fff" size="20px" />
            </div>
          )}
        </div>
        <div className="title-wrapper">
          <div className="text-h4 text-color-main">{template.name}</div>

          <div className="media-icons-wrapper">
            {mediaIcons(template.template_id).map((icon) => (
              <img src={`/assets/img/card_icons/ic-channel-${icon}.svg`} alt="" />
            ))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div css={[baseCss]} className="template-setting-container">
        <BorderSection>
          <div className="border-section-header-wrapper">
            <div className="title-wrapper">
              <div className="text-h2 text-color-main mr-4">{i18next.t('템플릿 선택')}</div>
            </div>
            <div className="description-wrapper">
              <div className="text-input text-color-main">
                {i18next.t('생성하려는 이미지에 적합한 템플릿을 선택해주세요.')}
              </div>
            </div>
            <BDSButton
              appearance="secondary"
              fontIcon="ic-add"
              fontIconColor="#7e8696"
              label={i18next.t('템플릿 요청')}
              style={{ position: 'absolute', top: '20px', right: '20px' }}
              onClick={() => setShowRequestModal(true)}
            />
          </div>
          <BGTab
            style={{ padding: '0 20px', marginTop: '14px' }}
            tabList={[
              {
                key: 'default',
                label: i18next.t('기본 템플릿'),
              },
              {
                key: 'request',
                label: i18next.t('요청 템플릿'),
              },
            ]}
            selectedTab={selectedTab}
            handleTab={(tab) => {
              setSelectedTab(tab);
            }}
          />
          <div className="border-section-contents-wrapper">
            {selectedTab.key === 'default' && (
              <div className="template-wrapper">
                {commonTemplates &&
                  commonTemplates.length > 0 &&
                  commonTemplates.map((template: TemplateList) => (
                    <div
                      key={getUniqKey('template', `${template.id}`)}
                      className={`template-box ${template.id === selectedTemplateState.id ? 'active' : ''}`}
                      onClick={() => {
                        setSelectedTemplateState(template);
                        resetTemplateFormListState();
                      }}
                      onMouseOver={() => false}
                      onMouseLeave={() => false}
                      onFocus={() => false}
                    >
                      {templateCard(template)}
                    </div>
                  ))}
              </div>
            )}
            {selectedTab.key === 'request' && (
              <React.Fragment>
                {requestedTemplates && requestedTemplates.length === 0 && (
                  <div className="empty-wrapper">
                    <BGEmpty
                      type="default"
                      title={i18next.t('요청하신 이미지 템플릿이 없습니다.')}
                      description={i18next.t('필요한 이미지 템플릿을 요청하세요.')}
                    />
                  </div>
                )}
                <div className="template-wrapper">
                  {requestedTemplates &&
                    requestedTemplates.length > 0 &&
                    requestedTemplates.map((template: TemplateList) => (
                      <div
                        key={getUniqKey('template', `${template.id}`)}
                        className={`template-box ${template.id === selectedTemplateState.id ? 'active' : ''}`}
                        onClick={() => {
                          setSelectedTemplateState(template);
                          resetTemplateFormListState();
                        }}
                        onMouseOver={() => false}
                        onMouseLeave={() => false}
                        onFocus={() => false}
                      >
                        {templateCard(template)}
                      </div>
                    ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </BorderSection>
      </div>
      {showRequestModal && (
        <WrapperModal
          isOpen={showRequestModal}
          close={() => {
            setShowRequestModal(false);
          }}
          position={{ width: '640px', minHeight: '865px', padding: '0' }}
        >
          <div className="request-modal" css={[requestModalCss]}>
            <div className="request-modal-header">
              <div className="text-h1 text-color-main">{i18next.t('이미지 템플릿 요청')}</div>
              <div className="text-input text-color-main mt-2">
                {i18next.t(
                  '이미지 템플릿 제작은 최대 3일 소요되며 제작이 끝나면 이메일과 알림톡을 통해 알려드립니다.\n제작이 완료된 템플릿은 크리에이티브 > 이미지 생성 > 요청 템플릿에 추가됩니다.',
                )}
              </div>
              <div className="template-info-wrapper">
                <div className="text-p3 text-bold text-color-main">
                  {i18next.t('요청 가능 템플릿 수 {{remains}}개', { remains: templateRemains })}
                </div>
                <div className="text-disclaimer">
                  {i18next.t('매월 3개의 템플릿 무료 제작 요청이 가능하며 초과 시, 개당 5,000 크레딧이 부과됩니다.')}
                </div>
              </div>
              <div className="horizontal-line" />
            </div>
            <form onSubmit={handleSubmit(handleRequestTemplate)}>
              <div className="request-modal-body">
                <div className="contents-wrapper">
                  <div className="grid-wrapper">
                    <div>
                      <div className="info-title">{i18next.t('이메일')}</div>
                      <BGInput
                        inputProps={{
                          type: 'text',
                          name: 'email',
                          placeholder: i18next.t('이메일을 입력하세요'),
                          value: email,
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue('email', event.target.value, { shouldValidate: true });
                            setEmail(event.target.value);
                          },
                        }}
                      />
                      {formState.errors.email && <InputError message={formState.errors.email.message as string} />}
                    </div>
                    <div>
                      <div className="info-title">{i18next.t('전화번호')}</div>
                      <BGInput
                        inputProps={{
                          type: 'text',
                          name: 'phone',
                          placeholder: i18next.t('전화번호를 입력하세요'),
                          value: phone,
                          onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                            setValue('phone', event.target.value, { shouldValidate: true });
                            setPhone(event.target.value);
                          },
                        }}
                      />
                      {formState.errors.phone && <InputError message={formState.errors.phone.message as string} />}
                    </div>
                  </div>
                </div>
                <div className="contents-wrapper">
                  <div className="info-title">{i18next.t('템플릿 비율(매체)')}</div>
                  <BDSSelectBox
                    appearance="gray"
                    style={{ width: '100%', marginTop: '8px' }}
                    list={mediaList}
                    defaultValue={i18next.t('선택하기')}
                    displayValue={(item: any) => {
                      return item;
                    }}
                    isSelected={(item: any) => {
                      return selectedMedia === item;
                    }}
                    handleUpdate={(item: any) => {
                      setValue('media', item, { shouldValidate: true });
                      setSelectedMedia(item);
                    }}
                  />
                </div>
                <div className="contents-wrapper">
                  <div className="info-title">{i18next.t('레퍼런스')}</div>
                  <div className="material-field-selector-wrapper">
                    <div className="content-upload-container">
                      <div className="uploader-wrapper">
                        <div className="image-uploader-title-wrapper">
                          <div className="image-uploader-subtitle">
                            <div>{i18next.t('* 파일 형식: ')} JPG, PNG</div>
                            <div>{i18next.t('* 파일 용량: {{size}}MB 이내', { size: 10 })}</div>
                          </div>
                        </div>
                        <div className="upload-box-uploader">
                          <div className="upload-box-thumbnail">
                            {reference ? (
                              <img
                                className="upload-box-image"
                                src={reference}
                                alt=""
                                style={{ width: '100%', height: '100%' }}
                              />
                            ) : (
                              <FontIcon name="ic-img" size="20px" color="#7e8696" />
                            )}
                          </div>
                          <InputFile
                            accept="image/*"
                            label={i18next.t('파일 선택')}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              const file = (e.target.files && e.target.files[0]) || undefined;
                              if (file === undefined) {
                                return;
                              }

                              // eslint-disable-next-line @typescript-eslint/no-unused-vars
                              loadFile(file, (ev: ProgressEvent<FileReader>) => {
                                handleUploadImage(file);
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contents-wrapper">
                  <div className="info-title">{i18next.t('요청사항')}</div>
                  <div className="material-field-input-wrapper">
                    <BDSTextArea
                      isAutoGrow
                      error={!!formState.errors.request}
                      style={{ width: '100%', marginTop: '8px', boxSizing: 'border-box', minHeight: 100 }}
                      textAreaProps={{
                        name: 'request',
                        cols: 30,
                        rows: 4,
                        value: request || '',
                        style: { minHeight: 80 },
                        placeholder: i18next.t('광고 문구, 컨셉 등 필요한 내용을 자세하게 입력해주세요.'),
                        onChange: (event) => {
                          setValue('request', event.target.value, { shouldValidate: true });
                          setRequest(event.target.value);
                        },
                      }}
                      curLength={request.length}
                    />
                    {formState.errors.request && <InputError message={formState.errors.request.message as string} />}
                  </div>
                </div>
                <div className="text-disclaimer mt-2 px-5">
                  {i18next.t(
                    '* 상품 이름, 판매 가격, 할인 가격을 활용하면 상품 추가 시, 템플릿에 데이터가 자동 입력됩니다. \n* 쇼핑몰 환경에 따라 일부 데이터 활용에 제한이 있을 수 있습니다. \n* 요청사항, 쇼핑몰 환경, 상품 데이터를 종합하여 최적의 이미지 템플릿을 제작합니다.',
                  )}
                </div>
                <div className="footer-wrapper">
                  <BDSButton
                    appearance="secondary"
                    label={i18next.t('취소')}
                    onClick={() => {
                      setShowRequestModal(false);
                      clearState();
                      reset({
                        email: '',
                        phone: '',
                        media: '',
                        reference: '',
                        request: '',
                      });
                    }}
                  />
                  <BDSButton
                    label={i18next.t('요청')}
                    type="submit"
                    isDisabled={!formState.isValid || selectedMedia === i18next.t('선택하기')}
                  />
                </div>
              </div>
            </form>
          </div>
        </WrapperModal>
      )}
    </React.Fragment>
  );
};
