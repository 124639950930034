import { css, useTheme, Theme } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = () => {
  const theme: Theme = useTheme();

  return css`
    * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      min-width: fit-content;
      display: flex;
      gap: ${GS.gutter}px;
      position: relative;
      font-stretch: normal;
      font-style: normal;

      .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .payment-info-container {
      display: flex;
      width: 100%;

      .badge {
        display: inline-block;
        margin-left: 4px;
        padding: 2px 4px;
        background-color: ${theme.colors.bg3};
        border-radius: 4px;
        ${theme.text.disclaimer}
        color: ${theme.colors.smallFont};
      }
    }

    .credit-history-wrapper {
      width: 100%;
    }
  `;
};

export const leftWrapper = css`
  width: calc(100% - 366px);
  min-width: ${GS.contentMinWidth}px;
  max-width: ${GS.contentMaxWidth}px;
  flex: 1;
`;
export const rightWrapper = css`
  width: 346px;
  position: relative;
`;

export const creditHistoryDetailModalCss = css`
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .body {
    margin: 0 -20px -20px -20px;
    .table-wrapper {
      overflow: auto;
      max-height: 552px;
    }
  }
`;

export const paymentCardCss = () => {
  const theme: Theme = useTheme();

  return css`
    width: 100%;
    display: grid;
    grid-template-columns: 360px 360px 560px;
    gap: 20px;

    .payment-card-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      padding: 13px 20px;
      border-radius: 8px;
      border: solid 1px #dce0e8;
      box-sizing: border-box;
      background-color: #fff;
      width: 100%;

      .name-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        ${theme.text.h2};
        color: ${theme.colors.mainFont};
        margin-top: 6px;
        margin-bottom: 16px;

        .name {
          min-height: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .tooltip-icon-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 4px;
          cursor: default;
        }
      }

      .button-wrapper {
        display: block;
        position: absolute;
        right: 20px;
      }

      .description {
        width: 100%;
        ${theme.text.disclaimer};
        margin-top: 8px;
      }
    }

    .plan-title-panel {
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
      border: solid 1px #dce0e8;
      width: 100%;
      background-color: #f9fafb;

      &:hover {
        background-color: #f9fafb;
        border: solid 1px #39f;
        cursor: pointer;
      }

      .plan-title-left {
        display: flex;
        .plan-icon {
          width: 64px;
          height: 64px;
          margin-right: 8px;
          border-radius: 8px;
          background-color: #f9fafb;
        }
        .plan-title-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          .plan-name-wrapper {
            display: flex;
            .plan-name {
              ${theme.text.h5};
              color: ${theme.colors.mainFont};
            }
          }
          .title-cycle {
            ${theme.text.disclaimer}
          }
          .card-no-exist {
            display: flex;
            align-self: center;
            font-size: 14px;
            color: #3d4046;
            padding: 22px 0 22px 16px;

            .font-icon-panel {
              margin-right: 4px;
            }
          }
        }
      }
      .plan-title-right {
        display: flex;
        .font-icon-panel {
          margin: auto 16px;
        }
      }
    }

    .plan-info-wrapper {
      .plan-info-list-panel {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        .divide-vertical {
          width: 1px;
          margin: 0px 24px;
          background-color: #dce0e8;
        }
        .plan-info-list {
          display: flex;
          flex-direction: column;

          .info-label {
            ${theme.text.h5};
            color: ${theme.colors.mainFont};
            margin-right: 16px;
            min-width: 70px;
          }
          .info-value {
            margin-top: 4px;
            ${theme.text.input};
            color: ${theme.colors.mainFont};
            display: flex;
          }
        }
      }
    }
  `;
};

export const creditHistoryCss = () => {
  const theme: Theme = useTheme();

  return css`
    .credit-history-header {
      margin: -20px -20px 20px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #dce0e8;
    }

    .credit-history-summary {
      display: flex;
      justify-content: space-between;

      & > :not(:last-child) {
        border-right: 1px solid #dce0e8;
        margin: -8px 0 -8px 0;
        padding: 8px 0 8px 0;
      }

      .summary-item {
        flex: 1;
        display: flex;
        justify-content: center;

        .summary-item-title {
          ${theme.text.h5};
          color: ${theme.colors.mainFont};
        }
        .summary-item-price {
          ${theme.text.input};
          color: ${theme.colors.mainFont};
        }
      }
    }

    .credit-history-body {
      margin: 0 -20px -20px -20px;
    }

    .credit-history-footer {
      display: flex;
      justify-content: center;
    }
  `;
};

export const tableCss = () => {
  const theme: Theme = useTheme();

  return css`
    table {
      padding: 0;
      margin: 0;
      border-collapse: separate;
      border-spacing: 0;
      width: 100%;
    }

    th {
      border-top: solid 1px #dce0e8;
      border-bottom: solid 1px #b8beca;
      background-color: #f9fafb;
      padding: 12px 16px;
      ${theme.text.h5};
      color: ${theme.colors.mainFont};
      text-align: left;
      position: sticky;
      top: 0;
    }
    th:not(:first-of-type) {
      border-left: solid 1px #dce0e8;
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }

    td {
      padding: 11px 16px 8px;
      ${theme.text.input};
      color: ${theme.colors.subFont};
      text-align: left;
      border-bottom: solid 1px #dce0e8;
    }

    td:not(:first-of-type) {
      border-left: solid 1px #dce0e8;
    }
  `;
};

export const creditChargeModalCss = () => {
  const theme: Theme = useTheme();

  return css`
    .header {
      margin-bottom: 24px;
    }
    .body {
      margin-bottom: 24px;

      .form-input-wrapper {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .credit-input-right-label {
          text-align: right;
          white-space: nowrap;
          ${theme.text.h5};
          color: ${theme.colors.subFont};
        }
      }
      .info {
        ${theme.text.disclaimer};
        margin-top: 8px;
      }
      .summary {
        .summary-item {
          ${theme.text.input};
          color: ${theme.colors.mainFont};

          display: flex;
          justify-content: space-between;

          &:not(:last-child) {
            margin-bottom: 8px;
          }
          &.bold {
            ${theme.text.h4};
            color: ${theme.colors.mainFont};
          }
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .credit-refund-button {
        ${theme.text.disclaimer};

        text-decoration: underline;
        cursor: pointer;
      }
    }
    .divider {
      border-bottom: 1px solid #dce0e8;
      margin-bottom: 16px;
    }
  `;
};

export const creditRefundModalCss = css`
  .credit-refund-header {
    margin-bottom: 8px;
  }
  .credit-refund-body {
    margin-bottom: 20px;
  }
`;
