import { css, Theme, useTheme } from '@emotion/react';
import { GS } from '../../styles/size';

export const baseCss = () => {
  return css`
    width: 100%;
    min-width: fit-content;

    * {
      box-sizing: border-box;
    }

    & {
      width: 100%;
      min-width: fit-content;
      display: flex;
      flex-direction: column;
      gap: ${GS.gutter}px;
    }

    .copy-campaign-container {
      font-stretch: normal;
      font-style: normal;
      width: 100%;
      display: flex;
      position: relative;

      .flex-row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .flex-col {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }
    }
  `;
};

export const leftWrapper = css`
  min-width: ${GS.contentMinWidth}px;
  max-width: ${GS.contentMaxWidth}px;
`;
export const rightWrapper = css`
  width: 260px;
  margin-left: 20px;
  position: relative;
`;

export const copyCampaignModalCss = () => {
  const theme: Theme = useTheme();

  return css`
    .horizontal-divider {
      width: 100%;
      height: 1px;
      margin: 16px 0;
      background-color: #dce0e8;
    }
    .copy-campaign-header {
      margin-bottom: 4px;
    }
    .copy-campaign-body {
      margin-bottom: 16px;

      .field-wrapper {
        display: flex;
        align-items: flex-start;
        gap: 20px;
      }
      .field-title {
        color: ${theme.colors.subFont};
        ${theme.text.h5};
      }
    }
    .copy-campaign-footer {
      //
    }
  `;
};
